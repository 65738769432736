// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");

var intialState = /* record */[/* feedbackTab */false];

function TicketHistoryView(Props) {
  var ticket_history = Props.ticket_history;
  var match = React.useState((function () {
          return intialState;
        }));
  var setState = match[1];
  var state = match[0];
  var ticketPriority = function (priority) {
    switch (priority) {
      case "High" :
          return "bg-orange-200 text-orange-500";
      case "Low" :
          return "bg-green-100 text-green-600";
      case "Medium" :
          return "bg-yellow-200 text-yellow-500";
      case "red_alert" :
          return "bg-lavenderblush text-firebrick";
      default:
        return "bg-white text-blackcoral";
    }
  };
  var match$1 = state[/* feedbackTab */0];
  var match$2 = state[/* feedbackTab */0];
  var tmp;
  if (match$2) {
    var match$3 = Ticket$ReactHooksTemplate.normalResponses(ticket_history);
    var tmp$1;
    if (match$3 !== undefined) {
      var response = match$3;
      tmp$1 = Belt_Array.mapWithIndex(response[/* header */0], (function (i, h) {
              return React.createElement("div", {
                          className: "flex w-full font-normal text-sm mt-4 text-left flex-wrap"
                        }, React.createElement("div", {
                              className: "pl-2 text-aurometalsaurus w-1/3 break-all"
                            }, h), React.createElement("div", {
                              className: "pl-2 w-2/3 break-all"
                            }, Belt_Option.getWithDefault(Caml_array.caml_array_get(response[/* contents */1], i), "")));
            }));
    } else {
      tmp$1 = React.createElement("span", undefined, "----No Response----");
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "max-h-100-px ml-2"
            }, tmp$1));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "bg-cultured rounded-lg p-4"
            }, React.createElement("div", {
                  className: "flex justify between bg-cultured w-full min-h-80-px p-4"
                }, React.createElement("div", {
                      className: "w-full rounded-lg min-h-80-px"
                    }, React.createElement("div", {
                          className: "flex justify-between pb-3 pr-3"
                        }, React.createElement("div", {
                              className: "flex font-medium text-sm"
                            }, "#", String(Ticket$ReactHooksTemplate.id(ticket_history))), React.createElement("div", {
                              className: "flex text-xs text-aurometalsaurus font-medium items-center gap-3"
                            }, React.createElement("div", {
                                  className: "text-white bg-aurometalsaurus py-1 px-2 uppercase rounded text-10-px font-normal"
                                }, Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket_history))), React.createElement("div", {
                                  className: "flex justify-center items-center font-normal rounded-2xl py-1 px-3 uppercase text-10-px " + ticketPriority(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket_history)))
                                }, Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket_history))), React.createElement("div", {
                                  className: "flex text-12-px"
                                }, React.createElement("img", {
                                      className: "h-12-px mr-1",
                                      src: Image$ReactHooksTemplate.clockCircle
                                    }), React.createElement("div", undefined, Ticket$ReactHooksTemplate.createdAt(ticket_history).format("lll")))))), React.createElement("div", {
                      className: "flex ml-2 pt-2"
                    }, React.createElement("div", {
                          onClick: (function (param) {
                              return Curry._1(setState, (function (state) {
                                            return /* record */[/* feedbackTab */!state[/* feedbackTab */0]];
                                          }));
                            })
                        }, match$1 ? React.createElement("img", {
                                className: "h-7-px",
                                src: Image$ReactHooksTemplate.chevronUp
                              }) : React.createElement("img", {
                                className: "h-7-px filter-gray-400",
                                src: Image$ReactHooksTemplate.chevronDown
                              })))), tmp);
}

var make = TicketHistoryView;

exports.intialState = intialState;
exports.make = make;
/* react Not a pure module */
