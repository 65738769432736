// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function series(t) {
  return t[/* series */0];
}

function drilldown(t) {
  return t[/* drilldown */1];
}

function data(t) {
  return t[/* data */2];
}

function width(t) {
  return t[/* width */3];
}

function height(t) {
  return t[/* height */4];
}

function fromJson(json) {
  return /* record */[
          /* series */Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), { }), "series"), "empty"),
          /* drilldown */Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), { }), "drilldown"), "empty"),
          /* data */Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), { }), "data"), "empty"),
          /* width */Json_decode.optional((function (param) {
                  return Json_decode.field("width", Json_decode.string, param);
                }), json),
          /* height */Json_decode.optional((function (param) {
                  return Json_decode.field("height", Json_decode.string, param);
                }), json)
        ];
}

exports.series = series;
exports.drilldown = drilldown;
exports.data = data;
exports.width = width;
exports.height = height;
exports.fromJson = fromJson;
/* No side effect */
