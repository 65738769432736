// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function empty(param) {
  return /* record */[
          /* name */undefined,
          /* logo */undefined
        ];
}

function logo(t) {
  return t[/* logo */1];
}

function name(t) {
  return t[/* name */0];
}

function decode(json) {
  return /* record */[
          /* name */Json_decode.field("name", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          /* logo */Json_decode.optional((function (param) {
                  return Json_decode.field("logo", Json_decode.string, param);
                }), json)
        ];
}

exports.name = name;
exports.logo = logo;
exports.empty = empty;
exports.decode = decode;
/* No side effect */
