// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Filter$ReactHooksTemplate = require("./filter.bs.js");
var FilterForm$ReactHooksTemplate = require("./FilterForm.bs.js");
var FilterPills$ReactHooksTemplate = require("./filterPills.bs.js");
var DateFilterForm$ReactHooksTemplate = require("./DateFilterForm.bs.js");
var FilterPillsElement$ReactHooksTemplate = require("./FilterPillsElement.bs.js");

var initialState = /* record */[
  /* loading */false,
  /* currentFilter */0,
  /* filterPills : [] */0,
  /* filters : [] */0
];

function reducer(state, param) {
  if (typeof param === "number") {
    return /* record */[
            /* loading */true,
            /* currentFilter */state[/* currentFilter */1],
            /* filterPills */state[/* filterPills */2],
            /* filters */state[/* filters */3]
          ];
  } else if (param.tag) {
    return /* record */[
            /* loading */state[/* loading */0],
            /* currentFilter */param[0],
            /* filterPills */param[1],
            /* filters */param[2]
          ];
  } else {
    return /* record */[
            /* loading */false,
            /* currentFilter */state[/* currentFilter */1],
            /* filterPills */param[1],
            /* filters */param[0]
          ];
  }
}

function Filters(Props) {
  var onClose = Props.onClose;
  var onApply = Props.onApply;
  var showDateFilter = Props.showDateFilter;
  var showFilterPills = Props.showFilterPills;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var applyFilter = function (filters) {
    return Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/filters", Json_encode.object_(/* :: */[
                    /* tuple */[
                      "filters",
                      Json_encode.list(Filter$ReactHooksTemplate.encode, filters)
                    ],
                    /* [] */0
                  ]));
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* FetchFiltersInProgress */0);
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/filters", Filter$ReactHooksTemplate.fromJson).then((function (xs) {
                  var filterPills = Belt_List.map(Belt_List.keep(xs, Filter$ReactHooksTemplate.isSelected), (function (x) {
                          return FilterPills$ReactHooksTemplate.make(Filter$ReactHooksTemplate.toString(x), x);
                        }));
                  return Promise.resolve(Curry._1(dispatch, /* FetchFiltersCompleted */Block.__(0, [
                                    xs,
                                    filterPills
                                  ])));
                }));
          return ;
        }), ([]));
  var handleSetFilter = function (filter) {
    var filterId = Filter$ReactHooksTemplate.id(filter);
    var filterString = Filter$ReactHooksTemplate.toString(filter);
    var match = Belt_Option.isNone(Belt_List.head(Belt_List.keep(state[/* filterPills */2], (function (xs) {
                    return filterId === FilterPills$ReactHooksTemplate.id(xs);
                  }))));
    var filterPills = match ? Pervasives.$at(state[/* filterPills */2], /* :: */[
            FilterPills$ReactHooksTemplate.make(filterString, filter),
            /* [] */0
          ]) : Belt_List.map(state[/* filterPills */2], (function (xs) {
              var match = FilterPills$ReactHooksTemplate.id(xs) === filterId;
              if (match) {
                return FilterPills$ReactHooksTemplate.make(filterString, filter);
              } else {
                return xs;
              }
            }));
    console.log(filterPills);
    var filters = Belt_List.map(state[/* filters */3], (function (xs) {
            var match = Filter$ReactHooksTemplate.id(xs) === filterId;
            if (match) {
              return filter;
            } else {
              return xs;
            }
          }));
    console.log(filters);
    return Curry._1(dispatch, /* AddFilter */Block.__(1, [
                  filterId,
                  filterPills,
                  filters
                ]));
  };
  var handleResetFilter = function (filter) {
    var filterId = Filter$ReactHooksTemplate.id(filter);
    var filterPills = Belt_List.keep(state[/* filterPills */2], (function (x) {
            return filterId !== FilterPills$ReactHooksTemplate.id(x);
          }));
    var filters = Belt_List.map(state[/* filters */3], (function (x) {
            var match = filterId === Filter$ReactHooksTemplate.id(x);
            if (match) {
              return filter;
            } else {
              return x;
            }
          }));
    applyFilter(filters).then((function (param) {
            return Promise.resolve((Curry._1(dispatch, /* AddFilter */Block.__(1, [
                                filterId,
                                filterPills,
                                filters
                              ])), Curry._1(onApply, /* () */0)));
          }));
    return /* () */0;
  };
  var displayDateFilter = function (xs) {
    var match = Filter$ReactHooksTemplate.filterType(xs);
    var tmp;
    tmp = match.tag === /* Date */1 ? React.createElement(DateFilterForm$ReactHooksTemplate.make, {
            filter: match[0],
            onChange: (function (param) {
                var filter = Filter$ReactHooksTemplate.setFilterTypes(param, xs);
                var filterId = Filter$ReactHooksTemplate.id(filter);
                var filters = Belt_List.map(state[/* filters */3], (function (xs) {
                        var match = Filter$ReactHooksTemplate.id(xs) === filterId;
                        if (match) {
                          return filter;
                        } else {
                          return xs;
                        }
                      }));
                var filterPills = Belt_List.map(Belt_List.keep(filters, Filter$ReactHooksTemplate.isSelected), (function (x) {
                        return FilterPills$ReactHooksTemplate.make(Filter$ReactHooksTemplate.toString(x), x);
                      }));
                applyFilter(filters).then((function (param) {
                        return Promise.resolve((Curry._1(dispatch, /* FetchFiltersCompleted */Block.__(0, [
                                            filters,
                                            filterPills
                                          ])), Curry._1(onApply, /* () */0)));
                      }));
                return /* () */0;
              }),
            displayFilterPills: false,
            disableDatePicker: false
          }) : React.createElement(React.Fragment, undefined);
    return React.createElement("div", undefined, tmp);
  };
  var tmp;
  if (showDateFilter) {
    var match$1 = state[/* filters */3];
    tmp = match$1 ? React.createElement(React.Fragment, undefined, displayDateFilter(match$1[0])) : React.createElement(React.Fragment, undefined);
  } else {
    tmp = showFilterPills ? $$Array.of_list(Belt_List.mapWithIndex(state[/* filterPills */2], (function (i, t) {
                  return React.createElement("div", {
                              key: String(i),
                              className: "flex flex-wrap mr-1"
                            }, React.createElement(FilterPillsElement$ReactHooksTemplate.make, {
                                  t: t,
                                  handleResetFilter: handleResetFilter,
                                  height: "h-8"
                                }));
                }))) : React.createElement("div", {
            className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
            style: {
              height: "calc(100vh + 5rem)"
            }
          }, React.createElement("div", {
                className: "bg-transparent top-64-px w-1/2 h-screen fixed right-0 z-10 shadow"
              }, React.createElement("div", {
                    className: "bg-white relative z-10 shadow",
                    style: {
                      height: "calc(100vh - 4rem)"
                    }
                  }, React.createElement(FilterForm$ReactHooksTemplate.make, {
                        filters: state[/* filters */3],
                        filterPills: state[/* filterPills */2],
                        onClose: onClose,
                        onApply: onApply,
                        handleSetFilter: handleSetFilter,
                        handleResetFilter: handleResetFilter,
                        currentFilter: state[/* currentFilter */1]
                      }))));
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = Filters;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
