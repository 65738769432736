// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var NewPasswordInput$ReactHooksTemplate = require("../common/NewPasswordInput.bs.js");

function isPasswordFilled(x) {
  return !Curry._1(Domain$ReactHooksTemplate.$$String.Password.isEmpty, x);
}

function isPasswordValid(x) {
  var match = isPasswordFilled(x);
  if (match) {
    return Utils$ReactHooksTemplate.checkMinLength8(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isAlphanumericPassword(x) {
  var match = isPasswordFilled(x);
  if (match) {
    return Utils$ReactHooksTemplate.checkAlphanumeric(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isUserNameInPassword(email, x) {
  var unameInPwd = Utils$ReactHooksTemplate.checkUsernameExistInPassword(email, Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  var match = isPasswordFilled(x);
  if (match) {
    return unameInPwd;
  } else {
    return false;
  }
}

function areIndpendentFieldsValid(passwordInputFields) {
  if (isPasswordValid(passwordInputFields[/* newPassword */1])) {
    return isPasswordValid(passwordInputFields[/* newPasswordConfirmation */2]);
  } else {
    return false;
  }
}

function arePasswordsFilled(x, y) {
  if (isPasswordFilled(x)) {
    return isPasswordFilled(y);
  } else {
    return false;
  }
}

function arePasswordsExactMatch(x, y) {
  var match = arePasswordsFilled(x, y);
  if (match) {
    return Caml_obj.caml_equal(x, y);
  } else {
    return true;
  }
}

function areDepedentFieldsValid(passwordInputFields) {
  return arePasswordsExactMatch(passwordInputFields[/* newPassword */1], passwordInputFields[/* newPasswordConfirmation */2]);
}

var emptyPasswordConfirmation_000 = /* oldPassword */Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0);

var emptyPasswordConfirmation_001 = /* newPassword */Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0);

var emptyPasswordConfirmation_002 = /* newPasswordConfirmation */Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0);

var emptyPasswordConfirmation = /* record */[
  emptyPasswordConfirmation_000,
  emptyPasswordConfirmation_001,
  emptyPasswordConfirmation_002,
  /* flash */undefined
];

function buildPasswordDict(passwordConfirmation) {
  var params = { };
  params["old_password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation[/* oldPassword */0]);
  params["password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation[/* newPassword */1]);
  params["password_confirmation"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation[/* newPasswordConfirmation */2]);
  var user = { };
  user["user"] = params;
  return user;
}

function updatePassword(passwordConfirmation) {
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users/update_password", buildPasswordDict(passwordConfirmation));
}

function hasNewPassword(passwordConfirmation) {
  return Caml_obj.caml_notequal(passwordConfirmation[/* newPassword */1], Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0));
}

function hasNewPasswordConfirmation(passwordConfirmation) {
  return Caml_obj.caml_notequal(passwordConfirmation[/* newPasswordConfirmation */2], Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0));
}

function hasMandatoryParams(passwordConfirmation) {
  if (hasNewPassword(passwordConfirmation)) {
    return hasNewPasswordConfirmation(passwordConfirmation);
  } else {
    return false;
  }
}

function isFormValid(passwordConfirmation) {
  if (hasMandatoryParams(passwordConfirmation) && areIndpendentFieldsValid(passwordConfirmation)) {
    return areDepedentFieldsValid(passwordConfirmation);
  } else {
    return false;
  }
}

function UserUpdatePassword(Props) {
  var match = React.useState((function () {
          return emptyPasswordConfirmation;
        }));
  var setState = match[1];
  var passwordConfirmation = match[0];
  var handleOldPasswordChange = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(setState, (function (passwordConfirmation) {
                                return /* record */[
                                        /* oldPassword */x,
                                        /* newPassword */passwordConfirmation[/* newPassword */1],
                                        /* newPasswordConfirmation */passwordConfirmation[/* newPasswordConfirmation */2],
                                        /* flash */passwordConfirmation[/* flash */3]
                                      ];
                              }));
                }), $$event);
  };
  var handlePasswordChange = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(setState, (function (passwordConfirmation) {
                                return /* record */[
                                        /* oldPassword */passwordConfirmation[/* oldPassword */0],
                                        /* newPassword */x,
                                        /* newPasswordConfirmation */passwordConfirmation[/* newPasswordConfirmation */2],
                                        /* flash */passwordConfirmation[/* flash */3]
                                      ];
                              }));
                }), $$event);
  };
  var handlePasswordConfirmationChange = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(setState, (function (passwordConfirmation) {
                                return /* record */[
                                        /* oldPassword */passwordConfirmation[/* oldPassword */0],
                                        /* newPassword */passwordConfirmation[/* newPassword */1],
                                        /* newPasswordConfirmation */x,
                                        /* flash */passwordConfirmation[/* flash */3]
                                      ];
                              }));
                }), $$event);
  };
  var decodeApiResponseStatus = function (response) {
    return Json_decode.field("status", Json_decode.string, response).toLowerCase();
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users/update_password", buildPasswordDict(passwordConfirmation)).then((function (response) {
            if (decodeApiResponseStatus(response) === "success") {
              (( window.location.href="/users/sign_in" ));
            } else {
              var errorMessage = List.fold_left((function (x, y) {
                      return x + y;
                    }), "", Json_decode.field("errors", (function (x) {
                          return Json_decode.field("password", (function (param) {
                                        return Json_decode.list(Json_decode.string, param);
                                      }), x);
                        }), response));
              Curry._1(setState, (function (passwordConfirmation) {
                      return /* record */[
                              /* oldPassword */passwordConfirmation[/* oldPassword */0],
                              /* newPassword */passwordConfirmation[/* newPassword */1],
                              /* newPasswordConfirmation */passwordConfirmation[/* newPasswordConfirmation */2],
                              /* flash *//* ErrorFlash */[errorMessage]
                            ];
                    }));
            }
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var validCSS = "hidden";
  var errorCSS = "text-red-600 font-medium";
  var passwordMinLengthCSS = function (x) {
    var match = isPasswordValid(x);
    if (match) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var passwordAlphanumericCSS = function (x) {
    var match = isAlphanumericPassword(x);
    if (match) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var passwordMatchCSS = function (x, y) {
    var match = arePasswordsExactMatch(x, y);
    if (match) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var handleFlashClose = function (param) {
    return Curry._1(setState, (function (passwordConfirmation) {
                  return /* record */[
                          /* oldPassword */passwordConfirmation[/* oldPassword */0],
                          /* newPassword */passwordConfirmation[/* newPassword */1],
                          /* newPasswordConfirmation */passwordConfirmation[/* newPasswordConfirmation */2],
                          /* flash */undefined
                        ];
                }));
  };
  var match$1 = passwordConfirmation[/* flash */3];
  var tmp;
  if (match$1 !== undefined) {
    var match$2 = match$1;
    tmp = match$2 ? React.createElement(Notification$ReactHooksTemplate.make, {
            text: match$2[0],
            isError: true,
            handleClose: handleFlashClose
          }) : React.createElement(React.Fragment, undefined, React.createElement(Notification$ReactHooksTemplate.make, {
                text: "Successfully updated password",
                handleClose: handleFlashClose
              }), React.createElement("p", undefined, "sdsfds"));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "p-30-px"
            }, tmp, React.createElement("h1", {
                  className: "text-32-px mb-30-px"
                }, "Change Password"), React.createElement("form", {
                  onSubmit: handleSubmit
                }, React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                          password: passwordConfirmation[/* oldPassword */0],
                          label: "Old Password",
                          handleChange: handleOldPasswordChange
                        })), React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                          password: passwordConfirmation[/* newPassword */1],
                          label: "New Password",
                          handleChange: handlePasswordChange
                        }), React.createElement("div", {
                          className: passwordMinLengthCSS(passwordConfirmation[/* newPassword */1]) + " mb-10-px"
                        }, "Minimum length should be 8."), React.createElement("div", {
                          className: passwordAlphanumericCSS(passwordConfirmation[/* newPassword */1]) + " mb-10-px"
                        }, "Password should contain atleast one lowercase, one uppercase, one number and one special character"), React.createElement("div", {
                          className: passwordMatchCSS(passwordConfirmation[/* newPassword */1], passwordConfirmation[/* newPasswordConfirmation */2])
                        }, "Passwords do not match.")), React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                          password: passwordConfirmation[/* newPasswordConfirmation */2],
                          label: "Confirm Password",
                          handleChange: handlePasswordConfirmationChange
                        }), React.createElement("div", {
                          className: passwordMinLengthCSS(passwordConfirmation[/* newPasswordConfirmation */2]) + " mb-10-px"
                        }, "Minimum length should be 8."), React.createElement("div", {
                          className: passwordMatchCSS(passwordConfirmation[/* newPassword */1], passwordConfirmation[/* newPasswordConfirmation */2])
                        }, "Passwords do not match."), React.createElement("div", {
                          className: passwordAlphanumericCSS(passwordConfirmation[/* newPasswordConfirmation */2]) + " mb-10-px"
                        }, "Password should contain atleast one lowercase, one uppercase, one number and one special character")), React.createElement("div", undefined, React.createElement("input", {
                          className: "bg-teal-500 hover:bg-teal-700 py-6-px px-20-px font-normal text-white font-16-px leading-snug",
                          disabled: !isFormValid(passwordConfirmation),
                          type: "submit",
                          value: "Submit"
                        }))));
}

var make = UserUpdatePassword;

exports.isPasswordFilled = isPasswordFilled;
exports.isPasswordValid = isPasswordValid;
exports.isAlphanumericPassword = isAlphanumericPassword;
exports.isUserNameInPassword = isUserNameInPassword;
exports.areIndpendentFieldsValid = areIndpendentFieldsValid;
exports.arePasswordsFilled = arePasswordsFilled;
exports.arePasswordsExactMatch = arePasswordsExactMatch;
exports.areDepedentFieldsValid = areDepedentFieldsValid;
exports.emptyPasswordConfirmation = emptyPasswordConfirmation;
exports.buildPasswordDict = buildPasswordDict;
exports.updatePassword = updatePassword;
exports.hasNewPassword = hasNewPassword;
exports.hasNewPasswordConfirmation = hasNewPasswordConfirmation;
exports.hasMandatoryParams = hasMandatoryParams;
exports.isFormValid = isFormValid;
exports.make = make;
/* emptyPasswordConfirmation Not a pure module */
