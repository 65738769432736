// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var NumberSelected$ReactHooksTemplate = require("./numberSelected.bs.js");

function getMin(t) {
  return t[/* min */0];
}

function getMax(t) {
  return t[/* max */1];
}

function selected(t) {
  return t[/* selected */2];
}

function isSelected(t) {
  return Belt_Option.isSome(t[/* selected */2]);
}

function setSelected(selected, t) {
  return /* record */[
          /* min */t[/* min */0],
          /* max */t[/* max */1],
          /* selected */selected
        ];
}

function resetSelected(t) {
  return /* record */[
          /* min */t[/* min */0],
          /* max */t[/* max */1],
          /* selected */undefined
        ];
}

function fromJson(param) {
  return /* record */[
          /* min */10,
          /* max */30,
          /* selected */undefined
        ];
}

function toString(t) {
  var match = t[/* selected */2];
  if (match !== undefined) {
    return NumberSelected$ReactHooksTemplate.toString(Caml_option.valFromOption(match));
  } else {
    return "";
  }
}

function encodeParams(t) {
  return Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalParamToList("min", t[/* min */0], (function (prim) {
                    return prim;
                  })), Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalParamToList("max", t[/* max */1], (function (prim) {
                        return prim;
                      })), Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t[/* selected */2], NumberSelected$ReactHooksTemplate.encode)));
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "category_type",
                "number"
              ],
              /* :: */[
                /* tuple */[
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                /* [] */0
              ]
            ]);
}

exports.getMin = getMin;
exports.getMax = getMax;
exports.selected = selected;
exports.isSelected = isSelected;
exports.fromJson = fromJson;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.toString = toString;
exports.encode = encode;
/* Utils-ReactHooksTemplate Not a pure module */
