// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t[/* id */0];
}

function senderId(t) {
  return t[/* senderId */1];
}

function $$default(t) {
  return t[/* default */2];
}

function decode(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* senderId */Json_decode.field("sender_id", Json_decode.string, json),
          /* default */Json_decode.field("default", Json_decode.bool, json)
        ];
}

function make(id, senderId, $staropt$star) {
  var $$default = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */[
          /* id */id,
          /* senderId */senderId,
          /* default */$$default
        ];
}

exports.id = id;
exports.senderId = senderId;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.decode = decode;
exports.make = make;
/* No side effect */
