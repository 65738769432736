// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var DropDownDefaultForm$ReactHooksTemplate = require("./DropDownDefaultForm.bs.js");

function DropDownForm(Props) {
  var dropDown = Props.dropDown;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var handleOnChange = function ($$event) {
    var choiceId = $$event.target.value;
    var selectedChoice = List.find((function (x) {
            return RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(x)) === choiceId;
          }), DropDown$ReactHooksTemplate.choices(dropDown));
    return Curry._1(onChange, DropDown$ReactHooksTemplate.setAnswer(dropDown, selectedChoice));
  };
  var matchChoicesWithAnswer = function (choice) {
    var match = DropDown$ReactHooksTemplate.getAnswer(dropDown);
    if (match !== undefined) {
      return Belt_List.has(match, choice, (function (xs, ys) {
                    return Caml_obj.caml_equal(DropDown$ReactHooksTemplate.choiceId(xs), DropDown$ReactHooksTemplate.choiceId(ys));
                  }));
    } else {
      return false;
    }
  };
  var match = DropDown$ReactHooksTemplate.representation(dropDown);
  var backGroundCss = match >= 2 ? "bg-black bg-opacity-50" : "bg-tropicalbreeze";
  var match$1 = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match$1 !== undefined) {
    switch (match$1) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(DropDownDefaultForm$ReactHooksTemplate.make, {
                    dropDown: dropDown,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        var match$2 = DropDown$ReactHooksTemplate.getAnswer(dropDown);
        var tmp;
        if (match$2 !== undefined) {
          var match$3 = Belt_List.head(match$2);
          tmp = match$3 !== undefined ? RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(match$3)) : "";
        } else {
          tmp = "Select here";
        }
        var match$4 = DropDown$ReactHooksTemplate.getAnswer(dropDown);
        var match$5 = DropDown$ReactHooksTemplate.getAnswer(dropDown);
        var tmp$1;
        if (match$5 !== undefined) {
          var match$6 = Belt_List.head(match$5);
          if (match$6 !== undefined) {
            var choice = match$6;
            var match$7 = matchChoicesWithAnswer(choice) && DropDown$ReactHooksTemplate.getAllowNonSpecifiedText(choice);
            tmp$1 = match$7 ? React.createElement("div", undefined, React.createElement("textarea", {
                        className: " " + (String(backGroundCss) + " w-full border p-4"),
                        placeholder: "Specify if others",
                        rows: 3,
                        onChange: (function (param) {
                            var choice$1 = choice;
                            var $$event = param;
                            return Curry._1(onChange, DropDown$ReactHooksTemplate.setNotSpecifiedTextForAnswer(dropDown, DropDown$ReactHooksTemplate.setNotSpecifiedText(Utils$ReactHooksTemplate.formTargetValue($$event), choice$1)));
                          })
                      })) : React.createElement(React.Fragment, undefined);
          } else {
            tmp$1 = React.createElement(React.Fragment, undefined);
          }
        } else {
          tmp$1 = React.createElement(React.Fragment, undefined);
        }
        return React.createElement("div", {
                    className: "flex flex-col"
                  }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                        text: DropDown$ReactHooksTemplate.getTextTranslation(dropDown, SurveyLanguage$ReactHooksTemplate.name(language)),
                        description: DropDown$ReactHooksTemplate.getDescriptionTranslation(dropDown, SurveyLanguage$ReactHooksTemplate.name(language)),
                        mandatory: mandatory,
                        surveyTheme: surveyTheme
                      }), React.createElement("div", {
                        className: "flex flex-col"
                      }, React.createElement("label", {
                            className: "block mb-10-px"
                          }, React.createElement("select", {
                                className: "" + (String(backGroundCss) + " p-2 border border-white-300 rounded\n                      flex w-3/4 mb-2 "),
                                value: tmp,
                                onChange: handleOnChange
                              }, match$4 !== undefined ? React.createElement(React.Fragment, undefined) : React.createElement("option", {
                                      key: "0",
                                      value: "0"
                                    }, "Select Field"), $$Array.of_list(Belt_List.map(DropDown$ReactHooksTemplate.choices(dropDown), (function (choice) {
                                          return React.createElement("option", {
                                                      key: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice)),
                                                      value: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice))
                                                    }, DropDown$ReactHooksTemplate.getChoiceTextTranslation(dropDown, choice, SurveyLanguage$ReactHooksTemplate.name(language)));
                                        }))))), tmp$1), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                          language: language
                        }) : React.createElement(React.Fragment, undefined));
    
  }
}

var make = DropDownForm;

exports.make = make;
/* react Not a pure module */
