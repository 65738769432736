// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var SurveyForm$ReactHooksTemplate = require("./SurveyForm.bs.js");

function reducer(state, action) {
  if (action) {
    return /* Available */[action[0]];
  } else {
    return /* InvalidSurvey */1;
  }
}

function fetchSurvey(id) {
  var url = "/api/v1/surveys/" + (String(id) + "");
  return Http$ReactHooksTemplate.fetchGetJSON(url).then((function (json) {
                console.log(json);
                return Promise.resolve(Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, json));
              }));
}

function SurveyEdit(Props) {
  var id = Props.id;
  var projectId = Props.projectId;
  var match = React.useReducer(reducer, /* FetchingSurvey */0);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          fetchSurvey(id).then((function (survey) {
                  return Promise.resolve(Curry._1(dispatch, /* FetchedSurvey */[survey]));
                }));
          return ;
        }), ([]));
  if (typeof state === "number") {
    if (state !== 0) {
      return React.createElement("p", undefined, "Survey not found");
    } else {
      return React.createElement("p", undefined, "Loading Survey");
    }
  } else {
    return React.createElement("div", undefined, React.createElement(SurveyForm$ReactHooksTemplate.make, {
                    survey: state[0],
                    projectId: projectId
                  }));
  }
}

var make = SurveyEdit;

exports.reducer = reducer;
exports.fetchSurvey = fetchSurvey;
exports.make = make;
/* react Not a pure module */
