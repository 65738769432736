// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Utils$ReactHooksTemplate = require("./utils.bs.js");

function TextInput(Props) {
  var label = Props.label;
  var value = Props.value;
  var handleChange = Props.handleChange;
  var errorCSS = Props.errorCSS;
  var errorText = Props.errorText;
  var match = Props.error;
  var error = match !== undefined ? match : false;
  var handleValidation = function ($$event) {
    var name_input = Utils$ReactHooksTemplate.formTargetValue($$event);
    var firstChar = name_input[0];
    var check = Belt_Array.getBy(/* array */[
          "<",
          "!",
          "=",
          "+",
          "-",
          "@",
          ">",
          "?",
          "'",
          "\"",
          "$",
          "#",
          "%",
          "^",
          "*",
          "(",
          ")",
          "/",
          ".",
          "&",
          ",",
          ";",
          "`",
          "~"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      console.log("Invalid Characters!");
      return /* () */0;
    } else {
      return Curry._1(handleChange, $$event);
    }
  };
  return React.createElement("label", {
              className: "block mb-10-px"
            }, React.createElement("div", {
                  className: "mb-10-px font-semibold"
                }, label), React.createElement("input", {
                  className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                  type: "text",
                  value: value,
                  onChange: handleValidation
                }), error ? React.createElement("div", {
                    className: errorCSS !== undefined ? errorCSS : "text-red-600 font-medium"
                  }, errorText !== undefined ? errorText : "Error") : React.createElement(React.Fragment, undefined));
}

var make = TextInput;

exports.make = make;
/* react Not a pure module */
