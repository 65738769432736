// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("./image.bs.js");

function $$Notification(Props) {
  var text = Props.text;
  var match = Props.isError;
  var isError = match !== undefined ? match : false;
  var handleClose = Props.handleClose;
  var backgroundColorCSS = isError ? "bg-red-400" : "bg-teal-400";
  return React.createElement("div", {
              className: "" + (String(backgroundColorCSS) + " flex justify-between items-center w-1/3\n    top-0 left-1/3 px-20-px py-10-px mx-auto")
            }, React.createElement("p", {
                  className: "mr-20-px"
                }, text), React.createElement("a", {
                  className: "w-15-px h-15-px flex-grow-0 flex-shrink-0",
                  href: "#",
                  onClick: (function ($$event) {
                      $$event.preventDefault();
                      return Curry._1(handleClose, /* () */0);
                    })
                }, React.createElement("img", {
                      src: Image$ReactHooksTemplate.closeIcon
                    })));
}

var make = $$Notification;

exports.make = make;
/* react Not a pure module */
