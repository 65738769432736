// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var RoleListItem$ReactHooksTemplate = require("./RoleListItem.bs.js");

function fetchRoles(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/roles", (function (param) {
                return Json_decode.field("roles", (function (param) {
                              return Json_decode.list(Role$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function reducer(state, action) {
  return /* FetchRolesCompleted */[action[0]];
}

function RoleList(Props) {
  var match = React.useReducer(reducer, /* FetchRolesInProgress */0);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$1[0];
  React.useEffect((function () {
          fetchRoles(/* () */0).then((function (roles) {
                  return Promise.resolve(Curry._1(dispatch, /* DisplayList */[roles]));
                }));
          return ;
        }), ([]));
  if (state) {
    var match$2 = Curry._1(hasPermission, /* ShowRole */13);
    var tmp;
    if (match$2) {
      var match$3 = Curry._1(hasPermission, /* AddRole */10);
      tmp = React.createElement("div", {
            className: "p-20-px"
          }, match$3 ? React.createElement("div", {
                  className: "flex justify-between items-center mb-30-px "
                }, React.createElement("h2", {
                      className: "mb-32-px text-2xl"
                    }, "Roles"), React.createElement("a", {
                      className: "text-white bg-teal-500 hover:bg-teal-700 py-6-px px-20-px",
                      href: "/roles/new"
                    }, "Add new Role")) : React.createElement(React.Fragment, undefined), React.createElement("table", {
                className: "w-full mt-10-px bg-white"
              }, React.createElement("thead", {
                    className: "border border-gray-400 bg-ghostwhite text-aurometalsaurus rounded-t-lg"
                  }, React.createElement("tr", undefined, React.createElement("th", {
                            className: "text-left w-1/6 font-thin py-10-px pl-4"
                          }, "Name"), React.createElement("th", {
                            className: "text-left w-1/6 font-thin py-10-px pl-4"
                          }, "Description"), React.createElement("th", {
                            className: "text-left w-3/6 font-thin py-10-px pl-4"
                          }, "Permissions"), React.createElement("th", {
                            className: "text-left w-1/6 font-thin py-10-px pl-4"
                          }, "Created At"))), React.createElement("tbody", {
                    className: "border border-gray-400"
                  }, $$Array.of_list(List.map((function (role) {
                              return React.createElement(RoleListItem$ReactHooksTemplate.make, {
                                          role: role,
                                          key: String(Role$ReactHooksTemplate.id(role))
                                        });
                            }), state[0])))));
    } else {
      tmp = React.createElement("div", {
            className: "h-screen w-full animated fadeIn"
          }, React.createElement(Loading$ReactHooksTemplate.make, {
                img: Image$ReactHooksTemplate.unAuthorized,
                text: "401: Unauthorized!!",
                loading: false
              }));
    }
    return React.createElement("div", undefined, tmp);
  } else {
    var match$4 = Curry._1(hasPermission, /* ShowRole */13);
    if (match$4) {
      return React.createElement("p", undefined, "Fetching roles...");
    } else {
      return React.createElement("div", {
                  className: "h-screen w-full animated fadeIn"
                }, React.createElement(Loading$ReactHooksTemplate.make, {
                      img: Image$ReactHooksTemplate.unAuthorized,
                      text: "401: Unauthorized!!",
                      loading: false
                    }));
    }
  }
}

var make = RoleList;

exports.fetchRoles = fetchRoles;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
