// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var TicketView$ReactHooksTemplate = require("./TicketView.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var TicketListItem$ReactHooksTemplate = require("./TicketListItem.bs.js");

function statusFromString(status) {
  var match = status.toLowerCase();
  switch (match) {
    case "closed" :
        return /* Closed */4;
    case "open" :
        return /* Open */1;
    case "parked" :
        return /* Parked */2;
    case "resolved" :
        return /* Resolved */3;
    default:
      return /* All */0;
  }
}

function statusToString(param) {
  switch (param) {
    case /* All */0 :
        return "all";
    case /* Open */1 :
        return "open";
    case /* Parked */2 :
        return "parked";
    case /* Resolved */3 :
        return "resolved";
    case /* Closed */4 :
        return "closed";
    
  }
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function page(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

function TicketWorker(Props) {
  var status = Props.status;
  Props.search;
  var initialState_007 = /* status */statusFromString(status);
  var initialState = /* record */[
    /* loading */true,
    /* showFilter */false,
    /* tickets : [] */0,
    /* ticketId */0,
    /* currentPage */"1",
    /* agents : [] */0,
    /* assignee */undefined,
    initialState_007
  ];
  var match = React.useState((function () {
          return initialState;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$1[0];
  var assignTicket = function (ticketId) {
    var match = state[/* assignee */6];
    if (match !== undefined) {
      var assigneeId = Ticket$ReactHooksTemplate.Assignee.id(match);
      var params = { };
      params["assignee_id"] = assigneeId;
      var ticket = Belt_List.keep(state[/* tickets */2], (function (xs) {
              return Ticket$ReactHooksTemplate.id(xs) === ticketId;
            }));
      var ticketIds = Belt_List.map(ticket, Ticket$ReactHooksTemplate.id);
      params["ids"] = Json_encode.list((function (prim) {
              return prim;
            }), ticketIds);
      var ticket$1 = { };
      ticket$1["ticket"] = params;
      Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/assign", ticket$1).then((function (response) {
              console.log(response);
              var updatedTickets = List.map((function (x) {
                      var match = Ticket$ReactHooksTemplate.id(x) === ticketId;
                      if (match) {
                        return Ticket$ReactHooksTemplate.updateAssignee(state[/* assignee */6], x);
                      } else {
                        return x;
                      }
                    }), state[/* tickets */2]);
              return Promise.resolve(Curry._1(setState, (function (param) {
                                return /* record */[
                                        /* loading */state[/* loading */0],
                                        /* showFilter */state[/* showFilter */1],
                                        /* tickets */updatedTickets,
                                        /* ticketId */state[/* ticketId */3],
                                        /* currentPage */state[/* currentPage */4],
                                        /* agents */state[/* agents */5],
                                        /* assignee */state[/* assignee */6],
                                        /* status */state[/* status */7]
                                      ];
                              })));
            }));
      return /* () */0;
    } else {
      return /* () */0;
    }
  };
  var handleAssigneeSelection = function ($$event) {
    var emailText = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, emailText)) {
      return Curry._1(setState, (function (param) {
                    return /* record */[
                            /* loading */state[/* loading */0],
                            /* showFilter */state[/* showFilter */1],
                            /* tickets */state[/* tickets */2],
                            /* ticketId */state[/* ticketId */3],
                            /* currentPage */state[/* currentPage */4],
                            /* agents */state[/* agents */5],
                            /* assignee */undefined,
                            /* status */state[/* status */7]
                          ];
                  }));
    } else {
      var selected = List.find((function (assignee) {
              return Caml_obj.caml_equal(Ticket$ReactHooksTemplate.Assignee.email(assignee), emailText);
            }), state[/* agents */5]);
      return Curry._1(setState, (function (param) {
                    return /* record */[
                            /* loading */state[/* loading */0],
                            /* showFilter */state[/* showFilter */1],
                            /* tickets */state[/* tickets */2],
                            /* ticketId */state[/* ticketId */3],
                            /* currentPage */state[/* currentPage */4],
                            /* agents */state[/* agents */5],
                            /* assignee */selected,
                            /* status */state[/* status */7]
                          ];
                  }));
    }
  };
  var fetchTickets = function (pageNumber) {
    var status = statusToString(state[/* status */7]);
    var match = Curry._1(hasPermission, /* ManageTickets */1);
    var url = match ? "/api/v1/tickets" : "/api/v1/tickets/assigned";
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + (String(url) + ("?status=" + (String(status) + ("&page=" + (String(pageNumber) + "&worker_page=true")))))).then((function (param) {
                  var page$1 = page(Caml_option.null_to_opt(param[0].get("Page")));
                  var tickets = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("tickets", (function (param) {
                                            return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                                          }), json));
                        }));
                  return Promise.resolve(/* tuple */[
                              tickets,
                              page$1
                            ]);
                }));
  };
  var fetchTicketWorkers = function (param) {
    var partial_arg = Ticket$ReactHooksTemplate.Assignee.decode;
    var partial_arg$1 = function (param) {
      return Json_decode.list(partial_arg, param);
    };
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                  return Json_decode.field("recovery_agents", partial_arg$1, param);
                }));
  };
  var domElement = React.useRef(null);
  var handleScroll = function ($$event) {
    var element = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    var bottom = element.scrollHeight - element.scrollTop - element.clientHeight < 5.0;
    if (bottom) {
      var page = Caml_format.caml_int_of_string(state[/* currentPage */4]);
      var pageNumber = String(page + 1 | 0);
      fetchTickets(pageNumber).then((function (param) {
              var page = param[1];
              return Promise.resolve(param[0].then((function (tickets) {
                                var mergedTickets = List.append(state[/* tickets */2], tickets);
                                return Promise.resolve(Curry._1(setState, (function (param) {
                                                  return /* record */[
                                                          /* loading */false,
                                                          /* showFilter */false,
                                                          /* tickets */mergedTickets,
                                                          /* ticketId */state[/* ticketId */3],
                                                          /* currentPage */page,
                                                          /* agents */state[/* agents */5],
                                                          /* assignee */state[/* assignee */6],
                                                          /* status */state[/* status */7]
                                                        ];
                                                })));
                              })));
            }));
      return /* () */0;
    } else {
      return 0;
    }
  };
  var updateTickets = function (ticketId, status) {
    var updatedTickets = List.map((function (x) {
            var match = Ticket$ReactHooksTemplate.id(x) === ticketId;
            if (match) {
              return Ticket$ReactHooksTemplate.updateTicketStatus(status, x);
            } else {
              return x;
            }
          }), state[/* tickets */2]);
    return Curry._1(setState, (function (param) {
                  return /* record */[
                          /* loading */state[/* loading */0],
                          /* showFilter */state[/* showFilter */1],
                          /* tickets */updatedTickets,
                          /* ticketId */state[/* ticketId */3],
                          /* currentPage */state[/* currentPage */4],
                          /* agents */state[/* agents */5],
                          /* assignee */state[/* assignee */6],
                          /* status */state[/* status */7]
                        ];
                }));
  };
  React.useEffect((function () {
          fetchTickets(state[/* currentPage */4]).then((function (param) {
                  var page = param[1];
                  var tickets = param[0];
                  return Promise.resolve(fetchTicketWorkers(/* () */0).then((function (agents) {
                                    return tickets.then((function (tickets) {
                                                  var selected = tickets ? Ticket$ReactHooksTemplate.id(tickets[0]) : 0;
                                                  var mergedTickets = List.append(state[/* tickets */2], tickets);
                                                  return Promise.resolve(Curry._1(setState, (function (param) {
                                                                    return /* record */[
                                                                            /* loading */false,
                                                                            /* showFilter */false,
                                                                            /* tickets */mergedTickets,
                                                                            /* ticketId */selected,
                                                                            /* currentPage */page,
                                                                            /* agents */agents,
                                                                            /* assignee */state[/* assignee */6],
                                                                            /* status */state[/* status */7]
                                                                          ];
                                                                  })));
                                                }));
                                  })));
                }));
          return ;
        }), ([]));
  var toggleFilter = function (param) {
    return Curry._1(setState, (function (param) {
                  return /* record */[
                          /* loading */state[/* loading */0],
                          /* showFilter */!state[/* showFilter */1],
                          /* tickets */state[/* tickets */2],
                          /* ticketId */state[/* ticketId */3],
                          /* currentPage */state[/* currentPage */4],
                          /* agents */state[/* agents */5],
                          /* assignee */state[/* assignee */6],
                          /* status */state[/* status */7]
                        ];
                }));
  };
  var handleStatusChange = function (status, _event) {
    Curry._1(setState, (function (param) {
            return /* record */[
                    /* loading */state[/* loading */0],
                    /* showFilter */state[/* showFilter */1],
                    /* tickets */state[/* tickets */2],
                    /* ticketId */state[/* ticketId */3],
                    /* currentPage */state[/* currentPage */4],
                    /* agents */state[/* agents */5],
                    /* assignee */state[/* assignee */6],
                    /* status */status
                  ];
          }));
    var statusParam = statusToString(status);
    ReasonReactRouter.push("/tickets/work/" + (String(statusParam) + ""));
    window.location.reload();
    return /* () */0;
  };
  var displayTickets = function (ticket) {
    var isOpenFilter = state[/* ticketId */3] === Ticket$ReactHooksTemplate.id(ticket);
    return React.createElement("div", {
                key: String(Ticket$ReactHooksTemplate.id(ticket)),
                className: "flex flex-col bg-white m-2 rounded"
              }, React.createElement("div", {
                    className: "flex text-sm justify-between rounded border-gray-200 " + (
                      isOpenFilter ? "bg-lavenderweb border-l-4 border-palatinateblue" : "border"
                    ),
                    onClick: (function (param) {
                        var id = Ticket$ReactHooksTemplate.id(ticket);
                        return Curry._1(setState, (function (param) {
                                      return /* record */[
                                              /* loading */state[/* loading */0],
                                              /* showFilter */state[/* showFilter */1],
                                              /* tickets */state[/* tickets */2],
                                              /* ticketId */id,
                                              /* currentPage */state[/* currentPage */4],
                                              /* agents */state[/* agents */5],
                                              /* assignee */state[/* assignee */6],
                                              /* status */state[/* status */7]
                                            ];
                                    }));
                      })
                  }, React.createElement(TicketListItem$ReactHooksTemplate.make, {
                        ticket: ticket
                      })));
  };
  var displaySelectedTicket = function (ticket) {
    var isOpenFilter = state[/* ticketId */3] === Ticket$ReactHooksTemplate.id(ticket);
    return React.createElement("div", {
                key: String(Ticket$ReactHooksTemplate.id(ticket)),
                className: "flex flex-col 1/2"
              }, isOpenFilter ? React.createElement(TicketView$ReactHooksTemplate.make, {
                      ticket: ticket,
                      updateTickets: updateTickets,
                      handleAssigneeSelection: handleAssigneeSelection,
                      assignTicket: assignTicket,
                      agents: state[/* agents */5]
                    }) : React.createElement(React.Fragment, undefined));
  };
  var onApply = function (param) {
    Curry._1(setState, (function (param) {
            return /* record */[
                    /* loading */true,
                    /* showFilter */false,
                    /* tickets */state[/* tickets */2],
                    /* ticketId */state[/* ticketId */3],
                    /* currentPage */state[/* currentPage */4],
                    /* agents */state[/* agents */5],
                    /* assignee */state[/* assignee */6],
                    /* status */state[/* status */7]
                  ];
          }));
    var pageNumber = state[/* currentPage */4];
    fetchTickets(pageNumber).then((function (param) {
            var page = param[1];
            return param[0].then((function (tickets) {
                          return Promise.resolve(Curry._1(setState, (function (param) {
                                            return /* record */[
                                                    /* loading */false,
                                                    /* showFilter */false,
                                                    /* tickets */tickets,
                                                    /* ticketId */state[/* ticketId */3],
                                                    /* currentPage */page,
                                                    /* agents */state[/* agents */5],
                                                    /* assignee */state[/* assignee */6],
                                                    /* status */state[/* status */7]
                                                  ];
                                          })));
                        }));
          }));
    return /* () */0;
  };
  var match$2 = state[/* loading */0];
  if (match$2) {
    return React.createElement("p", undefined, "Fetching tickets...");
  } else {
    var match$3 = state[/* showFilter */1];
    return React.createElement(React.Fragment, undefined, match$3 ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return toggleFilter(/* () */0);
                        }),
                      onApply: onApply,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Tickets"), React.createElement("button", {
                            className: "bg-ghostwhite border border-gray-400\n              shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "filters",
                            onClick: (function (param) {
                                return toggleFilter(/* () */0);
                              })
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.filterButton
                              }), "Filters")), React.createElement("div", {
                        className: "flex px-30-px"
                      }, React.createElement("div", {
                            className: "w-1/4 bg-ghostwhitegray border border-gray-300 rounded-l-lg",
                            style: {
                              height: "calc(100vh - 10.6rem)"
                            }
                          }, React.createElement("div", {
                                className: "border-b border-gray-300 m-1 mb-4"
                              }, React.createElement("div", {
                                    className: "search-wrapper w-full p-2"
                                  }, React.createElement("img", {
                                        className: "search-icon",
                                        src: Image$ReactHooksTemplate.search
                                      }), React.createElement("input", {
                                        className: "border border-gray-300 hover:border-gray-400 focus:border-palatinateblue\n                      outline-none py-4 text-base bg-transparent rounded-lg search-input font-normal mb-2 w-full px-1 mr-2",
                                        disabled: true,
                                        placeholder: "Search Tickets",
                                        type: "text"
                                      })), React.createElement("div", {
                                    className: "dropdown mb-4 ml-2"
                                  }, React.createElement("div", {
                                        className: "flex items-center text-sm font-normal capitalize"
                                      }, React.createElement("p", {
                                            className: "mr-5-px"
                                          }, statusToString(state[/* status */7])), React.createElement("img", {
                                            src: Image$ReactHooksTemplate.caretDown
                                          })), React.createElement("ul", {
                                        className: "dropdown-list shadow text-gray-800 text-sm"
                                      }, React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* All */0, param);
                                                  })
                                              }, "All")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Open */1, param);
                                                  })
                                              }, "Open")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Parked */2, param);
                                                  })
                                              }, "Parked")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Resolved */3, param);
                                                  })
                                              }, "Resolved")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Closed */4, param);
                                                  })
                                              }, "Closed"))))), React.createElement("div", {
                                ref: domElement,
                                className: "overflow-scroll",
                                style: {
                                  height: "calc(100vh - 19rem)"
                                },
                                onScroll: handleScroll
                              }, $$Array.of_list(Belt_List.map(state[/* tickets */2], displayTickets)))), React.createElement("div", {
                            className: "w-3/4 border border-gray-300 rounded-r-lg"
                          }, $$Array.of_list(Belt_List.map(state[/* tickets */2], displaySelectedTicket))))));
  }
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* perPage */"1"
];

var make = TicketWorker;

exports.statusFromString = statusFromString;
exports.statusToString = statusToString;
exports.totalRecord = totalRecord;
exports.page = page;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.make = make;
/* react Not a pure module */
