// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Modal$ReactHooksTemplate = require("./Modal.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var LogicGraph$ReactHooksTemplate = require("./LogicGraph.bs.js");
var LogicalJump$ReactHooksTemplate = require("./LogicalJump.bs.js");
var SurveyLogic$ReactHooksTemplate = require("./SurveyLogic.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var WelcomeEditor$ReactHooksTemplate = require("./WelcomeEditor.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var ThankYouEditor$ReactHooksTemplate = require("./ThankYouEditor.bs.js");
var SurveyConfiguration$ReactHooksTemplate = require("./SurveyConfiguration.bs.js");
var SurveyBuilderPreview$ReactHooksTemplate = require("./SurveyBuilderPreview.bs.js");
var SurveyPreviewWrapper$ReactHooksTemplate = require("./SurveyPreviewWrapper.bs.js");

var bhimaQuestiontemplate = "\n  <div class=\"h-full\">\n    <div class=\"pt-40-px w-full pb-20-px absolute\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"max-w-840-px mx-auto px-60-px min-h-screen flex flex-col\n    justify-center pt-160-px small:pt-142-px\">\n\n      <div class=\"pb-100-px\">\n        <div id=\"question\">question</div>\n      </div>\n      <div class=\"flex justify-center items-center mb-8\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var defaultQuestiontemplate = "\n  <div class=\"h-full\">\n    <div class=\"pt-40-px w-full pb-20-px\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"max-w-960-px mx-auto p-30-px min-h-screen flex flex-col\n    justify-center\">\n      <div class=\"pb-60-px\">\n        <div id=\"question\">question</div>\n      </div>\n      <div class=\"flex justify-center items-center mb-8\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var defaultQuestionBuildertemplate = "\n  <div>\n    <div>\n      <div>\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n  </div>\n";

var questiontemplates = /* record */[
  /* bhima */bhimaQuestiontemplate,
  /* default */defaultQuestiontemplate
];

var questionBuilderTemplates = /* record */[
  /* bhima */bhimaQuestiontemplate,
  /* default */defaultQuestionBuildertemplate
];

function questionsOrPageToString(param) {
  switch (param) {
    case /* Welcome */0 :
        return "welcome";
    case /* ThankYou */1 :
        return "thank_you";
    case /* LongText */2 :
        return "LongText";
    case /* ShortText */3 :
        return "ShortText";
    case /* StarRating */4 :
        return "StarRating";
    case /* OpinionScale */5 :
        return "OpinionScale";
    case /* Nps */6 :
        return "Nps";
    case /* MultipleChoice */7 :
        return "MultipleChoice";
    case /* RatingGroup */8 :
        return "RatingGroup";
    case /* SmileyRating */9 :
        return "SmileyRating";
    case /* Csat */10 :
        return "Csat";
    case /* MultipleSelect */11 :
        return "MultipleSelect";
    case /* DropDown */12 :
        return "DropDown";
    case /* MobileNumber */13 :
        return "MobileNumber";
    
  }
}

var initialAccordionState = /* record */[/* questionSettingsTab */true];

function decodeSurvey(json) {
  var name = Json_decode.field("survey", (function (param) {
          return Json_decode.field("name", Json_decode.string, param);
        }), json);
  var logo = Json_decode.field("survey", (function (param) {
          return Json_decode.field("logo", (function (param) {
                        return Json_decode.optional(Json_decode.string, param);
                      }), param);
        }), json);
  var questionFromJson = Question$ReactHooksTemplate.fromJson(json);
  var theme = Json_decode.field("survey", (function (param) {
          return Json_decode.field("theme", SurveyTheme$ReactHooksTemplate.decode, param);
        }), json);
  var thankYouList = Json_decode.field("survey", (function (param) {
          return Json_decode.field("thank_yous", (function (param) {
                        return Json_decode.list(ThankYou$ReactHooksTemplate.fromJson, param);
                      }), param);
        }), json);
  var surveyLanguages = Json_decode.field("survey", (function (param) {
          return Json_decode.field("survey_languages", (function (param) {
                        return Json_decode.list(SurveyLanguage$ReactHooksTemplate.fromJson, param);
                      }), param);
        }), json);
  return /* tuple */[
          name,
          logo,
          theme,
          Welcome$ReactHooksTemplate.fromJson(json),
          questionFromJson,
          LogicalJump$ReactHooksTemplate.fromJson(questionFromJson, json),
          thankYouList,
          surveyLanguages
        ];
}

function initialPreviewState(param) {
  if (param !== 0) {
    return /* Welcome */1;
  } else {
    return /* Empty */0;
  }
}

function addQuestionsToGraph(questions) {
  return List.fold_left((function (graph, item) {
                return LogicGraph$ReactHooksTemplate.addNode(/* Question */Block.__(1, [item]), graph);
              }), LogicGraph$ReactHooksTemplate.empty(/* () */0), questions);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* RemoveWelcomeEditorBlock */0 :
          var match = Survey$ReactHooksTemplate.welcome(state[/* survey */0]);
          var survey;
          if (match !== undefined) {
            var match$1 = Welcome$ReactHooksTemplate.id(Caml_option.valFromOption(match));
            survey = match$1 !== undefined ? Survey$ReactHooksTemplate.setDeletedWelcome(state[/* survey */0], Survey$ReactHooksTemplate.welcome(state[/* survey */0])) : state[/* survey */0];
          } else {
            survey = state[/* survey */0];
          }
          var match$2 = Survey$ReactHooksTemplate.questions(state[/* survey */0]);
          var nextQuestion;
          if (match$2) {
            nextQuestion = /* Question */Block.__(2, [match$2[0]]);
          } else {
            var match$3 = Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]);
            nextQuestion = match$3 ? /* ThankYou */Block.__(1, [match$3[0]]) : /* Empty */0;
          }
          if (typeof nextQuestion !== "number") {
            switch (nextQuestion.tag | 0) {
              case /* Welcome */0 :
                  break;
              case /* ThankYou */1 :
                  var x = nextQuestion[0];
                  var newrecord = Caml_array.caml_array_dup(state);
                  newrecord[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(x)]);
                  newrecord[/* selectedId */2] = ThankYou$ReactHooksTemplate.uuid(x);
                  newrecord[/* selected */1] = ThankYou$ReactHooksTemplate.categoryType;
                  newrecord[/* survey */0] = Survey$ReactHooksTemplate.setWelcome(survey, undefined);
                  return newrecord;
              case /* Question */2 :
                  var x$1 = nextQuestion[0];
                  var newrecord$1 = Caml_array.caml_array_dup(state);
                  newrecord$1[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
                      questionBuilderTemplates,
                      x$1,
                      CustomerInfo$ReactHooksTemplate.empty(/* () */0)
                    ]);
                  newrecord$1[/* selectedId */2] = Question$ReactHooksTemplate.uuid(x$1);
                  newrecord$1[/* selected */1] = Question$ReactHooksTemplate.categoryType(x$1);
                  newrecord$1[/* survey */0] = Survey$ReactHooksTemplate.setWelcome(survey, undefined);
                  return newrecord$1;
              
            }
          }
          var newrecord$2 = Caml_array.caml_array_dup(state);
          newrecord$2[/* builderPreview */8] = /* Empty */0;
          newrecord$2[/* selectedId */2] = RandomId$ReactHooksTemplate.generate(/* () */0);
          newrecord$2[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
          newrecord$2[/* survey */0] = Survey$ReactHooksTemplate.setWelcome(survey, undefined);
          return newrecord$2;
      case /* PreviewWelcome */1 :
          var newrecord$3 = Caml_array.caml_array_dup(state);
          newrecord$3[/* builderPreview */8] = /* Welcome */Block.__(0, [Survey$ReactHooksTemplate.welcome(state[/* survey */0])]);
          return newrecord$3;
      case /* TogglePreview */2 :
          var newrecord$4 = Caml_array.caml_array_dup(state);
          newrecord$4[/* showPreview */4] = !state[/* showPreview */4];
          return newrecord$4;
      case /* ToggleAddQuestion */3 :
          var newrecord$5 = Caml_array.caml_array_dup(state);
          newrecord$5[/* showAddQuestions */6] = !state[/* showAddQuestions */6];
          return newrecord$5;
      case /* ToggleSurveyConfiguration */4 :
          var newrecord$6 = Caml_array.caml_array_dup(state);
          newrecord$6[/* showConfigurations */14] = !state[/* showConfigurations */14];
          return newrecord$6;
      case /* AddLogicalJump */5 :
          var newrecord$7 = Caml_array.caml_array_dup(state);
          newrecord$7[/* logicalJumpGroup */7] = LogicalJump$ReactHooksTemplate.addToGroup(LogicalJump$ReactHooksTemplate.empty(/* () */0), state[/* logicalJumpGroup */7]);
          return newrecord$7;
      case /* InvalidName */6 :
          var newrecord$8 = Caml_array.caml_array_dup(state);
          newrecord$8[/* nameInvalid */3] = true;
          return newrecord$8;
      case /* ValidName */7 :
          var newrecord$9 = Caml_array.caml_array_dup(state);
          newrecord$9[/* nameInvalid */3] = false;
          return newrecord$9;
      case /* RemoveNotification */8 :
          var newrecord$10 = Caml_array.caml_array_dup(state);
          newrecord$10[/* notification */13] = undefined;
          return newrecord$10;
      
    }
  } else {
    switch (action.tag | 0) {
      case /* UpdateSurvey */0 :
          var newrecord$11 = Caml_array.caml_array_dup(state);
          newrecord$11[/* survey */0] = action[0];
          return newrecord$11;
      case /* SetClient */1 :
          var newrecord$12 = Caml_array.caml_array_dup(state);
          newrecord$12[/* client */10] = action[0];
          return newrecord$12;
      case /* AddEditorBlock */2 :
          var selected = questionsOrPageToString(action[0]);
          if (selected === Welcome$ReactHooksTemplate.categoryType) {
            var welcome = Welcome$ReactHooksTemplate.empty(/* () */0);
            var newrecord$13 = Caml_array.caml_array_dup(state);
            newrecord$13[/* builderPreview */8] = /* Welcome */Block.__(0, [Caml_option.some(welcome)]);
            newrecord$13[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
            newrecord$13[/* survey */0] = Survey$ReactHooksTemplate.setWelcome(state[/* survey */0], Caml_option.some(welcome));
            return newrecord$13;
          } else if (selected === ThankYou$ReactHooksTemplate.categoryType) {
            var thankYou = ThankYou$ReactHooksTemplate.empty(/* () */0);
            var thankYouList = Belt_List.concat(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), /* :: */[
                  thankYou,
                  /* [] */0
                ]);
            var noOfThankYous = Belt_List.length(thankYouList);
            var addedThankYouEl = List.nth(thankYouList, noOfThankYous - 1 | 0);
            var newrecord$14 = Caml_array.caml_array_dup(state);
            newrecord$14[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(thankYou)]);
            newrecord$14[/* selectedId */2] = ThankYou$ReactHooksTemplate.uuid(addedThankYouEl);
            newrecord$14[/* selected */1] = ThankYou$ReactHooksTemplate.categoryType;
            newrecord$14[/* survey */0] = Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], thankYouList);
            return newrecord$14;
          } else {
            var question = Question$ReactHooksTemplate.fromCategoryType(selected);
            var questions = Belt_List.concat(Survey$ReactHooksTemplate.questions(state[/* survey */0]), Belt_Option.mapWithDefault(question, /* [] */0, (function (x) {
                        return /* :: */[
                                x,
                                /* [] */0
                              ];
                      })));
            var graph$prime = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state[/* survey */0]), questions, Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), addQuestionsToGraph(questions)));
            var noOfQuestions = Belt_List.length(questions);
            var addedQuestionEl = List.nth(questions, noOfQuestions - 1 | 0);
            var newrecord$15 = Caml_array.caml_array_dup(state);
            newrecord$15[/* graph */9] = graph$prime;
            newrecord$15[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
                questionBuilderTemplates,
                question,
                CustomerInfo$ReactHooksTemplate.empty(/* () */0)
              ]);
            newrecord$15[/* selectedId */2] = Question$ReactHooksTemplate.uuid(addedQuestionEl);
            newrecord$15[/* selected */1] = selected;
            newrecord$15[/* survey */0] = Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions);
            return newrecord$15;
          }
      case /* AddEditorBlockInBetween */3 :
          var index = action[0];
          var selected$1 = questionsOrPageToString(action[1]);
          if (selected$1 === Welcome$ReactHooksTemplate.categoryType) {
            var welcome$1 = Welcome$ReactHooksTemplate.empty(/* () */0);
            var newrecord$16 = Caml_array.caml_array_dup(state);
            newrecord$16[/* builderPreview */8] = /* Welcome */Block.__(0, [Caml_option.some(welcome$1)]);
            newrecord$16[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
            newrecord$16[/* survey */0] = Survey$ReactHooksTemplate.setWelcome(state[/* survey */0], Caml_option.some(welcome$1));
            return newrecord$16;
          } else if (selected$1 === ThankYou$ReactHooksTemplate.categoryType) {
            var thankYou$1 = ThankYou$ReactHooksTemplate.empty(/* () */0);
            console.log(index);
            var match$4 = Belt_List.splitAt(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), index);
            var thankYouList$1;
            if (match$4 !== undefined) {
              var match$5 = match$4;
              thankYouList$1 = Belt_List.concatMany(/* array */[
                    match$5[0],
                    /* :: */[
                      thankYou$1,
                      /* [] */0
                    ],
                    match$5[1]
                  ]);
            } else {
              thankYouList$1 = Belt_List.concat(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), /* :: */[
                    thankYou$1,
                    /* [] */0
                  ]);
            }
            var addedThankYouEl$1 = List.nth(thankYouList$1, index + 1 | 0);
            var newrecord$17 = Caml_array.caml_array_dup(state);
            newrecord$17[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(addedThankYouEl$1)]);
            newrecord$17[/* selectedId */2] = ThankYou$ReactHooksTemplate.uuid(addedThankYouEl$1);
            newrecord$17[/* selected */1] = ThankYou$ReactHooksTemplate.categoryType;
            newrecord$17[/* survey */0] = Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], thankYouList$1);
            return newrecord$17;
          } else {
            var question$1 = Question$ReactHooksTemplate.fromCategoryType(selected$1);
            var match$6 = Belt_List.splitAt(Survey$ReactHooksTemplate.questions(state[/* survey */0]), index);
            var questions$1;
            if (match$6 !== undefined) {
              var match$7 = match$6;
              questions$1 = Belt_List.concatMany(/* array */[
                    match$7[0],
                    Belt_Option.mapWithDefault(question$1, /* [] */0, (function (x) {
                            return /* :: */[
                                    x,
                                    /* [] */0
                                  ];
                          })),
                    match$7[1]
                  ]);
            } else {
              questions$1 = Belt_List.concat(Survey$ReactHooksTemplate.questions(state[/* survey */0]), Belt_Option.mapWithDefault(question$1, /* [] */0, (function (x) {
                          return /* :: */[
                                  x,
                                  /* [] */0
                                ];
                        })));
            }
            var graph$prime$1 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$1, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state[/* survey */0]), questions$1, Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), addQuestionsToGraph(questions$1)));
            var addedQuestionEl$1 = List.nth(questions$1, index);
            var newrecord$18 = Caml_array.caml_array_dup(state);
            newrecord$18[/* graph */9] = graph$prime$1;
            newrecord$18[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
                questionBuilderTemplates,
                addedQuestionEl$1,
                CustomerInfo$ReactHooksTemplate.empty(/* () */0)
              ]);
            newrecord$18[/* selectedId */2] = Question$ReactHooksTemplate.uuid(addedQuestionEl$1);
            newrecord$18[/* selected */1] = selected$1;
            newrecord$18[/* survey */0] = Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions$1);
            return newrecord$18;
          }
      case /* RemoveThankYouEditorBlock */4 :
          var thankYou$2 = action[1];
          var index$1 = action[0];
          var match$8 = ThankYou$ReactHooksTemplate.id(thankYou$2);
          var deletedThankYouList = match$8 !== undefined ? Belt_List.concat(Survey$ReactHooksTemplate.deletedThankYouList(state[/* survey */0]), /* :: */[
                  thankYou$2,
                  /* [] */0
                ]) : Survey$ReactHooksTemplate.deletedThankYouList(state[/* survey */0]);
          var match$9 = Belt_List.get(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), index$1 + 1 | 0);
          var nextQuestion$1;
          if (match$9 !== undefined) {
            nextQuestion$1 = /* ThankYou */Block.__(1, [Caml_option.valFromOption(match$9)]);
          } else {
            var match$10 = Belt_List.get(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), index$1 - 1 | 0);
            if (match$10 !== undefined) {
              nextQuestion$1 = /* ThankYou */Block.__(1, [Caml_option.valFromOption(match$10)]);
            } else {
              var length = Belt_List.length(Survey$ReactHooksTemplate.questions(state[/* survey */0]));
              var match$11 = Survey$ReactHooksTemplate.questions(state[/* survey */0]);
              if (match$11) {
                var match$12 = Belt_List.get(Survey$ReactHooksTemplate.questions(state[/* survey */0]), length - 1 | 0);
                if (match$12 !== undefined) {
                  nextQuestion$1 = /* Question */Block.__(2, [match$12]);
                } else {
                  var match$13 = Survey$ReactHooksTemplate.welcome(state[/* survey */0]);
                  nextQuestion$1 = match$13 !== undefined ? /* Welcome */Block.__(0, [Caml_option.valFromOption(match$13)]) : /* Empty */0;
                }
              } else {
                var match$14 = Survey$ReactHooksTemplate.welcome(state[/* survey */0]);
                nextQuestion$1 = match$14 !== undefined ? /* Welcome */Block.__(0, [Caml_option.valFromOption(match$14)]) : /* Empty */0;
              }
            }
          }
          if (typeof nextQuestion$1 === "number") {
            var newrecord$19 = Caml_array.caml_array_dup(state);
            newrecord$19[/* builderPreview */8] = /* Empty */0;
            newrecord$19[/* selectedId */2] = RandomId$ReactHooksTemplate.generate(/* () */0);
            newrecord$19[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
            newrecord$19[/* survey */0] = Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), (function (x) {
                            return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                          }))), deletedThankYouList);
            return newrecord$19;
          } else {
            switch (nextQuestion$1.tag | 0) {
              case /* Welcome */0 :
                  var newrecord$20 = Caml_array.caml_array_dup(state);
                  newrecord$20[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
                  newrecord$20[/* survey */0] = Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), (function (x) {
                                  return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                }))), deletedThankYouList);
                  return newrecord$20;
              case /* ThankYou */1 :
                  var x$2 = nextQuestion$1[0];
                  var newrecord$21 = Caml_array.caml_array_dup(state);
                  newrecord$21[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(x$2)]);
                  newrecord$21[/* selectedId */2] = ThankYou$ReactHooksTemplate.uuid(x$2);
                  newrecord$21[/* selected */1] = ThankYou$ReactHooksTemplate.categoryType;
                  newrecord$21[/* survey */0] = Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), (function (x) {
                                  return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                }))), deletedThankYouList);
                  return newrecord$21;
              case /* Question */2 :
                  var x$3 = nextQuestion$1[0];
                  var newrecord$22 = Caml_array.caml_array_dup(state);
                  newrecord$22[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
                      questionBuilderTemplates,
                      x$3,
                      CustomerInfo$ReactHooksTemplate.empty(/* () */0)
                    ]);
                  newrecord$22[/* selectedId */2] = Question$ReactHooksTemplate.uuid(x$3);
                  newrecord$22[/* selected */1] = Question$ReactHooksTemplate.categoryType(x$3);
                  newrecord$22[/* survey */0] = Survey$ReactHooksTemplate.setDeletedThankYouList(Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], Belt_List.keep(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), (function (x) {
                                  return Caml_obj.caml_notequal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$2));
                                }))), deletedThankYouList);
                  return newrecord$22;
              
            }
          }
      case /* RemoveQuestionEditorBlock */5 :
          var question$2 = action[1];
          var index$2 = action[0];
          var match$15 = Question$ReactHooksTemplate.id(question$2);
          var deletedQuestions = match$15 !== undefined ? Belt_List.concat(Survey$ReactHooksTemplate.deletedQuestions(state[/* survey */0]), /* :: */[
                  question$2,
                  /* [] */0
                ]) : Survey$ReactHooksTemplate.deletedQuestions(state[/* survey */0]);
          var questions$prime = Belt_List.keep(Survey$ReactHooksTemplate.questions(state[/* survey */0]), (function (x) {
                  return Caml_obj.caml_notequal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question$2));
                }));
          var graph$prime$2 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$prime, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state[/* survey */0]), questions$prime, Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), addQuestionsToGraph(questions$prime)));
          var match$16 = Belt_List.get(Survey$ReactHooksTemplate.questions(state[/* survey */0]), index$2 + 1 | 0);
          var nextQuestion$2;
          if (match$16 !== undefined) {
            nextQuestion$2 = /* Question */Block.__(2, [match$16]);
          } else {
            var match$17 = Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]);
            if (match$17) {
              nextQuestion$2 = /* ThankYou */Block.__(1, [match$17[0]]);
            } else {
              var match$18 = Belt_List.get(Survey$ReactHooksTemplate.questions(state[/* survey */0]), index$2 - 1 | 0);
              if (match$18 !== undefined) {
                nextQuestion$2 = /* Question */Block.__(2, [match$18]);
              } else {
                var match$19 = Survey$ReactHooksTemplate.welcome(state[/* survey */0]);
                nextQuestion$2 = match$19 !== undefined ? /* Welcome */Block.__(0, [Caml_option.valFromOption(match$19)]) : /* Empty */0;
              }
            }
          }
          if (typeof nextQuestion$2 === "number") {
            var newrecord$23 = Caml_array.caml_array_dup(state);
            newrecord$23[/* graph */9] = graph$prime$2;
            newrecord$23[/* builderPreview */8] = /* Empty */0;
            newrecord$23[/* selectedId */2] = RandomId$ReactHooksTemplate.generate(/* () */0);
            newrecord$23[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
            newrecord$23[/* survey */0] = Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions$prime), deletedQuestions);
            return newrecord$23;
          } else {
            switch (nextQuestion$2.tag | 0) {
              case /* Welcome */0 :
                  var newrecord$24 = Caml_array.caml_array_dup(state);
                  newrecord$24[/* graph */9] = graph$prime$2;
                  newrecord$24[/* selected */1] = Welcome$ReactHooksTemplate.categoryType;
                  newrecord$24[/* survey */0] = Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions$prime), deletedQuestions);
                  return newrecord$24;
              case /* ThankYou */1 :
                  var x$4 = nextQuestion$2[0];
                  var newrecord$25 = Caml_array.caml_array_dup(state);
                  newrecord$25[/* graph */9] = graph$prime$2;
                  newrecord$25[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(x$4)]);
                  newrecord$25[/* selectedId */2] = ThankYou$ReactHooksTemplate.uuid(x$4);
                  newrecord$25[/* selected */1] = ThankYou$ReactHooksTemplate.categoryType;
                  newrecord$25[/* survey */0] = Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions$prime), deletedQuestions);
                  return newrecord$25;
              case /* Question */2 :
                  var x$5 = nextQuestion$2[0];
                  var newrecord$26 = Caml_array.caml_array_dup(state);
                  newrecord$26[/* graph */9] = graph$prime$2;
                  newrecord$26[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
                      questionBuilderTemplates,
                      x$5,
                      CustomerInfo$ReactHooksTemplate.empty(/* () */0)
                    ]);
                  newrecord$26[/* selectedId */2] = Question$ReactHooksTemplate.uuid(x$5);
                  newrecord$26[/* selected */1] = Question$ReactHooksTemplate.categoryType(x$5);
                  newrecord$26[/* survey */0] = Survey$ReactHooksTemplate.setDeletedQuestions(Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions$prime), deletedQuestions);
                  return newrecord$26;
              
            }
          }
      case /* SetSelected */6 :
          var newrecord$27 = Caml_array.caml_array_dup(state);
          newrecord$27[/* selected */1] = action[0];
          return newrecord$27;
      case /* SetSelectedId */7 :
          var newrecord$28 = Caml_array.caml_array_dup(state);
          newrecord$28[/* selectedId */2] = action[0];
          return newrecord$28;
      case /* UpdateWelcome */8 :
          var welcome$2 = action[0];
          var newrecord$29 = Caml_array.caml_array_dup(state);
          newrecord$29[/* builderPreview */8] = /* Welcome */Block.__(0, [Caml_option.some(welcome$2)]);
          newrecord$29[/* survey */0] = Survey$ReactHooksTemplate.setWelcome(state[/* survey */0], Caml_option.some(welcome$2));
          return newrecord$29;
      case /* UpdateThankYou */9 :
          var thankYou$3 = action[0];
          var thankYouList$prime = Belt_List.map(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), (function (x) {
                  var match = Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(thankYou$3));
                  if (match) {
                    return thankYou$3;
                  } else {
                    return x;
                  }
                }));
          var newrecord$30 = Caml_array.caml_array_dup(state);
          newrecord$30[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(thankYou$3)]);
          newrecord$30[/* survey */0] = Survey$ReactHooksTemplate.setThankYouList(state[/* survey */0], thankYouList$prime);
          return newrecord$30;
      case /* UpdateQuestion */10 :
          var question$3 = action[0];
          var questions$prime$1 = Belt_List.map(Survey$ReactHooksTemplate.questions(state[/* survey */0]), (function (x) {
                  var match = Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question$3));
                  if (match) {
                    return question$3;
                  } else {
                    return x;
                  }
                }));
          var graph$prime$3 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$prime$1, SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(state[/* survey */0]), questions$prime$1, Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]), addQuestionsToGraph(questions$prime$1)));
          var newrecord$31 = Caml_array.caml_array_dup(state);
          newrecord$31[/* graph */9] = graph$prime$3;
          newrecord$31[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
              questionBuilderTemplates,
              question$3,
              CustomerInfo$ReactHooksTemplate.empty(/* () */0)
            ]);
          newrecord$31[/* survey */0] = Survey$ReactHooksTemplate.setQuestions(state[/* survey */0], questions$prime$1);
          return newrecord$31;
      case /* PreviewQuestion */11 :
          var newrecord$32 = Caml_array.caml_array_dup(state);
          newrecord$32[/* builderPreview */8] = /* QuestionPage */Block.__(1, [
              questionBuilderTemplates,
              action[0],
              CustomerInfo$ReactHooksTemplate.empty(/* () */0)
            ]);
          return newrecord$32;
      case /* PreviewThankYou */12 :
          var newrecord$33 = Caml_array.caml_array_dup(state);
          newrecord$33[/* builderPreview */8] = /* ThankYou */Block.__(2, [Caml_option.some(action[0])]);
          return newrecord$33;
      case /* ShowWelcomeThankYou */13 :
          var newrecord$34 = Caml_array.caml_array_dup(state);
          newrecord$34[/* showThankyou */17] = action[1];
          newrecord$34[/* showWelcome */16] = action[0];
          return newrecord$34;
      case /* AddLogo */14 :
          var newrecord$35 = Caml_array.caml_array_dup(state);
          newrecord$35[/* survey */0] = Survey$ReactHooksTemplate.setLogo(state[/* survey */0], action[0]);
          return newrecord$35;
      case /* ReplaceLogic */15 :
          var newrecord$36 = Caml_array.caml_array_dup(state);
          newrecord$36[/* logicalJumpGroup */7] = LogicalJump$ReactHooksTemplate.replaceInGroup(action[0], state[/* logicalJumpGroup */7]);
          return newrecord$36;
      case /* ChangeLanguage */16 :
          var newrecord$37 = Caml_array.caml_array_dup(state);
          newrecord$37[/* language */11] = action[0];
          return newrecord$37;
      case /* AddNotification */17 :
          var newrecord$38 = Caml_array.caml_array_dup(state);
          newrecord$38[/* notification */13] = action[0];
          return newrecord$38;
      case /* SetOutsideClick */18 :
          var newrecord$39 = Caml_array.caml_array_dup(state);
          newrecord$39[/* outsideClick */15] = action[0];
          return newrecord$39;
      case /* SetAddQuestionBelow */19 :
          var newrecord$40 = Caml_array.caml_array_dup(state);
          newrecord$40[/* addQuestionBelow */18] = action[0];
          return newrecord$40;
      case /* SetAddThankYouToTop */20 :
          var newrecord$41 = Caml_array.caml_array_dup(state);
          newrecord$41[/* addThankYouToTop */20] = action[0];
          return newrecord$41;
      case /* SetToBeAddedBelowIndex */21 :
          var newrecord$42 = Caml_array.caml_array_dup(state);
          newrecord$42[/* toBeAddedBelowIndex */19] = action[0];
          return newrecord$42;
      
    }
  }
}

function SurveyForm(Props) {
  var survey = Props.survey;
  Props.projectId;
  var initialState;
  if (survey !== undefined) {
    var survey$1 = survey;
    initialState = /* record */[
      /* survey */survey$1,
      /* selected */Welcome$ReactHooksTemplate.categoryType,
      /* selectedId */RandomId$ReactHooksTemplate.generate(/* () */0),
      /* nameInvalid */Survey$ReactHooksTemplate.name(survey$1).length === 0,
      /* showPreview */false,
      /* showSurveyNameInput */false,
      /* showAddQuestions */false,
      /* logicalJumpGroup */LogicalJump$ReactHooksTemplate.makeGroup(/* () */0),
      /* builderPreview : Welcome */Block.__(0, [Survey$ReactHooksTemplate.welcome(survey$1)]),
      /* graph */SurveyLogic$ReactHooksTemplate.addLogicToEdges(Survey$ReactHooksTemplate.questions(survey$1), SurveyLogic$ReactHooksTemplate.changeEdges(Survey$ReactHooksTemplate.name(survey$1), Survey$ReactHooksTemplate.questions(survey$1), Survey$ReactHooksTemplate.thankYouList(survey$1), addQuestionsToGraph(Survey$ReactHooksTemplate.questions(survey$1)))),
      /* client */Client$ReactHooksTemplate.empty(/* () */0),
      /* language */SurveyLanguage$ReactHooksTemplate.$$default,
      /* surveyLanguages : [] */0,
      /* notification */undefined,
      /* showConfigurations */false,
      /* outsideClick */true,
      /* showWelcome */true,
      /* showThankyou */true,
      /* addQuestionBelow */false,
      /* toBeAddedBelowIndex */0,
      /* addThankYouToTop */false
    ];
  } else {
    initialState = /* record */[
      /* survey */Survey$ReactHooksTemplate.empty(/* () */0),
      /* selected */Welcome$ReactHooksTemplate.categoryType,
      /* selectedId */RandomId$ReactHooksTemplate.generate(/* () */0),
      /* nameInvalid */false,
      /* showPreview */false,
      /* showSurveyNameInput */false,
      /* showAddQuestions */false,
      /* logicalJumpGroup */LogicalJump$ReactHooksTemplate.makeGroup(/* () */0),
      /* builderPreview : Empty */0,
      /* graph */LogicGraph$ReactHooksTemplate.empty(/* () */0),
      /* client */Client$ReactHooksTemplate.empty(/* () */0),
      /* language */SurveyLanguage$ReactHooksTemplate.$$default,
      /* surveyLanguages : [] */0,
      /* notification */undefined,
      /* showConfigurations */false,
      /* outsideClick */true,
      /* showWelcome */true,
      /* showThankyou */true,
      /* addQuestionBelow */false,
      /* toBeAddedBelowIndex */0,
      /* addThankYouToTop */false
    ];
  }
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return /* Loading */0;
        }));
  var setMode = match$1[1];
  var match$2 = React.useState((function () {
          return initialAccordionState;
        }));
  var setAccordionState = match$2[1];
  var accordionState = match$2[0];
  var saveForm = function (survey) {
    var params = { };
    params["survey"] = Survey$ReactHooksTemplate.toJson(survey);
    var match = Survey$ReactHooksTemplate.id(survey);
    var tmp;
    if (match !== undefined) {
      var surveyId = String(match);
      tmp = Http$ReactHooksTemplate.fetchPutJSON("/api/v1/surveys/" + (String(surveyId) + ""), params);
    } else {
      tmp = Http$ReactHooksTemplate.fetchPostJSON("/api/v1/surveys", params);
    }
    return tmp.then((function (response) {
                  var status = Json_decode.field("status", (function (param) {
                          return Json_decode.withDefault("failed", Json_decode.string, param);
                        }), response);
                  if (status === "success") {
                    console.log(status);
                    Curry._1(dispatch, /* UpdateSurvey */Block.__(0, [Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, response)]));
                    Curry._1(dispatch, /* AddNotification */Block.__(17, [/* tuple */[
                              "Updated the survey",
                              false
                            ]]));
                  } else {
                    Curry._1(dispatch, /* AddNotification */Block.__(17, [/* tuple */[
                              "Something went wrong. Please contact admin",
                              true
                            ]]));
                  }
                  return Promise.resolve(/* () */0);
                }));
  };
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/clients/0", (function (param) {
                    return Json_decode.field("client", Client$ReactHooksTemplate.decode, param);
                  })).then((function (client) {
                  Curry._1(dispatch, /* SetClient */Block.__(1, [client]));
                  return Promise.resolve(Curry._1(setMode, (function (param) {
                                    return /* Ready */1;
                                  })));
                }));
          return ;
        }), ([]));
  var handleSaveForm = function ($$event) {
    $$event.preventDefault();
    if (Survey$ReactHooksTemplate.name(state[/* survey */0]).length === 0) {
      return Curry._1(dispatch, /* InvalidName */6);
    } else {
      Curry._1(setMode, (function (param) {
              return /* Saving */2;
            }));
      saveForm(state[/* survey */0]).then((function (param) {
              return Promise.resolve(Curry._1(setMode, (function (param) {
                                return /* Ready */1;
                              })));
            }));
      return /* () */0;
    }
  };
  var surveyBuilderPreview = function (param) {
    var match = state[/* builderPreview */8];
    var page;
    page = typeof match === "number" || !(match.tag === /* QuestionPage */1 && match[1] === undefined) ? state[/* builderPreview */8] : /* ThankYou */Block.__(2, [Belt_List.head(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0]))]);
    return React.createElement(React.Fragment, undefined, React.createElement(SurveyBuilderPreview$ReactHooksTemplate.make, {
                    page: page,
                    theme: Survey$ReactHooksTemplate.surveyTheme(state[/* survey */0]),
                    client: state[/* client */10],
                    handleQuestionPreview: (function (y) {
                        return Curry._1(dispatch, /* UpdateQuestion */Block.__(10, [y]));
                      }),
                    onWelcomeChange: (function (welcome) {
                        return Curry._1(dispatch, /* UpdateWelcome */Block.__(8, [welcome]));
                      }),
                    onThankYouChange: (function (thankYou) {
                        return Curry._1(dispatch, /* UpdateThankYou */Block.__(9, [thankYou]));
                      }),
                    handleFormOnSubmit: (function (param) {
                        return /* () */0;
                      }),
                    showPreviousButton: false,
                    showNextButton: false,
                    showSubmitButton: false,
                    languageSelected: state[/* language */11]
                  }));
  };
  var handleQuestionPageButtonClick = function (questionOrPage) {
    var match = questionsOrPageToString(questionOrPage);
    if (match === "thank_you") {
      var match$1 = state[/* addQuestionBelow */18];
      if (match$1) {
        var match$2 = state[/* addThankYouToTop */20];
        Curry._1(dispatch, /* AddEditorBlockInBetween */Block.__(3, [
                match$2 ? 0 : state[/* toBeAddedBelowIndex */19],
                questionOrPage
              ]));
      } else {
        Curry._1(dispatch, /* AddEditorBlock */Block.__(2, [questionOrPage]));
      }
    } else {
      var match$3 = state[/* addQuestionBelow */18];
      if (match$3) {
        Curry._1(dispatch, /* AddEditorBlockInBetween */Block.__(3, [
                state[/* toBeAddedBelowIndex */19],
                questionOrPage
              ]));
      } else {
        Curry._1(dispatch, /* AddEditorBlock */Block.__(2, [questionOrPage]));
      }
    }
    return Curry._1(dispatch, /* ToggleAddQuestion */3);
  };
  var welcomeEditorBlock = Belt_Option.mapWithDefault(Survey$ReactHooksTemplate.welcome(state[/* survey */0]), null, (function (welcome) {
          return React.createElement("div", {
                      className: "p-6",
                      onFocus: (function (param) {
                          return Curry._1(dispatch, /* PreviewWelcome */1);
                        })
                    }, React.createElement("div", undefined, React.createElement(WelcomeEditor$ReactHooksTemplate.make, {
                              welcome: welcome,
                              onWelcomeChange: (function (welcome) {
                                  return Curry._1(dispatch, /* UpdateWelcome */Block.__(8, [welcome]));
                                })
                            })));
        }));
  var welcomeListBlock = Belt_Option.mapWithDefault(Survey$ReactHooksTemplate.welcome(state[/* survey */0]), null, (function (welcome) {
          var match = state[/* selected */1] === Welcome$ReactHooksTemplate.categoryType;
          var match$1 = Welcome$ReactHooksTemplate.title(welcome) === "";
          var match$2 = state[/* selected */1] === Welcome$ReactHooksTemplate.categoryType && state[/* outsideClick */15] === false;
          return React.createElement("div", {
                      className: "p-3 border-b border-gray-400 mx-4 my-12-px flex rounded border bg-gray-100\n           hover:bg-alicebluesolid h-56-px justify-between " + (
                        match ? "bg-lavenderweb" : ""
                      ),
                      onClick: (function (param) {
                          Curry._1(dispatch, /* SetSelected */Block.__(6, [Welcome$ReactHooksTemplate.categoryType]));
                          return Curry._1(dispatch, /* PreviewWelcome */1);
                        })
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("div", {
                              className: "flex items-center text-sm text-darkcharcoal font-normal"
                            }, React.createElement("img", {
                                  className: "mr-2",
                                  src: Image$ReactHooksTemplate.dragHandle
                                }), React.createElement("div", {
                                  className: "mr-3 text-aurometalsaurus text-xs"
                                }, "1")), React.createElement("div", {
                              className: "flex flex-col items-center gap-1 justify-items-start"
                            }, match$1 ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                    className: "w-full"
                                  }, React.createElement("div", {
                                        className: "truncate max-w-150-px float-left text-darkcharcoal text-sm font-normal ml-px"
                                      }, Welcome$ReactHooksTemplate.title(welcome))), React.createElement("div", {
                                  className: "w-full"
                                }, React.createElement("div", {
                                      className: "bg-magnolia px-2 py-1 text-10-px font-light text-darkcharcoal rounded float-left"
                                    }, "Welcome")))), React.createElement("div", {
                          className: "flex justify-end items-center relative"
                        }, React.createElement("img", {
                              className: "h-18-px",
                              src: Image$ReactHooksTemplate.verticalDots,
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [false]));
                                })
                            }), React.createElement("div", {
                              className: "flex flex-wrap items-search p-2 absolute rounded border border-gray-400 bg-white\n                          text-darkcharcoal text-xs question-dropdown z-50 opacity-100 w-40 gap-2 " + (
                                match$2 ? "block" : "hidden"
                              )
                            }, React.createElement("a", {
                                  className: "flex text-xs gap-2"
                                }, React.createElement("img", {
                                      className: "h-12-px",
                                      src: Image$ReactHooksTemplate.plusCircle
                                    }), "Duplicate"), React.createElement("a", {
                                  className: "flex text-xs gap-2",
                                  onClick: (function (param) {
                                      Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                      Curry._1(dispatch, /* ShowWelcomeThankYou */Block.__(13, [
                                              false,
                                              Belt_List.length(Survey$ReactHooksTemplate.questions(state[/* survey */0])) === 0
                                            ]));
                                      Curry._1(dispatch, /* SetAddQuestionBelow */Block.__(19, [true]));
                                      Curry._1(dispatch, /* SetToBeAddedBelowIndex */Block.__(21, [0]));
                                      return Curry._1(dispatch, /* ToggleAddQuestion */3);
                                    })
                                }, React.createElement("img", {
                                      className: "h-12-px",
                                      src: Image$ReactHooksTemplate.plusCircle
                                    }), "Add question below"), React.createElement("a", {
                                  className: "flex text-xs gap-2",
                                  href: "#",
                                  onClick: (function ($$event) {
                                      $$event.preventDefault();
                                      $$event.stopPropagation();
                                      Curry._1(dispatch, /* RemoveWelcomeEditorBlock */0);
                                      return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                    })
                                }, React.createElement("img", {
                                      className: "h-12-px",
                                      src: Image$ReactHooksTemplate.trash
                                    }), "Delete"))));
        }));
  var thankYouEditorBlock = function (thankYou) {
    var id = ThankYou$ReactHooksTemplate.uuid(thankYou);
    return React.createElement("div", {
                key: RandomId$ReactHooksTemplate.toString(id),
                className: "p-6",
                onFocus: (function (param) {
                    return Curry._1(dispatch, /* PreviewThankYou */Block.__(12, [thankYou]));
                  })
              }, React.createElement(ThankYouEditor$ReactHooksTemplate.make, {
                    thankYou: thankYou,
                    onThankYouChange: (function (thankYou) {
                        return Curry._1(dispatch, /* UpdateThankYou */Block.__(9, [thankYou]));
                      })
                  }));
  };
  var questionFormItem = function (x) {
    var id = Question$ReactHooksTemplate.uuid(x);
    return React.createElement("div", {
                key: RandomId$ReactHooksTemplate.toString(id),
                className: "p-6",
                onFocus: (function (param) {
                    return Curry._1(dispatch, /* PreviewQuestion */Block.__(11, [x]));
                  })
              }, Question$ReactHooksTemplate.toEditorBlock((function (x) {
                      return Curry._1(dispatch, /* UpdateQuestion */Block.__(10, [x]));
                    }), x));
  };
  var questionsModal = function (showWelcome, showThankYou) {
    var match = state[/* showAddQuestions */6];
    return React.createElement("div", {
                className: "flex flex-wrap items-stretch shadow-md border absolute z-50 w-640-px bg-white\n          opacity-100 gap-2 rounded  " + (
                  match ? "block" : "hidden"
                )
              }, React.createElement("div", {
                    className: "p-5 w-full flex border-b border-gray-300 justify-between"
                  }, React.createElement("p", {
                        className: "text-base"
                      }, "Choose a question"), React.createElement("img", {
                        className: "h-3",
                        src: Image$ReactHooksTemplate.closeIcon,
                        onClick: (function (param) {
                            return Curry._1(dispatch, /* ToggleAddQuestion */3);
                          })
                      })), React.createElement("div", {
                    className: "relative w-full px-5"
                  }, React.createElement("img", {
                        className: "absolute search-icon-questions",
                        src: Image$ReactHooksTemplate.search
                      }), React.createElement("input", {
                        className: "h-32-px py-2 px-8 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                        placeholder: "Search Question Types",
                        type: "text"
                      })), React.createElement("div", undefined, React.createElement("div", {
                        className: "border-b border-gray-300 mx-5 pb-5"
                      }, React.createElement("div", {
                            className: "text-xs text-aurometalsaurus py-6"
                          }, "Frequently used"), React.createElement("div", {
                            className: "flex flex-wrap justify-items-center"
                          }, React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center " + (
                                  showWelcome ? "" : "disabled-text"
                                ),
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* Welcome */0);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.welcomeThankyou
                                  }), "Welcome page"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* Nps */6);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.opinionScale
                                  }), "NPS"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* MultipleChoice */7);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.radioButtonChecked
                                  }), "Multiple Choice"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* ShortText */3);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.shortText
                                  }), "Short Text"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* LongText */2);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.longText
                                  }), "Long Text"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* StarRating */4);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.rating
                                  }), "Star Rating"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center " + (
                                  showThankYou ? "" : "disabled-text"
                                ),
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* ThankYou */1);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.welcomeThankyou
                                  }), "Thank you page"))), React.createElement("div", {
                        className: "mx-5 pb-5"
                      }, React.createElement("div", {
                            className: "text-xs text-aurometalsaurus py-6"
                          }, "Others"), React.createElement("div", {
                            className: "flex flex-wrap justify-items-center"
                          }, React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* RatingGroup */8);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.rating
                                  }), "Rating Group"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* OpinionScale */5);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.opinionScale
                                  }), "Opinion Scale"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* SmileyRating */9);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.rating
                                  }), "Smiley Rating"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* Csat */10);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.defaultQuestion
                                  }), "CSAT"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* MultipleSelect */11);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.radioButtonChecked
                                  }), "Multiple Select"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* DropDown */12);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.defaultQuestion
                                  }), "Drop Down"), React.createElement("button", {
                                className: "py-5 text-sm border-b border-white hover:border-gray-300 mr-8 text-left flex items-center",
                                onClick: (function (param) {
                                    return handleQuestionPageButtonClick(/* MobileNumber */13);
                                  })
                              }, React.createElement("img", {
                                    className: "mr-2 h-3",
                                    src: Image$ReactHooksTemplate.shortText
                                  }), "MobileNumber")))));
  };
  var addQuestionButton = React.createElement("div", {
        className: "relative"
      }, React.createElement("button", {
            className: "bg-ghostwhite border border-gray-400\n           flex rounded p-2 text-sm",
            onClick: (function (param) {
                Curry._1(dispatch, /* SetAddQuestionBelow */Block.__(19, [false]));
                Curry._1(dispatch, /* SetAddThankYouToTop */Block.__(20, [false]));
                return Curry._1(dispatch, /* ToggleAddQuestion */3);
              })
          }, React.createElement("div", {
                className: "flex items-center justify-between gap-1 text-darkcharcoal"
              }, React.createElement("img", {
                    src: Image$ReactHooksTemplate.plusCircle
                  }), React.createElement("p", undefined, "Add question"))), questionsModal(state[/* showWelcome */16], state[/* showThankyou */17]));
  var match$3 = accordionState[/* questionSettingsTab */0];
  var match$4 = accordionState[/* questionSettingsTab */0];
  var editor = React.createElement("div", undefined, React.createElement("div", {
            className: "text-darkcharcoal font-medium text-base flex items-center p-6 justify-between",
            onClick: (function (param) {
                return Curry._1(setAccordionState, (function (accordionState) {
                              return /* record */[/* questionSettingsTab */!accordionState[/* questionSettingsTab */0]];
                            }));
              })
          }, React.createElement("div", {
                className: "flex gap-1"
              }, React.createElement("img", {
                    src: Image$ReactHooksTemplate.settings
                  }), "Question Settings"), match$3 ? React.createElement("img", {
                  className: "h-7-px filter-gray-400",
                  src: Image$ReactHooksTemplate.chevronDown
                }) : React.createElement("img", {
                  className: "h-7-px",
                  src: Image$ReactHooksTemplate.chevronUp
                })), match$4 ? (
          state[/* selected */1] === Welcome$ReactHooksTemplate.categoryType ? welcomeEditorBlock : (
              state[/* selected */1] === ThankYou$ReactHooksTemplate.categoryType ? $$Array.of_list(List.map(thankYouEditorBlock, List.filter((function (x) {
                                  return ThankYou$ReactHooksTemplate.uuid(x) === state[/* selectedId */2];
                                }))(Survey$ReactHooksTemplate.thankYouList(state[/* survey */0])))) : $$Array.of_list(List.map(questionFormItem, List.filter((function (x) {
                                  return Question$ReactHooksTemplate.uuid(x) === state[/* selectedId */2];
                                }))(Survey$ReactHooksTemplate.questions(state[/* survey */0]))))
            )
        ) : React.createElement(React.Fragment, undefined));
  var questionList = React.createElement("div", {
        className: "overflow-y-auto pb-12"
      }, welcomeListBlock, React.createElement("div", {
            className: "list-decimal"
          }, $$Array.of_list(List.mapi((function (i) {
                      return (function (param) {
                          var index = i;
                          var x = param;
                          var match = state[/* survey */0][/* welcome */4];
                          var slNo = match !== undefined ? index + 2 | 0 : index + 1 | 0;
                          var id = Question$ReactHooksTemplate.uuid(x);
                          var match$1 = state[/* selected */1] === Question$ReactHooksTemplate.categoryType(x) && state[/* selectedId */2] === Question$ReactHooksTemplate.uuid(x);
                          var match$2 = Question$ReactHooksTemplate.text(x) === "";
                          var match$3 = state[/* selected */1] === Question$ReactHooksTemplate.categoryType(x) && state[/* selectedId */2] === Question$ReactHooksTemplate.uuid(x) && state[/* outsideClick */15] === false;
                          return React.createElement("div", {
                                      key: RandomId$ReactHooksTemplate.toString(id),
                                      className: "p-2 border-b border-gray-400 mx-4 my-12-px  flex justify-between rounded border bg-gray-100 hover:bg-alicebluesolid h-56-px " + (
                                        match$1 ? "bg-lavenderweb" : ""
                                      ),
                                      onClick: (function (param) {
                                          Curry._1(dispatch, /* SetSelected */Block.__(6, [Question$ReactHooksTemplate.categoryType(x)]));
                                          Curry._1(dispatch, /* SetSelectedId */Block.__(7, [id]));
                                          return Curry._1(dispatch, /* PreviewQuestion */Block.__(11, [x]));
                                        })
                                    }, React.createElement("div", {
                                          className: "flex items-center"
                                        }, React.createElement("div", {
                                              className: "flex items-center text-sm text-darkcharcoal font-normal"
                                            }, React.createElement("img", {
                                                  className: "mr-2",
                                                  src: Image$ReactHooksTemplate.dragHandle
                                                }), React.createElement("div", {
                                                  className: "mr-3 text-aurometalsaurus text-xs"
                                                }, String(slNo))), React.createElement("div", {
                                              className: "flex flex-col items-center gap-1 justify-items-start"
                                            }, match$2 ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                                    className: "w-full"
                                                  }, React.createElement("div", {
                                                        className: "truncate max-w-150-px float-left text-darkcharcoal text-sm font-normal ml-px"
                                                      }, Question$ReactHooksTemplate.text(x))), React.createElement("div", {
                                                  className: "w-full"
                                                }, React.createElement("div", {
                                                      className: "bg-magnolia px-2 py-1 text-10-px font-light text-darkcharcoal rounded float-left"
                                                    }, Question$ReactHooksTemplate.categoryType(x))))), React.createElement("div", {
                                          className: "flex justify-end items-center relative"
                                        }, React.createElement("img", {
                                              className: "h-18-px",
                                              src: Image$ReactHooksTemplate.verticalDots,
                                              onClick: (function (param) {
                                                  return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [false]));
                                                })
                                            }), React.createElement("div", {
                                              className: "flex flex-wrap items-search p-2 absolute rounded border border-gray-400 bg-white\n                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 w-40 gap-2 " + (
                                                match$3 ? "block" : "hidden"
                                              )
                                            }, React.createElement("a", {
                                                  className: "flex text-xs gap-2",
                                                  href: "#"
                                                }, React.createElement("img", {
                                                      className: "h-12-px",
                                                      src: Image$ReactHooksTemplate.plusCircle
                                                    }), "Duplicate"), React.createElement("a", {
                                                  className: "flex text-xs gap-2",
                                                  onClick: (function (param) {
                                                      Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                                      Curry._1(dispatch, /* ShowWelcomeThankYou */Block.__(13, [
                                                              false,
                                                              (index + 1 | 0) === Belt_List.length(Survey$ReactHooksTemplate.questions(state[/* survey */0]))
                                                            ]));
                                                      Curry._1(dispatch, /* SetAddQuestionBelow */Block.__(19, [true]));
                                                      Curry._1(dispatch, /* SetAddThankYouToTop */Block.__(20, [(index + 1 | 0) === Belt_List.length(Survey$ReactHooksTemplate.questions(state[/* survey */0]))]));
                                                      Curry._1(dispatch, /* SetToBeAddedBelowIndex */Block.__(21, [index + 1 | 0]));
                                                      return Curry._1(dispatch, /* ToggleAddQuestion */3);
                                                    })
                                                }, React.createElement("img", {
                                                      className: "h-12-px",
                                                      src: Image$ReactHooksTemplate.plusCircle
                                                    }), "Add question below"), React.createElement("a", {
                                                  className: "flex text-xs gap-2",
                                                  href: "#",
                                                  onClick: (function ($$event) {
                                                      $$event.preventDefault();
                                                      $$event.stopPropagation();
                                                      Curry._1(dispatch, /* RemoveQuestionEditorBlock */Block.__(5, [
                                                              index,
                                                              x
                                                            ]));
                                                      return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                                    })
                                                }, React.createElement("img", {
                                                      className: "h-12-px",
                                                      src: Image$ReactHooksTemplate.trash
                                                    }), "Delete"))));
                        });
                    }), Survey$ReactHooksTemplate.questions(state[/* survey */0]))), $$Array.of_list(List.mapi((function (i) {
                      return (function (param) {
                          var index = i;
                          var thankYou = param;
                          var id = ThankYou$ReactHooksTemplate.uuid(thankYou);
                          var noOfQuestions = List.length(state[/* survey */0][/* questions */8]);
                          var match = state[/* survey */0][/* welcome */4];
                          var slNo = match !== undefined ? (index + noOfQuestions | 0) + 2 | 0 : (index + noOfQuestions | 0) + 1 | 0;
                          var match$1 = state[/* selected */1] === ThankYou$ReactHooksTemplate.categoryType && state[/* selectedId */2] === ThankYou$ReactHooksTemplate.uuid(thankYou);
                          var match$2 = ThankYou$ReactHooksTemplate.text(thankYou) === "";
                          var match$3 = state[/* selected */1] === ThankYou$ReactHooksTemplate.categoryType && state[/* selectedId */2] === ThankYou$ReactHooksTemplate.uuid(thankYou) && state[/* outsideClick */15] === false;
                          return React.createElement("li", {
                                      key: RandomId$ReactHooksTemplate.toString(id),
                                      className: "p-3 border-b border-gray-400 mx-4 my-12-px flex justify-between rounded border bg-gray-100 hover:bg-alicebluesolid h-56-px " + (
                                        match$1 ? "bg-lavenderweb" : ""
                                      ),
                                      onClick: (function (param) {
                                          Curry._1(dispatch, /* SetSelected */Block.__(6, [ThankYou$ReactHooksTemplate.categoryType]));
                                          Curry._1(dispatch, /* SetSelectedId */Block.__(7, [ThankYou$ReactHooksTemplate.uuid(thankYou)]));
                                          return Curry._1(dispatch, /* PreviewThankYou */Block.__(12, [thankYou]));
                                        })
                                    }, React.createElement("div", {
                                          className: "flex items-center"
                                        }, React.createElement("div", {
                                              className: "flex items-center text-sm text-darkcharcoal font-normal"
                                            }, React.createElement("img", {
                                                  className: "mr-2",
                                                  src: Image$ReactHooksTemplate.dragHandle
                                                }), React.createElement("div", {
                                                  className: "mr-3 text-aurometalsaurus text-xs"
                                                }, String(slNo))), React.createElement("div", {
                                              className: "flex flex-col items-center gap-1 justify-items-start"
                                            }, match$2 ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                                    className: "w-full"
                                                  }, React.createElement("div", {
                                                        className: "truncate max-w-150-px float-left text-darkcharcoal text-sm font-normal ml-px"
                                                      }, ThankYou$ReactHooksTemplate.text(thankYou))), React.createElement("div", {
                                                  className: "w-full"
                                                }, React.createElement("div", {
                                                      className: "bg-magnolia px-2 py-1 text-10-px font-light text-darkcharcoal rounded float-left"
                                                    }, "Thank You")))), React.createElement("div", {
                                          className: "flex justify-end items-center relative"
                                        }, React.createElement("img", {
                                              className: "h-18-px",
                                              src: Image$ReactHooksTemplate.verticalDots,
                                              onClick: (function (param) {
                                                  return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [false]));
                                                })
                                            }), React.createElement("div", {
                                              className: "flex flex-wrap items-search p-2 absolute rounded border border-gray-400 bg-white\n                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 w-40 gap-2 " + (
                                                match$3 ? "block" : "hidden"
                                              )
                                            }, React.createElement("a", {
                                                  className: "flex text-xs gap-2",
                                                  href: "#"
                                                }, React.createElement("img", {
                                                      className: "h-12-px",
                                                      src: Image$ReactHooksTemplate.plusCircle
                                                    }), "Duplicate"), React.createElement("a", {
                                                  className: "flex text-xs gap-2",
                                                  href: "#",
                                                  onClick: (function (param) {
                                                      Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                                      return Curry._1(dispatch, /* AddEditorBlockInBetween */Block.__(3, [
                                                                    index + 1 | 0,
                                                                    /* ThankYou */1
                                                                  ]));
                                                    })
                                                }, React.createElement("img", {
                                                      className: "h-12-px",
                                                      src: Image$ReactHooksTemplate.plusCircle
                                                    }), "Add thank you below"), React.createElement("a", {
                                                  className: "flex text-xs gap-2",
                                                  href: "#",
                                                  onClick: (function ($$event) {
                                                      $$event.preventDefault();
                                                      $$event.stopPropagation();
                                                      Curry._1(dispatch, /* RemoveThankYouEditorBlock */Block.__(4, [
                                                              index,
                                                              thankYou
                                                            ]));
                                                      return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                                    })
                                                }, React.createElement("img", {
                                                      className: "h-12-px",
                                                      src: Image$ReactHooksTemplate.trash
                                                    }), "Delete"))));
                        });
                    }), Survey$ReactHooksTemplate.thankYouList(state[/* survey */0])))));
  var editorTabView = React.createElement("div", {
        className: "bg-ghostwhiteblue w-2/5 flex flex-col overflow-y-auto",
        style: {
          height: "calc(100vh - 4rem)"
        },
        onClick: (function (param) {
            return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
          })
      }, editor);
  var listTabView = React.createElement("div", {
        className: "bg-ghostwhiteblue w-2/5 flex flex-col",
        style: {
          height: "calc(100vh - 4rem)"
        }
      }, React.createElement("div", {
            className: "flex justify-between p-4 items-center"
          }, React.createElement("div", {
                className: "text-base text-darkcharcoal"
              }, "Questions"), addQuestionButton), questionList);
  switch (match$1[0]) {
    case /* Loading */0 :
        return React.createElement("p", undefined, "Loading survey from API in progress...");
    case /* Ready */1 :
        var match$5 = state[/* notification */13];
        var tmp;
        if (match$5 !== undefined) {
          var match$6 = match$5;
          tmp = React.createElement("div", {
                className: "pt-1"
              }, React.createElement(Notification$ReactHooksTemplate.make, {
                    text: match$6[0],
                    isError: match$6[1],
                    handleClose: (function (param) {
                        return Curry._1(dispatch, /* RemoveNotification */8);
                      })
                  }));
        } else {
          tmp = React.createElement(React.Fragment, undefined);
        }
        var match$7 = state[/* showConfigurations */14];
        return React.createElement(React.Fragment, undefined, React.createElement(Modal$ReactHooksTemplate.make, {
                        show: state[/* showPreview */4],
                        children: React.createElement("div", {
                              className: "w-screen bg-white pt-6"
                            }, React.createElement("div", {
                                  className: ""
                                }, React.createElement(SurveyPreviewWrapper$ReactHooksTemplate.make, {
                                      survey: state[/* survey */0],
                                      client: state[/* client */10],
                                      showPreview: state[/* showPreview */4]
                                    }))),
                        handleClose: (function (param) {
                            Curry._1(dispatch, /* TogglePreview */2);
                            return /* () */0;
                          })
                      }), tmp, match$7 ? React.createElement("div", {
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                            })
                        }, React.createElement(SurveyConfiguration$ReactHooksTemplate.make, {
                              onClose: (function (param) {
                                  return Curry._1(dispatch, /* ToggleSurveyConfiguration */4);
                                }),
                              survey: state[/* survey */0],
                              updateSurvey: (function (survey) {
                                  return Curry._1(dispatch, /* UpdateSurvey */Block.__(0, [survey]));
                                })
                            })) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                        className: "bg-white w-full border border-gray-400 flex\n                      justify-between p-4 items-center",
                        onClick: (function (param) {
                            return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                          })
                      }, React.createElement("div", {
                            className: "text-lg font-semibold flex items-center"
                          }, React.createElement("a", {
                                className: "mr-3",
                                href: "/projects/all"
                              }, React.createElement("img", {
                                    src: Image$ReactHooksTemplate.backArrow
                                  })), Survey$ReactHooksTemplate.name(state[/* survey */0])), React.createElement("div", {
                            className: "flex items-center gap-4"
                          }, React.createElement("button", {
                                className: "bg-pigmentblue border border-gray-400\n              flex rounded p-2 text-sm",
                                onClick: (function (param) {
                                    Curry._1(dispatch, /* TogglePreview */2);
                                    return /* () */0;
                                  })
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between gap-1 text-white"
                                  }, React.createElement("img", {
                                        src: Image$ReactHooksTemplate.preview
                                      }), React.createElement("p", undefined, "Preview"))), React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n              flex rounded p-2 text-sm",
                                onClick: handleSaveForm
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between gap-1 text-darkcharcoal"
                                  }, React.createElement("img", {
                                        src: Image$ReactHooksTemplate.slider
                                      }), React.createElement("p", undefined, "Publish"))), React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n              flex rounded p-2 text-sm",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleSurveyConfiguration */4);
                                  })
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between gap-1 text-darkcharcoal"
                                  }, React.createElement("img", {
                                        src: Image$ReactHooksTemplate.cogTransparent
                                      }), React.createElement("p", undefined, "Configuration"))))), React.createElement("div", {
                        className: "flex w-full"
                      }, React.createElement("div", {
                            className: "flex w-full",
                            style: {
                              height: "calc(100vh - 110px)"
                            }
                          }, listTabView, React.createElement("div", {
                                className: "bg-white border-l border-r border-gray-300 w-full overflow-y-auto",
                                style: {
                                  height: "calc(100vh - 4rem)"
                                },
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* SetOutsideClick */Block.__(18, [true]));
                                  })
                              }, React.createElement("div", undefined, surveyBuilderPreview(/* () */0))), editorTabView)));
    case /* Saving */2 :
        return React.createElement("p", undefined, "Saving survey in progress...");
    
  }
}

var yesbankQuestiontemplate = "\n  <div class=\"flex flex-col h-full min-h-screen\">\n    <div class=\"h-80-px small:h-60-px mb-5 small:mb-6 bg-cobalt\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"mx-12 small:mx-4 flex flex-col\">\n      <div id=\"language\">language</div>\n      <div class=\"pl-142-px small:pl-4 pr-80-px small:pr-4 pt-24 small:pt-4 question-box-gradient rounded-lg mb-8 flex-grow overflow-y-auto pb-16 small:pb-12\">\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n    <div class=\"flex justify-center items-center mb-8\">\n      <div id=\"previousButton\"></div>\n      <div id=\"nextButton\"></div>\n    </div>\n  </div>";

var make = SurveyForm;

exports.yesbankQuestiontemplate = yesbankQuestiontemplate;
exports.bhimaQuestiontemplate = bhimaQuestiontemplate;
exports.defaultQuestiontemplate = defaultQuestiontemplate;
exports.defaultQuestionBuildertemplate = defaultQuestionBuildertemplate;
exports.questiontemplates = questiontemplates;
exports.questionBuilderTemplates = questionBuilderTemplates;
exports.questionsOrPageToString = questionsOrPageToString;
exports.initialAccordionState = initialAccordionState;
exports.decodeSurvey = decodeSurvey;
exports.initialPreviewState = initialPreviewState;
exports.addQuestionsToGraph = addQuestionsToGraph;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
