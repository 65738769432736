// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function CheckboxInput(Props) {
  var checked = Props.checked;
  var label = Props.label;
  var handleChange = Props.handleChange;
  var match = Props.indeterminate;
  var indeterminate = match !== undefined ? match : false;
  var match$1 = Props.className;
  var className = match$1 !== undefined ? match$1 : "";
  var match$2 = Props.checkboxClassName;
  var checkboxClassName = match$2 !== undefined ? match$2 : "checkbox";
  var match$3 = Props.labelClassName;
  var labelClassName = match$3 !== undefined ? match$3 : "text-base leading-normal whitespace-normal";
  label.length === 0;
  var domElement = React.useRef(null);
  React.useEffect((function () {
          var y = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
          y.indeterminate = indeterminate;
          return ;
        }));
  return React.createElement("div", {
              className: "flex gap-2 " + className
            }, React.createElement("label", undefined, React.createElement("input", {
                      ref: domElement,
                      checked: indeterminate ? false : checked,
                      type: "checkbox",
                      onChange: handleChange
                    }), React.createElement("div", {
                      className: checkboxClassName
                    })), React.createElement("div", {
                  className: labelClassName
                }, label));
}

var make = CheckboxInput;

exports.make = make;
/* react Not a pure module */
