// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Role$ReactHooksTemplate = require("./role.bs.js");

function UserRoleItem(Props) {
  var role = Props.role;
  return React.createElement("p", {
              className: "capitalize px-10-px py-3-px bg-teal-200"
            }, Role$ReactHooksTemplate.name(role));
}

var make = UserRoleItem;

exports.make = make;
/* react Not a pure module */
