// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Moment = require("moment");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Color$ReactHooksTemplate = require("./color.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");

var empty_001 = /* email */Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0);

var empty = /* record */[
  /* id */0,
  empty_001
];

function isEmpty(t) {
  return Caml_obj.caml_equal(t, empty);
}

function id(t) {
  return t[/* id */0];
}

function email(t) {
  return t[/* email */1];
}

function decode(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* email */Json_decode.field("email", Domain$ReactHooksTemplate.$$String.Email.decode, json)
        ];
}

var Assignee = {
  empty: empty,
  isEmpty: isEmpty,
  id: id,
  email: email,
  decode: decode
};

function empty$1(param) {
  return /* record */[
          /* header : array */[],
          /* contents : array */[]
        ];
}

function header(t) {
  return t[/* header */0];
}

function contents(t) {
  return t[/* contents */1];
}

function decodeResponseDetails(header, json) {
  return List.map((function (name) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field(name, Json_decode.string, param);
                            }), json);
              }), header);
}

function decode$1(json) {
  var header = Json_decode.field("header", (function (param) {
          return Json_decode.array(Json_decode.string, param);
        }), json);
  return /* record */[
          /* header */header,
          /* contents */Json_decode.field("responses", (function (param) {
                  return Json_decode.array((function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json)
        ];
}

var NormalResponses = {
  empty: empty$1,
  header: header,
  contents: contents,
  decodeResponseDetails: decodeResponseDetails,
  decode: decode$1
};

function name(t) {
  return t[/* name */1];
}

function decode$2(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* color */Json_decode.field("color", Color$ReactHooksTemplate.decode, json)
        ];
}

var Priority = {
  name: name,
  decode: decode$2
};

function toString(t) {
  switch (t) {
    case /* Open */0 :
        return "Open";
    case /* Parked */1 :
        return "Parked";
    case /* Closed */2 :
        return "Closed";
    case /* Resolved */3 :
        return "Resolved";
    
  }
}

function fromString(x) {
  var match = x.toLowerCase();
  switch (match) {
    case "closed" :
        return /* Closed */2;
    case "open" :
        return /* Open */0;
    case "parked" :
        return /* Parked */1;
    case "resolved" :
        return /* Resolved */3;
    default:
      return /* Open */0;
  }
}

function next(param) {
  if (param !== 1) {
    if (param !== 0) {
      return /* [] */0;
    } else {
      return /* :: */[
              /* Parked */1,
              /* :: */[
                /* Closed */2,
                /* :: */[
                  /* Resolved */3,
                  /* [] */0
                ]
              ]
            ];
    }
  } else {
    return /* :: */[
            /* Open */0,
            /* [] */0
          ];
  }
}

function decode$3(json) {
  return fromString(Json_decode.string(json));
}

var Status = {
  toString: toString,
  fromString: fromString,
  next: next,
  decode: decode$3
};

function id$1(t) {
  return t[/* id */0];
}

function msisdn(t) {
  return t[/* msisdn */3];
}

function email$1(t) {
  return t[/* email */4];
}

function firstName(t) {
  return t[/* firstName */1];
}

function lastName(t) {
  return t[/* lastName */2];
}

function name$1(t) {
  return Curry._1(Domain$ReactHooksTemplate.$$String.FirstName.show, t[/* firstName */1]) + (" " + Curry._1(Domain$ReactHooksTemplate.$$String.LastName.show, t[/* lastName */2]));
}

function assignee(t) {
  return t[/* assignee */6];
}

function priority(t) {
  return t[/* priority */5];
}

function status(t) {
  return t[/* status */7];
}

function createdAt(t) {
  return t[/* createdAt */10];
}

function rca(t) {
  return t[/* rca */9];
}

function normalResponses(t) {
  return t[/* normalResponses */8];
}

function channel(t) {
  return t[/* channel */11];
}

function touchPoint(t) {
  return t[/* touchPoint */12];
}

function lob(t) {
  return t[/* lob */13];
}

function shopName(t) {
  return t[/* shopName */14];
}

function escalation_level_2(t) {
  return t[/* escalation_level_2 */15];
}

function escalation_level_3(t) {
  return t[/* escalation_level_3 */16];
}

function escalation_level_4(t) {
  return t[/* escalation_level_4 */17];
}

function escalation_level_5(t) {
  return t[/* escalation_level_5 */18];
}

function updateTicketStatus(status, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* firstName */t[/* firstName */1],
          /* lastName */t[/* lastName */2],
          /* msisdn */t[/* msisdn */3],
          /* email */t[/* email */4],
          /* priority */t[/* priority */5],
          /* assignee */t[/* assignee */6],
          /* status */status,
          /* normalResponses */t[/* normalResponses */8],
          /* rca */t[/* rca */9],
          /* createdAt */t[/* createdAt */10],
          /* channel */t[/* channel */11],
          /* touchPoint */t[/* touchPoint */12],
          /* lob */t[/* lob */13],
          /* shopName */t[/* shopName */14],
          /* escalation_level_2 */t[/* escalation_level_2 */15],
          /* escalation_level_3 */t[/* escalation_level_3 */16],
          /* escalation_level_4 */t[/* escalation_level_4 */17],
          /* escalation_level_5 */t[/* escalation_level_5 */18]
        ];
}

function updateAssignee(assignee, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* firstName */t[/* firstName */1],
          /* lastName */t[/* lastName */2],
          /* msisdn */t[/* msisdn */3],
          /* email */t[/* email */4],
          /* priority */t[/* priority */5],
          /* assignee */assignee,
          /* status */t[/* status */7],
          /* normalResponses */t[/* normalResponses */8],
          /* rca */t[/* rca */9],
          /* createdAt */t[/* createdAt */10],
          /* channel */t[/* channel */11],
          /* touchPoint */t[/* touchPoint */12],
          /* lob */t[/* lob */13],
          /* shopName */t[/* shopName */14],
          /* escalation_level_2 */t[/* escalation_level_2 */15],
          /* escalation_level_3 */t[/* escalation_level_3 */16],
          /* escalation_level_4 */t[/* escalation_level_4 */17],
          /* escalation_level_5 */t[/* escalation_level_5 */18]
        ];
}

function decode$4(json) {
  var partial_arg = Domain$ReactHooksTemplate.$$String.FirstName.decode;
  var partial_arg$1 = Curry._1(Domain$ReactHooksTemplate.$$String.FirstName.empty, /* () */0);
  var partial_arg$2 = function (param) {
    return Json_decode.withDefault(partial_arg$1, partial_arg, param);
  };
  var partial_arg$3 = Domain$ReactHooksTemplate.$$String.LastName.decode;
  var partial_arg$4 = Curry._1(Domain$ReactHooksTemplate.$$String.LastName.empty, /* () */0);
  var partial_arg$5 = function (param) {
    return Json_decode.withDefault(partial_arg$4, partial_arg$3, param);
  };
  var partial_arg$6 = Domain$ReactHooksTemplate.$$String.Msisdn.decode;
  var partial_arg$7 = Curry._1(Domain$ReactHooksTemplate.$$String.Msisdn.empty, /* () */0);
  var partial_arg$8 = function (param) {
    return Json_decode.withDefault(partial_arg$7, partial_arg$6, param);
  };
  var partial_arg$9 = Domain$ReactHooksTemplate.$$String.Email.decode;
  var partial_arg$10 = Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0);
  var partial_arg$11 = function (param) {
    return Json_decode.withDefault(partial_arg$10, partial_arg$9, param);
  };
  var partial_arg_000 = /* header : array */[];
  var partial_arg_001 = /* contents : array */[];
  var partial_arg$12 = /* record */[
    partial_arg_000,
    partial_arg_001
  ];
  var partial_arg$13 = function (param) {
    return Json_decode.withDefault(partial_arg$12, decode$1, param);
  };
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* firstName */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("first_name", partial_arg$2, param);
                }), json),
          /* lastName */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("last_name", partial_arg$5, param);
                }), json),
          /* msisdn */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("msisdn", partial_arg$8, param);
                }), json),
          /* email */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("email", partial_arg$11, param);
                }), json),
          /* priority */Json_decode.field("ticket_priority", decode$2, json),
          /* assignee */Json_decode.optional((function (param) {
                  return Json_decode.field("assignee", decode, param);
                }), json),
          /* status */Json_decode.field("status", decode$3, json),
          /* normalResponses */Json_decode.optional((function (param) {
                  return Json_decode.field("normal_responses", partial_arg$13, param);
                }), json),
          /* rca */Json_decode.optional((function (param) {
                  return Json_decode.field("rca", Json_decode.string, param);
                }), json),
          /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
          /* channel */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("channel", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          /* touchPoint */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("touch_point", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          /* lob */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("lob", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          /* shopName */Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("more_details", (function (param) {
                                return Json_decode.optional((function (param) {
                                              return Json_decode.field("shop_name", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          /* escalation_level_2 */Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_2", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          /* escalation_level_3 */Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_3", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          /* escalation_level_4 */Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_4", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json),
          /* escalation_level_5 */Json_decode.optional((function (param) {
                  return Json_decode.field("customer_detail", (function (param) {
                                return Json_decode.field("more_details", (function (param) {
                                              return Json_decode.field("escalation_level_5", Json_decode.string, param);
                                            }), param);
                              }), param);
                }), json)
        ];
}

exports.Assignee = Assignee;
exports.NormalResponses = NormalResponses;
exports.Priority = Priority;
exports.Status = Status;
exports.id = id$1;
exports.msisdn = msisdn;
exports.email = email$1;
exports.firstName = firstName;
exports.lastName = lastName;
exports.name = name$1;
exports.assignee = assignee;
exports.priority = priority;
exports.status = status;
exports.createdAt = createdAt;
exports.rca = rca;
exports.normalResponses = normalResponses;
exports.channel = channel;
exports.touchPoint = touchPoint;
exports.lob = lob;
exports.shopName = shopName;
exports.escalation_level_2 = escalation_level_2;
exports.escalation_level_3 = escalation_level_3;
exports.escalation_level_4 = escalation_level_4;
exports.escalation_level_5 = escalation_level_5;
exports.updateTicketStatus = updateTicketStatus;
exports.updateAssignee = updateAssignee;
exports.decode = decode$4;
/* empty Not a pure module */
