// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Recharts = require("recharts");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var User$ReactHooksTemplate = require("./user.bs.js");
var Recharts$ReactHooksTemplate = require("../dashboard/common/Recharts.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var SearchForm$ReactHooksTemplate = require("../surveys/SearchForm.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var UserListItem$ReactHooksTemplate = require("./UserListItem.bs.js");
var UserListHeader$ReactHooksTemplate = require("./UserListHeader.bs.js");
var OverlayClosable$ReactHooksTemplate = require("../common/OverlayClosable.bs.js");
var ConfirmationDialog$ReactHooksTemplate = require("./ConfirmationDialog.bs.js");

function deleteUser(user) {
  var userId = String(User$ReactHooksTemplate.id(user));
  return Http$ReactHooksTemplate.fetchDeleteJSON("/api/v1/users/" + (String(userId) + ""));
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* LoadUsers */0 :
          return /* record */[
                  /* users */state[/* users */0],
                  /* userToDelete */state[/* userToDelete */1],
                  /* statusCount */state[/* statusCount */2],
                  /* loadingUsers */true,
                  /* loadingStatusCount */state[/* loadingStatusCount */4]
                ];
      case /* LoadStatusCount */1 :
          return /* record */[
                  /* users */state[/* users */0],
                  /* userToDelete */state[/* userToDelete */1],
                  /* statusCount */state[/* statusCount */2],
                  /* loadingUsers */state[/* loadingUsers */3],
                  /* loadingStatusCount */true
                ];
      case /* CancelDelete */2 :
          return /* record */[
                  /* users */state[/* users */0],
                  /* userToDelete */undefined,
                  /* statusCount */state[/* statusCount */2],
                  /* loadingUsers */state[/* loadingUsers */3],
                  /* loadingStatusCount */state[/* loadingStatusCount */4]
                ];
      
    }
  } else {
    switch (action.tag | 0) {
      case /* DisplayList */0 :
          return /* record */[
                  /* users */action[0],
                  /* userToDelete */state[/* userToDelete */1],
                  /* statusCount */state[/* statusCount */2],
                  /* loadingUsers */false,
                  /* loadingStatusCount */state[/* loadingStatusCount */4]
                ];
      case /* DisplayStatusCount */1 :
          return /* record */[
                  /* users */state[/* users */0],
                  /* userToDelete */state[/* userToDelete */1],
                  /* statusCount */action[0],
                  /* loadingUsers */state[/* loadingUsers */3],
                  /* loadingStatusCount */false
                ];
      case /* DisplayDeleteConfirmation */2 :
          return /* record */[
                  /* users */state[/* users */0],
                  /* userToDelete */action[0],
                  /* statusCount */state[/* statusCount */2],
                  /* loadingUsers */state[/* loadingUsers */3],
                  /* loadingStatusCount */state[/* loadingStatusCount */4]
                ];
      
    }
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var initialState = /* record */[
  /* users : [] */0,
  /* userToDelete */undefined,
  /* statusCount : record */[
    /* active */0,
    /* inActive */0
  ],
  /* loadingUsers */true,
  /* loadingStatusCount */true
];

var searchPlaceholder = "Search Users";

function UserList(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setSearchKey = match$1[1];
  var searchKey = match$1[0];
  var queryParams = Querystringify.parse(search);
  var match$2 = Js_dict.get(queryParams, "page");
  var currentPage;
  if (match$2 !== undefined) {
    var x = match$2;
    if (x === "") {
      currentPage = 1;
    } else {
      var page = Belt_Int.fromString(x);
      currentPage = page !== undefined ? page : 1;
    }
  } else {
    currentPage = 1;
  }
  var match$3 = Js_dict.get(queryParams, "search");
  var currentSearchKey;
  if (match$3 !== undefined) {
    var x$1 = match$3;
    currentSearchKey = x$1 === "" ? undefined : x$1;
  } else {
    currentSearchKey = undefined;
  }
  var match$4 = React.useState((function () {
          return /* record */[
                  /* total */"1",
                  /* perPage */"1",
                  /* currentPage */currentPage
                ];
        }));
  var setPageInfo = match$4[1];
  var pageInfo = match$4[0];
  var fetchUsers = function (param) {
    var pageNumber = String(pageInfo[/* currentPage */2]);
    var url = currentSearchKey !== undefined ? "/api/v1/users?page=" + (String(pageNumber) + ("&search=" + (String(currentSearchKey) + ""))) : "/api/v1/users?page=" + (String(pageNumber) + "");
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + (String(url) + "")).then((function (param) {
                  var headers = param[0];
                  var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
                  var perPage$1 = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
                  var users = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("users", (function (param) {
                                            return Json_decode.list(User$ReactHooksTemplate.decode, param);
                                          }), json));
                        }));
                  Curry._1(setSearchKey, (function (param) {
                          return currentSearchKey;
                        }));
                  return Promise.resolve(/* tuple */[
                              users,
                              /* tuple */[
                                total,
                                perPage$1
                              ]
                            ]);
                }));
  };
  var match$5 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var handlePageChange = function (pageNumber) {
    var pageNumber$1 = String(pageNumber);
    if (searchKey !== undefined) {
      ReasonReactRouter.push("/users/all?page=" + (String(pageNumber$1) + ("&search=" + (String(searchKey) + ""))));
    } else {
      ReasonReactRouter.push("/users/all?page=" + (String(pageNumber$1) + ""));
    }
    Curry._1(setPageInfo, (function (pageInfo) {
            return /* record */[
                    /* total */pageInfo[/* total */0],
                    /* perPage */pageInfo[/* perPage */1],
                    /* currentPage */Caml_format.caml_int_of_string(pageNumber$1)
                  ];
          }));
    window.scrollTo(0.0, 0.0);
    return /* () */0;
  };
  var handleSearch = function (param) {
    if (searchKey !== undefined) {
      return ReasonReactRouter.push("/users/all?search=" + (String(searchKey) + ""));
    } else {
      return ReasonReactRouter.push("/users/all?");
    }
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* LoadStatusCount */1);
          var url = currentSearchKey !== undefined ? "/api/v1/users/status_wise_count?search=" + (String(currentSearchKey) + "") : "/api/v1/users/status_wise_count";
          Http$ReactHooksTemplate.fetchGetJSON("" + (String(url) + "")).then((function (json) {
                  var statusCount_000 = /* active */Belt_Option.getWithDefault(Json_decode.field("count", (function (param) {
                              return Json_decode.optional((function (param) {
                                            return Json_decode.field("true", Json_decode.$$int, param);
                                          }), param);
                            }), json), 0);
                  var statusCount_001 = /* inActive */Belt_Option.getWithDefault(Json_decode.field("count", (function (param) {
                              return Json_decode.optional((function (param) {
                                            return Json_decode.field("false", Json_decode.$$int, param);
                                          }), param);
                            }), json), 0);
                  var statusCount = /* record */[
                    statusCount_000,
                    statusCount_001
                  ];
                  Curry._1(setSearchKey, (function (param) {
                          return currentSearchKey;
                        }));
                  Curry._1(dispatch, /* DisplayStatusCount */Block.__(1, [statusCount]));
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), /* array */[currentSearchKey]);
  React.useEffect((function () {
          Curry._1(dispatch, /* LoadUsers */0);
          fetchUsers(/* () */0).then((function (param) {
                  var paginationInfo = param[1];
                  var perPage = paginationInfo[1];
                  var total = paginationInfo[0];
                  Curry._1(setPageInfo, (function (state) {
                          return /* record */[
                                  /* total */total,
                                  /* perPage */perPage,
                                  /* currentPage */state[/* currentPage */2]
                                ];
                        }));
                  return param[0].then((function (xs) {
                                return Promise.resolve(Curry._1(dispatch, /* DisplayList */Block.__(0, [xs])));
                              }));
                }));
          return ;
        }), /* tuple */[
        currentPage,
        currentSearchKey
      ]);
  var handleCancelDelete = function (param) {
    return Curry._1(dispatch, /* CancelDelete */2);
  };
  var data = function (statusCount) {
    return /* array */[
            {
              name: "active",
              count: statusCount[/* active */0]
            },
            {
              name: "inactive",
              count: statusCount[/* inActive */1]
            }
          ];
  };
  var summary = function (param) {
    var match = state[/* loadingStatusCount */4];
    return React.createElement("div", {
                className: "h-32 w-1/3 mb-4 bg-white rounded-sm shadow border-l-8 border-teal-600 mr-20-px p-8"
              }, match ? React.createElement("p", undefined, "Loading data") : React.createElement(Recharts.ResponsiveContainer, {
                      children: React.createElement(Recharts.BarChart, {
                            data: data(state[/* statusCount */2]),
                            margin: Recharts$ReactHooksTemplate.Margin.make(0, 0, 0, 40),
                            layout: "vertical",
                            children: null
                          }, React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(undefined)(undefined, "count", undefined, undefined, undefined), undefined, undefined, undefined, undefined, /* number */561678025, undefined, /* () */0)), React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(/* Num */[0])(1, "name", undefined, undefined, undefined, undefined), [
                                    (function (key) {
                                        return key.toLocaleUpperCase();
                                      }),
                                    undefined,
                                    undefined,
                                    /* category */762063614,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    /* () */0
                                  ])), React.createElement(Recharts.Tooltip, { }), React.createElement(Recharts.Bar, {
                                dataKey: "count",
                                barSize: 200,
                                fill: "#ff7300"
                              }))
                    }));
  };
  var match$6 = Curry._1(match$5[0], /* AddUser */6);
  var match$7 = state[/* loadingUsers */3];
  var tmp;
  if (match$7) {
    tmp = React.createElement(React.Fragment, undefined, summary(/* () */0), React.createElement("p", undefined, "Fetching users..."));
  } else {
    var match$8 = state[/* userToDelete */1];
    var tmp$1;
    if (match$8 !== undefined) {
      var user = match$8;
      var partial_arg = state[/* users */0];
      tmp$1 = React.createElement(OverlayClosable$ReactHooksTemplate.make, {
            hidden: false,
            children: React.createElement(ConfirmationDialog$ReactHooksTemplate.make, {
                  user: user,
                  handleCancel: handleCancelDelete,
                  handleDelete: (function (param) {
                      var users = partial_arg;
                      var user$1 = user;
                      deleteUser(user$1).then((function (param) {
                                return Promise.resolve(Curry._1(dispatch, /* DisplayList */Block.__(0, [users])));
                              })).then((function (param) {
                              return Promise.resolve((window.location.reload(), /* () */0));
                            }));
                      return /* () */0;
                    }),
                  handleClose: handleCancelDelete
                })
          });
    } else {
      tmp$1 = React.createElement(React.Fragment, undefined, summary(/* () */0), React.createElement("div", {
                className: "flex flex-row justify-end mb-10-px relative w-full"
              }, React.createElement(SearchForm$ReactHooksTemplate.make, {
                    searchKey: searchKey,
                    setSearchKey: setSearchKey,
                    handleSearch: handleSearch,
                    searchPlaceholder: searchPlaceholder
                  })), React.createElement(UserListHeader$ReactHooksTemplate.make, { }), React.createElement("div", {
                className: "shadow bg-white"
              }, $$Array.of_list(List.map((function (user) {
                          return React.createElement(UserListItem$ReactHooksTemplate.make, {
                                      email: User$ReactHooksTemplate.email(user),
                                      active: user[/* active */5],
                                      roles: user[/* roles */6],
                                      dataRestrictions: user[/* dataRestrictions */7],
                                      lastSignInAt: user[/* lastSignInAt */8],
                                      handleDelete: (function (param) {
                                          return Curry._1(dispatch, /* DisplayDeleteConfirmation */Block.__(2, [user]));
                                        }),
                                      handleEdit: (function (param) {
                                          var user$1 = user;
                                          return ReasonReactRouter.push("/users/edit/" + String(User$ReactHooksTemplate.id(user$1)));
                                        }),
                                      key: String(User$ReactHooksTemplate.id(user))
                                    });
                        }), state[/* users */0]))));
    }
    tmp = React.createElement(React.Fragment, undefined, tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "m-30-px"
                }, match$6 ? React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px "
                      }, React.createElement("h2", {
                            className: "mb-30-px text-2xl"
                          }, "Users"), React.createElement("a", {
                            className: "text-white bg-primary hover:bg-teal-700 py-6-px px-20-px rounded shadow",
                            href: "/users/new"
                          }, "Add New User")) : React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px "
                      }, React.createElement("h2", {
                            className: "mb-30-px text-2xl"
                          }, "Users")), tmp), React.createElement("div", {
                  className: "flex justify-end"
                }, React.createElement(Pagination$ReactHooksTemplate.make, {
                      total: totalPages(Caml_format.caml_int_of_string(pageInfo[/* total */0]), Caml_format.caml_int_of_string(pageInfo[/* perPage */1])),
                      currentPage: currentPage,
                      handlePageChange: handlePageChange
                    })));
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* perPage */"1",
  /* currentPage */1
];

var make = UserList;

exports.deleteUser = deleteUser;
exports.reducer = reducer;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.initialState = initialState;
exports.searchPlaceholder = searchPlaceholder;
exports.make = make;
/* react Not a pure module */
