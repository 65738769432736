// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");

function fetchTickets(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/tickets", (function (param) {
                return Json_decode.field("tickets", (function (param) {
                              return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function fetchTicketWorkers(param) {
  var partial_arg = Ticket$ReactHooksTemplate.Assignee.decode;
  var partial_arg$1 = function (param) {
    return Json_decode.list(partial_arg, param);
  };
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                return Json_decode.field("recovery_agents", partial_arg$1, param);
              }));
}

function create(ticket) {
  return /* record */[
          /* ticket */ticket,
          /* enabled */false
        ];
}

function ticket(t) {
  return t[/* ticket */0];
}

function ticketId(t) {
  return Ticket$ReactHooksTemplate.id(t[/* ticket */0]);
}

function enabled(t) {
  return t[/* enabled */1];
}

function forceStatus(enabled, t) {
  return /* record */[
          /* ticket */t[/* ticket */0],
          /* enabled */enabled
        ];
}

function toggleStatus(t) {
  return /* record */[
          /* ticket */t[/* ticket */0],
          /* enabled */!t[/* enabled */1]
        ];
}

var TicketStatus = {
  create: create,
  ticket: ticket,
  ticketId: ticketId,
  enabled: enabled,
  forceStatus: forceStatus,
  toggleStatus: toggleStatus
};

var emptyForm = /* record */[
  /* ticketStatuses : [] */0,
  /* assignees : [] */0,
  /* selected */undefined,
  /* selectAll */false
];

function reducer(state, action) {
  var match = state[/* loading */0];
  if (match) {
    if (typeof action !== "number" && !action.tag) {
      var init = state[/* form */3];
      return /* record */[
              /* loading */false,
              /* toggleFilter */state[/* toggleFilter */1],
              /* scope */state[/* scope */2],
              /* form : record */[
                /* ticketStatuses */action[0],
                /* assignees */action[1],
                /* selected */init[/* selected */2],
                /* selectAll */init[/* selectAll */3]
              ]
            ];
    }
    return /* record */[
            /* loading */state[/* loading */0],
            /* toggleFilter */state[/* toggleFilter */1],
            /* scope */state[/* scope */2],
            /* form */emptyForm
          ];
  } else if (typeof action === "number") {
    switch (action) {
      case /* ToggleSelectAll */0 :
          var partial_arg = !state[/* form */3][/* selectAll */3];
          var ticketStatuses = List.map((function (param) {
                  return forceStatus(partial_arg, param);
                }), state[/* form */3][/* ticketStatuses */0]);
          var init$1 = state[/* form */3];
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* scope */state[/* scope */2],
                  /* form : record */[
                    /* ticketStatuses */ticketStatuses,
                    /* assignees */init$1[/* assignees */1],
                    /* selected */init$1[/* selected */2],
                    /* selectAll */!state[/* form */3][/* selectAll */3]
                  ]
                ];
      case /* ToggleFilter */1 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* toggleFilter */!state[/* toggleFilter */1],
                  /* scope */state[/* scope */2],
                  /* form */state[/* form */3]
                ];
      case /* Loading */2 :
          return /* record */[
                  /* loading */true,
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* scope */state[/* scope */2],
                  /* form */state[/* form */3]
                ];
      
    }
  } else {
    switch (action.tag | 0) {
      case /* DisplayList */0 :
          var init$2 = state[/* form */3];
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* scope */state[/* scope */2],
                  /* form : record */[
                    /* ticketStatuses */action[0],
                    /* assignees */action[1],
                    /* selected */init$2[/* selected */2],
                    /* selectAll */init$2[/* selectAll */3]
                  ]
                ];
      case /* UpdateAssignee */1 :
          var init$3 = state[/* form */3];
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* scope */state[/* scope */2],
                  /* form : record */[
                    /* ticketStatuses */init$3[/* ticketStatuses */0],
                    /* assignees */init$3[/* assignees */1],
                    /* selected */action[0],
                    /* selectAll */init$3[/* selectAll */3]
                  ]
                ];
      case /* ToggleTicketStatus */2 :
          var ticketStatus = action[0];
          var ticketStatuses$1 = List.map((function (x) {
                  var match = Caml_obj.caml_equal(ticketStatus, x);
                  if (match) {
                    return toggleStatus(x);
                  } else {
                    return x;
                  }
                }), state[/* form */3][/* ticketStatuses */0]);
          var init$4 = state[/* form */3];
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* scope */state[/* scope */2],
                  /* form : record */[
                    /* ticketStatuses */ticketStatuses$1,
                    /* assignees */init$4[/* assignees */1],
                    /* selected */init$4[/* selected */2],
                    /* selectAll */init$4[/* selectAll */3]
                  ]
                ];
      case /* UpdateViewFilter */3 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* scope */action[0],
                  /* form */state[/* form */3]
                ];
      
    }
  }
}

function assignTicket(assignee, tickets) {
  var params = { };
  params["assignee_id"] = Ticket$ReactHooksTemplate.Assignee.id(assignee);
  var ticketIds = Belt_List.map(tickets, Ticket$ReactHooksTemplate.id);
  params["ids"] = Json_encode.list((function (prim) {
          return prim;
        }), ticketIds);
  var ticket = { };
  ticket["ticket"] = params;
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/assign", ticket);
}

function scopeFromString(scope) {
  var match = scope.toLowerCase();
  switch (match) {
    case "all" :
        return /* All */0;
    case "assigned" :
        return /* Assigned */1;
    case "unassigned" :
        return /* Unassigned */2;
    default:
      return /* All */0;
  }
}

function scopeToString(param) {
  switch (param) {
    case /* All */0 :
        return "all";
    case /* Assigned */1 :
        return "assigned";
    case /* Unassigned */2 :
        return "unassigned";
    
  }
}

function fetchTicketDetails(param) {
  return fetchTickets(/* () */0).then((function (tickets) {
                return fetchTicketWorkers(/* () */0).then((function (workers) {
                              var ticketStatuses = List.map(create, tickets);
                              return Promise.resolve(/* tuple */[
                                          ticketStatuses,
                                          workers
                                        ]);
                            }));
              }));
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* perPage */"1"
];

function s(prim) {
  return prim;
}

function TicketManager(Props) {
  var scope = Props.scope;
  var search = Props.search;
  var initialState_002 = /* scope */scopeFromString(scope);
  var initialState = /* record */[
    /* loading */true,
    /* toggleFilter */false,
    initialState_002,
    /* form */emptyForm
  ];
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return /* record */[
                  /* sortedColumn */"created_at",
                  /* sortingOrder */"desc"
                ];
        }));
  var setSortingInfo = match$1[1];
  var sortingInfo = match$1[0];
  var match$2 = React.useState((function () {
          return emptyPageInfo;
        }));
  var setPageInfo = match$2[1];
  var pageInfo = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setOpenSortModal = match$3[1];
  var queryParams = Querystringify.parse(search);
  var match$4 = Js_dict.get(queryParams, "page");
  var currentPage;
  if (match$4 !== undefined) {
    var x = match$4;
    if (x === "") {
      currentPage = 1;
    } else {
      var page = Belt_Int.fromString(x);
      currentPage = page !== undefined ? page : 1;
    }
  } else {
    currentPage = 1;
  }
  var match$5 = Js_dict.get(queryParams, "sortedCol");
  var sortedColumn;
  if (match$5 !== undefined) {
    var x$1 = match$5;
    sortedColumn = x$1 === "" ? "" : x$1;
  } else {
    sortedColumn = "";
  }
  var match$6 = Js_dict.get(queryParams, "sortOrder");
  var sortingOrder;
  if (match$6 !== undefined) {
    var x$2 = match$6;
    sortingOrder = x$2 === "" ? "" : x$2;
  } else {
    sortingOrder = "";
  }
  var fetchTickets = function (param) {
    var pageNumber = String(currentPage);
    var match = sortingInfo[/* sortedColumn */0] === "";
    var sortedCol = match ? sortedColumn : sortingInfo[/* sortedColumn */0];
    var match$1 = sortingInfo[/* sortingOrder */1] === "";
    var sortOrder = match$1 ? sortingOrder : sortingInfo[/* sortingOrder */1];
    var match$2 = sortedCol !== "" && sortOrder !== "";
    var url = match$2 ? "/api/v1/tickets?page=" + (String(pageNumber) + ("&scope=" + (String(scope) + ("&sortedCol=" + (String(sortedCol) + ("&sortOrder=" + (String(sortOrder) + ""))))))) : "/api/v1/tickets?page=" + (String(pageNumber) + ("&scope=" + (String(scope) + "")));
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + (String(url) + "")).then((function (param) {
                  var headers = param[0];
                  var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
                  var perPage$1 = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
                  var tickets = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("tickets", (function (param) {
                                            return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                                          }), json));
                        }));
                  return Promise.resolve(/* tuple */[
                              tickets,
                              /* tuple */[
                                total,
                                perPage$1
                              ]
                            ]);
                }));
  };
  var fetchTicketWorkers = function (param) {
    var partial_arg = Ticket$ReactHooksTemplate.Assignee.decode;
    var partial_arg$1 = function (param) {
      return Json_decode.list(partial_arg, param);
    };
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                  return Json_decode.field("recovery_agents", partial_arg$1, param);
                }));
  };
  var fetchTicketDetails = function (param) {
    return fetchTickets(/* () */0).then((function (param) {
                  var pageInfo = param[1];
                  var tickets = param[0];
                  return fetchTicketWorkers(/* () */0).then((function (workers) {
                                return tickets.then((function (tickets) {
                                              var ticketStatuses = List.map(create, tickets);
                                              return Promise.resolve(/* tuple */[
                                                          /* tuple */[
                                                            ticketStatuses,
                                                            workers
                                                          ],
                                                          pageInfo
                                                        ]);
                                            }));
                              }));
                }));
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* Loading */2);
          fetchTicketDetails(/* () */0).then((function (param) {
                  var ticketDetails = param[0];
                  var pageInfo = param[1];
                  var perPage = pageInfo[1];
                  var total = pageInfo[0];
                  Curry._1(setPageInfo, (function (param) {
                          return /* record */[
                                  /* total */total,
                                  /* perPage */perPage
                                ];
                        }));
                  return Promise.resolve(Curry._1(dispatch, /* DisplayList */Block.__(0, [
                                    ticketDetails[0],
                                    ticketDetails[1]
                                  ])));
                }));
          return ;
        }), /* array */[currentPage]);
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* Loading */2);
    fetchTicketDetails(/* () */0).then((function (param) {
            var ticketDetails = param[0];
            var pageInfo = param[1];
            var perPage = pageInfo[1];
            var total = pageInfo[0];
            Curry._1(setPageInfo, (function (param) {
                    return /* record */[
                            /* total */total,
                            /* perPage */perPage
                          ];
                  }));
            return Promise.resolve(Curry._1(dispatch, /* DisplayList */Block.__(0, [
                              ticketDetails[0],
                              ticketDetails[1]
                            ])));
          }));
    return /* () */0;
  };
  var handlePageChange = function (pageNumber) {
    var scopeParam = scopeToString(state[/* scope */2]);
    var pageNumber$1 = String(pageNumber);
    var sortedCol = sortingInfo[/* sortedColumn */0];
    var sortOrder = sortingInfo[/* sortingOrder */1];
    return ReasonReactRouter.push("/tickets/manage/" + (String(scopeParam) + ("?page=" + (String(pageNumber$1) + ("&sortedCol=" + (String(sortedCol) + ("&sortOrder=" + (String(sortOrder) + ""))))))));
  };
  var applySort = function (param) {
    var scopeParam = scopeToString(state[/* scope */2]);
    var sortedCol = sortingInfo[/* sortedColumn */0];
    var sortOrder = sortingInfo[/* sortingOrder */1];
    ReasonReactRouter.push("/tickets/manage/" + (String(scopeParam) + ("?page=1&sortedCol=" + (String(sortedCol) + ("&sortOrder=" + (String(sortOrder) + ""))))));
    Curry._1(dispatch, /* Loading */2);
    fetchTicketDetails(/* () */0).then((function (param) {
            var ticketDetails = param[0];
            var pageInfo = param[1];
            var perPage = pageInfo[1];
            var total = pageInfo[0];
            Curry._1(setPageInfo, (function (param) {
                    return /* record */[
                            /* total */total,
                            /* perPage */perPage
                          ];
                  }));
            return Promise.resolve(Curry._1(dispatch, /* DisplayList */Block.__(0, [
                              ticketDetails[0],
                              ticketDetails[1]
                            ])));
          }));
    return /* () */0;
  };
  var selectedScope = function (param) {
    var scopeParam = scopeToString(state[/* scope */2]);
    if (scopeParam === "all") {
      return "All Tickets";
    } else {
      return scopeParam;
    }
  };
  var match$7 = state[/* loading */0];
  if (match$7) {
    return React.createElement("p", undefined, "Fetching tickets...");
  } else {
    var emailText = function (assignee) {
      return Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.Assignee.email(assignee));
    };
    var match$8 = state[/* form */3][/* selected */2];
    var selectedValue = match$8 !== undefined ? emailText(match$8) : "";
    var isSomeTicketSelected = Belt_List.some(state[/* form */3][/* ticketStatuses */0], enabled);
    var isSelectionEmpty = Belt_Option.isNone(state[/* form */3][/* selected */2]);
    var selectionDisabledCSS = isSomeTicketSelected ? "" : "hidden";
    var submitButtonStatus = isSomeTicketSelected && !isSelectionEmpty;
    var submitDisabledCSS = submitButtonStatus ? "" : "hidden";
    var selectedTickets = Belt_List.map(Belt_List.keep(state[/* form */3][/* ticketStatuses */0], enabled), ticket);
    var postTicketAssignment = function (assignee) {
      if (selectedTickets) {
        assignTicket(assignee, selectedTickets).then((function (response) {
                  return Promise.resolve((console.log(response), /* () */0));
                })).then((function (param) {
                return Promise.resolve((window.location.reload(), /* () */0));
              }));
        return /* () */0;
      } else {
        return /* () */0;
      }
    };
    var handleTicketAssignment = function (_event) {
      return Belt_Option.mapWithDefault(state[/* form */3][/* selected */2], /* () */0, postTicketAssignment);
    };
    var handleUpdateScope = function (scope, _event) {
      Curry._1(dispatch, /* UpdateViewFilter */Block.__(3, [scope]));
      var scopeParam = scopeToString(scope);
      var sortedCol = sortingInfo[/* sortedColumn */0];
      var sortOrder = sortingInfo[/* sortingOrder */1];
      ReasonReactRouter.push("/tickets/manage/" + (String(scopeParam) + ("?sortedCol=" + (String(sortedCol) + ("&sortOrder=" + (String(sortOrder) + ""))))));
      window.location.reload();
      return /* () */0;
    };
    var ticketScope = function (scope) {
      switch (scope) {
        case /* All */0 :
            return (function (param) {
                return true;
              });
        case /* Assigned */1 :
            return (function (x) {
                return Belt_Option.isSome(Ticket$ReactHooksTemplate.assignee(x[/* ticket */0]));
              });
        case /* Unassigned */2 :
            return (function (x) {
                return Belt_Option.isNone(Ticket$ReactHooksTemplate.assignee(x[/* ticket */0]));
              });
        
      }
    };
    var ticketPriority = function (priority) {
      switch (priority) {
        case "High" :
            return "bg-orange-200 text-orange-500";
        case "Low" :
            return "bg-green-100 text-green-600";
        case "Medium" :
            return "bg-yellow-200 text-yellow-500";
        case "red_alert" :
            return "bg-lavenderblush text-firebrick";
        default:
          return "bg-white text-blackcoral";
      }
    };
    var ticketPriorityString = function (priority) {
      if (priority === "red_alert") {
        return "Red Alert";
      } else {
        return priority;
      }
    };
    var bgStatusColor = function (status) {
      switch (status) {
        case "Closed" :
            return "bg-firebrick";
        case "Open" :
            return "bg-candypink";
        case "Parked" :
            return "bg-yellow-400";
        case "Resolved" :
            return "bg-americangreen";
        default:
          return "hidden";
      }
    };
    var scopeTicketStatus = function (xs) {
      return Belt_List.keep(xs, ticketScope(state[/* scope */2]));
    };
    var total = Belt_List.length(state[/* form */3][/* ticketStatuses */0]);
    var enabled$1 = Belt_List.length(Belt_List.keep(state[/* form */3][/* ticketStatuses */0], enabled));
    var isIndeterminate = enabled$1 > 0 && enabled$1 !== total;
    var match$9 = state[/* toggleFilter */1];
    var partial_arg = state[/* form */3];
    var tmp;
    if (match$3[0]) {
      var match$10 = sortingInfo[/* sortedColumn */0] !== "created_at" || sortingInfo[/* sortingOrder */1] === "desc";
      var match$11 = sortingInfo[/* sortedColumn */0] === "created_at" && sortingInfo[/* sortingOrder */1] === "asc";
      tmp = React.createElement("div", {
            className: "flex border border-gray-400 rounded-lg shadow-md flex-col justify-start\n                          items-start gap-2 p-4 z-10 absolute bg-white sort-modal"
          }, React.createElement("div", {
                className: "text-sm font-light text-aurometalsaurus"
              }, "Sort"), React.createElement("button", {
                className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                  match$10 ? "bg-lavenderweb border border-lavenderweb" : ""
                ),
                onClick: (function (param) {
                    return Curry._1(setSortingInfo, (function (param) {
                                  return /* record */[
                                          /* sortedColumn */"created_at",
                                          /* sortingOrder */"desc"
                                        ];
                                }));
                  })
              }, "Sort by latest first"), React.createElement("button", {
                className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                  match$11 ? "bg-lavenderweb border border-lavenderweb" : ""
                ),
                onClick: (function (param) {
                    return Curry._1(setSortingInfo, (function (param) {
                                  return /* record */[
                                          /* sortedColumn */"created_at",
                                          /* sortingOrder */"asc"
                                        ];
                                }));
                  })
              }, "Sort by oldest first"), React.createElement("hr", {
                className: "border border-gray-200 w-full"
              }), React.createElement("div", {
                className: "flex justify-between w-full font-semibold text-sm gap-2"
              }, React.createElement("button", {
                    className: "py-2 w-1/2 border border-gray-300 text-aurometalsaurus rounded",
                    onClick: (function (param) {
                        return Curry._1(setOpenSortModal, (function (param) {
                                      return false;
                                    }));
                      })
                  }, "Cancel"), React.createElement("button", {
                    className: "py-2 w-1/2 bg-pigmentblue text-white rounded",
                    onClick: (function (param) {
                        applySort(/* () */0);
                        return Curry._1(setOpenSortModal, (function (param) {
                                      return false;
                                    }));
                      })
                  }, "Apply")));
    } else {
      tmp = React.createElement(React.Fragment, undefined);
    }
    return React.createElement(React.Fragment, undefined, match$9 ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return Curry._1(dispatch, /* ToggleFilter */1);
                        }),
                      onApply: onApplyFilter,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Overview"), React.createElement("div", {
                            className: "flex gap-4"
                          }, React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n              shadow-inner flex rounded py-2 px-3 text-xs",
                                id: "filters",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleFilter */1);
                                  })
                              }, React.createElement("img", {
                                    className: "h-14-px mr-2-px",
                                    src: Image$ReactHooksTemplate.filterButton
                                  }), "Filters"), React.createElement("button", {
                                className: "bg-pigmentblue border border-gray-400\n                  flex rounded py-2 px-3 text-xs text-white",
                                onClick: (function ($$event) {
                                    $$event.preventDefault();
                                    Http$ReactHooksTemplate.fetchGetJSON("/api/v1/tickets/download").then((function (file_name) {
                                            return Promise.resolve((console.log(file_name), ReasonReactRouter.push("/tickets/downloadList")));
                                          }));
                                    return /* () */0;
                                  })
                              }, React.createElement("img", {
                                    className: "h-14-px mr-1 filter-white",
                                    src: Image$ReactHooksTemplate.download
                                  }), "Download"))), React.createElement("div", {
                        className: "flex items-center px-30-px"
                      }, React.createElement("div", {
                            className: "dropdown mr-20-px"
                          }, React.createElement("div", {
                                className: "flex items-center text-sm font-normal capitalize justify-between"
                              }, React.createElement("p", {
                                    className: "mr-5-px"
                                  }, selectedScope(/* () */0)), React.createElement("img", {
                                    src: Image$ReactHooksTemplate.caretDown
                                  })), React.createElement("ul", {
                                className: "dropdown-list shadow text-gray-800 text-sm"
                              }, React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            return handleUpdateScope(/* All */0, param);
                                          })
                                      }, "All Tickets")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            return handleUpdateScope(/* Assigned */1, param);
                                          })
                                      }, "Assigned")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            return handleUpdateScope(/* Unassigned */2, param);
                                          })
                                      }, "Unassigned")))), React.createElement("div", {
                            className: "flex"
                          }, React.createElement(Pagination$ReactHooksTemplate.make, {
                                total: totalPages(Caml_format.caml_int_of_string(pageInfo[/* total */0]), Caml_format.caml_int_of_string(pageInfo[/* perPage */1])),
                                currentPage: currentPage,
                                handlePageChange: handlePageChange
                              })), React.createElement("div", {
                            className: "flex items-center px-30-px"
                          }, React.createElement("select", {
                                className: "" + (String(selectionDisabledCSS) + " block border px-3 py-2 border-gray-400\n                  w-52 bg-white rounded capitalize mr-6 text-sm"),
                                disabled: !isSomeTicketSelected,
                                value: selectedValue,
                                onChange: (function (param) {
                                    var form = partial_arg;
                                    var $$event = param;
                                    var emailText = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Utils$ReactHooksTemplate.formTargetValue($$event));
                                    if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, emailText)) {
                                      return Curry._1(dispatch, /* UpdateAssignee */Block.__(1, [undefined]));
                                    } else {
                                      var selected = List.find((function (assignee) {
                                              return Caml_obj.caml_equal(Ticket$ReactHooksTemplate.Assignee.email(assignee), emailText);
                                            }), form[/* assignees */1]);
                                      return Curry._1(dispatch, /* UpdateAssignee */Block.__(1, [selected]));
                                    }
                                  })
                              }, $$Array.of_list(List.map((function (assignee) {
                                          return React.createElement("option", {
                                                      key: String(Ticket$ReactHooksTemplate.Assignee.id(assignee)),
                                                      value: emailText(assignee)
                                                    }, emailText(assignee));
                                        }), /* :: */[
                                        Ticket$ReactHooksTemplate.Assignee.empty,
                                        state[/* form */3][/* assignees */1]
                                      ]))), React.createElement("button", {
                                className: "" + (String(submitDisabledCSS) + " bg-ghostwhite border border-gray-400 shadow-inner\n                  rounded py-2 px-3 text-sm hover:bg-palatinateblue hover:text-white"),
                                disabled: !submitButtonStatus,
                                onClick: handleTicketAssignment
                              }, "Assign Ticket"))), React.createElement("div", {
                        className: "flex px-30-px pb-30-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4"
                                      }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                            checked: state[/* form */3][/* selectAll */3],
                                            label: "",
                                            handleChange: (function (param) {
                                                return Curry._1(dispatch, /* ToggleSelectAll */0);
                                              }),
                                            indeterminate: isIndeterminate,
                                            checkboxClassName: "custom_checkbox"
                                          })), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Id", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Name", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Msisdn", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Email", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "TouchPoint", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Lob", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Shop Name", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Priority", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Assignee", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Escalation Level 2", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Status", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex cursor-pointer",
                                            onClick: (function (param) {
                                                return Curry._1(setOpenSortModal, (function (param) {
                                                              return true;
                                                            }));
                                              })
                                          }, "Created At", React.createElement("img", {
                                                className: "ml-4-px sort-img",
                                                src: Image$ReactHooksTemplate.heading
                                              })), tmp))), React.createElement("tbody", undefined, Belt_List.toArray(Belt_List.map(scopeTicketStatus(state[/* form */3][/* ticketStatuses */0]), (function (x) {
                                          var ticket = x[/* ticket */0];
                                          var match = Ticket$ReactHooksTemplate.touchPoint(ticket);
                                          var match$1 = Ticket$ReactHooksTemplate.lob(ticket);
                                          var match$2 = Ticket$ReactHooksTemplate.shopName(ticket);
                                          var match$3 = Ticket$ReactHooksTemplate.escalation_level_2(ticket);
                                          return React.createElement("tr", {
                                                      key: String(Ticket$ReactHooksTemplate.id(ticket)),
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                              checked: x[/* enabled */1],
                                                              label: "",
                                                              handleChange: (function (param) {
                                                                  return Curry._1(dispatch, /* ToggleTicketStatus */Block.__(2, [x]));
                                                                }),
                                                              checkboxClassName: "custom_checkbox"
                                                            })), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("a", {
                                                              href: "/tickets/show/" + String(Ticket$ReactHooksTemplate.id(ticket))
                                                            }, String(Ticket$ReactHooksTemplate.id(ticket)))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Ticket$ReactHooksTemplate.name(x[/* ticket */0])), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Curry._1(Domain$ReactHooksTemplate.$$String.Msisdn.show, Ticket$ReactHooksTemplate.msisdn(ticket))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.email(ticket))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match !== undefined ? match : ""), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match$1 !== undefined ? match$1 : ""), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match$2 !== undefined ? match$2 : ""), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "flex justify-center items-center font-normal border border-gray-300 rounded-2xl\n                            py-1 px-3 uppercase text-10-px " + ticketPriority(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))
                                                            }, ticketPriorityString(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket))))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.mapWithDefault(Ticket$ReactHooksTemplate.assignee(ticket), "", (function (x) {
                                                                return Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.Assignee.email(x));
                                                              }))), React.createElement("td", undefined, match$3 !== undefined ? match$3 : ""), React.createElement("td", {
                                                          className: "px-4 py-2 uppercase"
                                                        }, React.createElement("div", {
                                                              className: "text-10-px px-2 py-1 rounded-2xl flex gap-1 items-center\n                                          border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                            }, React.createElement("div", {
                                                                  className: "h-3 w-3 rounded-full " + bgStatusColor(Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket)))
                                                                }), Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket)))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Ticket$ReactHooksTemplate.createdAt(ticket).format("lll")));
                                        }))))))));
  }
}

var make = TicketManager;

exports.fetchTickets = fetchTickets;
exports.fetchTicketWorkers = fetchTicketWorkers;
exports.TicketStatus = TicketStatus;
exports.emptyForm = emptyForm;
exports.reducer = reducer;
exports.assignTicket = assignTicket;
exports.scopeFromString = scopeFromString;
exports.scopeToString = scopeToString;
exports.fetchTicketDetails = fetchTicketDetails;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.s = s;
exports.make = make;
/* react Not a pure module */
