// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Recharts = require("recharts");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var Recharts$ReactHooksTemplate = require("../common/Recharts.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

function BarChartComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var drillDownKey = Props.drillDownKey;
  var onClick = Props.onClick;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var renderLegend = function (value, param) {
    return React.createElement("span", {
                className: "text-xs"
              }, value);
  };
  var layout = GenericChartAttribute$ReactHooksTemplate.layout(chartAttribute);
  var partial_arg = AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute));
  var partial_arg$1 = function (param) {
    return Json_decode.field(partial_arg, Json_decode.string, param);
  };
  var decodeXAxisTableData = Json_decode.list((function (param) {
          return Json_decode.withDefault("", partial_arg$1, param);
        }), Belt_Option.getExn(GenericChartAttribute$ReactHooksTemplate.data(chartAttribute)));
  var decodeYAxisTableData = Belt_List.map(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (item) {
          var partial_arg = AxisData$ReactHooksTemplate.key(item);
          var partial_arg$1 = function (param) {
            return Json_decode.field(partial_arg, Json_decode.$$float, param);
          };
          return Json_decode.list((function (param) {
                        return Json_decode.withDefault(0, partial_arg$1, param);
                      }), Belt_Option.getExn(GenericChartAttribute$ReactHooksTemplate.data(chartAttribute)));
        }));
  var isStacked = Belt_List.reduce(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), false, (function (acc, xs) {
          var match = Belt_Option.isSome(AxisData$ReactHooksTemplate.stackId(xs));
          if (match) {
            return true;
          } else {
            return false;
          }
        }));
  var data = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
  var dataLength;
  if (data !== undefined) {
    var match = Js_json.decodeArray(Caml_option.valFromOption(data));
    dataLength = match !== undefined ? match.length : 0;
  } else {
    dataLength = 0;
  }
  var match$1 = dataLength > 5;
  var tmp;
  if (match$1) {
    var match$2 = enlarged || Belt_Option.getWithDefault(GenericChartAttribute$ReactHooksTemplate.width(chartAttribute), "") === "w-full";
    var tmp$1;
    if (match$2) {
      tmp$1 = undefined;
    } else {
      var match$3 = dataLength > 10;
      tmp$1 = match$3 ? 9 : dataLength - 1 | 0;
    }
    tmp = React.createElement(Recharts.Brush, {
          dataKey: AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)),
          endIndex: tmp$1,
          height: enlarged ? 20 : 10
        });
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var match$4 = isStacked || !enlarged;
  var tmp$2;
  if (match$4) {
    tmp$2 = React.createElement(React.Fragment, undefined);
  } else {
    var axisData = Belt_List.head(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute));
    tmp$2 = React.createElement("div", {
          className: "flex p-2 items-center justify-stretch"
        }, React.createElement("div", undefined, React.createElement("div", {
                  className: "px-4 text-xs font-bold border border-1 p-1 flex-grow",
                  style: {
                    borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                  }
                }, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute))), $$Array.of_list(Belt_List.map(decodeXAxisTableData, (function (item) {
                        var match = item === "";
                        return React.createElement("div", {
                                    className: "px-4 text-xs font-bold border border-1\n                border-t-0 p-1",
                                    style: {
                                      borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                                    }
                                  }, match ? "default" : item);
                      })))), React.createElement("div", undefined, React.createElement("div", {
                  className: "px-4 text-xs font-bold border border-1 border-l-0\n             p-1 flex-grow",
                  style: {
                    borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                  }
                }, axisData !== undefined ? AxisData$ReactHooksTemplate.key(Caml_option.valFromOption(axisData)) : ""), $$Array.of_list(List.flatten(Belt_List.map(decodeYAxisTableData, (function (list) {
                            return Belt_List.map(list, (function (item) {
                                          return React.createElement("div", {
                                                      className: "px-4 text-xs font-bold border border-1\n                  border-t-0 border-l-0 p-1 flex-grow",
                                                      style: {
                                                        borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                                                      }
                                                    }, item.toString());
                                        }));
                          }))))));
  }
  return React.createElement("div", {
              className: "flex flex-col items-center"
            }, React.createElement("div", {
                  className: " w-full " + (String(chartHeight) + " p-20-px ")
                }, React.createElement(Recharts.ResponsiveContainer, {
                      children: React.createElement(Recharts.BarChart, {
                            data: GenericChartAttribute$ReactHooksTemplate.data(chartAttribute),
                            margin: Recharts$ReactHooksTemplate.Margin.make(20, 0, 0, 0),
                            layout: (function () {
                                  switch (layout) {
                                    case -367674250 :
                                        return "vertical";
                                    case 365165796 :
                                        return "horizontal";
                                    
                                  }
                                })(),
                            children: null
                          }, React.createElement(Recharts.CartesianGrid, { }), layout !== -367674250 ? React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(isStacked ? /* Num */[0] : /* PreserveStartEnd */2)(2, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, 0, /* bottom */-445061397)("middle", 10, false))), undefined, 80, undefined, undefined, undefined, undefined, /* () */0)) : React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(undefined)(1, undefined, undefined, undefined, undefined), undefined, undefined, undefined, undefined, /* number */561678025, undefined, /* () */0)), layout >= 365165796 ? React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(1, undefined, undefined, undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      /* () */0
                                    ])) : React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(isStacked ? /* Num */[0] : /* PreserveStartEnd */2)(1, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), undefined, 300, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, /* top */5793429)("end", 10, true))), [
                                      undefined,
                                      undefined,
                                      undefined,
                                      /* category */762063614,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      /* () */0
                                    ])), tmp, React.createElement(Recharts.Tooltip, {
                                cursor: Recharts$ReactHooksTemplate.Cursor.make("#F0F0F0"),
                                contentStyle: {
                                  fontSize: "10pt"
                                }
                              }), React.createElement(Recharts.Legend, {
                                formatter: renderLegend
                              }), $$Array.of_list(Belt_List.mapWithIndex(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (i, xs) {
                                      var match = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
                                      return React.createElement(Recharts.Bar, {
                                                  dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                  barSize: 30,
                                                  fill: ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                  stackId: Belt_Option.getWithDefault(AxisData$ReactHooksTemplate.stackId(xs), ""),
                                                  onClick: (function (data, param) {
                                                      var key = Json_decode.field(AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), Json_decode.string, Belt_Option.getExn((data == null) ? undefined : Caml_option.some(data)).payload);
                                                      return Curry._3(onClick, key, chartId, GenericChartAttribute$ReactHooksTemplate.onClick(chartAttribute));
                                                    }),
                                                  children: null,
                                                  key: String(i)
                                                }, React.createElement(Recharts.LabelList, {
                                                      dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                      fill: "#F0F0F0",
                                                      fontSize: 10
                                                    }), match !== undefined ? Belt_Array.map(Belt_Option.getWithDefault(Js_json.decodeArray(Caml_option.valFromOption(match)), /* array */[]), (function (xs) {
                                                          var tmp;
                                                          if (drillDownKey !== undefined) {
                                                            var match = drillDownKey === "";
                                                            if (match) {
                                                              tmp = ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute));
                                                            } else {
                                                              var match$1 = Caml_obj.caml_equal(Js_json.decodeString(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(xs), { }), AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute))), null)), String(drillDownKey));
                                                              tmp = match$1 ? ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)) : "#d8d8d8";
                                                            }
                                                          } else {
                                                            tmp = ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute));
                                                          }
                                                          return React.createElement(Recharts.Cell, {
                                                                      key: String(chartId),
                                                                      fill: tmp
                                                                    });
                                                        })) : React.createElement(React.Fragment, undefined));
                                    }))))
                    })), tmp$2);
}

var make = BarChartComponent;

exports.make = make;
/* react Not a pure module */
