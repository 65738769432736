// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Recharts = require("recharts");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var Recharts$ReactHooksTemplate = require("../common/Recharts.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

function ComposedChartComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var drillDownKey = Props.drillDownKey;
  var onClick = Props.onClick;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var renderToolTip = function (data) {
    var payload = data["payload"];
    if (payload == null) {
      return React.createElement("div", undefined);
    } else {
      var newPayload = Js_dict.values(payload);
      var match = newPayload.length;
      if (match !== 0) {
        console.log(Caml_array.caml_array_get(newPayload, 0));
        var payloadData = Js_dict.get(Caml_array.caml_array_get(newPayload, 0), "payload");
        if (payloadData !== undefined) {
          return React.createElement("div", {
                      className: "recharts-default-tooltip bg-white border border-solid border-1\n          border-gray-300 p-10-px"
                    }, React.createElement("ul", {
                          className: "recharts-tooltip-itemlist"
                        }, Belt_Array.map(Js_dict.entries(Caml_option.valFromOption(payloadData)), (function (param) {
                                return React.createElement("li", {
                                            className: "recharts-tooltip-item py-3-px"
                                          }, React.createElement("span", undefined, String(param[0])), React.createElement("span", undefined, " : "), React.createElement("span", undefined, param[1]));
                              }))));
        } else {
          return React.createElement("div", undefined);
        }
      } else {
        return React.createElement("div", undefined);
      }
    }
  };
  var renderLegend = function (value, param) {
    return React.createElement("span", {
                className: "text-xs"
              }, value);
  };
  var match = chartHeight === "h-96";
  var data = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
  var dataLength;
  if (data !== undefined) {
    var match$1 = Js_json.decodeArray(Caml_option.valFromOption(data));
    dataLength = match$1 !== undefined ? match$1.length : 0;
  } else {
    dataLength = 0;
  }
  var match$2 = dataLength > 5;
  var tmp;
  if (match$2) {
    var match$3 = enlarged || Belt_Option.getWithDefault(GenericChartAttribute$ReactHooksTemplate.width(chartAttribute), "") === "w-full";
    var tmp$1;
    if (match$3) {
      tmp$1 = undefined;
    } else {
      var match$4 = dataLength > 10;
      tmp$1 = match$4 ? 9 : dataLength - 1 | 0;
    }
    tmp = React.createElement(Recharts.Brush, {
          dataKey: AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)),
          endIndex: tmp$1,
          height: enlarged ? 20 : 10
        });
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var xs = GenericChartAttribute$ReactHooksTemplate.yAxisList(chartAttribute);
  return React.createElement("div", {
              className: " w-full " + (String(chartHeight) + " p-20-px ")
            }, React.createElement(Recharts.ResponsiveContainer, {
                  children: React.createElement(Recharts.ComposedChart, {
                        data: GenericChartAttribute$ReactHooksTemplate.data(chartAttribute),
                        margin: Recharts$ReactHooksTemplate.Margin.make(20, 20, 0, 0),
                        children: null
                      }, React.createElement(Recharts.CartesianGrid, { }), React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(/* Num */[0])(enlarged ? 4 : 2, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, 0, undefined)("middle", 20, undefined))), undefined, match ? 100 : 30, undefined, undefined, undefined, undefined, /* () */0)), (console.log(GenericChartAttribute$ReactHooksTemplate.yAxisList(chartAttribute)), React.createElement(React.Fragment, undefined)), tmp, xs ? $$Array.of_list(Belt_List.map(xs, (function (x) {
                                    var match = AxisData$ReactHooksTemplate.yAxisId(x);
                                    var match$1 = AxisData$ReactHooksTemplate.yAxisOrientation(x);
                                    var tmp;
                                    if (match$1 !== undefined) {
                                      var match$2 = match$1 === "right";
                                      tmp = match$2 ? /* right */-379319332 : /* left */-944764921;
                                    } else {
                                      tmp = /* left */-944764921;
                                    }
                                    return React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(undefined, undefined, match !== undefined ? match : "left", undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                                    undefined,
                                                    tmp,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* () */0
                                                  ]));
                                  }))) : $$Array.of_list(/* :: */[
                              React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(undefined, undefined, "left", undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                        undefined,
                                        /* left */-944764921,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        /* () */0
                                      ])),
                              /* [] */0
                            ]), React.createElement(Recharts.Tooltip, {
                            content: renderToolTip
                          }), React.createElement(Recharts.Legend, {
                            formatter: renderLegend
                          }), $$Array.of_list(Belt_List.mapWithIndex(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (i, xs) {
                                  var match = AxisData$ReactHooksTemplate.type_(xs);
                                  switch (match) {
                                    case /* Line */0 :
                                        var match$1 = AxisData$ReactHooksTemplate.fill(xs);
                                        var match$2 = AxisData$ReactHooksTemplate.key(xs);
                                        var tmp = match$2 === "KPI" ? "3 4 5 2" : "";
                                        var match$3 = AxisData$ReactHooksTemplate.yAxisId(xs);
                                        var match$4 = AxisData$ReactHooksTemplate.key(xs);
                                        var tmp$1 = match$4 === "KPI" ? React.createElement(React.Fragment, undefined) : React.createElement(Recharts.LabelList, {
                                                dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                position: "top",
                                                fill: "black",
                                                fontSize: 12
                                              });
                                        return React.createElement(Recharts.Line, {
                                                    type: "monotone",
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    stroke: match$1 !== undefined ? match$1 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                    strokeDasharray: tmp,
                                                    connectNulls: true,
                                                    yAxisId: match$3 !== undefined ? match$3 : "left",
                                                    children: tmp$1,
                                                    key: String(i)
                                                  });
                                    case /* Bar */1 :
                                        var match$5 = AxisData$ReactHooksTemplate.fill(xs);
                                        var match$6 = AxisData$ReactHooksTemplate.yAxisId(xs);
                                        var match$7 = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
                                        return React.createElement(Recharts.Bar, {
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    barSize: 30,
                                                    fill: match$5 !== undefined ? match$5 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                    stackId: Belt_Option.getWithDefault(AxisData$ReactHooksTemplate.stackId(xs), ""),
                                                    yAxisId: match$6 !== undefined ? match$6 : "left",
                                                    onClick: (function (data, param) {
                                                        var key = Json_decode.field(AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), Json_decode.string, Belt_Option.getExn((data == null) ? undefined : Caml_option.some(data)).payload);
                                                        return Curry._3(onClick, key, chartId, GenericChartAttribute$ReactHooksTemplate.onClick(chartAttribute));
                                                      }),
                                                    children: match$7 !== undefined ? Belt_Array.map(Belt_Option.getWithDefault(Js_json.decodeArray(Caml_option.valFromOption(match$7)), /* array */[]), (function (x) {
                                                              var tmp;
                                                              if (drillDownKey !== undefined) {
                                                                var match = Caml_obj.caml_equal(Js_json.decodeString(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(x), { }), AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute))), null)), String(drillDownKey));
                                                                if (match) {
                                                                  var match$1 = AxisData$ReactHooksTemplate.fill(xs);
                                                                  tmp = match$1 !== undefined ? match$1 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute));
                                                                } else {
                                                                  tmp = "#d8d8d8";
                                                                }
                                                              } else {
                                                                var match$2 = AxisData$ReactHooksTemplate.fill(xs);
                                                                tmp = match$2 !== undefined ? match$2 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute));
                                                              }
                                                              return React.createElement(Recharts.Cell, {
                                                                          key: String(chartId),
                                                                          fill: tmp
                                                                        });
                                                            })) : React.createElement(React.Fragment, undefined),
                                                    key: String(i)
                                                  });
                                    case /* Scatter */2 :
                                        var match$8 = AxisData$ReactHooksTemplate.fill(xs);
                                        var match$9 = AxisData$ReactHooksTemplate.yAxisId(xs);
                                        return React.createElement(Recharts.Scatter, {
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    fill: match$8 !== undefined ? match$8 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                    yAxisId: match$9 !== undefined ? match$9 : "left",
                                                    children: React.createElement(Recharts.LabelList, {
                                                          dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                          position: "top",
                                                          fill: "black",
                                                          fontSize: 12
                                                        }),
                                                    key: String(i)
                                                  });
                                    
                                  }
                                }))))
                }));
}

var make = ComposedChartComponent;

exports.make = make;
/* react Not a pure module */
