// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");

function MobileNumberEditor(Props) {
  var mobileNumber = Props.mobileNumber;
  var onMobileNumberChange = Props.onMobileNumberChange;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium"
                    }, "Placeholder"), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Placeholder",
                      type: "text",
                      value: MobileNumber$ReactHooksTemplate.placeholder(mobileNumber),
                      onChange: (function ($$event) {
                          return Curry._1(onMobileNumberChange, MobileNumber$ReactHooksTemplate.setPlaceholder(mobileNumber, Utils$ReactHooksTemplate.formTargetValue($$event)));
                        })
                    })));
}

var make = MobileNumberEditor;

exports.make = make;
/* react Not a pure module */
