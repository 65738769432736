// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");

function emptyKpi(param) {
  return /* record */[
          /* id */undefined,
          /* userId */undefined,
          /* email */Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0),
          /* filterFieldId */"",
          /* filterField */"",
          /* filterValue */"",
          /* kpiValue */0
        ];
}

function id(t) {
  return t[/* id */0];
}

function userId(t) {
  return t[/* userId */1];
}

function email(t) {
  return t[/* email */2];
}

function filterField(t) {
  return t[/* filterField */4];
}

function filterFieldId(t) {
  return t[/* filterFieldId */3];
}

function filterValue(t) {
  return t[/* filterValue */5];
}

function kpiValue(t) {
  return t[/* kpiValue */6];
}

function setFilterField(t, x) {
  return /* record */[
          /* id */t[/* id */0],
          /* userId */t[/* userId */1],
          /* email */t[/* email */2],
          /* filterFieldId */t[/* filterFieldId */3],
          /* filterField */x,
          /* filterValue */t[/* filterValue */5],
          /* kpiValue */t[/* kpiValue */6]
        ];
}

function setFilterValue(t, y) {
  return /* record */[
          /* id */t[/* id */0],
          /* userId */t[/* userId */1],
          /* email */t[/* email */2],
          /* filterFieldId */t[/* filterFieldId */3],
          /* filterField */t[/* filterField */4],
          /* filterValue */y,
          /* kpiValue */t[/* kpiValue */6]
        ];
}

function setKpi(t, z) {
  return /* record */[
          /* id */t[/* id */0],
          /* userId */t[/* userId */1],
          /* email */t[/* email */2],
          /* filterFieldId */t[/* filterFieldId */3],
          /* filterField */t[/* filterField */4],
          /* filterValue */t[/* filterValue */5],
          /* kpiValue */z
        ];
}

function decode(json) {
  return /* record */[
          /* id */Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          /* userId */Json_decode.field("user_id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          /* email */Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Json_decode.field("user", (function (param) {
                      return Json_decode.field("email", Json_decode.string, param);
                    }), json)),
          /* filterFieldId */Json_decode.field("details", (function (param) {
                  return Json_decode.field("field_id", Json_decode.string, param);
                }), json),
          /* filterField */Json_decode.field("details", (function (param) {
                  return Json_decode.field("field", Json_decode.string, param);
                }), json),
          /* filterValue */Json_decode.field("details", (function (param) {
                  return Json_decode.field("value", Json_decode.string, param);
                }), json),
          /* kpiValue */Json_decode.field("details", (function (param) {
                  return Json_decode.field("kpi", Json_decode.$$int, param);
                }), json)
        ];
}

exports.emptyKpi = emptyKpi;
exports.id = id;
exports.userId = userId;
exports.email = email;
exports.filterField = filterField;
exports.filterFieldId = filterFieldId;
exports.filterValue = filterValue;
exports.kpiValue = kpiValue;
exports.setFilterField = setFilterField;
exports.setFilterValue = setFilterValue;
exports.setKpi = setKpi;
exports.decode = decode;
/* No side effect */
