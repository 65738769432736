// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReactHtmlParser = require("react-html-parser");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var WelcomePreview$ReactHooksTemplate = require("./WelcomePreview.bs.js");
var ThankYouPreview$ReactHooksTemplate = require("./ThankYouPreview.bs.js");

function domIdFortemplateIdentifiers(param) {
  switch (param) {
    case /* Question */0 :
        return "question";
    case /* WelcomeButton */1 :
        return "welcomeButton";
    case /* Unknown */2 :
        return "";
    
  }
}

function convertDomIdToTemplateIdentifier(id) {
  if (id === "question") {
    return /* Question */0;
  } else {
    return /* Unknown */2;
  }
}

function decodeNodeAttributes(json) {
  var id = Json_decode.optional((function (param) {
          return Json_decode.field("id", Json_decode.string, param);
        }), json);
  return convertDomIdToTemplateIdentifier(Belt_Option.getWithDefault(id, ""));
}

function decodeLogo(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("url", Json_decode.string, param);
              }), json);
}

function getQuestionFromPage(page) {
  if (typeof page === "number" || page.tag !== /* QuestionPage */1) {
    return ;
  } else {
    return Caml_option.some(page[1]);
  }
}

function SurveyBuilderPreview(Props) {
  var page = Props.page;
  var theme = Props.theme;
  var client = Props.client;
  var handleQuestionPreview = Props.handleQuestionPreview;
  var onWelcomeChange = Props.onWelcomeChange;
  var onThankYouChange = Props.onThankYouChange;
  var handleFormOnSubmit = Props.handleFormOnSubmit;
  Props.showPreviousButton;
  Props.showNextButton;
  Props.showSubmitButton;
  var match = Props.languageSelected;
  var languageSelected = match !== undefined ? match : SurveyLanguage$ReactHooksTemplate.$$default;
  var questionElement = function (customerInfo, language, question) {
    return React.createElement("div", {
                key: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question))
              }, Question$ReactHooksTemplate.preview(Curry.__1(handleQuestionPreview), customerInfo, theme, language, question));
  };
  var transform = function (customerInfo, node, index) {
    var match = decodeNodeAttributes(node.attribs);
    switch (match) {
      case /* Question */0 :
          var match$1 = getQuestionFromPage(page);
          var tmp;
          if (match$1 !== undefined) {
            var x = Caml_option.valFromOption(match$1);
            tmp = x !== undefined ? questionElement(customerInfo, languageSelected, x) : React.createElement(React.Fragment, undefined);
          } else {
            tmp = React.createElement(React.Fragment, undefined);
          }
          return React.createElement("div", {
                      className: "w-full"
                    }, tmp);
      case /* WelcomeButton */1 :
          return React.createElement(React.Fragment, undefined);
      case /* Unknown */2 :
          return ReactHtmlParser.convertNodeToElement(node, index, (function (param, param$1) {
                        return transform(customerInfo, param, param$1);
                      }));
      
    }
  };
  var defaultWelcome = Welcome$ReactHooksTemplate.make("Your opinion matters!", "", "Take the survey", Image$ReactHooksTemplate.surveyWelcomeBg);
  var welcomePreviewItem = function (welcome) {
    return Belt_Option.mapWithDefault(welcome, null, (function (param) {
                  return React.createElement("div", {
                              className: "h-full"
                            }, welcome !== undefined ? React.createElement(WelcomePreview$ReactHooksTemplate.make, {
                                    default: defaultWelcome,
                                    welcome: Caml_option.valFromOption(welcome),
                                    buttonBgColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                                    language: languageSelected,
                                    onWelcomeChange: onWelcomeChange
                                  }) : React.createElement(WelcomePreview$ReactHooksTemplate.make, {
                                    default: defaultWelcome,
                                    welcome: defaultWelcome,
                                    buttonBgColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(theme),
                                    language: languageSelected,
                                    onWelcomeChange: onWelcomeChange
                                  }));
                }));
  };
  var thankYouPreviewItem = function (thankYou) {
    return Belt_Option.mapWithDefault(thankYou, null, (function (thankYou) {
                  return React.createElement(ThankYouPreview$ReactHooksTemplate.make, {
                              thankYou: thankYou,
                              onThankYouChange: onThankYouChange
                            });
                }));
  };
  var match$1 = SurveyLanguage$ReactHooksTemplate.name(languageSelected) === "Arabic";
  var match$2 = Client$ReactHooksTemplate.name(client);
  var tmp;
  if (match$2 !== undefined) {
    if (typeof page === "number") {
      tmp = React.createElement(React.Fragment, undefined);
    } else {
      switch (page.tag | 0) {
        case /* Welcome */0 :
            tmp = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement("div", undefined), welcomePreviewItem(page[0])));
            break;
        case /* QuestionPage */1 :
            var customerInfo = page[2];
            tmp = ReactHtmlParser.default(page[0][/* default */1], {
                  transform: (function (param, param$1) {
                      return transform(customerInfo, param, param$1);
                    })
                });
            break;
        case /* ThankYou */2 :
            Curry._1(handleFormOnSubmit, /* () */0);
            tmp = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement("div", undefined), thankYouPreviewItem(page[0])));
            break;
        
      }
    }
  } else {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              text: "Loading Survey.."
            }));
  }
  return React.createElement("div", {
              className: "bg-white",
              dir: match$1 ? "rtl" : "",
              style: {
                color: SurveyTheme$ReactHooksTemplate.questionColor(theme),
                fontFamily: SurveyTheme$ReactHooksTemplate.font(theme),
                height: "calc(100vh - 190px)"
              }
            }, tmp);
}

var make = SurveyBuilderPreview;

exports.domIdFortemplateIdentifiers = domIdFortemplateIdentifiers;
exports.convertDomIdToTemplateIdentifier = convertDomIdToTemplateIdentifier;
exports.decodeNodeAttributes = decodeNodeAttributes;
exports.decodeLogo = decodeLogo;
exports.getQuestionFromPage = getQuestionFromPage;
exports.make = make;
/* react Not a pure module */
