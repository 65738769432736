// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function TextAreaInputNew(Props) {
  var label = Props.label;
  var value = Props.value;
  var handleChange = Props.handleChange;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "text-sm font-medium mb-2"
                }, label), React.createElement("textarea", {
                  className: "outline-none border border-gray-400 h-40 w-full rounded px-3 py-2 text-sm",
                  value: value,
                  onChange: handleChange
                }));
}

var make = TextAreaInputNew;

exports.make = make;
/* react Not a pure module */
