// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var LongTextDefaultForm$ReactHooksTemplate = require("./LongTextDefaultForm.bs.js");

function LongTextForm(Props) {
  var longText = Props.longText;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy(/* array */[
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, ""));
    } else {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, value));
    }
  };
  var match = LongText$ReactHooksTemplate.representation(longText);
  var backGroundCss = match >= 2 ? "bg-black bg-opacity-50 text-white" : "bg-tropicalbreeze";
  var match$1 = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match$1 !== undefined) {
    switch (match$1) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(LongTextDefaultForm$ReactHooksTemplate.make, {
                    longText: longText,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        return React.createElement("div", {
                    className: "flex flex-col"
                  }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                        text: LongText$ReactHooksTemplate.getTextTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                        description: LongText$ReactHooksTemplate.getDescriptionTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                        mandatory: mandatory,
                        surveyTheme: surveyTheme
                      }), React.createElement("p", {
                        className: "flex flex-wrap w-full"
                      }, React.createElement("textarea", {
                            className: "" + (String(backGroundCss) + " h-127-px overflow-x-hidden\n            w-full outline-none focus:shadow-outline rounded-lg p-2 "),
                            maxLength: 3500,
                            placeholder: LongText$ReactHooksTemplate.getPlaceholderTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                            rows: 5,
                            value: Belt_Option.getWithDefault(LongText$ReactHooksTemplate.answer(longText), ""),
                            onChange: handleOnchange
                          })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                          language: language
                        }) : React.createElement(React.Fragment, undefined));
    
  }
}

var make = LongTextForm;

exports.make = make;
/* react Not a pure module */
