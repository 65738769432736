// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Project$ReactHooksTemplate = require("./project.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");

function ProjectCreate(Props) {
  var toggleProjectCreate = Props.toggleProjectCreate;
  var match = React.useState((function () {
          return Project$ReactHooksTemplate.empty(/* () */0);
        }));
  var setProject = match[1];
  var project = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setErrors = match$1[1];
  var match$2 = Project$ReactHooksTemplate.name(project).length === 0;
  return React.createElement("div", {
              className: "flex flex-col justify-between h-full"
            }, React.createElement("div", {
                  className: "p-8 border-t"
                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                      label: "Project Name",
                      value: Project$ReactHooksTemplate.name(project),
                      handleChange: (function (param) {
                          var project$1 = project;
                          var e = param;
                          var name = e.target.value;
                          return Curry._1(setProject, (function (param) {
                                        return Project$ReactHooksTemplate.setName(project$1, name);
                                      }));
                        }),
                      maxLength: 100
                    }), Project$ReactHooksTemplate.name(project).length === 0 ? React.createElement("div", {
                        className: "my-2"
                      }, React.createElement("p", {
                            className: "text-red-500 text-xs float-right"
                          }, "Name can't be empty")) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                  className: "p-8 flex border-t border-gray-200 gap-3 font-medium\n                    absolute bottom-0 text-base w-full z-30 "
                }, React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n        flex rounded py-3 px-6 text-xs w-full justify-center",
                      id: "close_modal",
                      onClick: (function (param) {
                          return Curry._1(toggleProjectCreate, /* () */0);
                        })
                    }, "Discard"), React.createElement("button", {
                      className: "bg-palatinateblue border border-gray-400\n        flex rounded py-3 px-6 text-xs text-white w-full justify-center " + (
                        match$2 ? "disabled-input" : ""
                      ),
                      disabled: Project$ReactHooksTemplate.name(project).length === 0,
                      onClick: (function (param) {
                          var project$1 = project;
                          var params = { };
                          params["survey"] = Project$ReactHooksTemplate.toJson(project$1);
                          Http$ReactHooksTemplate.fetchPostJSON("/api/v1/projects", params).then((function (response) {
                                  var status = Json_decode.field("status", (function (param) {
                                          return Json_decode.withDefault("failed", Json_decode.string, param);
                                        }), response);
                                  if (status === "success") {
                                    Curry._1(toggleProjectCreate, /* () */0);
                                    ReasonReactRouter.push("/projects/all");
                                  } else {
                                    Curry._1(setErrors, (function (param) {
                                            return "Something went wrong. Please contact admin";
                                          }));
                                  }
                                  return Promise.resolve(/* () */0);
                                }));
                          return /* () */0;
                        })
                    }, "Save")));
}

var make = ProjectCreate;

exports.make = make;
/* react Not a pure module */
