// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");

function Tickets(Props) {
  var content = Props.content;
  var match = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match[0];
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var match$1 = url[/* path */0];
  var selected;
  if (match$1 && match$1[0] === "tickets") {
    var match$2 = match$1[1];
    if (match$2) {
      switch (match$2[0]) {
        case "agent_summary" :
            selected = match$2[1] ? "" : "agent_summary";
            break;
        case "downloadList" :
            selected = match$2[1] ? "" : "downloads";
            break;
        case "manage" :
            var match$3 = match$2[1];
            selected = match$3 && !match$3[1] ? "overview" : "";
            break;
        case "work" :
            var match$4 = match$2[1];
            selected = match$4 && match$4[1] ? "" : "work_on_tickets";
            break;
        default:
          selected = "";
      }
    } else {
      selected = "";
    }
  } else {
    selected = "";
  }
  var match$5 = Curry._1(hasPermission, /* ManageTickets */1);
  var tmp;
  if (match$5) {
    var match$6 = selected === "overview";
    var match$7 = selected === "overview";
    tmp = React.createElement("a", {
          href: "/tickets/manage/all"
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$6 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$7 ? "filter-white" : ""
                  ),
                  title: "Overview",
                  src: Image$ReactHooksTemplate.sidebarOverview
                })));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var match$8 = selected === "work_on_tickets";
  var match$9 = selected === "work_on_tickets";
  var match$10 = Curry._1(hasPermission, /* ManageTickets */1);
  var tmp$1;
  if (match$10) {
    var match$11 = selected === "agent_summary";
    var match$12 = selected === "agent_summary";
    tmp$1 = React.createElement("a", {
          href: "/tickets/agent_summary"
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$11 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$12 ? "filter-white" : ""
                  ),
                  title: "Agent Summary",
                  src: Image$ReactHooksTemplate.agentSummary
                })));
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined);
  }
  var match$13 = Curry._1(hasPermission, /* ManageTickets */1);
  var tmp$2;
  if (match$13) {
    var match$14 = selected === "downloadsList";
    var match$15 = selected === "downloadsList";
    tmp$2 = React.createElement("a", {
          href: "/tickets/downloadList"
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$14 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$15 ? "filter-white" : ""
                  ),
                  title: "Download List",
                  src: Image$ReactHooksTemplate.downloadBox
                })));
  } else {
    tmp$2 = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "min-h-screen w-16 bg-ghostwhite border-r border-gray-300"
                }, tmp, React.createElement("a", {
                      href: "/tickets/work/all"
                    }, React.createElement("div", {
                          className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                            match$8 ? "bg-palatinateblue border-palatinateblue" : ""
                          )
                        }, React.createElement("img", {
                              className: "m-auto inline-block align-middle max-h-full\n              max-w-full w-auto h-auto inset-0 absolute " + (
                                match$9 ? "filter-white" : ""
                              ),
                              title: "Work on Tickets",
                              src: Image$ReactHooksTemplate.workOnTickets
                            }))), tmp$1, tmp$2), React.createElement("div", {
                  className: "w-screen"
                }, content));
}

var make = Tickets;

exports.make = make;
/* react Not a pure module */
