// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Mode$ReactHooksTemplate = require("./mode.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Campaign$ReactHooksTemplate = require("./campaign.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var SearchForm$ReactHooksTemplate = require("./SearchForm.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var CampaignForm$ReactHooksTemplate = require("./CampaignForm.bs.js");

var initialState = /* record */[
  /* loading */true,
  /* campaigns : [] */0,
  /* campaignChangeModalToggle */false,
  /* outsideClick */true,
  /* selectedCampaign */undefined,
  /* formView : CampaignNew */0
];

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* FetchCampaignsInProgress */0) {
      return /* record */[
              /* loading */true,
              /* campaigns */state[/* campaigns */1],
              /* campaignChangeModalToggle */state[/* campaignChangeModalToggle */2],
              /* outsideClick */state[/* outsideClick */3],
              /* selectedCampaign */state[/* selectedCampaign */4],
              /* formView */state[/* formView */5]
            ];
    } else {
      return /* record */[
              /* loading */state[/* loading */0],
              /* campaigns */state[/* campaigns */1],
              /* campaignChangeModalToggle */!state[/* campaignChangeModalToggle */2],
              /* outsideClick */state[/* outsideClick */3],
              /* selectedCampaign */state[/* selectedCampaign */4],
              /* formView */state[/* formView */5]
            ];
    }
  } else {
    switch (action.tag | 0) {
      case /* FetchCampaignsCompleted */0 :
          return /* record */[
                  /* loading */false,
                  /* campaigns */action[0],
                  /* campaignChangeModalToggle */state[/* campaignChangeModalToggle */2],
                  /* outsideClick */state[/* outsideClick */3],
                  /* selectedCampaign */state[/* selectedCampaign */4],
                  /* formView */state[/* formView */5]
                ];
      case /* SetOutsideClick */1 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* campaigns */state[/* campaigns */1],
                  /* campaignChangeModalToggle */state[/* campaignChangeModalToggle */2],
                  /* outsideClick */action[0],
                  /* selectedCampaign */state[/* selectedCampaign */4],
                  /* formView */state[/* formView */5]
                ];
      case /* SetCampaign */2 :
          var campaign = action[0];
          if (campaign !== undefined) {
            return /* record */[
                    /* loading */state[/* loading */0],
                    /* campaigns */state[/* campaigns */1],
                    /* campaignChangeModalToggle */state[/* campaignChangeModalToggle */2],
                    /* outsideClick */state[/* outsideClick */3],
                    /* selectedCampaign */Caml_option.some(Caml_option.valFromOption(campaign)),
                    /* formView */state[/* formView */5]
                  ];
          } else {
            return /* record */[
                    /* loading */state[/* loading */0],
                    /* campaigns */state[/* campaigns */1],
                    /* campaignChangeModalToggle */state[/* campaignChangeModalToggle */2],
                    /* outsideClick */state[/* outsideClick */3],
                    /* selectedCampaign */undefined,
                    /* formView */state[/* formView */5]
                  ];
          }
      case /* SetFormView */3 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* campaigns */state[/* campaigns */1],
                  /* campaignChangeModalToggle */state[/* campaignChangeModalToggle */2],
                  /* outsideClick */state[/* outsideClick */3],
                  /* selectedCampaign */state[/* selectedCampaign */4],
                  /* formView */action[0]
                ];
      
    }
  }
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* per_page */"1"
];

var searchPlaceholder = "Search Campaigns";

function CampaignList(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return emptyPageInfo;
        }));
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var match$2 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var match$3 = React.useState((function () {
          return ;
        }));
  var setSearchKey = match$3[1];
  var searchKey = match$3[0];
  var queryParams = Querystringify.parse(search);
  var match$4 = Js_dict.get(queryParams, "page");
  var currentPage;
  if (match$4 !== undefined) {
    var x = match$4;
    if (x === "") {
      currentPage = 1;
    } else {
      var page = Belt_Int.fromString(x);
      currentPage = page !== undefined ? page : 1;
    }
  } else {
    currentPage = 1;
  }
  var match$5 = Js_dict.get(queryParams, "search");
  var currentSearchKey;
  if (match$5 !== undefined) {
    var x$1 = match$5;
    currentSearchKey = x$1 === "" ? undefined : x$1;
  } else {
    currentSearchKey = undefined;
  }
  var handleSearch = function (param) {
    if (searchKey !== undefined) {
      return ReasonReactRouter.push("/campaigns/all?search=" + (String(searchKey) + ""));
    } else {
      return ReasonReactRouter.push("/campaigns/all?");
    }
  };
  var handlePageChange = function (pageNumber) {
    var pageNumber$1 = String(pageNumber);
    if (currentSearchKey !== undefined) {
      return ReasonReactRouter.push("/campaigns/all?page=" + (String(pageNumber$1) + ("&search=" + (String(currentSearchKey) + ""))));
    } else {
      return ReasonReactRouter.push("/campaigns/all?page=" + (String(pageNumber$1) + ""));
    }
  };
  var toggleCampaign = function (option) {
    Curry._1(dispatch, /* ToggleCampaignModal */1);
    if (option === "save") {
      var url = "/api/v1/campaigns?page=" + String(currentPage);
      Curry._1(dispatch, /* FetchCampaignsInProgress */0);
      Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then((function (param) {
              var headers = param[0];
              var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
              var per_page = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
              return Promise.resolve((Curry._1(setPageInfo, (function (param) {
                                  return /* record */[
                                          /* total */total,
                                          /* per_page */per_page
                                        ];
                                })), param[1].then((function (json) {
                                  return Promise.resolve(Json_decode.field("campaigns", (function (param) {
                                                      return Json_decode.list(Campaign$ReactHooksTemplate.decode, param);
                                                    }), json)).then((function (campaigns) {
                                                return Promise.resolve(Curry._1(dispatch, /* FetchCampaignsCompleted */Block.__(0, [campaigns])));
                                              }));
                                }))));
            }));
      return /* () */0;
    } else {
      return 0;
    }
  };
  var totalPages = function (total_records, per_page) {
    return Caml_int32.div(total_records - 1 | 0, per_page) + 1 | 0;
  };
  var randomBgColor = function (param) {
    return Belt_Array.getExn(Belt_Array.shuffle($$Array.of_list(/* :: */[
                        "bg-toolbox",
                        /* :: */[
                          "bg-arylideyellowlight",
                          /* :: */[
                            "bg-iguanagreen",
                            /* :: */[
                              "bg-vistablue",
                              /* :: */[
                                "bg-iceberg",
                                /* :: */[
                                  "bg-scooter",
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])), 0);
  };
  React.useEffect((function () {
          var pageNumber = String(currentPage);
          var url = currentSearchKey !== undefined ? "/api/v1/campaigns?page=" + (String(pageNumber) + ("&search=" + (String(currentSearchKey) + ""))) : "/api/v1/campaigns?page=" + (String(pageNumber) + "");
          Curry._1(dispatch, /* FetchCampaignsInProgress */0);
          Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + (String(url) + "")).then((function (param) {
                  var headers = param[0];
                  var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
                  var per_page = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
                  return Promise.resolve((Curry._1(setPageInfo, (function (param) {
                                      return /* record */[
                                              /* total */total,
                                              /* per_page */per_page
                                            ];
                                    })), Curry._1(setSearchKey, (function (param) {
                                      return currentSearchKey;
                                    })), param[1].then((function (json) {
                                      return Promise.resolve(Json_decode.field("campaigns", (function (param) {
                                                          return Json_decode.list(Campaign$ReactHooksTemplate.decode, param);
                                                        }), json)).then((function (campaigns) {
                                                    return Promise.resolve(Curry._1(dispatch, /* FetchCampaignsCompleted */Block.__(0, [campaigns])));
                                                  }));
                                    }))));
                }));
          return ;
        }), /* tuple */[
        currentPage,
        currentSearchKey
      ]);
  var match$6 = state[/* loading */0];
  var tmp;
  if (match$6) {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.loadingList,
              text: "Loading Campaigns"
            }));
  } else {
    var match$7 = Curry._1(match$2[0], /* CreateCampaign */15);
    var match$8 = state[/* campaignChangeModalToggle */2];
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "flex items-center border-b border-gray-300 px-30-px py-16-px justify-between"
            }, React.createElement("h2", {
                  className: "text-xl font-semibold"
                }, "Campaigns")), React.createElement("div", {
              className: "flex justify-between px-30-px pt-20-px items-center"
            }, React.createElement("div", {
                  className: "flex items-center"
                }, React.createElement(SearchForm$ReactHooksTemplate.make, {
                      searchKey: searchKey,
                      setSearchKey: setSearchKey,
                      handleSearch: handleSearch,
                      searchPlaceholder: searchPlaceholder
                    }), React.createElement(Pagination$ReactHooksTemplate.make, {
                      total: totalPages(Caml_format.caml_int_of_string(pageInfo[/* total */0]), Caml_format.caml_int_of_string(pageInfo[/* per_page */1])),
                      currentPage: currentPage,
                      handlePageChange: handlePageChange
                    })), match$7 ? React.createElement("div", {
                    className: "bg-ghostwhite border border-gray-400\n                    shadow-inner flex rounded py-2 px-3 text-xs",
                    id: "add-campaign",
                    onClick: (function (param) {
                        Curry._1(dispatch, /* SetFormView */Block.__(3, [/* CampaignNew */0]));
                        Curry._1(dispatch, /* SetCampaign */Block.__(2, [undefined]));
                        return Curry._1(dispatch, /* ToggleCampaignModal */1);
                      })
                  }, React.createElement("img", {
                        className: "h-14-px mr-2-px",
                        src: Image$ReactHooksTemplate.plusCircle
                      }), "New Campaign") : React.createElement(React.Fragment, undefined)), React.createElement("div", {
              className: "flex px-30-px pb-30-px"
            }, React.createElement("table", {
                  className: "text-left table-auto w-full border-separate text-sm font-medium"
                }, React.createElement("thead", {
                      className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                    }, React.createElement("tr", undefined, React.createElement("th", {
                              className: "px-4 py-3"
                            }, React.createElement("div", {
                                  className: "flex"
                                }, "Name", React.createElement("img", {
                                      className: "ml-4-px",
                                      src: Image$ReactHooksTemplate.heading
                                    }))), React.createElement("th", {
                              className: "px-4 py-3"
                            }, React.createElement("div", {
                                  className: "flex"
                                }, "Campaign ID", React.createElement("img", {
                                      className: "ml-4-px",
                                      src: Image$ReactHooksTemplate.heading
                                    }))), React.createElement("th", {
                              className: "px-4 py-3"
                            }, React.createElement("div", {
                                  className: "flex"
                                }, "Survey", React.createElement("img", {
                                      className: "ml-4-px",
                                      src: Image$ReactHooksTemplate.heading
                                    }))), React.createElement("th", {
                              className: "px-4 py-3"
                            }, React.createElement("div", {
                                  className: "flex"
                                }, "Modes", React.createElement("img", {
                                      className: "ml-4-px",
                                      src: Image$ReactHooksTemplate.heading
                                    }))), React.createElement("th", {
                              className: "px-4 py-3"
                            }, React.createElement("div", {
                                  className: "flex"
                                }, "")))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (campaign) {
                                var match = state[/* selectedCampaign */4];
                                var tmp;
                                if (match !== undefined) {
                                  var match$1 = state[/* outsideClick */3] === false && Caml_obj.caml_equal(Caml_option.valFromOption(match), campaign);
                                  tmp = match$1 ? "block" : "hidden";
                                } else {
                                  tmp = "hidden";
                                }
                                return React.createElement("tr", {
                                            key: String(Campaign$ReactHooksTemplate.id(campaign)),
                                            className: "border-b border-bottom-gray-600"
                                          }, React.createElement("td", {
                                                className: "px-4 py-3"
                                              }, React.createElement("a", {
                                                    className: "flex items-center",
                                                    href: "/campaigns/" + (String(Campaign$ReactHooksTemplate.id(campaign)) + "/dashboard")
                                                  }, React.createElement("div", {
                                                        className: "w-8 h-8 flex items-center text-white uppercase mr-3 rounded-lg " + randomBgColor(/* () */0)
                                                      }, React.createElement("div", {
                                                            className: "m-auto"
                                                          }, Campaign$ReactHooksTemplate.name(campaign)[0])), Campaign$ReactHooksTemplate.name(campaign))), React.createElement("td", {
                                                className: "px-4 py-3"
                                              }, React.createElement("a", {
                                                    href: "/campaigns/" + (String(Campaign$ReactHooksTemplate.id(campaign)) + "/dashboard")
                                                  }, Campaign$ReactHooksTemplate.uid(campaign))), React.createElement("td", {
                                                className: "px-4 py-3"
                                              }, React.createElement("a", {
                                                    href: "/campaigns/" + (String(Campaign$ReactHooksTemplate.id(campaign)) + "/dashboard")
                                                  }, Campaign$ReactHooksTemplate.surveyName(campaign))), React.createElement("td", {
                                                className: "px-4 py-3"
                                              }, React.createElement("a", {
                                                    href: "/campaigns/" + (String(Campaign$ReactHooksTemplate.id(campaign)) + "/dashboard")
                                                  }, List.fold_left((function (x, y) {
                                                          var match = x !== "";
                                                          return y + (
                                                                  match ? " | " + x : x
                                                                );
                                                        }), "", List.sort($$String.compare, List.map(Mode$ReactHooksTemplate.modeType, Campaign$ReactHooksTemplate.modes(campaign)))))), React.createElement("td", {
                                                className: "px-4 py-3"
                                              }, React.createElement("div", {
                                                    className: "flex justify-end items-center relative"
                                                  }, React.createElement("img", {
                                                        className: "h-18-px",
                                                        src: Image$ReactHooksTemplate.verticalDots,
                                                        onClick: (function (param) {
                                                            Curry._1(dispatch, /* SetOutsideClick */Block.__(1, [!state[/* outsideClick */3]]));
                                                            return Curry._1(dispatch, /* SetCampaign */Block.__(2, [Caml_option.some(campaign)]));
                                                          })
                                                      }), React.createElement("div", {
                                                        className: "flex flex-col items-search p-2 absolute rounded border border-gray-400 bg-white\n                                                     text-darkcharcoal text-xs question-dropdown z-50 opacity-100 gap-2 " + tmp
                                                      }, React.createElement("a", {
                                                            className: "flex text-xs gap-2",
                                                            onClick: (function (param) {
                                                                Curry._1(dispatch, /* SetOutsideClick */Block.__(1, [true]));
                                                                Curry._1(dispatch, /* SetCampaign */Block.__(2, [Caml_option.some(campaign)]));
                                                                Curry._1(dispatch, /* SetFormView */Block.__(3, [/* Edit */[campaign]]));
                                                                return Curry._1(dispatch, /* ToggleCampaignModal */1);
                                                              })
                                                          }, React.createElement("img", {
                                                                className: "h-12-px",
                                                                src: Image$ReactHooksTemplate.edit
                                                              }), "Edit")))));
                              }), state[/* campaigns */1]))))), match$8 ? React.createElement("div", {
                className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                style: {
                  height: "calc(100vh + 5rem)"
                }
              }, React.createElement("div", {
                    className: "bg-white modal-campaign z-10 shadow relative border border-gray-300 pointer-events-auto"
                  }, React.createElement("div", {
                        className: "border-t border-gray-200 text-base font-medium h-432-px"
                      }, React.createElement(CampaignForm$ReactHooksTemplate.make, {
                            toggleCampaign: toggleCampaign,
                            toggleModal: (function (param) {
                                return Curry._1(dispatch, /* ToggleCampaignModal */1);
                              }),
                            selectedCampaign: state[/* selectedCampaign */4]
                          })))) : React.createElement(React.Fragment, undefined));
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = CampaignList;

exports.initialState = initialState;
exports.reducer = reducer;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.emptyPageInfo = emptyPageInfo;
exports.searchPlaceholder = searchPlaceholder;
exports.make = make;
/* react Not a pure module */
