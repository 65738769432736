// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("../filters/filter.bs.js");
var Loading$ReactHooksTemplate = require("./Loading.bs.js");
var Dashboard$ReactHooksTemplate = require("./dashboard.bs.js");
var ListFilter$ReactHooksTemplate = require("../filters/listFilter.bs.js");
var DashboardComponent$ReactHooksTemplate = require("./DashboardComponent.bs.js");

function lableFunction(a, b) {
  console.log(a);
  console.log(b);
  return (
  '<p>' + a.title + '</p><ul><li>NPS: ' + a.nps + '</li></ul>'
  );
}

var initialState = /* record */[
  /* loading */true,
  /* dashboard */undefined,
  /* dashboards : [] */0,
  /* drillDownChartDetails */undefined,
  /* currentRef */undefined,
  /* breadCrumbs : [] */0
];

function reducer(state, param) {
  if (typeof param === "number") {
    return /* record */[
            /* loading */true,
            /* dashboard */state[/* dashboard */1],
            /* dashboards */state[/* dashboards */2],
            /* drillDownChartDetails */state[/* drillDownChartDetails */3],
            /* currentRef */state[/* currentRef */4],
            /* breadCrumbs */state[/* breadCrumbs */5]
          ];
  } else {
    switch (param.tag | 0) {
      case /* FetchDashboardCompleted */0 :
          var dashboards = param[0];
          var defaultDashboard = List.find_opt(Dashboard$ReactHooksTemplate.$$default, dashboards);
          var dashboard = defaultDashboard !== undefined ? Caml_option.some(Caml_option.valFromOption(defaultDashboard)) : Belt_List.head(dashboards);
          return /* record */[
                  /* loading */false,
                  /* dashboard */dashboard,
                  /* dashboards */dashboards,
                  /* drillDownChartDetails */state[/* drillDownChartDetails */3],
                  /* currentRef */dashboard,
                  /* breadCrumbs */dashboard !== undefined ? /* :: */[
                      Caml_option.valFromOption(dashboard),
                      /* [] */0
                    ] : /* [] */0
                ];
      case /* DrillDown */1 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* dashboard */state[/* dashboard */1],
                  /* dashboards */state[/* dashboards */2],
                  /* drillDownChartDetails */param[0],
                  /* currentRef */state[/* currentRef */4],
                  /* breadCrumbs */state[/* breadCrumbs */5]
                ];
      case /* ChangeDashboard */2 :
          var x = param[0];
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* dashboard */Caml_option.some(x),
                  /* dashboards */state[/* dashboards */2],
                  /* drillDownChartDetails */undefined,
                  /* currentRef */Caml_option.some(x),
                  /* breadCrumbs : :: */[
                    x,
                    /* [] */0
                  ]
                ];
      case /* GoToLinkedDashboard */3 :
          var x$1 = param[0];
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* dashboard */state[/* dashboard */1],
                  /* dashboards */state[/* dashboards */2],
                  /* drillDownChartDetails */undefined,
                  /* currentRef */Caml_option.some(x$1),
                  /* breadCrumbs */Belt_List.reverse(/* :: */[
                        x$1,
                        state[/* breadCrumbs */5]
                      ])
                ];
      case /* GoToClickedDashboard */4 :
          var match = Belt_List.take(state[/* breadCrumbs */5], param[1]);
          var breadCrumbs = match !== undefined ? match : /* [] */0;
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* dashboard */state[/* dashboard */1],
                  /* dashboards */state[/* dashboards */2],
                  /* drillDownChartDetails */undefined,
                  /* currentRef */Caml_option.some(param[0]),
                  /* breadCrumbs */breadCrumbs
                ];
      
    }
  }
}

function Dashboards(Props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var clearTempFilter = function (param) {
    return Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/filters", Json_encode.object_(/* :: */[
                    /* tuple */[
                      "scope",
                      "temporary"
                    ],
                    /* :: */[
                      /* tuple */[
                        "filters",
                        Json_encode.list(Filter$ReactHooksTemplate.encode, /* [] */0)
                      ],
                      /* [] */0
                    ]
                  ]));
  };
  React.useEffect((function () {
          clearTempFilter(/* () */0).then((function (param) {
                      Curry._1(dispatch, /* FetchDashboardInProgress */0);
                      return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/dashboards", Dashboard$ReactHooksTemplate.fromJson).then((function (xs) {
                                      return Promise.resolve((setTimeout((function (param) {
                                                          return Curry._1(dispatch, /* FetchDashboardCompleted */Block.__(0, [xs]));
                                                        }), Utils$ReactHooksTemplate.timeout), /* () */0));
                                    })).catch((function (err) {
                                    console.log(err);
                                    return Promise.resolve(Curry._1(dispatch, /* FetchDashboardCompleted */Block.__(0, [/* [] */0])));
                                  }));
                    })).then((function (result) {
                    return Promise.resolve((console.log(result), /* () */0));
                  })).catch((function (err) {
                  return Promise.resolve((console.log(err), /* () */0));
                }));
          return ;
        }), ([]));
  var onDashboardChange = function (dashboard) {
    return Curry._1(dispatch, /* ChangeDashboard */Block.__(2, [dashboard]));
  };
  var match$1 = state[/* loading */0];
  var tmp;
  if (match$1) {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading Dashboards"
            }));
  } else {
    var match$2 = state[/* currentRef */4];
    var match$3 = state[/* dashboard */1];
    tmp = match$2 !== undefined && match$3 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(DashboardComponent$ReactHooksTemplate.make, {
                dashboard: Caml_option.valFromOption(match$3),
                dashboards: state[/* dashboards */2],
                drillDownChartDetails: state[/* drillDownChartDetails */3],
                onClick: (function (key, chartId, xs) {
                    if (typeof xs === "number") {
                      return /* () */0;
                    } else if (xs.tag) {
                      var match = List.find_opt((function (y) {
                              return Dashboard$ReactHooksTemplate.name(y) === key;
                            }), state[/* dashboards */2]);
                      if (match !== undefined) {
                        var dashboard = Caml_option.valFromOption(match);
                        clearTempFilter(/* () */0).then((function (param) {
                                return Promise.resolve(Curry._1(dispatch, /* GoToLinkedDashboard */Block.__(3, [dashboard])));
                              }));
                        return /* () */0;
                      } else {
                        return /* () */0;
                      }
                    } else {
                      var x = xs[0];
                      var match$1 = state[/* drillDownChartDetails */3];
                      var tmp;
                      if (match$1 !== undefined) {
                        var match$2 = match$1[1] === key;
                        tmp = match$2 ? true : false;
                      } else {
                        tmp = false;
                      }
                      var empty = tmp;
                      var selectedFilter;
                      if (empty) {
                        selectedFilter = /* [] */0;
                      } else {
                        var match$3 = Filter$ReactHooksTemplate.filterType(x);
                        var tmp$1;
                        tmp$1 = match$3.tag === /* List */2 ? /* List */Block.__(2, [ListFilter$ReactHooksTemplate.setSelected(key, match$3[0])]) : Filter$ReactHooksTemplate.filterType(x);
                        selectedFilter = /* :: */[
                          Filter$ReactHooksTemplate.setFilterTypes(tmp$1, x),
                          /* [] */0
                        ];
                      }
                      Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/filters", Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "scope",
                                      "temporary"
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "filters",
                                        Json_encode.list(Filter$ReactHooksTemplate.encode, selectedFilter)
                                      ],
                                      /* [] */0
                                    ]
                                  ])).then((function (param) {
                                return Promise.resolve(empty ? Curry._1(dispatch, /* DrillDown */Block.__(1, [/* tuple */[
                                                      chartId,
                                                      ""
                                                    ]])) : Curry._1(dispatch, /* DrillDown */Block.__(1, [/* tuple */[
                                                      chartId,
                                                      key
                                                    ]])));
                              })).catch((function (err) {
                              return Promise.resolve((console.log(err), /* () */0));
                            }));
                      return /* () */0;
                    }
                  }),
                onDashboardChange: onDashboardChange,
                breadCrumbs: state[/* breadCrumbs */5],
                currentRef: Caml_option.valFromOption(match$2),
                breadCrumbsOnClick: (function (item, index) {
                    clearTempFilter(/* () */0).then((function (param) {
                            return Promise.resolve(Curry._1(dispatch, /* GoToClickedDashboard */Block.__(4, [
                                              item,
                                              index
                                            ])));
                          }));
                    return /* () */0;
                  })
              })) : "No Dashboards are loaded for the application.";
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = Dashboards;

exports.lableFunction = lableFunction;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
