// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var User$ReactHooksTemplate = require("./user.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");

function isDeleteKeyword(inputKeyword) {
  return inputKeyword.toUpperCase() === "DELETE";
}

function ConfirmationDialog(Props) {
  var user = Props.user;
  var handleCancel = Props.handleCancel;
  var handleDelete = Props.handleDelete;
  var handleClose = Props.handleClose;
  var match = React.useState((function () {
          return "";
        }));
  var setKeyword = match[1];
  var keyword = match[0];
  var handleDeleteConfirmation = function ($$event) {
    return Curry._1(setKeyword, $$event.target.value);
  };
  var match$1 = keyword.toUpperCase() !== "DELETE";
  var disabledCSS = match$1 ? "opacity-75" : "hover:bg-red-600 hover:text-white";
  return React.createElement("div", {
              className: "bg-white w-640-px mx-auto mt-100-px p-30-px"
            }, React.createElement("div", {
                  className: "flex justify-between items-center mb-10-px"
                }, React.createElement("p", {
                      className: "mx-auto font-light"
                    }, "Are you sure?"), React.createElement("button", {
                      onClick: handleClose
                    }, React.createElement("img", {
                          src: Image$ReactHooksTemplate.closeIcon
                        }))), React.createElement("p", {
                  className: "mb-10-px"
                }, "You are about to delete this user:"), React.createElement("p", {
                  className: "mb-30-px font-semibold"
                }, Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, User$ReactHooksTemplate.email(user))), React.createElement("div", {
                  className: "flex items-start bg-red-100 p-10-px rounded mb-20-px font-light"
                }, React.createElement("img", {
                      className: "mr-10-px",
                      src: Image$ReactHooksTemplate.errorInfoIcon
                    }), React.createElement("p", {
                      className: ""
                    }, "This deletes the user permanently and this action cannot be undone.")), React.createElement("p", {
                  className: "mb-10-px"
                }, "Type DELETE to confirm:"), React.createElement("input", {
                  className: "w-2/3 p-10-px mb-20-px outline-none border border-gray-500 focus:border-teal-500 uppercase",
                  type: "text",
                  value: keyword,
                  onChange: handleDeleteConfirmation
                }), React.createElement("div", {
                  className: "flex items-center"
                }, React.createElement("button", {
                      className: "" + (String(disabledCSS) + " text-red-600 py-6-px px-10-px border border-red-400 mr-20-px"),
                      disabled: keyword.toUpperCase() !== "DELETE",
                      onClick: handleDelete
                    }, "Delete"), React.createElement("button", {
                      className: "hover:text-teal-500 py-6-px px-10-px border border-gray-400 hover:border-teal-300",
                      onClick: handleCancel
                    }, "Cancel")));
}

var make = ConfirmationDialog;

exports.isDeleteKeyword = isDeleteKeyword;
exports.make = make;
/* react Not a pure module */
