// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var RatingSmileySet$ReactHooksTemplate = require("./ratingSmileySet.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var SmileyRatingDefaultForm$ReactHooksTemplate = require("./SmileyRatingDefaultForm.bs.js");

function SmileyRatingForm(Props) {
  var smileyRating = Props.smileyRating;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var style = function (selected) {
    if (selected) {
      return {
              borderColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(surveyTheme),
              borderWidth: "1px",
              outline: "None"
            };
    } else {
      return {
              outline: "None"
            };
    }
  };
  var smileyButton = function (ratingType, ratingSmileySet, selected) {
    var match = SmileyRating$ReactHooksTemplate.showLabels(smileyRating);
    return React.createElement("button", {
                key: String(SmileyRating$ReactHooksTemplate.ratingTypeToRating(ratingType)),
                className: "animated bounceIn flex flex-col items-center p-2 w-1/5 gap-1",
                style: style(selected),
                onClick: (function (e) {
                    e.preventDefault();
                    return Curry._1(onChange, SmileyRating$ReactHooksTemplate.setAnswer(smileyRating, ratingType));
                  })
              }, React.createElement("span", {
                    className: "text-5xl small:text-4xl"
                  }, React.createElement("img", {
                        src: SmileyRating$ReactHooksTemplate.ratingTypeToImage(ratingType, ratingSmileySet)
                      })), match ? React.createElement("div", {
                      className: "text-xs"
                    }, SmileyRating$ReactHooksTemplate.ratingTypeToString(ratingType)) : React.createElement(React.Fragment, undefined));
  };
  var match = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match !== undefined) {
    switch (match) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(SmileyRatingDefaultForm$ReactHooksTemplate.make, {
                    smileyRating: smileyRating,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        var match$1 = SmileyRating$ReactHooksTemplate.ratingSmileySet(smileyRating);
        var tmp;
        if (match$1 !== undefined) {
          var match$2 = SmileyRating$ReactHooksTemplate.ratingSmileySet(smileyRating);
          var tmp$1;
          if (match$2 !== undefined) {
            var ratingSmileySet = match$2;
            tmp$1 = $$Array.of_list(Belt_List.map(SmileyRating$ReactHooksTemplate.ratingTypeCollection(smileyRating), (function (xs) {
                        var match = SmileyRating$ReactHooksTemplate.answer(smileyRating);
                        var match$1 = RatingSmileySet$ReactHooksTemplate.selectedStateRatingSmileySet(ratingSmileySet);
                        if (match !== undefined) {
                          var ratingType = match;
                          if (match$1 !== undefined) {
                            var match$2 = xs === ratingType;
                            return smileyButton(xs, match$2 ? match$1 : ratingSmileySet, false);
                          } else {
                            return smileyButton(xs, ratingSmileySet, xs === ratingType);
                          }
                        } else {
                          return smileyButton(xs, ratingSmileySet, false);
                        }
                      })));
          } else {
            tmp$1 = React.createElement(React.Fragment, undefined);
          }
          tmp = React.createElement("div", {
                className: "flex flex-col"
              }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                    text: SmileyRating$ReactHooksTemplate.text(smileyRating),
                    description: SmileyRating$ReactHooksTemplate.description(smileyRating),
                    mandatory: mandatory,
                    surveyTheme: surveyTheme
                  }), React.createElement("div", {
                    className: "flex w-full justify-between pb-5"
                  }, tmp$1), React.createElement("div", undefined, mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                          language: language
                        }) : React.createElement(React.Fragment, undefined)));
        } else {
          tmp = React.createElement("p", undefined, "Loading...");
        }
        return React.createElement("div", undefined, tmp);
    
  }
}

var make = SmileyRatingForm;

exports.make = make;
/* react Not a pure module */
