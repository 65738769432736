// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Filter$ReactHooksTemplate = require("./filter.bs.js");
var FilterPills$ReactHooksTemplate = require("./filterPills.bs.js");

function FilterPillsElement(Props) {
  var t = Props.t;
  var handleResetFilter = Props.handleResetFilter;
  var height = Props.height;
  return React.createElement("div", {
              className: "flex items-center bg-ghostwhite text-center px-3 rounded-2xl border border-gray-400 " + (String(height) + "")
            }, React.createElement("div", {
                  className: "text-xs"
                }, FilterPills$ReactHooksTemplate.toString(t)), React.createElement("button", {
                  id: "filter_reset",
                  onClick: (function (param) {
                      return Curry._1(handleResetFilter, Filter$ReactHooksTemplate.resetSelected(FilterPills$ReactHooksTemplate.filter(t)));
                    })
                }, React.createElement("img", {
                      className: "w-2 ml-2",
                      src: Image$ReactHooksTemplate.closeIcon
                    })));
}

var make = FilterPillsElement;

exports.make = make;
/* react Not a pure module */
