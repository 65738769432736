// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function getDates(dateRange) {
  return /* record */[
          /* startDate */dateRange.startDate,
          /* endDate */dateRange.endDate
        ];
}

function getStartDate(t) {
  return t[/* startDate */0];
}

function getEndDate(t) {
  return t[/* endDate */1];
}

var DateRangeTuple = {
  getDates: getDates,
  getStartDate: getStartDate,
  getEndDate: getEndDate
};

var DateRangePicker = { };

exports.DateRangeTuple = DateRangeTuple;
exports.DateRangePicker = DateRangePicker;
/* No side effect */
