// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function CsatDefaultForm(Props) {
  var csat = Props.csat;
  Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 border border-gray-400 rounded-lg"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, Csat$ReactHooksTemplate.getTextTranslation(csat, SurveyLanguage$ReactHooksTemplate.name(language))), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, Csat$ReactHooksTemplate.getDescriptionTranslation(csat, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full small:gap-4 gap-10 items-start"
                }, $$Array.of_list(Belt_List.mapWithIndex(Csat$ReactHooksTemplate.answerTypeCollection(csat), (function (id, xs) {
                            var code = Csat$ReactHooksTemplate.answerTypeToUnicode(xs);
                            var match = Csat$ReactHooksTemplate.answered(csat);
                            var match$1 = Csat$ReactHooksTemplate.getAnswer(csat);
                            var match$2;
                            if (match && match$1 !== undefined) {
                              var match$3 = xs === match$1;
                              match$2 = match$3 ? /* tuple */[
                                  "text-6xl small:text-5xl",
                                  "pulse",
                                  "text-sm",
                                  "pt-0"
                                ] : /* tuple */[
                                  "text-5xl small:text-4xl",
                                  "",
                                  "text-xs small:hidden",
                                  "pt-10-px"
                                ];
                            } else {
                              match$2 = /* tuple */[
                                "text-5xl small:text-4xl",
                                "",
                                "text-xs small:hidden",
                                "pt-10-px"
                              ];
                            }
                            return React.createElement("button", {
                                        key: String(id),
                                        className: "animated " + (String(match$2[1]) + (" flex flex-col items-center\n               focus:outline-none " + (String(match$2[3]) + ""))),
                                        onClick: (function (param) {
                                            return Curry._1(onChange, Csat$ReactHooksTemplate.setAnswer(csat, xs));
                                          })
                                      }, React.createElement("span", {
                                            className: match$2[0]
                                          }, code), React.createElement("div", {
                                            className: match$2[2]
                                          }, Csat$ReactHooksTemplate.answerTypeToString(csat, xs, SurveyLanguage$ReactHooksTemplate.name(language))));
                          })))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = CsatDefaultForm;

exports.make = make;
/* react Not a pure module */
