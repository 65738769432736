// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Moment = require("moment");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var TicketPark$ReactHooksTemplate = require("./ticketPark.bs.js");
var TicketClose$ReactHooksTemplate = require("./ticketClose.bs.js");
var TicketAssign$ReactHooksTemplate = require("./ticketAssign.bs.js");
var TicketReopen$ReactHooksTemplate = require("./ticketReopen.bs.js");
var TicketComment$ReactHooksTemplate = require("./ticketComment.bs.js");
var TicketResolve$ReactHooksTemplate = require("./ticketResolve.bs.js");

function id(t) {
  return t[/* id */0];
}

function categoryType(t) {
  return t[/* categoryType */1];
}

function createdAt(t) {
  return t[/* createdAt */3];
}

function activityDetails(t) {
  return t[/* activityDetails */4];
}

function decode(json) {
  var activityType = Json_decode.field("activity", Json_decode.string, json);
  if (activityType === TicketClose$ReactHooksTemplate.activityType) {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketClose */Block.__(0, [TicketClose$ReactHooksTemplate.fromJson(json)])
          ];
  } else if (activityType === TicketPark$ReactHooksTemplate.activityType) {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketPark */Block.__(3, [TicketPark$ReactHooksTemplate.fromJson(json)])
          ];
  } else if (activityType === TicketAssign$ReactHooksTemplate.activityType) {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketAssign */Block.__(1, [TicketAssign$ReactHooksTemplate.fromJson(json)])
          ];
  } else if (activityType === TicketClose$ReactHooksTemplate.activityType) {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketClose */Block.__(0, [TicketClose$ReactHooksTemplate.fromJson(json)])
          ];
  } else if (activityType === TicketResolve$ReactHooksTemplate.activityType) {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketResolve */Block.__(2, [TicketResolve$ReactHooksTemplate.fromJson(json)])
          ];
  } else if (activityType === TicketReopen$ReactHooksTemplate.activityType) {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketReopen */Block.__(5, [TicketReopen$ReactHooksTemplate.fromJson(json)])
          ];
  } else {
    return /* record */[
            /* id */Json_decode.field("id", Json_decode.$$int, json),
            /* categoryType */activityType,
            /* ticketId */Json_decode.field("ticket_id", Json_decode.$$int, json),
            /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            /* activityDetails : TicketComment */Block.__(4, [TicketComment$ReactHooksTemplate.fromJson(json)])
          ];
  }
}

exports.id = id;
exports.categoryType = categoryType;
exports.createdAt = createdAt;
exports.activityDetails = activityDetails;
exports.decode = decode;
/* moment Not a pure module */
