// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function WelcomeFormYesbank(Props) {
  var $$default = Props.default;
  var welcome = Props.welcome;
  var handleStart = Props.handleStart;
  var language = Props.language;
  var withDefault = function ($$default, x) {
    var match = x.length === 0;
    if (match) {
      return $$default;
    } else {
      return x;
    }
  };
  return React.createElement("div", {
              className: "pb-100-px small:pb-80-px h-full"
            }, React.createElement("div", {
                  className: "h-full flex items-center survey-welcome-bg bg-cover rounded shadow"
                }, React.createElement("div", {
                      className: "flex flex-col items-center my-auto px-80-px small:px-6 w-full"
                    }, React.createElement("p", {
                          className: "text-white pb-16-px font-bold text-xl text-center small:text-lg uppercase"
                        }, withDefault(Welcome$ReactHooksTemplate.title($$default), Welcome$ReactHooksTemplate.getTitleTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", {
                          className: "text-white mb-10-px pb-16-px font-bold text-l text-center small:text-sm"
                        }, withDefault(Welcome$ReactHooksTemplate.description($$default), Welcome$ReactHooksTemplate.getDescriptionTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("a", {
                          className: "text-white text-18-px font-semibold bg-lochmara py-4 px-8 text-center rounded",
                          href: "#",
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              return Curry._1(handleStart, /* () */0);
                            })
                        }, withDefault(Welcome$ReactHooksTemplate.button($$default), Welcome$ReactHooksTemplate.getButtonTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))))));
}

var make = WelcomeFormYesbank;

exports.make = make;
/* react Not a pure module */
