// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function ThankYouFormBhima(Props) {
  var $$default = Props.default;
  var thankYou = Props.thankYou;
  var textColor = Props.textColor;
  var language = Props.language;
  var withDefault = function ($$default, x) {
    var match = x.length === 0;
    if (match) {
      return $$default;
    } else {
      return x;
    }
  };
  return React.createElement("div", {
              className: "max-w-840-px mx-auto px-60-px small-px-20-px min-h-screen flex flex-col\n    justify-center pt-120-px bg-white"
            }, React.createElement("div", {
                  className: "flex flex-col h-full"
                }, React.createElement("div", {
                      className: "text-center pb-10"
                    }, React.createElement("p", {
                          className: " text-xl leading-normal font-medium text-" + (String(textColor) + "")
                        }, withDefault(ThankYou$ReactHooksTemplate.text($$default), ThankYou$ReactHooksTemplate.getTextTranslation(thankYou, SurveyLanguage$ReactHooksTemplate.name(language))))), React.createElement("img", {
                      src: Image$ReactHooksTemplate.bhimaThankyou
                    })));
}

var make = ThankYouFormBhima;

exports.make = make;
/* react Not a pure module */
