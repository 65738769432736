// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Es6Promise = require("es6-promise");
var IsomorphicFetch = require("isomorphic-fetch");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("./common/http.bs.js");
var Editor$ReactHooksTemplate = require("./surveys/WysiwygEditor/Editor.bs.js");
var Header$ReactHooksTemplate = require("./common/Header.bs.js");
var SignIn$ReactHooksTemplate = require("./SignIn.bs.js");
var Tickets$ReactHooksTemplate = require("./tickets/Tickets.bs.js");
var Insights$ReactHooksTemplate = require("./dashboard/dashboard/Insights.bs.js");
var NpsTrend$ReactHooksTemplate = require("./surveys/NpsTrend.bs.js");
var Projects$ReactHooksTemplate = require("./surveys/Projects.bs.js");
var RoleList$ReactHooksTemplate = require("./settings/RoleList.bs.js");
var UserEdit$ReactHooksTemplate = require("./settings/UserEdit.bs.js");
var UserForm$ReactHooksTemplate = require("./settings/UserForm.bs.js");
var UserList$ReactHooksTemplate = require("./settings/UserList.bs.js");
var AdminView$ReactHooksTemplate = require("./AdminView.bs.js");
var Campaigns$ReactHooksTemplate = require("./surveys/Campaigns.bs.js");
var Responses$ReactHooksTemplate = require("./Responses.bs.js");
var Dashboards$ReactHooksTemplate = require("./dashboard/dashboard/Dashboards.bs.js");
var ReportList$ReactHooksTemplate = require("./surveys/ReportList.bs.js");
var RoleCreate$ReactHooksTemplate = require("./settings/RoleCreate.bs.js");
var SurveyEdit$ReactHooksTemplate = require("./surveys/SurveyEdit.bs.js");
var SurveyPage$ReactHooksTemplate = require("./surveys/SurveyPage.bs.js");
var DailyReport$ReactHooksTemplate = require("./DailyReport.bs.js");
var AuthProvider$ReactHooksTemplate = require("./AuthProvider.bs.js");
var CampaignList$ReactHooksTemplate = require("./surveys/CampaignList.bs.js");
var CategoryEdit$ReactHooksTemplate = require("./CategoryEdit.bs.js");
var DownloadList$ReactHooksTemplate = require("./tickets/DownloadList.bs.js");
var TicketWorker$ReactHooksTemplate = require("./tickets/TicketWorker.bs.js");
var InsightsIndex$ReactHooksTemplate = require("./dashboard/dashboard/InsightsIndex.bs.js");
var SurveyExpired$ReactHooksTemplate = require("./surveys/SurveyExpired.bs.js");
var TicketManager$ReactHooksTemplate = require("./tickets/TicketManager.bs.js");
var UserKpiCreate$ReactHooksTemplate = require("./settings/UserKpiCreate.bs.js");
var CampaignReport$ReactHooksTemplate = require("./surveys/CampaignReport.bs.js");
var PermissionTree$ReactHooksTemplate = require("./settings/permissionTree.bs.js");
var DataFieldReport$ReactHooksTemplate = require("./surveys/DataFieldReport.bs.js");
var QuickSurveyForm$ReactHooksTemplate = require("./surveys/QuickSurveyForm.bs.js");
var AgentWiseSummary$ReactHooksTemplate = require("./tickets/AgentWiseSummary.bs.js");
var DistributionList$ReactHooksTemplate = require("./surveys/DistributionList.bs.js");
var CampaignDashboard$ReactHooksTemplate = require("./surveys/CampaignDashboard.bs.js");
var QuickSurveyReport$ReactHooksTemplate = require("./surveys/QuickSurveyReport.bs.js");
var TelephonicSurveys$ReactHooksTemplate = require("./surveys/TelephonicSurveys.bs.js");
var UserKpiManagement$ReactHooksTemplate = require("./settings/UserKpiManagement.bs.js");
var ResponseModeReport$ReactHooksTemplate = require("./surveys/ResponseModeReport.bs.js");
var SurveyPageNotFound$ReactHooksTemplate = require("./surveys/SurveyPageNotFound.bs.js");
var UserUpdatePassword$ReactHooksTemplate = require("./settings/UserUpdatePassword.bs.js");
var InsightsCorrelation$ReactHooksTemplate = require("./dashboard/dashboard/InsightsCorrelation.bs.js");
var ReportDownloadsList$ReactHooksTemplate = require("./surveys/ReportDownloadsList.bs.js");
var TicketStandAloneView$ReactHooksTemplate = require("./tickets/TicketStandAloneView.bs.js");

Es6Promise.polyfill();

var emptyState = /* record */[/* permissions */PermissionTree$ReactHooksTemplate.emptyPermission(/* () */0)];

function fetchPermissions(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/user_permissions", PermissionTree$ReactHooksTemplate.decode);
}

function Application(Props) {
  var match = React.useState((function () {
          return emptyState;
        }));
  var setState = match[1];
  var state = match[0];
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var match$1 = url[/* path */0];
  var authorizationRequired;
  if (match$1) {
    switch (match$1[0]) {
      case "surveys" :
          var match$2 = match$1[1];
          if (match$2) {
            switch (match$2[0]) {
              case "404" :
              case "410" :
                  authorizationRequired = match$2[1] ? true : false;
                  break;
              case "quick_survey" :
                  authorizationRequired = true;
                  break;
              default:
                authorizationRequired = match$2[1] ? true : false;
            }
          } else {
            authorizationRequired = true;
          }
          break;
      case "users" :
          var match$3 = match$1[1];
          authorizationRequired = match$3 && match$3[0] === "sign_in" && !match$3[1] ? false : true;
          break;
      default:
        authorizationRequired = true;
    }
  } else {
    authorizationRequired = true;
  }
  React.useEffect((function () {
          if (authorizationRequired) {
            Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/user_permissions", PermissionTree$ReactHooksTemplate.decode).then((function (permissions) {
                    return Promise.resolve(Curry._1(setState, (function (param) {
                                      return /* record */[/* permissions */permissions];
                                    })));
                  }));
          }
          return ;
        }), ([]));
  var headerView = function (children, parent) {
    return React.createElement(React.Fragment, undefined, React.createElement(Header$ReactHooksTemplate.make, {
                    parent: parent
                  }), React.createElement("div", {
                    className: "pt-62-px"
                  }, children));
  };
  var standaloneView = function (children) {
    return React.createElement(React.Fragment, undefined, children);
  };
  var home = function (param) {
    if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowDashboard */0, state[/* permissions */0])) {
      return React.createElement(Dashboards$ReactHooksTemplate.make, { });
    } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowSurvey */5, state[/* permissions */0])) {
      return React.createElement(Projects$ReactHooksTemplate.make, {
                  search: url[/* search */2]
                });
    } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ManageTickets */1, state[/* permissions */0])) {
      return React.createElement(TicketManager$ReactHooksTemplate.make, {
                  scope: "all",
                  search: url[/* search */2]
                });
    } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ResolveTickets */2, state[/* permissions */0]) || PermissionTree$ReactHooksTemplate.hasCapability(/* ManageTickets */1, state[/* permissions */0])) {
      return React.createElement(TicketWorker$ReactHooksTemplate.make, {
                  status: "all",
                  search: url[/* search */2]
                });
    } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowUser */9, state[/* permissions */0])) {
      return React.createElement(UserList$ReactHooksTemplate.make, {
                  search: url[/* search */2]
                });
    } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowRole */13, state[/* permissions */0])) {
      return React.createElement(RoleList$ReactHooksTemplate.make, { });
    } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* EditTextCategories */20, state[/* permissions */0])) {
      return React.createElement(CategoryEdit$ReactHooksTemplate.make, { });
    } else {
      return React.createElement(React.Fragment, undefined);
    }
  };
  var match$4 = url[/* path */0];
  var children;
  if (match$4) {
    switch (match$4[0]) {
      case "admin" :
          var match$5 = match$4[1];
          children = match$5 && match$5[0] === "view" && !match$5[1] ? headerView(React.createElement(AdminView$ReactHooksTemplate.make, { }), "admin") : headerView(home(/* () */0), "dashboard");
          break;
      case "campaign" :
          var match$6 = match$4[1];
          if (match$6) {
            var campaignId = match$6[0];
            var match$7 = match$6[1];
            if (match$7) {
              if (match$7[0] === "campaign_report") {
                children = match$7[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                            content: React.createElement(CampaignReport$ReactHooksTemplate.make, {
                                  search: url[/* search */2],
                                  campaignId: campaignId
                                }),
                            campaignId: campaignId
                          }), "campaigns");
              } else if (campaignId === "reports") {
                var match$8 = match$6[1];
                if (match$8[1]) {
                  children = headerView(home(/* () */0), "dashboard");
                } else {
                  var campaignId$1 = match$8[0];
                  children = headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                            content: React.createElement(ReportList$ReactHooksTemplate.make, {
                                  id: campaignId$1,
                                  search: url[/* search */2]
                                }),
                            campaignId: campaignId$1
                          }), "campaigns");
                }
              } else {
                var match$9 = match$6[1];
                switch (match$9[0]) {
                  case "data_field_reports" :
                      children = match$9[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(DataFieldReport$ReactHooksTemplate.make, {
                                        search: url[/* search */2],
                                        campaignId: campaignId
                                      }),
                                  campaignId: campaignId
                                }), "campaigns");
                      break;
                  case "nps_trend" :
                      children = match$9[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(NpsTrend$ReactHooksTemplate.make, {
                                        search: url[/* search */2],
                                        campaignId: campaignId
                                      }),
                                  campaignId: campaignId
                                }), "campaigns");
                      break;
                  case "response_mode_reports" :
                      children = match$9[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(ResponseModeReport$ReactHooksTemplate.make, {
                                        search: url[/* search */2],
                                        campaignId: campaignId
                                      }),
                                  campaignId: campaignId
                                }), "campaigns");
                      break;
                  default:
                    children = headerView(home(/* () */0), "dashboard");
                }
              }
            } else {
              children = headerView(home(/* () */0), "dashboard");
            }
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "campaigns" :
          var match$10 = match$4[1];
          if (match$10) {
            var campaignId$2 = match$10[0];
            var exit = 0;
            switch (campaignId$2) {
              case "all" :
                  if (match$10[1]) {
                    exit = 1;
                  } else {
                    children = headerView(React.createElement(CampaignList$ReactHooksTemplate.make, {
                              search: url[/* search */2]
                            }), "campaigns");
                  }
                  break;
              case "telephonic_surveys" :
                  if (match$10[1]) {
                    exit = 1;
                  } else {
                    children = headerView(React.createElement(TelephonicSurveys$ReactHooksTemplate.make, { }), "telephonic_surveys");
                  }
                  break;
              default:
                exit = 1;
            }
            if (exit === 1) {
              var match$11 = match$10[1];
              if (match$11) {
                switch (match$11[0]) {
                  case "dashboard" :
                      children = match$11[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(CampaignDashboard$ReactHooksTemplate.make, {
                                        campaignId: campaignId$2
                                      }),
                                  campaignId: campaignId$2
                                }), "campaigns");
                      break;
                  case "distributions" :
                      children = match$11[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(DistributionList$ReactHooksTemplate.make, {
                                        campaignId: campaignId$2
                                      }),
                                  campaignId: campaignId$2
                                }), "campaigns");
                      break;
                  default:
                    if (campaignId$2 === "report_downloads") {
                      var match$12 = match$10[1];
                      if (match$12[1]) {
                        children = headerView(home(/* () */0), "dashboard");
                      } else {
                        var campaignId$3 = match$12[0];
                        children = headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(ReportDownloadsList$ReactHooksTemplate.make, {
                                        id: campaignId$3
                                      }),
                                  campaignId: campaignId$3
                                }), "campaigns");
                      }
                    } else {
                      children = headerView(home(/* () */0), "dashboard");
                    }
                }
              } else {
                children = headerView(home(/* () */0), "dashboard");
              }
            }
            
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "category_edit" :
          children = match$4[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(CategoryEdit$ReactHooksTemplate.make, {
                      params: url[/* search */2]
                    }), "comment_categorization");
          break;
      case "daily_report" :
          children = match$4[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(DailyReport$ReactHooksTemplate.make, {
                      params: url[/* search */2]
                    }), "daily_report");
          break;
      case "dashboard" :
          children = match$4[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Dashboards$ReactHooksTemplate.make, { }), "dashboard");
          break;
      case "editor" :
          children = match$4[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Editor$ReactHooksTemplate.make, { }), "editor");
          break;
      case "insights" :
          var match$13 = match$4[1];
          if (match$13) {
            switch (match$13[0]) {
              case "correlation" :
                  children = match$13[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(InsightsIndex$ReactHooksTemplate.make, {
                              content: React.createElement(InsightsCorrelation$ReactHooksTemplate.make, { })
                            }), "insights");
                  break;
              case "dashboard" :
                  children = match$13[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(InsightsIndex$ReactHooksTemplate.make, {
                              content: React.createElement(Insights$ReactHooksTemplate.make, { })
                            }), "insights");
                  break;
              default:
                children = headerView(home(/* () */0), "dashboard");
            }
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "projects" :
          var match$14 = match$4[1];
          if (match$14) {
            var projectId = match$14[0];
            var exit$1 = 0;
            if (projectId === "all" && !match$14[1]) {
              children = headerView(React.createElement(Projects$ReactHooksTemplate.make, {
                        search: url[/* search */2]
                      }), "surveys");
            } else {
              exit$1 = 1;
            }
            if (exit$1 === 1) {
              var match$15 = match$14[1];
              if (match$15 && match$15[0] === "surveys") {
                var match$16 = match$15[1];
                if (match$16 && match$16[0] === "edit") {
                  var match$17 = match$16[1];
                  children = match$17 && !match$17[1] ? headerView(React.createElement(SurveyEdit$ReactHooksTemplate.make, {
                              id: match$17[0],
                              projectId: projectId
                            }), "surveys") : headerView(home(/* () */0), "dashboard");
                } else {
                  children = headerView(home(/* () */0), "dashboard");
                }
              } else {
                children = headerView(home(/* () */0), "dashboard");
              }
            }
            
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "quick_survey" :
          var match$18 = match$4[1];
          children = match$18 && match$18[0] === "report" && !match$18[1] ? headerView(React.createElement(QuickSurveyReport$ReactHooksTemplate.make, {
                      search: url[/* search */2]
                    }), "quick_survey") : headerView(home(/* () */0), "dashboard");
          break;
      case "responses" :
          children = match$4[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Responses$ReactHooksTemplate.make, {
                      params: url[/* search */2]
                    }), "responses");
          break;
      case "roles" :
          var match$19 = match$4[1];
          if (match$19) {
            switch (match$19[0]) {
              case "all" :
                  children = match$19[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(RoleList$ReactHooksTemplate.make, { }), "roles");
                  break;
              case "new" :
                  children = match$19[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(RoleCreate$ReactHooksTemplate.make, { }), "roles");
                  break;
              default:
                children = headerView(home(/* () */0), "dashboard");
            }
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "surveys" :
          var match$20 = match$4[1];
          if (match$20) {
            var id = match$20[0];
            switch (id) {
              case "404" :
                  children = match$20[1] ? headerView(home(/* () */0), "dashboard") : standaloneView(React.createElement(SurveyPageNotFound$ReactHooksTemplate.make, { }));
                  break;
              case "410" :
                  children = match$20[1] ? headerView(home(/* () */0), "dashboard") : standaloneView(React.createElement(SurveyExpired$ReactHooksTemplate.make, { }));
                  break;
              case "quick_survey" :
                  children = match$20[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(QuickSurveyForm$ReactHooksTemplate.make, { }), "quick_survey");
                  break;
              default:
                children = match$20[1] ? headerView(home(/* () */0), "dashboard") : standaloneView(React.createElement(SurveyPage$ReactHooksTemplate.make, {
                            id: id,
                            params: url[/* search */2]
                          }));
            }
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "tickets" :
          var match$21 = match$4[1];
          if (match$21) {
            switch (match$21[0]) {
              case "agent_summary" :
                  children = match$21[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(AgentWiseSummary$ReactHooksTemplate.make, { })
                            }), "tickets");
                  break;
              case "downloadList" :
                  children = match$21[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(DownloadList$ReactHooksTemplate.make, { })
                            }), "tickets");
                  break;
              case "manage" :
                  var match$22 = match$21[1];
                  children = match$22 && !match$22[1] ? headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(TicketManager$ReactHooksTemplate.make, {
                                    scope: match$22[0],
                                    search: url[/* search */2]
                                  })
                            }), "tickets") : headerView(home(/* () */0), "dashboard");
                  break;
              case "show" :
                  var match$23 = match$21[1];
                  children = match$23 && !match$23[1] ? headerView(React.createElement(TicketStandAloneView$ReactHooksTemplate.make, {
                              id: match$23[0]
                            }), "tickets") : headerView(home(/* () */0), "dashboard");
                  break;
              case "work" :
                  var match$24 = match$21[1];
                  children = match$24 && !match$24[1] ? headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(TicketWorker$ReactHooksTemplate.make, {
                                    status: match$24[0],
                                    search: url[/* search */2]
                                  })
                            }), "tickets") : headerView(home(/* () */0), "dashboard");
                  break;
              default:
                children = headerView(home(/* () */0), "dashboard");
            }
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      case "users" :
          var match$25 = match$4[1];
          if (match$25) {
            switch (match$25[0]) {
              case "all" :
                  children = match$25[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(UserList$ReactHooksTemplate.make, {
                              search: url[/* search */2]
                            }), "users");
                  break;
              case "edit" :
                  var match$26 = match$25[1];
                  children = match$26 && !match$26[1] ? headerView(React.createElement(UserEdit$ReactHooksTemplate.make, {
                              id: match$26[0]
                            }), "users") : headerView(home(/* () */0), "dashboard");
                  break;
              case "kpi" :
                  var match$27 = match$25[1];
                  children = match$27 ? (
                      match$27[0] === "new" && !match$27[1] ? headerView(React.createElement(UserKpiCreate$ReactHooksTemplate.make, { }), "users") : headerView(home(/* () */0), "dashboard")
                    ) : headerView(React.createElement(UserKpiManagement$ReactHooksTemplate.make, {
                              search: url[/* search */2]
                            }), "users");
                  break;
              case "new" :
                  children = match$25[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(UserForm$ReactHooksTemplate.make, { }), "users");
                  break;
              case "sign_in" :
                  children = match$25[1] ? headerView(home(/* () */0), "dashboard") : standaloneView(React.createElement(SignIn$ReactHooksTemplate.make, { }));
                  break;
              case "update_password" :
                  children = match$25[1] ? headerView(home(/* () */0), "dashboard") : headerView(React.createElement(UserUpdatePassword$ReactHooksTemplate.make, { }), "users");
                  break;
              default:
                children = headerView(home(/* () */0), "dashboard");
            }
          } else {
            children = headerView(home(/* () */0), "dashboard");
          }
          break;
      default:
        children = headerView(home(/* () */0), "dashboard");
    }
  } else {
    children = headerView(home(/* () */0), "dashboard");
  }
  var partial_arg = state[/* permissions */0];
  var match$28 = state[/* permissions */0];
  var match$29 = url[/* path */0];
  var tmp;
  if (match$28) {
    tmp = React.createElement("div", {
          className: "font-inter"
        }, children);
  } else if (match$29) {
    switch (match$29[0]) {
      case "surveys" :
          var match$30 = match$29[1];
          if (match$30) {
            var id$1 = match$30[0];
            switch (id$1) {
              case "404" :
                  tmp = match$30[1] ? React.createElement(React.Fragment, undefined) : standaloneView(React.createElement(SurveyPageNotFound$ReactHooksTemplate.make, { }));
                  break;
              case "410" :
                  tmp = match$30[1] ? React.createElement(React.Fragment, undefined) : standaloneView(React.createElement(SurveyExpired$ReactHooksTemplate.make, { }));
                  break;
              default:
                tmp = match$30[1] ? React.createElement(React.Fragment, undefined) : standaloneView(React.createElement(SurveyPage$ReactHooksTemplate.make, {
                            id: id$1,
                            params: url[/* search */2]
                          }));
            }
          } else {
            tmp = React.createElement(React.Fragment, undefined);
          }
          break;
      case "users" :
          var match$31 = match$29[1];
          tmp = match$31 && match$31[0] === "sign_in" && !match$31[1] ? standaloneView(React.createElement(SignIn$ReactHooksTemplate.make, { })) : React.createElement(React.Fragment, undefined);
          break;
      default:
        tmp = React.createElement(React.Fragment, undefined);
    }
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement(AuthProvider$ReactHooksTemplate.make, AuthProvider$ReactHooksTemplate.makeProps(/* tuple */[
                  (function (param) {
                      return PermissionTree$ReactHooksTemplate.hasCapability(param, partial_arg);
                    }),
                  state[/* permissions */0]
                ], tmp, /* () */0));
}

var make = Application;

exports.emptyState = emptyState;
exports.fetchPermissions = fetchPermissions;
exports.make = make;
/*  Not a pure module */
