// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var ShortText$ReactHooksTemplate = require("./ShortText.bs.js");
var LogicGraph$ReactHooksTemplate = require("./LogicGraph.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var LogicalJump$ReactHooksTemplate = require("./LogicalJump.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var SurveyLogic$ReactHooksTemplate = require("./SurveyLogic.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");
var SurveyPreviewHtmlRenderer$ReactHooksTemplate = require("./SurveyPreviewHtmlRenderer.bs.js");

var defaultQuestiontemplate = "\n  <div class=\"h-full min-h-screen\">\n    <div class=\"max-w-840-px mx-auto px-40-px small:px-20-px pt-32-px small:pt-0\n    small:pb-80-px\">\n      <div class=\"h-84-px small:h-64-px py-16-px\">\n        <div id=\"logo\">\n          logo\n        </div>\n      </div>\n      <div class=\"pt-24-px small:pt-16-px pb-54-px\"\n      style=\"min-height: calc(100vh - 294px);\">\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n    <div class=\"w-full small:fixed small:bottom-0 bg-white small:bg-opacity-75\">\n      <div class=\"flex max-w-840-px mx-auto px-40-px small:px-20-px small:pt-16-px items-center pb-100-px small:pb-16-px\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var bhimaQuestiontemplate = "\n  <div class=\"h-full\">\n    <div class=\"pt-40-px w-full pb-20-px absolute\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"max-w-840-px mx-auto px-60-px small:px-20-px min-h-screen flex flex-col\n    justify-center pt-160-px small:pt-142-px\">\n\n      <div class=\"pb-100-px\">\n        <div id=\"question\">question</div>\n      </div>\n      <div class=\"flex justify-center items-center mb-8\">\n        <div id=\"previousButton\"></div>\n        <div id=\"nextButton\"></div>\n      </div>\n    </div>\n  </div>\n";

var questionTemplates = /* record */[
  /* bhima */bhimaQuestiontemplate,
  /* default */defaultQuestiontemplate
];

function questionsOrPageToString(param) {
  switch (param) {
    case /* Welcome */0 :
        return "welcome";
    case /* ThankYou */1 :
        return "thank_you";
    case /* LongText */2 :
        return "LongText";
    case /* ShortText */3 :
        return "ShortText";
    case /* StarRating */4 :
        return "StarRating";
    case /* OpinionScale */5 :
        return "OpinionScale";
    case /* Nps */6 :
        return "Nps";
    case /* MultipleChoice */7 :
        return "MultipleChoice";
    case /* RatingGroup */8 :
        return "RatingGroup";
    case /* SmileyRating */9 :
        return "Csat";
    case /* MultipleSelect */10 :
        return "MultipleSelect";
    case /* DropDown */11 :
        return "DropDown";
    case /* MobileNumber */12 :
        return "MobileNumber";
    
  }
}

function surveyToJson(welcome, questions, thankYou, logicalJumpGroup) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "welcome",
                Belt_Option.mapWithDefault(welcome, null, (function (eta) {
                        return Welcome$ReactHooksTemplate.toJson(undefined, eta);
                      }))
              ],
              /* :: */[
                /* tuple */[
                  "questions",
                  Belt_List.toArray(Belt_List.mapWithIndex(questions, (function (i, q) {
                              return Question$ReactHooksTemplate.toJson(undefined, i + 1 | 0, undefined, q);
                            })))
                ],
                /* :: */[
                  /* tuple */[
                    "thank_you",
                    Belt_Option.mapWithDefault(thankYou, null, (function (eta) {
                            return ThankYou$ReactHooksTemplate.toJson(undefined, 1, eta);
                          }))
                  ],
                  /* :: */[
                    /* tuple */[
                      "logic_jump",
                      LogicalJump$ReactHooksTemplate.encodeGroup(logicalJumpGroup)
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function initialPreviewState(param) {
  if (param !== 0) {
    return /* Welcome */1;
  } else {
    return /* Empty */0;
  }
}

function addQuestionsToGraph(questions) {
  return List.fold_left((function (graph, item) {
                return LogicGraph$ReactHooksTemplate.addNode(/* Question */Block.__(1, [item]), graph);
              }), LogicGraph$ReactHooksTemplate.empty(/* () */0), questions);
}

function surveyAnswers(questions) {
  return $$Array.of_list(Belt_List.mapWithIndex(questions, (function (i, q) {
                    return Question$ReactHooksTemplate.toJson(true, i + 1 | 0, undefined, q);
                  })));
}

function submitQuestionResponses($staropt$star, state) {
  var completed = $staropt$star !== undefined ? $staropt$star : false;
  Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/user_response", Json_encode.object_(/* :: */[
            /* tuple */[
              "token",
              state[/* id */0]
            ],
            /* :: */[
              /* tuple */[
                "responses",
                surveyAnswers(Belt_List.keep(state[/* questions */4], (function (q) {
                            return List.exists((function (x) {
                                          return Caml_obj.caml_equal(x, Question$ReactHooksTemplate.uuid(q));
                                        }), state[/* answeredQuestions */7]);
                          })))
              ],
              /* :: */[
                /* tuple */[
                  "language_code",
                  SurveyLanguage$ReactHooksTemplate.code(state[/* language */16])
                ],
                /* :: */[
                  /* tuple */[
                    "completed",
                    completed
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]));
  return /* () */0;
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* PreviewWelcome */0 :
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */state[/* questions */4],
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview */state[/* preview */6],
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview : Welcome */Block.__(0, [state[/* welcome */3]]),
                  /* graph */state[/* graph */9],
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */state[/* language */16]
                ];
      case /* PreviewQuestions */1 :
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */state[/* questions */4],
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview : Questions */2,
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview : QuestionPage */Block.__(1, [
                      questionTemplates,
                      Belt_List.head(state[/* questions */4]),
                      state[/* customerInfo */10]
                    ]),
                  /* graph */state[/* graph */9],
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */state[/* language */16]
                ];
      case /* PreviewThankYou */2 :
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */state[/* questions */4],
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview : ThankYou */3,
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview : ThankYou */Block.__(2, [Belt_List.head(state[/* thankYouList */5])]),
                  /* graph */state[/* graph */9],
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */state[/* language */16]
                ];
      case /* SkipSurvey */3 :
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */state[/* questions */4],
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview */state[/* preview */6],
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview */state[/* currentPreview */8],
                  /* graph */state[/* graph */9],
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */true,
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */state[/* language */16]
                ];
      
    }
  } else {
    switch (action.tag | 0) {
      case /* Initialize */0 :
          var customerInfo = action[6];
          var thankYouList = action[5];
          var questions = action[4];
          var welcome = action[3];
          var name = action[1];
          var graph = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions, SurveyLogic$ReactHooksTemplate.changeEdges(name, questions, thankYouList, addQuestionsToGraph(questions)));
          return /* record */[
                  /* id */action[0],
                  /* name */name,
                  /* logo */action[2],
                  /* welcome */welcome,
                  /* questions */questions,
                  /* thankYouList */thankYouList,
                  /* preview */state[/* preview */6],
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview */welcome !== undefined ? /* Welcome */Block.__(0, [welcome]) : (
                      questions ? /* QuestionPage */Block.__(1, [
                            questionTemplates,
                            Belt_List.head(questions),
                            customerInfo
                          ]) : /* ThankYou */Block.__(2, [Belt_List.head(thankYouList)])
                    ),
                  /* graph */graph,
                  /* customerInfo */customerInfo,
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */action[7],
                  /* client */action[8],
                  /* transactionStatus */action[9],
                  /* surveyLanguages */action[10],
                  /* language */action[11]
                ];
      case /* UpdateQuestion */1 :
          var question = action[0];
          var questions$prime = Belt_List.map(state[/* questions */4], (function (x) {
                  var match = Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question));
                  if (match) {
                    return question;
                  } else {
                    return x;
                  }
                }));
          var graph$prime = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$prime, SurveyLogic$ReactHooksTemplate.changeEdges(state[/* name */1], questions$prime, state[/* thankYouList */5], addQuestionsToGraph(questions$prime)));
          var match = Question$ReactHooksTemplate.answered(question);
          var answeredQuestions;
          if (match) {
            var match$1 = List.exists((function (x) {
                    return Caml_obj.caml_equal(x, Question$ReactHooksTemplate.uuid(question));
                  }), state[/* answeredQuestions */7]);
            answeredQuestions = match$1 ? state[/* answeredQuestions */7] : Belt_List.concat(state[/* answeredQuestions */7], /* :: */[
                    Question$ReactHooksTemplate.uuid(question),
                    /* [] */0
                  ]);
          } else {
            answeredQuestions = state[/* answeredQuestions */7];
          }
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */questions$prime,
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview */state[/* preview */6],
                  /* answeredQuestions */answeredQuestions,
                  /* currentPreview : QuestionPage */Block.__(1, [
                      questionTemplates,
                      question,
                      state[/* customerInfo */10]
                    ]),
                  /* graph */graph$prime,
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */state[/* language */16]
                ];
      case /* ChangeLanguage */2 :
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */state[/* questions */4],
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview */state[/* preview */6],
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview */state[/* currentPreview */8],
                  /* graph */state[/* graph */9],
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */action[0]
                ];
      case /* SetPage */3 :
          var page = action[0];
          if (typeof page === "number") {
            return state;
          } else {
            switch (page.tag | 0) {
              case /* QuestionPage */1 :
                  return /* record */[
                          /* id */state[/* id */0],
                          /* name */state[/* name */1],
                          /* logo */state[/* logo */2],
                          /* welcome */state[/* welcome */3],
                          /* questions */state[/* questions */4],
                          /* thankYouList */state[/* thankYouList */5],
                          /* preview */state[/* preview */6],
                          /* answeredQuestions */state[/* answeredQuestions */7],
                          /* currentPreview */page,
                          /* graph */SurveyLogic$ReactHooksTemplate.addLogicToEdges(state[/* questions */4], SurveyLogic$ReactHooksTemplate.changeEdges(state[/* name */1], state[/* questions */4], state[/* thankYouList */5], addQuestionsToGraph(state[/* questions */4]))),
                          /* customerInfo */state[/* customerInfo */10],
                          /* skipSurvey */state[/* skipSurvey */11],
                          /* theme */state[/* theme */12],
                          /* client */state[/* client */13],
                          /* transactionStatus */state[/* transactionStatus */14],
                          /* surveyLanguages */state[/* surveyLanguages */15],
                          /* language */state[/* language */16]
                        ];
              case /* Welcome */0 :
              case /* ThankYou */2 :
                  break;
              
            }
          }
          return /* record */[
                  /* id */state[/* id */0],
                  /* name */state[/* name */1],
                  /* logo */state[/* logo */2],
                  /* welcome */state[/* welcome */3],
                  /* questions */state[/* questions */4],
                  /* thankYouList */state[/* thankYouList */5],
                  /* preview */state[/* preview */6],
                  /* answeredQuestions */state[/* answeredQuestions */7],
                  /* currentPreview */page,
                  /* graph */state[/* graph */9],
                  /* customerInfo */state[/* customerInfo */10],
                  /* skipSurvey */state[/* skipSurvey */11],
                  /* theme */state[/* theme */12],
                  /* client */state[/* client */13],
                  /* transactionStatus */state[/* transactionStatus */14],
                  /* surveyLanguages */state[/* surveyLanguages */15],
                  /* language */state[/* language */16]
                ];
      case /* NextPage */4 :
          var page$1 = action[0];
          if (typeof page$1 === "number") {
            return /* record */[
                    /* id */state[/* id */0],
                    /* name */state[/* name */1],
                    /* logo */state[/* logo */2],
                    /* welcome */state[/* welcome */3],
                    /* questions */state[/* questions */4],
                    /* thankYouList */state[/* thankYouList */5],
                    /* preview */state[/* preview */6],
                    /* answeredQuestions */state[/* answeredQuestions */7],
                    /* currentPreview : Welcome */Block.__(0, [state[/* welcome */3]]),
                    /* graph */state[/* graph */9],
                    /* customerInfo */state[/* customerInfo */10],
                    /* skipSurvey */state[/* skipSurvey */11],
                    /* theme */state[/* theme */12],
                    /* client */state[/* client */13],
                    /* transactionStatus */state[/* transactionStatus */14],
                    /* surveyLanguages */state[/* surveyLanguages */15],
                    /* language */state[/* language */16]
                  ];
          } else {
            switch (page$1.tag | 0) {
              case /* Welcome */0 :
                  return /* record */[
                          /* id */state[/* id */0],
                          /* name */state[/* name */1],
                          /* logo */state[/* logo */2],
                          /* welcome */state[/* welcome */3],
                          /* questions */state[/* questions */4],
                          /* thankYouList */state[/* thankYouList */5],
                          /* preview */state[/* preview */6],
                          /* answeredQuestions */state[/* answeredQuestions */7],
                          /* currentPreview : QuestionPage */Block.__(1, [
                              questionTemplates,
                              Belt_List.head(state[/* questions */4]),
                              state[/* customerInfo */10]
                            ]),
                          /* graph */state[/* graph */9],
                          /* customerInfo */state[/* customerInfo */10],
                          /* skipSurvey */state[/* skipSurvey */11],
                          /* theme */state[/* theme */12],
                          /* client */state[/* client */13],
                          /* transactionStatus */state[/* transactionStatus */14],
                          /* surveyLanguages */state[/* surveyLanguages */15],
                          /* language */state[/* language */16]
                        ];
              case /* QuestionPage */1 :
                  var question$1 = page$1[1];
                  if (question$1 !== undefined) {
                    var q = question$1;
                    var saveResponse;
                    if (action[1]) {
                      saveResponse = false;
                    } else {
                      var match$2 = Question$ReactHooksTemplate.answered(q);
                      if (match$2) {
                        var match$3 = Belt_Option.getWithDefault(Client$ReactHooksTemplate.name(state[/* client */13]), "");
                        saveResponse = match$3 === "nykaa" ? true : false;
                      } else {
                        saveResponse = false;
                      }
                    }
                    var match$4 = LogicGraph$ReactHooksTemplate.getNextNode(q, state[/* graph */9]);
                    if (match$4 !== undefined) {
                      var match$5 = match$4;
                      if (match$5.tag) {
                        var nextQuestion = match$5[0];
                        if (saveResponse) {
                          submitQuestionResponses(false, state);
                        }
                        console.log(nextQuestion);
                        console.log("Next Question");
                        var match$6 = Question$ReactHooksTemplate.questionType(nextQuestion);
                        var question$prime;
                        switch (match$6.tag | 0) {
                          case /* RatingGroup */6 :
                              var x = match$6[0];
                              var match$7 = RatingGroup$ReactHooksTemplate.carryForwardFrom(x);
                              var groupsFromQuestion;
                              if (match$7 !== undefined) {
                                var id = Caml_option.valFromOption(match$7);
                                groupsFromQuestion = List.find((function (y) {
                                        return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(y), id);
                                      }), state[/* questions */4]);
                              } else {
                                groupsFromQuestion = nextQuestion;
                              }
                              var match$8 = Question$ReactHooksTemplate.questionType(groupsFromQuestion);
                              if (match$8.tag === /* MultipleSelect */9) {
                                var y = match$8[0];
                                var match$9 = MultipleSelect$ReactHooksTemplate.getAnswer(y);
                                question$prime = Question$ReactHooksTemplate.setQuestionType(nextQuestion, /* RatingGroup */Block.__(6, [RatingGroup$ReactHooksTemplate.setGroups(x, List.map((function (z) {
                                                    return /* tuple */[
                                                            RandomId$ReactHooksTemplate.toString(MultipleSelect$ReactHooksTemplate.choiceId(z)),
                                                            MultipleSelect$ReactHooksTemplate.getChoiceText(z)
                                                          ];
                                                  }), match$9 !== undefined ? match$9 : MultipleSelect$ReactHooksTemplate.choices(y)))]));
                              } else {
                                question$prime = nextQuestion;
                              }
                              break;
                          case /* MultipleSelect */9 :
                              var x$1 = match$6[0];
                              var match$10 = MultipleSelect$ReactHooksTemplate.carryForwardFrom(x$1);
                              if (match$10 !== undefined) {
                                var id$1 = Caml_option.valFromOption(match$10);
                                var optionsFromQuestion = List.find((function (y) {
                                        return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(y), id$1);
                                      }), state[/* questions */4]);
                                var match$11 = Question$ReactHooksTemplate.questionType(optionsFromQuestion);
                                if (match$11.tag === /* MultipleSelect */9) {
                                  var y$1 = match$11[0];
                                  var match$12 = MultipleSelect$ReactHooksTemplate.getAnswer(y$1);
                                  var tmp;
                                  if (match$12 !== undefined) {
                                    var choices = match$12;
                                    tmp = List.filter((function (z) {
                                              return List.exists((function (b) {
                                                            return Caml_obj.caml_equal(MultipleSelect$ReactHooksTemplate.choiceId(z), MultipleSelect$ReactHooksTemplate.choiceId(b));
                                                          }), choices);
                                            }))(MultipleSelect$ReactHooksTemplate.choices(y$1));
                                  } else {
                                    tmp = MultipleSelect$ReactHooksTemplate.choices(y$1);
                                  }
                                  question$prime = Question$ReactHooksTemplate.setQuestionType(nextQuestion, /* MultipleSelect */Block.__(9, [MultipleSelect$ReactHooksTemplate.setChoices(tmp, x$1)]));
                                } else {
                                  question$prime = nextQuestion;
                                }
                              } else {
                                question$prime = nextQuestion;
                              }
                              break;
                          default:
                            question$prime = nextQuestion;
                        }
                        var questions$prime$1 = Belt_List.map(state[/* questions */4], (function (x) {
                                var match = Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(question$prime));
                                if (match) {
                                  return question$prime;
                                } else {
                                  return x;
                                }
                              }));
                        var graph$prime$1 = SurveyLogic$ReactHooksTemplate.addLogicToEdges(questions$prime$1, SurveyLogic$ReactHooksTemplate.changeEdges(state[/* name */1], questions$prime$1, state[/* thankYouList */5], addQuestionsToGraph(questions$prime$1)));
                        var match$13 = Question$ReactHooksTemplate.answered(question$prime);
                        var answeredQuestions$1;
                        if (match$13) {
                          var match$14 = List.exists((function (x) {
                                  return Caml_obj.caml_equal(x, Question$ReactHooksTemplate.uuid(question$prime));
                                }), state[/* answeredQuestions */7]);
                          answeredQuestions$1 = match$14 ? state[/* answeredQuestions */7] : Belt_List.concat(state[/* answeredQuestions */7], /* :: */[
                                  Question$ReactHooksTemplate.uuid(question$prime),
                                  /* [] */0
                                ]);
                        } else {
                          answeredQuestions$1 = state[/* answeredQuestions */7];
                        }
                        return /* record */[
                                /* id */state[/* id */0],
                                /* name */state[/* name */1],
                                /* logo */state[/* logo */2],
                                /* welcome */state[/* welcome */3],
                                /* questions */questions$prime$1,
                                /* thankYouList */state[/* thankYouList */5],
                                /* preview */state[/* preview */6],
                                /* answeredQuestions */answeredQuestions$1,
                                /* currentPreview : QuestionPage */Block.__(1, [
                                    questionTemplates,
                                    question$prime,
                                    state[/* customerInfo */10]
                                  ]),
                                /* graph */graph$prime$1,
                                /* customerInfo */state[/* customerInfo */10],
                                /* skipSurvey */state[/* skipSurvey */11],
                                /* theme */state[/* theme */12],
                                /* client */state[/* client */13],
                                /* transactionStatus */state[/* transactionStatus */14],
                                /* surveyLanguages */state[/* surveyLanguages */15],
                                /* language */state[/* language */16]
                              ];
                      } else {
                        if (saveResponse) {
                          submitQuestionResponses(true, state);
                        }
                        return /* record */[
                                /* id */state[/* id */0],
                                /* name */state[/* name */1],
                                /* logo */state[/* logo */2],
                                /* welcome */state[/* welcome */3],
                                /* questions */state[/* questions */4],
                                /* thankYouList */state[/* thankYouList */5],
                                /* preview */state[/* preview */6],
                                /* answeredQuestions */state[/* answeredQuestions */7],
                                /* currentPreview : ThankYou */Block.__(2, [Caml_option.some(match$5[0])]),
                                /* graph */state[/* graph */9],
                                /* customerInfo */state[/* customerInfo */10],
                                /* skipSurvey */state[/* skipSurvey */11],
                                /* theme */state[/* theme */12],
                                /* client */state[/* client */13],
                                /* transactionStatus */state[/* transactionStatus */14],
                                /* surveyLanguages */state[/* surveyLanguages */15],
                                /* language */state[/* language */16]
                              ];
                      }
                    } else {
                      if (saveResponse) {
                        submitQuestionResponses(true, state);
                      }
                      return /* record */[
                              /* id */state[/* id */0],
                              /* name */state[/* name */1],
                              /* logo */state[/* logo */2],
                              /* welcome */state[/* welcome */3],
                              /* questions */state[/* questions */4],
                              /* thankYouList */state[/* thankYouList */5],
                              /* preview */state[/* preview */6],
                              /* answeredQuestions */state[/* answeredQuestions */7],
                              /* currentPreview : ThankYou */Block.__(2, [Belt_List.head(state[/* thankYouList */5])]),
                              /* graph */state[/* graph */9],
                              /* customerInfo */state[/* customerInfo */10],
                              /* skipSurvey */state[/* skipSurvey */11],
                              /* theme */state[/* theme */12],
                              /* client */state[/* client */13],
                              /* transactionStatus */state[/* transactionStatus */14],
                              /* surveyLanguages */state[/* surveyLanguages */15],
                              /* language */state[/* language */16]
                            ];
                    }
                  } else {
                    return state;
                  }
              case /* ThankYou */2 :
                  return state;
              
            }
          }
      case /* PrevPage */5 :
          var page$2 = action[0];
          if (typeof page$2 === "number") {
            return /* record */[
                    /* id */state[/* id */0],
                    /* name */state[/* name */1],
                    /* logo */state[/* logo */2],
                    /* welcome */state[/* welcome */3],
                    /* questions */state[/* questions */4],
                    /* thankYouList */state[/* thankYouList */5],
                    /* preview */state[/* preview */6],
                    /* answeredQuestions */state[/* answeredQuestions */7],
                    /* currentPreview : Welcome */Block.__(0, [state[/* welcome */3]]),
                    /* graph */state[/* graph */9],
                    /* customerInfo */state[/* customerInfo */10],
                    /* skipSurvey */state[/* skipSurvey */11],
                    /* theme */state[/* theme */12],
                    /* client */state[/* client */13],
                    /* transactionStatus */state[/* transactionStatus */14],
                    /* surveyLanguages */state[/* surveyLanguages */15],
                    /* language */state[/* language */16]
                  ];
          } else {
            switch (page$2.tag | 0) {
              case /* QuestionPage */1 :
                  var question$2 = page$2[1];
                  if (question$2 !== undefined) {
                    var q$1 = question$2;
                    var match$15 = LogicGraph$ReactHooksTemplate.getPrevNode(q$1, state[/* graph */9]);
                    if (match$15 !== undefined) {
                      var match$16 = match$15;
                      if (match$16.tag) {
                        return /* record */[
                                /* id */state[/* id */0],
                                /* name */state[/* name */1],
                                /* logo */state[/* logo */2],
                                /* welcome */state[/* welcome */3],
                                /* questions */state[/* questions */4],
                                /* thankYouList */state[/* thankYouList */5],
                                /* preview */state[/* preview */6],
                                /* answeredQuestions */Belt_List.keep(state[/* answeredQuestions */7], (function (x) {
                                        return Caml_obj.caml_notequal(x, Question$ReactHooksTemplate.uuid(q$1));
                                      })),
                                /* currentPreview : QuestionPage */Block.__(1, [
                                    questionTemplates,
                                    match$16[0],
                                    state[/* customerInfo */10]
                                  ]),
                                /* graph */SurveyLogic$ReactHooksTemplate.addLogicToEdges(state[/* questions */4], SurveyLogic$ReactHooksTemplate.changeEdges(state[/* name */1], state[/* questions */4], state[/* thankYouList */5], addQuestionsToGraph(state[/* questions */4]))),
                                /* customerInfo */state[/* customerInfo */10],
                                /* skipSurvey */state[/* skipSurvey */11],
                                /* theme */state[/* theme */12],
                                /* client */state[/* client */13],
                                /* transactionStatus */state[/* transactionStatus */14],
                                /* surveyLanguages */state[/* surveyLanguages */15],
                                /* language */state[/* language */16]
                              ];
                      }
                      
                    }
                    return /* record */[
                            /* id */state[/* id */0],
                            /* name */state[/* name */1],
                            /* logo */state[/* logo */2],
                            /* welcome */state[/* welcome */3],
                            /* questions */state[/* questions */4],
                            /* thankYouList */state[/* thankYouList */5],
                            /* preview */state[/* preview */6],
                            /* answeredQuestions */Belt_List.keep(state[/* answeredQuestions */7], (function (x) {
                                    return Caml_obj.caml_notequal(x, Question$ReactHooksTemplate.uuid(q$1));
                                  })),
                            /* currentPreview : Welcome */Block.__(0, [state[/* welcome */3]]),
                            /* graph */state[/* graph */9],
                            /* customerInfo */state[/* customerInfo */10],
                            /* skipSurvey */state[/* skipSurvey */11],
                            /* theme */state[/* theme */12],
                            /* client */state[/* client */13],
                            /* transactionStatus */state[/* transactionStatus */14],
                            /* surveyLanguages */state[/* surveyLanguages */15],
                            /* language */state[/* language */16]
                          ];
                  } else {
                    return state;
                  }
                  break;
              case /* Welcome */0 :
              case /* ThankYou */2 :
                  return state;
              
            }
          }
          break;
      
    }
  }
}

function defaultWelcome(param) {
  return Welcome$ReactHooksTemplate.make("Your opinion matters!", "Rate our products and services.", "Take the survey", param);
}

var defaultThankYou = ThankYou$ReactHooksTemplate.make("Thank you for taking the time to give your valuable feedback!");

function defaultOpinionScale(param) {
  var arg = OpinionScaleLabel$ReactHooksTemplate.make("Left", "Center", "Right");
  var arg$1 = RandomId$ReactHooksTemplate.generate(/* () */0);
  return (function (param) {
      return (function (param$1) {
          var param$2 = param$1;
          var param$3 = arg;
          var param$4 = arg$1;
          var param$5 = param;
          var param$6 = param$2;
          var param$7 = param$3;
          var param$8 = param$4;
          return OpinionScale$ReactHooksTemplate.make("Likely to recommend", "NPS Score", param$5, param$6, param$7, param$8);
        });
    });
}

function defaultStarRating(param) {
  return StarRating$ReactHooksTemplate.make("Star Rating", "Rate our services", RandomId$ReactHooksTemplate.generate(/* () */0));
}

function defaultLongText(param) {
  var arg = RandomId$ReactHooksTemplate.generate(/* () */0);
  return (function (param) {
      return (function (param$1) {
          var param$2 = param$1;
          var param$3 = arg;
          var param$4 = param;
          var param$5 = param$2;
          var param$6 = param$3;
          return LongText$ReactHooksTemplate.make("Long Text Question", "Share the reasons for your rating", param$4, param$5, param$6);
        });
    });
}

function defaultShortText(param) {
  var arg = RandomId$ReactHooksTemplate.generate(/* () */0);
  return (function (param) {
      return (function (param$1) {
          var param$2 = param$1;
          var param$3 = arg;
          var param$4 = param;
          var param$5 = param$2;
          var param$6 = param$3;
          return ShortText$ReactHooksTemplate.make("Short Text Question", "Enter Short Text..", param$4, param$5, param$6);
        });
    });
}

function saveForm(surveyId, welcome, questions, thankYou, logicalJumpGroup) {
  var params = { };
  params["survey"] = surveyToJson(welcome, questions, thankYou, logicalJumpGroup);
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/surveys/" + (String(surveyId) + "/bulk_create"), params);
}

function SurveyView(Props) {
  var survey = Props.survey;
  var client = Props.client;
  var transactionStatus = Props.transactionStatus;
  var surveyLanguages = Props.surveyLanguages;
  var language = Props.language;
  var customerInfo = Props.customerInfo;
  var preview = Props.preview;
  var params = Props.params;
  var token = Props.token;
  var showPreview = Props.showPreview;
  var params$1 = Querystringify.parse(params);
  var emptyState_009 = /* graph */LogicGraph$ReactHooksTemplate.empty(/* () */0);
  var emptyState_010 = /* customerInfo */CustomerInfo$ReactHooksTemplate.empty(/* () */0);
  var emptyState_012 = /* theme */SurveyTheme$ReactHooksTemplate.empty(/* () */0);
  var emptyState_013 = /* client */Client$ReactHooksTemplate.empty(/* () */0);
  var emptyState = /* record */[
    /* id */token,
    /* name */"",
    /* logo */undefined,
    /* welcome */undefined,
    /* questions : [] */0,
    /* thankYouList : [] */0,
    /* preview : Empty */0,
    /* answeredQuestions : [] */0,
    /* currentPreview : Empty */0,
    emptyState_009,
    emptyState_010,
    /* skipSurvey */false,
    emptyState_012,
    emptyState_013,
    /* transactionStatus */"",
    /* surveyLanguages : [] */0,
    /* language */SurveyLanguage$ReactHooksTemplate.$$default
  ];
  var match = React.useReducer(reducer, emptyState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return /* Loading */0;
        }));
  var setMode = match$1[1];
  var x = Js_dict.get(params$1, "ltr");
  var nps = x !== undefined ? x : Js_dict.get(params$1, "smiley_rating");
  React.useEffect((function () {
          Curry._1(dispatch, /* Initialize */Block.__(0, [
                  token,
                  Survey$ReactHooksTemplate.name(survey),
                  Survey$ReactHooksTemplate.logo(survey),
                  Survey$ReactHooksTemplate.welcome(survey),
                  Survey$ReactHooksTemplate.questions(survey),
                  Survey$ReactHooksTemplate.thankYouList(survey),
                  customerInfo,
                  Survey$ReactHooksTemplate.surveyTheme(survey),
                  client,
                  transactionStatus,
                  surveyLanguages,
                  language
                ]));
          return ;
        }), /* tuple */[
        survey,
        showPreview
      ]);
  React.useEffect((function () {
          switch (transactionStatus) {
            case "closed" :
            case "completed" :
                ReasonReactRouter.push("/surveys/410");
                break;
            default:
              var exit = 0;
              if (nps !== undefined) {
                var rating = nps;
                if (rating === "") {
                  exit = 1;
                } else {
                  var firstQuestion = Belt_List.head(Survey$ReactHooksTemplate.questions(survey));
                  if (firstQuestion !== undefined) {
                    var question = firstQuestion;
                    var questionType = Question$ReactHooksTemplate.questionType(question);
                    switch (questionType.tag | 0) {
                      case /* Nps */4 :
                          var nps$1 = questionType[0];
                          var answer = Caml_format.caml_float_of_string(rating) | 0;
                          if (answer >= 0 && answer < 11) {
                            var match = Nps$ReactHooksTemplate.answered(nps$1) && Nps$ReactHooksTemplate.answerToString(nps$1) === String(answer);
                            var updatedNps = match ? Nps$ReactHooksTemplate.setAnswer(Nps$ReactHooksTemplate.setAnswer(nps$1, answer), answer) : Nps$ReactHooksTemplate.setAnswer(nps$1, answer);
                            var question$1 = Question$ReactHooksTemplate.setQuestionType(question, /* Nps */Block.__(4, [updatedNps]));
                            Curry._1(dispatch, /* UpdateQuestion */Block.__(1, [question$1]));
                            Curry._1(dispatch, /* NextPage */Block.__(4, [
                                    /* QuestionPage */Block.__(1, [
                                        questionTemplates,
                                        question$1,
                                        state[/* customerInfo */10]
                                      ]),
                                    preview
                                  ]));
                          } else {
                            Curry._1(dispatch, /* PreviewQuestions */1);
                          }
                          break;
                      case /* SmileyRating */7 :
                          var smileyRating = questionType[0];
                          var answer$1 = List.find_opt((function (x) {
                                  return SmileyRating$ReactHooksTemplate.ratingTypeToRating(x) === Caml_format.caml_int_of_string(rating);
                                }), SmileyRating$ReactHooksTemplate.ratingTypeCollection(smileyRating));
                          if (answer$1 !== undefined) {
                            var updatedSmileyRating = SmileyRating$ReactHooksTemplate.setAnswer(smileyRating, answer$1);
                            var question$2 = Question$ReactHooksTemplate.setQuestionType(question, /* SmileyRating */Block.__(7, [updatedSmileyRating]));
                            Curry._1(dispatch, /* UpdateQuestion */Block.__(1, [question$2]));
                            Curry._1(dispatch, /* NextPage */Block.__(4, [
                                    /* QuestionPage */Block.__(1, [
                                        questionTemplates,
                                        question$2,
                                        state[/* customerInfo */10]
                                      ]),
                                    preview
                                  ]));
                          } else {
                            Curry._1(dispatch, /* PreviewQuestions */1);
                          }
                          break;
                      default:
                        Curry._1(dispatch, /* PreviewQuestions */1);
                    }
                  }
                  
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                var lastAnsweredQuestion = Belt_List.reduce(Survey$ReactHooksTemplate.questions(survey), undefined, (function (x, y) {
                        var match = Question$ReactHooksTemplate.answered(y);
                        if (match) {
                          return y;
                        } else {
                          return x;
                        }
                      }));
                if (lastAnsweredQuestion !== undefined) {
                  Curry._1(dispatch, /* NextPage */Block.__(4, [
                          /* QuestionPage */Block.__(1, [
                              questionTemplates,
                              lastAnsweredQuestion,
                              customerInfo
                            ]),
                          preview
                        ]));
                }
                
              }
              
          }
          Curry._1(setMode, (function (param) {
                  return /* Ready */1;
                }));
          return ;
        }), ([]));
  var onNextButtonClick = function (param) {
    return Curry._1(dispatch, /* NextPage */Block.__(4, [
                  state[/* currentPreview */8],
                  preview
                ]));
  };
  var onPrevButtonClick = function (param) {
    return Curry._1(dispatch, /* PrevPage */Block.__(5, [state[/* currentPreview */8]]));
  };
  var surveyPreview = function (type_) {
    var match = state[/* currentPreview */8];
    var page;
    page = typeof match === "number" || !(match.tag === /* QuestionPage */1 && match[1] === undefined) ? state[/* currentPreview */8] : /* ThankYou */Block.__(2, [Belt_List.head(state[/* thankYouList */5])]);
    var match$1 = state[/* currentPreview */8];
    var showPreviousButton;
    if (typeof match$1 === "number" || match$1.tag !== /* QuestionPage */1) {
      showPreviousButton = false;
    } else {
      var q = match$1[1];
      showPreviousButton = q !== undefined ? LogicGraph$ReactHooksTemplate.getPrevNode(q, state[/* graph */9]) !== undefined : false;
    }
    var match$2 = state[/* currentPreview */8];
    var showSubmitButton;
    if (typeof match$2 === "number" || match$2.tag !== /* QuestionPage */1) {
      showSubmitButton = false;
    } else {
      var q$1 = match$2[1];
      if (q$1 !== undefined) {
        var match$3 = LogicGraph$ReactHooksTemplate.getEdges(q$1, state[/* graph */9]);
        showSubmitButton = match$3 ? false : true;
      } else {
        showSubmitButton = false;
      }
    }
    var match$4 = state[/* currentPreview */8];
    var showNextButton;
    if (typeof match$4 === "number" || match$4.tag !== /* QuestionPage */1) {
      showNextButton = false;
    } else {
      var q$2 = match$4[1];
      if (q$2 !== undefined) {
        var q$3 = q$2;
        showNextButton = Question$ReactHooksTemplate.answered(q$3) || !Question$ReactHooksTemplate.mandatory(q$3);
      } else {
        showNextButton = false;
      }
    }
    return React.createElement(React.Fragment, undefined, React.createElement(SurveyPreviewHtmlRenderer$ReactHooksTemplate.make, {
                    page: page,
                    logo: state[/* logo */2],
                    theme: state[/* theme */12],
                    client: state[/* client */13],
                    onNextButtonClick: onNextButtonClick,
                    onPrevButtonClick: onPrevButtonClick,
                    handleQuestionPreview: (function (y) {
                        Curry._1(dispatch, /* UpdateQuestion */Block.__(1, [y]));
                        var match = Question$ReactHooksTemplate.questionType(y);
                        switch (match.tag | 0) {
                          case /* OpinionScale */0 :
                              var opinionScale = match[0];
                              var match$1 = OpinionScale$ReactHooksTemplate.autoAdvance(opinionScale) && OpinionScale$ReactHooksTemplate.answered(opinionScale);
                              if (match$1) {
                                return Curry._1(dispatch, /* NextPage */Block.__(4, [
                                              page,
                                              preview
                                            ]));
                              } else {
                                return /* () */0;
                              }
                          case /* Nps */4 :
                              var nps = match[0];
                              var match$2 = Nps$ReactHooksTemplate.autoAdvance(nps) && Nps$ReactHooksTemplate.answered(nps);
                              if (match$2) {
                                return Curry._1(dispatch, /* NextPage */Block.__(4, [
                                              page,
                                              preview
                                            ]));
                              } else {
                                return /* () */0;
                              }
                          default:
                            return /* () */0;
                        }
                      }),
                    onLanguageChange: (function (e) {
                        var languageName = e.target.value;
                        var language = List.find((function (x) {
                                return SurveyLanguage$ReactHooksTemplate.name(x) === languageName;
                              }), state[/* surveyLanguages */15]);
                        return Curry._1(dispatch, /* ChangeLanguage */Block.__(2, [language]));
                      }),
                    handleStart: (function (param) {
                        return Curry._1(dispatch, /* PreviewQuestions */1);
                      }),
                    type_: type_,
                    handleFormOnSubmit: (function (param) {
                        if (preview) {
                          return /* () */0;
                        } else {
                          return submitQuestionResponses(!state[/* skipSurvey */11], state);
                        }
                      }),
                    showPreviousButton: showPreviousButton,
                    showNextButton: showNextButton,
                    showSubmitButton: showSubmitButton,
                    languageSelected: state[/* language */16],
                    surveyLanguages: state[/* surveyLanguages */15]
                  }));
  };
  switch (match$1[0]) {
    case /* Loading */0 :
        return React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading Survey..."
                      }));
    case /* Ready */1 :
        if (preview) {
          return React.createElement(React.Fragment, undefined, surveyPreview(/* Modal */1));
        } else {
          return React.createElement(React.Fragment, undefined, surveyPreview(/* Page */0));
        }
    case /* Saving */2 :
        return React.createElement("p", undefined, "Saving survey in progress...");
    
  }
}

var yesbankQuestiontemplate = "\n  <div class=\"flex flex-col h-full min-h-screen\">\n    <div class=\"h-80-px small:h-60-px mb-10 small:mb-12\">\n      <div id=\"logo\">\n        logo\n      </div>\n    </div>\n    <div class=\"mx-12 small:mx-4 flex flex-col\">\n      <div class=\"pl-142-px small:pl-4 pr-80-px small:pr-4 pt-24 small:pt-4 question-box-gradient rounded-lg mb-8 overflow-y-auto pb-16\n    small:pb-12\" style=\"height: calc(100vh - 250px);\">\n        <div id=\"question\">question</div>\n      </div>\n    </div>\n\n    <div class=\"flex justify-center items-center small:mb-8 mb-4\">\n      <div id=\"previousButton\"></div>\n      <div id=\"nextButton\"></div>\n    </div>\n  </div>\n";

var make = SurveyView;

exports.yesbankQuestiontemplate = yesbankQuestiontemplate;
exports.defaultQuestiontemplate = defaultQuestiontemplate;
exports.bhimaQuestiontemplate = bhimaQuestiontemplate;
exports.questionTemplates = questionTemplates;
exports.questionsOrPageToString = questionsOrPageToString;
exports.surveyToJson = surveyToJson;
exports.initialPreviewState = initialPreviewState;
exports.addQuestionsToGraph = addQuestionsToGraph;
exports.surveyAnswers = surveyAnswers;
exports.submitQuestionResponses = submitQuestionResponses;
exports.reducer = reducer;
exports.defaultWelcome = defaultWelcome;
exports.defaultThankYou = defaultThankYou;
exports.defaultOpinionScale = defaultOpinionScale;
exports.defaultStarRating = defaultStarRating;
exports.defaultLongText = defaultLongText;
exports.defaultShortText = defaultShortText;
exports.saveForm = saveForm;
exports.make = make;
/* defaultThankYou Not a pure module */
