// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("../filters/filter.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var FilterPills$ReactHooksTemplate = require("../filters/filterPills.bs.js");
var ChartComponent$ReactHooksTemplate = require("./ChartComponent.bs.js");
var InsightsFilters$ReactHooksTemplate = require("../filters/InsightsFilters.bs.js");

function reducer(state, param) {
  if (typeof param === "number") {
    switch (param) {
      case /* FetchFiltersInProgress */0 :
          return /* record */[
                  /* loadingProduct */true,
                  /* loadingVoc */true,
                  /* loadingCorrelationData */state[/* loadingCorrelationData */2],
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */state[/* filters */7],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      case /* ToggleFilter */1 :
          return /* record */[
                  /* loadingProduct */state[/* loadingProduct */0],
                  /* loadingVoc */state[/* loadingVoc */1],
                  /* loadingCorrelationData */state[/* loadingCorrelationData */2],
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */!state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */state[/* filters */7],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      case /* FetchProductChartsInProgress */2 :
      case /* FetchVocChartsInProgress */3 :
          break;
      case /* FetchCorrelationDataInProgress */4 :
          return /* record */[
                  /* loadingProduct */state[/* loadingProduct */0],
                  /* loadingVoc */state[/* loadingVoc */1],
                  /* loadingCorrelationData */true,
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */state[/* filters */7],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      
    }
  } else {
    switch (param.tag | 0) {
      case /* FetchedCorrelationData */0 :
          return /* record */[
                  /* loadingProduct */state[/* loadingProduct */0],
                  /* loadingVoc */state[/* loadingVoc */1],
                  /* loadingCorrelationData */state[/* loadingCorrelationData */2],
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */state[/* filters */7],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */param[0]
                ];
      case /* FetchProductChartsCompleted */1 :
          return /* record */[
                  /* loadingProduct */false,
                  /* loadingVoc */state[/* loadingVoc */1],
                  /* loadingCorrelationData */state[/* loadingCorrelationData */2],
                  /* productCharts */param[0],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */state[/* filters */7],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      case /* SetFiltersAndPills */2 :
          return /* record */[
                  /* loadingProduct */true,
                  /* loadingVoc */true,
                  /* loadingCorrelationData */true,
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */param[1],
                  /* filters */param[0],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      case /* SetFilters */3 :
          return /* record */[
                  /* loadingProduct */state[/* loadingProduct */0],
                  /* loadingVoc */state[/* loadingVoc */1],
                  /* loadingCorrelationData */state[/* loadingCorrelationData */2],
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */state[/* vocCharts */4],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */param[0],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      case /* FetchVocChartsCompleted */4 :
          return /* record */[
                  /* loadingProduct */state[/* loadingProduct */0],
                  /* loadingVoc */false,
                  /* loadingCorrelationData */state[/* loadingCorrelationData */2],
                  /* productCharts */state[/* productCharts */3],
                  /* vocCharts */param[0],
                  /* toggleFilter */state[/* toggleFilter */5],
                  /* filterPills */state[/* filterPills */6],
                  /* filters */state[/* filters */7],
                  /* currentFilter */state[/* currentFilter */8],
                  /* correlationData */state[/* correlationData */9]
                ];
      
    }
  }
  return /* record */[
          /* loadingProduct */true,
          /* loadingVoc */state[/* loadingVoc */1],
          /* loadingCorrelationData */state[/* loadingCorrelationData */2],
          /* productCharts */state[/* productCharts */3],
          /* vocCharts */state[/* vocCharts */4],
          /* toggleFilter */state[/* toggleFilter */5],
          /* filterPills */state[/* filterPills */6],
          /* filters */state[/* filters */7],
          /* currentFilter */state[/* currentFilter */8],
          /* correlationData */state[/* correlationData */9]
        ];
}

var initialState_009 = /* correlationData : record */[
  /* categories : [] */0,
  /* data */null
];

var initialState = /* record */[
  /* loadingProduct */true,
  /* loadingVoc */true,
  /* loadingCorrelationData */true,
  /* productCharts */undefined,
  /* vocCharts */undefined,
  /* toggleFilter */false,
  /* filterPills : [] */0,
  /* filters : [] */0,
  /* currentFilter */0,
  initialState_009
];

var scale = {
  x: {
    type: "field",
    values: /* :: */[
      "MSISDN",
      /* :: */[
        "NPS",
        /* :: */[
          "CSAT",
          /* :: */[
            "TALK_DURATION",
            /* :: */[
              "TRIGGER_DATE",
              /* :: */[
                "TOUCH_POINT",
                /* :: */[
                  "LOB",
                  /* :: */[
                    "AGENT_NAME",
                    /* :: */[
                      "NPS_RESPONSE_TIME",
                      /* :: */[
                        "FCR",
                        /* :: */[
                          "AGENT_PERFORMANCE",
                          /* :: */[
                            "ISSUE_RESOLUTION",
                            /* :: */[
                              "VERBATIM",
                              /* :: */[
                                "SENTIMENT",
                                /* :: */[
                                  "CATEGORIES",
                                  /* :: */[
                                    "HVC",
                                    /* :: */[
                                      "HVC_SEGMENT",
                                      /* :: */[
                                        "REASON",
                                        /* :: */[
                                          "OUTCOME",
                                          /* :: */[
                                            "CATEGORY",
                                            /* :: */[
                                              "DISPOSITION",
                                              /* :: */[
                                                "INVITE_TYPE",
                                                /* :: */[
                                                  "CHANNEL_TYPE",
                                                  /* :: */[
                                                    "CALL_START_TIME",
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  },
  y: {
    type: "field",
    values: /* :: */[
      "MSISDN",
      /* :: */[
        "NPS",
        /* :: */[
          "CSAT",
          /* :: */[
            "TALK_DURATION",
            /* :: */[
              "TRIGGER_DATE",
              /* :: */[
                "TOUCH_POINT",
                /* :: */[
                  "LOB",
                  /* :: */[
                    "AGENT_NAME",
                    /* :: */[
                      "NPS_RESPONSE_TIME",
                      /* :: */[
                        "FCR",
                        /* :: */[
                          "AGENT_PERFORMANCE",
                          /* :: */[
                            "ISSUE_RESOLUTION",
                            /* :: */[
                              "VERBATIM",
                              /* :: */[
                                "SENTIMENT",
                                /* :: */[
                                  "CATEGORIES",
                                  /* :: */[
                                    "HVC",
                                    /* :: */[
                                      "HVC_SEGMENT",
                                      /* :: */[
                                        "REASON",
                                        /* :: */[
                                          "OUTCOME",
                                          /* :: */[
                                            "CATEGORY",
                                            /* :: */[
                                              "DISPOSITION",
                                              /* :: */[
                                                "INVITE_TYPE",
                                                /* :: */[
                                                  "CHANNEL_TYPE",
                                                  /* :: */[
                                                    "CALL_START_TIME",
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  },
  sales: {
    nice: true
  }
};

var grid = {
  alignTick: false,
  line: {
    style: {
      lineWidth: 1,
      lineDash: null,
      stroke: "#f0f0f0"
    }
  }
};

var style = {
  lineWidth: 1,
  stroke: "#fff"
};

function Insights(Props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualizations = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/visualizations?session=product", ChartId$ReactHooksTemplate.fromJson).then((function (xs) {
              console.log(xs);
              return Promise.resolve(xs);
            })).then((function (xs) {
            return Promise.resolve(setTimeout((function (param) {
                                return Curry._1(dispatch, /* FetchProductChartsCompleted */Block.__(1, [xs]));
                              }), Utils$ReactHooksTemplate.timeout)).then((function (param) {
                          return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/visualizations?session=voc", ChartId$ReactHooksTemplate.fromJson).then((function (xs) {
                                          console.log(xs);
                                          return Promise.resolve(xs);
                                        })).then((function (xs) {
                                        return Promise.resolve(Promise.resolve((setTimeout((function (param) {
                                                                  return Curry._1(dispatch, /* FetchVocChartsCompleted */Block.__(4, [xs]));
                                                                }), Utils$ReactHooksTemplate.timeout), /* () */0)).then((function (param) {
                                                          return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/correlation_data", (function (json) {
                                                                            return /* record */[
                                                                                    /* categories */Json_decode.field("categories", (function (param) {
                                                                                            return Json_decode.list(Json_decode.string, param);
                                                                                          }), json),
                                                                                    /* data */Belt_Option.getWithDefault(Js_json.decodeObject(json), { })["data"]
                                                                                  ];
                                                                          })).then((function (xs) {
                                                                          console.log(xs);
                                                                          return Promise.resolve(xs);
                                                                        })).then((function (xs) {
                                                                        return Promise.resolve((setTimeout((function (param) {
                                                                                            return Curry._1(dispatch, /* FetchedCorrelationData */Block.__(0, [xs]));
                                                                                          }), Utils$ReactHooksTemplate.timeout), /* () */0));
                                                                      }));
                                                        })));
                                      }));
                        }));
          }));
    return /* () */0;
  };
  React.useEffect((function () {
          fetchVisualizations(/* () */0);
          return ;
        }), ([]));
  var onApplyFilter = function (param) {
    fetchVisualizations(/* () */0);
    return /* () */0;
  };
  var toggleFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    return Curry._1(dispatch, /* SetFilters */Block.__(3, [state[/* filters */7]]));
  };
  var match$1 = state[/* toggleFilter */5];
  var match$2 = state[/* loadingProduct */0];
  var tmp;
  if (match$2) {
    tmp = React.createElement("div", undefined, React.createElement("p", undefined, "Loading..."));
  } else {
    var match$3 = state[/* productCharts */3];
    tmp = match$3 !== undefined ? $$Array.of_list(List.map((function (chartId) {
                  return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                              chartId: chartId,
                              drillDownChartDetails: undefined,
                              onClick: (function (param, param$1, param$2) {
                                  return /* () */0;
                                }),
                              onEnlarge: (function (param) {
                                  return /* () */0;
                                }),
                              insight: true,
                              key: String(ChartId$ReactHooksTemplate.id(chartId))
                            });
                }), match$3)) : React.createElement(React.Fragment, undefined);
  }
  var match$4 = state[/* loadingVoc */1];
  var tmp$1;
  if (match$4) {
    tmp$1 = React.createElement("div", undefined, React.createElement("p", undefined, "Loading..."));
  } else {
    var match$5 = state[/* vocCharts */4];
    tmp$1 = match$5 !== undefined ? $$Array.of_list(List.map((function (chartId) {
                  return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                              chartId: chartId,
                              drillDownChartDetails: undefined,
                              onClick: (function (param, param$1, param$2) {
                                  return /* () */0;
                                }),
                              onEnlarge: (function (param) {
                                  return /* () */0;
                                }),
                              insight: true,
                              key: String(ChartId$ReactHooksTemplate.id(chartId)) + "5"
                            });
                }), match$5)) : React.createElement(React.Fragment, undefined);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "p-20-px bg-porcelain"
                }, match$1 ? React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                        onClose: toggleFilter,
                        onApply: onApplyFilter,
                        showDateFilter: false,
                        showFilterPills: false,
                        correlation: false
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "px-16-px flex justify-between pb-20-px"
                    }, React.createElement("div", {
                          className: "flex flex-wrap gap-2"
                        }, React.createElement("div", {
                              className: "flex items-center font-normal text-sm h-8"
                            }, "Active Filters"), React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                              onClose: toggleFilter,
                              onApply: onApplyFilter,
                              showDateFilter: false,
                              showFilterPills: true,
                              correlation: false
                            })), React.createElement("div", {
                          className: "float-right"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n                              shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return toggleFilter(/* () */0);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"))), React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "w-full border mb-2 bg-white h-10"
                        }, React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("div", {
                                  className: "w-3 bg-red-600 h-10 mr-2"
                                }), React.createElement("p", {
                                  className: "text-lg font-extrabold"
                                }, "PRODUCT / SERVICE IMPACT"))), React.createElement("div", {
                          className: "flex flex-wrap"
                        }, tmp)), React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "w-full border mb-2 bg-white h-10"
                        }, React.createElement("div", {
                              className: "flex items-center mr-16"
                            }, React.createElement("div", {
                                  className: "w-3 bg-red-600 h-10 mr-2"
                                }), React.createElement("p", {
                                  className: "text-lg font-extrabold"
                                }, "VOC IMPACT"))), React.createElement("div", {
                          className: "flex flex-wrap"
                        }, tmp$1))));
}

var color = /* :: */[
  "#BAE7FF",
  /* :: */[
    "#1890FF",
    /* :: */[
      "#0050B3",
      /* [] */0
    ]
  ]
];

var make = Insights;

exports.reducer = reducer;
exports.initialState = initialState;
exports.scale = scale;
exports.grid = grid;
exports.color = color;
exports.style = style;
exports.make = make;
/* initialState Not a pure module */
