// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Filter$ReactHooksTemplate = require("../filters/filter.bs.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");

function data(t) {
  return t[/* data */0];
}

function layout(t) {
  var match = t[/* layout */1];
  if (match === "vertical") {
    return /* vertical */-367674250;
  } else {
    return /* horizontal */365165796;
  }
}

function xAxis(t) {
  return t[/* xAxis */2];
}

function yAxis(t) {
  return t[/* yAxis */4];
}

function yAxisList(t) {
  return t[/* yAxisList */3];
}

function colors(t) {
  return t[/* colors */6];
}

function onClick(t) {
  return t[/* onClick */5];
}

function width(t) {
  return t[/* width */7];
}

function height(t) {
  return t[/* height */8];
}

var decodeFilter = Filter$ReactHooksTemplate.decodeFilterItem;

function decodeOnClick(json) {
  var onClick = Json_decode.field("action", Json_decode.string, json);
  var match = onClick === "jump_to";
  if (match) {
    return /* GotoDashboard */Block.__(1, [Json_decode.field("dashboard_name", Json_decode.string, json)]);
  } else {
    var match$1 = onClick === "filter";
    if (match$1) {
      return /* DrillDown */Block.__(0, [Json_decode.field("filter", decodeFilter, json)]);
    } else {
      return /* NoAction */0;
    }
  }
}

function fromJson(json) {
  var data = Belt_Option.getWithDefault(Js_json.decodeObject(json), { });
  return /* record */[
          /* data */Js_dict.get(data, "data"),
          /* layout */Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("layout", (function (param) {
                                return Json_decode.optional(Json_decode.string, param);
                              }), param);
                }), json),
          /* xAxis */Json_decode.field("x_axis", AxisData$ReactHooksTemplate.decode, json),
          /* yAxisList */Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("y_axis_list", (function (param) {
                                return Json_decode.list(AxisData$ReactHooksTemplate.decode, param);
                              }), param);
                }), json),
          /* yAxis */Json_decode.field("y_axis", (function (param) {
                  return Json_decode.list(AxisData$ReactHooksTemplate.decode, param);
                }), json),
          /* onClick */Json_decode.withDefault(/* NoAction */0, (function (param) {
                  return Json_decode.field("on_click", decodeOnClick, param);
                }), json),
          /* colors */ChartComponentColor$ReactHooksTemplate.make(Json_decode.optional((function (param) {
                      return Json_decode.field("colors", (function (param) {
                                    return Json_decode.list(Json_decode.string, param);
                                  }), param);
                    }), json)),
          /* width */Json_decode.optional((function (param) {
                  return Json_decode.field("width", Json_decode.string, param);
                }), json),
          /* height */Json_decode.optional((function (param) {
                  return Json_decode.field("height", Json_decode.string, param);
                }), json)
        ];
}

exports.data = data;
exports.layout = layout;
exports.xAxis = xAxis;
exports.yAxis = yAxis;
exports.yAxisList = yAxisList;
exports.colors = colors;
exports.width = width;
exports.height = height;
exports.fromJson = fromJson;
exports.onClick = onClick;
/* Filter-ReactHooksTemplate Not a pure module */
