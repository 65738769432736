// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var TicketActivity$ReactHooksTemplate = require("./ticketActivity.bs.js");
var TicketParkView$ReactHooksTemplate = require("./TicketParkView.bs.js");
var TicketCloseView$ReactHooksTemplate = require("./TicketCloseView.bs.js");
var TicketAssignView$ReactHooksTemplate = require("./TicketAssignView.bs.js");
var TicketReopenView$ReactHooksTemplate = require("./TicketReopenView.bs.js");
var TicketCommentView$ReactHooksTemplate = require("./TicketCommentView.bs.js");
var TicketResolveView$ReactHooksTemplate = require("./TicketResolveView.bs.js");

function TicketActivityView(Props) {
  var activity = Props.activity;
  var match = TicketActivity$ReactHooksTemplate.activityDetails(activity);
  var tmp;
  switch (match.tag | 0) {
    case /* TicketClose */0 :
        tmp = React.createElement(TicketCloseView$ReactHooksTemplate.make, {
              activity: match[0]
            });
        break;
    case /* TicketAssign */1 :
        tmp = React.createElement(TicketAssignView$ReactHooksTemplate.make, {
              activity: match[0]
            });
        break;
    case /* TicketResolve */2 :
        tmp = React.createElement(TicketResolveView$ReactHooksTemplate.make, {
              activity: match[0]
            });
        break;
    case /* TicketPark */3 :
        tmp = React.createElement(TicketParkView$ReactHooksTemplate.make, {
              activity: match[0]
            });
        break;
    case /* TicketComment */4 :
        tmp = React.createElement(TicketCommentView$ReactHooksTemplate.make, {
              activity: match[0]
            });
        break;
    case /* TicketReopen */5 :
        tmp = React.createElement(TicketReopenView$ReactHooksTemplate.make, {
              activity: match[0]
            });
        break;
    
  }
  return React.createElement("div", {
              className: "flex w-full"
            }, React.createElement("img", {
                  className: "h-10 w-10 mr-2-px",
                  src: Image$ReactHooksTemplate.commentIcon
                }), React.createElement("div", {
                  className: "bg-cultured w-full ml-4 rounded-lg min-h-100-px p-4"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-xs font-normal"
                    }, TicketActivity$ReactHooksTemplate.createdAt(activity).format("llll")), React.createElement("div", {
                      className: "mt-2"
                    }, tmp)));
}

var make = TicketActivityView;

exports.make = make;
/* react Not a pure module */
