// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var DataField$ReactHooksTemplate = require("./dataField.bs.js");
var RoleCount$ReactHooksTemplate = require("./RoleCount.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var UserRoleItem$ReactHooksTemplate = require("./UserRoleItem.bs.js");
var DataRestriction$ReactHooksTemplate = require("./dataRestriction.bs.js");

function UserListItem(Props) {
  var email = Props.email;
  var active = Props.active;
  var roles = Props.roles;
  var dataRestrictions = Props.dataRestrictions;
  var lastSignInAt = Props.lastSignInAt;
  var handleDelete = Props.handleDelete;
  var handleEdit = Props.handleEdit;
  var emailText = Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, email);
  var match = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match[0];
  var activeText = active ? "Active" : "Inactive";
  var activeTextCSS = active ? "text-teal-700" : "";
  var remainingRolesCount = function (xs) {
    var count = List.length(xs);
    if (count !== 0) {
      return React.createElement(RoleCount$ReactHooksTemplate.make, {
                  count: count
                });
    } else {
      return null;
    }
  };
  var match$1;
  if (roles) {
    var match$2 = roles[1];
    var onlyRole = roles[0];
    if (match$2) {
      var rest = match$2[1];
      var secondRole = match$2[0];
      match$1 = rest ? /* tuple */[
          React.createElement(UserRoleItem$ReactHooksTemplate.make, {
                role: onlyRole
              }),
          React.createElement(UserRoleItem$ReactHooksTemplate.make, {
                role: secondRole
              }),
          remainingRolesCount(rest)
        ] : /* tuple */[
          React.createElement(UserRoleItem$ReactHooksTemplate.make, {
                role: onlyRole
              }),
          React.createElement(UserRoleItem$ReactHooksTemplate.make, {
                role: secondRole
              }),
          null
        ];
    } else {
      match$1 = /* tuple */[
        React.createElement(UserRoleItem$ReactHooksTemplate.make, {
              role: onlyRole
            }),
        null,
        null
      ];
    }
  } else {
    match$1 = /* tuple */[
      null,
      null,
      null
    ];
  }
  var match$3 = Curry._1(hasPermission, /* UpdateUser */8);
  var match$4 = Curry._1(hasPermission, /* DeleteUser */7);
  return React.createElement("div", {
              className: "flex items-center p-10-px hover:bg-teal-100 border-b"
            }, React.createElement("p", {
                  className: "w-1/4 "
                }, emailText), React.createElement("p", {
                  className: "w-60-px mr-30-px " + (String(activeTextCSS) + "")
                }, activeText), React.createElement("div", {
                  className: "flex items-center w-1/4 text-12-px"
                }, React.createElement("div", {
                      className: "mr-10-px"
                    }, match$1[0]), React.createElement("div", {
                      className: "mr-10-px"
                    }, match$1[1]), match$1[2]), React.createElement("div", {
                  className: "w-1/4 flex flex-wrap"
                }, $$Array.of_list(Belt_List.mapWithIndex(dataRestrictions, (function (i, x) {
                            var match = DataRestriction$ReactHooksTemplate.value(x);
                            return React.createElement("p", {
                                        key: String(i),
                                        className: "text-xs bg-gray-200 p-1 m-1"
                                      }, DataField$ReactHooksTemplate.name(DataRestriction$ReactHooksTemplate.dataField(x)) + ": ", React.createElement("span", {
                                            className: "text-xs font-extrabold"
                                          }, match ? List.fold_left((function (x, y) {
                                                    return y + ("," + x);
                                                  }), match[0], match[1]) : "---"));
                          })))), React.createElement("p", {
                  className: "w-1/4 "
                }, lastSignInAt !== undefined ? (function (param) {
                        return param.fromNow(false);
                      })(Caml_option.valFromOption(lastSignInAt)) : ""), React.createElement("p", {
                  className: "w-1/4 flex justify-end"
                }, match$3 ? React.createElement("button", {
                        className: "hover:text-teal-500 py-6-px px-10-px border border-gray-400 hover:border-teal-300 mr-20-px",
                        onClick: handleEdit
                      }, "Edit") : React.createElement(React.Fragment, undefined), match$4 ? React.createElement("button", {
                        className: "text-red-600 hover:text-white py-6-px px-10-px border border-red-400 hover:bg-red-600",
                        onClick: handleDelete
                      }, "Delete") : React.createElement(React.Fragment, undefined)));
}

var make = UserListItem;

exports.make = make;
/* react Not a pure module */
