// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");

function Loading(Props) {
  var match = Props.img;
  var img = match !== undefined ? Caml_option.valFromOption(match) : undefined;
  var match$1 = Props.text;
  var text = match$1 !== undefined ? match$1 : "";
  var match$2 = Props.loading;
  var loading = match$2 !== undefined ? match$2 : true;
  return React.createElement("div", {
              className: "h-full w-full flex flex-col justify-center bg-white"
            }, img !== undefined ? React.createElement("img", {
                    className: "h-32",
                    src: img
                  }) : React.createElement(React.Fragment, undefined), loading ? React.createElement("img", {
                    className: "h-10 m-4 loading-animation",
                    src: Image$ReactHooksTemplate.sandClock
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                  className: "text-center"
                }, text));
}

var make = Loading;

exports.make = make;
/* react Not a pure module */
