// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");

function getAnswer(question) {
  var match = Question$ReactHooksTemplate.questionType(question);
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.answerToString(match[0]);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.answerToString(match[0]);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.answerToString(match[0]);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.answerToString(match[0]);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.answerToString(match[0]);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.answerToString(match[0]);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.answerToString(match[0]);
    default:
      return "";
  }
}

function getAnswerList(question) {
  var match = Question$ReactHooksTemplate.questionType(question);
  switch (match.tag | 0) {
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.answerIdList(match[0]);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.answerIdList(match[0]);
    default:
      return /* [] */0;
  }
}

function compare(lhs, branchLogicComparator) {
  switch (branchLogicComparator.tag | 0) {
    case /* EqualsFromValue */0 :
        return lhs === branchLogicComparator[0];
    case /* EqualsFromList */1 :
        return Belt_List.has(branchLogicComparator[0], lhs, (function (item, search) {
                      return item === search;
                    }));
    case /* Conditional */2 :
        var lhs$1 = lhs;
        var comparator = branchLogicComparator[0];
        switch (comparator.tag | 0) {
          case /* Equals */0 :
              var lhs$2 = lhs$1;
              var comparatorValueType = comparator[0];
              if (comparatorValueType.tag) {
                return lhs$2 === comparatorValueType[0];
              } else {
                return Caml_format.caml_int_of_string(lhs$2) === Caml_format.caml_int_of_string(comparatorValueType[0]);
              }
          case /* LessThan */1 :
              var lhs$3 = lhs$1;
              var comparatorValueType$1 = comparator[0];
              if (comparatorValueType$1.tag) {
                return lhs$3 < comparatorValueType$1[0];
              } else {
                return Caml_format.caml_int_of_string(lhs$3) < Caml_format.caml_int_of_string(comparatorValueType$1[0]);
              }
          case /* GreaterThan */2 :
              var lhs$4 = lhs$1;
              var comparatorValueType$2 = comparator[0];
              if (comparatorValueType$2.tag) {
                return lhs$4 > comparatorValueType$2[0];
              } else {
                return Caml_format.caml_int_of_string(lhs$4) > Caml_format.caml_int_of_string(comparatorValueType$2[0]);
              }
          
        }
    case /* StringConditional */3 :
        var lhs$5 = lhs;
        var stringComparator = branchLogicComparator[0];
        switch (stringComparator.tag | 0) {
          case /* StringEquals */0 :
              return lhs$5 === stringComparator[0];
          case /* StringNotEquals */1 :
              return lhs$5 !== stringComparator[0];
          case /* StringContains */2 :
              return lhs$5.includes(stringComparator[0]);
          case /* StringNotContains */3 :
              return !lhs$5.includes(stringComparator[0]);
          
        }
    
  }
}

function isValid(param) {
  if (typeof param === "number") {
    return true;
  } else {
    switch (param.tag | 0) {
      case /* BranchLogic */0 :
          return compare(getAnswer(param[0]), param[1]);
      case /* MultipleAnswerLogic */1 :
          var lhs = getAnswerList(param[0]);
          var listLogicComparator = param[1];
          if (listLogicComparator.tag) {
            return !Belt_List.has(lhs, listLogicComparator[0], (function (item, search) {
                          return item === search;
                        }));
          } else {
            return Belt_List.has(lhs, listLogicComparator[0], (function (item, search) {
                          return item === search;
                        }));
          }
      case /* RatingGroupLogic */2 :
          var itemId = param[1];
          var match = Question$ReactHooksTemplate.questionType(param[0]);
          if (match.tag === /* RatingGroup */6) {
            var match$1 = RatingGroup$ReactHooksTemplate.answer(match[0]);
            if (match$1 !== undefined) {
              var answer = List.find_opt((function (param) {
                      return param[0] === itemId;
                    }), match$1);
              if (answer !== undefined) {
                return compare(String(answer[1]), param[2]);
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
      
    }
  }
}

exports.isValid = isValid;
/* Nps-ReactHooksTemplate Not a pure module */
