// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function assigner(t) {
  return t[/* assigner */0];
}

function assignee(t) {
  return t[/* assignee */1];
}

function fromJson(json) {
  return /* record */[
          /* assigner */Json_decode.field("user", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json),
          /* assignee */Json_decode.field("assignee", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json)
        ];
}

var activityType = "assign";

exports.activityType = activityType;
exports.assigner = assigner;
exports.assignee = assignee;
exports.fromJson = fromJson;
/* No side effect */
