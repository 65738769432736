// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* label */OpinionScaleLabel$ReactHooksTemplate.make(Json_decode.field("left_label", Json_decode.string, json), Json_decode.field("center_label", Json_decode.string, json), Json_decode.field("right_label", Json_decode.string, json))
        ];
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* description */"",
          /* label */OpinionScaleLabel$ReactHooksTemplate.empty(/* () */0),
          /* representation : Plain */0,
          /* autoAdvance */false,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function answered(t) {
  return t[/* answer */8] !== undefined;
}

function make(text, description, representation, autoAdvance, label, uuid) {
  return /* record */[
          /* id */undefined,
          /* uuid */uuid,
          /* text */text,
          /* description */description,
          /* label */label,
          /* representation */representation,
          /* autoAdvance */autoAdvance,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* description */t[/* description */3],
          /* label */t[/* label */4],
          /* representation */t[/* representation */5],
          /* autoAdvance */t[/* autoAdvance */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */description,
          /* label */t[/* label */4],
          /* representation */t[/* representation */5],
          /* autoAdvance */t[/* autoAdvance */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setLabel(t, label) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* label */label,
          /* representation */t[/* representation */5],
          /* autoAdvance */t[/* autoAdvance */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setRepresentation(t, representation) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* label */t[/* label */4],
          /* representation */representation,
          /* autoAdvance */t[/* autoAdvance */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setAutoAdvance(autoAdvance, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* label */t[/* label */4],
          /* representation */t[/* representation */5],
          /* autoAdvance */autoAdvance,
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setAnswer(t, scale) {
  var match = t[/* answer */8];
  var tmp;
  if (match !== undefined) {
    var match$1 = match === scale;
    tmp = match$1 ? undefined : scale;
  } else {
    tmp = scale;
  }
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* label */t[/* label */4],
          /* representation */t[/* representation */5],
          /* autoAdvance */t[/* autoAdvance */6],
          /* translations */t[/* translations */7],
          /* answer */tmp
        ];
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function text(t) {
  return t[/* text */2];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function description(t) {
  return t[/* description */3];
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */3];
  }
}

function label(t) {
  return t[/* label */4];
}

function getLabelTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* label */2];
  } else {
    return t[/* label */4];
  }
}

function representation(t) {
  return t[/* representation */5];
}

function autoAdvance(t) {
  return t[/* autoAdvance */6];
}

function answer(t) {
  return t[/* answer */8];
}

function rating(t) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    return match;
  }
  
}

function answerToString(t) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    return String(match);
  } else {
    return "";
  }
}

function $eq(t, x) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    return match === x;
  } else {
    return false;
  }
}

function $great$eq(t, x) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    return match >= x;
  } else {
    return false;
  }
}

function $less$eq(t, x) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    return match <= x;
  } else {
    return false;
  }
}

var isAnswer = $eq;

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */3]
      ],
      /* :: */[
        /* tuple */[
          "left_label",
          OpinionScaleLabel$ReactHooksTemplate.left(t[/* label */4])
        ],
        /* :: */[
          /* tuple */[
            "center_label",
            OpinionScaleLabel$ReactHooksTemplate.center(t[/* label */4])
          ],
          /* :: */[
            /* tuple */[
              "right_label",
              OpinionScaleLabel$ReactHooksTemplate.right(t[/* label */4])
            ],
            /* [] */0
          ]
        ]
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  Belt_Option.getWithDefault(t[/* answer */8], -1)
                ],
                encodeArray
              ] : encodeArray);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* label */OpinionScaleLabel$ReactHooksTemplate.make(Json_decode.field("category", (function (param) {
                      return Json_decode.field("left_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("center_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("right_label", Json_decode.string, param);
                    }), json)),
          /* representation : Plain */0,
          /* autoAdvance */false,
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "OpinionScale";

exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setLabel = setLabel;
exports.setRepresentation = setRepresentation;
exports.setAutoAdvance = setAutoAdvance;
exports.setAnswer = setAnswer;
exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.label = label;
exports.getLabelTranslation = getLabelTranslation;
exports.representation = representation;
exports.autoAdvance = autoAdvance;
exports.answer = answer;
exports.rating = rating;
exports.answered = answered;
exports.$eq = $eq;
exports.$great$eq = $great$eq;
exports.$less$eq = $less$eq;
exports.isAnswer = isAnswer;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.id = id;
exports.uuid = uuid;
exports.answerToString = answerToString;
/* RandomId-ReactHooksTemplate Not a pure module */
