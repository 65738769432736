// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var CheckboxStatus$ReactHooksTemplate = require("./checkboxStatus.bs.js");
var PermissionTree$ReactHooksTemplate = require("./permissionTree.bs.js");

function fetchPermissions(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/permissions", PermissionTree$ReactHooksTemplate.decode);
}

function getInputValue(param) {
  return param[0];
}

function getValidationError(param) {
  switch (param.tag | 0) {
    case /* Invalid */1 :
        return param[1];
    case /* Valid */0 :
    case /* NotValidated */2 :
        return /* [] */0;
    
  }
}

var emptyState_000 = /* name : NotValidated */Block.__(2, [Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0)]);

var emptyState_001 = /* description : NotValidated */Block.__(2, [Curry._1(Domain$ReactHooksTemplate.$$String.Description.empty, /* () */0)]);

var emptyState_002 = /* permissions : NotValidated */Block.__(2, [/* [] */0]);

var emptyState = /* record */[
  emptyState_000,
  emptyState_001,
  emptyState_002
];

function displayErrors(field) {
  switch (field.tag | 0) {
    case /* Invalid */1 :
        return React.createElement("div", undefined, $$Array.of_list(Belt_List.mapWithIndex(field[1], (function (key, error) {
                              return React.createElement("p", {
                                          key: String(key),
                                          className: "text-red-600 font-medium"
                                        }, error);
                            }))));
    case /* Valid */0 :
    case /* NotValidated */2 :
        return React.createElement(React.Fragment, undefined);
    
  }
}

function RoleCreate(Props) {
  var match = React.useState((function () {
          return emptyState;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/permissions", PermissionTree$ReactHooksTemplate.decode).then((function (permissions) {
                  return Promise.resolve(Curry._1(setState, (function (state) {
                                    return /* record */[
                                            /* name */state[/* name */0],
                                            /* description */state[/* description */1],
                                            /* permissions : NotValidated */Block.__(2, [permissions])
                                          ];
                                  })));
                }));
          return ;
        }), ([]));
  var toggleCheckboxState = function (name) {
    return Curry._1(setState, (function (state) {
                  return /* record */[
                          /* name */state[/* name */0],
                          /* description */state[/* description */1],
                          /* permissions : NotValidated */Block.__(2, [Belt_List.map(state[/* permissions */2][0], (function (param) {
                                      return PermissionTree$ReactHooksTemplate.toggle(name, param);
                                    }))])
                        ];
                }));
  };
  var capabilityCheckBoxItem = function (capability, status) {
    return React.createElement("div", {
                key: capability,
                className: "pl-5"
              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                    checked: CheckboxStatus$ReactHooksTemplate.toBool(status),
                    label: capability,
                    handleChange: (function (param) {
                        return toggleCheckboxState(capability);
                      })
                  }));
  };
  var groupCheckboxTree = function (group, status, children) {
    return React.createElement("div", {
                key: group
              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                    checked: CheckboxStatus$ReactHooksTemplate.toBool(status),
                    label: group,
                    handleChange: (function (param) {
                        return toggleCheckboxState(group);
                      }),
                    indeterminate: CheckboxStatus$ReactHooksTemplate.isIndeterminate(status)
                  }), React.createElement("div", {
                    className: "pl-5"
                  }, Belt_List.toArray(children)));
  };
  var permissionCheckboxTree = function (permissionTree) {
    return PermissionTree$ReactHooksTemplate.postOrder(capabilityCheckBoxItem, groupCheckboxTree, permissionTree);
  };
  var handleNameChange = function ($$event) {
    var value = Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Utils$ReactHooksTemplate.formTargetValue($$event).toLowerCase());
    return Curry._1(setState, (function (state) {
                  var match = Curry._1(Domain$ReactHooksTemplate.$$String.Name.isEmpty, value);
                  return /* record */[
                          /* name */match ? /* Invalid */Block.__(1, [
                                value,
                                /* :: */[
                                  "can't be empty",
                                  /* [] */0
                                ]
                              ]) : /* Valid */Block.__(0, [value]),
                          /* description */state[/* description */1],
                          /* permissions */state[/* permissions */2]
                        ];
                }));
  };
  var handleDescriptionChange = function ($$event) {
    var value = Curry._1(Domain$ReactHooksTemplate.$$String.Description.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    return Curry._1(setState, (function (state) {
                  return /* record */[
                          /* name */state[/* name */0],
                          /* description : NotValidated */Block.__(2, [value]),
                          /* permissions */state[/* permissions */2]
                        ];
                }));
  };
  var encodePermission = function (xs) {
    return Json_encode.object_(/* :: */[
                /* tuple */[
                  "role",
                  Json_encode.object_(/* :: */[
                        /* tuple */[
                          "name",
                          Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, state[/* name */0][0])
                        ],
                        /* :: */[
                          /* tuple */[
                            "description",
                            Curry._1(Domain$ReactHooksTemplate.$$String.Description.show, state[/* description */1][0])
                          ],
                          /* :: */[
                            /* tuple */[
                              "permissions",
                              Json_encode.object_(/* :: */[
                                    /* tuple */[
                                      "add",
                                      Json_encode.list((function (prim) {
                                              return prim;
                                            }), xs)
                                    ],
                                    /* [] */0
                                  ])
                            ],
                            /* [] */0
                          ]
                        ]
                      ])
                ],
                /* [] */0
              ]);
  };
  var decodeApiResponseStatus = function (json) {
    return Json_decode.field("status", Json_decode.string, json).toLowerCase();
  };
  var nameDecoder = function (json) {
    return Json_decode.field("name", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json);
  };
  var descriptionDecoder = function (json) {
    return Json_decode.field("description", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json);
  };
  var permissionDecoder = function (json) {
    return Json_decode.field("permissions", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json);
  };
  var fieldErrors = function (fieldDecoder, json) {
    return Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("errors", fieldDecoder, param);
                    }), json), /* [] */0);
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/roles", encodePermission(List.fold_left(PermissionTree$ReactHooksTemplate.enabledCapabilities, /* [] */0, state[/* permissions */2][0]))).then((function (json) {
            if (decodeApiResponseStatus(json) === "success") {
              ReasonReactRouter.push("/roles/all");
            } else {
              Curry._1(setState, (function (param) {
                      var xs = fieldErrors(nameDecoder, json);
                      var xs$1 = fieldErrors(descriptionDecoder, json);
                      var xs$2 = fieldErrors(permissionDecoder, json);
                      return /* record */[
                              /* name */xs ? /* Invalid */Block.__(1, [
                                    state[/* name */0][0],
                                    xs
                                  ]) : /* Valid */Block.__(0, [state[/* name */0][0]]),
                              /* description */xs$1 ? /* Invalid */Block.__(1, [
                                    state[/* description */1][0],
                                    xs$1
                                  ]) : /* Valid */Block.__(0, [state[/* description */1][0]]),
                              /* permissions */xs$2 ? /* Invalid */Block.__(1, [
                                    state[/* permissions */2][0],
                                    xs$2
                                  ]) : /* Valid */Block.__(0, [state[/* permissions */2][0]])
                            ];
                    }));
            }
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var permissionTree = Belt_List.toArray(Belt_List.map(state[/* permissions */2][0], permissionCheckboxTree));
  var match$2 = Curry._1(match$1[0], /* AddRole */10);
  return React.createElement("div", undefined, match$2 ? React.createElement("div", {
                    className: "p-30-px"
                  }, React.createElement("h1", {
                        className: "text-32-px mb-30-px"
                      }, "Add New Role"), React.createElement("form", {
                        className: "p-4",
                        onSubmit: handleSubmit
                      }, React.createElement("div", {
                            className: "mb-30-px"
                          }, React.createElement("label", {
                                className: "block mb-10-px"
                              }, React.createElement("p", {
                                    className: "mb-10-px font-semibold"
                                  }, "Name"), React.createElement("input", {
                                    className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                                    type: "text",
                                    value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, state[/* name */0][0]),
                                    onChange: handleNameChange
                                  })), displayErrors(state[/* name */0])), React.createElement("div", {
                            className: "mb-30-px"
                          }, React.createElement("label", {
                                className: "block mb-10-px"
                              }, React.createElement("p", {
                                    className: "mb-10-px font-semibold"
                                  }, "Description"), React.createElement("input", {
                                    className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                                    type: "text",
                                    value: Curry._1(Domain$ReactHooksTemplate.$$String.Description.show, state[/* description */1][0]),
                                    onChange: handleDescriptionChange
                                  }))), React.createElement("div", {
                            className: "mb-30-px"
                          }, React.createElement("label", {
                                className: "block mb-10-px"
                              }, React.createElement("p", {
                                    className: "mb-10-px font-semibold"
                                  }, "Permissions: "), displayErrors(state[/* permissions */2]), React.createElement("div", {
                                    className: "ml-20-px"
                                  }, permissionTree))), React.createElement("div", undefined, React.createElement("input", {
                                className: "text-white bg-primary hover:bg-teal-700 py-6-px px-20-px rounded shadow",
                                type: "submit",
                                value: "Add Role"
                              })))) : React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        img: Image$ReactHooksTemplate.unAuthorized,
                        text: "401: Unauthorized!!",
                        loading: false
                      })));
}

var make = RoleCreate;

exports.fetchPermissions = fetchPermissions;
exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
exports.emptyState = emptyState;
exports.displayErrors = displayErrors;
exports.make = make;
/* emptyState Not a pure module */
