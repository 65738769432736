// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var MultipleSelectDefaultForm$ReactHooksTemplate = require("./MultipleSelectDefaultForm.bs.js");

function MultipleSelectForm(Props) {
  var multipleSelect = Props.multipleSelect;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var matchChoicesWithAnswer = function (choice) {
    var match = MultipleSelect$ReactHooksTemplate.getAnswer(multipleSelect);
    if (match !== undefined) {
      return Belt_List.has(match, choice, (function (xs, ys) {
                    return Caml_obj.caml_equal(MultipleSelect$ReactHooksTemplate.choiceId(xs), MultipleSelect$ReactHooksTemplate.choiceId(ys));
                  }));
    } else {
      return false;
    }
  };
  var match = MultipleSelect$ReactHooksTemplate.representation(multipleSelect);
  var backGroundCss = match >= 2 ? "bg-black bg-opacity-50" : "bg-tropicalbreeze";
  var match$1 = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match$1 !== undefined) {
    switch (match$1) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(MultipleSelectDefaultForm$ReactHooksTemplate.make, {
                    multipleSelect: multipleSelect,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        return React.createElement("div", {
                    className: "flex flex-col"
                  }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                        text: MultipleSelect$ReactHooksTemplate.getTextTranslation(multipleSelect, SurveyLanguage$ReactHooksTemplate.name(language)),
                        description: MultipleSelect$ReactHooksTemplate.getDescriptionTranslation(multipleSelect, SurveyLanguage$ReactHooksTemplate.name(language)),
                        mandatory: mandatory,
                        surveyTheme: surveyTheme
                      }), React.createElement("div", {
                        className: "flex flex-col"
                      }, React.createElement("label", {
                            className: "block mb-10-px"
                          }, $$Array.of_list(Belt_List.map(MultipleSelect$ReactHooksTemplate.choices(multipleSelect), (function (choice) {
                                      var match = matchChoicesWithAnswer(choice) && MultipleSelect$ReactHooksTemplate.getAllowNonSpecifiedText(choice);
                                      return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                      key: RandomId$ReactHooksTemplate.toString(MultipleSelect$ReactHooksTemplate.choiceId(choice)),
                                                      className: "flex pb-2"
                                                    }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                          checked: matchChoicesWithAnswer(choice),
                                                          label: MultipleSelect$ReactHooksTemplate.getChoiceTextTranslation(multipleSelect, choice, SurveyLanguage$ReactHooksTemplate.name(language)),
                                                          handleChange: (function (param) {
                                                              return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setAnswer(multipleSelect, choice));
                                                            }),
                                                          className: " p-2 border border-white-300 rounded-full\n                      flex w-3/4 small:w-full mb-2 "
                                                        })), React.createElement("div", undefined, match ? React.createElement("textarea", {
                                                            className: " " + (String(backGroundCss) + " w-full border p-4"),
                                                            placeholder: "Type here",
                                                            rows: 3,
                                                            onChange: (function (param) {
                                                                var choice$1 = choice;
                                                                var $$event = param;
                                                                return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setNotSpecifiedTextForAnswer(multipleSelect, MultipleSelect$ReactHooksTemplate.setNotSpecifiedText(Utils$ReactHooksTemplate.formTargetValue($$event), choice$1)));
                                                              })
                                                          }) : React.createElement(React.Fragment, undefined)));
                                    }))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                          language: language
                        }) : React.createElement(React.Fragment, undefined));
    
  }
}

var make = MultipleSelectForm;

exports.make = make;
/* react Not a pure module */
