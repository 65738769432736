import nepal_cluster from "./nepal_cluster.json";

const clusterSeries = {
  mapData: nepal_cluster,
  name: "Nepal",
  dataLabels: {
    enabled: true,
    color: "#FFFFFF",
    format: "{point.hc-key}: {point.value}",
  },
  data: [
    { "hc-key": "Central", value: 10 },
    { "hc-key": "Cluster 6", value: 40 },
    { "hc-key": "Cluster 9", value: 28 },
    { "hc-key": "Cluster 1", value: 89 },
    { "hc-key": "Cluster 15", value: 56 },
    { "hc-key": "Cluster 3", value: 76 },
    { "hc-key": "Cluster 4", value: 92 },
    { "hc-key": "Cluster 10", value: 39 },
    { "hc-key": "Cluster 12", value: 94 },
    { "hc-key": "Cluster 13", value: 16 },
    { "hc-key": "Cluster 5", value: 31 },
    { "hc-key": "Cluster 14", value: 11 },
    { "hc-key": "Cluster 8", value: 15 },
    { "hc-key": "Cluster 7", value: 92 },
    { "hc-key": "Cluster 2", value: 25 },
    { "hc-key": "Cluster 11", value: 48 },
  ],
};
export { clusterSeries };
