import React from "react";
import _ from "lodash";
import {
  Chart,
  Geom,
  Tooltip,
  Coordinate,
  Legend,
  Axis,
  Interaction,
  G2,
  registerShape,
} from "bizcharts";

import DataSet from "@antv/data-set";

function getTextAttrs(cfg) {
  return _.assign({}, cfg.style, {
    fontSize: cfg.data.size,
    text: cfg.data.text,
    textAlign: "center",
    fontFamily: cfg.data.font,
    fill: cfg.color,
    textBaseline: "Alphabetic",
  });
}
registerShape("point", "cloud", {
  draw(cfg, container) {
    // console.log('cloud cfg', cfg);
    const attrs = getTextAttrs(cfg);
    const textShape = container.addShape("text", {
      attrs: _.assign(attrs, {
        x: cfg.x,
        y: cfg.y,
      }),
    });
    if (cfg.data.rotate) {
      G2.Util.rotate(textShape, (cfg.data.rotate * Math.PI) / 180);
    }
    return textShape;
  },
});

export default class Wordcloud extends React.Component {
  render() {
    const { data, onPointClick } = this.props;
    console.log("hereeree");
    console.log(data);
    console.log(onPointClick);
    const dv = new DataSet.View().source(data);
    const range = dv.range("value");
    const min = 0;
    const max = range[1];

    dv.transform({
      type: "tag-cloud",
      fields: ["x", "value"],
      size: [792, 256],
      font: "Verdana",
      padding: 0,
      timeInterval: 5000, // max execute time
      rotate() {
        let random = ~~(Math.random() * 4) % 4;
        if (random === 2) {
          random = 0;
        }
        return random * 90; // 0, 90, 270
      },
      fontSize(d) {
        if (d.value) {
          return ((d.value - min) / (max - min)) * (40 - 12) + 12;
        }
        return 0;
      },
    });
    const scale = {
      x: {
        nice: false,
      },
      y: {
        nice: false,
      },
    };

    console.log("After transform");
    console.log(dv);
    console.log(dv.rows);

    return React.createElement(
      "div",
      null,
      React.createElement(
        Chart,
        {
          height: 256,
          width: 792,
          data: dv.rows,
          scale: scale,
          padding: 0,
          autoFit: true,
          onPointClick: onPointClick,
        },
        React.createElement(Tooltip, { showTitle: false }),
        React.createElement(Coordinate, { reflect: "y" }),
        React.createElement(Axis, { name: "x", visible: false }),
        React.createElement(Axis, { name: "y", visible: false }),
        React.createElement(Legend, { visible: false }),
        React.createElement(Geom, {
          type: "point",
          position: "x*y",
          color: "category",
          shape: "cloud",
          tooltip: "value",
          state: {
            active: {
              style: {
                fill: "red",
                stroke: "orange",
              },
            },
          },
        }),
        React.createElement(Interaction, { type_: "element-active" })
      )
    );
  }
}
