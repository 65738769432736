// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function make(item) {
  return item;
}

function options(t) {
  return t;
}

function setOption(t, option) {
  return /* :: */[
          option,
          t
        ];
}

function toString(t) {
  return t;
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "options",
                Json_encode.list((function (prim) {
                        return prim;
                      }), t)
              ],
              /* [] */0
            ]);
}

function decode(json) {
  return Json_decode.withDefault(/* [] */0, (function (param) {
                return Json_decode.field("options", (function (param) {
                              return Json_decode.list(Json_decode.string, param);
                            }), param);
              }), json);
}

exports.make = make;
exports.options = options;
exports.setOption = setOption;
exports.toString = toString;
exports.encode = encode;
exports.decode = decode;
/* No side effect */
