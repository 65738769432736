// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var MomentRe = require("bs-moment/src/MomentRe.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* name */"",
          /* creator */undefined,
          /* logo */undefined,
          /* welcome */undefined,
          /* deletedWelcome */undefined,
          /* thankYouList : [] */0,
          /* deletedThankYouList : [] */0,
          /* questions : [] */0,
          /* deletedQuestions : [] */0,
          /* surveyTheme */SurveyTheme$ReactHooksTemplate.empty(/* () */0),
          /* updatedAt */undefined
        ];
}

function id(t) {
  return t[/* id */0];
}

function name(t) {
  return t[/* name */1];
}

function logo(t) {
  return t[/* logo */3];
}

function welcome(t) {
  return t[/* welcome */4];
}

function deletedWelcome(t) {
  return t[/* deletedWelcome */5];
}

function questions(t) {
  return t[/* questions */8];
}

function deletedQuestions(t) {
  return t[/* deletedQuestions */9];
}

function thankYouList(t) {
  return t[/* thankYouList */6];
}

function updatedAt(t) {
  return t[/* updatedAt */11];
}

function deletedThankYouList(t) {
  return t[/* deletedThankYouList */7];
}

function surveyTheme(t) {
  return t[/* surveyTheme */10];
}

function setName(t, name) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */name,
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */t[/* questions */8],
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setWelcome(t, welcome) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */welcome,
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */t[/* questions */8],
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setDeletedWelcome(t, deletedWelcome) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */deletedWelcome,
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */t[/* questions */8],
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setQuestions(t, questions) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */questions,
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setDeletedQuestions(t, deletedQuestions) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */t[/* questions */8],
          /* deletedQuestions */deletedQuestions,
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setThankYouList(t, thankYouList) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */thankYouList,
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */t[/* questions */8],
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setDeletedThankYouList(t, deletedThankYouList) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */t[/* logo */3],
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */deletedThankYouList,
          /* questions */t[/* questions */8],
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function setLogo(t, logo) {
  return /* record */[
          /* id */t[/* id */0],
          /* name */t[/* name */1],
          /* creator */t[/* creator */2],
          /* logo */logo,
          /* welcome */t[/* welcome */4],
          /* deletedWelcome */t[/* deletedWelcome */5],
          /* thankYouList */t[/* thankYouList */6],
          /* deletedThankYouList */t[/* deletedThankYouList */7],
          /* questions */t[/* questions */8],
          /* deletedQuestions */t[/* deletedQuestions */9],
          /* surveyTheme */t[/* surveyTheme */10],
          /* updatedAt */t[/* updatedAt */11]
        ];
}

function creatorDecoder(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* email */Json_decode.field("email", Json_decode.string, json),
          /* firstName */Json_decode.field("first_name", Json_decode.string, json),
          /* lastName */Json_decode.field("last_name", Json_decode.string, json)
        ];
}

function decodeThankYouPosition(json) {
  return /* tuple */[
          Json_decode.field("position", Json_decode.$$int, json),
          ThankYou$ReactHooksTemplate.fromJson(json)
        ];
}

function decodeThankyouList(json) {
  return Belt_List.map(Belt_List.sort(Json_decode.field("thank_yous", (function (param) {
                        return Json_decode.list(decodeThankYouPosition, param);
                      }), json), (function (param, param$1) {
                    return param[0] - param$1[0] | 0;
                  })), (function (param) {
                return param[1];
              }));
}

function convertDateToMoment(date) {
  if (date !== undefined) {
    return Caml_option.some((function (eta) {
                    return MomentRe.moment(undefined, eta);
                  })(date));
  }
  
}

function fromJson(json) {
  var questions = Question$ReactHooksTemplate.fromJson(json);
  return /* record */[
          /* id */Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* creator */Json_decode.field("creator", (function (param) {
                  return Json_decode.optional(creatorDecoder, param);
                }), json),
          /* logo */Json_decode.field("logo", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          /* welcome */Json_decode.optional((function (param) {
                  return Json_decode.field("welcome", Welcome$ReactHooksTemplate.decodeWelcome, param);
                }), json),
          /* deletedWelcome */undefined,
          /* thankYouList */decodeThankyouList(json),
          /* deletedThankYouList : [] */0,
          /* questions */questions,
          /* deletedQuestions : [] */0,
          /* surveyTheme */Json_decode.field("survey_theme", SurveyTheme$ReactHooksTemplate.decode, json),
          /* updatedAt */convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("updated_at", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json))
        ];
}

function toJson(t) {
  var questionAttributes = Belt_List.toArray(Belt_List.concat(Belt_List.mapWithIndex(t[/* questions */8], (function (i, x) {
                  return Question$ReactHooksTemplate.toJson(undefined, i + 1 | 0, undefined, x);
                })), Belt_List.mapWithIndex(t[/* deletedQuestions */9], (function (i, x) {
                  return Question$ReactHooksTemplate.toJson(undefined, i + 1 | 0, true, x);
                }))));
  var thankyouAttributes = Belt_List.toArray(Belt_List.concat(Belt_List.mapWithIndex(t[/* thankYouList */6], (function (i, x) {
                  return ThankYou$ReactHooksTemplate.toJson(undefined, i + 1 | 0, x);
                })), Belt_List.mapWithIndex(t[/* deletedThankYouList */7], (function (i, x) {
                  return ThankYou$ReactHooksTemplate.toJson(true, i + 1 | 0, x);
                }))));
  var match = t[/* deletedWelcome */5];
  var match$1 = t[/* welcome */4];
  var welcomeAttributes;
  if (match$1 !== undefined) {
    welcomeAttributes = Json_encode.nullable((function (eta) {
            return Welcome$ReactHooksTemplate.toJson(undefined, eta);
          }), match$1);
  } else {
    var partial_arg = true;
    welcomeAttributes = Json_encode.nullable((function (param) {
            return Welcome$ReactHooksTemplate.toJson(partial_arg, param);
          }), match);
  }
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t[/* id */0])
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  t[/* name */1]
                ],
                /* :: */[
                  /* tuple */[
                    "questions_attributes",
                    questionAttributes
                  ],
                  /* :: */[
                    /* tuple */[
                      "welcome_attributes",
                      welcomeAttributes
                    ],
                    /* :: */[
                      /* tuple */[
                        "thank_yous_attributes",
                        thankyouAttributes
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

exports.empty = empty;
exports.id = id;
exports.name = name;
exports.logo = logo;
exports.welcome = welcome;
exports.deletedWelcome = deletedWelcome;
exports.thankYouList = thankYouList;
exports.deletedThankYouList = deletedThankYouList;
exports.questions = questions;
exports.updatedAt = updatedAt;
exports.deletedQuestions = deletedQuestions;
exports.surveyTheme = surveyTheme;
exports.setName = setName;
exports.setWelcome = setWelcome;
exports.setDeletedWelcome = setDeletedWelcome;
exports.setQuestions = setQuestions;
exports.setDeletedQuestions = setDeletedQuestions;
exports.setThankYouList = setThankYouList;
exports.setDeletedThankYouList = setDeletedThankYouList;
exports.setLogo = setLogo;
exports.fromJson = fromJson;
exports.decodeThankyouList = decodeThankyouList;
exports.toJson = toJson;
/* MomentRe Not a pure module */
