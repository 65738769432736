// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var SmsMode$ReactHooksTemplate = require("./SmsMode.bs.js");
var EmailMode$ReactHooksTemplate = require("./EmailMode.bs.js");
var AppLinkMode$ReactHooksTemplate = require("./AppLinkMode.bs.js");
var SmsLinkMode$ReactHooksTemplate = require("./SmsLinkMode.bs.js");
var WebsiteLinkMode$ReactHooksTemplate = require("./WebsiteLinkMode.bs.js");
var WhatsappLinkMode$ReactHooksTemplate = require("./WhatsappLinkMode.bs.js");

function fromForm(form) {
  var match = form[/* formMode */1];
  var mode;
  if (typeof match === "number") {
    mode = match === /* AnonymousLink */0 ? /* AnonymousLink */0 : /* Telephonic */1;
  } else {
    switch (match.tag | 0) {
      case /* Email */0 :
          mode = /* Email */Block.__(0, [EmailMode$ReactHooksTemplate.fromForm(match[0])]);
          break;
      case /* Sms */1 :
          mode = /* Sms */Block.__(1, [SmsMode$ReactHooksTemplate.fromForm(match[0])]);
          break;
      case /* SmsLink */2 :
          mode = /* SmsLink */Block.__(2, [SmsLinkMode$ReactHooksTemplate.fromForm(match[0])]);
          break;
      case /* WhatsappLink */3 :
          mode = /* WhatsappLink */Block.__(3, [WhatsappLinkMode$ReactHooksTemplate.fromForm(match[0])]);
          break;
      case /* AppLink */4 :
          mode = /* AppLink */Block.__(4, [AppLinkMode$ReactHooksTemplate.fromForm(match[0])]);
          break;
      case /* WebsiteLink */5 :
          mode = /* WebsiteLink */Block.__(5, [WebsiteLinkMode$ReactHooksTemplate.fromForm(match[0])]);
          break;
      
    }
  }
  return /* record */[
          /* id */form[/* id */0],
          /* mode */mode
        ];
}

function toForm(t) {
  var match = t[/* mode */1];
  var formMode;
  if (typeof match === "number") {
    formMode = match === /* AnonymousLink */0 ? /* AnonymousLink */0 : /* Telephonic */1;
  } else {
    switch (match.tag | 0) {
      case /* Email */0 :
          formMode = /* Email */Block.__(0, [EmailMode$ReactHooksTemplate.toForm(match[0])]);
          break;
      case /* Sms */1 :
          formMode = /* Sms */Block.__(1, [SmsMode$ReactHooksTemplate.toForm(match[0])]);
          break;
      case /* SmsLink */2 :
          formMode = /* SmsLink */Block.__(2, [SmsLinkMode$ReactHooksTemplate.toForm(match[0])]);
          break;
      case /* WhatsappLink */3 :
          formMode = /* WhatsappLink */Block.__(3, [WhatsappLinkMode$ReactHooksTemplate.toForm(match[0])]);
          break;
      case /* AppLink */4 :
          formMode = /* AppLink */Block.__(4, [AppLinkMode$ReactHooksTemplate.toForm(match[0])]);
          break;
      case /* WebsiteLink */5 :
          formMode = /* WebsiteLink */Block.__(5, [WebsiteLinkMode$ReactHooksTemplate.toForm(match[0])]);
          break;
      
    }
  }
  return /* record */[
          /* id */t[/* id */0],
          /* formMode */formMode
        ];
}

function modeType(t) {
  var match = t[/* mode */1];
  if (typeof match === "number") {
    if (match === /* AnonymousLink */0) {
      return "AnonymousLink";
    } else {
      return "Telephonic";
    }
  } else {
    switch (match.tag | 0) {
      case /* Email */0 :
          return EmailMode$ReactHooksTemplate.modeType;
      case /* Sms */1 :
          return SmsMode$ReactHooksTemplate.modeType;
      case /* SmsLink */2 :
          return SmsLinkMode$ReactHooksTemplate.modeType;
      case /* WhatsappLink */3 :
          return WhatsappLinkMode$ReactHooksTemplate.modeType;
      case /* AppLink */4 :
          return AppLinkMode$ReactHooksTemplate.modeType;
      case /* WebsiteLink */5 :
          return WebsiteLinkMode$ReactHooksTemplate.modeType;
      
    }
  }
}

function encoder(t) {
  var match = t[/* mode */1];
  var tmp;
  if (typeof match === "number") {
    tmp = match === /* AnonymousLink */0 ? /* :: */[
        /* tuple */[
          "id",
          Json_encode.nullable((function (prim) {
                  return prim;
                }), t[/* id */0])
        ],
        /* :: */[
          /* tuple */[
            "mode_type",
            "AnonymousLink"
          ],
          /* :: */[
            /* tuple */[
              "mode_attributes",
              Json_encode.object_(/* [] */0)
            ],
            /* [] */0
          ]
        ]
      ] : /* :: */[
        /* tuple */[
          "id",
          Json_encode.nullable((function (prim) {
                  return prim;
                }), t[/* id */0])
        ],
        /* :: */[
          /* tuple */[
            "mode_type",
            "Telephonic"
          ],
          /* :: */[
            /* tuple */[
              "mode_attributes",
              Json_encode.object_(/* [] */0)
            ],
            /* [] */0
          ]
        ]
      ];
  } else {
    switch (match.tag | 0) {
      case /* Email */0 :
          tmp = /* :: */[
            /* tuple */[
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t[/* id */0])
            ],
            /* :: */[
              /* tuple */[
                "mode_type",
                EmailMode$ReactHooksTemplate.modeType
              ],
              /* :: */[
                /* tuple */[
                  "mode_attributes",
                  EmailMode$ReactHooksTemplate.encoder(match[0])
                ],
                /* [] */0
              ]
            ]
          ];
          break;
      case /* Sms */1 :
          tmp = /* :: */[
            /* tuple */[
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t[/* id */0])
            ],
            /* :: */[
              /* tuple */[
                "mode_type",
                "Sms"
              ],
              /* :: */[
                /* tuple */[
                  "mode_attributes",
                  SmsMode$ReactHooksTemplate.encoder(match[0])
                ],
                /* [] */0
              ]
            ]
          ];
          break;
      case /* SmsLink */2 :
          tmp = /* :: */[
            /* tuple */[
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t[/* id */0])
            ],
            /* :: */[
              /* tuple */[
                "mode_type",
                SmsLinkMode$ReactHooksTemplate.modeType
              ],
              /* :: */[
                /* tuple */[
                  "mode_attributes",
                  SmsLinkMode$ReactHooksTemplate.encoder(match[0])
                ],
                /* [] */0
              ]
            ]
          ];
          break;
      case /* WhatsappLink */3 :
          tmp = /* :: */[
            /* tuple */[
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t[/* id */0])
            ],
            /* :: */[
              /* tuple */[
                "mode_type",
                WhatsappLinkMode$ReactHooksTemplate.modeType
              ],
              /* :: */[
                /* tuple */[
                  "mode_attributes",
                  WhatsappLinkMode$ReactHooksTemplate.encoder(match[0])
                ],
                /* [] */0
              ]
            ]
          ];
          break;
      case /* AppLink */4 :
          tmp = /* :: */[
            /* tuple */[
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t[/* id */0])
            ],
            /* :: */[
              /* tuple */[
                "mode_type",
                AppLinkMode$ReactHooksTemplate.modeType
              ],
              /* :: */[
                /* tuple */[
                  "mode_attributes",
                  AppLinkMode$ReactHooksTemplate.encoder(match[0])
                ],
                /* [] */0
              ]
            ]
          ];
          break;
      case /* WebsiteLink */5 :
          tmp = /* :: */[
            /* tuple */[
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t[/* id */0])
            ],
            /* :: */[
              /* tuple */[
                "mode_type",
                WebsiteLinkMode$ReactHooksTemplate.modeType
              ],
              /* :: */[
                /* tuple */[
                  "mode_attributes",
                  WebsiteLinkMode$ReactHooksTemplate.encoder(match[0])
                ],
                /* [] */0
              ]
            ]
          ];
          break;
      
    }
  }
  return Json_encode.object_(tmp);
}

function decoder(json) {
  var modeType = Json_decode.field("mode_type", Json_decode.string, json);
  if (modeType === EmailMode$ReactHooksTemplate.modeType) {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : Email */Block.__(0, [Json_decode.field("mode", EmailMode$ReactHooksTemplate.decoder, json)])
          ];
  } else if (modeType === SmsLinkMode$ReactHooksTemplate.modeType) {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : SmsLink */Block.__(2, [Json_decode.field("mode", SmsLinkMode$ReactHooksTemplate.decoder, json)])
          ];
  } else if (modeType === WhatsappLinkMode$ReactHooksTemplate.modeType) {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : WhatsappLink */Block.__(3, [Json_decode.field("mode", WhatsappLinkMode$ReactHooksTemplate.decoder, json)])
          ];
  } else if (modeType === "AnonymousLink") {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : AnonymousLink */0
          ];
  } else if (modeType === "Sms") {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : Sms */Block.__(1, [Json_decode.field("mode", SmsMode$ReactHooksTemplate.decoder, json)])
          ];
  } else if (modeType === "Telephonic") {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : Telephonic */1
          ];
  } else if (modeType === "WebsiteLink") {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : WebsiteLink */Block.__(5, [Json_decode.field("mode", WebsiteLinkMode$ReactHooksTemplate.decoder, json)])
          ];
  } else {
    return /* record */[
            /* id */Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            /* mode : AppLink */Block.__(4, [Json_decode.field("mode", AppLinkMode$ReactHooksTemplate.decoder, json)])
          ];
  }
}

exports.fromForm = fromForm;
exports.toForm = toForm;
exports.modeType = modeType;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */
