// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Querystringify = require("querystringify");
var SurveyView$ReactHooksTemplate = require("./SurveyView.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function SurveyPreviewWrapper(Props) {
  var survey = Props.survey;
  var client = Props.client;
  var params = Props.params;
  var showPreview = Props.showPreview;
  Querystringify.parse(params);
  return React.createElement(React.Fragment, undefined, React.createElement(SurveyView$ReactHooksTemplate.make, {
                  survey: survey,
                  client: client,
                  transactionStatus: "open",
                  surveyLanguages: /* [] */0,
                  language: SurveyLanguage$ReactHooksTemplate.$$default,
                  customerInfo: CustomerInfo$ReactHooksTemplate.empty(/* () */0),
                  preview: true,
                  token: "",
                  showPreview: showPreview
                }));
}

var make = SurveyPreviewWrapper;

exports.make = make;
/* react Not a pure module */
