// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function make(logo) {
  return /* record */[/* logo */logo];
}

function getLogo(t) {
  return t[/* logo */0];
}

exports.make = make;
exports.getLogo = getLogo;
/* No side effect */
