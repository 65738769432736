// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Moment = require("moment");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Project$ReactHooksTemplate = require("./project.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var SearchForm$ReactHooksTemplate = require("./SearchForm.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var SurveyCreate$ReactHooksTemplate = require("./SurveyCreate.bs.js");

function decodeSurvey(json) {
  var partial_arg = Domain$ReactHooksTemplate.$$String.Email.decode;
  var partial_arg$1 = Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0);
  var partial_arg$2 = function (param) {
    return Json_decode.withDefault(partial_arg$1, partial_arg, param);
  };
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
          /* createdBy */Json_decode.field("creator", (function (param) {
                  return Json_decode.field("email", partial_arg$2, param);
                }), json)
        ];
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* per_page */"1"
];

var searchPlaceholder = "Search Surveys";

function SurveyList(Props) {
  Props.search;
  var selectedProject = Props.selectedProject;
  var match = React.useState((function () {
          return /* GetSurveys */0;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return emptyPageInfo;
        }));
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var match$2 = React.useState((function () {
          return 1;
        }));
  var setCurrentPage = match$2[1];
  var currentPage = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setSurveyCreate = match$3[1];
  var surveyCreate = match$3[0];
  var match$4 = React.useState((function () {
          return ;
        }));
  var setNotification = match$4[1];
  var notification = match$4[0];
  var match$5 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var match$6 = React.useState((function () {
          return ;
        }));
  var setSearchKey = match$6[1];
  var searchKey = match$6[0];
  var projectId = Belt_Option.getExn(Project$ReactHooksTemplate.id(selectedProject));
  var totalPages = function (total_records, per_page) {
    return Caml_int32.div(total_records - 1 | 0, per_page) + 1 | 0;
  };
  var randomBgColor = function (param) {
    return Belt_Array.getExn(Belt_Array.shuffle($$Array.of_list(/* :: */[
                        "bg-toolbox",
                        /* :: */[
                          "bg-arylideyellowlight",
                          /* :: */[
                            "bg-iguanagreen",
                            /* :: */[
                              "bg-vistablue",
                              /* :: */[
                                "bg-iceberg",
                                /* :: */[
                                  "bg-scooter",
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])), 0);
  };
  var fetchSurveys = function (currentPage, searchKey) {
    var url = searchKey !== undefined ? "/api/v1/projects/" + (String(projectId) + ("/surveys?search=" + (searchKey + ("&page=" + String(currentPage))))) : "/api/v1/projects/" + (String(projectId) + ("/surveys?page=" + String(currentPage)));
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then((function (param) {
                  var headers = param[0];
                  var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
                  var per_page = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
                  var surveys = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("surveys", (function (param) {
                                            return Json_decode.list(decodeSurvey, param);
                                          }), json));
                        }));
                  return Promise.resolve(/* tuple */[
                              surveys,
                              /* tuple */[
                                total,
                                per_page
                              ]
                            ]);
                }));
  };
  var handleSearch = function (param) {
    fetchSurveys(currentPage, searchKey).then((function (param) {
            var paginationInfo = param[1];
            var per_page = paginationInfo[1];
            var total = paginationInfo[0];
            Curry._1(setPageInfo, (function (param) {
                    return /* record */[
                            /* total */total,
                            /* per_page */per_page
                          ];
                  }));
            Curry._1(setCurrentPage, (function (param) {
                    return 1;
                  }));
            return param[0].then((function (surveys) {
                          return Promise.resolve(Curry._1(setState, (function (param) {
                                            return /* Ready */[surveys];
                                          })));
                        }));
          }));
    return /* () */0;
  };
  var handlePageChange = function (pageNumber) {
    Curry._1(setCurrentPage, (function (param) {
            return pageNumber;
          }));
    fetchSurveys(pageNumber, searchKey).then((function (param) {
            var paginationInfo = param[1];
            var per_page = paginationInfo[1];
            var total = paginationInfo[0];
            Curry._1(setPageInfo, (function (param) {
                    return /* record */[
                            /* total */total,
                            /* per_page */per_page
                          ];
                  }));
            return param[0].then((function (surveys) {
                          return Promise.resolve(Curry._1(setState, (function (param) {
                                            return /* Ready */[surveys];
                                          })));
                        }));
          }));
    return /* () */0;
  };
  React.useEffect((function () {
          fetchSurveys(1, undefined).then((function (param) {
                  var paginationInfo = param[1];
                  var per_page = paginationInfo[1];
                  var total = paginationInfo[0];
                  Curry._1(setPageInfo, (function (param) {
                          return /* record */[
                                  /* total */total,
                                  /* per_page */per_page
                                ];
                        }));
                  Curry._1(setCurrentPage, (function (param) {
                          return 1;
                        }));
                  Curry._1(setSearchKey, (function (param) {
                          return ;
                        }));
                  return param[0].then((function (surveys) {
                                return Promise.resolve(Curry._1(setState, (function (param) {
                                                  return /* Ready */[surveys];
                                                })));
                              }));
                }));
          return ;
        }), /* array */[projectId]);
  var toggleSurveyCreate = function (param) {
    return Curry._1(setSurveyCreate, (function (param) {
                  return !surveyCreate;
                }));
  };
  if (state) {
    var tmp;
    if (notification !== undefined) {
      var match$7 = notification;
      tmp = React.createElement("div", {
            className: "pt-1"
          }, React.createElement(Notification$ReactHooksTemplate.make, {
                text: match$7[0],
                isError: match$7[1],
                handleClose: (function (param) {
                    return Curry._1(setNotification, (function (param) {
                                  return ;
                                }));
                  })
              }));
    } else {
      tmp = React.createElement(React.Fragment, undefined);
    }
    var match$8 = Curry._1(match$5[0], /* BuildSurvey */3);
    return React.createElement("div", undefined, tmp, React.createElement("div", {
                    className: "flex items-center border-b border-gray-300 px-30-px py-16-px justify-between"
                  }, React.createElement("h2", {
                        className: "text-xl font-semibold"
                      }, Project$ReactHooksTemplate.name(selectedProject))), React.createElement("div", {
                    className: "flex justify-between px-30-px pt-20-px items-center"
                  }, React.createElement("div", {
                        className: "flex items-center"
                      }, React.createElement(SearchForm$ReactHooksTemplate.make, {
                            searchKey: searchKey,
                            setSearchKey: setSearchKey,
                            handleSearch: handleSearch,
                            searchPlaceholder: searchPlaceholder
                          }), React.createElement(Pagination$ReactHooksTemplate.make, {
                            total: totalPages(Caml_format.caml_int_of_string(pageInfo[/* total */0]), Caml_format.caml_int_of_string(pageInfo[/* per_page */1])),
                            currentPage: currentPage,
                            handlePageChange: handlePageChange
                          })), match$8 ? React.createElement("a", {
                          className: "bg-ghostwhite border border-gray-400\n                    shadow-inner flex rounded py-2 px-3 text-xs",
                          id: "add-survey",
                          onClick: toggleSurveyCreate
                        }, React.createElement("img", {
                              className: "h-14-px mr-1",
                              src: Image$ReactHooksTemplate.plusCircle
                            }), "New Survey") : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                    className: "flex px-30-px pb-30-px"
                  }, React.createElement("table", {
                        className: "text-left table-auto w-full border-separate text-sm font-medium"
                      }, React.createElement("thead", {
                            className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "px-4 py-3"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Name", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))), React.createElement("th", {
                                    className: "px-4 py-3"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Created by", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))), React.createElement("th", {
                                    className: "px-4 py-3"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Status", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))), React.createElement("th", {
                                    className: "px-4 py-3"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Created at", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))))), React.createElement("tbody", undefined, $$Array.of_list(Belt_List.map(state[0], (function (survey) {
                                      var id = String(survey[/* id */0]);
                                      return React.createElement("tr", {
                                                  key: String(survey[/* id */0]),
                                                  className: "border-b border-bottom-gray-600"
                                                }, React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          className: "flex items-center",
                                                          href: "/projects/" + (String(projectId) + ("/surveys/edit/" + (String(id) + "")))
                                                        }, React.createElement("div", {
                                                              className: "w-8 h-8 flex items-center text-white uppercase mr-3 rounded-lg " + randomBgColor(/* () */0)
                                                            }, React.createElement("div", {
                                                                  className: "m-auto"
                                                                }, survey[/* name */1][0])), survey[/* name */1])), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          href: "/projects/" + (String(projectId) + ("/surveys/edit/" + (String(id) + "")))
                                                        }, Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, survey[/* createdBy */3]))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("div", {
                                                          className: "text-xs px-3 py-2 rounded-2xl flex gap-1 items-center\n                                  border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                        }, React.createElement("div", {
                                                              className: "h-3 w-3 rounded-full bg-americangreen"
                                                            }), "Active")), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          href: "/projects/" + (String(projectId) + ("/surveys/edit/" + (String(id) + "")))
                                                        }, survey[/* createdAt */2].format("lll"))));
                                    })))))), surveyCreate ? React.createElement("div", {
                      className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                      style: {
                        height: "calc(100vh + 5rem)"
                      }
                    }, React.createElement("div", {
                          className: "bg-white modal-survey z-10 shadow relative border border-gray-300 pointer-events-auto"
                        }, React.createElement("div", {
                              className: "p-8 text-lg font-bold flex justify-between"
                            }, React.createElement("div", undefined, "Create New Survey"), React.createElement("img", {
                                  className: "h-14-px float-right",
                                  src: Image$ReactHooksTemplate.closeIcon,
                                  onClick: toggleSurveyCreate
                                })), React.createElement("div", {
                              className: "flex flex-col justify-between h-full"
                            }, React.createElement(SurveyCreate$ReactHooksTemplate.make, {
                                  toggleSurveyCreate: toggleSurveyCreate,
                                  projectId: projectId
                                })))) : React.createElement(React.Fragment, undefined));
  } else {
    return React.createElement(React.Fragment, undefined, "getting surveys");
  }
}

var make = SurveyList;

exports.decodeSurvey = decodeSurvey;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.emptyPageInfo = emptyPageInfo;
exports.searchPlaceholder = searchPlaceholder;
exports.make = make;
/* react Not a pure module */
