// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var TextFilter$ReactHooksTemplate = require("./textFilter.bs.js");
var TextSelected$ReactHooksTemplate = require("./textSelected.bs.js");

function TextFilterForm(Props) {
  var filter = Props.filter;
  var onChange = Props.onChange;
  var handleOnChange = function ($$event) {
    return Curry._1(onChange, /* FreeText */Block.__(5, [TextFilter$ReactHooksTemplate.setSelected(Caml_option.some(TextSelected$ReactHooksTemplate.make(Utils$ReactHooksTemplate.formTargetValue($$event))), filter)]));
  };
  return React.createElement("label", undefined, React.createElement("input", {
                  className: "border border-gray-300 hover:border-gray-400 focus:border-palatinateblue outline-none p-3 mt-4 text-sm bg-transparent w-4/5 rounded",
                  type: "text",
                  value: TextFilter$ReactHooksTemplate.toString(filter),
                  onChange: handleOnChange
                }));
}

var make = TextFilterForm;

exports.make = make;
/* react Not a pure module */
