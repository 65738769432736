// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var DateFilter$ReactHooksTemplate = require("./dateFilter.bs.js");
var ListFilter$ReactHooksTemplate = require("./listFilter.bs.js");
var TextFilter$ReactHooksTemplate = require("./textFilter.bs.js");
var EmailFilter$ReactHooksTemplate = require("./emailFilter.bs.js");
var MsisdnFilter$ReactHooksTemplate = require("./msisdnFilter.bs.js");
var NumberFilter$ReactHooksTemplate = require("./numberFilter.bs.js");

function id(t) {
  return t[/* id */0];
}

function title(t) {
  return t[/* title */1];
}

function filterType(t) {
  return t[/* filterType */2];
}

function setFilterTypes(filterType, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* title */t[/* title */1],
          /* filterType */filterType
        ];
}

function toString(t) {
  var match = t[/* filterType */2];
  var tmp;
  switch (match.tag | 0) {
    case /* Number */0 :
        tmp = NumberFilter$ReactHooksTemplate.toString(match[0]);
        break;
    case /* Date */1 :
        tmp = DateFilter$ReactHooksTemplate.toString(match[0]);
        break;
    case /* List */2 :
        tmp = ListFilter$ReactHooksTemplate.toString(match[0]);
        break;
    case /* Msisdn */3 :
        tmp = MsisdnFilter$ReactHooksTemplate.toString(match[0]);
        break;
    case /* Email */4 :
        tmp = EmailFilter$ReactHooksTemplate.toString(match[0]);
        break;
    case /* FreeText */5 :
        tmp = TextFilter$ReactHooksTemplate.toString(match[0]);
        break;
    
  }
  return t[/* title */1] + (" : " + tmp);
}

function isSelected(t) {
  var match = t[/* filterType */2];
  switch (match.tag | 0) {
    case /* Number */0 :
        return NumberFilter$ReactHooksTemplate.isSelected(match[0]);
    case /* Date */1 :
        return DateFilter$ReactHooksTemplate.isSelected(match[0]);
    case /* List */2 :
        return ListFilter$ReactHooksTemplate.isSelected(match[0]);
    case /* Msisdn */3 :
        return MsisdnFilter$ReactHooksTemplate.isSelected(match[0]);
    case /* Email */4 :
        return EmailFilter$ReactHooksTemplate.isSelected(match[0]);
    case /* FreeText */5 :
        return TextFilter$ReactHooksTemplate.isSelected(match[0]);
    
  }
}

function resetSelected(t) {
  var match = t[/* filterType */2];
  var filterType;
  switch (match.tag | 0) {
    case /* Number */0 :
        filterType = /* Number */Block.__(0, [NumberFilter$ReactHooksTemplate.resetSelected(match[0])]);
        break;
    case /* Date */1 :
        filterType = /* Date */Block.__(1, [DateFilter$ReactHooksTemplate.resetSelected(match[0])]);
        break;
    case /* List */2 :
        filterType = /* List */Block.__(2, [ListFilter$ReactHooksTemplate.resetSelected(match[0])]);
        break;
    case /* Msisdn */3 :
        filterType = /* Msisdn */Block.__(3, [MsisdnFilter$ReactHooksTemplate.resetSelected(match[0])]);
        break;
    case /* Email */4 :
        filterType = /* Email */Block.__(4, [EmailFilter$ReactHooksTemplate.resetSelected(match[0])]);
        break;
    case /* FreeText */5 :
        filterType = /* FreeText */Block.__(5, [TextFilter$ReactHooksTemplate.resetSelected(match[0])]);
        break;
    
  }
  return setFilterTypes(filterType, t);
}

function decodeFilterType(json) {
  var match = Json_decode.field("category_type", Json_decode.string, json);
  switch (match) {
    case "date" :
        return /* Date */Block.__(1, [Json_decode.field("params", DateFilter$ReactHooksTemplate.fromJson, json)]);
    case "email" :
        return /* Email */Block.__(4, [Json_decode.field("params", EmailFilter$ReactHooksTemplate.fromJson, json)]);
    case "list" :
        return /* List */Block.__(2, [Json_decode.field("params", ListFilter$ReactHooksTemplate.fromJson, json)]);
    case "msisdn" :
        return /* Msisdn */Block.__(3, [Json_decode.field("params", MsisdnFilter$ReactHooksTemplate.fromJson, json)]);
    default:
      return /* FreeText */Block.__(5, [Json_decode.field("params", TextFilter$ReactHooksTemplate.fromJson, json)]);
  }
}

function decodeFilterItem(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* title */Json_decode.field("title", Json_decode.string, json),
          /* filterType */Json_decode.field("filter", decodeFilterType, json)
        ];
}

function fromJson(json) {
  return Json_decode.field("filters", (function (param) {
                return Json_decode.list(decodeFilterItem, param);
              }), json);
}

function encodeFilter(t) {
  var match = t[/* filterType */2];
  switch (match.tag | 0) {
    case /* Number */0 :
        return NumberFilter$ReactHooksTemplate.encode(match[0]);
    case /* Date */1 :
        return DateFilter$ReactHooksTemplate.encode(match[0]);
    case /* List */2 :
        return ListFilter$ReactHooksTemplate.encode(match[0]);
    case /* Msisdn */3 :
        return MsisdnFilter$ReactHooksTemplate.encode(match[0]);
    case /* Email */4 :
        return EmailFilter$ReactHooksTemplate.encode(match[0]);
    case /* FreeText */5 :
        return TextFilter$ReactHooksTemplate.encode(match[0]);
    
  }
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                t[/* id */0]
              ],
              /* :: */[
                /* tuple */[
                  "title",
                  t[/* title */1]
                ],
                /* :: */[
                  /* tuple */[
                    "filter",
                    encodeFilter(t)
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

exports.fromJson = fromJson;
exports.title = title;
exports.filterType = filterType;
exports.id = id;
exports.setFilterTypes = setFilterTypes;
exports.toString = toString;
exports.isSelected = isSelected;
exports.resetSelected = resetSelected;
exports.encode = encode;
exports.decodeFilterItem = decodeFilterItem;
/* DateFilter-ReactHooksTemplate Not a pure module */
