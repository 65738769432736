// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function setRatingColor(high, medium, low) {
  return /* record */[
          /* low */low,
          /* medium */medium,
          /* high */high
        ];
}

function getRatingHighColor(t) {
  return t[/* high */2];
}

function getRatingMediumColor(t) {
  return t[/* medium */1];
}

function getRatingLowColor(t) {
  return t[/* low */0];
}

var ratingColor = /* record */[
  /* low */"#F5817D",
  /* medium */"#F9C940",
  /* high */"#52B257"
];

function getRatingColor(t) {
  switch (t) {
    case /* One */1 :
    case /* Two */2 :
        return "#F5817D";
    case /* Three */3 :
        return "#F9C940";
    case /* Void */0 :
    case /* Four */4 :
    case /* Five */5 :
        return "#52B257";
    
  }
}

var getRatingInt = StarRating$ReactHooksTemplate.ratingToInt;

function getRating(t) {
  return t[/* rating */0];
}

var ratingAndStyleGroup_000 = /* record */[
  /* rating : One */1,
  /* color */"#F5817D"
];

var ratingAndStyleGroup_001 = /* :: */[
  /* record */[
    /* rating : Two */2,
    /* color */"#F5817D"
  ],
  /* :: */[
    /* record */[
      /* rating : Three */3,
      /* color */"#F9C940"
    ],
    /* :: */[
      /* record */[
        /* rating : Four */4,
        /* color */"#52B257"
      ],
      /* :: */[
        /* record */[
          /* rating : Five */5,
          /* color */"#52B257"
        ],
        /* [] */0
      ]
    ]
  ]
];

var ratingAndStyleGroup = /* :: */[
  ratingAndStyleGroup_000,
  ratingAndStyleGroup_001
];

function StarRatingDefaultForm(Props) {
  var starRating = Props.starRating;
  Props.surveyTheme;
  var mandatory = Props.mandatory;
  var language = Props.language;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 border border-gray-400 rounded-lg"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, StarRating$ReactHooksTemplate.getTextTranslation(starRating, SurveyLanguage$ReactHooksTemplate.name(language))), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, StarRating$ReactHooksTemplate.getDescriptionTranslation(starRating, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex flex-col pb-6"
                }, React.createElement("div", {
                      className: "flex"
                    }, $$Array.of_list(Belt_List.mapWithIndex(ratingAndStyleGroup, (function (index, rating) {
                                var index$1 = index;
                                var rating$1 = rating[/* rating */0];
                                var match = StarRating$ReactHooksTemplate.ratingToInt(rating$1) <= StarRating$ReactHooksTemplate.ratingToInt(StarRating$ReactHooksTemplate.rating(starRating));
                                var fill = match ? getRatingColor(StarRating$ReactHooksTemplate.rating(starRating)) : getRatingColor(rating$1);
                                var tmp;
                                switch (rating$1) {
                                  case /* Void */0 :
                                      tmp = React.createElement(React.Fragment, undefined);
                                      break;
                                  case /* One */1 :
                                      tmp = React.createElement("polygon", {
                                            id: "Fill-1",
                                            fill: fill,
                                            points: "7.728 7 7 7.502 7 8.051 7.691 7.575 7.726 7.575 7.726 9.819 8.317 9.819 8.317 7"
                                          });
                                      break;
                                  case /* Two */2 :
                                      tmp = React.createElement("path", {
                                            id: "2",
                                            d: "M7,7.92578125 L7,7.91601562 C7,7.37109375 7.41796875,7 8.02734375,7 C8.61523438,7 9.02539062,7.33789062 9.02539062,7.82226562 C9.02539062,8.10102983 8.88818763,8.34428267 8.4963887,8.73104838 L7.83203125,9.36523438 L7.83203125,9.40039062 L9.06640625,9.40039062 L9.06640625,9.890625 L7.03320312,9.890625 L7.03320312,9.47851562 L7.953125,8.578125 C8.34960938,8.19140625 8.44921875,8.04492188 8.44921875,7.86132812 C8.44921875,7.63476562 8.265625,7.47460938 8.0078125,7.47460938 C7.76475694,7.47460938 7.58960262,7.61658468 7.55368013,7.83057645 L7.546875,7.92578125 L7,7.92578125 Z",
                                            fill: fill,
                                            fillRule: "nonzero"
                                          });
                                      break;
                                  case /* Three */3 :
                                      tmp = React.createElement("path", {
                                            id: "3",
                                            d: "M7.7265625,8.66796875 L7.7265625,8.22851562 L8.0703125,8.22851562 C8.33789062,8.22851562 8.51953125,8.07226562 8.51953125,7.84179688 C8.51953125,7.61523438 8.34375,7.47070312 8.06835938,7.47070312 C7.79492188,7.47070312 7.61328125,7.625 7.59765625,7.86914062 L7.05273438,7.86914062 C7.07226562,7.3359375 7.46875,7 8.08398438,7 C8.66210938,7 9.078125,7.31640625 9.078125,7.75976562 C9.078125,8.0859375 8.87304688,8.33984375 8.55859375,8.40429688 L8.55859375,8.43945312 C8.9453125,8.48242188 9.1875,8.74023438 9.1875,9.11132812 C9.1875,9.60546875 8.72265625,9.96289062 8.08007812,9.96289062 C7.45117188,9.96289062 7.02539062,9.61523438 7,9.08398438 L7.56445312,9.08398438 C7.58203125,9.32226562 7.78320312,9.47265625 8.0859375,9.47265625 C8.38085938,9.47265625 8.5859375,9.30664062 8.5859375,9.06640625 C8.5859375,8.8203125 8.39257812,8.66796875 8.07617188,8.66796875 L7.7265625,8.66796875 Z",
                                            fill: fill,
                                            fillRule: "nonzero"
                                          });
                                      break;
                                  case /* Four */4 :
                                      tmp = React.createElement("path", {
                                            id: "4",
                                            d: "M8.37890625,9.81835938 L8.94140625,9.81835938 L8.94140625,9.32617188 L9.31054688,9.32617188 L9.31054688,8.8359375 L8.94140625,8.8359375 L8.94140625,7 L8.11523438,7 L7.76821899,7.52862549 C7.44458008,8.02868652 7.19042969,8.44580078 7,8.8046875 L7,9.32617188 L8.37890625,9.32617188 L8.37890625,9.81835938 Z M7.52929688,8.82226562 C7.8515625,8.23828125 8.10351562,7.84375 8.36132812,7.45898438 L8.39257812,7.45898438 L8.39257812,8.85351562 L7.52929688,8.85351562 L7.52929688,8.82226562 Z",
                                            fill: fill,
                                            fillRule: "nonzero"
                                          });
                                      break;
                                  case /* Five */5 :
                                      tmp = React.createElement("path", {
                                            id: "Fill-1",
                                            d: "M7,9.018 L7.545,9.018 C7.578,9.252 7.785,9.409 8.053,9.409 C8.355,9.409 8.564,9.205 8.564,8.899 C8.564,8.588 8.354,8.381 8.055,8.381 C7.836,8.381 7.67,8.477 7.572,8.635 L7.045,8.635 L7.186,7 L8.957,7 L8.957,7.491 L7.643,7.491 L7.58,8.215 L7.615,8.215 C7.734,8.043 7.934,7.94 8.213,7.94 C8.748,7.94 9.127,8.332 9.127,8.885 C9.127,9.483 8.695,9.891 8.049,9.891 C7.436,9.891 7.018,9.522 7,9.018",
                                            fill: fill
                                          });
                                      break;
                                  
                                }
                                var match$1 = StarRating$ReactHooksTemplate.ratingToInt(rating$1) <= StarRating$ReactHooksTemplate.ratingToInt(StarRating$ReactHooksTemplate.rating(starRating));
                                return React.createElement("a", {
                                            key: String(index$1),
                                            className: "mr-8 small:mr-6",
                                            href: "#",
                                            onClick: (function (param) {
                                                var rating$2 = rating$1;
                                                var $$event = param;
                                                $$event.preventDefault();
                                                return Curry._1(onChange, StarRating$ReactHooksTemplate.setAnswer(starRating, rating$2));
                                              })
                                          }, React.createElement("svg", {
                                                className: "h-72-px w-72-px small:h-8 small:w-8",
                                                version: "1.1",
                                                viewBox: "0 0 16 16",
                                                xmlns: "http://www.w3.org/2000/svg"
                                              }, tmp, React.createElement("g", {
                                                    id: "Star_1",
                                                    fill: "none",
                                                    fillRule: "evenodd",
                                                    stroke: "none",
                                                    strokeWidth: "1"
                                                  }, match$1 ? React.createElement("polygon", {
                                                          id: "Path",
                                                          fill: fill,
                                                          points: "8.00003333 1 5.8374897 5.60788861 1 6.34709213 4.50095 9.93484254 3.67401273 15.0000667 8.00003333 12.6088553 12.3260539 15.0000667 11.50005 9.93484254 15.001 6.34709213 10.1635103 5.60788861"
                                                        }) : React.createElement("path", {
                                                          id: "Fill-1",
                                                          d: "M8,1 L5.836,5.609 L1,6.348 L4.5,9.935 L3.674,15 L8,12.609 L12.326,15 L11.5,9.935 L15,6.348 L10.163,5.609 L8,1 Z M5.486,10.096 L5.568,9.598 L5.215,9.237 L3.074,7.043 L5.988,6.598 L6.515,6.517 L6.742,6.034 L8,3.354 L9.257,6.034 L9.484,6.517 L10.012,6.598 L12.925,7.043 L10.784,9.237 L10.432,9.598 L10.513,10.096 L11.008,13.129 L8.483,11.733 L8,11.466 L7.516,11.733 L4.992,13.129 L5.486,10.096 Z",
                                                          fill: fill
                                                        }))));
                              }))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = StarRatingDefaultForm;

exports.setRatingColor = setRatingColor;
exports.getRatingHighColor = getRatingHighColor;
exports.getRatingMediumColor = getRatingMediumColor;
exports.getRatingLowColor = getRatingLowColor;
exports.ratingColor = ratingColor;
exports.getRatingColor = getRatingColor;
exports.getRatingInt = getRatingInt;
exports.getRating = getRating;
exports.ratingAndStyleGroup = ratingAndStyleGroup;
exports.make = make;
/* react Not a pure module */
