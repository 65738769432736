// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ListSelected$ReactHooksTemplate = require("./listSelected.bs.js");

function getList(t) {
  return t[/* options */0];
}

function fromJson(json) {
  return /* record */[
          /* options */Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("options", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          /* selected */Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("selected", ListSelected$ReactHooksTemplate.decode, param);
                }), json)
        ];
}

function selected(t) {
  return t[/* selected */1];
}

function isSelected(t) {
  return Belt_List.length(t[/* selected */1]) > 0;
}

function setSelected(selected, t) {
  var match = Belt_Option.isNone(Belt_List.head(Belt_List.keep(t[/* selected */1], (function (xs) {
                  return xs === selected;
                }))));
  var selectedList = match ? /* :: */[
      selected,
      t[/* selected */1]
    ] : Belt_List.keep(t[/* selected */1], (function (xs) {
            return xs !== selected;
          }));
  return /* record */[
          /* options */t[/* options */0],
          /* selected */selectedList
        ];
}

function resetSelected(t) {
  return /* record */[
          /* options */t[/* options */0],
          /* selected : [] */0
        ];
}

function toString(t) {
  console.log(t[/* selected */1]);
  return $$Array.of_list(ListSelected$ReactHooksTemplate.toString(t[/* selected */1])).join(", ");
}

function encodeSelected(selected) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "options",
                Json_encode.list((function (prim) {
                        return prim;
                      }), selected)
              ],
              /* [] */0
            ]);
}

function encodeParams(t) {
  if (List.length(t[/* selected */1]) > 0) {
    return /* :: */[
            /* tuple */[
              "options",
              Json_encode.list((function (prim) {
                      return prim;
                    }), t[/* options */0])
            ],
            /* :: */[
              /* tuple */[
                "selected",
                encodeSelected(t[/* selected */1])
              ],
              /* [] */0
            ]
          ];
  } else {
    return /* :: */[
            /* tuple */[
              "options",
              Json_encode.list((function (prim) {
                      return prim;
                    }), t[/* options */0])
            ],
            /* [] */0
          ];
  }
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "category_type",
                "list"
              ],
              /* :: */[
                /* tuple */[
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                /* [] */0
              ]
            ]);
}

exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.getList = getList;
exports.selected = selected;
exports.isSelected = isSelected;
exports.toString = toString;
exports.fromJson = fromJson;
exports.encode = encode;
/* No side effect */
