// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function NpsPreview$NpsItem(Props) {
  var step = Props.step;
  var className = Props.className;
  var onSelection = Props.onSelection;
  var style = Props.style;
  Props.representation;
  return React.createElement("button", {
              className: className,
              style: style,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(onSelection, step);
                })
            }, React.createElement("div", undefined, React.createElement("span", undefined, String(step))));
}

var NpsItem = {
  make: NpsPreview$NpsItem
};

function colorBasedOnLikelyness(index) {
  switch (index) {
    case 0 :
    case 1 :
    case 2 :
    case 3 :
    case 4 :
    case 5 :
    case 6 :
        return "#E60000";
    case 7 :
    case 8 :
        return "#F79406";
    case 9 :
    case 10 :
        return "#21AD04";
    default:
      return "#ffffff";
  }
}

function NpsPreview(Props) {
  var nps = Props.nps;
  var customerInfo = Props.customerInfo;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var steps = function (count) {
    return Belt_List.makeBy(count, (function (x) {
                  var match = Nps$ReactHooksTemplate.includeZero(nps);
                  if (match) {
                    return x;
                  } else {
                    return x + 1 | 0;
                  }
                }));
  };
  var scale = Nps$ReactHooksTemplate.scale(nps);
  var includeZero = Nps$ReactHooksTemplate.includeZero(nps);
  var startRating = scale ? 0 : 1;
  var endRating = scale ? 10 : 5;
  var selectedCss = function (x) {
    var match = Nps$ReactHooksTemplate.isAnswer(nps, x);
    if (match) {
      return "border-2 border-bleudefrance";
    } else {
      return "";
    }
  };
  var rmName = CustomerInfo$ReactHooksTemplate.rmName(customerInfo);
  var questionText = Nps$ReactHooksTemplate.getTextTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)).replace("${RM_NAME}", rmName);
  var items = function (a, b) {
    return $$Array.of_list(Belt_List.mapWithIndex(steps((b - a | 0) + 1 | 0), (function (i, x) {
                      return React.createElement(NpsPreview$NpsItem, {
                                  step: includeZero ? x + a | 0 : (x + a | 0) - 1 | 0,
                                  className: selectedCss(x) + (" " + "  w-48-px h-48-px rounded-xl focus:outline-none text-white text-lg\n    font-medium "),
                                  onSelection: (function (scale) {
                                      return Curry._1(onChange, Nps$ReactHooksTemplate.setAnswer(nps, scale));
                                    }),
                                  style: {
                                    backgroundColor: colorBasedOnLikelyness(x)
                                  },
                                  representation: Nps$ReactHooksTemplate.representation(nps),
                                  key: String(i + a | 0)
                                });
                    })));
  };
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, Nps$ReactHooksTemplate.setText(nps, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, Nps$ReactHooksTemplate.setDescription(nps, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: questionText,
                  description: Nps$ReactHooksTemplate.getDescriptionTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", {
                  className: "flex flex-col justify-between items-start max-w-600-px"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Range/Steps"), React.createElement("div", {
                      className: "flex gap-2 max-w-600-px"
                    }, items(startRating, endRating)), React.createElement("div", {
                      className: " flex justify-between  w-full pt-6 pb-10 text-spanishgray text-sm "
                    }, React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", {
                          className: "pl-12"
                        }, NpsLabel$ReactHooksTemplate.center(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                        language: language
                      }) : React.createElement(React.Fragment, undefined)));
}

var make = NpsPreview;

exports.NpsItem = NpsItem;
exports.colorBasedOnLikelyness = colorBasedOnLikelyness;
exports.make = make;
/* react Not a pure module */
