// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var User$ReactHooksTemplate = require("./user.bs.js");
var UserForm$ReactHooksTemplate = require("./UserForm.bs.js");

function reducer(state, action) {
  if (action) {
    return /* Available */[action[0]];
  } else {
    return /* InvalidUser */1;
  }
}

function fetchUser(id) {
  var url = "/api/v1/users/" + (String(id) + "");
  return Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                return Json_decode.field("user", User$ReactHooksTemplate.decode, param);
              }));
}

function UserEdit(Props) {
  var id = Props.id;
  var match = React.useReducer(reducer, /* FetchingUser */0);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          fetchUser(id).then((function (user) {
                  return Promise.resolve(Curry._1(dispatch, /* FetchedUser */[user]));
                }));
          return ;
        }), ([]));
  if (typeof state === "number") {
    if (state !== 0) {
      return React.createElement("p", undefined, "User not found");
    } else {
      return React.createElement("p", undefined, "Loading user");
    }
  } else {
    return React.createElement("div", undefined, React.createElement(UserForm$ReactHooksTemplate.make, {
                    user: state[0]
                  }));
  }
}

var make = UserEdit;

exports.reducer = reducer;
exports.fetchUser = fetchUser;
exports.make = make;
/* react Not a pure module */
