// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function RoleCount(Props) {
  var count = Props.count;
  var countText = "+" + String(count);
  return React.createElement("p", {
              className: "font-bold text-teal-500"
            }, countText);
}

var make = RoleCount;

exports.make = make;
/* react Not a pure module */
