// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var React = require("react");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");

function SurveyConfiguration(Props) {
  var onClose = Props.onClose;
  var survey = Props.survey;
  var updateSurvey = Props.updateSurvey;
  var initialState_003 = /* logoUrl */Survey$ReactHooksTemplate.logo(survey);
  var initialState = /* record */[
    /* edited */false,
    /* survey */survey,
    /* showUploadInputField */false,
    initialState_003,
    /* logoFile */undefined
  ];
  var match = React.useState((function () {
          return initialState;
        }));
  var setState = match[1];
  var state = match[0];
  var handleLogoChange = function ($$event) {
    var logoFile = Caml_array.caml_array_get($$event.target.files, 0);
    var logoUrl = window.URL.createObjectURL(logoFile);
    return Curry._1(setState, (function (param) {
                  return /* record */[
                          /* edited */true,
                          /* survey */state[/* survey */1],
                          /* showUploadInputField */false,
                          /* logoUrl */logoUrl,
                          /* logoFile */Caml_option.some(logoFile)
                        ];
                }));
  };
  var showUploadInputField = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return /* record */[
                          /* edited */state[/* edited */0],
                          /* survey */state[/* survey */1],
                          /* showUploadInputField */true,
                          /* logoUrl */state[/* logoUrl */3],
                          /* logoFile */state[/* logoFile */4]
                        ];
                }));
  };
  var revertLogoChanges = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return /* record */[
                          /* edited */false,
                          /* survey */state[/* survey */1],
                          /* showUploadInputField */false,
                          /* logoUrl */Survey$ReactHooksTemplate.logo(state[/* survey */1]),
                          /* logoFile */state[/* logoFile */4]
                        ];
                }));
  };
  var saveSurveyLogo = function ($$event) {
    $$event.preventDefault();
    var formData = new FormData();
    var match = state[/* logoFile */4];
    var match$1 = Survey$ReactHooksTemplate.id(state[/* survey */1]);
    if (match !== undefined && match$1 !== undefined) {
      var file = Caml_option.valFromOption(match);
      ((function (eta) {
              var param = undefined;
              var param$1 = eta;
              param$1.append("survey[logo]", file, param !== undefined ? Caml_option.valFromOption(param) : undefined);
              return /* () */0;
            })(formData));
      fetch("/api/v1/surveys/" + String(match$1), Fetch.RequestInit.make(/* Put */3, /* array */[
                      /* tuple */[
                        "X-CSRF-TOKEN",
                        Utils$ReactHooksTemplate.csrfToken
                      ],
                      /* tuple */[
                        "Accept",
                        "*"
                      ]
                    ], Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (response) {
                Curry._1(setState, (function (state) {
                        return /* record */[
                                /* edited */false,
                                /* survey */state[/* survey */1],
                                /* showUploadInputField */state[/* showUploadInputField */2],
                                /* logoUrl */state[/* logoUrl */3],
                                /* logoFile */state[/* logoFile */4]
                              ];
                      }));
                return response.json();
              })).then((function (response) {
              var logoUrl = Json_decode.field("survey", (function (param) {
                      return Json_decode.field("logo", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), response);
              var newSurvey = Survey$ReactHooksTemplate.setLogo(survey, logoUrl);
              Curry._1(setState, (function (state) {
                      return /* record */[
                              /* edited */state[/* edited */0],
                              /* survey */newSurvey,
                              /* showUploadInputField */state[/* showUploadInputField */2],
                              /* logoUrl */state[/* logoUrl */3],
                              /* logoFile */state[/* logoFile */4]
                            ];
                    }));
              Curry._1(updateSurvey, newSurvey);
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    } else {
      return /* () */0;
    }
  };
  var partial_arg = state[/* survey */1];
  var match$1 = state[/* showUploadInputField */2];
  var match$2 = state[/* edited */0] === true;
  return React.createElement("div", {
              className: "bg-white w-5/12 fixed h-screen top-0 right-0 z-10 shadow"
            }, React.createElement("div", {
                  className: "flex justify-between bg-antiflashwhitegrey px-6 py-5 text-lg font-semibold"
                }, "Survey Configuration", React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("button", {
                          onClick: onClose
                        }, React.createElement("img", {
                              className: "w-3",
                              src: Image$ReactHooksTemplate.closeIcon
                            })))), React.createElement("div", {
                  className: "py-6 px-10 overflow-auto max-h-screen"
                }, React.createElement("div", {
                      className: "border-b border-gray-300 mb-8"
                    }, React.createElement("div", {
                          className: "flex justify-between pb-8"
                        }, React.createElement("div", {
                              className: "flex text-base font-medium"
                            }, React.createElement("img", {
                                  className: "w-4 mr-1",
                                  src: Image$ReactHooksTemplate.cogTransparent
                                }), "Survey Details"), React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("img", {
                                  className: "w-3",
                                  src: Image$ReactHooksTemplate.minus
                                }))), React.createElement("div", {
                          className: "px-4 mb-8"
                        }, React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "bg-ghostwhitegray h-16 w-full flex\n                              items-center px-4 justify-between rounded-lg\n                              border border-gray-300"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Survey Title"), React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm",
                                      value: Survey$ReactHooksTemplate.name(state[/* survey */1]),
                                      onChange: (function (param) {
                                          var survey = partial_arg;
                                          var $$event = param;
                                          var name = $$event.target.value;
                                          var newSurvey = Survey$ReactHooksTemplate.setName(survey, name);
                                          Curry._1(setState, (function (state) {
                                                  return /* record */[
                                                          /* edited */state[/* edited */0],
                                                          /* survey */newSurvey,
                                                          /* showUploadInputField */state[/* showUploadInputField */2],
                                                          /* logoUrl */state[/* logoUrl */3],
                                                          /* logoFile */state[/* logoFile */4]
                                                        ];
                                                }));
                                          return Curry._1(updateSurvey, newSurvey);
                                        })
                                    }))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "Meta Description"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full flex\n                              items-center px-4 justify-between rounded-lg\n                              border border-gray-300 py-4"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Description"), React.createElement("textArea", {
                                      className: "h-20 px-4 py-3 rounded border border-gray-300 text-sm",
                                      value: Survey$ReactHooksTemplate.name(survey)
                                    }))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "Survey Logo"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full\n                              items-center px-4 rounded-lg\n                              border border-gray-300 py-4"
                                }, React.createElement("div", {
                                      className: "flex justify-start mb-4 items-center h-138-px"
                                    }, React.createElement("div", {
                                          className: "text-sm text-darkcharcoal pr-10 px-2"
                                        }, "Logo"), React.createElement("div", {
                                          className: "flex flex-col items-center justify-center h-128-px"
                                        }, match$1 ? React.createElement("input", {
                                                className: "h-10 w-3/4 block border px-3 py-2 border-gray-400 bg-white rounded capitalize text-sm",
                                                accept: ".png",
                                                type: "file",
                                                onChange: handleLogoChange
                                              }) : React.createElement(React.Fragment, undefined, React.createElement("img", {
                                                    className: "h-100-px mb-1",
                                                    src: Belt_Option.getWithDefault(state[/* logoUrl */3], "#")
                                                  }), React.createElement("button", {
                                                    className: "round border text-sm bg-ghostwhite\n                           border-gray-400 p-1 rounded",
                                                    onClick: showUploadInputField
                                                  }, "Change")))), match$2 ? React.createElement("div", {
                                        className: "flex justify-end gap-3"
                                      }, React.createElement("button", {
                                            className: "border rounded p-2 text-sm text-darkcharcoal\n                  bg-white",
                                            disabled: !state[/* edited */0],
                                            onClick: revertLogoChanges
                                          }, "Revert"), React.createElement("button", {
                                            className: "border rounded p-2 text-sm bg-pigmentblue\n                  text-white w-2/11",
                                            disabled: !state[/* edited */0],
                                            onClick: saveSurveyLogo
                                          }, "Apply")) : React.createElement("div", {
                                        className: "flex justify-end gap-3"
                                      }, React.createElement("button", {
                                            className: "border text-gray-400 rounded p-2 text-sm",
                                            disabled: !state[/* edited */0],
                                            onClick: revertLogoChanges
                                          }, "Revert"), React.createElement("button", {
                                            className: "border rounded p-2 text-sm bg-pigmentblue\n                  text-white w-2/11 opacity-25",
                                            disabled: !state[/* edited */0],
                                            onClick: saveSurveyLogo
                                          }, "Apply")))), React.createElement("div", {
                              className: "border-b border-gray-300 mb-8"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "flex text-base font-medium"
                                    }, React.createElement("img", {
                                          className: "w-4 mr-1",
                                          src: Image$ReactHooksTemplate.palette
                                        }), "Themes"), React.createElement("div", {
                                      className: "flex items-center"
                                    }, React.createElement("img", {
                                          className: "w-3",
                                          src: Image$ReactHooksTemplate.minus
                                        }))), React.createElement("div", {
                                  className: "px-8 mb-8"
                                }, React.createElement("div", {
                                      className: "p-6 bg-ghostwhite rounded-xl border border-gray-300"
                                    }, React.createElement("div", {
                                          className: "flex justify-between pb-8 items-center"
                                        }, React.createElement("div", {
                                              className: "flex text-sm font-normal"
                                            }, "Current Theme"), React.createElement("div", {
                                              className: "flex items-center"
                                            }, React.createElement("select", {
                                                  className: "h-10 block border px-2 py-1 border-gray-400\n                      bg-white rounded text-sm",
                                                  value: "Default Theme"
                                                }, React.createElement("option", undefined, "Default Theme")))), React.createElement("div", {
                                          className: "flex justify-between pb-8 items-center"
                                        }, React.createElement("div", {
                                              className: "flex text-sm font-normal"
                                            }, "Color Scheme"), React.createElement("div", {
                                              className: "flex items-center border border-gray-400 rounded"
                                            }, React.createElement("div", {
                                                  className: "w-8 h-10 bg-abaddonblack rounded-l"
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 bg-crimson"
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 bg-white"
                                                }), React.createElement("div", {
                                                  className: "w-8 h-10 bg-pigmentblue rounded-r"
                                                }))), React.createElement("div", {
                                          className: "flex items-center gap-8 justify-center"
                                        }, React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 bg-abaddonblack rounded-full border border-gray-400 mb-1"
                                                }), "Questions"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 bg-crimson rounded-full border border-gray-400 mb-1"
                                                }), "Buttons"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 bg-white rounded-full border border-gray-400 mb-1"
                                                }), "Background"), React.createElement("div", {
                                              className: "flex flex-col text-xs items-center"
                                            }, React.createElement("div", {
                                                  className: "w-10 h-10 bg-pigmentblue rounded-full border border-gray-400 mb-1"
                                                }), "Answers"))))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "Survey Termination Redirection"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full flex\n                              items-center p-4 rounded-lg\n                              border border-gray-300"
                                }, React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm w-full",
                                      placeholder: "https://abcd.com"
                                    }))), React.createElement("div", {
                              className: "mb-8"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "text-base font-medium text-darkcharcoal"
                                    }, "In Active Survey Redirection"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray w-full flex\n                              items-center p-4 rounded-lg\n                              border border-gray-300"
                                }, React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm w-full",
                                      placeholder: "https://abcd.com"
                                    }))))), React.createElement("div", {
                      className: "border-b border-gray-300 mb-8"
                    }, React.createElement("div", {
                          className: "flex justify-between pb-8"
                        }, React.createElement("div", {
                              className: "flex text-base font-medium"
                            }, React.createElement("img", {
                                  className: "w-4 mr-1",
                                  src: Image$ReactHooksTemplate.cogTransparent
                                }), "Survey Settings"), React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("img", {
                                  className: "w-3",
                                  src: Image$ReactHooksTemplate.minus
                                }))), React.createElement("div", {
                          className: "px-4 mb-8"
                        }, React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Progress Bar"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Track IP"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Randomize"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between pb-8"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Show question number"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "flex justify-between"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Save and continue"), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        })))), React.createElement("div", {
                              className: "mb-8 pb-8 border-b border-gray-300"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "flex flex-col gap-2"
                                    }, React.createElement("div", {
                                          className: "text-base font-medium text-darkcharcoal"
                                        }, "Limit questions per page"), React.createElement("div", {
                                          className: "text-xs text-aurometalsaurus"
                                        }, "Limit the number of questions per each page/section")), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))), React.createElement("div", {
                                  className: "bg-ghostwhitegray h-16 w-full flex\n                              items-center px-4 justify-between rounded-lg\n                              border border-gray-300"
                                }, React.createElement("div", {
                                      className: "text-sm text-darkcharcoal"
                                    }, "Max number of questions per page"), React.createElement("input", {
                                      className: "h-10 px-4 py-3 rounded border border-gray-300 text-sm w-20"
                                    }))), React.createElement("div", {
                              className: "mb-8 pb-8"
                            }, React.createElement("div", {
                                  className: "flex justify-between pb-4"
                                }, React.createElement("div", {
                                      className: "flex flex-col gap-2"
                                    }, React.createElement("div", {
                                          className: "text-base font-medium text-darkcharcoal"
                                        }, "Resumbission"), React.createElement("div", {
                                          className: "text-xs text-aurometalsaurus"
                                        }, "Allow users to resubmit the response")), React.createElement("label", {
                                      className: "switch"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))))))));
}

var make = SurveyConfiguration;

exports.make = make;
/* react Not a pure module */
