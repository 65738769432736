// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReactCsv = require("react-csv");
var FileSaver = require("file-saver");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var DomToImage = require("dom-to-image");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Chart$ReactHooksTemplate = require("./chart.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var Loading$ReactHooksTemplate = require("./Loading.bs.js");
var MapAttribute$ReactHooksTemplate = require("./mapAttribute.bs.js");
var MapComponent$ReactHooksTemplate = require("./MapComponent.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");
var TableComponent$ReactHooksTemplate = require("./TableComponent.bs.js");
var BarChartComponent$ReactHooksTemplate = require("./BarChartComponent.bs.js");
var PieChartComponent$ReactHooksTemplate = require("./PieChartComponent.bs.js");
var AreaChartComponent$ReactHooksTemplate = require("./AreaChartComponent.bs.js");
var LineChartComponent$ReactHooksTemplate = require("./LineChartComponent.bs.js");
var ScoreCardComponent$ReactHooksTemplate = require("./ScoreCardComponent.bs.js");
var WordcloudComponent$ReactHooksTemplate = require("./WordcloudComponent.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");
var ComposedChartComponent$ReactHooksTemplate = require("./ComposedChartComponent.bs.js");
var RadialBarChartComponent$ReactHooksTemplate = require("./RadialBarChartComponent.bs.js");

var initialState = /* record */[
  /* loading */true,
  /* chart */undefined,
  /* drillDownKey */undefined
];

function reducer(state, param) {
  if (typeof param === "number") {
    return /* record */[
            /* loading */true,
            /* chart */state[/* chart */1],
            /* drillDownKey */state[/* drillDownKey */2]
          ];
  } else if (param.tag) {
    return /* record */[
            /* loading */state[/* loading */0],
            /* chart */state[/* chart */1],
            /* drillDownKey */param[0]
          ];
  } else {
    return /* record */[
            /* loading */false,
            /* chart */param[0],
            /* drillDownKey */state[/* drillDownKey */2]
          ];
  }
}

function ChartComponent(Props) {
  var chartId = Props.chartId;
  var drillDownChartDetails = Props.drillDownChartDetails;
  var onClick = Props.onClick;
  var onEnlarge = Props.onEnlarge;
  Props.scoreCardCount;
  var match = Props.scoreCard;
  var scoreCard = match !== undefined ? match : false;
  var match$1 = Props.enlarged;
  var enlarged = match$1 !== undefined ? match$1 : false;
  var match$2 = Props.insight;
  var insight = match$2 !== undefined ? match$2 : false;
  var match$3 = Props.campaign;
  var campaign = match$3 !== undefined ? match$3 : false;
  var match$4 = React.useReducer(reducer, initialState);
  var dispatch = match$4[1];
  var state = match$4[0];
  var fetchVisualization = function (param) {
    Curry._1(dispatch, /* FetchChartInProgress */0);
    var url = insight ? "/api/v1/insights/show_visualization?visualization=" + ChartId$ReactHooksTemplate.name(chartId) : (
        campaign ? "/api/v1/campaigns/visualization_data?question_id=" + (String(ChartId$ReactHooksTemplate.id(chartId)) + ("&campaign_id=" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("&name=" + ChartId$ReactHooksTemplate.name(chartId))))) : "/api/v1/dashboards/" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("/visualizations/" + String(ChartId$ReactHooksTemplate.id(chartId))))
      );
    return Http$ReactHooksTemplate.fetchGetJSONDecode(url, Chart$ReactHooksTemplate.fromJson).then((function (xs) {
                    return Promise.resolve((setTimeout((function (param) {
                                        return Curry._1(dispatch, /* FetchChartCompleted */Block.__(0, [Caml_option.some(xs)]));
                                      }), Utils$ReactHooksTemplate.timeout), /* () */0));
                  })).catch((function (err) {
                  console.log(err);
                  return Promise.resolve(Curry._1(dispatch, /* FetchChartCompleted */Block.__(0, [undefined])));
                }));
  };
  React.useEffect((function () {
          if (drillDownChartDetails !== undefined) {
            var match = drillDownChartDetails;
            var key = match[1];
            var match$1 = key === "";
            if (match$1) {
              Curry._1(dispatch, /* DrillDown */Block.__(1, [key]));
            }
            var match$2 = match[0] !== ChartId$ReactHooksTemplate.id(chartId);
            if (match$2) {
              fetchVisualization(/* () */0);
            } else {
              Curry._1(dispatch, /* DrillDown */Block.__(1, [key]));
            }
          } else {
            fetchVisualization(/* () */0);
          }
          return ;
        }), /* array */[drillDownChartDetails]);
  var chartElement = React.useRef(null);
  var loadChart = function (chart) {
    var chartWidth;
    if (enlarged) {
      chartWidth = "w-full";
    } else {
      var match = Chart$ReactHooksTemplate.type_(chart);
      if (typeof match === "number") {
        chartWidth = "w-1/2";
      } else {
        switch (match.tag | 0) {
          case /* ScoreCard */7 :
              chartWidth = "w-1/2";
              break;
          case /* Map */8 :
              var match$1 = MapAttribute$ReactHooksTemplate.width(match[0]);
              chartWidth = match$1 !== undefined ? match$1 : "w-1/2";
              break;
          case /* Table */9 :
              var match$2 = TableAttribute$ReactHooksTemplate.width(match[0]);
              chartWidth = match$2 !== undefined ? match$2 : "w-1/2";
              break;
          default:
            var match$3 = GenericChartAttribute$ReactHooksTemplate.width(match[0]);
            chartWidth = match$3 !== undefined ? match$3 : "w-1/2";
        }
      }
    }
    var chartHeight;
    if (enlarged) {
      var match$4 = Chart$ReactHooksTemplate.type_(chart);
      if (typeof match$4 === "number") {
        chartHeight = "h-96";
      } else {
        switch (match$4.tag | 0) {
          case /* Map */8 :
          case /* Table */9 :
              chartHeight = "h-full";
              break;
          default:
            chartHeight = "h-96";
        }
      }
    } else {
      var match$5 = Chart$ReactHooksTemplate.type_(chart);
      if (typeof match$5 === "number") {
        chartHeight = "h-64";
      } else {
        switch (match$5.tag | 0) {
          case /* ScoreCard */7 :
              chartHeight = "h-64";
              break;
          case /* Map */8 :
              var match$6 = MapAttribute$ReactHooksTemplate.height(match$5[0]);
              chartHeight = match$6 !== undefined ? match$6 : "h-64";
              break;
          case /* Table */9 :
              var match$7 = TableAttribute$ReactHooksTemplate.height(match$5[0]);
              chartHeight = match$7 !== undefined ? match$7 : "h-64";
              break;
          default:
            var match$8 = GenericChartAttribute$ReactHooksTemplate.height(match$5[0]);
            chartHeight = match$8 !== undefined ? match$8 : "h-64";
        }
      }
    }
    var match$9 = Chart$ReactHooksTemplate.type_(chart);
    var tmp;
    if (typeof match$9 === "number") {
      tmp = React.createElement(React.Fragment, undefined);
    } else {
      switch (match$9.tag | 0) {
        case /* LineChart */0 :
            tmp = React.createElement(LineChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: match$9[0],
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* AreaChart */1 :
            tmp = React.createElement(AreaChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: match$9[0],
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* PieChart */2 :
            tmp = React.createElement(PieChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: match$9[0],
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* BarChart */3 :
            tmp = React.createElement(BarChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: match$9[0],
                  drillDownKey: state[/* drillDownKey */2],
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* RadialBarChart */4 :
            tmp = React.createElement(RadialBarChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: match$9[0],
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* Wordcloud */5 :
            tmp = React.createElement(WordcloudComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: match$9[0]
                });
            break;
        case /* ComposedChart */6 :
            tmp = React.createElement(ComposedChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: match$9[0],
                  drillDownKey: state[/* drillDownKey */2],
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* ScoreCard */7 :
            tmp = React.createElement(React.Fragment, undefined);
            break;
        case /* Map */8 :
            tmp = React.createElement(MapComponent$ReactHooksTemplate.make, {
                  mapAttribute: match$9[0],
                  chart: chart,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* Table */9 :
            tmp = React.createElement(TableComponent$ReactHooksTemplate.make, {
                  attribute: match$9[0],
                  chart: chart,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        
      }
    }
    var rightPosition = enlarged ? "right-0" : "right-32-px";
    var rightPosition$1 = enlarged ? "right-32-px" : "right-64-px";
    var match$10 = Chart$ReactHooksTemplate.type_(chart);
    var tmp$1;
    if (typeof match$10 === "number") {
      tmp$1 = React.createElement(React.Fragment, undefined);
    } else {
      switch (match$10.tag | 0) {
        case /* ScoreCard */7 :
            tmp$1 = React.createElement(React.Fragment, undefined);
            break;
        case /* Map */8 :
            tmp$1 = React.createElement("div", {
                  className: " absolute top-0 " + (String(rightPosition$1) + " p-16-px")
                }, React.createElement(ReactCsv.CSVLink, {
                      data: MapAttribute$ReactHooksTemplate.data(match$10[0]),
                      separator: ",",
                      filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                      children: React.createElement("img", {
                            className: "w-5",
                            src: Image$ReactHooksTemplate.csvDownloadButton
                          })
                    }));
            break;
        case /* Table */9 :
            tmp$1 = React.createElement("div", {
                  className: " absolute top-0 " + (String(rightPosition$1) + " p-16-px\n                      z-50")
                }, React.createElement(ReactCsv.CSVLink, {
                      data: TableAttribute$ReactHooksTemplate.data(match$10[0]),
                      separator: ",",
                      filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                      children: React.createElement("img", {
                            className: "w-5",
                            src: Image$ReactHooksTemplate.csvDownloadButton
                          })
                    }));
            break;
        default:
          tmp$1 = React.createElement("div", {
                className: " absolute top-0 " + (String(rightPosition$1) + " p-16-px")
              }, React.createElement(ReactCsv.CSVLink, {
                    data: GenericChartAttribute$ReactHooksTemplate.data(match$10[0]),
                    separator: ",",
                    filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                    children: React.createElement("img", {
                          className: "w-5",
                          src: Image$ReactHooksTemplate.csvDownloadButton
                        })
                  }));
      }
    }
    return React.createElement("div", {
                className: "" + (String(chartWidth) + " pb-16-px pr-16-px rounded-sm animated fadeIn\n    ")
              }, React.createElement("div", {
                    className: "relative"
                  }, React.createElement("div", {
                        ref: chartElement,
                        className: "border border-gray-400 rounded bg-white"
                      }, React.createElement("h4", {
                            className: "animated fadeIn font-bold text-charcoal p-20-px flex"
                          }, React.createElement("img", {
                                alt: "doubleMinimize",
                                src: Image$ReactHooksTemplate.doubleMinimize
                              }), Chart$ReactHooksTemplate.title(chart)), React.createElement("div", {
                            className: "w-full animated fadeIn"
                          }, tmp)), enlarged ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                          className: "absolute top-0 right-0 p-16-px"
                        }, React.createElement("button", {
                              onClick: (function (e) {
                                  e.preventDefault();
                                  return Curry._1(onEnlarge, /* () */0);
                                })
                            }, React.createElement("img", {
                                  className: "w-5",
                                  alt: "Expand",
                                  src: Image$ReactHooksTemplate.expand
                                }))), React.createElement("div", {
                        className: " absolute top-0 " + (String(rightPosition) + " p-16-px")
                      }, React.createElement("button", {
                            onClick: (function (e) {
                                e.preventDefault();
                                var ref_ = Belt_Option.getExn(Caml_option.nullable_to_opt(chartElement.current));
                                DomToImage.toBlob(ref_).then((function (response) {
                                        return Promise.resolve((FileSaver.saveAs(response, "chart.png"), /* () */0));
                                      }));
                                return /* () */0;
                              })
                          }, React.createElement("img", {
                                className: "w-5",
                                src: Image$ReactHooksTemplate.pngDownloadButton
                              }))), tmp$1));
  };
  var match$5 = state[/* loading */0];
  var tmp;
  if (match$5) {
    tmp = React.createElement(Loading$ReactHooksTemplate.make, { });
  } else {
    var match$6 = state[/* chart */1];
    if (match$6 !== undefined) {
      var chart = Caml_option.valFromOption(match$6);
      var match$7 = Chart$ReactHooksTemplate.type_(chart);
      tmp = typeof match$7 === "number" || match$7.tag !== /* ScoreCard */7 ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
              className: "w-full"
            }, React.createElement(ScoreCardComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: match$7[0],
                  onClick: onClick
                }));
    } else {
      tmp = "No Data Loaded for the chart";
    }
  }
  var loadScoreCard = React.createElement("div", {
        className: "min-w-265-px"
      }, tmp);
  var match$8 = state[/* loading */0];
  var loadOtherCharts;
  if (match$8) {
    loadOtherCharts = React.createElement("div", {
          className: "w-1/2 pb-16-px pr-16-px rounded-sm animated fadeIn "
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading chart"
            }));
  } else {
    var match$9 = state[/* chart */1];
    loadOtherCharts = match$9 !== undefined ? loadChart(Caml_option.valFromOption(match$9)) : "No Data Loaded for the chart";
  }
  if (scoreCard) {
    return loadScoreCard;
  } else {
    return loadOtherCharts;
  }
}

var make = ChartComponent;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
