// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decodeChoice(json) {
  return /* tuple */[
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("label", Json_decode.string, json)
        ];
}

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* choices */Json_decode.field("choices", (function (param) {
                  return Json_decode.list(decodeChoice, param);
                }), json)
        ];
}

function makeChoice(text, allowNonSpecifiedText, notSpecifiedText) {
  return /* record */[
          /* id */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */text,
          /* allowNonSpecifiedText */allowNonSpecifiedText,
          /* notSpecifiedText */notSpecifiedText
        ];
}

function toggleAllowNonSpecifiedText(choice) {
  return /* record */[
          /* id */choice[/* id */0],
          /* text */choice[/* text */1],
          /* allowNonSpecifiedText */!choice[/* allowNonSpecifiedText */2],
          /* notSpecifiedText */choice[/* notSpecifiedText */3]
        ];
}

function getAllowNonSpecifiedText(choice) {
  return choice[/* allowNonSpecifiedText */2];
}

function choiceText(choice) {
  return choice[/* text */1];
}

function setNotSpecifiedText(notSpecifiedText, choice) {
  return /* record */[
          /* id */choice[/* id */0],
          /* text */choice[/* text */1],
          /* allowNonSpecifiedText */choice[/* allowNonSpecifiedText */2],
          /* notSpecifiedText */notSpecifiedText
        ];
}

function choiceId(choice) {
  return choice[/* id */0];
}

function setChoiceText(text, choice) {
  return /* record */[
          /* id */choice[/* id */0],
          /* text */text,
          /* allowNonSpecifiedText */choice[/* allowNonSpecifiedText */2],
          /* notSpecifiedText */choice[/* notSpecifiedText */3]
        ];
}

function getChoiceText(choice) {
  return choice[/* text */1];
}

function getAnswer(t) {
  return t[/* answer */9];
}

function answerIdList(t) {
  var match = t[/* answer */9];
  if (match !== undefined) {
    var choices = match;
    if (choices) {
      return Belt_List.map(choices, (function (x) {
                    return RandomId$ReactHooksTemplate.toString(x[/* id */0]);
                  }));
    } else {
      return /* [] */0;
    }
  } else {
    return /* [] */0;
  }
}

function answered(t) {
  return t[/* answer */9] !== undefined;
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* description */"",
          /* choices : :: */[
            /* record */[
              /* id */RandomId$ReactHooksTemplate.generate(/* () */0),
              /* text */"Choice 1",
              /* allowNonSpecifiedText */false,
              /* notSpecifiedText */undefined
            ],
            /* :: */[
              /* record */[
                /* id */RandomId$ReactHooksTemplate.generate(/* () */0),
                /* text */"Choice 2",
                /* allowNonSpecifiedText */false,
                /* notSpecifiedText */undefined
              ],
              /* [] */0
            ]
          ],
          /* multipleAnswer */true,
          /* representation : TropicalBreezeBackground */1,
          /* translations : [] */0,
          /* carryForwardFrom */undefined,
          /* answer */undefined
        ];
}

function hasChoiceInAnswer(choice, xs) {
  return Belt_List.has(xs, choice, (function (xs, ys) {
                return Caml_obj.caml_equal(xs[/* id */0], ys[/* id */0]);
              }));
}

function carryForwardFrom(t) {
  return t[/* carryForwardFrom */8];
}

function clearAnswer(t) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */undefined
        ];
}

function setAnswer(t, choice) {
  var match = t[/* multipleAnswer */5];
  var answer;
  if (match) {
    var match$1 = t[/* answer */9];
    if (match$1 !== undefined) {
      var xs = match$1;
      var match$2 = hasChoiceInAnswer(choice, xs);
      var answer$1 = match$2 ? Belt_List.keep(xs, (function (xs) {
                return Caml_obj.caml_notequal(xs[/* id */0], choice[/* id */0]);
              })) : /* :: */[
          choice,
          xs
        ];
      answer = answer$1 ? answer$1 : undefined;
    } else {
      answer = /* :: */[
        choice,
        /* [] */0
      ];
    }
  } else {
    answer = /* :: */[
      choice,
      /* [] */0
    ];
  }
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */answer
        ];
}

function setNotSpecifiedTextForAnswer(t, choice) {
  var match = t[/* answer */9];
  var answer;
  if (match !== undefined) {
    var xs = match;
    var match$1 = hasChoiceInAnswer(choice, xs);
    answer = match$1 ? Belt_List.map(xs, (function (ys) {
              var match = Caml_obj.caml_equal(ys[/* id */0], choice[/* id */0]);
              if (match) {
                return choice;
              } else {
                return ys;
              }
            })) : xs;
  } else {
    answer = undefined;
  }
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */answer
        ];
}

function text(t) {
  return t[/* text */2];
}

function description(t) {
  return t[/* description */3];
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function multipleAnswer(t) {
  return t[/* multipleAnswer */5];
}

function representation(t) {
  return t[/* representation */6];
}

function choices(t) {
  return t[/* choices */4];
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */t[/* answer */9]
        ];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function getChoiceTextTranslation(t, choice, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return List.find((function (param) {
                    return param[0] === RandomId$ReactHooksTemplate.toString(choice[/* id */0]);
                  }), translation[1][/* choices */2])[1];
  } else {
    return choice[/* text */1];
  }
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */description,
          /* choices */t[/* choices */4],
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */t[/* answer */9]
        ];
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */3];
  }
}

function setRepresentation(t, representation) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */representation,
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */t[/* answer */9]
        ];
}

function toggleMultipleAnswer(t) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* multipleAnswer */!t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */t[/* answer */9]
        ];
}

function setChoices(choices, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */choices,
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */t[/* answer */9]
        ];
}

function removeChoice(choice, choices, t) {
  var newSetOfChoices = Belt_List.keep(choices, (function (xs) {
          return Caml_obj.caml_notequal(xs[/* id */0], choice[/* id */0]);
        }));
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */newSetOfChoices,
          /* multipleAnswer */t[/* multipleAnswer */5],
          /* representation */t[/* representation */6],
          /* translations */t[/* translations */7],
          /* carryForwardFrom */t[/* carryForwardFrom */8],
          /* answer */t[/* answer */9]
        ];
}

function setChoice(choice, t) {
  var exist = Belt_List.has(t[/* choices */4], choice, (function (xs, choice) {
          return Caml_obj.caml_equal(xs[/* id */0], choice[/* id */0]);
        }));
  if (exist) {
    return /* record */[
            /* id */t[/* id */0],
            /* uuid */t[/* uuid */1],
            /* text */t[/* text */2],
            /* description */t[/* description */3],
            /* choices */Belt_List.map(t[/* choices */4], (function (xs) {
                    var match = Caml_obj.caml_equal(xs[/* id */0], choice[/* id */0]);
                    if (match) {
                      return choice;
                    } else {
                      return xs;
                    }
                  })),
            /* multipleAnswer */t[/* multipleAnswer */5],
            /* representation */t[/* representation */6],
            /* translations */t[/* translations */7],
            /* carryForwardFrom */t[/* carryForwardFrom */8],
            /* answer */t[/* answer */9]
          ];
  } else {
    return /* record */[
            /* id */t[/* id */0],
            /* uuid */t[/* uuid */1],
            /* text */t[/* text */2],
            /* description */t[/* description */3],
            /* choices */Belt_List.concat(t[/* choices */4], /* :: */[
                  choice,
                  /* [] */0
                ]),
            /* multipleAnswer */t[/* multipleAnswer */5],
            /* representation */t[/* representation */6],
            /* translations */t[/* translations */7],
            /* carryForwardFrom */t[/* carryForwardFrom */8],
            /* answer */t[/* answer */9]
          ];
  }
}

function encodeChoice(choice) {
  var objectEncoderList_000 = /* tuple */[
    "id",
    RandomId$ReactHooksTemplate.toString(choice[/* id */0])
  ];
  var objectEncoderList_001 = /* :: */[
    /* tuple */[
      "label",
      choice[/* text */1]
    ],
    /* :: */[
      /* tuple */[
        "allow_non_specified_text",
        choice[/* allowNonSpecifiedText */2]
      ],
      /* [] */0
    ]
  ];
  var objectEncoderList = /* :: */[
    objectEncoderList_000,
    objectEncoderList_001
  ];
  var match = choice[/* notSpecifiedText */3];
  var textForNonSpecified = match !== undefined ? /* :: */[
      /* tuple */[
        "notSpecifiedText",
        match
      ],
      /* [] */0
    ] : /* [] */0;
  return Json_encode.object_(Pervasives.$at(objectEncoderList, textForNonSpecified));
}

function encodeAnswer(answer) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                RandomId$ReactHooksTemplate.toString(answer[/* id */0])
              ],
              /* :: */[
                /* tuple */[
                  "label",
                  answer[/* text */1]
                ],
                /* [] */0
              ]
            ]);
}

function encodeTextEntries(xs) {
  var textEntries = List.filter((function (x) {
            return Belt_Option.isSome(x[/* notSpecifiedText */3]);
          }))(xs);
  return /* :: */[
          /* tuple */[
            "text_entries",
            Json_encode.list((function (x) {
                    return Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "id",
                                  RandomId$ReactHooksTemplate.toString(x[/* id */0])
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "text",
                                    Belt_Option.getExn(x[/* notSpecifiedText */3])
                                  ],
                                  /* [] */0
                                ]
                              ]);
                  }), textEntries)
          ],
          /* [] */0
        ];
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */3]
      ],
      /* :: */[
        /* tuple */[
          "options",
          Json_encode.list(encodeChoice, t[/* choices */4])
        ],
        /* :: */[
          /* tuple */[
            "multiple_answer",
            t[/* multipleAnswer */5]
          ],
          /* [] */0
        ]
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  var tmp;
  if (withAnswer) {
    var match = t[/* answer */9];
    if (match !== undefined) {
      var xs = match;
      var encodedTextEntries = encodeTextEntries(xs);
      tmp = Pervasives.$at(/* :: */[
            /* tuple */[
              "answer",
              Json_encode.list(encodeAnswer, xs)
            ],
            encodeArray
          ], encodedTextEntries);
    } else {
      tmp = /* [] */0;
    }
  } else {
    tmp = encodeArray;
  }
  return Json_encode.object_(tmp);
}

function decodeChoice$1(json) {
  return /* record */[
          /* id */Json_decode.field("id", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("label", Json_decode.string, json),
          /* allowNonSpecifiedText */Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("allow_non_specified_text", Json_decode.bool, param);
                }), json),
          /* notSpecifiedText */undefined
        ];
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* choices */Json_decode.field("category", (function (param) {
                  return Json_decode.field("options", (function (param) {
                                return Json_decode.list(decodeChoice$1, param);
                              }), param);
                }), json),
          /* multipleAnswer */Json_decode.field("category", (function (param) {
                  return Json_decode.field("multiple_answer", Json_decode.bool, param);
                }), json),
          /* representation : TropicalBreezeBackground */1,
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* carryForwardFrom */Json_decode.field("category", (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("options_from_uid", RandomId$ReactHooksTemplate.fromJson, param);
                              }), param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "MultipleSelect";

exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.choices = choices;
exports.multipleAnswer = multipleAnswer;
exports.representation = representation;
exports.choiceText = choiceText;
exports.choiceId = choiceId;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setRepresentation = setRepresentation;
exports.setChoiceText = setChoiceText;
exports.getChoiceText = getChoiceText;
exports.getChoiceTextTranslation = getChoiceTextTranslation;
exports.setChoices = setChoices;
exports.setChoice = setChoice;
exports.removeChoice = removeChoice;
exports.toggleMultipleAnswer = toggleMultipleAnswer;
exports.uuid = uuid;
exports.id = id;
exports.clearAnswer = clearAnswer;
exports.setAnswer = setAnswer;
exports.answered = answered;
exports.toJson = toJson;
exports.categoryType = categoryType;
exports.empty = empty;
exports.carryForwardFrom = carryForwardFrom;
exports.makeChoice = makeChoice;
exports.getAnswer = getAnswer;
exports.answerIdList = answerIdList;
exports.toggleAllowNonSpecifiedText = toggleAllowNonSpecifiedText;
exports.getAllowNonSpecifiedText = getAllowNonSpecifiedText;
exports.fromJson = fromJson;
exports.setNotSpecifiedText = setNotSpecifiedText;
exports.setNotSpecifiedTextForAnswer = setNotSpecifiedTextForAnswer;
/* RandomId-ReactHooksTemplate Not a pure module */
