// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Campaign$ReactHooksTemplate = require("./campaign.bs.js");
var HtmlToPdf$ReactHooksTemplate = require("../dashboard/dashboard/htmlToPdf.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");

function Campaigns(Props) {
  var content = Props.content;
  var campaignId = Props.campaignId;
  var match = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setCampaignName = match$1[1];
  var campaignName = match$1[0];
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var match$2 = url[/* path */0];
  var selected;
  if (match$2) {
    switch (match$2[0]) {
      case "campaign" :
          var match$3 = match$2[1];
          if (match$3) {
            if (match$3[0] === "reports") {
              var match$4 = match$3[1];
              selected = match$4 && !match$4[1] ? "reports" : "";
            } else {
              var match$5 = match$3[1];
              if (match$5) {
                switch (match$5[0]) {
                  case "campaign_report" :
                      selected = match$5[1] ? "" : "Campaign Report";
                      break;
                  case "data_field_reports" :
                      selected = match$5[1] ? "" : "Data Field Report";
                      break;
                  case "nps_trend" :
                      selected = match$5[1] ? "" : "Nps Trend";
                      break;
                  case "response_mode_reports" :
                      selected = match$5[1] ? "" : "Response Mode Report";
                      break;
                  default:
                    selected = "";
                }
              } else {
                selected = "";
              }
            }
          } else {
            selected = "";
          }
          break;
      case "campaigns" :
          var match$6 = match$2[1];
          if (match$6) {
            var match$7 = match$6[1];
            if (match$7) {
              switch (match$7[0]) {
                case "dashboard" :
                    selected = match$7[1] ? "" : "dashboard";
                    break;
                case "distributions" :
                    selected = match$7[1] ? "" : "distributions";
                    break;
                default:
                  selected = match$6[0] === "report_downloads" && !match$6[1][1] ? "report_downloads" : "";
              }
            } else {
              selected = "";
            }
          } else {
            selected = "";
          }
          break;
      default:
        selected = "";
    }
  } else {
    selected = "";
  }
  var url$1 = "/api/v1/campaigns/" + (String(campaignId) + "");
  var fetchCampaign = Http$ReactHooksTemplate.fetchGetJSON(url$1).then((function (json) {
          return Promise.resolve(Json_decode.field("campaign", Campaign$ReactHooksTemplate.decode, json));
        }));
  React.useEffect((function () {
          fetchCampaign.then((function (campaign) {
                  console.log("campaign");
                  console.log(campaign);
                  return Promise.resolve((Curry._1(setCampaignName, (function (param) {
                                      return Campaign$ReactHooksTemplate.name(campaign);
                                    })), /* () */0));
                }));
          return ;
        }), ([]));
  var match$8 = Curry._1(hasPermission, /* ShowCampaign */14);
  var tmp;
  if (match$8) {
    var match$9 = selected === "dashboard";
    var match$10 = selected === "dashboard";
    tmp = React.createElement("a", {
          href: "/campaigns/" + (String(campaignId) + "/dashboard")
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$9 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$10 ? "filter-white" : ""
                  ),
                  title: "Campaign Dashboard",
                  src: Image$ReactHooksTemplate.dashboard
                })));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var match$11 = Curry._1(hasPermission, /* ShowDistribution */17);
  var tmp$1;
  if (match$11) {
    var match$12 = selected === "distributions";
    var match$13 = selected === "distributions";
    tmp$1 = React.createElement("a", {
          href: "/campaigns/" + (String(campaignId) + "/distributions")
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$12 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$13 ? "filter-white" : ""
                  ),
                  title: "Campaign Distributions",
                  src: Image$ReactHooksTemplate.distributions
                })));
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined);
  }
  var match$14 = Curry._1(hasPermission, /* ShowReport */19);
  var tmp$2;
  if (match$14) {
    var match$15 = selected !== "distributions" && selected !== "report_downloads" && selected !== "dashboard";
    var match$16 = selected !== "distributions" && selected !== "report_downloads" && selected !== "dashboard";
    tmp$2 = React.createElement("a", {
          href: "/campaign/reports/" + (String(campaignId) + "")
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$15 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$16 ? "filter-white" : ""
                  ),
                  title: "Campaign Reports",
                  src: Image$ReactHooksTemplate.reports
                })));
  } else {
    tmp$2 = React.createElement(React.Fragment, undefined);
  }
  var match$17 = Curry._1(hasPermission, /* ShowReport */19);
  var tmp$3;
  if (match$17) {
    var match$18 = selected === "report_downloads";
    var match$19 = selected === "report_downloads";
    tmp$3 = React.createElement("a", {
          href: "/campaigns/report_downloads/" + (String(campaignId) + "")
        }, React.createElement("div", {
              className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                match$18 ? "bg-palatinateblue border-palatinateblue" : ""
              )
            }, React.createElement("img", {
                  className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                    match$19 ? "filter-white" : ""
                  ),
                  title: "Report Downloads",
                  src: Image$ReactHooksTemplate.downloadBox
                })));
  } else {
    tmp$3 = React.createElement(React.Fragment, undefined);
  }
  var match$20 = selected !== "distributions" && selected !== "report_downloads" && selected !== "reports";
  var match$21 = selected === "dashboard";
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "min-h-screen w-16 bg-ghostwhite border-r border-gray-300"
                }, tmp, tmp$1, tmp$2, tmp$3), React.createElement("div", {
                  className: "w-screen"
                }, React.createElement("div", {
                      className: "flex items-center h-16 border-b border-gray-300 p-20-px justify-between"
                    }, React.createElement("div", {
                          className: "flex items-center text-sm font-normal leading-tight\n        tracking-normal text-left"
                        }, React.createElement("a", {
                              className: "mr-1",
                              href: "/campaigns/all"
                            }, React.createElement("h2", {
                                  className: "hover:underline hover:underline-offset-4"
                                }, "Campaigns")), React.createElement("img", {
                              className: "mr-1 h-3 w-4 mt-1",
                              src: Image$ReactHooksTemplate.arrowRight
                            }), match$20 ? React.createElement(React.Fragment, undefined, React.createElement("a", {
                                    className: "mr-1",
                                    href: "/campaign/reports/" + (String(campaignId) + "")
                                  }, React.createElement("h2", {
                                        className: "hover:underline hover:underline-offset-4"
                                      }, campaignName)), React.createElement("img", {
                                    className: "mr-1 h-3 w-4 mt-1",
                                    src: Image$ReactHooksTemplate.arrowRight
                                  }), React.createElement("h2", undefined, selected)) : React.createElement("h2", undefined, campaignName)), match$21 ? React.createElement("div", undefined, React.createElement("button", {
                                className: "p-2 mr-4",
                                id: "pdf_download",
                                onClick: (function (param) {
                                    var element = Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#dashboard")));
                                    HtmlToPdf$ReactHooksTemplate.generateDashboardPdf(element);
                                    return /* () */0;
                                  })
                              }, React.createElement("img", {
                                    className: "h-16-px",
                                    src: Image$ReactHooksTemplate.download
                                  }))) : React.createElement(React.Fragment, undefined)), content));
}

var make = Campaigns;

exports.make = make;
/* react Not a pure module */
