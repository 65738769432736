// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function ToggleInput(Props) {
  var checked = Props.checked;
  var handleChange = Props.handleChange;
  return React.createElement("label", {
              className: "flex items-center"
            }, React.createElement("input", {
                  checked: checked,
                  type: "checkbox",
                  onChange: handleChange
                }), React.createElement("p", {
                  className: "togglebutton"
                }));
}

var make = ToggleInput;

exports.make = make;
/* react Not a pure module */
