// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var SurveyView$ReactHooksTemplate = require("./SurveyView.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function decodeSurvey(json) {
  var survey = Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, json);
  var surveyLanguages = Json_decode.field("survey", (function (param) {
          return Json_decode.field("survey_languages", (function (param) {
                        return Json_decode.list(SurveyLanguage$ReactHooksTemplate.fromJson, param);
                      }), param);
        }), json);
  var language = Belt_Option.getWithDefault(Json_decode.field("survey", (function (param) {
              return Json_decode.optional((function (param) {
                            return Json_decode.field("survey_language", SurveyLanguage$ReactHooksTemplate.fromJson, param);
                          }), param);
            }), json), SurveyLanguage$ReactHooksTemplate.$$default);
  var client = Json_decode.field("survey", (function (param) {
          return Json_decode.field("client", Client$ReactHooksTemplate.decode, param);
        }), json);
  var transactionStatus = Json_decode.field("survey", (function (param) {
          return Json_decode.field("transaction_status", Json_decode.string, param);
        }), json);
  return /* tuple */[
          survey,
          CustomerInfo$ReactHooksTemplate.fromJson(json),
          client,
          transactionStatus,
          surveyLanguages,
          language
        ];
}

function reducer(state, action) {
  return /* record */[
          /* survey */action[0],
          /* customerInfo */action[1],
          /* skipSurvey */state[/* skipSurvey */2],
          /* theme */state[/* theme */3],
          /* client */action[2],
          /* transactionStatus */action[3],
          /* surveyLanguages */action[4],
          /* language */action[5]
        ];
}

function SurveyPage(Props) {
  var id = Props.id;
  var params = Props.params;
  var params$1 = Querystringify.parse(params);
  var emptyState_000 = /* survey */Survey$ReactHooksTemplate.empty(/* () */0);
  var emptyState_001 = /* customerInfo */CustomerInfo$ReactHooksTemplate.empty(/* () */0);
  var emptyState_003 = /* theme */SurveyTheme$ReactHooksTemplate.empty(/* () */0);
  var emptyState_004 = /* client */Client$ReactHooksTemplate.empty(/* () */0);
  var emptyState = /* record */[
    emptyState_000,
    emptyState_001,
    /* skipSurvey */false,
    emptyState_003,
    emptyState_004,
    /* transactionStatus */"",
    /* surveyLanguages : [] */0,
    /* language */SurveyLanguage$ReactHooksTemplate.$$default
  ];
  var match = React.useReducer(reducer, emptyState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return /* Loading */0;
        }));
  var setMode = match$1[1];
  var x = Js_dict.get(params$1, "ltr");
  if (x === undefined) {
    Js_dict.get(params$1, "smiley_rating");
  }
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/survey_details", Json_encode.object_(/* :: */[
                        /* tuple */[
                          "token",
                          id
                        ],
                        /* [] */0
                      ])).then((function (json) {
                    return Promise.resolve(decodeSurvey(json));
                  })).then((function (param) {
                  Curry._1(dispatch, /* Initialize */[
                        param[0],
                        param[1],
                        param[2],
                        param[3],
                        param[4],
                        param[5]
                      ]);
                  return Promise.resolve(Curry._1(setMode, (function (param) {
                                    return /* Ready */1;
                                  })));
                }));
          return ;
        }), ([]));
  switch (match$1[0]) {
    case /* Loading */0 :
        return React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading Survey..."
                      }));
    case /* Ready */1 :
        return React.createElement(React.Fragment, undefined, React.createElement(SurveyView$ReactHooksTemplate.make, {
                        survey: state[/* survey */0],
                        client: state[/* client */4],
                        transactionStatus: state[/* transactionStatus */5],
                        surveyLanguages: state[/* surveyLanguages */6],
                        language: state[/* language */7],
                        customerInfo: state[/* customerInfo */1],
                        preview: false,
                        token: id
                      }));
    case /* Saving */2 :
        return React.createElement("p", undefined, "Saving survey in progress...");
    
  }
}

var make = SurveyPage;

exports.decodeSurvey = decodeSurvey;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
