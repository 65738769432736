// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Moment = require("moment");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var DataRestriction$ReactHooksTemplate = require("./dataRestriction.bs.js");

function id(t) {
  return t[/* id */0];
}

function email(t) {
  return t[/* email */1];
}

function firstName(t) {
  return t[/* firstName */2];
}

function lastName(t) {
  return t[/* lastName */3];
}

function username(t) {
  return t[/* username */4];
}

function active(t) {
  return t[/* active */5];
}

function roles(t) {
  return t[/* roles */6];
}

function dataRestrictions(t) {
  return t[/* dataRestrictions */7];
}

function lastSignInAt(t) {
  return t[/* lastSignInAt */8];
}

function decodeNameField(label, json) {
  var __x = Json_decode.field(label, (function (param) {
          return Json_decode.optional(Json_decode.string, param);
        }), json);
  return Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Belt_Option.getWithDefault(__x, ""));
}

function decodeLastSignInAt(label, json) {
  var lastSignInAt = Json_decode.optional((function (param) {
          return Json_decode.field(label, Json_decode.string, param);
        }), json);
  if (lastSignInAt !== undefined) {
    return Caml_option.some(Moment(new Date(lastSignInAt)));
  }
  
}

function decode(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* email */Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Json_decode.field("email", Json_decode.string, json)),
          /* firstName */decodeNameField("first_name", json),
          /* lastName */decodeNameField("last_name", json),
          /* username */Json_decode.optional((function (param) {
                  return decodeNameField("username", param);
                }), json),
          /* active */Json_decode.field("active", Json_decode.bool, json),
          /* roles */Json_decode.field("roles", (function (param) {
                  return Json_decode.list(Role$ReactHooksTemplate.decode, param);
                }), json),
          /* dataRestrictions */Json_decode.field("data_restrictions", (function (param) {
                  return Json_decode.list(DataRestriction$ReactHooksTemplate.decode, param);
                }), json),
          /* lastSignInAt */decodeLastSignInAt("last_sign_in_at", json)
        ];
}

function showRoles(t) {
  return List.fold_left((function (acc, x) {
                var match = acc === "";
                if (match) {
                  return Role$ReactHooksTemplate.name(x);
                } else {
                  return acc + (", " + Role$ReactHooksTemplate.name(x));
                }
              }), "", t[/* roles */6]);
}

function debug(t) {
  console.log("------------");
  console.log("email     : " + Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, t[/* email */1]));
  console.log("full name : " + (Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, t[/* firstName */2]) + (" " + Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, t[/* lastName */3]))));
  console.log("active : " + Pervasives.string_of_bool(t[/* active */5]));
  console.log("------------");
  return /* () */0;
}

exports.id = id;
exports.email = email;
exports.firstName = firstName;
exports.lastName = lastName;
exports.username = username;
exports.active = active;
exports.roles = roles;
exports.dataRestrictions = dataRestrictions;
exports.lastSignInAt = lastSignInAt;
exports.decodeNameField = decodeNameField;
exports.decodeLastSignInAt = decodeLastSignInAt;
exports.decode = decode;
exports.showRoles = showRoles;
exports.debug = debug;
/* moment Not a pure module */
