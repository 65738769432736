import React, { Component } from "react";
import proj4 from "./proj4.js";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import drilldown from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

drilldown(Highcharts);
dataModule(Highcharts);

import nepal from "./nepal.json";
//import bagmati from "./bagmati.json";
//import karnali from "./karnali.json";
//import mahakali from "./mahakali.json";
//import janakpur from "./janakpur.json";
//import bheri from "./bheri.json";
//import dhawalagiri from "./dhawalagiri.json";
//import narayani from "./narayani.json";
//import mechi from "./mechi.json";
//import lumbini from "./lumbini.json";
//import koshi from "./koshi.json";
//import gandaki from "./gandaki.json";
//import sagarmatha from "./sagarmatha.json";
//import rapti from "./rapti.json";
//import seti from "./seti.json";
import { mainSeries } from "./main.js";
import { drilldownSeries } from "./drilldown.js";
import { clusterSeries } from "./cluster.js";

class HighchartsBinding extends Component {
  render() {
    return React.createElement(
      "div",
      {
        className: "h-full w-full p-20-px",
      },
      React.createElement(HighchartsReact, {
        highcharts: Highcharts,
        constructorType: "mapChart",
        options: {
          drilldown: {
            series: this.props.drilldown,
          },
          title: {
            text: "",
          },
          chart: {
            height: this.props.mapHeight,
          },
          exporting: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          legend: {
            layout: "vertical",
            align: "left",
            verticalAlign: "middle",
          },
          dataLabels: {
            enabled: true,
            color: "#FFFFFF",
            format: "{point.name}: {point.value}",
          },
          colorAxis: this.props.colorAxis,
          series: this.props.series,
        },
      })
    );
  }
}

export default HighchartsBinding;
export { HighchartsBinding };
