// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function OpinionScalePreview$OpinionScaleItem(Props) {
  var step = Props.step;
  var className = Props.className;
  var onSelection = Props.onSelection;
  var style = Props.style;
  return React.createElement("button", {
              className: className,
              style: style,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(onSelection, step);
                })
            }, React.createElement("div", undefined, React.createElement("span", undefined, String(step))));
}

var OpinionScaleItem = {
  make: OpinionScalePreview$OpinionScaleItem
};

function colorBasedOnLikelyness(index) {
  switch (index) {
    case 0 :
    case 1 :
    case 2 :
    case 3 :
    case 4 :
        return "#f5d0d0";
    case 5 :
    case 6 :
        return "#d9daf8";
    case 7 :
    case 8 :
    case 9 :
    case 10 :
        return "#c7f8d8";
    default:
      return "#ffffff";
  }
}

function OpinionScalePreview(Props) {
  var opinionScale = Props.opinionScale;
  var surveyTheme = Props.surveyTheme;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var selectedCss = function (x) {
    var match = OpinionScale$ReactHooksTemplate.isAnswer(opinionScale, x);
    if (match) {
      return "text-vistablue border-2 border-bleudefrance";
    } else {
      return "";
    }
  };
  var items = function (a, b) {
    return $$Array.of_list(Belt_List.mapWithIndex(Belt_List.makeBy((b - a | 0) + 1 | 0, (function (x) {
                          return x;
                        })), (function (i, x) {
                      return React.createElement(OpinionScalePreview$OpinionScaleItem, {
                                  step: x + a | 0,
                                  className: selectedCss(x) + " w-48-px h-48-px rounded-xl focus:outline-none text-darkcharcoal text-lg\n    font-medium",
                                  onSelection: (function (scale) {
                                      return Curry._1(onChange, OpinionScale$ReactHooksTemplate.setAnswer(opinionScale, scale));
                                    }),
                                  style: {
                                    backgroundColor: colorBasedOnLikelyness(i)
                                  },
                                  key: String(i + a | 0)
                                });
                    })));
  };
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, OpinionScale$ReactHooksTemplate.setText(opinionScale, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, OpinionScale$ReactHooksTemplate.setDescription(opinionScale, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: OpinionScale$ReactHooksTemplate.text(opinionScale),
                  description: OpinionScale$ReactHooksTemplate.description(opinionScale),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", {
                  className: "flex flex-col justify-between items-start max-w-600-px"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Range/Steps"), React.createElement("div", {
                      className: "flex gap-2 max-w-600-px"
                    }, items(0, 10)), React.createElement("div", {
                      className: " flex justify-between  w-full pt-6 pb-10 text-spanishgray text-sm "
                    }, React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.left(OpinionScale$ReactHooksTemplate.label(opinionScale))), React.createElement("p", {
                          className: "pl-12"
                        }, OpinionScaleLabel$ReactHooksTemplate.center(OpinionScale$ReactHooksTemplate.label(opinionScale))), React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.right(OpinionScale$ReactHooksTemplate.label(opinionScale)))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, { }) : React.createElement(React.Fragment, undefined)));
}

var make = OpinionScalePreview;

exports.OpinionScaleItem = OpinionScaleItem;
exports.colorBasedOnLikelyness = colorBasedOnLikelyness;
exports.make = make;
/* react Not a pure module */
