// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var Condition$ReactHooksTemplate = require("./Condition.bs.js");

function empty(param) {
  return /* Empty */0;
}

function addNode(node, graph) {
  if (graph) {
    return /* Graph */[
            /* :: */[
              node,
              graph[0]
            ],
            graph[1]
          ];
  } else {
    return /* Graph */[
            /* :: */[
              node,
              /* [] */0
            ],
            /* [] */0
          ];
  }
}

function addEdge(edge, graph) {
  if (graph) {
    return /* Graph */[
            graph[0],
            /* :: */[
              edge,
              graph[1]
            ]
          ];
  } else {
    return /* Empty */0;
  }
}

function getEdges(node, graph) {
  if (graph) {
    return List.filter((function (x) {
                    return Question$ReactHooksTemplate.equals(node, x[0]);
                  }))(graph[1]);
  } else {
    return /* [] */0;
  }
}

function removeEdgeForNode(from_, graph) {
  if (graph) {
    var edges = graph[1];
    var nodes = graph[0];
    if (edges) {
      return /* Graph */[
              nodes,
              List.filter((function (x) {
                        switch (x.tag | 0) {
                          case /* DefaultLogic */0 :
                              return !Question$ReactHooksTemplate.equals(from_, x[0]);
                          case /* SkipLogic */1 :
                          case /* JumpTo */2 :
                              return true;
                          
                        }
                      }))(edges)
            ];
    } else {
      return /* Graph */[
              nodes,
              /* [] */0
            ];
    }
  } else {
    return /* Empty */0;
  }
}

function changeEdge(from, edge, graph) {
  return addEdge(edge, removeEdgeForNode(from, graph));
}

function getNextNode(from, graph) {
  if (graph) {
    return Belt_List.head(List.fold_left((function (nextNodes, x) {
                      switch (x.tag | 0) {
                        case /* DefaultLogic */0 :
                            var to_ = x[1];
                            if (to_.tag) {
                              var match = Question$ReactHooksTemplate.equals(x[0], from);
                              if (match) {
                                return /* :: */[
                                        to_,
                                        nextNodes
                                      ];
                              } else {
                                return nextNodes;
                              }
                            } else {
                              return nextNodes;
                            }
                        case /* SkipLogic */1 :
                            var to_$1 = x[1];
                            var match$1 = Question$ReactHooksTemplate.equals(x[0], from) && List.fold_left((function (a, x) {
                                    if (Condition$ReactHooksTemplate.isValid(x)) {
                                      return a;
                                    } else {
                                      return false;
                                    }
                                  }), true, x[2]);
                            if (match$1) {
                              if (to_$1.tag) {
                                var match$2 = getNextNode(to_$1[0], graph);
                                if (match$2 !== undefined) {
                                  return /* :: */[
                                          match$2,
                                          nextNodes
                                        ];
                                } else {
                                  return /* [] */0;
                                }
                              } else {
                                return /* [] */0;
                              }
                            } else {
                              return nextNodes;
                            }
                        case /* JumpTo */2 :
                            var match$3 = Question$ReactHooksTemplate.equals(x[0], from) && List.fold_left((function (a, x) {
                                    if (Condition$ReactHooksTemplate.isValid(x)) {
                                      return a;
                                    } else {
                                      return false;
                                    }
                                  }), true, x[2]);
                            if (match$3) {
                              return /* :: */[
                                      x[1],
                                      nextNodes
                                    ];
                            } else {
                              return nextNodes;
                            }
                        
                      }
                    }), /* [] */0, graph[1]));
  }
  
}

function getPrevNode(to_, graph) {
  if (graph) {
    return Belt_List.head(List.fold_left((function (nextNodes, x) {
                      switch (x.tag | 0) {
                        case /* DefaultLogic */0 :
                            var to__ = x[1];
                            if (to__.tag) {
                              var match = Question$ReactHooksTemplate.equals(to_, to__[0]);
                              if (match) {
                                return /* :: */[
                                        /* Question */Block.__(1, [x[0]]),
                                        nextNodes
                                      ];
                              } else {
                                return nextNodes;
                              }
                            } else {
                              return nextNodes;
                            }
                        case /* SkipLogic */1 :
                            var to__$1 = x[1];
                            if (to__$1.tag) {
                              var match$1 = Question$ReactHooksTemplate.equals(to_, to__$1[0]) && List.fold_left((function (a, x) {
                                      if (Condition$ReactHooksTemplate.isValid(x)) {
                                        return a;
                                      } else {
                                        return false;
                                      }
                                    }), true, x[2]);
                              if (match$1) {
                                var match$2 = getPrevNode(x[0], graph);
                                if (match$2 !== undefined) {
                                  return /* :: */[
                                          match$2,
                                          nextNodes
                                        ];
                                } else {
                                  return nextNodes;
                                }
                              } else {
                                return nextNodes;
                              }
                            } else {
                              return nextNodes;
                            }
                        case /* JumpTo */2 :
                            var to__$2 = x[1];
                            if (to__$2.tag) {
                              var match$3 = Question$ReactHooksTemplate.equals(to_, to__$2[0]) && List.fold_left((function (a, x) {
                                      if (Condition$ReactHooksTemplate.isValid(x)) {
                                        return a;
                                      } else {
                                        return false;
                                      }
                                    }), true, x[2]);
                              if (match$3) {
                                return /* :: */[
                                        /* Question */Block.__(1, [x[0]]),
                                        nextNodes
                                      ];
                              } else {
                                return nextNodes;
                              }
                            } else {
                              return nextNodes;
                            }
                        
                      }
                    }), /* [] */0, graph[1]));
  }
  
}

exports.addNode = addNode;
exports.empty = empty;
exports.addEdge = addEdge;
exports.getEdges = getEdges;
exports.changeEdge = changeEdge;
exports.getNextNode = getNextNode;
exports.getPrevNode = getPrevNode;
/* Question-ReactHooksTemplate Not a pure module */
