// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var MultipleChoiceDefaultForm$ReactHooksTemplate = require("./MultipleChoiceDefaultForm.bs.js");

function MultipleChoiceForm(Props) {
  var multipleChoice = Props.multipleChoice;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var match = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match !== undefined) {
    switch (match) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(MultipleChoiceDefaultForm$ReactHooksTemplate.make, {
                    multipleChoice: multipleChoice,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        return React.createElement("div", {
                    className: "flex flex-col"
                  }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                        text: MultipleChoice$ReactHooksTemplate.getTextTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)),
                        description: MultipleChoice$ReactHooksTemplate.getDescriptionTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)),
                        mandatory: mandatory,
                        surveyTheme: surveyTheme
                      }), React.createElement("div", {
                        className: "flex flex-col"
                      }, React.createElement("label", {
                            className: "block mb-10-px"
                          }, $$Array.of_list(Belt_List.mapWithIndex(MultipleChoice$ReactHooksTemplate.getChoicesTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)), (function (param, param$1) {
                                      var id = param$1[0];
                                      return React.createElement("div", {
                                                  key: id,
                                                  className: "pb-2"
                                                }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                      checked: MultipleChoice$ReactHooksTemplate.$eq(multipleChoice, id),
                                                      label: param$1[1],
                                                      handleChange: (function (param) {
                                                          return Curry._1(onChange, MultipleChoice$ReactHooksTemplate.setAnswer(multipleChoice, id));
                                                        }),
                                                      className: " p-2 border border-white-300 rounded-full\n                    flex w-3/4 small:w-full mb-2 "
                                                    }));
                                    }))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                          language: language
                        }) : React.createElement(React.Fragment, undefined));
    
  }
}

var make = MultipleChoiceForm;

exports.make = make;
/* react Not a pure module */
