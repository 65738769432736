// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var FeedFile$ReactHooksTemplate = require("./feedFile.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var Distribution$ReactHooksTemplate = require("./distribution.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var DistributionCreate$ReactHooksTemplate = require("./DistributionCreate.bs.js");

var initialState = /* record */[
  /* loading */true,
  /* distributions : [] */0,
  /* notification */undefined,
  /* distributionCreate */false
];

function reducer(state, param) {
  if (typeof param === "number") {
    switch (param) {
      case /* FetchDistributionInProgress */0 :
          return /* record */[
                  /* loading */true,
                  /* distributions */state[/* distributions */1],
                  /* notification */state[/* notification */2],
                  /* distributionCreate */state[/* distributionCreate */3]
                ];
      case /* RemoveNotification */1 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* distributions */state[/* distributions */1],
                  /* notification */undefined,
                  /* distributionCreate */state[/* distributionCreate */3]
                ];
      case /* ToggleDistributionCreate */2 :
          return /* record */[
                  /* loading */state[/* loading */0],
                  /* distributions */state[/* distributions */1],
                  /* notification */state[/* notification */2],
                  /* distributionCreate */!state[/* distributionCreate */3]
                ];
      
    }
  } else if (param.tag) {
    return /* record */[
            /* loading */state[/* loading */0],
            /* distributions */state[/* distributions */1],
            /* notification */param[0],
            /* distributionCreate */state[/* distributionCreate */3]
          ];
  } else {
    return /* record */[
            /* loading */false,
            /* distributions */param[0],
            /* notification */state[/* notification */2],
            /* distributionCreate */state[/* distributionCreate */3]
          ];
  }
}

function DistributionList(Props) {
  var campaignId = Props.campaignId;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var toggleDistributionCreate = function (option) {
    Curry._1(dispatch, /* ToggleDistributionCreate */2);
    if (option === "save") {
      fetch("/api/v1/campaigns/" + (String(campaignId) + "/distributions"), Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (response) {
                  return response.json();
                })).then((function (json) {
                return Promise.resolve(Json_decode.field("distributions", (function (param) {
                                  return Json_decode.list(Distribution$ReactHooksTemplate.decode, param);
                                }), json));
              })).then((function (distributions) {
              Curry._1(dispatch, /* FetchDistributionsCompleted */Block.__(0, [distributions]));
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    } else {
      return 0;
    }
  };
  React.useEffect((function () {
          fetch("/api/v1/campaigns/" + (String(campaignId) + "/distributions"), Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (response) {
                      return response.json();
                    })).then((function (json) {
                    return Promise.resolve(Json_decode.field("distributions", (function (param) {
                                      return Json_decode.list(Distribution$ReactHooksTemplate.decode, param);
                                    }), json));
                  })).then((function (distributions) {
                  Curry._1(dispatch, /* FetchDistributionsCompleted */Block.__(0, [distributions]));
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), ([]));
  var removeNotification = function (param) {
    return Curry._1(dispatch, /* RemoveNotification */1);
  };
  var randomBgColor = function (param) {
    return Belt_Array.getExn(Belt_Array.shuffle($$Array.of_list(/* :: */[
                        "bg-toolbox",
                        /* :: */[
                          "bg-arylideyellowlight",
                          /* :: */[
                            "bg-iguanagreen",
                            /* :: */[
                              "bg-vistablue",
                              /* :: */[
                                "bg-iceberg",
                                /* :: */[
                                  "bg-scooter",
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])), 0);
  };
  var bgSourceStatusColor = function (status) {
    switch (status) {
      case "pending" :
          return "bg-candypink";
      case "processed" :
          return "bg-americangreen";
      case "processing" :
          return "bg-cobalt";
      default:
        return "hidden";
    }
  };
  var bgStatusColor = function (status) {
    switch (status) {
      case "finished" :
          return "bg-americangreen";
      case "paused" :
          return "bg-lavenderblush";
      case "pending" :
          return "bg-candypink";
      case "running" :
          return "bg-cobalt";
      case "scheduled" :
          return "bg-yellow-400";
      case "stopped" :
          return "bg-firebrick";
      default:
        return "hidden";
    }
  };
  var match$2 = state[/* loading */0];
  if (match$2) {
    return React.createElement("p", undefined, "Loading distributions...");
  } else {
    var match$3 = state[/* notification */2];
    var match$4 = Curry._1(match$1[0], /* CreateDistribution */18);
    var match$5 = state[/* distributionCreate */3];
    return React.createElement(React.Fragment, undefined, match$3 !== undefined ? React.createElement(Notification$ReactHooksTemplate.make, {
                      text: match$3,
                      handleClose: removeNotification
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center p-20-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Distributions"), match$4 ? React.createElement("div", {
                              className: "bg-ghostwhite border border-gray-400\n                    shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "add-distribution",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* ToggleDistributionCreate */2);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.plusCircle
                                }), "New Distribution") : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                        className: "flex px-20-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-sm font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "UID", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Source Type", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Source Detail", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Source Status", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Total", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Status", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Created At", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-3"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Actions", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                          var match = Distribution$ReactHooksTemplate.customerSource(x);
                                          var match$1 = Distribution$ReactHooksTemplate.customerSource(x);
                                          var y = match$1[0];
                                          var match$2 = Distribution$ReactHooksTemplate.customerSource(x);
                                          var match$3 = Distribution$ReactHooksTemplate.status(x);
                                          var tmp = match$3 === "pending" ? React.createElement("input", {
                                                  className: "text-white text-sm bg-pigmentblue hover:bg-teal-800 py-1\n                                px-3 rounded w-80-px",
                                                  type: "button",
                                                  value: "Start",
                                                  onClick: (function (param) {
                                                      var x$1 = x;
                                                      var $$event = param;
                                                      $$event.preventDefault();
                                                      var distributionId = String(Distribution$ReactHooksTemplate.id(x$1));
                                                      var url = "/api/v1/distributions/" + (String(distributionId) + "/start");
                                                      Http$ReactHooksTemplate.fetchPutJSON(url, { }).then((function (response) {
                                                              var status = Json_decode.field("status", (function (param) {
                                                                      return Json_decode.withDefault("failed", Json_decode.string, param);
                                                                    }), response);
                                                              if (status === "success") {
                                                                var distribution = Json_decode.field("distribution", Distribution$ReactHooksTemplate.decode, response);
                                                                Curry._1(dispatch, /* FetchDistributionsCompleted */Block.__(0, [Belt_List.map(state[/* distributions */1], (function (y) {
                                                                                var match = Distribution$ReactHooksTemplate.id(distribution) === Distribution$ReactHooksTemplate.id(y);
                                                                                if (match) {
                                                                                  return distribution;
                                                                                } else {
                                                                                  return y;
                                                                                }
                                                                              }))]));
                                                                Curry._1(dispatch, /* AddNotification */Block.__(1, ["Successfully started\n         distribution"]));
                                                              } else {
                                                                Curry._1(dispatch, /* AddNotification */Block.__(1, ["Something went wrong. Please try\n         again later"]));
                                                              }
                                                              return Promise.resolve(/* () */0);
                                                            }));
                                                      return /* () */0;
                                                    })
                                                }) : React.createElement(React.Fragment, undefined);
                                          return React.createElement("tr", {
                                                      key: Distribution$ReactHooksTemplate.uid(x),
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, React.createElement("div", {
                                                              className: "flex items-center"
                                                            }, React.createElement("div", {
                                                                  className: "w-8 h-8 flex items-center text-white uppercase mr-3 rounded-lg " + randomBgColor(/* () */0)
                                                                }, React.createElement("div", {
                                                                      className: "m-auto"
                                                                    }, Distribution$ReactHooksTemplate.uid(x)[0])), Distribution$ReactHooksTemplate.uid(x))), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, React.createElement("div", {
                                                              className: "text-xs px-3 py-2 rounded-2xl flex items-center\n                                        border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                            }, Distribution$ReactHooksTemplate.customerSourceType(x))), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, React.createElement("div", {
                                                              className: "text-xs px-3 py-2 w-fit font-normal text-aurometalsaurus"
                                                            }, FeedFile$ReactHooksTemplate.name(match[0]))), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, React.createElement("div", {
                                                              className: "text-xs px-3 py-2 rounded-2xl flex gap-1 items-center\n                                            border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                            }, React.createElement("div", {
                                                                  className: "h-3 w-3 rounded-full " + bgSourceStatusColor(FeedFile$ReactHooksTemplate.status(y))
                                                                }), FeedFile$ReactHooksTemplate.status(y).toUpperCase())), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, String(FeedFile$ReactHooksTemplate.count(match$2[0]))), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, React.createElement("div", {
                                                              className: "text-xs px-3 py-2 rounded-2xl flex gap-1 items-center\n                                        border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                            }, React.createElement("div", {
                                                                  className: "h-3 w-3 rounded-full " + bgStatusColor(Distribution$ReactHooksTemplate.status(x))
                                                                }), Distribution$ReactHooksTemplate.status(x).toUpperCase())), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, React.createElement("div", {
                                                              className: "text-xs px-3 py-2 rounded-2xl flex items-center\n                                    border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                            }, Distribution$ReactHooksTemplate.createdAt(x).format("lll"))), React.createElement("td", {
                                                          className: "px-4 py-3"
                                                        }, tmp));
                                        }), state[/* distributions */1])))))), match$5 ? React.createElement("div", {
                      className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                      style: {
                        height: "calc(100vh + 5rem)"
                      }
                    }, React.createElement("div", {
                          className: "bg-white modal-campaign z-10 shadow relative border border-gray-300 pointer-events-auto"
                        }, React.createElement("div", {
                              className: "p-8 text-lg font-bold flex justify-between"
                            }, React.createElement("div", undefined, "Create New Distribution"), React.createElement("img", {
                                  className: "h-14-px float-right",
                                  src: Image$ReactHooksTemplate.closeIcon,
                                  onClick: (function (param) {
                                      return Curry._1(dispatch, /* ToggleDistributionCreate */2);
                                    })
                                })), React.createElement("div", {
                              className: "border-t border-gray-200 text-base font-medium h-432-px"
                            }, React.createElement(DistributionCreate$ReactHooksTemplate.make, {
                                  campaignId: campaignId,
                                  toggleDistributionCreate: toggleDistributionCreate
                                })))) : React.createElement(React.Fragment, undefined));
  }
}

var make = DistributionList;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */
