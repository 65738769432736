// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var HtmlToDraft = require("html-to-draft");
var ReactDraftWysiwyg = require("react-draft-wysiwyg");

var defaultHtml = "<p> hello </p>";

function Editor(Props) {
  HtmlToDraft.default(defaultHtml);
  return React.createElement("div", {
              className: "flex"
            }, React.createElement(ReactDraftWysiwyg.Editor, {
                  wrapperClassName: "w-1/2"
                }), React.createElement("div", {
                  className: "w-1/2"
                }, "Html Preview"));
}

var make = Editor;

exports.defaultHtml = defaultHtml;
exports.make = make;
/* react Not a pure module */
