// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function CsatPreview(Props) {
  var csat = Props.csat;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, Csat$ReactHooksTemplate.setText(csat, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, Csat$ReactHooksTemplate.setDescription(csat, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: Csat$ReactHooksTemplate.getTextTranslation(csat, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: Csat$ReactHooksTemplate.getDescriptionTranslation(csat, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", {
                  className: "flex w-full gap-8 items-start"
                }, $$Array.of_list(Belt_List.mapWithIndex(Csat$ReactHooksTemplate.answerTypeCollection(csat), (function (id, xs) {
                            var code = Csat$ReactHooksTemplate.answerTypeToUnicode(xs);
                            var match = Csat$ReactHooksTemplate.answered(csat);
                            var match$1 = Csat$ReactHooksTemplate.getAnswer(csat);
                            var match$2;
                            if (match && match$1 !== undefined) {
                              var match$3 = xs === match$1;
                              match$2 = match$3 ? /* tuple */[
                                  "text-6xl small:text-5xl",
                                  "pulse",
                                  "text-sm",
                                  "pt-0"
                                ] : /* tuple */[
                                  "text-5xl small:text-4xl",
                                  "",
                                  "text-xs",
                                  "pt-10-px"
                                ];
                            } else {
                              match$2 = /* tuple */[
                                "text-5xl small:text-4xl",
                                "",
                                "text-xs",
                                "pt-10-px"
                              ];
                            }
                            return React.createElement("button", {
                                        key: String(id),
                                        className: "animated " + (String(match$2[1]) + (" flex flex-col items-center\n               focus:outline-none " + (String(match$2[3]) + ""))),
                                        onClick: (function (param) {
                                            return Curry._1(onChange, Csat$ReactHooksTemplate.setAnswer(csat, xs));
                                          })
                                      }, React.createElement("span", {
                                            className: match$2[0]
                                          }, code), React.createElement("div", {
                                            className: match$2[2]
                                          }, Csat$ReactHooksTemplate.answerTypeToString(csat, xs, SurveyLanguage$ReactHooksTemplate.name(language))));
                          })))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = CsatPreview;

exports.make = make;
/* react Not a pure module */
