// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decodeChoice(json) {
  return /* tuple */[
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("choice", Json_decode.string, json)
        ];
}

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* choices */Json_decode.field("choices", (function (param) {
                  return Json_decode.list(decodeChoice, param);
                }), json)
        ];
}

function $eq(t, x) {
  var match = t[/* answer */6];
  if (match !== undefined) {
    return x === match;
  } else {
    return false;
  }
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* description */"",
          /* choices : :: */[
            /* tuple */[
              RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(/* () */0)),
              ""
            ],
            /* :: */[
              /* tuple */[
                RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(/* () */0)),
                ""
              ],
              /* [] */0
            ]
          ],
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function make(text, description, choices, uuid) {
  return /* record */[
          /* id */undefined,
          /* uuid */uuid,
          /* text */text,
          /* description */description,
          /* choices */choices,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function answerToString(t) {
  var match = t[/* answer */6];
  if (match !== undefined) {
    return match;
  } else {
    return "";
  }
}

function text(t) {
  return t[/* text */2];
}

function description(t) {
  return t[/* description */3];
}

function choices(t) {
  return t[/* choices */4];
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* translations */t[/* translations */5],
          /* answer */t[/* answer */6]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */description,
          /* choices */t[/* choices */4],
          /* translations */t[/* translations */5],
          /* answer */t[/* answer */6]
        ];
}

function setChoices(t, choices) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */choices,
          /* translations */t[/* translations */5],
          /* answer */t[/* answer */6]
        ];
}

function removeChoice(choiceId, choices, t) {
  var newSetOfChoices = Belt_List.keep(choices, (function (param) {
          return param[0] !== choiceId;
        }));
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */newSetOfChoices,
          /* translations */t[/* translations */5],
          /* answer */t[/* answer */6]
        ];
}

function setAnswer(t, answer) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* choices */t[/* choices */4],
          /* translations */t[/* translations */5],
          /* answer */answer
        ];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */5]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */5]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */3];
  }
}

function getChoicesTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */5]);
  if (translation !== undefined) {
    return translation[1][/* choices */2];
  } else {
    return t[/* choices */4];
  }
}

function answer(t) {
  return t[/* answer */6];
}

function answered(t) {
  return t[/* answer */6] !== undefined;
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function encodeChoice(param) {
  return /* :: */[
          /* tuple */[
            "id",
            param[0]
          ],
          /* :: */[
            /* tuple */[
              "choice",
              param[1]
            ],
            /* [] */0
          ]
        ];
}

function encodeChoices(choices) {
  return Json_encode.list(Json_encode.object_, List.map(encodeChoice, choices));
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */3]
      ],
      /* :: */[
        /* tuple */[
          "choices",
          encodeChoices(t[/* choices */4])
        ],
        /* [] */0
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  Belt_Option.getWithDefault(t[/* answer */6], "")
                ],
                encodeArray
              ] : encodeArray);
}

function decodeChoice$1(json) {
  return /* tuple */[
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("choice", Json_decode.string, json)
        ];
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* choices */Json_decode.field("category", (function (param) {
                  return Json_decode.field("choices", (function (param) {
                                return Json_decode.list(decodeChoice$1, param);
                              }), param);
                }), json),
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "MultipleChoice";

exports.categoryType = categoryType;
exports.empty = empty;
exports.text = text;
exports.description = description;
exports.choices = choices;
exports.make = make;
exports.id = id;
exports.uuid = uuid;
exports.answer = answer;
exports.answered = answered;
exports.setText = setText;
exports.getTextTranslation = getTextTranslation;
exports.setDescription = setDescription;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.setAnswer = setAnswer;
exports.setChoices = setChoices;
exports.removeChoice = removeChoice;
exports.getChoicesTranslation = getChoicesTranslation;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.$eq = $eq;
exports.answerToString = answerToString;
/* RandomId-ReactHooksTemplate Not a pure module */
