// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return /* record */[/* title */Json_decode.field("title", (function (x) {
                  return /* record */[/* text_color */Json_decode.field("text_color", Json_decode.string, x)];
                }), json)];
}

exports.fromJson = fromJson;
/* No side effect */
