// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ScoreCardSetting$ReactHooksTemplate = require("./scoreCardSetting.bs.js");
var ScoreCardChartAttribute$ReactHooksTemplate = require("./scoreCardChartAttribute.bs.js");

function ScoreCardComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var onClick = Props.onClick;
  var match = ScoreCardChartAttribute$ReactHooksTemplate.onClick(chartAttribute);
  var cursorStyle = typeof match === "number" ? "" : "cursor-pointer hover:border hover:border-1 hover:border-purple-800";
  var match$1 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var titleTextColor;
  if (match$1 !== undefined) {
    var setting = match$1;
    switch (setting.tag | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          titleTextColor = "text-white";
          break;
      case /* ScoreCardSetting */2 :
          titleTextColor = ScoreCardSetting$ReactHooksTemplate.titleTextColor(setting[0]);
          break;
      
    }
  } else {
    titleTextColor = "text-charcoal";
  }
  var match$2 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var background;
  if (match$2 !== undefined) {
    var setting$1 = match$2;
    switch (setting$1.tag | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          background = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          background = ScoreCardSetting$ReactHooksTemplate.background(setting$1[0]);
          break;
      
    }
  } else {
    background = "bg-aliceblue";
  }
  var match$3 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var boxBackground;
  if (match$3 !== undefined) {
    var setting$2 = match$3;
    switch (setting$2.tag | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          boxBackground = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          boxBackground = ScoreCardSetting$ReactHooksTemplate.boxBackground(setting$2[0]);
          break;
      
    }
  } else {
    boxBackground = "bg-verdigris";
  }
  var match$4 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var innerContainerBackground;
  if (match$4 !== undefined) {
    var setting$3 = match$4;
    switch (setting$3.tag | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          innerContainerBackground = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          innerContainerBackground = ScoreCardSetting$ReactHooksTemplate.innerContainerBackground(setting$3[0]);
          break;
      
    }
  } else {
    innerContainerBackground = "bg-azureishwhite";
  }
  var match$5 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var borderColor;
  if (match$5 !== undefined) {
    var setting$4 = match$5;
    switch (setting$4.tag | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          borderColor = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          borderColor = ScoreCardSetting$ReactHooksTemplate.innerContainerBackground(setting$4[0]);
          break;
      
    }
  } else {
    borderColor = "border-verdigris";
  }
  var formatter = function (value) {
    var match = Math.abs(value) > 999.0;
    if (match) {
      return (value / 1000.0).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  };
  return React.createElement("div", {
              className: "box rounded-lg h-154-px " + (String(background) + (" " + (String(cursorStyle) + ("\n    border " + (String(borderColor) + ""))))),
              onClick: (function (param) {
                  return Curry._3(onClick, Belt_Option.getWithDefault(Caml_array.caml_array_get(ScoreCardChartAttribute$ReactHooksTemplate.title(chartAttribute).split((/:/)), 0), ""), chartId, ScoreCardChartAttribute$ReactHooksTemplate.onClick(chartAttribute));
                })
            }, React.createElement("div", {
                  className: "animated fadeIn p-20-px pb-0\n      text-base font-semibold " + (String(titleTextColor) + " leading-none flex")
                }, React.createElement("div", {
                      className: "min-w-30-px h-30-px " + (String(boxBackground) + " flex rounded-lg mr-2")
                    }, React.createElement("div", {
                          className: "bg-white h-8-px w-8-px m-auto rounded-lg"
                        })), React.createElement("div", {
                      className: "flex"
                    }, React.createElement("div", {
                          className: "m-auto"
                        }, ScoreCardChartAttribute$ReactHooksTemplate.title(chartAttribute)))), React.createElement("div", {
                  className: "flex flex-wrap gap-4 px-4 animated fadeIn justify-evenly items-center"
                }, $$Array.of_list(Belt_List.map(ScoreCardChartAttribute$ReactHooksTemplate.data(chartAttribute), (function (param) {
                            var value = param[1];
                            var id = param[0];
                            var tmp;
                            if (value !== undefined) {
                              var value$1 = value;
                              tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: "font-semibold h-24-px text-xl leading-tight text-center\n                m-auto text-22-px mt-17-px tooltip"
                                      }, formatter(value$1), React.createElement("span", {
                                            className: "tooltiptext " + (String(boxBackground) + " text-base")
                                          }, value$1.toString().toLocaleUpperCase())));
                            } else {
                              tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: "font-semibold h-24-px text-xl leading-tight\n                text-center m-auto text-22-px mt-17-px"
                                      }, "--"));
                            }
                            return React.createElement("div", {
                                        key: id,
                                        className: "text-center " + (String(innerContainerBackground) + " w-110-px h-78-px rounded mt-2")
                                      }, tmp, React.createElement("div", {
                                            className: "text-xs pt-8-px font-normal leading-tight text-charcoal tracking-normal"
                                          }, id));
                          })))));
}

var make = ScoreCardComponent;

exports.make = make;
/* react Not a pure module */
