// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var CogSvg = require("../../../images/cog.svg");
var EditPng = require("../../../images/edit.png");
var EditSvg = require("../../../images/edit.svg");
var PlusSvg = require("../../../images/plus.svg");
var EmailSvg = require("../../../images/email.svg");
var MinusSvg = require("../../../images/minus.svg");
var PhoneSvg = require("../../../images/phone.svg");
var SharePng = require("../../../images/share.png");
var ShareSvg = require("../../../images/share.svg");
var TrashSvg = require("../../../images/trash.svg");
var AvatarSvg = require("../../../images/avatar.svg");
var ExpandSvg = require("../../../images/expand.svg");
var FilterSvg = require("../../../images/filter.svg");
var RatingSvg = require("../../../images/rating.svg");
var SearchSvg = require("../../../images/search.svg");
var SliderSvg = require("../../../images/slider.svg");
var SubmitSvg = require("../../../images/submit.svg");
var HeadingSvg = require("../../../images/heading.svg");
var PaletteSvg = require("../../../images/palette.svg");
var PreviewSvg = require("../../../images/preview.svg");
var ReportsSvg = require("../../../images/reports.svg");
var CalendarSvg = require("../../../images/calendar.svg");
var CommentsSvg = require("../../../images/comments.svg");
var DownloadSvg = require("../../../images/download.svg");
var HashDocSvg = require("../../../images/hash-doc.svg");
var MinimizeSvg = require("../../../images/minimize.svg");
var SettingsSvg = require("../../../images/settings.svg");
var ThankyouSvg = require("../../../images/thankyou.svg");
var DashboardSvg = require("../../../images/dashboard.svg");
var Fair_starSvg = require("../../../images/fair_star.svg");
var GeckolystPng = require("../../../images/geckolyst.png");
var Good_starSvg = require("../../../images/good_star.svg");
var LongTextSvg = require("../../../images/long-text.svg");
var Poor_starSvg = require("../../../images/poor_star.svg");
var ThankYouPng = require("../../../images/thank-you.png");
var UserPlusSvg = require("../../../images/user-plus.svg");
var ArrowLeftSvg = require("../../../images/arrow-left.svg");
var BackArrowSvg = require("../../../images/back-arrow.svg");
var CaretDownSvg = require("../../../images/caret-down.svg");
var ChevronUpSvg = require("../../../images/chevron-up.svg");
var Close_iconSvg = require("../../../images/close_icon.svg");
var Empty_starSvg = require("../../../images/empty_star.svg");
var Red_smileySvg = require("../../../images/red_smiley.svg");
var Sad_buttonSvg = require("../../../images/sad_button.svg");
var SandClockSvg = require("../../../images/sand-clock.svg");
var ShortTextSvg = require("../../../images/short-text.svg");
var ArrowRightSvg = require("../../../images/arrow-right.svg");
var DragHandleSvg = require("../../../images/drag-handle.svg");
var Fair_smileySvg = require("../../../images/fair_smiley.svg");
var Filled_starSvg = require("../../../images/filled_star.svg");
var Good_smileySvg = require("../../../images/good_smiley.svg");
var PlusCircleSvg = require("../../../images/plus-circle.svg");
var Poor_smileySvg = require("../../../images/poor_smiley.svg");
var PreviewEyeSvg = require("../../../images/preview-eye.svg");
var SearchGraySvg = require("../../../images/search-gray.svg");
var SurveySendSvg = require("../../../images/survey-send.svg");
var ChevronDownSvg = require("../../../images/chevron-down.svg");
var CircledPlusSvg = require("../../../images/circled-plus.svg");
var ClockCircleSvg = require("../../../images/clock-circle.svg");
var CommentIconSvg = require("../../../images/comment-icon.svg");
var CsvDownloadSvg = require("../../../images/csv-download.svg");
var CurvedArrowSvg = require("../../../images/curved-arrow.svg");
var DownloadBoxSvg = require("../../../images/download-box.svg");
var Green_smileySvg = require("../../../images/green_smiley.svg");
var Happy_buttonSvg = require("../../../images/happy_button.svg");
var Loading_listSvg = require("../../../images/loading_list.svg");
var Maybe_smileySvg = require("../../../images/maybe_smiley.svg");
var PngDownloadSvg = require("../../../images/png-download.svg");
var Vgood_smileySvg = require("../../../images/vgood_smiley.svg");
var YesbankLogoPng = require("../../../images/yesbank-logo.png");
var AgentSummarySvg = require("../../../images/agent-summary.svg");
var AssignTicketSvg = require("../../../images/assign-ticket.svg");
var DistributionsSvg = require("../../../images/distributions.svg");
var ExpandButtonSvg = require("../../../images/expand-button.svg");
var Filter_buttonSvg = require("../../../images/filter_button.svg");
var Likely_smileySvg = require("../../../images/likely_smiley.svg");
var OpinionScaleSvg = require("../../../images/opinion-scale.svg");
var SearchFilterSvg = require("../../../images/search-filter.svg");
var VerticalDotsSvg = require("../../../images/vertical-dots.svg");
var Bhima_thankyouSvg = require("../../../images/bhima_thankyou.svg");
var DashboardIconSvg = require("../../../images/dashboard-icon.svg");
var Neutral_buttonSvg = require("../../../images/neutral_button.svg");
var Very_good_starSvg = require("../../../images/very_good_star.svg");
var Very_poor_starSvg = require("../../../images/very_poor_star.svg");
var CogTransparentSvg = require("../../../images/cog-transparent.svg");
var Double_minimizeSvg = require("../../../images/double_minimize.svg");
var DownloadButtonSvg = require("../../../images/download-button.svg");
var Unlikely_smileySvg = require("../../../images/unlikely_smiley.svg");
var WorkOnTicketsSvg = require("../../../images/work-on-tickets.svg");
var DefaultQuestionSvg = require("../../../images/default-question.svg");
var Excellent_smileySvg = require("../../../images/excellent_smiley.svg");
var SidebarOverviewSvg = require("../../../images/sidebar-overview.svg");
var WelcomeThankyouSvg = require("../../../images/welcome-thankyou.svg");
var DashboardLoadingSvg = require("../../../images/dashboard-loading.svg");
var SidebarDashboardSvg = require("../../../images/sidebar-dashboard.svg");
var Survey_welcome_bgPng = require("../../../images/survey_welcome_bg.png");
var Yesbank_red_smileySvg = require("../../../images/yesbank_red_smiley.svg");
var Unauthorized_accessSvg = require("../../../images/unauthorized_access.svg");
var Fair_smiley_selectedSvg = require("../../../images/fair_smiley_selected.svg");
var Good_smiley_selectedSvg = require("../../../images/good_smiley_selected.svg");
var Poor_smiley_selectedSvg = require("../../../images/poor_smiley_selected.svg");
var RadioButtonCheckedSvg = require("../../../images/radio-button-checked.svg");
var Very_unlikely_smileySvg = require("../../../images/very_unlikely_smiley.svg");
var Yesbank_green_smileySvg = require("../../../images/yesbank_green_smiley.svg");
var Vgood_smiley_selectedSvg = require("../../../images/vgood_smiley_selected.svg");
var Error_information_iconSvg = require("../../../images/error_information_icon.svg");
var Extremely_likely_smileySvg = require("../../../images/extremely_likely_smiley.svg");
var Excellent_smiley_selectedSvg = require("../../../images/excellent_smiley_selected.svg");

var errorInfoIcon = Error_information_iconSvg;

var closeIcon = Close_iconSvg;

var geckolystLogo = GeckolystPng;

var cog = CogSvg;

var chevronDown = ChevronDownSvg;

var caretDown = CaretDownSvg;

var chevronUp = ChevronUpSvg;

var arrowLeft = ArrowLeftSvg;

var arrowRight = ArrowRightSvg;

var circledPlus = CircledPlusSvg;

var heading = HeadingSvg;

var yesbankLogo = YesbankLogoPng;

var surveyWelcomeBg = Survey_welcome_bgPng;

var surveyThankYouBg = ThankYouPng;

var surveyCompleted = YesbankLogoPng;

var surveySend = SurveySendSvg;

var filter = FilterSvg;

var filterButton = Filter_buttonSvg;

var plus = PlusSvg;

var dashboardLoading = DashboardLoadingSvg;

var sandClock = SandClockSvg;

var downloadButton = DownloadButtonSvg;

var download = DownloadSvg;

var pngDownloadButton = PngDownloadSvg;

var csvDownloadButton = CsvDownloadSvg;

var expandButton = ExpandButtonSvg;

var expand = ExpandSvg;

var minimize = MinimizeSvg;

var doubleMinimize = Double_minimizeSvg;

var dashboardIcon = DashboardIconSvg;

var loadingList = Loading_listSvg;

var unAuthorized = Unauthorized_accessSvg;

var happyGreenSmiley = Green_smileySvg;

var sadRedSmiley = Red_smileySvg;

var happyGreenSmileyYesbank = Yesbank_green_smileySvg;

var sadRedSmileyYesbank = Yesbank_red_smileySvg;

var extremelyLikelySmiley = Extremely_likely_smileySvg;

var likelySmiley = Likely_smileySvg;

var maybeSmiley = Maybe_smileySvg;

var unlikelySmiley = Unlikely_smileySvg;

var veryUnlikelySmiley = Very_unlikely_smileySvg;

var excellentSmiley = Excellent_smileySvg;

var vgoodSmiley = Vgood_smileySvg;

var goodSmiley = Good_smileySvg;

var fairSmiley = Fair_smileySvg;

var poorSmiley = Poor_smileySvg;

var excellentSmileySelected = Excellent_smiley_selectedSvg;

var vgoodSmileySelected = Vgood_smiley_selectedSvg;

var goodSmileySelected = Good_smiley_selectedSvg;

var fairSmileySelected = Fair_smiley_selectedSvg;

var poorSmileySelected = Poor_smiley_selectedSvg;

var vgoodStar = Very_good_starSvg;

var goodStar = Good_starSvg;

var fairStar = Fair_starSvg;

var poorStar = Poor_starSvg;

var vpoorStar = Very_poor_starSvg;

var emptyStar = Empty_starSvg;

var filledStar = Filled_starSvg;

var bhimaThankyou = Bhima_thankyouSvg;

var thankyou = ThankyouSvg;

var sidebarDashboard = SidebarDashboardSvg;

var sidebarOverview = SidebarOverviewSvg;

var workOnTickets = WorkOnTicketsSvg;

var agentSummary = AgentSummarySvg;

var backArrow = BackArrowSvg;

var clockCircle = ClockCircleSvg;

var assignTicket = AssignTicketSvg;

var comments = CommentsSvg;

var search = SearchSvg;

var searchFilter = SearchFilterSvg;

var avatar = AvatarSvg;

var email = EmailSvg;

var phone = PhoneSvg;

var submit = SubmitSvg;

var userPlus = UserPlusSvg;

var calendar = CalendarSvg;

var plusCircle = PlusCircleSvg;

var trash = TrashSvg;

var dragHandle = DragHandleSvg;

var settings = SettingsSvg;

var slider = SliderSvg;

var preview = PreviewSvg;

var verticalDots = VerticalDotsSvg;

var opinionScale = OpinionScaleSvg;

var welcomeThankyou = WelcomeThankyouSvg;

var shortText = ShortTextSvg;

var longText = LongTextSvg;

var defaultQuestion = DefaultQuestionSvg;

var hashDoc = HashDocSvg;

var radioButtonChecked = RadioButtonCheckedSvg;

var rating = RatingSvg;

var share = SharePng;

var searchGray = SearchGraySvg;

var distributions = DistributionsSvg;

var dashboard = DashboardSvg;

var reports = ReportsSvg;

var downloadBox = DownloadBoxSvg;

var cogTransparent = CogTransparentSvg;

var minus = MinusSvg;

var commentIcon = CommentIconSvg;

var curvedArrow = CurvedArrowSvg;

var palette = PaletteSvg;

var sadButton = Sad_buttonSvg;

var neutralButton = Neutral_buttonSvg;

var happyButton = Happy_buttonSvg;

var edit = EditSvg;

var previewEye = PreviewEyeSvg;

exports.errorInfoIcon = errorInfoIcon;
exports.closeIcon = closeIcon;
exports.geckolystLogo = geckolystLogo;
exports.cog = cog;
exports.chevronDown = chevronDown;
exports.caretDown = caretDown;
exports.chevronUp = chevronUp;
exports.arrowLeft = arrowLeft;
exports.arrowRight = arrowRight;
exports.circledPlus = circledPlus;
exports.heading = heading;
exports.yesbankLogo = yesbankLogo;
exports.surveyWelcomeBg = surveyWelcomeBg;
exports.surveyThankYouBg = surveyThankYouBg;
exports.surveyCompleted = surveyCompleted;
exports.surveySend = surveySend;
exports.filter = filter;
exports.filterButton = filterButton;
exports.plus = plus;
exports.dashboardLoading = dashboardLoading;
exports.sandClock = sandClock;
exports.downloadButton = downloadButton;
exports.download = download;
exports.pngDownloadButton = pngDownloadButton;
exports.csvDownloadButton = csvDownloadButton;
exports.expandButton = expandButton;
exports.expand = expand;
exports.minimize = minimize;
exports.doubleMinimize = doubleMinimize;
exports.dashboardIcon = dashboardIcon;
exports.loadingList = loadingList;
exports.unAuthorized = unAuthorized;
exports.happyGreenSmiley = happyGreenSmiley;
exports.sadRedSmiley = sadRedSmiley;
exports.happyGreenSmileyYesbank = happyGreenSmileyYesbank;
exports.sadRedSmileyYesbank = sadRedSmileyYesbank;
exports.extremelyLikelySmiley = extremelyLikelySmiley;
exports.likelySmiley = likelySmiley;
exports.maybeSmiley = maybeSmiley;
exports.unlikelySmiley = unlikelySmiley;
exports.veryUnlikelySmiley = veryUnlikelySmiley;
exports.excellentSmiley = excellentSmiley;
exports.vgoodSmiley = vgoodSmiley;
exports.goodSmiley = goodSmiley;
exports.fairSmiley = fairSmiley;
exports.poorSmiley = poorSmiley;
exports.excellentSmileySelected = excellentSmileySelected;
exports.vgoodSmileySelected = vgoodSmileySelected;
exports.goodSmileySelected = goodSmileySelected;
exports.fairSmileySelected = fairSmileySelected;
exports.poorSmileySelected = poorSmileySelected;
exports.vgoodStar = vgoodStar;
exports.goodStar = goodStar;
exports.fairStar = fairStar;
exports.poorStar = poorStar;
exports.vpoorStar = vpoorStar;
exports.emptyStar = emptyStar;
exports.filledStar = filledStar;
exports.bhimaThankyou = bhimaThankyou;
exports.thankyou = thankyou;
exports.sidebarDashboard = sidebarDashboard;
exports.sidebarOverview = sidebarOverview;
exports.workOnTickets = workOnTickets;
exports.agentSummary = agentSummary;
exports.backArrow = backArrow;
exports.clockCircle = clockCircle;
exports.assignTicket = assignTicket;
exports.comments = comments;
exports.search = search;
exports.searchFilter = searchFilter;
exports.avatar = avatar;
exports.email = email;
exports.phone = phone;
exports.submit = submit;
exports.userPlus = userPlus;
exports.calendar = calendar;
exports.plusCircle = plusCircle;
exports.trash = trash;
exports.dragHandle = dragHandle;
exports.settings = settings;
exports.slider = slider;
exports.preview = preview;
exports.verticalDots = verticalDots;
exports.opinionScale = opinionScale;
exports.welcomeThankyou = welcomeThankyou;
exports.shortText = shortText;
exports.longText = longText;
exports.defaultQuestion = defaultQuestion;
exports.hashDoc = hashDoc;
exports.radioButtonChecked = radioButtonChecked;
exports.rating = rating;
exports.share = share;
exports.searchGray = searchGray;
exports.distributions = distributions;
exports.dashboard = dashboard;
exports.reports = reports;
exports.downloadBox = downloadBox;
exports.cogTransparent = cogTransparent;
exports.minus = minus;
exports.commentIcon = commentIcon;
exports.curvedArrow = curvedArrow;
exports.palette = palette;
exports.sadButton = sadButton;
exports.neutralButton = neutralButton;
exports.happyButton = happyButton;
exports.edit = edit;
exports.previewEye = previewEye;
/* errorInfoIcon Not a pure module */
