// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function getInputValue(param) {
  return param[0];
}

function getValidationError(param) {
  switch (param.tag | 0) {
    case /* Invalid */1 :
        return param[1];
    case /* Valid */0 :
    case /* Pristine */2 :
        return /* [] */0;
    
  }
}

exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
/* No side effect */
