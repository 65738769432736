import React, { Component } from "react";
import proj4 from "./proj4.js";
import { render } from "react-dom";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import accessibility from "highcharts/modules/accessibility.js";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);
solidGauge(Highcharts);

class HighchartsGaugeBinding extends Component {
  render() {
    return React.createElement(
      "div",
      {
        className: "h-64",
      },
      React.createElement(HighchartsReact, {
        highcharts: Highcharts,
        options: {
          chart: {
            type: "solidgauge",
            height: "216",
          },
          title: { text: "" },
          exporting: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          pane: {
            center: ["50%", "50%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor: null,
              innerRadius: "60%",
              outerRadius: "100%",
              border: 0,
              shpe: "arc",
            },
          },
          yAxis: {
            stops: [
              [0.5, "#DF5353"],
              [0.75, "#DDDF0D"],
              [0.9, "#55BF3B"],
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
              text: "NPS",
              y: -70,
            },
            labels: {
              y: 16,
            },
            min: -100,
            max: 100,
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true,
              },
              lineWidth: 0,
            },
          },
          series: [
            {
              name: "NPS",
              data: [-10],
              dataLabels: {
                format:
                  '<div style="text-align:center">' +
                  '<span style="font-size:25px">{y}</span><br/>' +
                  '<span style="font-size:12px;opacity:0.4">NPS</span>' +
                  "</div>",
              },
            },
          ],
        },
      })
    );
  }
}

export default HighchartsGaugeBinding;
export { HighchartsGaugeBinding };
