// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var VisualizationSettings$ReactHooksTemplate = require("./visualizationSettings.bs.js");

function id(t) {
  return t[/* id */0];
}

function name(t) {
  return t[/* name */1];
}

function dashboardId(t) {
  return t[/* dashboardId */2];
}

function visualizationType(t) {
  return t[/* visualizationType */4];
}

function decodeItem(json) {
  var visualizationType = Json_decode.field("visualization_type", Json_decode.string, json);
  var match = visualizationType === "chart";
  var tmp;
  if (match) {
    tmp = /* Chart */0;
  } else {
    var match$1 = visualizationType === "map";
    if (match$1) {
      tmp = /* Map */2;
    } else {
      var match$2 = visualizationType === "score_card";
      tmp = match$2 ? /* ScoreCard */1 : /* Empty */4;
    }
  }
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* dashboardId */Json_decode.field("dashboard_id", Json_decode.$$int, json),
          /* settings */Json_decode.field("settings", (function (param) {
                  return Json_decode.withDefault(undefined, (function (param) {
                                return VisualizationSettings$ReactHooksTemplate.fromJson(visualizationType, param);
                              }), param);
                }), json),
          /* visualizationType */tmp
        ];
}

function fromJson(json) {
  return Json_decode.field("visualizations", (function (param) {
                return Json_decode.list(decodeItem, param);
              }), json);
}

exports.fromJson = fromJson;
exports.id = id;
exports.name = name;
exports.dashboardId = dashboardId;
exports.visualizationType = visualizationType;
/* No side effect */
