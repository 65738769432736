// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function ThankYouEditor(Props) {
  Props.thankYou;
  Props.onThankYouChange;
  return React.createElement("div", undefined);
}

var make = ThankYouEditor;

exports.make = make;
/* react Not a pure module */
