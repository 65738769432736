// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var NpsForm$ReactHooksTemplate = require("./NpsForm.bs.js");
var CsatForm$ReactHooksTemplate = require("./csatForm.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var NpsEditor$ReactHooksTemplate = require("./NpsEditor.bs.js");
var ShortText$ReactHooksTemplate = require("./ShortText.bs.js");
var CsatEditor$ReactHooksTemplate = require("./csatEditor.bs.js");
var NpsPreview$ReactHooksTemplate = require("./NpsPreview.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var CsatPreview$ReactHooksTemplate = require("./CsatPreview.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var DropDownForm$ReactHooksTemplate = require("./DropDownForm.bs.js");
var LongTextForm$ReactHooksTemplate = require("./LongTextForm.bs.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var ShortTextForm$ReactHooksTemplate = require("./ShortTextForm.bs.js");
var DropDownEditor$ReactHooksTemplate = require("./DropDownEditor.bs.js");
var LongTextEditor$ReactHooksTemplate = require("./LongTextEditor.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var StarRatingForm$ReactHooksTemplate = require("./StarRatingForm.bs.js");
var DropDownPreview$ReactHooksTemplate = require("./DropDownPreview.bs.js");
var LongTextPreview$ReactHooksTemplate = require("./LongTextPreview.bs.js");
var RatingGroupForm$ReactHooksTemplate = require("./RatingGroupForm.bs.js");
var ShortTextEditor$ReactHooksTemplate = require("./ShortTextEditor.bs.js");
var MobileNumberForm$ReactHooksTemplate = require("./MobileNumberForm.bs.js");
var OpinionScaleForm$ReactHooksTemplate = require("./OpinionScaleForm.bs.js");
var ShortTextPreview$ReactHooksTemplate = require("./ShortTextPreview.bs.js");
var SmileyRatingForm$ReactHooksTemplate = require("./SmileyRatingForm.bs.js");
var StarRatingEditor$ReactHooksTemplate = require("./StarRatingEditor.bs.js");
var RatingGroupEditor$ReactHooksTemplate = require("./RatingGroupEditor.bs.js");
var StarRatingPreview$ReactHooksTemplate = require("./StarRatingPreview.bs.js");
var MobileNumberEditor$ReactHooksTemplate = require("./MobileNumberEditor.bs.js");
var MultipleChoiceForm$ReactHooksTemplate = require("./MultipleChoiceForm.bs.js");
var MultipleSelectForm$ReactHooksTemplate = require("./MultipleSelectForm.bs.js");
var OpinionScaleEditor$ReactHooksTemplate = require("./OpinionScaleEditor.bs.js");
var RatingGroupPreview$ReactHooksTemplate = require("./RatingGroupPreview.bs.js");
var SmileyRatingEditor$ReactHooksTemplate = require("./SmileyRatingEditor.bs.js");
var MobileNumberPreview$ReactHooksTemplate = require("./MobileNumberPreview.bs.js");
var OpinionScalePreview$ReactHooksTemplate = require("./OpinionScalePreview.bs.js");
var SmileyRatingPreview$ReactHooksTemplate = require("./SmileyRatingPreview.bs.js");
var MultipleChoiceEditor$ReactHooksTemplate = require("./MultipleChoiceEditor.bs.js");
var MultipleSelectEditor$ReactHooksTemplate = require("./MultipleSelectEditor.bs.js");
var MultipleChoicePreview$ReactHooksTemplate = require("./MultipleChoicePreview.bs.js");
var MultipleSelectPreview$ReactHooksTemplate = require("./MultipleSelectPreview.bs.js");

function id(t) {
  return t[/* id */2];
}

function uuid(t) {
  var match = t[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.uuid(match[0]);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.uuid(match[0]);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.uuid(match[0]);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.uuid(match[0]);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.uuid(match[0]);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.uuid(match[0]);
    case /* RatingGroup */6 :
        return RatingGroup$ReactHooksTemplate.uuid(match[0]);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.uuid(match[0]);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.uuid(match[0]);
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.uuid(match[0]);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.uuid(match[0]);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.uuid(match[0]);
    
  }
}

function text(t) {
  var match = t[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.text(match[0]);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.text(match[0]);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.text(match[0]);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.text(match[0]);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.text(match[0]);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.text(match[0]);
    case /* RatingGroup */6 :
        return RatingGroup$ReactHooksTemplate.text(match[0]);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.text(match[0]);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.text(match[0]);
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.text(match[0]);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.text(match[0]);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.text(match[0]);
    
  }
}

function categoryType(t) {
  var match = t[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return "OpinionScale";
    case /* StarRating */1 :
        return "StarRating";
    case /* LongText */2 :
        return "LongText";
    case /* ShortText */3 :
        return "ShortText";
    case /* Nps */4 :
        return "Nps";
    case /* MultipleChoice */5 :
        return "MultipleChoice";
    case /* RatingGroup */6 :
        return "RatingGroup";
    case /* SmileyRating */7 :
        return "SmileyRating";
    case /* Csat */8 :
        return "Csat";
    case /* MultipleSelect */9 :
        return "MultipleSelect";
    case /* DropDown */10 :
        return "DropDown";
    case /* MobileNumber */11 :
        return "MobileNumber";
    
  }
}

function questionType(t) {
  return t[/* questionType */1];
}

function mandatory(t) {
  return t[/* mandatory */0];
}

function setQuestionType(t, questionType) {
  return /* record */[
          /* mandatory */t[/* mandatory */0],
          /* questionType */questionType,
          /* id */t[/* id */2]
        ];
}

function answered(t) {
  var match = t[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.answered(match[0]);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.answered(match[0]);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.answered(match[0]);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.answered(match[0]);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.answered(match[0]);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.answered(match[0]);
    case /* RatingGroup */6 :
        return RatingGroup$ReactHooksTemplate.answered(match[0], t[/* mandatory */0]);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.answered(match[0]);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.answered(match[0]);
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.answered(match[0]);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.answered(match[0]);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.answered(match[0]);
    
  }
}

function fromCategoryType(x) {
  var questionType = x === OpinionScale$ReactHooksTemplate.categoryType ? /* OpinionScale */Block.__(0, [OpinionScale$ReactHooksTemplate.empty(/* () */0)]) : (
      x === StarRating$ReactHooksTemplate.categoryType ? /* StarRating */Block.__(1, [StarRating$ReactHooksTemplate.empty(/* () */0)]) : (
          x === LongText$ReactHooksTemplate.categoryType ? /* LongText */Block.__(2, [LongText$ReactHooksTemplate.empty(/* () */0)]) : (
              x === ShortText$ReactHooksTemplate.categoryType ? /* ShortText */Block.__(3, [ShortText$ReactHooksTemplate.empty(/* () */0)]) : (
                  x === Nps$ReactHooksTemplate.categoryType ? /* Nps */Block.__(4, [Nps$ReactHooksTemplate.empty(/* () */0)]) : (
                      x === MultipleChoice$ReactHooksTemplate.categoryType ? /* MultipleChoice */Block.__(5, [MultipleChoice$ReactHooksTemplate.empty(/* () */0)]) : (
                          x === RatingGroup$ReactHooksTemplate.categoryType ? /* RatingGroup */Block.__(6, [RatingGroup$ReactHooksTemplate.empty(/* () */0)]) : (
                              x === SmileyRating$ReactHooksTemplate.categoryType ? /* SmileyRating */Block.__(7, [SmileyRating$ReactHooksTemplate.empty(/* () */0)]) : (
                                  x === Csat$ReactHooksTemplate.categoryType ? /* Csat */Block.__(8, [Csat$ReactHooksTemplate.empty(/* () */0)]) : (
                                      x === MultipleSelect$ReactHooksTemplate.categoryType ? /* MultipleSelect */Block.__(9, [MultipleSelect$ReactHooksTemplate.empty(/* () */0)]) : (
                                          x === DropDown$ReactHooksTemplate.categoryType ? /* DropDown */Block.__(10, [DropDown$ReactHooksTemplate.empty(/* () */0)]) : (
                                              x === MobileNumber$ReactHooksTemplate.categoryType ? /* MobileNumber */Block.__(11, [MobileNumber$ReactHooksTemplate.empty(/* () */0)]) : undefined
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  if (questionType !== undefined) {
    return /* record */[
            /* mandatory */false,
            /* questionType */questionType,
            /* id */undefined
          ];
  }
  
}

function toEditorBlock(f, question) {
  var match = question[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return React.createElement(OpinionScaleEditor$ReactHooksTemplate.make, {
                    opinionScale: match[0],
                    onOpinionScaleChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* OpinionScale */Block.__(0, [x])));
                      })
                  });
    case /* StarRating */1 :
        return React.createElement(StarRatingEditor$ReactHooksTemplate.make, {
                    starRating: match[0],
                    onStarRatingChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* StarRating */Block.__(1, [x])));
                      })
                  });
    case /* LongText */2 :
        return React.createElement(LongTextEditor$ReactHooksTemplate.make, {
                    longText: match[0],
                    onLongTextChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* LongText */Block.__(2, [x])));
                      })
                  });
    case /* ShortText */3 :
        return React.createElement(ShortTextEditor$ReactHooksTemplate.make, {
                    shortText: match[0],
                    onShortTextChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* ShortText */Block.__(3, [x])));
                      })
                  });
    case /* Nps */4 :
        return React.createElement(NpsEditor$ReactHooksTemplate.make, {
                    nps: match[0],
                    onNpsChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* Nps */Block.__(4, [x])));
                      })
                  });
    case /* MultipleChoice */5 :
        return React.createElement(MultipleChoiceEditor$ReactHooksTemplate.make, {
                    multipleChoice: match[0],
                    onMultipleChoiceChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MultipleChoice */Block.__(5, [x])));
                      })
                  });
    case /* RatingGroup */6 :
        return React.createElement(RatingGroupEditor$ReactHooksTemplate.make, {
                    ratingGroup: match[0],
                    onRatingGroupChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* RatingGroup */Block.__(6, [x])));
                      })
                  });
    case /* SmileyRating */7 :
        return React.createElement(SmileyRatingEditor$ReactHooksTemplate.make, {
                    smileyRating: match[0],
                    onSmileyRatingChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* SmileyRating */Block.__(7, [x])));
                      })
                  });
    case /* Csat */8 :
        return React.createElement(CsatEditor$ReactHooksTemplate.make, {
                    csat: match[0],
                    onCsatChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* Csat */Block.__(8, [x])));
                      })
                  });
    case /* MultipleSelect */9 :
        return React.createElement(MultipleSelectEditor$ReactHooksTemplate.make, {
                    multipleSelect: match[0],
                    onMultipleChoiceChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MultipleSelect */Block.__(9, [x])));
                      })
                  });
    case /* DropDown */10 :
        return React.createElement(DropDownEditor$ReactHooksTemplate.make, {
                    dropDown: match[0],
                    onDropDownChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* DropDown */Block.__(10, [x])));
                      })
                  });
    case /* MobileNumber */11 :
        return React.createElement(MobileNumberEditor$ReactHooksTemplate.make, {
                    mobileNumber: match[0],
                    onMobileNumberChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MobileNumber */Block.__(11, [x])));
                      })
                  });
    
  }
}

function equals(lhs, rhs) {
  return Caml_obj.caml_equal(uuid(lhs), uuid(rhs));
}

function toForm(f, customerInfo, surveyTheme, language, client, question) {
  var match = question[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return React.createElement(OpinionScaleForm$ReactHooksTemplate.make, {
                    opinionScale: match[0],
                    customerInfo: customerInfo,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* OpinionScale */Block.__(0, [x])));
                      })
                  });
    case /* StarRating */1 :
        return React.createElement(StarRatingForm$ReactHooksTemplate.make, {
                    starRating: match[0],
                    surveyTheme: surveyTheme,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    language: language,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* StarRating */Block.__(1, [x])));
                      })
                  });
    case /* LongText */2 :
        return React.createElement(LongTextForm$ReactHooksTemplate.make, {
                    longText: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* LongText */Block.__(2, [x])));
                      })
                  });
    case /* ShortText */3 :
        return React.createElement(ShortTextForm$ReactHooksTemplate.make, {
                    shortText: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* ShortText */Block.__(3, [x])));
                      })
                  });
    case /* Nps */4 :
        return React.createElement(NpsForm$ReactHooksTemplate.make, {
                    nps: match[0],
                    customerInfo: customerInfo,
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* Nps */Block.__(4, [x])));
                      })
                  });
    case /* MultipleChoice */5 :
        return React.createElement(MultipleChoiceForm$ReactHooksTemplate.make, {
                    multipleChoice: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MultipleChoice */Block.__(5, [x])));
                      })
                  });
    case /* RatingGroup */6 :
        return React.createElement(RatingGroupForm$ReactHooksTemplate.make, {
                    ratingGroup: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* RatingGroup */Block.__(6, [x])));
                      })
                  });
    case /* SmileyRating */7 :
        return React.createElement(SmileyRatingForm$ReactHooksTemplate.make, {
                    smileyRating: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* SmileyRating */Block.__(7, [x])));
                      })
                  });
    case /* Csat */8 :
        return React.createElement(CsatForm$ReactHooksTemplate.make, {
                    csat: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* Csat */Block.__(8, [x])));
                      })
                  });
    case /* MultipleSelect */9 :
        return React.createElement(MultipleSelectForm$ReactHooksTemplate.make, {
                    multipleSelect: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MultipleSelect */Block.__(9, [x])));
                      })
                  });
    case /* DropDown */10 :
        return React.createElement(DropDownForm$ReactHooksTemplate.make, {
                    dropDown: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* DropDown */Block.__(10, [x])));
                      })
                  });
    case /* MobileNumber */11 :
        return React.createElement(MobileNumberForm$ReactHooksTemplate.make, {
                    mobileNumber: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    client: client,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MobileNumber */Block.__(11, [x])));
                      })
                  });
    
  }
}

function preview(f, customerInfo, surveyTheme, language, question) {
  var match = question[/* questionType */1];
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        return React.createElement(OpinionScalePreview$ReactHooksTemplate.make, {
                    opinionScale: match[0],
                    surveyTheme: surveyTheme,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* OpinionScale */Block.__(0, [x])));
                      })
                  });
    case /* StarRating */1 :
        return React.createElement(StarRatingPreview$ReactHooksTemplate.make, {
                    starRating: match[0],
                    surveyTheme: surveyTheme,
                    mandatory: question[/* mandatory */0],
                    language: language,
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* StarRating */Block.__(1, [x])));
                      })
                  });
    case /* LongText */2 :
        return React.createElement(LongTextPreview$ReactHooksTemplate.make, {
                    longText: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* LongText */Block.__(2, [x])));
                      })
                  });
    case /* ShortText */3 :
        return React.createElement(ShortTextPreview$ReactHooksTemplate.make, {
                    shortText: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* ShortText */Block.__(3, [x])));
                      })
                  });
    case /* Nps */4 :
        return React.createElement(NpsPreview$ReactHooksTemplate.make, {
                    nps: match[0],
                    customerInfo: customerInfo,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* Nps */Block.__(4, [x])));
                      })
                  });
    case /* MultipleChoice */5 :
        return React.createElement(MultipleChoicePreview$ReactHooksTemplate.make, {
                    multipleChoice: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MultipleChoice */Block.__(5, [x])));
                      })
                  });
    case /* RatingGroup */6 :
        return React.createElement(RatingGroupPreview$ReactHooksTemplate.make, {
                    ratingGroup: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* RatingGroup */Block.__(6, [x])));
                      })
                  });
    case /* SmileyRating */7 :
        return React.createElement(SmileyRatingPreview$ReactHooksTemplate.make, {
                    smileyRating: match[0],
                    surveyTheme: surveyTheme,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* SmileyRating */Block.__(7, [x])));
                      })
                  });
    case /* Csat */8 :
        return React.createElement(CsatPreview$ReactHooksTemplate.make, {
                    csat: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* Csat */Block.__(8, [x])));
                      })
                  });
    case /* MultipleSelect */9 :
        return React.createElement(MultipleSelectPreview$ReactHooksTemplate.make, {
                    multipleSelect: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MultipleSelect */Block.__(9, [x])));
                      })
                  });
    case /* DropDown */10 :
        return React.createElement(DropDownPreview$ReactHooksTemplate.make, {
                    dropDown: match[0],
                    surveyTheme: surveyTheme,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* DropDown */Block.__(10, [x])));
                      })
                  });
    case /* MobileNumber */11 :
        return React.createElement(MobileNumberPreview$ReactHooksTemplate.make, {
                    mobileNumber: match[0],
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: question[/* mandatory */0],
                    onChange: (function (x) {
                        return Curry._1(f, setQuestionType(question, /* MobileNumber */Block.__(11, [x])));
                      })
                  });
    
  }
}

function toJson($staropt$star, position, $staropt$star$1, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var destroy = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var match = t[/* questionType */1];
  var questionTypeAttributes;
  switch (match.tag | 0) {
    case /* OpinionScale */0 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            OpinionScale$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              OpinionScale$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* StarRating */1 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            StarRating$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              StarRating$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* LongText */2 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            LongText$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              LongText$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* ShortText */3 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            ShortText$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              ShortText$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* Nps */4 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            Nps$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              Nps$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* MultipleChoice */5 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            MultipleChoice$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              MultipleChoice$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* RatingGroup */6 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            RatingGroup$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              RatingGroup$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* SmileyRating */7 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            SmileyRating$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              SmileyRating$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* Csat */8 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            Csat$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              Csat$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* MultipleSelect */9 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            MultipleSelect$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              MultipleSelect$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* DropDown */10 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            DropDown$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              DropDown$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    case /* MobileNumber */11 :
        questionTypeAttributes = /* :: */[
          /* tuple */[
            "category_type",
            MobileNumber$ReactHooksTemplate.categoryType
          ],
          /* :: */[
            /* tuple */[
              "category_attributes",
              MobileNumber$ReactHooksTemplate.toJson(withAnswer, match[0])
            ],
            /* [] */0
          ]
        ];
        break;
    
  }
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "mandatory",
                t[/* mandatory */0]
              ],
              /* :: */[
                /* tuple */[
                  "uid",
                  RandomId$ReactHooksTemplate.toJson(uuid(t))
                ],
                /* :: */[
                  /* tuple */[
                    "id",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), t[/* id */2])
                  ],
                  /* :: */[
                    /* tuple */[
                      "position",
                      position
                    ],
                    /* :: */[
                      /* tuple */[
                        "_destroy",
                        destroy
                      ],
                      questionTypeAttributes
                    ]
                  ]
                ]
              ]
            ]);
}

function decode(json) {
  var categoryType = Json_decode.field("category_type", Json_decode.string, json);
  var questionType = categoryType === OpinionScale$ReactHooksTemplate.categoryType ? /* OpinionScale */Block.__(0, [OpinionScale$ReactHooksTemplate.fromJson(json)]) : (
      categoryType === StarRating$ReactHooksTemplate.categoryType ? /* StarRating */Block.__(1, [StarRating$ReactHooksTemplate.fromJson(json)]) : (
          categoryType === LongText$ReactHooksTemplate.categoryType ? /* LongText */Block.__(2, [LongText$ReactHooksTemplate.fromJson(json)]) : (
              categoryType === ShortText$ReactHooksTemplate.categoryType ? /* ShortText */Block.__(3, [ShortText$ReactHooksTemplate.fromJson(json)]) : (
                  categoryType === Nps$ReactHooksTemplate.categoryType ? /* Nps */Block.__(4, [Nps$ReactHooksTemplate.fromJson(json)]) : (
                      categoryType === MultipleChoice$ReactHooksTemplate.categoryType ? /* MultipleChoice */Block.__(5, [MultipleChoice$ReactHooksTemplate.fromJson(json)]) : (
                          categoryType === RatingGroup$ReactHooksTemplate.categoryType ? /* RatingGroup */Block.__(6, [RatingGroup$ReactHooksTemplate.fromJson(json)]) : (
                              categoryType === SmileyRating$ReactHooksTemplate.categoryType ? /* SmileyRating */Block.__(7, [SmileyRating$ReactHooksTemplate.fromJson(json)]) : (
                                  categoryType === Csat$ReactHooksTemplate.categoryType ? /* Csat */Block.__(8, [Csat$ReactHooksTemplate.fromJson(json)]) : (
                                      categoryType === MultipleSelect$ReactHooksTemplate.categoryType ? /* MultipleSelect */Block.__(9, [MultipleSelect$ReactHooksTemplate.fromJson(json)]) : (
                                          categoryType === DropDown$ReactHooksTemplate.categoryType ? /* DropDown */Block.__(10, [DropDown$ReactHooksTemplate.fromJson(json)]) : (
                                              categoryType === MobileNumber$ReactHooksTemplate.categoryType ? /* MobileNumber */Block.__(11, [MobileNumber$ReactHooksTemplate.fromJson(json)]) : undefined
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  if (questionType !== undefined) {
    return /* record */[
            /* mandatory */Json_decode.field("mandatory", Json_decode.bool, json),
            /* questionType */questionType,
            /* id */Json_decode.optional((function (param) {
                    return Json_decode.field("id", Json_decode.$$int, param);
                  }), json)
          ];
  }
  
}

function decodeQuestionPosition(json) {
  return /* tuple */[
          Json_decode.field("position", Json_decode.$$int, json),
          decode(json)
        ];
}

function fromJson(json) {
  var questions = Belt_List.map(Belt_List.keep(Belt_List.map(Belt_List.sort(Json_decode.field("questions", (function (param) {
                          return Json_decode.list(decodeQuestionPosition, param);
                        }), json), (function (param, param$1) {
                      return param[0] - param$1[0] | 0;
                    })), (function (param) {
                  return param[1];
                })), Belt_Option.isSome), Belt_Option.getExn);
  var decodeResponses = function (param) {
    var questions$1 = questions;
    var json = param;
    var questionId = Json_decode.field("question_uid", RandomId$ReactHooksTemplate.fromJson, json);
    var question = List.find((function (x) {
            return Caml_obj.caml_equal(uuid(x), questionId);
          }), questions$1);
    var match = question[/* questionType */1];
    switch (match.tag | 0) {
      case /* OpinionScale */0 :
          var rating = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), json)) | 0;
          var opinionScale = OpinionScale$ReactHooksTemplate.setAnswer(match[0], rating);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : OpinionScale */Block.__(0, [opinionScale]),
                  /* id */question[/* id */2]
                ];
      case /* StarRating */1 :
          var rating$1 = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), json)) | 0;
          var starRating = StarRating$ReactHooksTemplate.setAnswer(match[0], StarRating$ReactHooksTemplate.intToRating(rating$1));
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : StarRating */Block.__(1, [starRating]),
                  /* id */question[/* id */2]
                ];
      case /* LongText */2 :
          var answer = Json_decode.field("response", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json);
          var longText = LongText$ReactHooksTemplate.setAnswer(match[0], answer);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : LongText */Block.__(2, [longText]),
                  /* id */question[/* id */2]
                ];
      case /* ShortText */3 :
          var answer$1 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json);
          var shortText = ShortText$ReactHooksTemplate.setAnswer(match[0], answer$1);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : ShortText */Block.__(3, [shortText]),
                  /* id */question[/* id */2]
                ];
      case /* Nps */4 :
          var rating$2 = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), json)) | 0;
          var nps = Nps$ReactHooksTemplate.setAnswer(match[0], rating$2);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : Nps */Block.__(4, [nps]),
                  /* id */question[/* id */2]
                ];
      case /* MultipleChoice */5 :
          var choice = Json_decode.field("response", (function (param) {
                  return Json_decode.field("choice", Json_decode.string, param);
                }), json);
          var multipleChoice = MultipleChoice$ReactHooksTemplate.setAnswer(match[0], choice);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : MultipleChoice */Block.__(5, [multipleChoice]),
                  /* id */question[/* id */2]
                ];
      case /* RatingGroup */6 :
          var decodeSelections = function (json) {
            return /* tuple */[
                    Json_decode.field("id", Json_decode.string, json),
                    Json_decode.field("value", Json_decode.$$int, json)
                  ];
          };
          var answer$2 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("answer", (function (param) {
                                return Json_decode.list(decodeSelections, param);
                              }), param);
                }), json);
          var ratingGroup = List.fold_left(RatingGroup$ReactHooksTemplate.setAnswer, match[0], answer$2);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : RatingGroup */Block.__(6, [ratingGroup]),
                  /* id */question[/* id */2]
                ];
      case /* SmileyRating */7 :
          var smileyRating = match[0];
          var rating$3 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("rating", Json_decode.$$int, param);
                }), json);
          var ratingType = List.find((function (x) {
                  return SmileyRating$ReactHooksTemplate.ratingTypeToRating(x) === rating$3;
                }), SmileyRating$ReactHooksTemplate.ratingTypeCollection(smileyRating));
          var smileyRating$1 = SmileyRating$ReactHooksTemplate.setAnswer(smileyRating, ratingType);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : SmileyRating */Block.__(7, [smileyRating$1]),
                  /* id */question[/* id */2]
                ];
      case /* Csat */8 :
          var rating$4 = Caml_format.caml_float_of_string(Json_decode.field("response", (function (param) {
                      return Json_decode.field("rating", Json_decode.string, param);
                    }), json)) | 0;
          var csat = Csat$ReactHooksTemplate.setAnswer(match[0], Csat$ReactHooksTemplate.ratingToAnswerType(rating$4));
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : Csat */Block.__(8, [csat]),
                  /* id */question[/* id */2]
                ];
      case /* MultipleSelect */9 :
          var decodeSelections$1 = function (json) {
            return /* record */[
                    /* id */Json_decode.field("id", RandomId$ReactHooksTemplate.fromJson, json),
                    /* text */Json_decode.field("label", Json_decode.string, json),
                    /* allowNonSpecifiedText */false,
                    /* notSpecifiedText */undefined
                  ];
          };
          var answer$3 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("answer", (function (param) {
                                return Json_decode.list(decodeSelections$1, param);
                              }), param);
                }), json);
          var multipleSelect = List.fold_left(MultipleSelect$ReactHooksTemplate.setAnswer, match[0], answer$3);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : MultipleSelect */Block.__(9, [multipleSelect]),
                  /* id */question[/* id */2]
                ];
      case /* DropDown */10 :
          var decodeSelections$2 = function (json) {
            return /* record */[
                    /* id */Json_decode.field("id", RandomId$ReactHooksTemplate.fromJson, json),
                    /* text */Json_decode.field("label", Json_decode.string, json),
                    /* allowNonSpecifiedText */false,
                    /* notSpecifiedText */undefined
                  ];
          };
          var answer$4 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("answer", (function (param) {
                                return Json_decode.list(decodeSelections$2, param);
                              }), param);
                }), json);
          var dropDown = List.fold_left(DropDown$ReactHooksTemplate.setAnswer, match[0], answer$4);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : DropDown */Block.__(10, [dropDown]),
                  /* id */question[/* id */2]
                ];
      case /* MobileNumber */11 :
          var answer$5 = Json_decode.field("response", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json);
          var mobileNumber = MobileNumber$ReactHooksTemplate.setAnswer(match[0], answer$5);
          return /* record */[
                  /* mandatory */question[/* mandatory */0],
                  /* questionType : MobileNumber */Block.__(11, [mobileNumber]),
                  /* id */question[/* id */2]
                ];
      
    }
  };
  var respondedQuestions = Json_decode.withDefault(/* [] */0, (function (param) {
          return Json_decode.field("responses", (function (param) {
                        return Json_decode.list(decodeResponses, param);
                      }), param);
        }), json);
  return List.map((function (q) {
                var match = List.find_opt((function (x) {
                        return Caml_obj.caml_equal(x[/* id */2], q[/* id */2]);
                      }), respondedQuestions);
                if (match !== undefined) {
                  return match;
                } else {
                  return q;
                }
              }), questions);
}

exports.toEditorBlock = toEditorBlock;
exports.toForm = toForm;
exports.preview = preview;
exports.id = id;
exports.uuid = uuid;
exports.text = text;
exports.categoryType = categoryType;
exports.questionType = questionType;
exports.mandatory = mandatory;
exports.setQuestionType = setQuestionType;
exports.fromCategoryType = fromCategoryType;
exports.fromJson = fromJson;
exports.decode = decode;
exports.toJson = toJson;
exports.equals = equals;
exports.answered = answered;
/* react Not a pure module */
