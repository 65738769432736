// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Role$ReactHooksTemplate = require("./role.bs.js");

function RoleListItem(Props) {
  var role = Props.role;
  var capabilities = Role$ReactHooksTemplate.enabledCapabilities(role);
  return React.createElement("tr", {
              key: String(Role$ReactHooksTemplate.id(role)),
              className: "hover:bg-gray-200 hover:shadow-md border-b"
            }, React.createElement("td", {
                  className: "py-10-px pl-4 w-1/6"
                }, Role$ReactHooksTemplate.name(role)), React.createElement("td", {
                  className: "py-10-px pl-4 w-1/6"
                }, Role$ReactHooksTemplate.description(role)), React.createElement("td", {
                  className: "flex flex-wrap py-10-px pl-4 w-3/6"
                }, $$Array.of_list(List.map((function (capability) {
                            return React.createElement("p", {
                                        key: capability,
                                        className: "capitalize px-10-px py-3-px bg-gray-200 m-1\n            hover:bg-white"
                                      }, capability);
                          }), capabilities))), React.createElement("td", {
                  className: "py-10-px w-1/6"
                }, (function (param) {
                      return param.fromNow(false);
                    })(Role$ReactHooksTemplate.createdAt(role))));
}

var make = RoleListItem;

exports.make = make;
/* react Not a pure module */
