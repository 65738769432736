// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var MapAttribute$ReactHooksTemplate = require("./mapAttribute.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");
var ScoreCardChartAttribute$ReactHooksTemplate = require("./scoreCardChartAttribute.bs.js");

function id(t) {
  return t[/* id */0];
}

function title(t) {
  return t[/* title */2];
}

function type_(t) {
  return t[/* type_ */1];
}

function decodeItem(json) {
  var category = Json_decode.either((function (param) {
            return Json_decode.field("chart_type", Json_decode.string, param);
          }), (function (param) {
            return Json_decode.field("visualization_type", Json_decode.string, param);
          }))(json);
  var match = category === "radial_bar";
  var tmp;
  if (match) {
    tmp = /* RadialBarChart */Block.__(4, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]);
  } else {
    var match$1 = category === "map";
    if (match$1) {
      tmp = /* Map */Block.__(8, [MapAttribute$ReactHooksTemplate.fromJson(json)]);
    } else {
      var match$2 = category === "table";
      if (match$2) {
        tmp = /* Table */Block.__(9, [TableAttribute$ReactHooksTemplate.fromJson(json)]);
      } else {
        var match$3 = category === "line";
        if (match$3) {
          tmp = /* LineChart */Block.__(0, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]);
        } else {
          var match$4 = category === "pie";
          if (match$4) {
            tmp = /* PieChart */Block.__(2, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]);
          } else {
            var match$5 = category === "area";
            if (match$5) {
              tmp = /* AreaChart */Block.__(1, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]);
            } else {
              var match$6 = category === "bar";
              if (match$6) {
                tmp = /* BarChart */Block.__(3, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]);
              } else {
                var match$7 = category === "wordcloud";
                if (match$7) {
                  tmp = /* Wordcloud */Block.__(5, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]);
                } else {
                  var match$8 = category === "score_card";
                  if (match$8) {
                    tmp = /* ScoreCard */Block.__(7, [ScoreCardChartAttribute$ReactHooksTemplate.fromJson(json)]);
                  } else {
                    var match$9 = category === "composed";
                    tmp = match$9 ? /* ComposedChart */Block.__(6, [GenericChartAttribute$ReactHooksTemplate.fromJson(json)]) : /* EmptyChart */0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* type_ */tmp,
          /* title */Json_decode.field("title", Json_decode.string, json)
        ];
}

function fromJson(json) {
  return Json_decode.field("visualization", decodeItem, json);
}

exports.fromJson = fromJson;
exports.title = title;
exports.type_ = type_;
exports.id = id;
/* GenericChartAttribute-ReactHooksTemplate Not a pure module */
