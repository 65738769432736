// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var RatingSmileySet$ReactHooksTemplate = require("./ratingSmileySet.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function SmileyRatingPreview(Props) {
  var smileyRating = Props.smileyRating;
  var surveyTheme = Props.surveyTheme;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var style = function (selected) {
    if (selected) {
      return {
              borderColor: SurveyTheme$ReactHooksTemplate.nextButtonColor(surveyTheme),
              borderWidth: "1px",
              outline: "None"
            };
    } else {
      return {
              outline: "None"
            };
    }
  };
  var smileyButton = function (ratingType, ratingSmileySet, selected) {
    var match = SmileyRating$ReactHooksTemplate.showLabels(smileyRating);
    return React.createElement("button", {
                key: String(SmileyRating$ReactHooksTemplate.ratingTypeToRating(ratingType)),
                className: "animated bounceIn flex flex-col items-center p-2 gap-1",
                style: style(selected),
                onClick: (function (e) {
                    e.preventDefault();
                    return Curry._1(onChange, SmileyRating$ReactHooksTemplate.setAnswer(smileyRating, ratingType));
                  })
              }, React.createElement("span", {
                    className: "text-5xl small:text-4xl"
                  }, React.createElement("img", {
                        src: SmileyRating$ReactHooksTemplate.ratingTypeToImage(ratingType, ratingSmileySet)
                      })), match ? React.createElement("div", {
                      className: "text-xs"
                    }, SmileyRating$ReactHooksTemplate.ratingTypeToString(ratingType)) : React.createElement(React.Fragment, undefined));
  };
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, SmileyRating$ReactHooksTemplate.setText(smileyRating, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, SmileyRating$ReactHooksTemplate.setDescription(smileyRating, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var match = SmileyRating$ReactHooksTemplate.ratingSmileySet(smileyRating);
  var tmp;
  if (match !== undefined) {
    var match$1 = SmileyRating$ReactHooksTemplate.ratingSmileySet(smileyRating);
    var tmp$1;
    if (match$1 !== undefined) {
      var ratingSmileySet = match$1;
      tmp$1 = $$Array.of_list(Belt_List.map(SmileyRating$ReactHooksTemplate.ratingTypeCollection(smileyRating), (function (xs) {
                  var match = SmileyRating$ReactHooksTemplate.answer(smileyRating);
                  var match$1 = RatingSmileySet$ReactHooksTemplate.selectedStateRatingSmileySet(ratingSmileySet);
                  if (match !== undefined) {
                    var ratingType = match;
                    if (match$1 !== undefined) {
                      var match$2 = xs === ratingType;
                      return smileyButton(xs, match$2 ? match$1 : ratingSmileySet, false);
                    } else {
                      return smileyButton(xs, ratingSmileySet, xs === ratingType);
                    }
                  } else {
                    return smileyButton(xs, ratingSmileySet, false);
                  }
                })));
    } else {
      tmp$1 = React.createElement(React.Fragment, undefined);
    }
    tmp = React.createElement("div", {
          className: "w-full p-16"
        }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
              text: SmileyRating$ReactHooksTemplate.text(smileyRating),
              description: SmileyRating$ReactHooksTemplate.description(smileyRating),
              mandatory: mandatory,
              surveyTheme: surveyTheme,
              onTitleChange: onTitleChange,
              onDescriptionChange: onDescriptionChange
            }), React.createElement("div", {
              className: "flex w-full justify-between pb-5"
            }, tmp$1), React.createElement("div", undefined, mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, { }) : React.createElement(React.Fragment, undefined)));
  } else {
    tmp = React.createElement("p", undefined, "Loading...");
  }
  return React.createElement("div", undefined, tmp);
}

var make = SmileyRatingPreview;

exports.make = make;
/* react Not a pure module */
