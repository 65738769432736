// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Kpi$ReactHooksTemplate = require("./kpi.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var KpiCrud$ReactHooksTemplate = require("./KpiCrud.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var UserKpiListHeader$ReactHooksTemplate = require("./UserKpiListHeader.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    return /* record */[
            /* kpis */state[/* kpis */0],
            /* selectedKpi */state[/* selectedKpi */1],
            /* kpiChangeModalToggle */!state[/* kpiChangeModalToggle */2],
            /* action */state[/* action */3],
            /* outsideClick */state[/* outsideClick */4]
          ];
  } else {
    switch (action.tag | 0) {
      case /* LoadKpis */0 :
          var kpis = action[0];
          if (kpis !== undefined) {
            return /* record */[
                    /* kpis */kpis,
                    /* selectedKpi */state[/* selectedKpi */1],
                    /* kpiChangeModalToggle */state[/* kpiChangeModalToggle */2],
                    /* action */state[/* action */3],
                    /* outsideClick */state[/* outsideClick */4]
                  ];
          } else {
            return /* record */[
                    /* kpis */undefined,
                    /* selectedKpi */state[/* selectedKpi */1],
                    /* kpiChangeModalToggle */state[/* kpiChangeModalToggle */2],
                    /* action */state[/* action */3],
                    /* outsideClick */state[/* outsideClick */4]
                  ];
          }
      case /* SetKpi */1 :
          var kpi = action[0];
          if (kpi !== undefined) {
            return /* record */[
                    /* kpis */state[/* kpis */0],
                    /* selectedKpi */kpi,
                    /* kpiChangeModalToggle */state[/* kpiChangeModalToggle */2],
                    /* action */state[/* action */3],
                    /* outsideClick */state[/* outsideClick */4]
                  ];
          } else {
            return /* record */[
                    /* kpis */state[/* kpis */0],
                    /* selectedKpi */undefined,
                    /* kpiChangeModalToggle */state[/* kpiChangeModalToggle */2],
                    /* action */state[/* action */3],
                    /* outsideClick */state[/* outsideClick */4]
                  ];
          }
      case /* SetAction */2 :
          return /* record */[
                  /* kpis */state[/* kpis */0],
                  /* selectedKpi */state[/* selectedKpi */1],
                  /* kpiChangeModalToggle */state[/* kpiChangeModalToggle */2],
                  /* action */action[0],
                  /* outsideClick */state[/* outsideClick */4]
                ];
      case /* SetKpiChangeModal */3 :
          return /* record */[
                  /* kpis */state[/* kpis */0],
                  /* selectedKpi */state[/* selectedKpi */1],
                  /* kpiChangeModalToggle */action[0],
                  /* action */state[/* action */3],
                  /* outsideClick */state[/* outsideClick */4]
                ];
      case /* SetOutsideClick */4 :
          return /* record */[
                  /* kpis */state[/* kpis */0],
                  /* selectedKpi */state[/* selectedKpi */1],
                  /* kpiChangeModalToggle */state[/* kpiChangeModalToggle */2],
                  /* action */state[/* action */3],
                  /* outsideClick */action[0]
                ];
      
    }
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* perPage */"1",
  /* currentPage */"1"
];

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var initialState = /* record */[
  /* kpis */undefined,
  /* selectedKpi */undefined,
  /* kpiChangeModalToggle */false,
  /* action */"",
  /* outsideClick */true
];

function UserKpiManagement(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var queryParams = Querystringify.parse(search);
  var match$1 = Js_dict.get(queryParams, "page");
  var currentPage;
  if (match$1 !== undefined) {
    var x = match$1;
    if (x === "") {
      currentPage = 1;
    } else {
      var page = Belt_Int.fromString(x);
      currentPage = page !== undefined ? page : 1;
    }
  } else {
    currentPage = 1;
  }
  var match$2 = React.useState((function () {
          return emptyPageInfo;
        }));
  var setPageInfo = match$2[1];
  var pageInfo = match$2[0];
  var fetchUsers = function (param) {
    var pageNumber = pageInfo[/* currentPage */2];
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("/api/v1/kpis?page=" + (String(pageNumber) + "")).then((function (param) {
                  var headers = param[0];
                  var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
                  var perPage$1 = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
                  var kpis = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("kpis", (function (param) {
                                            return Json_decode.list(Kpi$ReactHooksTemplate.decode, param);
                                          }), json));
                        }));
                  return Promise.resolve(/* tuple */[
                              kpis,
                              /* tuple */[
                                total,
                                perPage$1
                              ]
                            ]);
                }));
  };
  var buildSurveyFormDict = function (kpi) {
    var params = { };
    params["user_id"] = Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(kpi));
    params["field"] = Kpi$ReactHooksTemplate.filterField(kpi);
    params["field_id"] = Kpi$ReactHooksTemplate.filterFieldId(kpi);
    params["value"] = Kpi$ReactHooksTemplate.filterValue(kpi);
    params["kpi"] = Kpi$ReactHooksTemplate.kpiValue(kpi);
    return params;
  };
  var buildTargetDict = function (kpi) {
    var params = { };
    params["field"] = Kpi$ReactHooksTemplate.filterField(kpi);
    params["field_id"] = Kpi$ReactHooksTemplate.filterFieldId(kpi);
    params["value"] = Kpi$ReactHooksTemplate.filterValue(kpi);
    params["kpi"] = Kpi$ReactHooksTemplate.kpiValue(kpi);
    return params;
  };
  var handlePageChange = function (pageNumber) {
    var pageNumber$1 = String(pageNumber);
    ReasonReactRouter.push("/users/all?page=" + (String(pageNumber$1) + ""));
    Curry._1(setPageInfo, (function (pageInfo) {
            return /* record */[
                    /* total */pageInfo[/* total */0],
                    /* perPage */pageInfo[/* perPage */1],
                    /* currentPage */pageNumber$1
                  ];
          }));
    window.scrollTo(0.0, 0.0);
    return /* () */0;
  };
  var handleChange = function (kpi, action) {
    if (kpi !== undefined) {
      var kpi$1 = kpi;
      switch (action) {
        case "create" :
            var newKpi = kpi$1;
            Http$ReactHooksTemplate.fetchPostJSON("/api/v1/kpis", buildSurveyFormDict(newKpi)).then((function (response) {
                    var status = Json_decode.field("status", Json_decode.string, response);
                    var kpi = Json_decode.field("kpi", Kpi$ReactHooksTemplate.decode, response);
                    if (status === "success") {
                      var match = state[/* kpis */0];
                      if (match !== undefined) {
                        return Promise.resolve((Curry._1(dispatch, /* LoadKpis */Block.__(0, [List.append(/* :: */[
                                                  kpi,
                                                  /* [] */0
                                                ], match)])), Curry._1(dispatch, /* SetKpiChangeModal */Block.__(3, [false]))));
                      } else {
                        return Promise.resolve((Curry._1(dispatch, /* LoadKpis */Block.__(0, [/* :: */[
                                              kpi,
                                              /* [] */0
                                            ]])), Curry._1(dispatch, /* SetKpiChangeModal */Block.__(3, [false]))));
                      }
                    } else {
                      return Promise.resolve((console.log("error"), /* () */0));
                    }
                  }));
            return /* () */0;
        case "delete" :
            var kpi$2 = kpi$1;
            var kpiId = Kpi$ReactHooksTemplate.id(kpi$2);
            Http$ReactHooksTemplate.fetchDeleteJSON("/api/v1/kpis/" + (String(kpiId) + "")).then((function (response) {
                    var status = Json_decode.field("status", Json_decode.string, response);
                    if (status === "success") {
                      var kpiId = Kpi$ReactHooksTemplate.id(kpi$2);
                      var match = state[/* kpis */0];
                      if (match !== undefined) {
                        return Promise.resolve((Curry._1(dispatch, /* LoadKpis */Block.__(0, [Belt_List.keep(match, (function (x) {
                                                    return Caml_obj.caml_notequal(Kpi$ReactHooksTemplate.id(x), kpiId);
                                                  }))])), Curry._1(dispatch, /* SetKpiChangeModal */Block.__(3, [false]))));
                      } else {
                        return Promise.resolve(Curry._1(dispatch, /* SetKpiChangeModal */Block.__(3, [false])));
                      }
                    } else {
                      return Promise.resolve((console.log("error"), /* () */0));
                    }
                  }));
            return /* () */0;
        case "edit" :
            var kpi$3 = kpi$1;
            var kpiId$1 = Kpi$ReactHooksTemplate.id(kpi$3);
            Http$ReactHooksTemplate.fetchPutJSON("/api/v1/kpis/" + (String(kpiId$1) + ""), buildTargetDict(kpi$3)).then((function (response) {
                    var status = Json_decode.field("status", Json_decode.string, response);
                    if (status === "success") {
                      var kpiId = Kpi$ReactHooksTemplate.id(kpi$3);
                      var match = state[/* kpis */0];
                      if (match !== undefined) {
                        return Promise.resolve((Curry._1(dispatch, /* LoadKpis */Block.__(0, [List.append(/* :: */[
                                                  kpi$3,
                                                  /* [] */0
                                                ], Belt_List.keep(match, (function (x) {
                                                        return Caml_obj.caml_notequal(Kpi$ReactHooksTemplate.id(x), kpiId);
                                                      })))])), Curry._1(dispatch, /* SetKpiChangeModal */Block.__(3, [false]))));
                      } else {
                        return Promise.resolve(Curry._1(dispatch, /* SetKpiChangeModal */Block.__(3, [false])));
                      }
                    } else {
                      return Promise.resolve((console.log("error"), /* () */0));
                    }
                  }));
            return /* () */0;
        default:
          console.log("Undefined Action");
          return /* () */0;
      }
    } else {
      return /* () */0;
    }
  };
  React.useEffect((function () {
          fetchUsers(/* () */0).then((function (param) {
                  var paginationInfo = param[1];
                  var perPage = paginationInfo[1];
                  var total = paginationInfo[0];
                  Curry._1(setPageInfo, (function (state) {
                          return /* record */[
                                  /* total */total,
                                  /* perPage */perPage,
                                  /* currentPage */state[/* currentPage */2]
                                ];
                        }));
                  return Promise.resolve(param[0].then((function (xs) {
                                    return Promise.resolve(Curry._1(dispatch, /* LoadKpis */Block.__(0, [xs])));
                                  })));
                }));
          return ;
        }), /* array */[pageInfo[/* currentPage */2]]);
  var match$3 = state[/* kpis */0];
  var match$4 = state[/* kpiChangeModalToggle */2];
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex items-center border-b border-gray-300 px-30-px py-16-px justify-between"
                }, React.createElement("h2", {
                      className: "text-xl font-semibold"
                    }, "KPI Management")), React.createElement("div", {
                  className: "flex justify-between px-30-px pt-20-px items-center"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement(Pagination$ReactHooksTemplate.make, {
                          total: totalPages(Caml_format.caml_int_of_string(pageInfo[/* total */0]), Caml_format.caml_int_of_string(pageInfo[/* perPage */1])),
                          currentPage: currentPage,
                          handlePageChange: handlePageChange
                        })), React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n        shadow-inner flex rounded py-2 px-3 text-xs focus:outline-none",
                      id: "add-campaign",
                      onClick: (function (param) {
                          Curry._1(dispatch, /* SetAction */Block.__(2, ["create"]));
                          Curry._1(dispatch, /* SetKpi */Block.__(1, [undefined]));
                          return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                        })
                    }, React.createElement("img", {
                          className: "h-14-px mr-2-px",
                          src: Image$ReactHooksTemplate.plusCircle
                        }), "New KPI")), match$3 !== undefined ? React.createElement("div", {
                    className: "flex px-30-px pb-30-px"
                  }, React.createElement("table", {
                        className: "text-left table-auto w-full border-separate text-sm font-medium"
                      }, React.createElement(UserKpiListHeader$ReactHooksTemplate.make, { }), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (kpi) {
                                      var match = state[/* selectedKpi */1];
                                      var tmp;
                                      if (match !== undefined) {
                                        var match$1 = state[/* outsideClick */4] === false && Caml_obj.caml_equal(match, kpi);
                                        tmp = match$1 ? "block" : "hidden";
                                      } else {
                                        tmp = "hidden";
                                      }
                                      return React.createElement("tr", {
                                                  className: "border-b border-bottom-gray-600"
                                                }, React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(kpi))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, Kpi$ReactHooksTemplate.filterField(kpi)), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, Kpi$ReactHooksTemplate.filterValue(kpi)), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, String(Kpi$ReactHooksTemplate.kpiValue(kpi))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("div", {
                                                          className: "flex justify-end items-center relative"
                                                        }, React.createElement("img", {
                                                              className: "h-18-px",
                                                              src: Image$ReactHooksTemplate.verticalDots,
                                                              onClick: (function (param) {
                                                                  Curry._1(dispatch, /* SetOutsideClick */Block.__(4, [!state[/* outsideClick */4]]));
                                                                  return Curry._1(dispatch, /* SetKpi */Block.__(1, [kpi]));
                                                                })
                                                            }), React.createElement("div", {
                                                              className: "flex flex-col items-search p-2 absolute rounded border border-gray-400 bg-white\n                                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 gap-2 " + tmp
                                                            }, React.createElement("a", {
                                                                  className: "flex text-xs gap-2",
                                                                  onClick: (function (param) {
                                                                      Curry._1(dispatch, /* SetOutsideClick */Block.__(4, [true]));
                                                                      Curry._1(dispatch, /* SetAction */Block.__(2, ["edit"]));
                                                                      return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "h-12-px",
                                                                      src: Image$ReactHooksTemplate.edit
                                                                    }), "Edit"), React.createElement("a", {
                                                                  className: "flex text-xs gap-2",
                                                                  href: "#",
                                                                  onClick: (function (param) {
                                                                      Curry._1(dispatch, /* SetOutsideClick */Block.__(4, [true]));
                                                                      Curry._1(dispatch, /* SetAction */Block.__(2, ["delete"]));
                                                                      Curry._1(dispatch, /* SetKpi */Block.__(1, [kpi]));
                                                                      return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "h-12-px",
                                                                      src: Image$ReactHooksTemplate.trash
                                                                    }), "Delete")))));
                                    }), match$3))))) : React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Fetching users...")), match$4 ? React.createElement("div", {
                    className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                    style: {
                      height: "calc(100vh + 5rem)"
                    }
                  }, React.createElement(KpiCrud$ReactHooksTemplate.make, {
                        handleChange: handleChange,
                        kpi: state[/* selectedKpi */1],
                        action: state[/* action */3],
                        toggleModal: (function (param) {
                            return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                          })
                      })) : React.createElement(React.Fragment, undefined));
}

var make = UserKpiManagement;

exports.reducer = reducer;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */
