// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");

function empty(param) {
  return /* record */[/* message */""];
}

function setMessage(x) {
  return /* record */[/* message */x];
}

function validMessage(x) {
  return x !== "";
}

function toForm(t) {
  var match = t[/* message */0] !== "";
  return /* record */[/* message */match ? /* Valid */Block.__(0, [t[/* message */0]]) : /* Invalid */Block.__(1, [
                t[/* message */0],
                /* :: */[
                  "can't be empty",
                  /* [] */0
                ]
              ])];
}

function fromForm(form) {
  return /* record */[/* message */FormField$ReactHooksTemplate.getInputValue(form[/* message */0])];
}

function encoder(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "message",
                t[/* message */0]
              ],
              /* [] */0
            ]);
}

function decoder(json) {
  return /* record */[/* message */Json_decode.field("message", Json_decode.string, json)];
}

var modeType = "WebsiteLink";

exports.modeType = modeType;
exports.empty = empty;
exports.setMessage = setMessage;
exports.validMessage = validMessage;
exports.toForm = toForm;
exports.fromForm = fromForm;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */
