// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var CheckboxStatus$ReactHooksTemplate = require("./checkboxStatus.bs.js");

function getPermissionCategoryName(param) {
  switch (param) {
    case /* ShowDashboard */0 :
        return "show_dashboard";
    case /* ManageTickets */1 :
        return "manage_ticket";
    case /* ResolveTickets */2 :
        return "resolve_ticket";
    case /* BuildSurvey */3 :
        return "build_survey";
    case /* DeleteSurvey */4 :
        return "delete_survey";
    case /* ShowSurvey */5 :
        return "show_survey";
    case /* AddUser */6 :
        return "add_user";
    case /* DeleteUser */7 :
        return "delete_user";
    case /* UpdateUser */8 :
        return "update_user";
    case /* ShowUser */9 :
        return "show_user";
    case /* AddRole */10 :
        return "add_role";
    case /* DeleteRole */11 :
        return "delete_role";
    case /* UpdateRole */12 :
        return "update_role";
    case /* ShowRole */13 :
        return "show_role";
    case /* ShowCampaign */14 :
        return "show_campaign";
    case /* CreateCampaign */15 :
        return "create_campaign";
    case /* ShowQuickSurvey */16 :
        return "show_quick_survey";
    case /* ShowDistribution */17 :
        return "show_distribution";
    case /* CreateDistribution */18 :
        return "create_distribution";
    case /* ShowReport */19 :
        return "show_report";
    case /* EditTextCategories */20 :
        return "edit_text_categories";
    case /* ShowKpi */21 :
        return "show_kpi";
    case /* AddKpi */22 :
        return "add_kpi";
    case /* UpdateKpi */23 :
        return "update_kpi";
    case /* DeleteKpi */24 :
        return "delete_kpi";
    
  }
}

function status(t) {
  if (t.tag) {
    return CheckboxStatus$ReactHooksTemplate.reduce(Belt_List.map(t[1], status));
  } else {
    return CheckboxStatus$ReactHooksTemplate.fromBool(t[1]);
  }
}

function postOrder(onCapability, onGroup, tree) {
  if (tree.tag) {
    return Curry._3(onGroup, tree[0], status(tree), Belt_List.map(tree[1], (function (param) {
                      return postOrder(onCapability, onGroup, param);
                    })));
  } else {
    return Curry._2(onCapability, tree[0], CheckboxStatus$ReactHooksTemplate.fromBool(tree[1]));
  }
}

function enabledCapabilities(xs, tree) {
  if (tree.tag) {
    return List.fold_left(enabledCapabilities, xs, tree[1]);
  } else if (tree[1]) {
    return /* :: */[
            tree[0],
            xs
          ];
  } else {
    return xs;
  }
}

function hasCapability(permissionCategory, treeList) {
  var checkCapabilityList = List.filter((function (name) {
            return name === getPermissionCategoryName(permissionCategory);
          }))(List.fold_left(enabledCapabilities, /* [] */0, treeList));
  return Belt_List.head(checkCapabilityList) !== undefined;
}

function setEnabled(x, t) {
  if (t.tag) {
    return /* Group */Block.__(1, [
              t[0],
              Belt_List.map(t[1], (function (param) {
                      return setEnabled(x, param);
                    }))
            ]);
  } else {
    return /* Capability */Block.__(0, [
              t[0],
              x
            ]);
  }
}

function toggle(x, t) {
  if (t.tag) {
    var children = t[1];
    var group = t[0];
    if (x === group) {
      var checked = CheckboxStatus$ReactHooksTemplate.toBool(status(t));
      var partial_arg = !checked;
      return /* Group */Block.__(1, [
                group,
                Belt_List.map(children, (function (param) {
                        return setEnabled(partial_arg, param);
                      }))
              ]);
    } else {
      return /* Group */Block.__(1, [
                group,
                Belt_List.map(children, (function (param) {
                        return toggle(x, param);
                      }))
              ]);
    }
  } else {
    var capability = t[0];
    if (x === capability) {
      return /* Capability */Block.__(0, [
                capability,
                !t[1]
              ]);
    } else {
      return t;
    }
  }
}

function decodeCapability(json) {
  var capability = Json_decode.field("capability", Json_decode.string, json);
  var enabled = Json_decode.field("enabled", Json_decode.bool, json);
  return /* Capability */Block.__(0, [
            capability,
            enabled
          ]);
}

function decodeGroup(json) {
  var group = Json_decode.field("group", Json_decode.string, json);
  var children = Json_decode.field("children", (function (param) {
          return Json_decode.list(decodeChild, param);
        }), json);
  return /* Group */Block.__(1, [
            group,
            children
          ]);
}

function decodeChild(json) {
  return Json_decode.either(decodeGroup, decodeCapability)(json);
}

function decode(json) {
  return Json_decode.field("permissions", (function (param) {
                return Json_decode.list(decodeChild, param);
              }), json);
}

function emptyPermission(param) {
  return /* [] */0;
}

exports.postOrder = postOrder;
exports.enabledCapabilities = enabledCapabilities;
exports.status = status;
exports.toggle = toggle;
exports.decode = decode;
exports.emptyPermission = emptyPermission;
exports.hasCapability = hasCapability;
/* No side effect */
