// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Utils$ReactHooksTemplate = require("./utils.bs.js");

function TextInputNew(Props) {
  var label = Props.label;
  var value = Props.value;
  var handleChange = Props.handleChange;
  var errorCSS = Props.errorCSS;
  var errorText = Props.errorText;
  var match = Props.error;
  var error = match !== undefined ? match : false;
  var maxLength = Props.maxLength;
  var handleValidation = function ($$event) {
    var name_input = Utils$ReactHooksTemplate.formTargetValue($$event);
    var firstChar = name_input[0];
    var check = Belt_Array.getBy(/* array */[
          "<",
          "!",
          "=",
          "+",
          "-",
          "@",
          ">",
          "?",
          "'",
          "\"",
          "$",
          "#",
          "%",
          "^",
          "*",
          "(",
          ")",
          "/",
          ".",
          "&",
          ",",
          ";",
          "`",
          "~"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      console.log("Invalid Characters!");
      return /* () */0;
    } else {
      return Curry._1(handleChange, $$event);
    }
  };
  var maximumLength = maxLength !== undefined ? maxLength : 524288;
  return React.createElement("div", {
              className: "flex justify-between items-center"
            }, React.createElement("div", {
                  className: "text-sm font-medium"
                }, label), React.createElement("input", {
                  className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                  maxLength: maximumLength,
                  type: "text",
                  value: value,
                  onChange: handleValidation
                }), error ? React.createElement("div", {
                    className: errorCSS !== undefined ? errorCSS : "text-red-600 font-medium float-right"
                  }, errorText !== undefined ? errorText : "Error") : React.createElement(React.Fragment, undefined));
}

var make = TextInputNew;

exports.make = make;
/* react Not a pure module */
