// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");

function getInputValue(param) {
  return param[0];
}

function getValidationError(param) {
  switch (param.tag | 0) {
    case /* Invalid */1 :
        return param[1];
    case /* Valid */0 :
    case /* Pristine */2 :
        return /* [] */0;
    
  }
}

function validFormInput(param) {
  switch (param.tag | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function validForm(form) {
  if (validFormInput(form[/* name */0]) && validFormInput(form[/* campaignId */1])) {
    return validFormInput(form[/* file */2]);
  } else {
    return false;
  }
}

function validFileSize(file) {
  return file.size < 5242880;
}

function DistributionCreate(Props) {
  var campaignId = Props.campaignId;
  var toggleDistributionCreate = Props.toggleDistributionCreate;
  var emptyState_000 = /* name : Valid */Block.__(0, [""]);
  var emptyState_001 = /* campaignId : Valid */Block.__(0, [Caml_format.caml_int_of_string(campaignId)]);
  var emptyState_002 = /* file : Pristine */Block.__(2, [undefined]);
  var emptyState = /* record */[
    emptyState_000,
    emptyState_001,
    emptyState_002
  ];
  var match = React.useState((function () {
          return emptyState;
        }));
  var setState = match[1];
  var state = match[0];
  var handleFileChange = function ($$event) {
    var file = Caml_array.caml_array_get($$event.target.files, 0);
    if (file.size < 5242880) {
      return Curry._1(setState, (function (state) {
                    return /* record */[
                            /* name */state[/* name */0],
                            /* campaignId */state[/* campaignId */1],
                            /* file : Valid */Block.__(0, [file])
                          ];
                  }));
    } else {
      return Curry._1(setState, (function (state) {
                    return /* record */[
                            /* name */state[/* name */0],
                            /* campaignId */state[/* campaignId */1],
                            /* file : Invalid */Block.__(1, [
                                file,
                                /* :: */[
                                  "File size should be less\n    than 5 MB",
                                  /* [] */0
                                ]
                              ])
                          ];
                  }));
    }
  };
  var handleFormSubmit = function ($$event) {
    $$event.preventDefault();
    var form = state;
    var formData = new FormData();
    var match = form[/* file */2][0];
    if (match !== undefined) {
      var file = Caml_option.valFromOption(match);
      ((function (eta) {
              var param = undefined;
              var param$1 = eta;
              param$1.append("distribution[customer_source_attributes][file]", file, param !== undefined ? Caml_option.valFromOption(param) : undefined);
              return /* () */0;
            })(formData));
    }
    formData.append("distribution[customer_source_type]", "FeedFile");
    formData.append("distribution[customer_source_attributes][name]", form[/* name */0][0]);
    fetch("/api/v1/campaigns/" + (String(form[/* campaignId */1][0]) + "/distributions"), Fetch.RequestInit.make(/* Post */2, /* array */[
                  /* tuple */[
                    "X-CSRF-TOKEN",
                    Utils$ReactHooksTemplate.csrfToken
                  ],
                  /* tuple */[
                    "Accept",
                    "*"
                  ]
                ], Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (response) {
            Curry._1(toggleDistributionCreate, "save");
            return Promise.resolve(response.json());
          }));
    return /* () */0;
  };
  validForm(state);
  var match$1 = state[/* file */2];
  var tmp;
  switch (match$1.tag | 0) {
    case /* Invalid */1 :
        tmp = $$Array.of_list(Belt_List.mapWithIndex(match$1[1], (function (i, e) {
                    return React.createElement("p", {
                                key: String(i),
                                className: "text-red-500 font-medium float-right"
                              }, e);
                  })));
        break;
    case /* Valid */0 :
    case /* Pristine */2 :
        tmp = React.createElement(React.Fragment, undefined);
        break;
    
  }
  return React.createElement("div", undefined, React.createElement("form", {
                  onSubmit: handleFormSubmit
                }, React.createElement("div", {
                      className: "flex h-432-px z-20 relative"
                    }, React.createElement("div", {
                          className: "w-3/10 flex flex-col border-r border-gray-300\n                        text-darkcharcoal text-base"
                        }, React.createElement("div", {
                              className: "px-6 py-5 bg-lavenderweb"
                            }, "General Settings")), React.createElement("div", {
                          className: "w-7/10 flex flex-col p-8"
                        }, React.createElement("div", {
                              className: "flex justify-between items-center"
                            }, React.createElement("div", {
                                  className: "text-sm font-medium"
                                }, "File Upload"), React.createElement("div", {
                                  className: "w-2/3"
                                }, React.createElement("input", {
                                      className: "h-10 w-3/4 block border px-3 py-2 border-gray-400\n                           bg-white rounded capitalize text-sm",
                                      accept: ".csv",
                                      type: "file",
                                      onChange: handleFileChange
                                    }))), React.createElement("div", undefined, tmp))), React.createElement("div", {
                      className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n        absolute bottom-0 text-base w-full z-30 relative"
                    }, React.createElement("button", {
                          className: "bg-ghostwhite border border-gray-400\n          flex rounded py-3 px-6 text-xs",
                          id: "close_modal",
                          onClick: (function (param) {
                              return Curry._1(toggleDistributionCreate, "close");
                            })
                        }, "Discard"), React.createElement("input", {
                          className: "bg-palatinateblue border border-gray-400\n          flex rounded py-3 px-6 text-xs text-white",
                          disabled: !validForm(state),
                          type: "submit",
                          value: "Save"
                        }))));
}

var make = DistributionCreate;

exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
exports.validFormInput = validFormInput;
exports.validForm = validForm;
exports.validFileSize = validFileSize;
exports.make = make;
/* react Not a pure module */
