// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("./image.bs.js");

function Pagination(Props) {
  var total = Props.total;
  var match = Props.currentPage;
  var currentPage = match !== undefined ? match : 1;
  var match$1 = Props.siblingCount;
  var siblingCount = match$1 !== undefined ? match$1 : 2;
  var handlePageChange = Props.handlePageChange;
  var currentPage$1 = currentPage < 1 ? 1 : (
      currentPage > total ? total : currentPage
    );
  var leftSiblingCount;
  if (currentPage$1 === 1) {
    leftSiblingCount = 0;
  } else if ((currentPage$1 - 1 | 0) < siblingCount) {
    leftSiblingCount = currentPage$1 - 1 | 0;
  } else if ((currentPage$1 - 1 | 0) === siblingCount || (total - currentPage$1 | 0) >= siblingCount) {
    leftSiblingCount = siblingCount;
  } else {
    var addToLeft = siblingCount - (total - currentPage$1 | 0) | 0;
    var match$2 = (currentPage$1 - 1 | 0) > (siblingCount + addToLeft | 0);
    leftSiblingCount = match$2 ? siblingCount + addToLeft | 0 : currentPage$1 - 1 | 0;
  }
  var rightSiblingCount;
  if (total === currentPage$1) {
    rightSiblingCount = 0;
  } else if ((total - currentPage$1 | 0) < siblingCount) {
    rightSiblingCount = total - currentPage$1 | 0;
  } else if ((total - currentPage$1 | 0) === siblingCount || (currentPage$1 - 1 | 0) >= siblingCount) {
    rightSiblingCount = siblingCount;
  } else {
    var addToRight = siblingCount - (currentPage$1 - 1 | 0) | 0;
    console.log(addToRight);
    console.log("sfsd");
    var match$3 = (total - currentPage$1 | 0) > (siblingCount + addToRight | 0);
    rightSiblingCount = match$3 ? siblingCount + addToRight | 0 : total - currentPage$1 | 0;
  }
  var leftSiblings = function (currentPage, leftSiblingCount) {
    if (leftSiblingCount !== 0) {
      if (leftSiblingCount !== 1) {
        return List.append(leftSiblings(currentPage - 1 | 0, leftSiblingCount - 1 | 0), /* :: */[
                    /* Page */Block.__(0, [currentPage - 1 | 0]),
                    /* [] */0
                  ]);
      } else {
        return /* :: */[
                /* Page */Block.__(0, [currentPage - 1 | 0]),
                /* [] */0
              ];
      }
    } else {
      return /* [] */0;
    }
  };
  var rightSiblings = function (currentPage, rightSiblingCount) {
    if (rightSiblingCount !== 0) {
      if (rightSiblingCount !== 1) {
        return List.append(/* :: */[
                    /* Page */Block.__(0, [currentPage + 1 | 0]),
                    /* [] */0
                  ], rightSiblings(currentPage + 1 | 0, rightSiblingCount - 1 | 0));
      } else {
        return /* :: */[
                /* Page */Block.__(0, [currentPage + 1 | 0]),
                /* [] */0
              ];
      }
    } else {
      return /* [] */0;
    }
  };
  var pageButtons = function (param) {
    var xs = leftSiblings(currentPage$1, leftSiblingCount);
    var firstPart;
    var exit = 0;
    if (xs) {
      var match = xs[0];
      if (typeof match === "number" || match.tag) {
        exit = 1;
      } else {
        var match$1 = match[0];
        if (match$1 !== 1) {
          if (match$1 !== 2) {
            exit = 1;
          } else {
            firstPart = List.append(/* :: */[
                  /* Page */Block.__(0, [1]),
                  /* [] */0
                ], /* :: */[
                  /* Page */Block.__(0, [2]),
                  xs[1]
                ]);
          }
        } else {
          firstPart = /* :: */[
            /* Page */Block.__(0, [1]),
            xs[1]
          ];
        }
      }
    } else {
      firstPart = /* [] */0;
    }
    if (exit === 1) {
      firstPart = List.append(/* :: */[
            /* Page */Block.__(0, [1]),
            /* :: */[
              /* Separator */Block.__(1, ["..."]),
              /* [] */0
            ]
          ], xs);
    }
    var xs$1 = List.rev(rightSiblings(currentPage$1, rightSiblingCount));
    var lastPart;
    var exit$1 = 0;
    if (xs$1) {
      var match$2 = xs$1[0];
      if (typeof match$2 === "number" || match$2.tag) {
        exit$1 = 1;
      } else {
        var rest = xs$1[1];
        var x = match$2[0];
        if (x === total) {
          lastPart = List.rev(/* :: */[
                /* Page */Block.__(0, [x]),
                rest
              ]);
        } else if (x === (total - 1 | 0)) {
          lastPart = List.append(List.rev(/* :: */[
                    /* Page */Block.__(0, [x]),
                    rest
                  ]), /* :: */[
                /* Page */Block.__(0, [total]),
                /* [] */0
              ]);
        } else {
          exit$1 = 1;
        }
      }
    } else {
      lastPart = /* [] */0;
    }
    if (exit$1 === 1) {
      lastPart = List.append(List.rev(xs$1), /* :: */[
            /* Separator */Block.__(1, ["..."]),
            /* :: */[
              /* Page */Block.__(0, [total]),
              /* [] */0
            ]
          ]);
    }
    return List.append(/* :: */[
                /* PreviousPage */0,
                /* [] */0
              ], List.append(firstPart, List.append(/* :: */[
                        /* Page */Block.__(0, [currentPage$1]),
                        /* [] */0
                      ], List.append(lastPart, /* :: */[
                            /* NextPage */1,
                            /* [] */0
                          ]))));
  };
  var buttonCSS = "focus:outline-none text-xs h-30-px";
  var activeButtonCSS = "focus:outline-none text-xs h-30-px border border-black rounded-full h-30-px";
  var disabledButtonCSS = "focus:outline-none text-xs h-30-px cursor-not-allowed";
  return React.createElement("div", {
              className: "bg-white"
            }, React.createElement("nav", undefined, React.createElement("ul", {
                      className: "flex justify-between p-20-px"
                    }, $$Array.of_list(Belt_List.map(pageButtons(/* () */0), (function (pageButton) {
                                if (typeof pageButton === "number") {
                                  if (pageButton === /* PreviousPage */0) {
                                    var match = currentPage$1 === 1;
                                    return React.createElement("li", {
                                                key: String(0)
                                              }, React.createElement("button", {
                                                    key: String(0),
                                                    className: match ? disabledButtonCSS : buttonCSS,
                                                    disabled: currentPage$1 === 1,
                                                    onClick: (function (param) {
                                                        return Curry._1(handlePageChange, currentPage$1 - 1 | 0);
                                                      })
                                                  }, React.createElement("img", {
                                                        src: Image$ReactHooksTemplate.arrowLeft
                                                      })));
                                  } else {
                                    var match$1 = currentPage$1 === total;
                                    return React.createElement("li", {
                                                key: String(total + 1 | 0)
                                              }, React.createElement("button", {
                                                    key: String(total + 1 | 0),
                                                    className: match$1 ? disabledButtonCSS : buttonCSS,
                                                    disabled: currentPage$1 === total,
                                                    onClick: (function (param) {
                                                        return Curry._1(handlePageChange, currentPage$1 + 1 | 0);
                                                      })
                                                  }, React.createElement("img", {
                                                        src: Image$ReactHooksTemplate.arrowRight
                                                      })));
                                  }
                                } else if (pageButton.tag) {
                                  return React.createElement("li", undefined, React.createElement("button", {
                                                  key: String(total + 2 | 0),
                                                  className: buttonCSS,
                                                  disabled: true,
                                                  onClick: (function (param) {
                                                      return Curry._1(handlePageChange, currentPage$1 - 1 | 0);
                                                    })
                                                }, pageButton[0]));
                                } else {
                                  var x = pageButton[0];
                                  var match$2 = x === currentPage$1;
                                  var css = match$2 ? activeButtonCSS : buttonCSS;
                                  return React.createElement("li", {
                                              key: String(x)
                                            }, React.createElement("button", {
                                                  key: String(x),
                                                  className: css + " mx-2 w-30-px text-aurometalsaurus",
                                                  value: String(x),
                                                  onClick: (function (param) {
                                                      return Curry._1(handlePageChange, x);
                                                    })
                                                }, String(x)));
                                }
                              }))))));
}

var make = Pagination;

exports.make = make;
/* react Not a pure module */
