// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var LogicGraph$ReactHooksTemplate = require("./LogicGraph.bs.js");

function addLogicToEdges(_questions, _graph) {
  while(true) {
    var graph = _graph;
    var questions = _questions;
    if (questions) {
      var tl = questions[1];
      if (tl) {
        _graph = LogicGraph$ReactHooksTemplate.addEdge(/* DefaultLogic */Block.__(0, [
                questions[0],
                /* Question */Block.__(1, [tl[0]])
              ]), graph);
        _questions = tl;
        continue ;
      } else {
        return graph;
      }
    } else {
      return graph;
    }
  };
}

function changeEdgeForBranchLogic(fromQuestion, toQuestion, comparator, graph) {
  return LogicGraph$ReactHooksTemplate.changeEdge(fromQuestion, /* JumpTo */Block.__(2, [
                fromQuestion,
                toQuestion,
                /* :: */[
                  /* BranchLogic */Block.__(0, [
                      fromQuestion,
                      comparator
                    ]),
                  /* [] */0
                ]
              ]), graph);
}

function changeEdges(surveyName, questions, thankYouList, graph) {
  if (Belt_List.length(questions) > 0 && surveyName !== "t_180_survey") {
    if (surveyName === "t_20_survey") {
      return LogicGraph$ReactHooksTemplate.addEdge(/* DefaultLogic */Block.__(0, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)])
                  ]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 0), /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]), /* EqualsFromList */Block.__(1, [/* :: */[
                          "9",
                          /* :: */[
                            "10",
                            /* [] */0
                          ]
                        ]]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 0), /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]), /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])]), graph)));
    } else if (surveyName === "retail_asset_onboarding") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 3),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 3),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "1",
                                /* :: */[
                                  "2",
                                  /* :: */[
                                    "3",
                                    /* :: */[
                                      "4",
                                      /* :: */[
                                        "5",
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 1), /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]), /* EqualsFromList */Block.__(1, [/* :: */[
                          "IZdi14T5We9NtbDr",
                          /* :: */[
                            "8xoHDHTM9GCELio9",
                            /* [] */0
                          ]
                        ]]), graph));
    } else if (surveyName === "retail_asset_existing_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 4),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                    /* :: */[
                      /* RatingGroupLogic */Block.__(2, [
                          Belt_List.getExn(questions, 4),
                          "OuSsnjtRa9OqqmlX",
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 2),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* MultipleAnswerLogic */Block.__(1, [
                              Belt_List.getExn(questions, 2),
                              /* NotContains */Block.__(1, ["Ap2FoSkBBHtGRDDw"])
                            ]),
                          /* [] */0
                        ]
                      ]), graph));
    } else if (surveyName === "retail_asset_existing_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 4),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                    /* :: */[
                      /* RatingGroupLogic */Block.__(2, [
                          Belt_List.getExn(questions, 4),
                          "OuSsnjtRa9OqqmlX",
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 2),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* MultipleAnswerLogic */Block.__(1, [
                              Belt_List.getExn(questions, 2),
                              /* NotContains */Block.__(1, ["AMC3CL9Bz0wG9FOI"])
                            ]),
                          /* [] */0
                        ]
                      ]), graph));
    } else if (surveyName === "netbanking") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 6),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 6),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "4",
                                /* :: */[
                                  "5",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 4), /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]), /* EqualsFromList */Block.__(1, [/* :: */[
                          "4",
                          /* :: */[
                            "5",
                            /* [] */0
                          ]
                        ]]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 2), /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]), /* EqualsFromValue */Block.__(0, ["e-JY9ZOK-jM3CYixPj6u"]), graph)));
    } else if (surveyName === "branch_banking_financial" || surveyName === "branch_banking_non_financial") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 7),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 7),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "4",
                                /* :: */[
                                  "5",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 5), /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]), /* EqualsFromList */Block.__(1, [/* :: */[
                          "4",
                          /* :: */[
                            "5",
                            /* [] */0
                          ]
                        ]]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 3), /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]), /* EqualsFromList */Block.__(1, [/* :: */[
                              "4",
                              /* :: */[
                                "5",
                                /* [] */0
                              ]
                            ]]), graph)));
    } else if (surveyName === "RM_Survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 1),
                          /* EqualsFromValue */Block.__(0, ["LLR4rOd2MP-jVcA9GjM"])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "RM_Survey_Non_Individual") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 1),
                          /* EqualsFromValue */Block.__(0, ["8ae54a4b-2a91-4531-b753-8938b2a7460e"])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "rm_affluent_individual_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 1),
                          /* EqualsFromValue */Block.__(0, ["EvTsh2mKlcDN3D4W"])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "complaint_resolution_survey_v01") {
      return changeEdgeForBranchLogic(Belt_List.getExn(questions, 4), /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]), /* EqualsFromList */Block.__(1, [/* :: */[
                      "4",
                      /* :: */[
                        "5",
                        /* [] */0
                      ]
                    ]]), changeEdgeForBranchLogic(Belt_List.getExn(questions, 0), /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]), /* EqualsFromList */Block.__(1, [/* :: */[
                          "4",
                          /* :: */[
                            "5",
                            /* [] */0
                          ]
                        ]]), graph));
    } else if (surveyName === "complaint_resolution_survey") {
      return changeEdgeForBranchLogic(Belt_List.getExn(questions, 3), /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]), /* EqualsFromList */Block.__(1, [/* :: */[
                      "4",
                      /* :: */[
                        "5",
                        /* [] */0
                      ]
                    ]]), graph);
    } else if (surveyName === "SRM_Survey") {
      return changeEdgeForBranchLogic(Belt_List.getExn(questions, 3), /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]), /* EqualsFromList */Block.__(1, [/* :: */[
                      "4",
                      /* :: */[
                        "5",
                        /* [] */0
                      ]
                    ]]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 2),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 1),
                              /* EqualsFromValue */Block.__(0, ["nMe7GqkvRWLZh2qdC1nd"])
                            ]),
                          /* [] */0
                        ]
                      ]), graph));
    } else if (surveyName === "seb_mib_renewals_enhancement") {
      return LogicGraph$ReactHooksTemplate.addEdge(/* DefaultLogic */Block.__(0, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)])
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 0),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "7",
                                    /* :: */[
                                      "8",
                                      /* [] */0
                                    ]
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 0),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else if (surveyName === "seb_mib_survey_ntb_customers") {
      return LogicGraph$ReactHooksTemplate.addEdge(/* DefaultLogic */Block.__(0, [
                    Belt_List.getExn(questions, 4),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 6)])
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 3),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 3),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "7",
                                    /* :: */[
                                      "8",
                                      /* [] */0
                                    ]
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 3),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 3),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.addEdge(/* DefaultLogic */Block.__(0, [
                                Belt_List.getExn(questions, 1),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 3)])
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 0),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "7",
                                                /* :: */[
                                                  "8",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 0),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 0),
                                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), graph))))));
    } else if (surveyName === "corporate_banking_survey") {
      return LogicGraph$ReactHooksTemplate.addEdge(/* DefaultLogic */Block.__(0, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)])
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 0),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 0),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "9",
                                        /* :: */[
                                          "10",
                                          /* [] */0
                                        ]
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else if (surveyName === "nykaa_beauty_post_delivery_survey" || surveyName === "nykaa_man_post_delivery_survey" || surveyName === "nykaa_fashion_post_delivery_survey" || surveyName === "nykaa_post_delivery_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 3),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "7",
                                /* :: */[
                                  "8",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* SkipLogic */Block.__(1, [
                        Belt_List.getExn(questions, 3),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* SkipLogic */Block.__(1, [
                            Belt_List.getExn(questions, 2),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* SkipLogic */Block.__(1, [
                                Belt_List.getExn(questions, 2),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 1),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "9",
                                                /* :: */[
                                                  "10",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 1),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 0),
                                              /* EqualsFromList */Block.__(1, [/* :: */[
                                                    "7",
                                                    /* :: */[
                                                      "8",
                                                      /* [] */0
                                                    ]
                                                  ]])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                            Belt_List.getExn(questions, 1),
                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                            /* :: */[
                                              /* BranchLogic */Block.__(0, [
                                                  Belt_List.getExn(questions, 0),
                                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                                ]),
                                              /* [] */0
                                            ]
                                          ]), graph)))))));
    } else if (surveyName === "nykaa_beauty_post_delivery_survey_v01" || surveyName === "nykaa_fashion_post_delivery_survey_v01" || surveyName === "nykaa_man_post_delivery_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 4),
                    /* ThankYou */Block.__(0, [Belt_List.getExn(thankYouList, 0)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["6"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 3),
                        /* ThankYou */Block.__(0, [Belt_List.getExn(thankYouList, 0)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["6"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 2),
                            /* ThankYou */Block.__(0, [Belt_List.getExn(thankYouList, 1)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 1),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                            "9",
                                            /* :: */[
                                              "10",
                                              /* [] */0
                                            ]
                                          ]])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 1),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "7",
                                                /* :: */[
                                                  "8",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 1),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 0),
                                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), graph))))));
    } else if (surveyName === "casa_onboarding_branch_accnt_opening_survey" || surveyName === "casa_onboarding_web_accnt_opening_survey" || surveyName === "casa_onboarding_aims_tablet_accnt_opening_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "7",
                                /* :: */[
                                  "8",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 1),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "9",
                                    /* :: */[
                                      "10",
                                      /* [] */0
                                    ]
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 0),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 0),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                            "7",
                                            /* :: */[
                                              "8",
                                              /* [] */0
                                            ]
                                          ]])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 0),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "9",
                                                /* :: */[
                                                  "10",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), graph)))));
    } else if (surveyName === "merchant_pos_existing_merchants") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 4),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 4),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "9sYAkUQOJcSgVKnn",
                                /* [] */0
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 3),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 3),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "g9n1rJtTqYGQgyod",
                                    /* [] */0
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 1),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "9",
                                        /* :: */[
                                          "10",
                                          /* [] */0
                                        ]
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 0),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 0),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "9",
                                                /* :: */[
                                                  "10",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), graph)))));
    } else if (surveyName === "merchant_pos_new_onboarded") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 7),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 9)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 7),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "Xr4CUe16Cirfcnj7",
                                /* [] */0
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 5),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 5),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "4",
                                    /* :: */[
                                      "5",
                                      /* [] */0
                                    ]
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 3),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 3),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "4",
                                        /* :: */[
                                          "5",
                                          /* [] */0
                                        ]
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 1),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                            "9",
                                            /* :: */[
                                              "10",
                                              /* [] */0
                                            ]
                                          ]])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 1),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "9",
                                                /* :: */[
                                                  "10",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 0),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 0),
                                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                            Belt_List.getExn(questions, 0),
                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                            /* :: */[
                                              /* BranchLogic */Block.__(0, [
                                                  Belt_List.getExn(questions, 0),
                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                        "9",
                                                        /* :: */[
                                                          "10",
                                                          /* [] */0
                                                        ]
                                                      ]])
                                                ]),
                                              /* [] */0
                                            ]
                                          ]), graph)))))));
    } else if (surveyName === "mib_existing" || surveyName === "seb_existing") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 4),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 4),
                          /* EqualsFromValue */Block.__(0, ["pmJ0che5X2lVUq5Y"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 4),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 4),
                              /* EqualsFromValue */Block.__(0, ["ZRjQtYTfBnSiJnvU"])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 1),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "9",
                                        /* :: */[
                                          "10",
                                          /* [] */0
                                        ]
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 0),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                            "7",
                                            /* :: */[
                                              "8",
                                              /* [] */0
                                            ]
                                          ]])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 0),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 0),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 0),
                                              /* EqualsFromList */Block.__(1, [/* :: */[
                                                    "9",
                                                    /* :: */[
                                                      "10",
                                                      /* [] */0
                                                    ]
                                                  ]])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), graph))))));
    } else if (surveyName === "mib_onboarding" || surveyName === "seb_onboarding") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 7),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 6),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "9",
                                /* :: */[
                                  "10",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* SkipLogic */Block.__(1, [
                        Belt_List.getExn(questions, 6),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 6),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "9",
                                    /* :: */[
                                      "10",
                                      /* [] */0
                                    ]
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* SkipLogic */Block.__(1, [
                            Belt_List.getExn(questions, 6),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 6),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "9",
                                        /* :: */[
                                          "10",
                                          /* [] */0
                                        ]
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 6),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 6),
                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 6),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 6),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "7",
                                                /* :: */[
                                                  "8",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 4),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 3),
                                              /* EqualsFromList */Block.__(1, [/* :: */[
                                                    "7",
                                                    /* :: */[
                                                      "8",
                                                      /* [] */0
                                                    ]
                                                  ]])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                            Belt_List.getExn(questions, 3),
                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                            /* :: */[
                                              /* BranchLogic */Block.__(0, [
                                                  Belt_List.getExn(questions, 3),
                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                        "9",
                                                        /* :: */[
                                                          "10",
                                                          /* [] */0
                                                        ]
                                                      ]])
                                                ]),
                                              /* [] */0
                                            ]
                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                                Belt_List.getExn(questions, 3),
                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                                                /* :: */[
                                                  /* BranchLogic */Block.__(0, [
                                                      Belt_List.getExn(questions, 3),
                                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                                    ]),
                                                  /* [] */0
                                                ]
                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                                    Belt_List.getExn(questions, 3),
                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                                    /* :: */[
                                                      /* BranchLogic */Block.__(0, [
                                                          Belt_List.getExn(questions, 3),
                                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                                "7",
                                                                /* :: */[
                                                                  "8",
                                                                  /* [] */0
                                                                ]
                                                              ]])
                                                        ]),
                                                      /* [] */0
                                                    ]
                                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                        Belt_List.getExn(questions, 1),
                                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                                        /* :: */[
                                                          /* BranchLogic */Block.__(0, [
                                                              Belt_List.getExn(questions, 0),
                                                              /* EqualsFromList */Block.__(1, [/* :: */[
                                                                    "9",
                                                                    /* :: */[
                                                                      "10",
                                                                      /* [] */0
                                                                    ]
                                                                  ]])
                                                            ]),
                                                          /* [] */0
                                                        ]
                                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                            Belt_List.getExn(questions, 0),
                                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                                            /* :: */[
                                                              /* BranchLogic */Block.__(0, [
                                                                  Belt_List.getExn(questions, 0),
                                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                                        "7",
                                                                        /* :: */[
                                                                          "8",
                                                                          /* [] */0
                                                                        ]
                                                                      ]])
                                                                ]),
                                                              /* [] */0
                                                            ]
                                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                                Belt_List.getExn(questions, 0),
                                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                                                /* :: */[
                                                                  /* BranchLogic */Block.__(0, [
                                                                      Belt_List.getExn(questions, 0),
                                                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                                                    ]),
                                                                  /* [] */0
                                                                ]
                                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                                    Belt_List.getExn(questions, 0),
                                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                                                    /* :: */[
                                                                      /* BranchLogic */Block.__(0, [
                                                                          Belt_List.getExn(questions, 0),
                                                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                                                "9",
                                                                                /* :: */[
                                                                                  "10",
                                                                                  /* [] */0
                                                                                ]
                                                                              ]])
                                                                        ]),
                                                                      /* [] */0
                                                                    ]
                                                                  ]), graph)))))))))))));
    } else if (surveyName === "merchant_pos_new_onboarded_v01" || surveyName === "ra_collections_predelinquent_customer_calls_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 1),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "9",
                                    /* :: */[
                                      "10",
                                      /* [] */0
                                    ]
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 0),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "7",
                                        /* :: */[
                                          "8",
                                          /* [] */0
                                        ]
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 0),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 0),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "9",
                                                /* :: */[
                                                  "10",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), graph)))));
    } else if (surveyName === "merchant_pos_existing_merchants_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 5),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 4),
                          /* Contains */Block.__(0, ["014b75c8-7816-4bfd-a2c2-7dd29d0e9723"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 4),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                        /* :: */[
                          /* MultipleAnswerLogic */Block.__(1, [
                              Belt_List.getExn(questions, 4),
                              /* Contains */Block.__(0, ["2143afa3-89ab-4c37-b67a-24db59a5bea8"])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 2),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 1),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 0),
                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                            "9",
                                            /* :: */[
                                              "10",
                                              /* [] */0
                                            ]
                                          ]])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 0),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 0),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "7",
                                                /* :: */[
                                                  "8",
                                                  /* [] */0
                                                ]
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 0),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 0),
                                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                            Belt_List.getExn(questions, 0),
                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                            /* :: */[
                                              /* BranchLogic */Block.__(0, [
                                                  Belt_List.getExn(questions, 0),
                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                        "9",
                                                        /* :: */[
                                                          "10",
                                                          /* [] */0
                                                        ]
                                                      ]])
                                                ]),
                                              /* [] */0
                                            ]
                                          ]), graph)))))));
    } else if (surveyName === "nw_portin_customer_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 9), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 9),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 4),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "B6k3P3xz1CJrbh23",
                                /* [] */0
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 3),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 9)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 3),
                              /* EqualsFromList */Block.__(1, [/* :: */[
                                    "B6k3P3xz1CJrbh23",
                                    /* [] */0
                                  ]])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 8), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 8),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 3),
                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                        "sQwuyiN4knQgsQSI",
                                        /* [] */0
                                      ]])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 3),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                                /* :: */[
                                  /* BranchLogic */Block.__(0, [
                                      Belt_List.getExn(questions, 3),
                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                            "sQwuyiN4knQgsQSI",
                                            /* [] */0
                                          ]])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 7), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 7),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                                    /* :: */[
                                      /* BranchLogic */Block.__(0, [
                                          Belt_List.getExn(questions, 3),
                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                "j15PG6UhU1czQ3D8",
                                                /* [] */0
                                              ]])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 3),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                                        /* :: */[
                                          /* BranchLogic */Block.__(0, [
                                              Belt_List.getExn(questions, 3),
                                              /* EqualsFromList */Block.__(1, [/* :: */[
                                                    "j15PG6UhU1czQ3D8",
                                                    /* [] */0
                                                  ]])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* JumpTo */Block.__(2, [
                                            Belt_List.getExn(questions, 6),
                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                                            /* :: */[
                                              /* BranchLogic */Block.__(0, [
                                                  Belt_List.getExn(questions, 2),
                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                        "c7NLXE7up5lltr21",
                                                        /* [] */0
                                                      ]])
                                                ]),
                                              /* [] */0
                                            ]
                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                                                Belt_List.getExn(questions, 2),
                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                                /* :: */[
                                                  /* BranchLogic */Block.__(0, [
                                                      Belt_List.getExn(questions, 2),
                                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                                            "c7NLXE7up5lltr21",
                                                            /* [] */0
                                                          ]])
                                                    ]),
                                                  /* [] */0
                                                ]
                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), /* JumpTo */Block.__(2, [
                                                    Belt_List.getExn(questions, 5),
                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                                                    /* :: */[
                                                      /* BranchLogic */Block.__(0, [
                                                          Belt_List.getExn(questions, 2),
                                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                                "TDSViyNoeIrp6glZ",
                                                                /* [] */0
                                                              ]])
                                                        ]),
                                                      /* [] */0
                                                    ]
                                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                                                        Belt_List.getExn(questions, 2),
                                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                                                        /* :: */[
                                                          /* BranchLogic */Block.__(0, [
                                                              Belt_List.getExn(questions, 2),
                                                              /* EqualsFromList */Block.__(1, [/* :: */[
                                                                    "TDSViyNoeIrp6glZ",
                                                                    /* [] */0
                                                                  ]])
                                                            ]),
                                                          /* [] */0
                                                        ]
                                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                                                            Belt_List.getExn(questions, 4),
                                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                                                            /* :: */[
                                                              /* BranchLogic */Block.__(0, [
                                                                  Belt_List.getExn(questions, 2),
                                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                                        "vBC3og8tuBWF4BNV",
                                                                        /* [] */0
                                                                      ]])
                                                                ]),
                                                              /* [] */0
                                                            ]
                                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                                                                Belt_List.getExn(questions, 2),
                                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                                                /* :: */[
                                                                  /* BranchLogic */Block.__(0, [
                                                                      Belt_List.getExn(questions, 2),
                                                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                                                            "vBC3og8tuBWF4BNV",
                                                                            /* [] */0
                                                                          ]])
                                                                    ]),
                                                                  /* [] */0
                                                                ]
                                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                                    Belt_List.getExn(questions, 1),
                                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 10)]),
                                                                    /* :: */[
                                                                      /* BranchLogic */Block.__(0, [
                                                                          Belt_List.getExn(questions, 0),
                                                                          /* EqualsFromList */Block.__(1, [/* :: */[
                                                                                "9",
                                                                                /* :: */[
                                                                                  "10",
                                                                                  /* [] */0
                                                                                ]
                                                                              ]])
                                                                        ]),
                                                                      /* [] */0
                                                                    ]
                                                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                                        Belt_List.getExn(questions, 0),
                                                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                                                        /* :: */[
                                                                          /* BranchLogic */Block.__(0, [
                                                                              Belt_List.getExn(questions, 0),
                                                                              /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["7"])])])
                                                                            ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                                            Belt_List.getExn(questions, 0),
                                                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                                                            /* :: */[
                                                                              /* BranchLogic */Block.__(0, [
                                                                                  Belt_List.getExn(questions, 0),
                                                                                  /* EqualsFromList */Block.__(1, [/* :: */[
                                                                                        "7",
                                                                                        /* :: */[
                                                                                          "8",
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]])
                                                                                ]),
                                                                              /* [] */0
                                                                            ]
                                                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                                                                                Belt_List.getExn(questions, 0),
                                                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 1)]),
                                                                                /* :: */[
                                                                                  /* BranchLogic */Block.__(0, [
                                                                                      Belt_List.getExn(questions, 0),
                                                                                      /* EqualsFromList */Block.__(1, [/* :: */[
                                                                                            "9",
                                                                                            /* :: */[
                                                                                              "10",
                                                                                              /* [] */0
                                                                                            ]
                                                                                          ]])
                                                                                    ]),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]), graph))))))))))))))));
    } else if (surveyName === "existing_demat_account_holders_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 6),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 6),
                          /* Contains */Block.__(0, ["Dz6jBO6WreUbbmmt"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 4),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                        /* :: */[
                          /* MultipleAnswerLogic */Block.__(1, [
                              Belt_List.getExn(questions, 3),
                              /* Contains */Block.__(0, ["VMUVu0zKBTA7Il2j"])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 3),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                            /* :: */[
                              /* MultipleAnswerLogic */Block.__(1, [
                                  Belt_List.getExn(questions, 3),
                                  /* Contains */Block.__(0, ["2Vw0BAGV1jKMcVqD"])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else if (surveyName === "for_new_demat_accounts_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 3),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 1),
                          /* Contains */Block.__(0, ["aRmcL6rmhbfHnZRM"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 2),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                        /* :: */[
                          /* MultipleAnswerLogic */Block.__(1, [
                              Belt_List.getExn(questions, 2),
                              /* Contains */Block.__(0, ["M8MHpFhQwamUcLgz"])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 1),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                            /* :: */[
                              /* MultipleAnswerLogic */Block.__(1, [
                                  Belt_List.getExn(questions, 1),
                                  /* Contains */Block.__(0, ["A5wY6QWTGgaUZE69"])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else if (surveyName === "Credit_Card_Contact_Center_Survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 2),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "xQHzwwcAdHQuuqhZ",
                                /* [] */0
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "contact_center_retail_banking_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 2),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "rp52TixLIn7eGJ1s",
                                /* [] */0
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "retail_asset_osd") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 0),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["6"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "msme_ntb_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 1),
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["6"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "iris_customer_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 6),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 6),
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 4),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 4),
                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 2),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 2),
                                  /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else if (surveyName === "psrm_co-operate_banking_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 10), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 10),
                    /* ThankYou */Block.__(0, [Belt_List.getExn(thankYouList, 0)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 9),
                          /* Contains */Block.__(0, ["XB86vJcouaaG6SM6"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 9), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 9),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 11)]),
                        /* :: */[
                          /* MultipleAnswerLogic */Block.__(1, [
                              Belt_List.getExn(questions, 9),
                              /* Contains */Block.__(0, ["77MJp0OoAK3K7tI7"])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 6), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 6),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 8)]),
                            /* :: */[
                              /* RatingGroupLogic */Block.__(2, [
                                  Belt_List.getExn(questions, 1),
                                  "0lkExwnWpmJeCLit",
                                  /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), /* JumpTo */Block.__(2, [
                                Belt_List.getExn(questions, 5),
                                /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                                /* :: */[
                                  /* RatingGroupLogic */Block.__(2, [
                                      Belt_List.getExn(questions, 1),
                                      "0lkExwnWpmJeCLit",
                                      /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                    ]),
                                  /* [] */0
                                ]
                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 5), /* JumpTo */Block.__(2, [
                                    Belt_List.getExn(questions, 5),
                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                    /* :: */[
                                      /* RatingGroupLogic */Block.__(2, [
                                          Belt_List.getExn(questions, 1),
                                          "0lkExwnWpmJeCLit",
                                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                        ]),
                                      /* [] */0
                                    ]
                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                                        Belt_List.getExn(questions, 4),
                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                                        /* :: */[
                                          /* RatingGroupLogic */Block.__(2, [
                                              Belt_List.getExn(questions, 1),
                                              "0lkExwnWpmJeCLit",
                                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                            ]),
                                          /* [] */0
                                        ]
                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 4), /* JumpTo */Block.__(2, [
                                            Belt_List.getExn(questions, 4),
                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                            /* :: */[
                                              /* RatingGroupLogic */Block.__(2, [
                                                  Belt_List.getExn(questions, 1),
                                                  "0lkExwnWpmJeCLit",
                                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                ]),
                                              /* [] */0
                                            ]
                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                                Belt_List.getExn(questions, 3),
                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                                                /* :: */[
                                                  /* RatingGroupLogic */Block.__(2, [
                                                      Belt_List.getExn(questions, 1),
                                                      "fFHZdFzfHaXXMVd3",
                                                      /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                                    ]),
                                                  /* [] */0
                                                ]
                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 3), /* JumpTo */Block.__(2, [
                                                    Belt_List.getExn(questions, 3),
                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                                    /* :: */[
                                                      /* RatingGroupLogic */Block.__(2, [
                                                          Belt_List.getExn(questions, 1),
                                                          "fFHZdFzfHaXXMVd3",
                                                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                        ]),
                                                      /* [] */0
                                                    ]
                                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                                                        Belt_List.getExn(questions, 2),
                                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                                                        /* :: */[
                                                          /* RatingGroupLogic */Block.__(2, [
                                                              Belt_List.getExn(questions, 1),
                                                              "fFHZdFzfHaXXMVd3",
                                                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                                            ]),
                                                          /* [] */0
                                                        ]
                                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                                                            Belt_List.getExn(questions, 2),
                                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                                            /* :: */[
                                                              /* RatingGroupLogic */Block.__(2, [
                                                                  Belt_List.getExn(questions, 1),
                                                                  "fFHZdFzfHaXXMVd3",
                                                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                                ]),
                                                              /* [] */0
                                                            ]
                                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                Belt_List.getExn(questions, 1),
                                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                                                /* :: */[
                                                                  /* RatingGroupLogic */Block.__(2, [
                                                                      Belt_List.getExn(questions, 1),
                                                                      "0lkExwnWpmJeCLit",
                                                                      /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                                    ]),
                                                                  /* [] */0
                                                                ]
                                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                    Belt_List.getExn(questions, 1),
                                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 6)]),
                                                                    /* :: */[
                                                                      /* RatingGroupLogic */Block.__(2, [
                                                                          Belt_List.getExn(questions, 1),
                                                                          "0lkExwnWpmJeCLit",
                                                                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                                        ]),
                                                                      /* [] */0
                                                                    ]
                                                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                        Belt_List.getExn(questions, 1),
                                                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 7)]),
                                                                        /* :: */[
                                                                          /* RatingGroupLogic */Block.__(2, [
                                                                              Belt_List.getExn(questions, 1),
                                                                              "fFHZdFzfHaXXMVd3",
                                                                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                                                            ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                            Belt_List.getExn(questions, 1),
                                                                            /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                                                                            /* :: */[
                                                                              /* RatingGroupLogic */Block.__(2, [
                                                                                  Belt_List.getExn(questions, 1),
                                                                                  "fFHZdFzfHaXXMVd3",
                                                                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                                                ]),
                                                                              /* [] */0
                                                                            ]
                                                                          ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                                Belt_List.getExn(questions, 1),
                                                                                /* Question */Block.__(1, [Belt_List.getExn(questions, 5)]),
                                                                                /* :: */[
                                                                                  /* RatingGroupLogic */Block.__(2, [
                                                                                      Belt_List.getExn(questions, 1),
                                                                                      "fFHZdFzfHaXXMVd3",
                                                                                      /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                                                                    ]),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                                    Belt_List.getExn(questions, 1),
                                                                                    /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                                                                                    /* :: */[
                                                                                      /* RatingGroupLogic */Block.__(2, [
                                                                                          Belt_List.getExn(questions, 1),
                                                                                          "yrq9Fm6lHMlEHbpu",
                                                                                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["4"])])])
                                                                                        ]),
                                                                                      /* [] */0
                                                                                    ]
                                                                                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                                                                                        Belt_List.getExn(questions, 1),
                                                                                        /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                                                                                        /* :: */[
                                                                                          /* RatingGroupLogic */Block.__(2, [
                                                                                              Belt_List.getExn(questions, 1),
                                                                                              "yrq9Fm6lHMlEHbpu",
                                                                                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["3"])])])
                                                                                            ]),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]), graph))))))))))))))))));
    } else if (surveyName === "trade_n_forex_transaction_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 2),
                          /* Contains */Block.__(0, ["LieEnO0jsMNDq45n"])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "ybl_adro_survey") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* SkipLogic */Block.__(1, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 2),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "4",
                                /* :: */[
                                  "5",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "ybl_msme_etb_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 1),
                          /* Contains */Block.__(0, ["hQRlpLZjafe28Col"])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "ybl_msme_ntb_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 1),
                          /* EqualsFromList */Block.__(1, [/* :: */[
                                "4",
                                /* :: */[
                                  "5",
                                  /* [] */0
                                ]
                              ]])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "contact_center_retail_banking_survey_v02") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 2),
                          /* NotContains */Block.__(1, ["Ha0zWyoZj1ZCDRgO"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 1), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 1),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["8"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 0),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else if (surveyName === "ybl_rm_affluent_individual_survey_v01" || surveyName === "ybl_rm_affluent_nonindividual_survey_v01") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 0),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["8"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), graph);
    } else if (surveyName === "ybl_wholesale_banking_service_contact_centre" || surveyName === "ybl_wholesale_banking_service_relationship_manager") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 1),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 3)]),
                    /* :: */[
                      /* BranchLogic */Block.__(0, [
                          Belt_List.getExn(questions, 0),
                          /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 0),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["8"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), graph));
    } else if (surveyName === "ybl_yes_private_contact_center") {
      return LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 2), /* JumpTo */Block.__(2, [
                    Belt_List.getExn(questions, 2),
                    /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                    /* :: */[
                      /* MultipleAnswerLogic */Block.__(1, [
                          Belt_List.getExn(questions, 2),
                          /* NotContains */Block.__(1, ["QQUCxSF28GyxkZov"])
                        ]),
                      /* [] */0
                    ]
                  ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                        Belt_List.getExn(questions, 1),
                        /* Question */Block.__(1, [Belt_List.getExn(questions, 4)]),
                        /* :: */[
                          /* BranchLogic */Block.__(0, [
                              Belt_List.getExn(questions, 0),
                              /* Conditional */Block.__(2, [/* GreaterThan */Block.__(2, [/* Int */Block.__(0, ["8"])])])
                            ]),
                          /* [] */0
                        ]
                      ]), LogicGraph$ReactHooksTemplate.changeEdge(Belt_List.getExn(questions, 0), /* JumpTo */Block.__(2, [
                            Belt_List.getExn(questions, 0),
                            /* Question */Block.__(1, [Belt_List.getExn(questions, 2)]),
                            /* :: */[
                              /* BranchLogic */Block.__(0, [
                                  Belt_List.getExn(questions, 0),
                                  /* Conditional */Block.__(2, [/* LessThan */Block.__(1, [/* Int */Block.__(0, ["9"])])])
                                ]),
                              /* [] */0
                            ]
                          ]), graph)));
    } else {
      return graph;
    }
  } else {
    return graph;
  }
}

exports.addLogicToEdges = addLogicToEdges;
exports.changeEdgeForBranchLogic = changeEdgeForBranchLogic;
exports.changeEdges = changeEdges;
/* LogicGraph-ReactHooksTemplate Not a pure module */
