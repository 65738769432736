// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function background(t) {
  return t[/* title */0][/* background */0];
}

function titleTextColor(t) {
  return t[/* title */0][/* text_color */1];
}

function boxBackground(t) {
  return t[/* title */0][/* box_bg */2];
}

function innerContainerBackground(t) {
  return t[/* title */0][/* inner_container_bg */3];
}

function borderColor(t) {
  return t[/* title */0][/* border_color */4];
}

function fromJson(json) {
  return /* record */[/* title */Json_decode.field("title", (function (x) {
                  return /* record */[
                          /* background */Json_decode.field("background", Json_decode.string, x),
                          /* text_color */Json_decode.field("text_color", Json_decode.string, x),
                          /* box_bg */Json_decode.field("box_bg", Json_decode.string, x),
                          /* inner_container_bg */Json_decode.field("inner_container_bg", Json_decode.string, x),
                          /* border_color */Json_decode.field("border_color", Json_decode.string, x)
                        ];
                }), json)];
}

exports.background = background;
exports.titleTextColor = titleTextColor;
exports.boxBackground = boxBackground;
exports.innerContainerBackground = innerContainerBackground;
exports.borderColor = borderColor;
exports.fromJson = fromJson;
/* No side effect */
