// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var SearchForm$ReactHooksTemplate = require("./SearchForm.bs.js");

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var searchPlaceholder = "Search Projects";

function ProjectList(Props) {
  var searchKey = Props.searchKey;
  var setSearchKey = Props.setSearchKey;
  var handleSearch = Props.handleSearch;
  var projects = Props.projects;
  var selectedProjectId = Props.selectedProjectId;
  var setProject = Props.setProject;
  var toggleProjectCreate = Props.toggleProjectCreate;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex items-center h-10 m-4 p-4"
                }, React.createElement(SearchForm$ReactHooksTemplate.make, {
                      searchKey: searchKey,
                      setSearchKey: setSearchKey,
                      handleSearch: handleSearch,
                      searchPlaceholder: searchPlaceholder
                    })), React.createElement("div", {
                  className: "m-6 text-darkcharcoal text-xl font-semibold"
                }, "Projects"), React.createElement("div", {
                  className: "align-middle items-center mt-6"
                }, $$Array.of_list(Belt_List.mapWithIndex(projects, (function (index, project) {
                            var match = selectedProjectId === project[/* id */0];
                            return React.createElement("div", {
                                        key: String(index),
                                        className: "px-6 py-3 h-10 " + (
                                          match ? "text-darkcharcoal font-semibold bg-lavenderweb" : "text-aurometalsaurus text-base"
                                        ),
                                        onClick: (function (param) {
                                            return Curry._1(setProject, project);
                                          })
                                      }, React.createElement("div", {
                                            className: "flex justify-between w-full items-center"
                                          }, React.createElement("div", undefined, project[/* name */1]), React.createElement("div", {
                                                className: "font-normal text-xs align-middle"
                                              }, React.createElement("div", {
                                                    className: "m-auto"
                                                  }, String(project[/* surveyCount */2])))));
                          })))), React.createElement("a", {
                  className: "bg-ghostwhite border border-gray-400 mx-4 my-6\n              shadow-inner flex justify-center rounded py-2 px-3 text-sm",
                  id: "add-survey",
                  onClick: toggleProjectCreate
                }, React.createElement("img", {
                      className: "h-14-px mr-1",
                      src: Image$ReactHooksTemplate.plusCircle
                    }), "New Project"));
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* per_page */"1"
];

var make = ProjectList;

exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.emptyPageInfo = emptyPageInfo;
exports.searchPlaceholder = searchPlaceholder;
exports.make = make;
/* react Not a pure module */
