// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function NpsDefaultForm$NpsItem(Props) {
  var step = Props.step;
  var className = Props.className;
  var onSelection = Props.onSelection;
  var style = Props.style;
  Props.representation;
  return React.createElement("button", {
              className: className,
              style: style,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(onSelection, step);
                })
            }, React.createElement("div", undefined, React.createElement("span", undefined, String(step))));
}

var NpsItem = {
  make: NpsDefaultForm$NpsItem
};

function colorBasedOnLikelyness(index) {
  switch (index) {
    case 0 :
    case 1 :
    case 2 :
    case 3 :
    case 4 :
    case 5 :
    case 6 :
        return "#E60000";
    case 7 :
    case 8 :
        return "#F79406";
    case 9 :
    case 10 :
        return "#21AD04";
    default:
      return "#ffffff";
  }
}

function NpsDefaultForm(Props) {
  var nps = Props.nps;
  var customerInfo = Props.customerInfo;
  Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var steps = function (count) {
    return Belt_List.makeBy(count, (function (x) {
                  var match = Nps$ReactHooksTemplate.includeZero(nps);
                  if (match) {
                    return x;
                  } else {
                    return x + 1 | 0;
                  }
                }));
  };
  var scale = Nps$ReactHooksTemplate.scale(nps);
  var includeZero = Nps$ReactHooksTemplate.includeZero(nps);
  var startRating = scale ? 0 : 1;
  var endRating = scale ? 10 : 5;
  var selectedCss = function (x) {
    var match = Nps$ReactHooksTemplate.isAnswer(nps, x);
    if (match) {
      return "border-2 border-bleudefrance";
    } else {
      return "";
    }
  };
  var rmName = CustomerInfo$ReactHooksTemplate.rmName(customerInfo);
  var questionText = Nps$ReactHooksTemplate.getTextTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)).replace("${RM_NAME}", rmName);
  var items = function (a, b) {
    return $$Array.of_list(Belt_List.mapWithIndex(steps((b - a | 0) + 1 | 0), (function (i, x) {
                      return React.createElement(NpsDefaultForm$NpsItem, {
                                  step: includeZero ? x + a | 0 : (x + a | 0) - 1 | 0,
                                  className: selectedCss(x) + (" " + " small:w-6 small:h-6 w-12 h-12 small:rounded rounded-xl focus:outline-none\n    text-white small:text-sm text-lg font-medium "),
                                  onSelection: (function (scale) {
                                      return Curry._1(onChange, Nps$ReactHooksTemplate.setAnswer(nps, scale));
                                    }),
                                  style: {
                                    backgroundColor: colorBasedOnLikelyness(x)
                                  },
                                  representation: Nps$ReactHooksTemplate.representation(nps),
                                  key: String(i + a | 0)
                                });
                    })));
  };
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 border border-gray-400 rounded-lg"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, questionText), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, Nps$ReactHooksTemplate.getDescriptionTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full"
                }, React.createElement("div", {
                      className: "flex flex-col justify-between items-start"
                    }, React.createElement("div", {
                          className: "flex small:gap-1 gap-2"
                        }, items(startRating, endRating)), React.createElement("div", {
                          className: " flex justify-between w-full pt-6 pb-10 text-spanishgray text-sm "
                        }, React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", {
                              className: "pl-12"
                            }, NpsLabel$ReactHooksTemplate.center(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                            language: language
                          }) : React.createElement(React.Fragment, undefined))));
}

var make = NpsDefaultForm;

exports.NpsItem = NpsItem;
exports.colorBasedOnLikelyness = colorBasedOnLikelyness;
exports.make = make;
/* react Not a pure module */
