// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var TextInput$ReactHooksTemplate = require("../common/TextInput.bs.js");

var emptyFormInfo = /* record */[
  /* msisdn */"",
  /* email */"",
  /* first_name */""
];

function QuickSurveyForm(Props) {
  var match = React.useState((function () {
          return emptyFormInfo;
        }));
  var setFormInfo = match[1];
  var formInfo = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setformSubmitMessage = match$1[1];
  var formSubmitMessage = match$1[0];
  var buildSurveyFormDict = function (form) {
    var params = { };
    params["msisdn"] = form[/* msisdn */0];
    params["email"] = form[/* email */1];
    params["first_name"] = form[/* first_name */2];
    params["api_key"] = "2f129c96-9077-485d-b6fa-61619c284ffe";
    return params;
  };
  var handleCustomerNameChange = function ($$event) {
    var first_name = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormInfo, (function (formInfo) {
                  return /* record */[
                          /* msisdn */formInfo[/* msisdn */0],
                          /* email */formInfo[/* email */1],
                          /* first_name */first_name
                        ];
                }));
  };
  var handleMsisdnChange = function ($$event) {
    var msisdn = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormInfo, (function (formInfo) {
                  return /* record */[
                          /* msisdn */msisdn,
                          /* email */formInfo[/* email */1],
                          /* first_name */formInfo[/* first_name */2]
                        ];
                }));
  };
  var handleEmailChange = function ($$event) {
    var email = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormInfo, (function (formInfo) {
                  return /* record */[
                          /* msisdn */formInfo[/* msisdn */0],
                          /* email */email,
                          /* first_name */formInfo[/* first_name */2]
                        ];
                }));
  };
  var tmp = formSubmitMessage === "" ? React.createElement(React.Fragment, undefined) : React.createElement(React.Fragment, undefined, React.createElement("p", {
              className: "text-teal-500"
            }, formSubmitMessage));
  return React.createElement("div", {
              className: "p-30-px m-30-px w-2/3 bg-white"
            }, React.createElement("h1", {
                  className: "text-32-px mb-30-px"
                }, "Survey Form"), tmp, React.createElement("form", {
                  onSubmit: (function (param) {
                      var form = formInfo;
                      var $$event = param;
                      $$event.preventDefault();
                      Http$ReactHooksTemplate.fetchPostJSON("/api/v1/quick_survey", buildSurveyFormDict(form)).then((function (response) {
                              var status = Json_decode.field("status", Json_decode.string, response);
                              var description = Json_decode.field("description", Json_decode.string, response);
                              var exit = 0;
                              switch (status) {
                                case "error" :
                                case "success" :
                                    exit = 1;
                                    break;
                                default:
                                  Curry._1(setformSubmitMessage, (function (param) {
                                          return "Success.........";
                                        }));
                              }
                              if (exit === 1) {
                                Curry._1(setformSubmitMessage, (function (param) {
                                        return description;
                                      }));
                              }
                              return Promise.resolve((console.log(response), /* () */0));
                            }));
                      return Curry._1(setFormInfo, (function (param) {
                                    return emptyFormInfo;
                                  }));
                    })
                }, React.createElement("div", {
                      className: "flex mb-30-px items-center"
                    }, React.createElement("div", {
                          className: "w-1/2"
                        }, React.createElement(TextInput$ReactHooksTemplate.make, {
                              label: "Customer Name",
                              value: formInfo[/* first_name */2],
                              handleChange: handleCustomerNameChange
                            }))), React.createElement("div", {
                      className: "flex mb-30-px items-center"
                    }, React.createElement("div", {
                          className: "w-1/2"
                        }, React.createElement(TextInput$ReactHooksTemplate.make, {
                              label: "Mobile Number",
                              value: formInfo[/* msisdn */0],
                              handleChange: handleMsisdnChange
                            }))), React.createElement("div", {
                      className: "flex mb-30-px items-center"
                    }, React.createElement("div", {
                          className: "w-1/2"
                        }, React.createElement(TextInput$ReactHooksTemplate.make, {
                              label: "Email",
                              value: formInfo[/* email */1],
                              handleChange: handleEmailChange
                            }))), React.createElement("div", undefined, React.createElement("input", {
                          className: "bg-teal-500 hover:bg-teal-700 py-6-px px-20-px font-normal text-white font-16-px",
                          type: "submit",
                          value: "Submit"
                        }))));
}

var make = QuickSurveyForm;

exports.emptyFormInfo = emptyFormInfo;
exports.make = make;
/* react Not a pure module */
