// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var MomentRe = require("bs-moment/src/MomentRe.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var DateSelected$ReactHooksTemplate = require("./dateSelected.bs.js");

function getFrom(t) {
  return t[/* from */0];
}

function getTo(t) {
  return t[/* to_ */1];
}

function selected(t) {
  return t[/* selected */2];
}

function isSelected(t) {
  return Belt_Option.isSome(t[/* selected */2]);
}

function setSelected(selected, t) {
  return /* record */[
          /* from */t[/* from */0],
          /* to_ */t[/* to_ */1],
          /* selected */selected
        ];
}

function resetSelected(t) {
  return /* record */[
          /* from */t[/* from */0],
          /* to_ */t[/* to_ */1],
          /* selected */undefined
        ];
}

function convertDateToMoment(date) {
  if (date !== undefined) {
    return Caml_option.some((function (eta) {
                    return MomentRe.moment(undefined, eta);
                  })(date));
  }
  
}

function fromJson(json) {
  return /* record */[
          /* from */convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("start_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          /* to_ */convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("end_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          /* selected */Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("selected", DateSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        ];
}

function toString(t) {
  var match = t[/* selected */2];
  if (match !== undefined) {
    return DateSelected$ReactHooksTemplate.toString(match);
  } else {
    return "";
  }
}

function encodeParams(t) {
  return Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("start_date", t[/* from */0]), Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("end_date", t[/* to_ */1]), Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t[/* selected */2], DateSelected$ReactHooksTemplate.encode)));
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "category_type",
                "date"
              ],
              /* :: */[
                /* tuple */[
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                /* [] */0
              ]
            ]);
}

exports.getFrom = getFrom;
exports.getTo = getTo;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.toString = toString;
exports.encode = encode;
exports.fromJson = fromJson;
exports.selected = selected;
exports.isSelected = isSelected;
/* MomentRe Not a pure module */
