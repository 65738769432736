// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(top, right, bottom, left) {
  return {
          top: top,
          right: right,
          bottom: bottom,
          left: left
        };
}

var Margin = {
  make: make
};

function make$1(left, right) {
  return {
          left: left,
          right: right
        };
}

var Padding = {
  make: make$1
};

function make$2(fontSize, angle, position) {
  return (function (param, param$1, param$2) {
      return {
              fontSize: fontSize,
              angle: angle,
              position: position,
              textAnchor: param,
              width: param$1,
              scaleToFit: param$2
            };
    });
}

var Tick = {
  make: make$2
};

function make$3(fill) {
  return {
          fill: fill
        };
}

var Cursor = {
  make: make$3
};

var LineChart = { };

var AreaChart = { };

var BarChart = { };

var Line = { };

var Area = { };

var CartesianGrid = { };

var ReferenceLine = { };

function encode(param) {
  if (typeof param === "number") {
    switch (param) {
      case /* PreserveStart */0 :
          return "preserveStart";
      case /* PreserveEnd */1 :
          return "preserveEnd";
      case /* PreserveStartEnd */2 :
          return "preserveStartEnd";
      
    }
  } else {
    return param[0];
  }
}

function encodeOpt(__x) {
  return Belt_Option.map(__x, encode);
}

var AxisInterval = {
  encode: encode,
  encodeOpt: encodeOpt
};

function makeProps(interval) {
  var arg = Belt_Option.map(interval, encode);
  return (function (param, param$1, param$2, param$3, param$4) {
      return (function (param$5, param$6, param$7, param$8, param$9, param$10, param$11) {
          var prim = param;
          var prim$1 = param$1;
          var prim$2 = param$2;
          var prim$3 = param$3;
          var prim$4 = param$4;
          var prim$5 = arg;
          var prim$6 = param$5;
          var prim$7 = param$6;
          var prim$8 = param$7;
          var prim$9 = param$8;
          var prim$10 = param$9;
          var prim$11 = param$10;
          var tmp = { };
          if (prim !== undefined) {
            tmp.minTickGap = Caml_option.valFromOption(prim);
          }
          if (prim$1 !== undefined) {
            tmp.dataKey = Caml_option.valFromOption(prim$1);
          }
          if (prim$2 !== undefined) {
            tmp.tickLine = Caml_option.valFromOption(prim$2);
          }
          if (prim$3 !== undefined) {
            tmp.axisLine = Caml_option.valFromOption(prim$3);
          }
          if (prim$4 !== undefined) {
            tmp.tick = Caml_option.valFromOption(prim$4);
          }
          if (prim$5 !== undefined) {
            tmp.interval = Caml_option.valFromOption(prim$5);
          }
          if (prim$6 !== undefined) {
            tmp.padding = Caml_option.valFromOption(prim$6);
          }
          if (prim$7 !== undefined) {
            tmp.height = Caml_option.valFromOption(prim$7);
          }
          if (prim$8 !== undefined) {
            tmp.tickMargin = Caml_option.valFromOption(prim$8);
          }
          if (prim$9 !== undefined) {
            tmp.tickFormatter = Caml_option.valFromOption(prim$9);
          }
          if (prim$10 !== undefined) {
            tmp.type = (function () {
                  switch (Caml_option.valFromOption(prim$10)) {
                    case 561678025 :
                        return "number";
                    case 762063614 :
                        return "category";
                    
                  }
                })();
          }
          if (prim$11 !== undefined) {
            tmp.key = Caml_option.valFromOption(prim$11);
          }
          return tmp;
        });
    });
}

var XAxis = {
  makeProps: makeProps
};

function makeProps$1(interval) {
  var arg = Belt_Option.map(interval, encode);
  return (function (param, param$1, param$2, param$3, param$4, param$5) {
      return (function (param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
          var prim = param;
          var prim$1 = param$1;
          var prim$2 = param$2;
          var prim$3 = param$3;
          var prim$4 = param$4;
          var prim$5 = param$5;
          var prim$6 = arg;
          var prim$7 = param$6;
          var prim$8 = param$7;
          var prim$9 = param$8;
          var prim$10 = param$9;
          var prim$11 = param$10;
          var prim$12 = param$11;
          var prim$13 = param$12;
          var prim$14 = param$13;
          var tmp = { };
          if (prim !== undefined) {
            tmp.minTickGap = Caml_option.valFromOption(prim);
          }
          if (prim$1 !== undefined) {
            tmp.dataKey = Caml_option.valFromOption(prim$1);
          }
          if (prim$2 !== undefined) {
            tmp.yAxisId = Caml_option.valFromOption(prim$2);
          }
          if (prim$3 !== undefined) {
            tmp.width = Caml_option.valFromOption(prim$3);
          }
          if (prim$4 !== undefined) {
            tmp.height = Caml_option.valFromOption(prim$4);
          }
          if (prim$5 !== undefined) {
            tmp.tick = Caml_option.valFromOption(prim$5);
          }
          if (prim$6 !== undefined) {
            tmp.interval = Caml_option.valFromOption(prim$6);
          }
          if (prim$7 !== undefined) {
            tmp.tickFormatter = Caml_option.valFromOption(prim$7);
          }
          if (prim$8 !== undefined) {
            tmp.orientation = (function () {
                  switch (Caml_option.valFromOption(prim$8)) {
                    case -379319332 :
                        return "right";
                    case -944764921 :
                        return "left";
                    
                  }
                })();
          }
          if (prim$9 !== undefined) {
            tmp.domain = Caml_option.valFromOption(prim$9);
          }
          if (prim$10 !== undefined) {
            tmp.type = (function () {
                  switch (Caml_option.valFromOption(prim$10)) {
                    case 561678025 :
                        return "number";
                    case 762063614 :
                        return "category";
                    
                  }
                })();
          }
          if (prim$11 !== undefined) {
            tmp.tickLine = Caml_option.valFromOption(prim$11);
          }
          if (prim$12 !== undefined) {
            tmp.axisLine = Caml_option.valFromOption(prim$12);
          }
          if (prim$13 !== undefined) {
            tmp.tickMargin = Caml_option.valFromOption(prim$13);
          }
          if (prim$14 !== undefined) {
            tmp.key = Caml_option.valFromOption(prim$14);
          }
          return tmp;
        });
    });
}

var YAxis = {
  makeProps: makeProps$1
};

var Tooltip = { };

var Legend = { };

var ComposedChart = { };

var Bar = { };

var Scatter = { };

var LabelList = { };

var ResponsiveContainer = { };

var Brush = { };

var PieChart = { };

var Pie = { };

var Cell = { };

exports.Margin = Margin;
exports.Padding = Padding;
exports.Tick = Tick;
exports.Cursor = Cursor;
exports.LineChart = LineChart;
exports.AreaChart = AreaChart;
exports.BarChart = BarChart;
exports.Line = Line;
exports.Area = Area;
exports.CartesianGrid = CartesianGrid;
exports.ReferenceLine = ReferenceLine;
exports.AxisInterval = AxisInterval;
exports.XAxis = XAxis;
exports.YAxis = YAxis;
exports.Tooltip = Tooltip;
exports.Legend = Legend;
exports.ComposedChart = ComposedChart;
exports.Bar = Bar;
exports.Scatter = Scatter;
exports.LabelList = LabelList;
exports.ResponsiveContainer = ResponsiveContainer;
exports.Brush = Brush;
exports.PieChart = PieChart;
exports.Pie = Pie;
exports.Cell = Cell;
/* No side effect */
