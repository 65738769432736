// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");

function RatingGroupEditor(Props) {
  var ratingGroup = Props.ratingGroup;
  var onRatingGroupChange = Props.onRatingGroupChange;
  return React.createElement("div", undefined, React.createElement("button", {
                  className: "bg-ghostwhite border border-gray-400\n          flex rounded p-2 text-sm mt-0",
                  onClick: (function (param) {
                      return Curry._1(onRatingGroupChange, RatingGroup$ReactHooksTemplate.setGroups(ratingGroup, Belt_List.concat(/* :: */[
                                          /* tuple */[
                                            RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(/* () */0)),
                                            ""
                                          ],
                                          /* [] */0
                                        ], RatingGroup$ReactHooksTemplate.groups(ratingGroup))));
                    })
                }, React.createElement("div", {
                      className: "flex items-center justify-between gap-1 text-darkcharcoal"
                    }, React.createElement("img", {
                          src: Image$ReactHooksTemplate.plusCircle
                        }), React.createElement("p", undefined, "Add option"))), React.createElement("div", {
                  className: "mb-7 mt-4"
                }, $$Array.of_list(List.mapi((function (i, param) {
                            var groupId = param[0];
                            return React.createElement("div", undefined, React.createElement("div", {
                                            className: "mb-4"
                                          }, React.createElement("div", {
                                                className: "flex justify-between mb-2"
                                              }, React.createElement("div", {
                                                    className: "text-darkcharcoal text-sm font-medium"
                                                  }, "Label " + String(i + 1 | 0)), React.createElement("img", {
                                                    className: "h-12-px",
                                                    src: Image$ReactHooksTemplate.trash,
                                                    onClick: (function (param) {
                                                        return Curry._1(onRatingGroupChange, RatingGroup$ReactHooksTemplate.removeGroup(groupId, RatingGroup$ReactHooksTemplate.groups(ratingGroup), ratingGroup));
                                                      })
                                                  })), React.createElement("input", {
                                                className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                                                placeholder: "Enter Label " + String(i + 1 | 0),
                                                type: "text",
                                                value: param[1],
                                                onChange: (function ($$event) {
                                                    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                    return Curry._1(onRatingGroupChange, RatingGroup$ReactHooksTemplate.setGroups(ratingGroup, List.map((function (group) {
                                                                          var id = group[0];
                                                                          var match = id === groupId;
                                                                          if (match) {
                                                                            return /* tuple */[
                                                                                    id,
                                                                                    value
                                                                                  ];
                                                                          } else {
                                                                            return group;
                                                                          }
                                                                        }), RatingGroup$ReactHooksTemplate.groups(ratingGroup))));
                                                  })
                                              })));
                          }), List.rev(RatingGroup$ReactHooksTemplate.groups(ratingGroup))))));
}

var make = RatingGroupEditor;

exports.make = make;
/* react Not a pure module */
