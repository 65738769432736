// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t[/* id */0];
}

function decode(json) {
  return /* record */[/* id */Json_decode.field("id", Json_decode.$$int, json)];
}

var dataTypeName = "MsisdnType";

exports.dataTypeName = dataTypeName;
exports.id = id;
exports.decode = decode;
/* No side effect */
