// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* label */NpsLabel$ReactHooksTemplate.make(Json_decode.field("left_label", Json_decode.string, json), Json_decode.field("center_label", Json_decode.string, json), Json_decode.field("right_label", Json_decode.string, json)),
          /* veryUnlikely */Json_decode.withDefault("Very Unhappy!", (function (param) {
                  return Json_decode.field("very_unlikely", Json_decode.string, param);
                }), json),
          /* unlikely */Json_decode.withDefault("Unhappy!", (function (param) {
                  return Json_decode.field("unlikely", Json_decode.string, param);
                }), json),
          /* maybe */Json_decode.withDefault("It was ok!", (function (param) {
                  return Json_decode.field("maybe", Json_decode.string, param);
                }), json),
          /* likely */Json_decode.withDefault("Happy!", (function (param) {
                  return Json_decode.field("likely", Json_decode.string, param);
                }), json),
          /* extremelyLikely */Json_decode.withDefault("Super Happy!", (function (param) {
                  return Json_decode.field("extremely_likely", Json_decode.string, param);
                }), json)
        ];
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* scale : TenScale */1,
          /* includeZero */true,
          /* description */"",
          /* label */NpsLabel$ReactHooksTemplate.empty(/* () */0),
          /* representation : Plain */0,
          /* autoAdvance */false,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function make(text, description, representation, autoAdvance, label, uuid) {
  return /* record */[
          /* id */undefined,
          /* uuid */uuid,
          /* text */text,
          /* scale : TenScale */1,
          /* includeZero */true,
          /* description */description,
          /* label */label,
          /* representation */representation,
          /* autoAdvance */autoAdvance,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* scale */t[/* scale */3],
          /* includeZero */t[/* includeZero */4],
          /* description */t[/* description */5],
          /* label */t[/* label */6],
          /* representation */t[/* representation */7],
          /* autoAdvance */t[/* autoAdvance */8],
          /* translations */t[/* translations */9],
          /* answer */t[/* answer */10]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* scale */t[/* scale */3],
          /* includeZero */t[/* includeZero */4],
          /* description */description,
          /* label */t[/* label */6],
          /* representation */t[/* representation */7],
          /* autoAdvance */t[/* autoAdvance */8],
          /* translations */t[/* translations */9],
          /* answer */t[/* answer */10]
        ];
}

function setLabel(t, label) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* scale */t[/* scale */3],
          /* includeZero */t[/* includeZero */4],
          /* description */t[/* description */5],
          /* label */label,
          /* representation */t[/* representation */7],
          /* autoAdvance */t[/* autoAdvance */8],
          /* translations */t[/* translations */9],
          /* answer */t[/* answer */10]
        ];
}

function setRepresentation(t, representation) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* scale */t[/* scale */3],
          /* includeZero */t[/* includeZero */4],
          /* description */t[/* description */5],
          /* label */t[/* label */6],
          /* representation */representation,
          /* autoAdvance */t[/* autoAdvance */8],
          /* translations */t[/* translations */9],
          /* answer */t[/* answer */10]
        ];
}

function setAutoAdvance(autoAdvance, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* scale */t[/* scale */3],
          /* includeZero */t[/* includeZero */4],
          /* description */t[/* description */5],
          /* label */t[/* label */6],
          /* representation */t[/* representation */7],
          /* autoAdvance */autoAdvance,
          /* translations */t[/* translations */9],
          /* answer */t[/* answer */10]
        ];
}

function $eq(t, x) {
  var match = t[/* answer */10];
  if (match !== undefined) {
    return match === x;
  } else {
    return false;
  }
}

function $great$eq(t, x) {
  var match = t[/* answer */10];
  if (match !== undefined) {
    return match >= x;
  } else {
    return false;
  }
}

function $less$eq(t, x) {
  var match = t[/* answer */10];
  if (match !== undefined) {
    return match <= x;
  } else {
    return false;
  }
}

function setAnswer(t, scale) {
  var match = t[/* answer */10];
  var tmp;
  if (match !== undefined) {
    var match$1 = $eq(t, scale);
    tmp = match$1 ? undefined : scale;
  } else {
    tmp = scale;
  }
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* scale */t[/* scale */3],
          /* includeZero */t[/* includeZero */4],
          /* description */t[/* description */5],
          /* label */t[/* label */6],
          /* representation */t[/* representation */7],
          /* autoAdvance */t[/* autoAdvance */8],
          /* translations */t[/* translations */9],
          /* answer */tmp
        ];
}

function answered(t) {
  return t[/* answer */10] !== undefined;
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function text(t) {
  return t[/* text */2];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function description(t) {
  return t[/* description */5];
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */5];
  }
}

function label(t) {
  return t[/* label */6];
}

function getLabelTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* label */2];
  } else {
    return t[/* label */6];
  }
}

function getVeryUnlikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  console.log("sdfsdf");
  console.log(translation);
  if (translation !== undefined) {
    return translation[1][/* veryUnlikely */3];
  } else {
    return "Very Unhappy!";
  }
}

function getUnlikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* unlikely */4];
  } else {
    return "Unhappy!";
  }
}

function getMaybeTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* maybe */5];
  } else {
    return "It was ok!";
  }
}

function getLikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* likely */6];
  } else {
    return "Happy!";
  }
}

function getExtremelyLikelyTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */9]);
  if (translation !== undefined) {
    return translation[1][/* extremelyLikely */7];
  } else {
    return "Super Happy!";
  }
}

function representation(t) {
  return t[/* representation */7];
}

function scale(t) {
  return t[/* scale */3];
}

function includeZero(t) {
  return t[/* includeZero */4];
}

function autoAdvance(t) {
  return t[/* autoAdvance */8];
}

function answer(t) {
  return t[/* answer */10];
}

function rating(t) {
  var match = t[/* answer */10];
  if (match !== undefined) {
    return match;
  }
  
}

function answerToString(t) {
  var match = t[/* answer */10];
  if (match !== undefined) {
    return String(match);
  } else {
    return "";
  }
}

function isAnswer(t, x) {
  var match = t[/* answer */10];
  if (match !== undefined) {
    return $eq(t, x);
  } else {
    return false;
  }
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */5]
      ],
      /* :: */[
        /* tuple */[
          "left_label",
          NpsLabel$ReactHooksTemplate.left(t[/* label */6])
        ],
        /* :: */[
          /* tuple */[
            "center_label",
            NpsLabel$ReactHooksTemplate.center(t[/* label */6])
          ],
          /* :: */[
            /* tuple */[
              "right_label",
              NpsLabel$ReactHooksTemplate.right(t[/* label */6])
            ],
            /* :: */[
              /* tuple */[
                "start_from_zero",
                t[/* includeZero */4]
              ],
              /* :: */[
                /* tuple */[
                  "scale",
                  t[/* scale */3] ? 10 : 5
                ],
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  Belt_Option.getWithDefault(t[/* answer */10], -1)
                ],
                encodeArray
              ] : encodeArray);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  var match = Json_decode.field("category", (function (param) {
          return Json_decode.field("scale", (function (param) {
                        return Json_decode.withDefault(10, Json_decode.$$int, param);
                      }), param);
        }), json);
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* scale */match !== 5 ? /* TenScale */1 : /* FiveScale */0,
          /* includeZero */Json_decode.field("category", (function (param) {
                  return Json_decode.field("start_from_zero", (function (param) {
                                return Json_decode.withDefault(true, Json_decode.bool, param);
                              }), param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* label */NpsLabel$ReactHooksTemplate.make(Json_decode.field("category", (function (param) {
                      return Json_decode.field("left_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("center_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("right_label", Json_decode.string, param);
                    }), json)),
          /* representation : Plain */0,
          /* autoAdvance */false,
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "Nps";

exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setText = setText;
exports.getTextTranslation = getTextTranslation;
exports.setDescription = setDescription;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.setLabel = setLabel;
exports.getLabelTranslation = getLabelTranslation;
exports.getVeryUnlikelyTranslation = getVeryUnlikelyTranslation;
exports.getUnlikelyTranslation = getUnlikelyTranslation;
exports.getMaybeTranslation = getMaybeTranslation;
exports.getLikelyTranslation = getLikelyTranslation;
exports.getExtremelyLikelyTranslation = getExtremelyLikelyTranslation;
exports.setRepresentation = setRepresentation;
exports.setAutoAdvance = setAutoAdvance;
exports.$eq = $eq;
exports.$great$eq = $great$eq;
exports.$less$eq = $less$eq;
exports.text = text;
exports.description = description;
exports.label = label;
exports.representation = representation;
exports.scale = scale;
exports.includeZero = includeZero;
exports.autoAdvance = autoAdvance;
exports.answer = answer;
exports.answered = answered;
exports.isAnswer = isAnswer;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.id = id;
exports.uuid = uuid;
exports.setAnswer = setAnswer;
exports.rating = rating;
exports.answerToString = answerToString;
/* RandomId-ReactHooksTemplate Not a pure module */
