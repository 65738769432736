// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");

function QuestionTextPreview(Props) {
  var text = Props.text;
  var description = Props.description;
  var mandatory = Props.mandatory;
  var surveyTheme = Props.surveyTheme;
  var onTitleChange = Props.onTitleChange;
  var onDescriptionChange = Props.onDescriptionChange;
  var match = SurveyTheme$ReactHooksTemplate.name(surveyTheme);
  switch (match) {
    case "bhima" :
    case "yesbank" :
        break;
    default:
      return React.createElement("div", undefined, React.createElement("div", {
                      className: "mb-8"
                    }, React.createElement("div", {
                          className: "text-aurometalsaurus text-sm mb-2 font-normal"
                        }, "Question Text"), React.createElement("input", {
                          className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                          placeholder: "Enter Text",
                          type: "text",
                          value: text,
                          onChange: Curry.__1(onTitleChange)
                        })), React.createElement("p", undefined, mandatory ? React.createElement("span", undefined, " *") : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                      className: "mb-10"
                    }, React.createElement("div", {
                          className: "text-aurometalsaurus text-sm mb-2 font-normal"
                        }, "Description"), React.createElement("input", {
                          className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                          placeholder: "Enter Description",
                          type: "text",
                          value: description,
                          onChange: Curry.__1(onDescriptionChange)
                        })));
  }
  return React.createElement("div", {
              className: "flex flex-col"
            }, React.createElement("p", {
                  className: " text-2xl small:text-lg small:py-5 font-semibold\n        mb-4 small:mb-2 "
                }, text, mandatory ? React.createElement("span", undefined, " *") : React.createElement(React.Fragment, undefined)), React.createElement("p", {
                  className: " text-base mb-12 small:mb-4 "
                }, description));
}

var make = QuestionTextPreview;

exports.make = make;
/* react Not a pure module */
