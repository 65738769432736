// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");

function WelcomeEditor(Props) {
  var welcome = Props.welcome;
  var onWelcomeChange = Props.onWelcomeChange;
  var count = Welcome$ReactHooksTemplate.button(welcome).length;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium flex justify-between"
                    }, "Button Text", React.createElement("p", {
                          className: "text-gray-500 text-xs p-1 flex justify-end"
                        }, String(count) + (" / " + String(24)))), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Enter Button Text",
                      type: "text",
                      value: Welcome$ReactHooksTemplate.button(welcome),
                      onChange: (function ($$event) {
                          var formTargetValue = Utils$ReactHooksTemplate.formTargetValue($$event);
                          if (Utils$ReactHooksTemplate.checkMaxLength24(formTargetValue)) {
                            return Curry._1(onWelcomeChange, Welcome$ReactHooksTemplate.setButton(welcome, formTargetValue));
                          } else {
                            return 0;
                          }
                        })
                    })));
}

var maxCount = 24;

var make = WelcomeEditor;

exports.maxCount = maxCount;
exports.make = make;
/* react Not a pure module */
