// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function decode(json) {
  return /* record */[
          /* title */Json_decode.field("title", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* button */Json_decode.field("button", Json_decode.string, json)
        ];
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* title */"",
          /* description */"",
          /* button */"",
          /* translations : [] */0,
          /* bgImage */undefined
        ];
}

function make(title, description, button, bgImage) {
  return /* record */[
          /* id */undefined,
          /* title */title,
          /* description */description,
          /* button */button,
          /* translations : [] */0,
          /* bgImage */bgImage
        ];
}

function title(t) {
  return t[/* title */1];
}

function description(t) {
  return t[/* description */2];
}

function button(t) {
  return t[/* button */3];
}

function bgImage(t) {
  return t[/* bgImage */5];
}

function id(t) {
  return t[/* id */0];
}

function setTitle(t, title) {
  return /* record */[
          /* id */t[/* id */0],
          /* title */title,
          /* description */t[/* description */2],
          /* button */t[/* button */3],
          /* translations */t[/* translations */4],
          /* bgImage */t[/* bgImage */5]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* title */t[/* title */1],
          /* description */description,
          /* button */t[/* button */3],
          /* translations */t[/* translations */4],
          /* bgImage */t[/* bgImage */5]
        ];
}

function setButton(t, button) {
  return /* record */[
          /* id */t[/* id */0],
          /* title */t[/* title */1],
          /* description */t[/* description */2],
          /* button */button,
          /* translations */t[/* translations */4],
          /* bgImage */t[/* bgImage */5]
        ];
}

function setBgImage(t, bgImage) {
  return /* record */[
          /* id */t[/* id */0],
          /* title */t[/* title */1],
          /* description */t[/* description */2],
          /* button */t[/* button */3],
          /* translations */t[/* translations */4],
          /* bgImage */bgImage
        ];
}

function getTitleTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */4]);
  if (translation !== undefined) {
    return translation[1][/* title */0];
  } else {
    return t[/* title */1];
  }
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */4]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */2];
  }
}

function getButtonTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */4]);
  if (translation !== undefined) {
    return translation[1][/* button */2];
  } else {
    return t[/* button */3];
  }
}

function toJson($staropt$star, t) {
  var destroy = $staropt$star !== undefined ? $staropt$star : false;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "title",
                t[/* title */1]
              ],
              /* :: */[
                /* tuple */[
                  "description",
                  t[/* description */2]
                ],
                /* :: */[
                  /* tuple */[
                    "button",
                    t[/* button */3]
                  ],
                  /* :: */[
                    /* tuple */[
                      "id",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), t[/* id */0])
                    ],
                    /* :: */[
                      /* tuple */[
                        "_destroy",
                        destroy
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function decodeWelcome(json) {
  return /* record */[
          /* id */Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          /* title */Json_decode.field("title", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* button */Json_decode.field("button", Json_decode.string, json),
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* bgImage */Json_decode.optional((function (param) {
                  return Json_decode.field("bg_image", Json_decode.string, param);
                }), json)
        ];
}

function fromJson(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("survey", (function (param) {
                              return Json_decode.field("welcome", decodeWelcome, param);
                            }), param);
              }), json);
}

var categoryType = "welcome";

exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setTitle = setTitle;
exports.setDescription = setDescription;
exports.setButton = setButton;
exports.setBgImage = setBgImage;
exports.title = title;
exports.id = id;
exports.description = description;
exports.button = button;
exports.getTitleTranslation = getTitleTranslation;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.getButtonTranslation = getButtonTranslation;
exports.bgImage = bgImage;
exports.toJson = toJson;
exports.decodeWelcome = decodeWelcome;
exports.fromJson = fromJson;
/* No side effect */
