// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("./filter.bs.js");

function make(text, filter) {
  return /* record */[
          /* text */text,
          /* filter */filter
        ];
}

function toString(t) {
  return Utils$ReactHooksTemplate.humanize(t[/* text */0]);
}

function id(t) {
  return Filter$ReactHooksTemplate.id(t[/* filter */1]);
}

function filter(t) {
  return t[/* filter */1];
}

exports.make = make;
exports.toString = toString;
exports.id = id;
exports.filter = filter;
/* Utils-ReactHooksTemplate Not a pure module */
