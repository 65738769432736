// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decode(json) {
  return /* record */[/* text */Json_decode.field("text", Json_decode.string, json)];
}

function make(text) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */text,
          /* image */undefined,
          /* translations : [] */0
        ];
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* image */undefined,
          /* translations : [] */0
        ];
}

function text(t) {
  return t[/* text */2];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */4]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* image */t[/* image */3],
          /* translations */t[/* translations */4]
        ];
}

function image(t) {
  return t[/* image */3];
}

function uuid(t) {
  return t[/* uuid */1];
}

function id(t) {
  return t[/* id */0];
}

function toJson($staropt$star, position, t) {
  var destroy = $staropt$star !== undefined ? $staropt$star : false;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "text",
                t[/* text */2]
              ],
              /* :: */[
                /* tuple */[
                  "position",
                  position
                ],
                /* :: */[
                  /* tuple */[
                    "id",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), t[/* id */0])
                  ],
                  /* :: */[
                    /* tuple */[
                      "_destroy",
                      destroy
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* image */Json_decode.field("img_url", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json)
        ];
}

var categoryType = "thank_you";

exports.categoryType = categoryType;
exports.make = make;
exports.empty = empty;
exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.image = image;
exports.uuid = uuid;
exports.id = id;
exports.setText = setText;
exports.toJson = toJson;
exports.fromJson = fromJson;
/* RandomId-ReactHooksTemplate Not a pure module */
