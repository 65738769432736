// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function ratingToInt(param) {
  switch (param) {
    case /* Void */0 :
        return -1;
    case /* One */1 :
        return 1;
    case /* Two */2 :
        return 2;
    case /* Three */3 :
        return 3;
    case /* Four */4 :
        return 4;
    case /* Five */5 :
        return 5;
    
  }
}

function intToRating(param) {
  var switcher = param - 1 | 0;
  if (switcher > 4 || switcher < 0) {
    return /* Void */0;
  } else {
    return switcher + 1 | 0;
  }
}

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json)
        ];
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* description */"",
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function make(text, description, uuid) {
  return /* record */[
          /* id */undefined,
          /* uuid */uuid,
          /* text */text,
          /* description */description,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* description */t[/* description */3],
          /* translations */t[/* translations */4],
          /* answer */t[/* answer */5]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */description,
          /* translations */t[/* translations */4],
          /* answer */t[/* answer */5]
        ];
}

function setAnswer(t, rating) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* translations */t[/* translations */4],
          /* answer */rating
        ];
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function text(t) {
  return t[/* text */2];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */4]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */4]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */3];
  }
}

function description(t) {
  return t[/* description */3];
}

function answer(t) {
  return t[/* answer */5];
}

function answered(t) {
  return t[/* answer */5] !== undefined;
}

function answerToString(t) {
  var match = t[/* answer */5];
  if (match !== undefined) {
    var param = match;
    switch (param) {
      case /* Void */0 :
          return "-1";
      case /* One */1 :
          return "1";
      case /* Two */2 :
          return "2";
      case /* Three */3 :
          return "3";
      case /* Four */4 :
          return "4";
      case /* Five */5 :
          return "5";
      
    }
  } else {
    return "";
  }
}

function rating(t) {
  var match = t[/* answer */5];
  if (match !== undefined) {
    return match;
  } else {
    return /* Void */0;
  }
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */3]
      ],
      /* [] */0
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  ratingToInt(Belt_Option.getWithDefault(t[/* answer */5], /* Void */0))
                ],
                encodeArray
              ] : encodeArray);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "StarRating";

exports.ratingToInt = ratingToInt;
exports.intToRating = intToRating;
exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setText = setText;
exports.getTextTranslation = getTextTranslation;
exports.setDescription = setDescription;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.setAnswer = setAnswer;
exports.text = text;
exports.description = description;
exports.answer = answer;
exports.answered = answered;
exports.rating = rating;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.id = id;
exports.uuid = uuid;
exports.answerToString = answerToString;
/* RandomId-ReactHooksTemplate Not a pure module */
