// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return /* record */[
          /* title */Json_decode.field("title", (function (x) {
                  return /* record */[/* text_color */Json_decode.field("text_color", Json_decode.string, x)];
                }), json),
          /* series */Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), { }), "series"), "empty"),
          /* drilldown */Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), { }), "drilldown"), "empty")
        ];
}

exports.fromJson = fromJson;
/* No side effect */
