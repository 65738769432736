import nepal from "./nepal.json";

const mainSeries = {
  mapData: nepal,
  name: "Nepal",
  dataLabels: {
    enabled: true,
    color: "#FFFFFF",
    format: "{point.hc-key}: {point.value}",
  },
  data: [
    { "hc-key": "BAGMATI", drilldown: "BAGMATI", value: -78 },
    { "hc-key": "BHERI", drilldown: "BHERI", value: 85 },
    { "hc-key": "DHAWALAGIRI", drilldown: "DHAWALAGIRI", value: -38 },
    { "hc-key": "GANDAKI", drilldown: "GANDAKI", value: 95 },
    { "hc-key": "JANAKPUR", drilldown: "JANAKPUR", value: -98 },
    { "hc-key": "KARNALI", drilldown: "KARNALI", value: -49 },
    { "hc-key": "KOSHI", drilldown: "KOSHI", value: -55 },
    { "hc-key": "LUMBINI", drilldown: "LUMBINI", value: 85 },
    { "hc-key": "MAHAKALI", drilldown: "MAHAKALI", value: 100 },
    { "hc-key": "MECHI", drilldown: "MECHI", value: -36 },
    { "hc-key": "NARAYANI", drilldown: "NARAYANI", value: -18 },
    { "hc-key": "RAPTI", drilldown: "RAPTI", value: -37 },
    { "hc-key": "SAGARMATHA", drilldown: "SAGARMATHA", value: 44 },
    { "hc-key": "SETI", drilldown: "SETI", value: 54 },
  ],
};

export { mainSeries };
