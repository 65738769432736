// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");

function email(t) {
  return t[/* email */0];
}

function lob(t) {
  return t[/* lob */2];
}

function touchPoint(t) {
  return t[/* touchPoint */1];
}

function open_(t) {
  return t[/* open_ */3];
}

function closed(t) {
  return t[/* closed */4];
}

function parked(t) {
  return t[/* parked */5];
}

function resolved(t) {
  return t[/* resolved */6];
}

function fromJson(json) {
  return /* record */[
          /* email */Json_decode.field("agent", Json_decode.string, json),
          /* touchPoint */Json_decode.optional((function (param) {
                  return Json_decode.field("touch_point", Json_decode.string, param);
                }), json),
          /* lob */Json_decode.optional((function (param) {
                  return Json_decode.field("lob", Json_decode.string, param);
                }), json),
          /* open_ */Json_decode.field("open", Json_decode.$$int, json),
          /* closed */Json_decode.field("closed", Json_decode.$$int, json),
          /* parked */Json_decode.field("parked", Json_decode.$$int, json),
          /* resolved */Json_decode.field("resolved", Json_decode.$$int, json)
        ];
}

var AgentData = {
  email: email,
  lob: lob,
  touchPoint: touchPoint,
  open_: open_,
  closed: closed,
  parked: parked,
  resolved: resolved,
  fromJson: fromJson
};

function fetchAgentWiseSummary(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/tickets/agent_wise_summary", (function (param) {
                return Json_decode.field("agent_wise_summary", (function (param) {
                              return Json_decode.list(fromJson, param);
                            }), param);
              }));
}

var initialState = /* record */[
  /* loading */true,
  /* filter */false,
  /* data : [] */0
];

function AgentWiseSummary(Props) {
  var match = React.useState((function () {
          return initialState;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          fetchAgentWiseSummary(/* () */0).then((function (agentDataList) {
                  return Promise.resolve(Curry._1(setState, (function (state) {
                                    return /* record */[
                                            /* loading */false,
                                            /* filter */state[/* filter */1],
                                            /* data */agentDataList
                                          ];
                                  })));
                }));
          return ;
        }), ([]));
  var onApplyFilter = function (param) {
    Curry._1(setState, (function (state) {
            return /* record */[
                    /* loading */true,
                    /* filter */false,
                    /* data */state[/* data */2]
                  ];
          }));
    fetchAgentWiseSummary(/* () */0).then((function (agentDataList) {
            return Promise.resolve(Curry._1(setState, (function (state) {
                              return /* record */[
                                      /* loading */false,
                                      /* filter */state[/* filter */1],
                                      /* data */agentDataList
                                    ];
                            })));
          }));
    return /* () */0;
  };
  if (state[/* loading */0] === true) {
    return React.createElement("p", undefined, "Loading");
  } else {
    var match$1 = state[/* filter */1];
    return React.createElement(React.Fragment, undefined, match$1 ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return Curry._1(setState, (function (state) {
                                        return /* record */[
                                                /* loading */state[/* loading */0],
                                                /* filter */false,
                                                /* data */state[/* data */2]
                                              ];
                                      }));
                        }),
                      onApply: onApplyFilter,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Agent Summary"), React.createElement("button", {
                            className: "bg-ghostwhite border border-gray-400\n            shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "filters",
                            onClick: (function (param) {
                                return Curry._1(setState, (function (state) {
                                              return /* record */[
                                                      /* loading */state[/* loading */0],
                                                      /* filter */true,
                                                      /* data */state[/* data */2]
                                                    ];
                                            }));
                              })
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.filterButton
                              }), "Filters")), React.createElement("div", {
                        className: "flex items-center px-30-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Email", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Touch Point", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Lob", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Open", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Parked", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Closed", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Resolved", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Total", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                          return React.createElement("tr", {
                                                      key: x[/* email */0],
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, x[/* email */0]), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.getWithDefault(x[/* touchPoint */1], "")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.getWithDefault(x[/* lob */2], "")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x[/* open_ */3])), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x[/* parked */5])), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x[/* closed */4])), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x[/* resolved */6])), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(((x[/* resolved */6] + x[/* open_ */3] | 0) + x[/* parked */5] | 0) + x[/* closed */4] | 0)));
                                        }), state[/* data */2])))))));
  }
}

var make = AgentWiseSummary;

exports.AgentData = AgentData;
exports.fetchAgentWiseSummary = fetchAgentWiseSummary;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */
