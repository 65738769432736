// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Project$ReactHooksTemplate = require("./project.bs.js");
var SurveyList$ReactHooksTemplate = require("./SurveyList.bs.js");
var ProjectList$ReactHooksTemplate = require("./ProjectList.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var ProjectCreate$ReactHooksTemplate = require("./ProjectCreate.bs.js");

function fetchProjects(currentSearchKey) {
  var url = currentSearchKey !== undefined ? "/api/v1/projects/?search=" + (String(Caml_option.valFromOption(currentSearchKey)) + "") : "/api/v1/projects/";
  return Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                return Json_decode.field("projects", (function (param) {
                              return Json_decode.list(Project$ReactHooksTemplate.fromJson, param);
                            }), param);
              }));
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function Projects(Props) {
  var search = Props.search;
  var match = React.useState((function () {
          return /* GetSurveys */0;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setSelectedProjectId = match$1[1];
  var match$2 = React.useState((function () {
          return ;
        }));
  var setSelectedProject = match$2[1];
  var selectedProject = match$2[0];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setNotification = match$3[1];
  var notification = match$3[0];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setProjectCreate = match$4[1];
  var projectCreate = match$4[0];
  var match$5 = React.useState((function () {
          return ;
        }));
  var setSearchKey = match$5[1];
  var searchKey = match$5[0];
  var queryParams = Querystringify.parse(search);
  var match$6 = Js_dict.get(queryParams, "search");
  var currentSearchKey;
  if (match$6 !== undefined) {
    var x = match$6;
    currentSearchKey = x === "" ? undefined : x;
  } else {
    currentSearchKey = undefined;
  }
  var handleSearch = function (param) {
    if (searchKey !== undefined) {
      return ReasonReactRouter.push("/projects/all?search=" + (String(searchKey) + ""));
    } else {
      return ReasonReactRouter.push("/projects/all?");
    }
  };
  var setProject = function (project) {
    if (project !== undefined) {
      var x = project;
      Curry._1(setSelectedProjectId, (function (param) {
              return Project$ReactHooksTemplate.id(x);
            }));
      return Curry._1(setSelectedProject, (function (param) {
                    return x;
                  }));
    } else {
      Curry._1(setSelectedProjectId, (function (param) {
              return ;
            }));
      return Curry._1(setSelectedProject, (function (param) {
                    return ;
                  }));
    }
  };
  var toggleProjectCreate = function (param) {
    return Curry._1(setProjectCreate, (function (param) {
                  return !projectCreate;
                }));
  };
  React.useEffect((function () {
          fetchProjects(currentSearchKey).then((function (projects) {
                  return Promise.resolve((Curry._1(setState, (function (param) {
                                      return /* Ready */[projects];
                                    })), setProject(Belt_List.head(projects)), Curry._1(setSearchKey, (function (param) {
                                      return currentSearchKey;
                                    }))));
                }));
          return ;
        }), /* tuple */[
        projectCreate,
        currentSearchKey
      ]);
  if (state) {
    var tmp;
    if (notification !== undefined) {
      var match$7 = notification;
      tmp = React.createElement("div", {
            className: "pt-1"
          }, React.createElement(Notification$ReactHooksTemplate.make, {
                text: match$7[0],
                isError: match$7[1],
                handleClose: (function (param) {
                    return Curry._1(setNotification, (function (param) {
                                  return ;
                                }));
                  })
              }));
    } else {
      tmp = React.createElement(React.Fragment, undefined);
    }
    return React.createElement("div", {
                className: "min-h-screen"
              }, tmp, React.createElement("div", {
                    className: "flex min-h-screen"
                  }, React.createElement("div", {
                        className: "w-1/6 bg-ghostwhiteblue min-h-screen"
                      }, React.createElement(ProjectList$ReactHooksTemplate.make, {
                            searchKey: searchKey,
                            setSearchKey: setSearchKey,
                            handleSearch: handleSearch,
                            projects: state[0],
                            selectedProjectId: match$1[0],
                            setProject: setProject,
                            toggleProjectCreate: toggleProjectCreate
                          })), React.createElement("div", {
                        className: "w-5/6 min-h-screen"
                      }, selectedProject !== undefined ? React.createElement(SurveyList$ReactHooksTemplate.make, {
                              search: search,
                              selectedProject: selectedProject
                            }) : React.createElement(React.Fragment, undefined))), projectCreate ? React.createElement("div", {
                      className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                      style: {
                        height: "calc(100vh + 5rem)"
                      }
                    }, React.createElement("div", {
                          className: "bg-white modal-survey z-10 shadow relative border border-gray-300 pointer-events-auto"
                        }, React.createElement("div", {
                              className: "p-8 text-lg font-bold flex justify-between"
                            }, React.createElement("div", undefined, "Create New Project"), React.createElement("img", {
                                  className: "h-14-px float-right",
                                  src: Image$ReactHooksTemplate.closeIcon,
                                  onClick: toggleProjectCreate
                                })), React.createElement("div", {
                              className: "flex flex-col justify-between h-full"
                            }, React.createElement(ProjectCreate$ReactHooksTemplate.make, {
                                  toggleProjectCreate: toggleProjectCreate
                                })))) : React.createElement(React.Fragment, undefined));
  } else {
    return React.createElement(React.Fragment, undefined, "getting surveys");
  }
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* per_page */"1"
];

var make = Projects;

exports.fetchProjects = fetchProjects;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.emptyPageInfo = emptyPageInfo;
exports.make = make;
/* react Not a pure module */
