// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function user_email(t) {
  return t[/* user_email */0];
}

function comment(t) {
  return t[/* comment */1];
}

function fromJson(json) {
  return /* record */[
          /* user_email */Json_decode.field("user", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json),
          /* comment */Json_decode.field("more_details", (function (param) {
                  return Json_decode.field("comment", Json_decode.string, param);
                }), json)
        ];
}

var activityType = "close";

exports.activityType = activityType;
exports.user_email = user_email;
exports.comment = comment;
exports.fromJson = fromJson;
/* No side effect */
