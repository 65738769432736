// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var EmailSelected$ReactHooksTemplate = require("./emailSelected.bs.js");

function getDomains(t) {
  return t[/* domains */0];
}

function setSelected(selected, t) {
  return /* record */[
          /* domains */t[/* domains */0],
          /* selected */selected
        ];
}

function resetSelected(t) {
  return /* record */[
          /* domains */t[/* domains */0],
          /* selected */undefined
        ];
}

function selected(t) {
  return t[/* selected */1];
}

function isSelected(t) {
  return Belt_Option.isSome(t[/* selected */1]);
}

function fromJson(json) {
  return /* record */[
          /* domains */Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("domains", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          /* selected */Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("selected", (function (param) {
                                return Json_decode.optional(EmailSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        ];
}

function toString(t) {
  return EmailSelected$ReactHooksTemplate.toString(Belt_Option.getWithDefault(t[/* selected */1], ""));
}

function encodeParams(t) {
  return Pervasives.$at(/* :: */[
              /* tuple */[
                "domains",
                Json_encode.list((function (prim) {
                        return prim;
                      }), t[/* domains */0])
              ],
              /* [] */0
            ], Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t[/* selected */1], EmailSelected$ReactHooksTemplate.encode));
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "category_type",
                "email"
              ],
              /* :: */[
                /* tuple */[
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                /* [] */0
              ]
            ]);
}

exports.getDomains = getDomains;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.toString = toString;
exports.selected = selected;
exports.isSelected = isSelected;
exports.encode = encode;
exports.fromJson = fromJson;
/* Utils-ReactHooksTemplate Not a pure module */
