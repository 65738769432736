// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function make(id, resourceType) {
  return /* record */[
          /* id */id,
          /* resourceType */resourceType
        ];
}

function resourceType(t) {
  return t[/* resourceType */1];
}

function resourceTypeString(t) {
  var match = t[/* resourceType */1];
  if (match.tag) {
    return "User";
  } else {
    return "Role";
  }
}

function id(t) {
  return t[/* id */0];
}

function decodeRole(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json)
        ];
}

function decodeUser(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* email */Json_decode.field("email", Json_decode.string, json)
        ];
}

function decode(json) {
  var resourceType = Json_decode.field("shareable_type", Json_decode.string, json);
  var id = Json_decode.optional((function (param) {
          return Json_decode.field("id", Json_decode.$$int, param);
        }), json);
  if (resourceType === "Role") {
    return /* record */[
            /* id */id,
            /* resourceType : Role */Block.__(0, [/* record */[
                  /* id */Json_decode.field("shareable", (function (param) {
                          return Json_decode.field("id", Json_decode.$$int, param);
                        }), json),
                  /* name */Json_decode.field("shareable", (function (param) {
                          return Json_decode.field("name", Json_decode.string, param);
                        }), json)
                ]])
          ];
  } else {
    return /* record */[
            /* id */id,
            /* resourceType : User */Block.__(1, [/* record */[
                  /* id */Json_decode.field("shareable", (function (param) {
                          return Json_decode.field("id", Json_decode.$$int, param);
                        }), json),
                  /* email */Json_decode.field("shareable", (function (param) {
                          return Json_decode.field("email", Json_decode.string, param);
                        }), json)
                ]])
          ];
  }
}

function encode($staropt$star, t) {
  var destroy = $staropt$star !== undefined ? $staropt$star : false;
  var match = t[/* resourceType */1];
  var encodeArray_000 = /* tuple */[
    "shareable_type",
    resourceTypeString(t)
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "shareable_id",
      match[0][/* id */0]
    ],
    /* :: */[
      /* tuple */[
        "id",
        Json_encode.nullable((function (prim) {
                return prim;
              }), t[/* id */0])
      ],
      /* [] */0
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(destroy ? /* :: */[
                /* tuple */[
                  "_destroy",
                  destroy
                ],
                encodeArray
              ] : encodeArray);
}

exports.make = make;
exports.resourceType = resourceType;
exports.resourceTypeString = resourceTypeString;
exports.id = id;
exports.decodeRole = decodeRole;
exports.decodeUser = decodeUser;
exports.decode = decode;
exports.encode = encode;
/* No side effect */
