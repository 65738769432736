// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function name(t) {
  return t[/* name */0];
}

function count(t) {
  return t[/* count */1];
}

function status(t) {
  return t[/* status */2];
}

function decode(json) {
  return /* record */[
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* count */Json_decode.field("count", (function (param) {
                  return Json_decode.withDefault(0, Json_decode.$$int, param);
                }), json),
          /* status */Json_decode.field("status", Json_decode.string, json)
        ];
}

var customerSourceType = "FeedFile";

exports.name = name;
exports.count = count;
exports.status = status;
exports.customerSourceType = customerSourceType;
exports.decode = decode;
/* No side effect */
