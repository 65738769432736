// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");

function InsightsIndex(Props) {
  var content = Props.content;
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var match = url[/* path */0];
  var selected;
  if (match && match[0] === "insights") {
    var match$1 = match[1];
    if (match$1) {
      switch (match$1[0]) {
        case "correlation" :
            selected = match$1[1] ? "dashboard" : "correlation";
            break;
        case "dashboard" :
            selected = "dashboard";
            break;
        default:
          selected = "dashboard";
      }
    } else {
      selected = "dashboard";
    }
  } else {
    selected = "dashboard";
  }
  var match$2 = selected === "dashboard";
  var match$3 = selected === "dashboard";
  var match$4 = selected === "correlation";
  var match$5 = selected === "correlation";
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "min-h-screen w-16 bg-ghostwhite border-r border-gray-300"
                }, React.createElement("a", {
                      href: "/insights/dashboard"
                    }, React.createElement("div", {
                          className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                            match$2 ? "bg-palatinateblue border-palatinateblue" : ""
                          )
                        }, React.createElement("img", {
                              className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                match$3 ? "filter-white" : ""
                              ),
                              title: "Insights Dashboard",
                              src: Image$ReactHooksTemplate.dashboard
                            }))), React.createElement("a", {
                      href: "/insights/correlation"
                    }, React.createElement("div", {
                          className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                            match$4 ? "bg-palatinateblue border-palatinateblue" : ""
                          )
                        }, React.createElement("img", {
                              className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                match$5 ? "filter-white" : ""
                              ),
                              title: "Insights Correlation",
                              src: Image$ReactHooksTemplate.distributions
                            })))), React.createElement("div", {
                  className: "w-screen"
                }, content));
}

var make = InsightsIndex;

exports.make = make;
/* react Not a pure module */
