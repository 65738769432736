// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var VisualizationSettings$ReactHooksTemplate = require("./visualizationSettings.bs.js");

function data(t) {
  return t[/* data */0];
}

function title(t) {
  return t[/* title */1];
}

function onClick(t) {
  return t[/* onClick */3];
}

function settings(t) {
  return t[/* settings */2];
}

function decodeOnClick(json) {
  var onClickAction = Json_decode.field("action", Json_decode.string, json);
  if (onClickAction === "jump_to") {
    var goToDashboardId = Json_decode.field("dashboard_name", Json_decode.string, json);
    return /* GotoDashboard */Block.__(1, [goToDashboardId]);
  } else {
    return /* NoAction */0;
  }
}

function decodeData(json) {
  return /* tuple */[
          Json_decode.field("key", Json_decode.string, json),
          Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("score", (function (param) {
                                return Json_decode.optional(Json_decode.$$float, param);
                              }), param);
                }), json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* data */Json_decode.field("data", (function (param) {
                  return Json_decode.list(decodeData, param);
                }), json),
          /* title */Json_decode.field("title", Json_decode.string, json),
          /* settings */Json_decode.field("settings", (function (param) {
                  return Json_decode.withDefault(undefined, (function (param) {
                                return VisualizationSettings$ReactHooksTemplate.fromJson("score_card", param);
                              }), param);
                }), json),
          /* onClick */Json_decode.withDefault(/* NoAction */0, (function (param) {
                  return Json_decode.field("on_click", decodeOnClick, param);
                }), json)
        ];
}

exports.fromJson = fromJson;
exports.data = data;
exports.title = title;
exports.settings = settings;
exports.onClick = onClick;
/* No side effect */
