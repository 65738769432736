// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Highchart_gaugeJs = require("../../../packs/highchart_gauge.js");
var Highchart_normalJs = require("../../../packs/highchart_normal.js");
var Highchart_clusterJs = require("../../../packs/highchart_cluster.js");
var Highchart_normal2Js = require("../../../packs/highchart_normal2.js");
var Highcharts_heatmapJs = require("../../../packs/highcharts_heatmap.js");
var Highcharts_cluster2Js = require("../../../packs/highcharts_cluster2.js");

function buildColorAxisDict(colorAxis) {
  var dataClasses = $$Array.of_list(List.map((function (x) {
              return Json_encode.object_(/* :: */[
                          /* tuple */[
                            "from",
                            x[/* from */0]
                          ],
                          /* :: */[
                            /* tuple */[
                              "to",
                              x[/* _to */1]
                            ],
                            /* :: */[
                              /* tuple */[
                                "color",
                                x[/* color */2]
                              ],
                              /* [] */0
                            ]
                          ]
                        ]);
            }), colorAxis[/* dataClasses */0]));
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "dataClasses",
                dataClasses
              ],
              /* :: */[
                /* tuple */[
                  "minColor",
                  colorAxis[/* minColor */1]
                ],
                /* :: */[
                  /* tuple */[
                    "maxColor",
                    colorAxis[/* maxColor */2]
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var make = Highchart_normalJs.HighchartsBinding;

var HighchartsBinding = {
  make: make
};

var make$1 = Highchart_gaugeJs.HighchartsGaugeBinding;

var HighchartsGaugeBinding = {
  make: make$1
};

var make$2 = Highcharts_heatmapJs.HeatmapBinding;

var HeatmapBinding = {
  make: make$2
};

var make$3 = Highchart_normal2Js.HighchartsBinding2;

var HighchartsBinding2 = {
  make: make$3
};

var make$4 = Highchart_clusterJs.HighchartsBindingCluster;

var HighchartsBindingCluster = {
  make: make$4
};

var make$5 = Highcharts_cluster2Js.HighchartsBindingCluster2;

var HighchartsBindingCluster2 = {
  make: make$5
};

exports.buildColorAxisDict = buildColorAxisDict;
exports.HighchartsBinding = HighchartsBinding;
exports.HighchartsGaugeBinding = HighchartsGaugeBinding;
exports.HeatmapBinding = HeatmapBinding;
exports.HighchartsBinding2 = HighchartsBinding2;
exports.HighchartsBindingCluster = HighchartsBindingCluster;
exports.HighchartsBindingCluster2 = HighchartsBindingCluster2;
/* make Not a pure module */
