// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");

function empty(param) {
  return /* record */[/* senderId */0];
}

function make(senderId) {
  return senderId;
}

function setSenderId(x) {
  return /* record */[/* senderId */x];
}

function senderId(t) {
  return t[/* senderId */0];
}

function validSenderId(x) {
  return x !== 0;
}

function toForm(t) {
  var match = t[/* senderId */0] !== 0;
  return /* record */[/* senderId */match ? /* Valid */Block.__(0, [t[/* senderId */0]]) : /* Invalid */Block.__(1, [
                t[/* senderId */0],
                /* :: */[
                  "Sender Id Can't be Empty",
                  /* [] */0
                ]
              ])];
}

function fromForm(form) {
  return /* record */[/* senderId */FormField$ReactHooksTemplate.getInputValue(form[/* senderId */0])];
}

function encoder(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "sender_id",
                t[/* senderId */0]
              ],
              /* [] */0
            ]);
}

function decoder(json) {
  return /* record */[/* senderId */Json_decode.field("sender_id", Json_decode.$$int, json)];
}

var modeType = "Sms";

exports.modeType = modeType;
exports.empty = empty;
exports.make = make;
exports.setSenderId = setSenderId;
exports.senderId = senderId;
exports.validSenderId = validSenderId;
exports.toForm = toForm;
exports.fromForm = fromForm;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */
