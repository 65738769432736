// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function make(name, id, parentRef, children) {
  return /* record */[
          /* name */name,
          /* id */id,
          /* parentRef */parentRef,
          /* children */children
        ];
}

function name(t) {
  return t[/* name */0];
}

function id(t) {
  return t[/* id */1];
}

function addChildren(child, t) {
  return /* record */[
          /* name */t[/* name */0],
          /* id */t[/* id */1],
          /* parentRef */t[/* parentRef */2],
          /* children : :: */[
            child,
            t[/* children */3]
          ]
        ];
}

function parentRef(t) {
  return t[/* parentRef */2];
}

exports.name = name;
exports.id = id;
exports.make = make;
exports.addChildren = addChildren;
exports.parentRef = parentRef;
/* No side effect */
