// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var EncryptRsa = require("encrypt-rsa");
var Utils$ReactHooksTemplate = require("./common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./settings/domain.bs.js");
var RsaEncrypt$ReactHooksTemplate = require("./common/rsaEncrypt.bs.js");

function empty(param) {
  return /* record */[
          /* email */Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0),
          /* password */"",
          /* rememberMe */false
        ];
}

function setEmail(email, t) {
  return /* record */[
          /* email */email,
          /* password */t[/* password */1],
          /* rememberMe */t[/* rememberMe */2]
        ];
}

function setPassword(password, t) {
  return /* record */[
          /* email */t[/* email */0],
          /* password */password,
          /* rememberMe */t[/* rememberMe */2]
        ];
}

function toggleRememberMe(t) {
  return /* record */[
          /* email */t[/* email */0],
          /* password */t[/* password */1],
          /* rememberMe */!t[/* rememberMe */2]
        ];
}

function rememberMe(t) {
  return t[/* rememberMe */2];
}

function email(t) {
  return t[/* email */0];
}

function password(t) {
  return t[/* password */1];
}

var SignInParams = {
  empty: empty,
  setEmail: setEmail,
  setPassword: setPassword,
  toggleRememberMe: toggleRememberMe,
  rememberMe: rememberMe,
  email: email,
  password: password
};

function SignIn(Props) {
  var match = React.useState((function () {
          return empty(/* () */0);
        }));
  var setState = match[1];
  var state = match[0];
  var __x = document.querySelector("meta[name='rsa-public-key']");
  var __x$1 = Belt_Option.flatMap((__x == null) ? undefined : Caml_option.some(__x), (function (param) {
          return Caml_option.nullable_to_opt(param.getAttribute("content"));
        }));
  var rsaPublicKey = Belt_Option.getWithDefault(__x$1, "");
  var handleSubmit = function (param) {
    var encryptedPassword = new EncryptRsa.default().encryptStringWithRsaPublicKey(RsaEncrypt$ReactHooksTemplate.EncryptionOptions.make(state[/* password */1], rsaPublicKey));
    return Curry._1(setState, (function (state) {
                  return /* record */[
                          /* email */state[/* email */0],
                          /* password */encryptedPassword,
                          /* rememberMe */state[/* rememberMe */2]
                        ];
                }));
  };
  var match$1 = state[/* rememberMe */2];
  return React.createElement("div", undefined, React.createElement("form", {
                  action: "/users/sign_in",
                  autoComplete: "off",
                  charSet: "UTF-8",
                  method: "post",
                  onSubmit: handleSubmit
                }, React.createElement("input", {
                      name: "authenticity_token",
                      type: "hidden",
                      value: Utils$ReactHooksTemplate.csrfToken
                    }), React.createElement("div", {
                      className: "mb-20-px"
                    }, React.createElement("label", {
                          className: "block text-18-px\n          mb-20-px",
                          name: "email"
                        }, "Email"), React.createElement("input", {
                          className: "border\n        border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none\n        p-10-px w-full",
                          name: "user[email]",
                          type: "email",
                          value: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, state[/* email */0]),
                          onChange: (function (e) {
                              var $$event = e;
                              var email = $$event.target.value;
                              return Curry._1(setState, (function (state) {
                                            return /* record */[
                                                    /* email */email,
                                                    /* password */state[/* password */1],
                                                    /* rememberMe */state[/* rememberMe */2]
                                                  ];
                                          }));
                            })
                        })), React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement("label", {
                          className: "block text-18-px mb-20-px",
                          name: "password"
                        }, "Password"), React.createElement("input", {
                          className: "border\n        border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none\n        p-10-px w-full",
                          autoComplete: "off",
                          name: "user[password]",
                          type: "password",
                          value: state[/* password */1],
                          onChange: (function (e) {
                              var $$event = e;
                              var password = $$event.target.value;
                              return Curry._1(setState, (function (state) {
                                            return /* record */[
                                                    /* email */state[/* email */0],
                                                    /* password */password,
                                                    /* rememberMe */state[/* rememberMe */2]
                                                  ];
                                          }));
                            })
                        })), React.createElement("div", {
                      className: "mb-20-px"
                    }, React.createElement("input", {
                          className: "text-white bg-teal-500 hover:bg-teal-700 p-10-px w-full",
                          name: "commit",
                          type: "submit",
                          value: "Log in"
                        })), React.createElement("label", {
                      className: "flex items-center justify-center\n      mb-20-px",
                      name: "remember_me"
                    }, React.createElement("input", {
                          name: "user[remember_me]",
                          type: "checkbox",
                          value: match$1 ? "1" : "0",
                          onChange: (function (param) {
                              return Curry._1(setState, (function (state) {
                                            return /* record */[
                                                    /* email */state[/* email */0],
                                                    /* password */state[/* password */1],
                                                    /* rememberMe */!state[/* rememberMe */2]
                                                  ];
                                          }));
                            })
                        }), React.createElement("span", {
                          className: "checkbox mr-10-px"
                        }), React.createElement("span", undefined, "Remember Me"))));
}

var make = SignIn;

exports.SignInParams = SignInParams;
exports.make = make;
/* react Not a pure module */
