// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Moment = require("moment");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var PermissionTree$ReactHooksTemplate = require("./permissionTree.bs.js");

function create(id, name, description, createdAt, permissions) {
  return /* record */[
          /* id */id,
          /* name */name,
          /* description */description,
          /* permissions */permissions,
          /* createdAt */createdAt
        ];
}

function id(t) {
  return t[/* id */0];
}

function name(t) {
  return t[/* name */1];
}

function description(t) {
  return t[/* description */2];
}

function createdAt(t) {
  return t[/* createdAt */4];
}

function permissions(t) {
  return t[/* permissions */3];
}

function enabledCapabilities(t) {
  return List.concat(Belt_List.map(t[/* permissions */3], (function (permission) {
                    return PermissionTree$ReactHooksTemplate.enabledCapabilities(/* [] */0, permission);
                  })));
}

function decode(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* permissions */Json_decode.withDefault(/* [] */0, PermissionTree$ReactHooksTemplate.decode, json),
          /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json)))
        ];
}

function debug(t) {
  console.log("Role ID   : " + String(t[/* id */0]));
  console.log("Role Name : " + t[/* name */1]);
  return /* () */0;
}

exports.create = create;
exports.id = id;
exports.name = name;
exports.description = description;
exports.createdAt = createdAt;
exports.permissions = permissions;
exports.enabledCapabilities = enabledCapabilities;
exports.decode = decode;
exports.debug = debug;
/* moment Not a pure module */
