// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* description */"",
          /* groups : :: */[
            /* tuple */[
              RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(/* () */0)),
              ""
            ],
            /* [] */0
          ],
          /* carryForwardFrom */undefined,
          /* answerRequirement : All */0,
          /* representation : Smiley */0,
          /* answer */undefined
        ];
}

function answerRequirementMet(answers, t) {
  var answersSize = Belt_List.size(answers);
  var match = t[/* answerRequirement */6];
  if (typeof match === "number") {
    return Belt_List.size(t[/* groups */4]) === answersSize;
  } else {
    switch (match.tag | 0) {
      case /* AtLeast */0 :
          return match[0] <= answersSize;
      case /* AtMost */1 :
          return match[0] >= answersSize;
      case /* Exactly */2 :
          return match[0] === answersSize;
      case /* Range */3 :
          if (match[0] <= answersSize) {
            return match[1] >= answersSize;
          } else {
            return false;
          }
      
    }
  }
}

function make(text, description, groups, representation, uuid) {
  return /* record */[
          /* id */undefined,
          /* uuid */uuid,
          /* text */text,
          /* description */description,
          /* groups */groups,
          /* carryForwardFrom */undefined,
          /* answerRequirement : All */0,
          /* representation */representation,
          /* answer */undefined
        ];
}

function answered(t, mandatory) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    var match$1 = answerRequirementMet(match, t);
    if (match$1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function text(t) {
  return t[/* text */2];
}

function description(t) {
  return t[/* description */3];
}

function groups(t) {
  return t[/* groups */4];
}

function carryForwardFrom(t) {
  return t[/* carryForwardFrom */5];
}

function representation(t) {
  return t[/* representation */7];
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* description */t[/* description */3],
          /* groups */t[/* groups */4],
          /* carryForwardFrom */t[/* carryForwardFrom */5],
          /* answerRequirement */t[/* answerRequirement */6],
          /* representation */t[/* representation */7],
          /* answer */t[/* answer */8]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */description,
          /* groups */t[/* groups */4],
          /* carryForwardFrom */t[/* carryForwardFrom */5],
          /* answerRequirement */t[/* answerRequirement */6],
          /* representation */t[/* representation */7],
          /* answer */t[/* answer */8]
        ];
}

function setGroups(t, groups) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* groups */groups,
          /* carryForwardFrom */t[/* carryForwardFrom */5],
          /* answerRequirement */t[/* answerRequirement */6],
          /* representation */t[/* representation */7],
          /* answer */t[/* answer */8]
        ];
}

function removeGroup(groupId, groups, t) {
  var newSetOfGroups = Belt_List.keep(groups, (function (param) {
          return param[0] !== groupId;
        }));
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* groups */newSetOfGroups,
          /* carryForwardFrom */t[/* carryForwardFrom */5],
          /* answerRequirement */t[/* answerRequirement */6],
          /* representation */t[/* representation */7],
          /* answer */t[/* answer */8]
        ];
}

function setRepresentation(t, representation) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* groups */t[/* groups */4],
          /* carryForwardFrom */t[/* carryForwardFrom */5],
          /* answerRequirement */t[/* answerRequirement */6],
          /* representation */representation,
          /* answer */t[/* answer */8]
        ];
}

function answer(t) {
  return t[/* answer */8];
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function setAnswer(t, groupAnswer) {
  var groupId = groupAnswer[0];
  var match = t[/* answer */8];
  if (match !== undefined) {
    return /* record */[
            /* id */t[/* id */0],
            /* uuid */t[/* uuid */1],
            /* text */t[/* text */2],
            /* description */t[/* description */3],
            /* groups */t[/* groups */4],
            /* carryForwardFrom */t[/* carryForwardFrom */5],
            /* answerRequirement */t[/* answerRequirement */6],
            /* representation */t[/* representation */7],
            /* answer */Belt_List.concat(Belt_List.keep(match, (function (x) {
                        return x[0] !== groupId;
                      })), /* :: */[
                  groupAnswer,
                  /* [] */0
                ])
          ];
  } else {
    return /* record */[
            /* id */t[/* id */0],
            /* uuid */t[/* uuid */1],
            /* text */t[/* text */2],
            /* description */t[/* description */3],
            /* groups */t[/* groups */4],
            /* carryForwardFrom */t[/* carryForwardFrom */5],
            /* answerRequirement */t[/* answerRequirement */6],
            /* representation */t[/* representation */7],
            /* answer *//* :: */[
              groupAnswer,
              /* [] */0
            ]
          ];
  }
}

function encodeGroup(param) {
  return /* :: */[
          /* tuple */[
            "id",
            param[0]
          ],
          /* :: */[
            /* tuple */[
              "group",
              param[1]
            ],
            /* [] */0
          ]
        ];
}

function encodeGroups(groups) {
  return Json_encode.list(Json_encode.object_, List.map(encodeGroup, groups));
}

function encodeAnswer(param) {
  return /* :: */[
          /* tuple */[
            "id",
            param[0]
          ],
          /* :: */[
            /* tuple */[
              "value",
              param[1]
            ],
            /* [] */0
          ]
        ];
}

function encodeAnswers(answer) {
  return Json_encode.list(Json_encode.object_, List.map(encodeAnswer, answer));
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */3]
      ],
      /* :: */[
        /* tuple */[
          "groups",
          encodeGroups(t[/* groups */4])
        ],
        /* [] */0
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  encodeAnswers(Belt_Option.getWithDefault(t[/* answer */8], /* [] */0))
                ],
                encodeArray
              ] : encodeArray);
}

function decodeGroup(json) {
  return /* tuple */[
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("group", Json_decode.string, json)
        ];
}

function decodeGroups(json) {
  return Json_decode.field("groups", (function (param) {
                return Json_decode.list(decodeGroup, param);
              }), json);
}

function decodeAnswerRequirement(json) {
  var condition = Json_decode.field("condition", Json_decode.string, json);
  switch (condition) {
    case "at_least" :
        return /* AtLeast */Block.__(0, [Json_decode.field("count", Json_decode.$$int, json)]);
    case "at_most" :
        return /* AtMost */Block.__(1, [Json_decode.field("count", Json_decode.$$int, json)]);
    case "exactly" :
        return /* Exactly */Block.__(2, [Json_decode.field("count", Json_decode.$$int, json)]);
    case "range" :
        return /* Range */Block.__(3, [
                  Json_decode.field("from", Json_decode.$$int, json),
                  Json_decode.field("to", Json_decode.$$int, json)
                ]);
    default:
      return /* All */0;
  }
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* groups */Json_decode.field("category", decodeGroups, json),
          /* carryForwardFrom */Json_decode.field("category", (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("groups_from_uid", RandomId$ReactHooksTemplate.fromJson, param);
                              }), param);
                }), json),
          /* answerRequirement */Json_decode.field("category", (function (param) {
                  return Json_decode.withDefault(/* All */0, (function (param) {
                                return Json_decode.field("answer_requirement", decodeAnswerRequirement, param);
                              }), param);
                }), json),
          /* representation : Smiley */0,
          /* answer */undefined
        ];
}

var categoryType = "RatingGroup";

exports.categoryType = categoryType;
exports.empty = empty;
exports.text = text;
exports.description = description;
exports.groups = groups;
exports.carryForwardFrom = carryForwardFrom;
exports.make = make;
exports.id = id;
exports.uuid = uuid;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setAnswer = setAnswer;
exports.answered = answered;
exports.answer = answer;
exports.setGroups = setGroups;
exports.removeGroup = removeGroup;
exports.representation = representation;
exports.setRepresentation = setRepresentation;
exports.toJson = toJson;
exports.fromJson = fromJson;
/* RandomId-ReactHooksTemplate Not a pure module */
