// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");

function MultipleSelectEditor(Props) {
  var multipleSelect = Props.multipleSelect;
  var onMultipleChoiceChange = Props.onMultipleChoiceChange;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4 pb-4 border-b border-gray-300"
                }, React.createElement("div", {
                      className: "flex justify-between"
                    }, React.createElement("div", {
                          className: "text-darkcharcoal text-sm font-medium"
                        }, "Multiple Select"), React.createElement("label", {
                          className: "switch"
                        }, React.createElement("input", {
                              checked: MultipleSelect$ReactHooksTemplate.multipleAnswer(multipleSelect),
                              type: "checkbox",
                              onChange: (function (param) {
                                  return Curry._1(onMultipleChoiceChange, MultipleSelect$ReactHooksTemplate.toggleMultipleAnswer(multipleSelect));
                                })
                            }), React.createElement("span", {
                              className: "slider round"
                            })))), React.createElement("button", {
                  className: "bg-ghostwhite border border-gray-400\n          flex rounded p-2 text-sm mt-2",
                  onClick: (function (param) {
                      return Curry._1(onMultipleChoiceChange, MultipleSelect$ReactHooksTemplate.setChoice(MultipleSelect$ReactHooksTemplate.makeChoice("", false, undefined), multipleSelect));
                    })
                }, React.createElement("div", {
                      className: "flex items-center justify-between gap-1 text-darkcharcoal"
                    }, React.createElement("img", {
                          src: Image$ReactHooksTemplate.plusCircle
                        }), React.createElement("p", undefined, "Add option"))), React.createElement("div", {
                  className: "mb-7 mt-4"
                }, $$Array.of_list(List.mapi((function (i, choice) {
                            return React.createElement("div", {
                                        key: RandomId$ReactHooksTemplate.toString(MultipleSelect$ReactHooksTemplate.choiceId(choice)),
                                        className: "mb-4"
                                      }, React.createElement("div", {
                                            className: "flex justify-between mb-2"
                                          }, React.createElement("div", {
                                                className: "text-darkcharcoal text-sm font-medium"
                                              }, "Choice " + String(i + 1 | 0)), React.createElement("img", {
                                                className: "h-12-px",
                                                src: Image$ReactHooksTemplate.trash,
                                                onClick: (function (param) {
                                                    return Curry._1(onMultipleChoiceChange, MultipleSelect$ReactHooksTemplate.removeChoice(choice, MultipleSelect$ReactHooksTemplate.choices(multipleSelect), multipleSelect));
                                                  })
                                              })), React.createElement("input", {
                                            className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                                            placeholder: "Enter Choice " + String(i + 1 | 0),
                                            type: "text",
                                            value: MultipleSelect$ReactHooksTemplate.getChoiceText(choice),
                                            onChange: (function ($$event) {
                                                var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                return Curry._1(onMultipleChoiceChange, MultipleSelect$ReactHooksTemplate.setChoice(MultipleSelect$ReactHooksTemplate.setChoiceText(value, choice), multipleSelect));
                                              })
                                          }), React.createElement("div", {
                                            className: "text-darkcharcoal text-sm font-light mt-1"
                                          }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                checked: MultipleSelect$ReactHooksTemplate.getAllowNonSpecifiedText(choice),
                                                label: "Enable non specified text",
                                                handleChange: (function (param) {
                                                    return Curry._1(onMultipleChoiceChange, MultipleSelect$ReactHooksTemplate.setChoice(MultipleSelect$ReactHooksTemplate.toggleAllowNonSpecifiedText(choice), multipleSelect));
                                                  }),
                                                checkboxClassName: "custom_checkbox"
                                              })));
                          }), MultipleSelect$ReactHooksTemplate.choices(multipleSelect)))));
}

var make = MultipleSelectEditor;

exports.make = make;
/* react Not a pure module */
