// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function RadioButtonInput(Props) {
  var checked = Props.checked;
  var label = Props.label;
  var handleChange = Props.handleChange;
  var match = Props.indeterminate;
  var indeterminate = match !== undefined ? match : false;
  var match$1 = Props.className;
  var className = match$1 !== undefined ? match$1 : "";
  label.length === 0;
  var domElement = React.useRef(null);
  React.useEffect((function () {
          var y = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
          y.indeterminate = indeterminate;
          return ;
        }));
  return React.createElement("div", {
              className: "items-center mb-4 " + className
            }, React.createElement("input", {
                  ref: domElement,
                  className: "custom-radio",
                  id: "box-shadow",
                  checked: indeterminate ? false : checked,
                  type: "radio",
                  onChange: handleChange
                }), React.createElement("span", {
                  className: "ml-2 font-16-px leading-normal whitespace-normal w-11/12 align-top"
                }, label));
}

var make = RadioButtonInput;

exports.make = make;
/* react Not a pure module */
