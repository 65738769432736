// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var MapSetting$ReactHooksTemplate = require("./mapSetting.bs.js");
var ChartSetting$ReactHooksTemplate = require("./chartSetting.bs.js");
var ScoreCardSetting$ReactHooksTemplate = require("./scoreCardSetting.bs.js");

function fromJson(visualizationType, json) {
  if (visualizationType === "score_card") {
    return /* ScoreCardSetting */Block.__(2, [ScoreCardSetting$ReactHooksTemplate.fromJson(json)]);
  } else if (visualizationType === "map") {
    return /* MapSetting */Block.__(1, [MapSetting$ReactHooksTemplate.fromJson(json)]);
  } else {
    return /* ChartSetting */Block.__(0, [ChartSetting$ReactHooksTemplate.fromJson(json)]);
  }
}

exports.fromJson = fromJson;
/* No side effect */
