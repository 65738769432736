// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var MomentRe = require("bs-moment/src/MomentRe.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");

function make(from, to_) {
  return /* record */[
          /* from */from,
          /* to_ */to_
        ];
}

function from(t) {
  return t[/* from */0];
}

function to_(t) {
  return t[/* to_ */1];
}

function getMomentString(date) {
  if (date !== undefined) {
    return Caml_option.valFromOption(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
}

function toString(t) {
  return "from: " + (getMomentString(t[/* from */0]) + (" to: " + getMomentString(t[/* to_ */1])));
}

function encode(t) {
  return Json_encode.object_(Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("start_date", t[/* from */0]), Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("end_date", t[/* to_ */1])));
}

function convertDateToMoment(date) {
  if (date !== undefined) {
    return Caml_option.some((function (eta) {
                    return MomentRe.moment(undefined, eta);
                  })(date));
  }
  
}

function decode(json) {
  return /* record */[
          /* from */convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("start_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          /* to_ */convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("end_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json))
        ];
}

exports.make = make;
exports.getMomentString = getMomentString;
exports.toString = toString;
exports.encode = encode;
exports.decode = decode;
exports.from = from;
exports.to_ = to_;
/* MomentRe Not a pure module */
