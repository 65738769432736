// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Moment = require("moment");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var FeedFile$ReactHooksTemplate = require("./feedFile.bs.js");

function id(t) {
  return t[/* id */0];
}

function uid(t) {
  return t[/* uid */1];
}

function status(t) {
  return t[/* status */4];
}

function customerSourceType(t) {
  return t[/* customerSourceType */3];
}

function customerSource(t) {
  return t[/* customerSource */2];
}

function createdAt(t) {
  return t[/* createdAt */5];
}

function decode(json) {
  Json_decode.field("customer_source_type", Json_decode.string, json);
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* uid */Json_decode.field("uid", Json_decode.string, json),
          /* customerSource : FeedFile */[Json_decode.field("customer_source", FeedFile$ReactHooksTemplate.decode, json)],
          /* customerSourceType */Json_decode.field("customer_source_type", Json_decode.string, json),
          /* status */Json_decode.field("status", Json_decode.string, json),
          /* createdAt */Moment(new Date(Json_decode.field("created_at", Json_decode.string, json)))
        ];
}

exports.id = id;
exports.uid = uid;
exports.status = status;
exports.customerSourceType = customerSourceType;
exports.customerSource = customerSource;
exports.createdAt = createdAt;
exports.decode = decode;
/* moment Not a pure module */
