// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var ReportDownload$ReactHooksTemplate = require("./ReportDownload.bs.js");

function ReportDownloadsList(Props) {
  var id = Props.id;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setState = match[1];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/campaigns/" + (String(id) + "/report_downloads"), (function (param) {
                    return Json_decode.field("report_downloads", (function (param) {
                                  return Json_decode.list(ReportDownload$ReactHooksTemplate.decode, param);
                                }), param);
                  })).then((function (report_downloads) {
                  return Promise.resolve(Curry._1(setState, (function (param) {
                                    return report_downloads;
                                  })));
                }));
          return ;
        }), ([]));
  var match$2 = Curry._1(match$1[0], /* ShowReport */19);
  return React.createElement(React.Fragment, undefined, match$2 ? React.createElement("div", undefined, React.createElement("div", {
                        className: "flex items-center p-20-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Report Downloads")), React.createElement("div", {
                        className: "flex px-20-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "File", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Status", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Created At", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          })))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (report_download) {
                                          var rid = ReportDownload$ReactHooksTemplate.id(report_download);
                                          var origin = window.location.origin;
                                          var downloadUrl = "" + (String(origin) + ("/api/v1/report_downloads/" + (String(rid) + "/download")));
                                          var match = ReportDownload$ReactHooksTemplate.status(report_download);
                                          return React.createElement("tr", {
                                                      key: String(ReportDownload$ReactHooksTemplate.id(report_download)),
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, ReportDownload$ReactHooksTemplate.name(report_download)), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, ReportDownload$ReactHooksTemplate.Status.toString(ReportDownload$ReactHooksTemplate.status(report_download))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, (function (param) {
                                                              return param.fromNow(false);
                                                            })(ReportDownload$ReactHooksTemplate.createdAt(report_download))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match >= 2 ? React.createElement("a", {
                                                                href: downloadUrl
                                                              }, React.createElement("img", {
                                                                    className: "h-16-px",
                                                                    src: Image$ReactHooksTemplate.downloadButton
                                                                  })) : React.createElement(React.Fragment, undefined)));
                                        }), match[0])))))) : React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        img: Image$ReactHooksTemplate.unAuthorized,
                        text: "401: Unauthorized!!",
                        loading: false
                      })));
}

var emptyState = /* [] */0;

var make = ReportDownloadsList;

exports.emptyState = emptyState;
exports.make = make;
/* react Not a pure module */
