// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var CsatDefaultForm$ReactHooksTemplate = require("./CsatDefaultForm.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function CsatForm(Props) {
  var csat = Props.csat;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var match = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match !== undefined) {
    switch (match) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(CsatDefaultForm$ReactHooksTemplate.make, {
                    csat: csat,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        return React.createElement("div", {
                    className: "flex flex-col"
                  }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                        text: Csat$ReactHooksTemplate.getTextTranslation(csat, SurveyLanguage$ReactHooksTemplate.name(language)),
                        description: Csat$ReactHooksTemplate.getDescriptionTranslation(csat, SurveyLanguage$ReactHooksTemplate.name(language)),
                        mandatory: mandatory,
                        surveyTheme: surveyTheme
                      }), React.createElement("div", {
                        className: "flex w-full justify-between items-start pb-5"
                      }, $$Array.of_list(Belt_List.mapWithIndex(Csat$ReactHooksTemplate.answerTypeCollection(csat), (function (id, xs) {
                                  var code = Csat$ReactHooksTemplate.answerTypeToUnicode(xs);
                                  var match = Csat$ReactHooksTemplate.answered(csat);
                                  var match$1 = Csat$ReactHooksTemplate.getAnswer(csat);
                                  var match$2;
                                  if (match && match$1 !== undefined) {
                                    var match$3 = xs === match$1;
                                    match$2 = match$3 ? /* tuple */[
                                        "text-6xl small:text-5xl",
                                        "pulse",
                                        "text-sm",
                                        "pt-0"
                                      ] : /* tuple */[
                                        "text-5xl small:text-4xl",
                                        "",
                                        "text-xs small:hidden",
                                        "pt-10-px"
                                      ];
                                  } else {
                                    match$2 = /* tuple */[
                                      "text-5xl small:text-4xl",
                                      "",
                                      "text-xs small:hidden",
                                      "pt-10-px"
                                    ];
                                  }
                                  return React.createElement("button", {
                                              key: String(id),
                                              className: "animated " + (String(match$2[1]) + (" flex flex-col items-center\n                  focus:outline-none " + (String(match$2[3]) + ""))),
                                              onClick: (function (param) {
                                                  return Curry._1(onChange, Csat$ReactHooksTemplate.setAnswer(csat, xs));
                                                })
                                            }, React.createElement("span", {
                                                  className: match$2[0]
                                                }, code), React.createElement("div", {
                                                  className: match$2[2]
                                                }, Csat$ReactHooksTemplate.answerTypeToString(csat, xs, SurveyLanguage$ReactHooksTemplate.name(language))));
                                })))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                          language: language
                        }) : React.createElement(React.Fragment, undefined));
    
  }
}

var make = CsatForm;

exports.make = make;
/* react Not a pure module */
