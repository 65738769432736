// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function MobileNumberPreview(Props) {
  var mobileNumber = Props.mobileNumber;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy(/* array */[
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, ""));
    } else {
      Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, value));
    }
    var validNumber = value.search((/^\d{1,17}$/));
    if (validNumber !== 0) {
      return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, ""));
    } else {
      return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, value));
    }
  };
  MobileNumber$ReactHooksTemplate.representation(mobileNumber);
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setText(mobileNumber, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setDescription(mobileNumber, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: MobileNumber$ReactHooksTemplate.getTextTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: MobileNumber$ReactHooksTemplate.getDescriptionTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", undefined, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Input"), React.createElement("input", {
                      className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                      placeholder: MobileNumber$ReactHooksTemplate.getPlaceholderTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                      type: "text",
                      value: MobileNumber$ReactHooksTemplate.answerToString(mobileNumber),
                      onChange: handleOnchange
                    })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MobileNumberPreview;

exports.make = make;
/* react Not a pure module */
