// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("./http.bs.js");
var Image$ReactHooksTemplate = require("./image.bs.js");
var Vendor$ReactHooksTemplate = require("./vendor.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var DeviseSignOut$ReactHooksTemplate = require("./DeviseSignOut.bs.js");

var emptyState_000 = /* vendor */Vendor$ReactHooksTemplate.make(undefined);

var emptyState = /* record */[
  emptyState_000,
  /* selected */"dashboard",
  /* clientName */""
];

function decodeLogo(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("url", Json_decode.string, param);
              }), json);
}

function decodeClientName(json) {
  return Json_decode.field("name", Json_decode.string, json);
}

function Header$QuickSurveyDropdown(Props) {
  var selected = Props.selected;
  var match = selected === "quick_survey";
  var match$1 = selected !== "quick_survey";
  return React.createElement("div", {
              className: "dropdown mr-20-px"
            }, React.createElement("div", {
                  className: "flex items-center font " + (
                    match ? "font-extrabold" : ""
                  )
                }, React.createElement("p", {
                      className: "mr-5-px"
                    }, "Quick Survey")), React.createElement("ul", {
                  className: "dropdown-list shadow text-gray-800 text-sm"
                }, React.createElement("li", {
                      className: "hover:bg-gray-300"
                    }, React.createElement("a", {
                          className: "block p-10-px",
                          href: "/surveys/quick_survey"
                        }, "New Quick Survey")), React.createElement("li", {
                      className: "hover:bg-gray-300"
                    }, React.createElement("a", {
                          className: "block p-10-px",
                          href: "/quick_survey/report"
                        }, "Reports"))), React.createElement("hr", {
                  className: "bg-white border-white w-full rounded mr-20-px\n        header_select_higlighter " + (
                    match$1 ? "hidden" : ""
                  )
                }));
}

var QuickSurveyDropdown = {
  make: Header$QuickSurveyDropdown
};

function Header$SettingsDropdown(Props) {
  var hasPermission = Props.hasPermission;
  var match = Curry._1(hasPermission, /* ShowUser */9) || Curry._1(hasPermission, /* AddUser */6) || Curry._1(hasPermission, /* UpdateUser */8) || Curry._1(hasPermission, /* DeleteUser */7);
  var match$1 = Curry._1(hasPermission, /* ShowRole */13) || Curry._1(hasPermission, /* AddRole */10) || Curry._1(hasPermission, /* UpdateRole */12) || Curry._1(hasPermission, /* DeleteRole */11);
  var match$2 = Curry._1(hasPermission, /* ShowUser */9) || Curry._1(hasPermission, /* AddUser */6) || Curry._1(hasPermission, /* UpdateUser */8) || Curry._1(hasPermission, /* DeleteUser */7);
  var match$3 = Curry._1(hasPermission, /* ShowKpi */21) || Curry._1(hasPermission, /* AddKpi */22) || Curry._1(hasPermission, /* UpdateKpi */23) || Curry._1(hasPermission, /* DeleteKpi */24);
  return React.createElement("div", {
              className: "dropdown"
            }, React.createElement("img", {
                  className: "h-16-px",
                  src: Image$ReactHooksTemplate.cog
                }), React.createElement("div", {
                  className: "dropdown-list right-0 shadow text-gray-800 text-sm"
                }, React.createElement("ul", undefined, React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, match ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/users/all"
                              }, "Users") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, match$1 ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/roles/all"
                              }, "Roles") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, match$2 ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/admin/view"
                              }, "Admin Pages") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, match$3 ? React.createElement("a", {
                                className: "block p-10-px",
                                href: "/users/kpi"
                              }, "KPI Management") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                          className: "hover:bg-gray-300"
                        }, React.createElement("a", {
                              className: "block p-10-px",
                              href: "/users/update_password"
                            }, "Change Password"))), React.createElement("div", {
                      className: "h-1-px bg-gray-300"
                    }), React.createElement("div", {
                      className: "hover:bg-gray-300 p-10-px"
                    }, React.createElement(DeviseSignOut$ReactHooksTemplate.make, { }))));
}

var SettingsDropdown = {
  make: Header$SettingsDropdown
};

function Header(Props) {
  var parent = Props.parent;
  var match = React.useState((function () {
          return emptyState;
        }));
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$1[0];
  React.useEffect((function () {
          console.log("Parent");
          console.log(parent);
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/clients/client_name_and_logo").then((function (json) {
                  return Promise.resolve(Curry._1(setState, (function (param) {
                                    return /* record */[
                                            /* vendor */Vendor$ReactHooksTemplate.make(decodeLogo(json)),
                                            /* selected */parent,
                                            /* clientName */Json_decode.field("name", Json_decode.string, json)
                                          ];
                                  })));
                }));
          return ;
        }), ([]));
  var match$2 = state[/* clientName */2] === "ncell";
  var match$3 = Vendor$ReactHooksTemplate.getLogo(state[/* vendor */0]);
  var match$4 = Curry._1(hasPermission, /* ShowDashboard */0);
  var tmp;
  if (match$4) {
    var match$5 = state[/* selected */1] === "dashboard";
    var match$6 = state[/* selected */1] !== "dashboard";
    tmp = React.createElement("div", {
          className: "mr-20-px"
        }, React.createElement("a", {
              className: match$5 ? "font-extrabold" : "",
              href: "/dashboard"
            }, "Dashboard"), React.createElement("hr", {
              className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                match$6 ? "hidden" : ""
              )
            }));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var match$7 = Curry._1(hasPermission, /* ShowSurvey */5) || Curry._1(hasPermission, /* DeleteSurvey */4) || Curry._1(hasPermission, /* BuildSurvey */3);
  var tmp$1;
  if (match$7) {
    var match$8 = state[/* selected */1] === "surveys";
    var match$9 = state[/* selected */1] !== "surveys";
    tmp$1 = React.createElement("div", {
          className: "mr-20-px"
        }, React.createElement("a", {
              className: match$8 ? "font-extrabold" : "",
              href: "/projects/all"
            }, "Surveys"), React.createElement("hr", {
              className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                match$9 ? "hidden" : ""
              )
            }));
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined);
  }
  var match$10 = Curry._1(hasPermission, /* ShowCampaign */14) || Curry._1(hasPermission, /* CreateCampaign */15);
  var tmp$2;
  if (match$10) {
    var match$11 = state[/* selected */1] === "campaigns";
    var match$12 = state[/* selected */1] !== "campaigns";
    tmp$2 = React.createElement("div", {
          className: "mr-20-px"
        }, React.createElement("a", {
              className: match$11 ? "font-extrabold" : "",
              href: "/campaigns/all"
            }, "Campaigns"), React.createElement("hr", {
              className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                match$12 ? "hidden" : ""
              )
            }));
  } else {
    tmp$2 = React.createElement(React.Fragment, undefined);
  }
  var match$13 = Curry._1(hasPermission, /* ShowQuickSurvey */16);
  var match$14 = Curry._1(hasPermission, /* ResolveTickets */2);
  var tmp$3;
  if (match$14) {
    var match$15 = Curry._1(hasPermission, /* ManageTickets */1);
    if (match$15) {
      var match$16 = state[/* selected */1] === "tickets";
      var match$17 = state[/* selected */1] !== "tickets";
      tmp$3 = React.createElement("div", {
            className: "mr-20-px"
          }, React.createElement("a", {
                className: match$16 ? "font-extrabold" : "",
                href: "/tickets/manage/all"
              }, "Tickets"), React.createElement("hr", {
                className: "bg-white border-white w-full rounded mr-20-px\n                  header_select_higlighter " + (
                  match$17 ? "hidden" : ""
                )
              }));
    } else {
      var match$18 = state[/* selected */1] === "tickets";
      var match$19 = state[/* selected */1] !== "tickets";
      tmp$3 = React.createElement("div", {
            className: "mr-20-px"
          }, React.createElement("a", {
                className: match$18 ? "font-extrabold" : "",
                href: "/tickets/work/all"
              }, "Tickets"), React.createElement("hr", {
                className: "bg-white border-white w-full rounded mr-20-px\n                  header_select_higlighter " + (
                  match$19 ? "hidden" : ""
                )
              }));
    }
  } else {
    tmp$3 = React.createElement(React.Fragment, undefined);
  }
  var match$20 = Curry._1(hasPermission, /* EditTextCategories */20);
  var tmp$4;
  if (match$20) {
    var match$21 = state[/* selected */1] === "comment_categorization";
    var match$22 = state[/* selected */1] !== "comment_categorization";
    tmp$4 = React.createElement("div", {
          className: "mr-20-px"
        }, React.createElement("a", {
              className: match$21 ? "font-extrabold" : "",
              href: "/category_edit"
            }, "Comment Categorisation"), React.createElement("hr", {
              className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                match$22 ? "hidden" : ""
              )
            }));
  } else {
    tmp$4 = React.createElement(React.Fragment, undefined);
  }
  var match$23 = Curry._1(hasPermission, /* AddUser */6);
  var tmp$5;
  if (match$23) {
    var match$24 = state[/* selected */1] === "telephonic_surveys";
    var match$25 = state[/* selected */1] !== "telephonic_surveys";
    tmp$5 = React.createElement("div", {
          className: "mr-20-px"
        }, React.createElement("a", {
              className: match$24 ? "font-extrabold" : "",
              href: "/campaigns/telephonic_surveys"
            }, "Telephonic Surveys"), React.createElement("hr", {
              className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                match$25 ? "hidden" : ""
              )
            }));
  } else {
    tmp$5 = React.createElement(React.Fragment, undefined);
  }
  var match$26 = Curry._1(hasPermission, /* AddUser */6);
  var tmp$6;
  if (match$26) {
    var match$27 = state[/* selected */1] === "insights";
    var match$28 = state[/* selected */1] !== "insights";
    tmp$6 = React.createElement("div", {
          className: "mr-20-px"
        }, React.createElement("a", {
              className: match$27 ? "font-extrabold" : "",
              href: "/insights/dashboard"
            }, "Insights"), React.createElement("hr", {
              className: "bg-white border-white w-full rounded mr-20-px\n                    header_select_higlighter " + (
                match$28 ? "hidden" : ""
              )
            }));
  } else {
    tmp$6 = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: match$2 ? "flex flex-col fixed w-full z-10 p-16-px bg-purple-900" : "flex flex-col fixed w-full z-10 p-16-px bg-pinetree"
            }, React.createElement("div", {
                  className: "flex"
                }, React.createElement("div", {
                      className: "flex items-center justify-between text-white text-16-px\n        w-full z-10 leading-tight font-thin"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("img", {
                              className: "h-32-px pr-4 mr-2",
                              src: Image$ReactHooksTemplate.geckolystLogo
                            }), match$3 !== undefined ? React.createElement("img", {
                                className: "h-32-px pl-4 border-l border-teal-200 mr-8",
                                src: match$3
                              }) : React.createElement("div", {
                                className: "h-32-px pl-4 border-l border-teal-200 mr-8"
                              }), tmp, tmp$1, tmp$2, match$13 ? React.createElement(Header$QuickSurveyDropdown, {
                                selected: state[/* selected */1]
                              }) : React.createElement(React.Fragment, undefined), tmp$3, tmp$4, tmp$5, tmp$6), React.createElement("div", undefined, React.createElement(Header$SettingsDropdown, {
                              hasPermission: hasPermission
                            })))));
}

var make = Header;

exports.emptyState = emptyState;
exports.decodeLogo = decodeLogo;
exports.decodeClientName = decodeClientName;
exports.QuickSurveyDropdown = QuickSurveyDropdown;
exports.SettingsDropdown = SettingsDropdown;
exports.make = make;
/* emptyState Not a pure module */
