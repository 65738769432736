// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function name(t) {
  return t[/* name */0];
}

function code(t) {
  return t[/* code */1];
}

function messages(t) {
  return t[/* messages */2];
}

function message(t, key) {
  var match = List.find_opt((function (x) {
          return x[/* key */0] === key;
        }), t[/* messages */2]);
  if (match !== undefined) {
    return match[/* verbiage */1];
  } else {
    switch (key) {
      case "back" :
          return "Back";
      case "mandatory" :
          return "Request your response. This is a mandatory question.";
      case "next" :
          return "Next";
      case "submit" :
          return "Submit";
      default:
        return "";
    }
  }
}

function decodeMessage(json) {
  return /* record */[
          /* key */Json_decode.field("key", Json_decode.string, json),
          /* verbiage */Json_decode.field("verbiage", Json_decode.string, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* code */Json_decode.field("code", Json_decode.string, json),
          /* messages */Json_decode.field("messages", (function (param) {
                  return Json_decode.list(decodeMessage, param);
                }), json)
        ];
}

var $$default = /* record */[
  /* name */"English",
  /* code */"en",
  /* messages : :: */[
    /* record */[
      /* key */"next",
      /* verbiage */"Next"
    ],
    /* :: */[
      /* record */[
        /* key */"back",
        /* verbiage */"Back"
      ],
      /* :: */[
        /* record */[
          /* key */"submit",
          /* verbiage */"Submit"
        ],
        /* :: */[
          /* record */[
            /* key */"mandatory",
            /* verbiage */"Request your response. This is a mandatory question."
          ],
          /* [] */0
        ]
      ]
    ]
  ]
];

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.name = name;
exports.code = code;
exports.messages = messages;
exports.message = message;
exports.decodeMessage = decodeMessage;
exports.fromJson = fromJson;
/* No side effect */
