// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function empty(param) {
  return /* record */[
          /* left */"",
          /* center */"",
          /* right */""
        ];
}

function make(left, center, right) {
  return /* record */[
          /* left */left,
          /* center */center,
          /* right */right
        ];
}

function setLeft(t, left) {
  return /* record */[
          /* left */left,
          /* center */t[/* center */1],
          /* right */t[/* right */2]
        ];
}

function setCenter(t, center) {
  return /* record */[
          /* left */t[/* left */0],
          /* center */center,
          /* right */t[/* right */2]
        ];
}

function setRight(t, right) {
  return /* record */[
          /* left */t[/* left */0],
          /* center */t[/* center */1],
          /* right */right
        ];
}

function left(t) {
  return t[/* left */0];
}

function center(t) {
  return t[/* center */1];
}

function right(t) {
  return t[/* right */2];
}

exports.empty = empty;
exports.make = make;
exports.setLeft = setLeft;
exports.setCenter = setCenter;
exports.setRight = setRight;
exports.left = left;
exports.center = center;
exports.right = right;
/* No side effect */
