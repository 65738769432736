// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Highcharts$ReactHooksTemplate = require("../../common/Highcharts.bs.js");

function MapComponent(Props) {
  var mapAttribute = Props.mapAttribute;
  Props.chart;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var mapHeight;
  if (enlarged) {
    mapHeight = "50%";
  } else {
    var match = chartHeight === "h-64";
    mapHeight = match ? "216" : "432";
  }
  return React.createElement("div", {
              className: chartHeight
            }, React.createElement(Highcharts$ReactHooksTemplate.HighchartsBinding.make, {
                  colorAxis: Highcharts$ReactHooksTemplate.buildColorAxisDict(/* record */[
                        /* dataClasses : :: */[
                          /* record */[
                            /* from */-100,
                            /* _to */0,
                            /* color */"#fc8181"
                          ],
                          /* :: */[
                            /* record */[
                              /* from */0,
                              /* _to */50,
                              /* color */"#f6ad55"
                            ],
                            /* :: */[
                              /* record */[
                                /* from */50,
                                /* _to */100,
                                /* color */"#68d391"
                              ],
                              /* [] */0
                            ]
                          ]
                        ],
                        /* minColor */"#fc8181",
                        /* maxColor */"#68d391"
                      ]),
                  series: mapAttribute[/* series */0],
                  drilldown: mapAttribute[/* drilldown */1],
                  mapHeight: mapHeight,
                  dataLabels: enlarged
                }));
}

var make = MapComponent;

exports.make = make;
/* react Not a pure module */
