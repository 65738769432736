// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var Application$ReactHooksTemplate = require("./Application.bs.js");

ReactDOMRe.renderToElementWithId(React.createElement(Application$ReactHooksTemplate.make, { }), "content");

/*  Not a pure module */
