// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Utils$ReactHooksTemplate = require("./utils.bs.js");

function DeviseSignOut(Props) {
  return React.createElement("form", {
              className: "",
              action: "/users/sign_out",
              method: "post"
            }, React.createElement("input", {
                  name: "_method",
                  type: "hidden",
                  value: "delete"
                }), React.createElement("input", {
                  className: "bg-transparent border border-transparent text-sm font-normal leader-tight cursor-pointer",
                  type: "submit",
                  value: "Logout"
                }), React.createElement("input", {
                  name: "authenticity_token",
                  type: "hidden",
                  value: Utils$ReactHooksTemplate.csrfToken
                }));
}

var make = DeviseSignOut;

exports.make = make;
/* react Not a pure module */
