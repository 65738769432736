// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Client$ReactHooksTemplate = require("../settings/client.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");
var OpinionScaleDefaultForm$ReactHooksTemplate = require("./OpinionScaleDefaultForm.bs.js");

function OpinionScaleForm$OpinionScaleItem(Props) {
  var step = Props.step;
  var className = Props.className;
  var onSelection = Props.onSelection;
  var style = Props.style;
  var representation = Props.representation;
  return React.createElement("button", {
              className: className,
              style: style,
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(onSelection, step);
                })
            }, representation ? React.createElement("div", {
                    className: "py-5 small:py-2"
                  }, React.createElement("span", undefined, String(step))) : React.createElement(React.Fragment, undefined));
}

var OpinionScaleItem = {
  make: OpinionScaleForm$OpinionScaleItem
};

function colorBasedOnLikelyness(index) {
  switch (index) {
    case 0 :
        return "#cf0000";
    case 1 :
        return "#da0000";
    case 2 :
        return "#f12500";
    case 3 :
        return "#ff331a";
    case 4 :
        return "#ff4118";
    case 5 :
        return "#ff541a";
    case 6 :
        return "#ff8000";
    case 7 :
        return "#ffc400";
    case 8 :
        return "#ffd50c";
    case 9 :
        return "#1abf00";
    case 10 :
        return "#169f00";
    default:
      return "#ffffff";
  }
}

function OpinionScaleForm(Props) {
  var opinionScale = Props.opinionScale;
  var customerInfo = Props.customerInfo;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var selectedRating = OpinionScale$ReactHooksTemplate.rating(opinionScale);
  var selectedCss = function (x) {
    var match = OpinionScale$ReactHooksTemplate.isAnswer(opinionScale, x);
    if (match) {
      return "text-blue-800";
    } else {
      return "";
    }
  };
  var likelyness;
  if (selectedRating !== undefined) {
    var rating = selectedRating;
    switch (rating) {
      case 0 :
      case 1 :
          likelyness = /* VeryUnlikely */Block.__(0, [rating]);
          break;
      case 2 :
      case 3 :
      case 4 :
      case 5 :
      case 6 :
          likelyness = /* Unlikely */Block.__(1, [rating]);
          break;
      case 7 :
      case 8 :
          likelyness = /* Maybe */Block.__(2, [rating]);
          break;
      case 9 :
          likelyness = /* Likely */Block.__(3, [rating]);
          break;
      case 10 :
          likelyness = /* ExtremelyLikely */Block.__(4, [rating]);
          break;
      default:
        likelyness = undefined;
    }
  } else {
    likelyness = undefined;
  }
  var filledBackgroundCSS;
  if (likelyness !== undefined) {
    switch (likelyness.tag | 0) {
      case /* VeryUnlikely */0 :
          filledBackgroundCSS = "very-unlikely";
          break;
      case /* Unlikely */1 :
          filledBackgroundCSS = "unlikely";
          break;
      case /* Maybe */2 :
          filledBackgroundCSS = "maybe";
          break;
      case /* Likely */3 :
          filledBackgroundCSS = "likely";
          break;
      case /* ExtremelyLikely */4 :
          filledBackgroundCSS = "extremely-likely";
          break;
      
    }
  } else {
    filledBackgroundCSS = "";
  }
  var ratingEmoji;
  if (likelyness !== undefined) {
    switch (likelyness.tag | 0) {
      case /* VeryUnlikely */0 :
          ratingEmoji = Image$ReactHooksTemplate.veryUnlikelySmiley;
          break;
      case /* Unlikely */1 :
          ratingEmoji = Image$ReactHooksTemplate.unlikelySmiley;
          break;
      case /* Maybe */2 :
          ratingEmoji = Image$ReactHooksTemplate.maybeSmiley;
          break;
      case /* Likely */3 :
          ratingEmoji = Image$ReactHooksTemplate.likelySmiley;
          break;
      case /* ExtremelyLikely */4 :
          ratingEmoji = Image$ReactHooksTemplate.extremelyLikelySmiley;
          break;
      
    }
  } else {
    ratingEmoji = "";
  }
  var ratingMessage;
  if (likelyness !== undefined) {
    switch (likelyness.tag | 0) {
      case /* VeryUnlikely */0 :
          ratingMessage = "Very Unhappy!";
          break;
      case /* Unlikely */1 :
          ratingMessage = "Unhappy!";
          break;
      case /* Maybe */2 :
          ratingMessage = "It was ok!";
          break;
      case /* Likely */3 :
          ratingMessage = "Happy!";
          break;
      case /* ExtremelyLikely */4 :
          ratingMessage = "Super Happy!";
          break;
      
    }
  } else {
    ratingMessage = "";
  }
  var rmName = CustomerInfo$ReactHooksTemplate.rmName(customerInfo);
  var questionText = OpinionScale$ReactHooksTemplate.getTextTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)).replace("${RM_NAME}", rmName);
  var ratings = $$Array.of_list(Belt_List.mapWithIndex(Belt_List.makeBy(11, (function (x) {
                  return x;
                })), (function (i, param) {
              return React.createElement("p", undefined, String(i));
            })));
  var items = function (a, b) {
    return $$Array.of_list(Belt_List.mapWithIndex(Belt_List.makeBy((b - a | 0) + 1 | 0, (function (x) {
                          return x;
                        })), (function (i, x) {
                      var match = OpinionScale$ReactHooksTemplate.representation(opinionScale);
                      var match$1 = OpinionScale$ReactHooksTemplate.representation(opinionScale);
                      return React.createElement(OpinionScaleForm$OpinionScaleItem, {
                                  step: x + a | 0,
                                  className: match ? "w-1/11 focus:outline-none text-white text-xl font-medium " + selectedCss(x) : "w-full",
                                  onSelection: (function (scale) {
                                      return Curry._1(onChange, OpinionScale$ReactHooksTemplate.setAnswer(opinionScale, scale));
                                    }),
                                  style: match$1 ? ({
                                        backgroundColor: colorBasedOnLikelyness(i)
                                      }) : { },
                                  representation: OpinionScale$ReactHooksTemplate.representation(opinionScale),
                                  key: String(i + a | 0)
                                });
                    })));
  };
  var match = Client$ReactHooksTemplate.name(client);
  var exit = 0;
  if (match !== undefined) {
    switch (match) {
      case "bhima" :
      case "ncell" :
      case "nykaa" :
          exit = 2;
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        return React.createElement(OpinionScaleDefaultForm$ReactHooksTemplate.make, {
                    opinionScale: opinionScale,
                    surveyTheme: surveyTheme,
                    language: language,
                    mandatory: mandatory,
                    onChange: onChange
                  });
    case 2 :
        var match$1 = OpinionScale$ReactHooksTemplate.representation(opinionScale);
        var tmp;
        if (match$1) {
          tmp = React.createElement("div", {
                className: "flex flex-col justify-between items-start"
              }, React.createElement("div", {
                    className: "flex border rounded-sm justify-between divide-x w-full\n            border-white bg-white"
                  }, items(0, 10)), React.createElement("div", {
                    className: " flex justify-between w-full pt-6 small:pt-3\n            text-lg small:text-xs pb-10 "
                  }, React.createElement("div", {
                        className: "flex flex-col text-center"
                      }, React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.left(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("span", {
                            className: "flex justify-center"
                          }, React.createElement("img", {
                                className: "h-5",
                                src: Image$ReactHooksTemplate.sadRedSmileyYesbank
                              }))), React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.center(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                        className: "flex flex-col justify-center"
                      }, React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.right(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("span", {
                            className: "flex justify-center"
                          }, React.createElement("img", {
                                className: "h-5",
                                src: Image$ReactHooksTemplate.happyGreenSmileyYesbank
                              })))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                      language: language
                    }) : React.createElement(React.Fragment, undefined));
        } else {
          var tmp$1;
          if (likelyness !== undefined) {
            var likelyness$1 = likelyness;
            var tmp$2;
            if (likelyness$1.tag === /* ExtremelyLikely */4) {
              tmp$2 = React.createElement("div", {
                    className: " flex justify-between divide-x w-full\n          " + (String(filledBackgroundCSS) + " h-78-px small:h-48-px rounded-lg\n            divide-tropicalbreeze mb-16-px small:mb-8-px")
                  }, items(0, 10));
            } else {
              var rating$1 = likelyness$1[0];
              var filledCount = String(rating$1 + 1 | 0);
              var unFilledCount = String(11 - (rating$1 + 1 | 0) | 0);
              tmp$2 = React.createElement("div", {
                    className: "flex w-full mb-17-px small:mb-4-px bg-tropicalbreeze rounded-lg"
                  }, React.createElement("div", {
                        className: " flex justify-between divide-x w-" + (String(filledCount) + ("/11\n          h-78-px small:h-48-px rounded-lg rounded-r-none divide-tropicalbreeze\n            " + (String(filledBackgroundCSS) + "")))
                      }, items(0, rating$1)), React.createElement("div", {
                        className: " flex justify-between divide-x w-" + (String(unFilledCount) + "/11\n          bg-tropicalbreeze h-78-px small:h-48-px rounded-lg rounded-l-none divide-herringsilver")
                      }, items(rating$1 + 1 | 0, 10)));
            }
            tmp$1 = React.createElement(React.Fragment, undefined, tmp$2, React.createElement("div", {
                      className: "w-full flex flex-col items-center"
                    }, React.createElement("img", {
                          className: "mx-auto w-200-px small:w-160-px h-200-px\n                  small:h-160-px mb-8-px",
                          src: ratingEmoji
                        }), React.createElement("p", {
                          className: "font-normal font-semibold text-2xl small:text-xl leading-8\n                  small:leading-4 text-center tracking-wide"
                        }, ratingMessage)));
          } else {
            tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: "flex justify-between divide-x w-full\n          bg-tropicalbreeze h-78-px small:h-48-px rounded-lg divide-herringsilver\n          mb-17-px small:mb-4-px"
                    }, items(0, 10)), React.createElement("div", {
                      className: "flex justify-between w-full text-base small:text-sm font-semibold\n          leading-4 tracking-wide"
                    }, React.createElement("div", {
                          className: "flex flex-col text-center items-start"
                        }, React.createElement("span", undefined, React.createElement("img", {
                                  className: "w-60-px h-60-px",
                                  src: Image$ReactHooksTemplate.sadRedSmiley
                                })), React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.left(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language))))), React.createElement("div", {
                          className: "flex flex-col text-center items-end"
                        }, React.createElement("span", undefined, React.createElement("img", {
                                  className: "w-60-px h-60-px",
                                  src: Image$ReactHooksTemplate.happyGreenSmiley
                                })), React.createElement("p", undefined, OpinionScaleLabel$ReactHooksTemplate.right(OpinionScale$ReactHooksTemplate.getLabelTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)))))), mandatory ? React.createElement("p", {
                        className: " w-full pt-6 small:pt-3\n            text-lg opacity-50"
                      }, "* ", React.createElement("span", {
                            className: "text-xs"
                          }, "Request your response. This is a mandatory question")) : React.createElement(React.Fragment, undefined));
          }
          tmp = React.createElement("div", {
                className: "flex flex-col justify-between items-start"
              }, React.createElement("div", {
                    className: "flex justify-between w-full font-semibold text-base\n          text-center tracking-wide font-normal mb-8-px small:mb-4-px"
                  }, ratings), tmp$1);
        }
        return React.createElement(React.Fragment, undefined, React.createElement(QuestionText$ReactHooksTemplate.make, {
                        text: questionText,
                        description: OpinionScale$ReactHooksTemplate.getDescriptionTranslation(opinionScale, SurveyLanguage$ReactHooksTemplate.name(language)),
                        mandatory: mandatory,
                        surveyTheme: surveyTheme
                      }), tmp);
    
  }
}

var make = OpinionScaleForm;

exports.OpinionScaleItem = OpinionScaleItem;
exports.colorBasedOnLikelyness = colorBasedOnLikelyness;
exports.make = make;
/* react Not a pure module */
