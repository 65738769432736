// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("../filters/filter.bs.js");
var Highcharts$ReactHooksTemplate = require("../../common/Highcharts.bs.js");
var FilterPills$ReactHooksTemplate = require("../filters/filterPills.bs.js");
var InsightsFilters$ReactHooksTemplate = require("../filters/InsightsFilters.bs.js");

function reducer(state, param) {
  if (typeof param === "number") {
    switch (param) {
      case /* ToggleFilter */1 :
          return /* record */[
                  /* loadingCorrelationData */state[/* loadingCorrelationData */0],
                  /* toggleFilter */!state[/* toggleFilter */1],
                  /* filterPills */state[/* filterPills */2],
                  /* filters */state[/* filters */3],
                  /* currentFilter */state[/* currentFilter */4],
                  /* correlationData */state[/* correlationData */5]
                ];
      case /* FetchFiltersInProgress */0 :
      case /* FetchCorrelationDataInProgress */2 :
          break;
      
    }
  } else {
    switch (param.tag | 0) {
      case /* FetchedCorrelationData */0 :
          return /* record */[
                  /* loadingCorrelationData */state[/* loadingCorrelationData */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* filterPills */state[/* filterPills */2],
                  /* filters */state[/* filters */3],
                  /* currentFilter */state[/* currentFilter */4],
                  /* correlationData */param[0]
                ];
      case /* SetFiltersAndPills */1 :
          return /* record */[
                  /* loadingCorrelationData */true,
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* filterPills */param[1],
                  /* filters */param[0],
                  /* currentFilter */state[/* currentFilter */4],
                  /* correlationData */state[/* correlationData */5]
                ];
      case /* SetFilters */2 :
          return /* record */[
                  /* loadingCorrelationData */state[/* loadingCorrelationData */0],
                  /* toggleFilter */state[/* toggleFilter */1],
                  /* filterPills */state[/* filterPills */2],
                  /* filters */param[0],
                  /* currentFilter */state[/* currentFilter */4],
                  /* correlationData */state[/* correlationData */5]
                ];
      
    }
  }
  return /* record */[
          /* loadingCorrelationData */true,
          /* toggleFilter */state[/* toggleFilter */1],
          /* filterPills */state[/* filterPills */2],
          /* filters */state[/* filters */3],
          /* currentFilter */state[/* currentFilter */4],
          /* correlationData */state[/* correlationData */5]
        ];
}

var initialState_005 = /* correlationData : record */[
  /* categories : [] */0,
  /* data */null
];

var initialState = /* record */[
  /* loadingCorrelationData */true,
  /* toggleFilter */false,
  /* filterPills : [] */0,
  /* filters : [] */0,
  /* currentFilter */0,
  initialState_005
];

function InsightsCorrelation(Props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualizations = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/correlation_data", (function (json) {
                return /* record */[
                        /* categories */Json_decode.field("categories", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), json),
                        /* data */Belt_Option.getWithDefault(Js_json.decodeObject(json), { })["data"]
                      ];
              })).then((function (xs) {
              console.log(xs);
              return Promise.resolve(xs);
            })).then((function (xs) {
            return Promise.resolve(setTimeout((function (param) {
                              return Curry._1(dispatch, /* FetchedCorrelationData */Block.__(0, [xs]));
                            }), Utils$ReactHooksTemplate.timeout));
          }));
    return /* () */0;
  };
  React.useEffect((function () {
          fetchVisualizations(/* () */0);
          return ;
        }), ([]));
  var onApplyFilter = function (param) {
    fetchVisualizations(/* () */0);
    return /* () */0;
  };
  var toggleFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    return Curry._1(dispatch, /* SetFilters */Block.__(2, [state[/* filters */3]]));
  };
  var match$1 = state[/* toggleFilter */1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "p-20-px bg-porcelain"
                }, match$1 ? React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                        onClose: toggleFilter,
                        onApply: onApplyFilter,
                        showDateFilter: false,
                        showFilterPills: false,
                        correlation: true
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "px-16-px flex justify-between pb-20-px"
                    }, React.createElement("div", {
                          className: "flex flex-wrap gap-2"
                        }, React.createElement("div", {
                              className: "flex items-center font-normal text-sm h-8"
                            }, "Active Filters"), React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                              onClose: toggleFilter,
                              onApply: onApplyFilter,
                              showDateFilter: false,
                              showFilterPills: true,
                              correlation: true
                            })), React.createElement("div", {
                          className: "float-right"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n                              shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return toggleFilter(/* () */0);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"))), React.createElement("div", {
                      className: "flex flex-col pb-20-px"
                    }, React.createElement("div", {
                          className: "w-full border mb-2 bg-white h-10"
                        }, React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("div", {
                                  className: "w-3 bg-red-600 h-10 mr-2"
                                }), React.createElement("p", {
                                  className: "text-lg font-extrabold"
                                }, "CORRELATION MATRIX"))), React.createElement("div", {
                          className: "border bg-white flex justify-center p-2 h-full w-full"
                        }, React.createElement(Highcharts$ReactHooksTemplate.HeatmapBinding.make, {
                              mapHeight: "30%",
                              categories: $$Array.of_list(state[/* correlationData */5][/* categories */0]),
                              data: state[/* correlationData */5][/* data */1]
                            })))));
}

var make = InsightsCorrelation;

exports.reducer = reducer;
exports.initialState = initialState;
exports.make = make;
/* initialState Not a pure module */
