// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var TextSelected$ReactHooksTemplate = require("./textSelected.bs.js");

var categoryType = "text";

function setSelected(selected, t) {
  return /* record */[/* selected */selected];
}

function resetSelected(t) {
  return /* record */[/* selected */undefined];
}

function fromJson(json) {
  return /* record */[/* selected */Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("selected", (function (param) {
                                return Json_decode.optional(TextSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)];
}

function toString(t) {
  var match = t[/* selected */0];
  if (match !== undefined) {
    return TextSelected$ReactHooksTemplate.toString(Caml_option.valFromOption(match));
  } else {
    return "";
  }
}

function isSelected(t) {
  return Belt_Option.isSome(t[/* selected */0]);
}

function encodeParams(t) {
  return Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t[/* selected */0], TextSelected$ReactHooksTemplate.encode);
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "category_type",
                categoryType
              ],
              /* :: */[
                /* tuple */[
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                /* [] */0
              ]
            ]);
}

exports.categoryType = categoryType;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.fromJson = fromJson;
exports.toString = toString;
exports.isSelected = isSelected;
exports.encodeParams = encodeParams;
exports.encode = encode;
/* Utils-ReactHooksTemplate Not a pure module */
