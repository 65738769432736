// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function headers(t) {
  return t[/* headers */0];
}

function rows(t) {
  return t[/* rows */1];
}

function width(t) {
  return t[/* width */2];
}

function height(t) {
  return t[/* height */3];
}

function data(t) {
  return t[/* data */4];
}

function fromJson(json) {
  var headers = Json_decode.field("headers", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }), json);
  var rowDecoder = function (json) {
    return List.map((function (x) {
                  return Json_decode.withDefault("", (function (param) {
                                return Json_decode.field(x, Json_decode.string, param);
                              }), json);
                }), headers);
  };
  return /* record */[
          /* headers */headers,
          /* rows */Json_decode.field("data", (function (param) {
                  return Json_decode.list(rowDecoder, param);
                }), json),
          /* width */Json_decode.optional((function (param) {
                  return Json_decode.field("width", Json_decode.string, param);
                }), json),
          /* height */Json_decode.optional((function (param) {
                  return Json_decode.field("height", Json_decode.string, param);
                }), json),
          /* data */Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), { }), "data"), "empty")
        ];
}

exports.headers = headers;
exports.rows = rows;
exports.width = width;
exports.height = height;
exports.data = data;
exports.fromJson = fromJson;
/* No side effect */
