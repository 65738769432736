// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var RatingSmileySet$ReactHooksTemplate = require("./ratingSmileySet.bs.js");

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json)
        ];
}

function answer(t) {
  return t[/* answer */8];
}

function answered(t) {
  return t[/* answer */8] !== undefined;
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* description */"",
          /* ratingSmileySet */undefined,
          /* showLabels */true,
          /* negativeToPositive */true,
          /* translations : [] */0,
          /* answer */undefined
        ];
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function text(t) {
  return t[/* text */2];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function description(t) {
  return t[/* description */3];
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */7]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */3];
  }
}

function showLabels(t) {
  return t[/* showLabels */5];
}

function negativeToPositive(t) {
  return t[/* negativeToPositive */6];
}

function ratingSmileySet(t) {
  return t[/* ratingSmileySet */4];
}

function ratingTypeCollection(t) {
  var match = t[/* negativeToPositive */6];
  if (match) {
    return /* :: */[
            /* One */0,
            /* :: */[
              /* Two */1,
              /* :: */[
                /* Three */2,
                /* :: */[
                  /* Four */3,
                  /* :: */[
                    /* Five */4,
                    /* [] */0
                  ]
                ]
              ]
            ]
          ];
  } else {
    return /* :: */[
            /* Five */4,
            /* :: */[
              /* Four */3,
              /* :: */[
                /* Three */2,
                /* :: */[
                  /* Two */1,
                  /* :: */[
                    /* One */0,
                    /* [] */0
                  ]
                ]
              ]
            ]
          ];
  }
}

function ratingTypeToImage(ratingType, ratingSmileySet) {
  switch (ratingType) {
    case /* One */0 :
        return RatingSmileySet$ReactHooksTemplate.ratingOneSmiley(ratingSmileySet);
    case /* Two */1 :
        return RatingSmileySet$ReactHooksTemplate.ratingTwoSmiley(ratingSmileySet);
    case /* Three */2 :
        return RatingSmileySet$ReactHooksTemplate.ratingThreeSmiley(ratingSmileySet);
    case /* Four */3 :
        return RatingSmileySet$ReactHooksTemplate.ratingFourSmiley(ratingSmileySet);
    case /* Five */4 :
        return RatingSmileySet$ReactHooksTemplate.ratingFiveSmiley(ratingSmileySet);
    case /* NotSelected */5 :
        return Image$ReactHooksTemplate.excellentSmiley;
    
  }
}

function ratingTypeToString(param) {
  switch (param) {
    case /* One */0 :
        return "Poor";
    case /* Two */1 :
        return "Fair";
    case /* Three */2 :
        return "Good";
    case /* Four */3 :
        return "Very Good";
    case /* Five */4 :
        return "Excellent";
    case /* NotSelected */5 :
        return "";
    
  }
}

function ratingTypeToRating(param) {
  switch (param) {
    case /* One */0 :
        return 1;
    case /* Two */1 :
        return 2;
    case /* Three */2 :
        return 3;
    case /* Four */3 :
        return 4;
    case /* Five */4 :
        return 5;
    case /* NotSelected */5 :
        return -1;
    
  }
}

function answerToString(t) {
  var match = t[/* answer */8];
  if (match !== undefined) {
    return ratingTypeToString(match);
  } else {
    return "";
  }
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* description */t[/* description */3],
          /* ratingSmileySet */t[/* ratingSmileySet */4],
          /* showLabels */t[/* showLabels */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */description,
          /* ratingSmileySet */t[/* ratingSmileySet */4],
          /* showLabels */t[/* showLabels */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setShowLabels(t, showLabels) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* ratingSmileySet */t[/* ratingSmileySet */4],
          /* showLabels */showLabels,
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setNegativeToPositive(t, negativeToPositive) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* ratingSmileySet */t[/* ratingSmileySet */4],
          /* showLabels */t[/* showLabels */5],
          /* negativeToPositive */negativeToPositive,
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function setAnswer(t, answer) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* ratingSmileySet */t[/* ratingSmileySet */4],
          /* showLabels */t[/* showLabels */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* translations */t[/* translations */7],
          /* answer */answer
        ];
}

function setRatingSmileySet(t, ratingSmileySet) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* description */t[/* description */3],
          /* ratingSmileySet */ratingSmileySet,
          /* showLabels */t[/* showLabels */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* translations */t[/* translations */7],
          /* answer */t[/* answer */8]
        ];
}

function encodeAnswerTypeCollection(option) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                ratingTypeToRating(option)
              ],
              /* :: */[
                /* tuple */[
                  "label",
                  ratingTypeToString(option)
                ],
                /* [] */0
              ]
            ]);
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var match = t[/* ratingSmileySet */4];
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */3]
      ],
      /* :: */[
        /* tuple */[
          "options",
          Json_encode.list(encodeAnswerTypeCollection, ratingTypeCollection(t))
        ],
        /* :: */[
          /* tuple */[
            "rating_smiley_set_id",
            match !== undefined ? RatingSmileySet$ReactHooksTemplate.id(match) : 0
          ],
          /* [] */0
        ]
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  ratingTypeToRating(Belt_Option.getWithDefault(t[/* answer */8], /* NotSelected */5))
                ],
                encodeArray
              ] : encodeArray);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* ratingSmileySet */Json_decode.optional((function (param) {
                  return Json_decode.field("category", (function (param) {
                                return Json_decode.field("rating_smiley_set", RatingSmileySet$ReactHooksTemplate.fromJson, param);
                              }), param);
                }), json),
          /* showLabels */Json_decode.field("category", (function (param) {
                  return Json_decode.field("show_labels", Json_decode.bool, param);
                }), json),
          /* negativeToPositive */Json_decode.field("category", (function (param) {
                  return Json_decode.field("negative_to_positive", Json_decode.bool, param);
                }), json),
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "SmileyRating";

exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.answer = answer;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.showLabels = showLabels;
exports.negativeToPositive = negativeToPositive;
exports.ratingSmileySet = ratingSmileySet;
exports.ratingTypeCollection = ratingTypeCollection;
exports.ratingTypeToImage = ratingTypeToImage;
exports.ratingTypeToString = ratingTypeToString;
exports.ratingTypeToRating = ratingTypeToRating;
exports.setText = setText;
exports.setShowLabels = setShowLabels;
exports.setNegativeToPositive = setNegativeToPositive;
exports.setDescription = setDescription;
exports.setRatingSmileySet = setRatingSmileySet;
exports.categoryType = categoryType;
exports.answered = answered;
exports.empty = empty;
exports.id = id;
exports.uuid = uuid;
exports.toJson = toJson;
exports.setAnswer = setAnswer;
exports.fromJson = fromJson;
exports.answerToString = answerToString;
/* Image-ReactHooksTemplate Not a pure module */
