import bagmati from "./regions/bagmati.json";
import bhaktapur from "./districts/bhaktapur.json";
import dhading from "./districts/dhading.json";
import kabhrepalanchok from "./districts/kabhrepalanchok.json";
import kathmandu from "./districts/kathmandu.json";
import lalitpur from "./districts/lalitpur.json";
import nuwakot from "./districts/nuwakot.json";
import rasuwa from "./districts/rasuwa.json";
import sindhupalchok from "./districts/sindhupalchok.json";
import bheri from "./regions/bheri.json";
import banke from "./districts/banke.json";
import bardiya from "./districts/bardiya.json";
import dailekh from "./districts/dailekh.json";
import jajarkot from "./districts/jajarkot.json";
import surkhet from "./districts/surkhet.json";
import dhawalagiri from "./regions/dhaulagiri.json";
import baglung from "./districts/baglung.json";
import gulmi from "./districts/gulmi.json";
import mustang from "./districts/mustang.json";
import myagdi from "./districts/myagdi.json";
import parbat from "./districts/parbat.json";
import gandaki from "./regions/gandaki.json";
import gorkha from "./districts/gorkha.json";
import kaski from "./districts/kaski.json";
import lamjung from "./districts/lamjung.json";
import manang from "./districts/manang.json";
import syangja from "./districts/syangja.json";
import tanahu from "./districts/tanahu.json";
import janakpur from "./regions/janakpur.json";
import dhanusha from "./districts/dhanusha.json";
import dolakha from "./districts/dolakha.json";
import mahottari from "./districts/mahottari.json";
import ramechhap from "./districts/ramechhap.json";
import sarlahi from "./districts/sarlahi.json";
import sindhuli from "./districts/sindhuli.json";
import karnali from "./regions/karnali.json";
import dolpa from "./districts/dolpa.json";
import humla from "./districts/humla.json";
import jumla from "./districts/jumla.json";
import kalikot from "./districts/kalikot.json";
import mugu from "./districts/mugu.json";
import koshi from "./regions/koshi.json";
import bhojpur from "./districts/bhojpur.json";
import dhankuta from "./districts/dhankuta.json";
import morang from "./districts/morang.json";
import sankhuwasabha from "./districts/sankhuwasabha.json";
import sunsari from "./districts/sunsari.json";
import terhathum from "./districts/terhathum.json";
import lumbini from "./regions/lumbini.json";
import arghakhanchi from "./districts/arghakhanchi.json";
//import gulmi from "./districts/gulmi.json";
import kapilbastu from "./districts/kapilbastu.json";
import nawalparasi_east from "./districts/nawalparasi_east.json";
import nawalparasi_west from "./districts/nawalparasi_west.json";
import palpa from "./districts/palpa.json";
import rupandehi from "./districts/rupandehi.json";
import mahakali from "./regions/mahakali.json";
import baitadi from "./districts/baitadi.json";
import dadeldhura from "./districts/dadeldhura.json";
import darchula from "./districts/darchula.json";
import kanchanpur from "./districts/kanchanpur.json";
import mechi from "./regions/mechi.json";
import ilam from "./districts/ilam.json";
import jhapa from "./districts/jhapa.json";
import panchthar from "./districts/panchthar.json";
import taplejung from "./districts/taplejung.json";
import narayani from "./regions/narayani.json";
import bara from "./districts/bara.json";
import chitawan from "./districts/chitawan.json";
import makawanpur from "./districts/makawanpur.json";
import parsa from "./districts/parsa.json";
import rautahat from "./districts/rautahat.json";
import rapti from "./regions/rapti.json";
import dang from "./districts/dang.json";
import pyuthan from "./districts/pyuthan.json";
import rolpa from "./districts/rolpa.json";
import rukum_east from "./districts/rukum_east.json";
import rukum_west from "./districts/rukum_west.json";
import salyan from "./districts/salyan.json";
import sagarmatha from "./regions/sagarmatha.json";
import khotang from "./districts/khotang.json";
import okhaldhunga from "./districts/okhaldhunga.json";
import saptari from "./districts/saptari.json";
import siraha from "./districts/siraha.json";
import solukhumbu from "./districts/solukhumbu.json";
import udayapur from "./districts/udayapur.json";
import seti from "./regions/seti.json";
import achham from "./districts/achham.json";
import bajhang from "./districts/bajhang.json";
import bajura from "./districts/bajura.json";
import doti from "./districts/doti.json";
import kailali from "./districts/kailali.json";
const drilldownSeries = [
  {
    mapData: bhaktapur,
    name: "BHAKTAPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BHAKTAPUR",
    data: [
      ["Bhaktapur", 96],
      ["Changunarayan", 91],
      ["Madhyapur Thimi", 5],
      ["Suryabinayak", 26],
    ],
  },
  {
    mapData: dhading,
    name: "DHADING",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DHADING",
    data: [
      ["Benighat Rorang", 27],
      ["Dhunibesi", 28],
      ["Gajuri", 65],
      ["Galchi", 4],
      ["Gangajamuna", 43],
      ["Jwalamukhi", 40],
      ["Netrawati Dubjhong", 12],
      ["Nilakantha", 53],
      ["Siddhalek", 47],
      ["Thakre", 79],
      ["Tripura Sundari", 76],
    ],
  },
  {
    mapData: kabhrepalanchok,
    name: "KABHREPALANCHOK",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KABHREPALANCHOK",
    data: [
      ["Banepa", 87],
      ["Bethanchowk", 85],
      ["Bhumlu", 27],
      ["Chaurideurali", 32],
      ["Dhulikhel", 6],
      ["Mandandeupur", 8],
      ["Namobuddha", 13],
      ["Panauti", 80],
      ["Panchkhal", 64],
      ["Roshi", 11],
      ["Temal", 29],
    ],
  },
  {
    mapData: kathmandu,
    name: "KATHMANDU",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KATHMANDU",
    data: [
      ["Budhanilakantha", 30],
      ["Chandragiri", 35],
      ["Dakshinkali", 60],
      ["Gokarneshwor", 54],
      ["Kageshwori Manahora", 88],
      ["Kathmandu", 92],
      ["Kirtipur", 17],
      ["Nagarjun", 91],
      ["Shankharapur", 56],
      ["Tarakeshwor", 60],
      ["Tokha", 37],
    ],
  },
  {
    mapData: lalitpur,
    name: "LALITPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "LALITPUR",
    data: [
      ["Bagmati", 80],
      ["Godawari", 69],
      ["Konjyosom", 100],
      ["Lalitpur", 29],
      ["Mahalaxmi", 86],
      ["Mahankal", 8],
    ],
  },
  {
    mapData: nuwakot,
    name: "NUWAKOT",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "NUWAKOT",
    data: [
      ["Belkotgadhi", 68],
      ["Bidur", 29],
      ["Dupcheshwar", 51],
      ["Kakani", 47],
      ["Kispang", 6],
      ["Myagang", 29],
      ["Panchakanya", 49],
      ["Suryagadhi", 25],
      ["Tadi", 3],
      ["Tarkeshwar", 30],
    ],
  },
  {
    mapData: rasuwa,
    name: "RASUWA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RASUWA",
    data: [
      ["Aama Chhodingmo", 56],
      ["Gosaikunda", 81],
      ["Kalika", 38],
      ["Naukunda", 65],
    ],
  },
  {
    mapData: sindhupalchok,
    name: "SINDHUPALCHOK",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SINDHUPALCHOK",
    data: [
      ["Balefi", 98],
      ["Barhabise", 98],
      ["Bhotekoshi", 26],
      ["Chautara Sangachok Gadhi", 77],
      ["Helambu", 72],
      ["Indrawati", 31],
      ["Lisangkhu Pakhar", 27],
      ["Melamchi", 22],
      ["Panchpokhari Thangpal", 90],
      ["Sunkoshi", 92],
      ["Tripurasundari", 34],
    ],
  },
  {
    mapData: bagmati,
    name: "BAGMATI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BAGMATI",
    data: [
      { "hc-key": "BHAKTAPUR", value: 58, drilldown: "BHAKTAPUR" },
      { "hc-key": "DHADING", value: 78, drilldown: "DHADING" },
      { "hc-key": "KABHREPALANCHOK", value: 72, drilldown: "KABHREPALANCHOK" },
      { "hc-key": "KATHMANDU", value: 36, drilldown: "KATHMANDU" },
      { "hc-key": "LALITPUR", value: 30, drilldown: "LALITPUR" },
      { "hc-key": "NUWAKOT", value: 29, drilldown: "NUWAKOT" },
      { "hc-key": "RASUWA", value: 4, drilldown: "RASUWA" },
      { "hc-key": "SINDHUPALCHOK", value: 19, drilldown: "SINDHUPALCHOK" },
    ],
  },
  {
    mapData: banke,
    name: "BANKE",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BANKE",
    data: [
      ["Baijanath", 36],
      ["Duduwa", 72],
      ["Janki", 97],
      ["Khajura", 26],
      ["Kohalpur", 87],
      ["Narainapur", 99],
      ["Nepalgunj", 80],
      ["Rapti Sonari", 36],
    ],
  },
  {
    mapData: bardiya,
    name: "BARDIYA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BARDIYA",
    data: [
      ["Badhaiyatal", 69],
      ["Bansagadhi", 84],
      ["Barbardiya", 89],
      ["Geruwa", 62],
      ["Gulariya", 6],
      ["Madhuwan", 16],
      ["Rajapur", 17],
      ["Thakurbaba", 27],
    ],
  },
  {
    mapData: dailekh,
    name: "DAILEKH",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DAILEKH",
    data: [
      ["Bhagawatimai", 19],
      ["Bhairabi", 40],
      ["Chamunda Bindrasaini", 86],
      ["Dullu", 68],
      ["Dungeshwor", 86],
      ["Gurans", 84],
      ["Mahabu", 50],
      ["Narayan", 93],
      ["Naumule", 6],
    ],
  },
  {
    mapData: jajarkot,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "JAJARKOT",
    id: "JAJARKOT",
    data: [
      ["Bheri", 11],
      ["Chhedagad", 79],
      ["Nalagad", 4],
    ],
  },
  {
    mapData: surkhet,
    name: "SURKHET",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SURKHET",
    data: [
      ["Barahtal", 14],
      ["Bheriganga", 15],
      ["Birendranagar", 46],
      ["Chingad", 26],
      ["Gurbhakot", 8],
      ["Lekbeshi", 47],
      ["Panchpuri", 68],
      ["Simta", 30],
    ],
  },
  {
    mapData: bheri,
    name: "BHERI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BHERI",
    data: [
      { "hc-key": "BANKE", value: 92, drilldown: "BANKE" },
      { "hc-key": "BARDIYA", value: 67, drilldown: "BARDIYA" },
      { "hc-key": "DAILEKH", value: 15, drilldown: "DAILEKH" },
      { "hc-key": "JAJARKOT", value: 56, drilldown: "JAJARKOT" },
      { "hc-key": "SURKHET", value: 4, drilldown: "SURKHET" },
    ],
  },
  {
    mapData: baglung,
    name: "BAGLUNG",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BAGLUNG",
    data: [
      ["Badigad", 74],
      ["Baglung", 21],
      ["Bareng", 60],
      ["Dhorpatan", 71],
      ["Dhorpatan Hunting Reserve", 75],
      ["Galkot", 86],
      ["Jaimuni", 48],
      ["Kanthekhola", 49],
      ["Nisikhola", 21],
    ],
  },
  {
    mapData: mustang,
    name: "MUSTANG",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "MUSTANG",
    data: [
      ["Baragaun Muktichhetra", 14],
      ["Gharapjhong", 83],
    ],
  },
  {
    mapData: myagdi,
    name: "MYAGDI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "MYAGDI",
    data: [
      ["Annapurna", 48],
      ["Beni", 74],
      ["Dhaulagiri", 47],
      ["Malika", 72],
      ["Mangala", 99],
      ["Raghuganga", 86],
    ],
  },
  {
    mapData: parbat,
    name: "PARBAT",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "PARBAT",
    data: [
      ["Bihadi", 60],
      ["Jaljala", 97],
      ["Kushma", 60],
      ["Mahashila", 11],
      ["Modi", 8],
      ["Painyu", 96],
      ["Phalebas", 94],
    ],
  },
  {
    mapData: dhawalagiri,
    name: "DHAWALAGIRI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DHAWALAGIRI",
    data: [
      { "hc-key": "BAGLUNG", value: 76, drilldown: "BAGLUNG" },
      { "hc-key": "GULMI", value: 29, drilldown: "GULMI" },
      { "hc-key": "MUSTANG", value: 34, drilldown: "MUSTANG" },
      { "hc-key": "MYAGDI", value: 19, drilldown: "MYAGDI" },
      { "hc-key": "PARBAT", value: 45, drilldown: "PARBAT" },
    ],
  },
  {
    mapData: gorkha,
    name: "GORKHA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "GORKHA",
    data: [
      ["Aarughat", 93],
      ["Ajirkot", 7],
      ["Barpak Sulikot", 46],
      ["Bhimsenthapa", 97],
      ["Gandaki", 90],
      ["Gorkha", 14],
      ["Palungtar", 11],
      ["Sahid Lakhan", 6],
      ["Siranchok", 36],
    ],
  },
  {
    mapData: kaski,
    name: "KASKI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KASKI",
    data: [
      ["Annapurna", 29],
      ["Machhapuchchhre", 37],
      ["Madi", 78],
      ["Pokhara", 82],
      ["Rupa", 37],
    ],
  },
  {
    mapData: lamjung,
    name: "LAMJUNG",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "LAMJUNG",
    data: [
      ["Besishahar", 70],
      ["Dordi", 18],
      ["Dudhpokhari", 23],
      ["Kwholasothar", 17],
      ["Madhya Nepal", 33],
      ["Marsyangdi", 77],
      ["Rainas", 25],
      ["Sundarbazar", 92],
    ],
  },
  {
    mapData: manang,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "MANANG",
    id: "MANANG",
    data: [
      ["Chame", 3],
      ["Manangngisyang", 69],
    ],
  },
  {
    mapData: syangja,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "SYANGJA",
    id: "SYANGJA",
    data: [
      ["Aandhikhola", 27],
      ["Arjunchaupari", 94],
      ["Bhirkot", 60],
      ["Biruwa", 78],
      ["Chapakot", 72],
      ["Galyang", 89],
      ["Harinas", 36],
      ["Kaligandagi", 81],
      ["Phedikhola", 42],
      ["Putalibazar", 70],
      ["Waling", 30],
    ],
  },
  {
    mapData: tanahu,
    name: "TANAHU",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "TANAHU",
    data: [
      ["Anbukhaireni", 44],
      ["Bandipur", 81],
      ["Bhanu", 67],
      ["Bhimad", 42],
      ["Byas", 58],
      ["Devghat", 81],
      ["Ghiring", 36],
      ["Myagde", 27],
      ["Rhishing", 79],
      ["Shuklagandaki", 50],
    ],
  },
  {
    mapData: gandaki,
    name: "GANDAKI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "GANDAKI",
    data: [
      { "hc-key": "GORKHA", value: 41, drilldown: "GORKHA" },
      { "hc-key": "KASKI", value: 32, drilldown: "KASKI" },
      { "hc-key": "LAMJUNG", value: 28, drilldown: "LAMJUNG" },
      { "hc-key": "MANANG", value: 13, drilldown: "MANANG" },
      { "hc-key": "SYANGJA", value: 29, drilldown: "SYANGJA" },
      { "hc-key": "TANAHU", value: 7, drilldown: "TANAHU" },
    ],
  },
  {
    mapData: dhanusha,
    name: "DHANUSHA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DHANUSHA",
    data: [
      ["Aaurahi", 97],
      ["Bateshwor", 23],
      ["Bideha", 93],
      ["Chhireshwornath", 17],
      ["Dhanauji", 22],
      ["Dhanusadham", 50],
      ["Ganeshman Charnath", 13],
      ["Hansapur", 12],
      ["Janaknandani", 15],
      ["Janakpurdham", 10],
      ["Kamala", 59],
      ["Lakshminiya", 11],
      ["Mithila", 22],
      ["Mithila Bihari", 23],
      ["Mukhiyapatti Musarmiya", 53],
      ["Nagarain", 28],
      ["Sabaila", 90],
      ["Sahidnagar", 60],
    ],
  },
  {
    mapData: dolakha,
    name: "DOLAKHA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DOLAKHA",
    data: [
      ["Baiteshwor", 58],
      ["Bhimeshwor", 23],
      ["Gaurishankar", 12],
      ["Jiri", 10],
      ["Melung", 82],
      ["Sailung", 62],
      ["Tamakoshi", 0],
    ],
  },
  {
    mapData: mahottari,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "MAHOTTARI",
    id: "MAHOTTARI",
    data: [
      ["Aurahi", 82],
      ["Balwa", 41],
      ["Bardibas", 42],
      ["Bhangaha", 1],
      ["Ekdanra", 61],
      ["Gaushala", 67],
      ["Jaleswor", 66],
      ["Loharpatti", 74],
      ["Mahottari", 96],
      ["Manra Siswa", 58],
      ["Matihani", 100],
      ["Pipra", 76],
      ["Ramgopalpur", 29],
      ["Samsi", 18],
      ["Sonama", 18],
    ],
  },
  {
    mapData: ramechhap,
    name: "RAMECHHAP",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RAMECHHAP",
    data: [
      ["Doramba", 66],
      ["Gokulganga", 41],
      ["Manthali", 20],
      ["Ramechhap", 64],
      ["Sunapati", 99],
      ["Umakunda", 13],
    ],
  },
  {
    mapData: sarlahi,
    name: "SARLAHI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SARLAHI",
    data: [
      ["Bagmati", 44],
      ["Balara", 55],
      ["Barahathawa", 28],
      ["Basbariya", 99],
      ["Bishnu", 55],
      ["Bramhapuri", 86],
      ["Chakraghatta", 71],
      ["Chandranagar", 83],
      ["Dhankaul", 26],
      ["Godaita", 14],
      ["Haripur", 22],
      ["Haripurwa", 7],
      ["Hariwan", 95],
      ["Ishworpur", 52],
      ["Kabilasi", 44],
      ["Kaudena", 13],
      ["Lalbandi", 12],
      ["Malangawa", 95],
      ["Parsa", 6],
      ["Ramnagar", 89],
    ],
  },
  {
    mapData: sindhuli,
    name: "SINDHULI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SINDHULI",
    data: [
      ["Dudhouli", 59],
      ["Kamalamai", 78],
      ["Marin", 65],
      ["Phikkal", 93],
      ["Sunkoshi", 19],
      ["Tinpatan", 70],
    ],
  },
  {
    mapData: janakpur,
    name: "JANAKPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "JANAKPUR",
    data: [
      { "hc-key": "DHANUSHA", value: 42, drilldown: "DHANUSHA" },
      { "hc-key": "DOLAKHA", value: 4, drilldown: "DOLAKHA" },
      { "hc-key": "MAHOTTARI", value: 21, drilldown: "MAHOTTARI" },
      { "hc-key": "RAMECHHAP", value: 31, drilldown: "RAMECHHAP" },
      { "hc-key": "SARLAHI", value: 71, drilldown: "SARLAHI" },
      { "hc-key": "SINDHULI", value: 89, drilldown: "SINDHULI" },
    ],
  },
  {
    mapData: dolpa,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "DOLPA",
    id: "DOLPA",
    data: [["Thuli Bheri", 5]],
  },
  {
    mapData: humla,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "HUMLA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "HUMLA",

    data: [["Simkot", 35]],
  },
  {
    mapData: jumla,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "JUMLA",
    id: "JUMLA",
    data: [["Chandannath", 10]],
  },
  {
    mapData: kalikot,
    name: "KALIKOT",
    id: "KALIKOT",
    data: [["Khandachakra", 14]],
  },
  {
    mapData: mugu,
    name: "MUGU",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "MUGU",
    data: [["Chhayanath Rara", 85]],
  },
  {
    mapData: karnali,
    name: "KARNALI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KARNALI",
    data: [
      { "hc-key": "DOLPA", value: 72, drilldown: "DOLPA" },
      { "hc-key": "HUMLA", value: 66, drilldown: "HUMLA" },
      { "hc-key": "JUMLA", value: 18, drilldown: "JUMLA" },
      { "hc-key": "KALIKOT", value: 98, drilldown: "KALIKOT" },
      { "hc-key": "MUGU", value: 7, drilldown: "MUGU" },
    ],
  },
  {
    mapData: bhojpur,
    name: "BHOJPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BHOJPUR",
    data: [
      ["Arun", 73],
      ["Bhojpur", 0],
      ["Pauwadungma", 90],
      ["Ramprasad Rai", 19],
      ["Shadananda", 55],
      ["Temkemaiyung", 7],
    ],
  },
  {
    mapData: dhankuta,
    name: "DHANKUTA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DHANKUTA",
    data: [
      ["Chaubise", 91],
      ["Chhathar Jorpati", 96],
      ["Dhankuta", 67],
      ["Mahalaxmi", 64],
      ["Pakhribas", 51],
      ["Sahidbhumi", 4],
      ["Sangurigadhi", 20],
    ],
  },
  {
    mapData: morang,
    name: "MORANG",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "MORANG",
    data: [
      ["Belbari", 10],
      ["Biratnagar", 73],
      ["Budhiganga", 56],
      ["Dhanpalthan", 2],
      ["Gramthan", 69],
      ["Jahada", 53],
      ["Kanepokhari", 100],
      ["Katahari", 81],
      ["Kerabari", 98],
      ["Letang", 64],
      ["Miklajung", 14],
      ["Patahrishanishchare", 6],
      ["Rangeli", 85],
      ["Ratuwamai", 87],
      ["Sundarharaicha", 97],
      ["Sunwarshi", 85],
      ["Uralabari", 60],
    ],
  },
  {
    mapData: sankhuwasabha,
    name: "SANKHUWASABHA",
    id: "SANKHUWASABHA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    data: [
      ["Chainpur", 80],
      ["Chichila", 36],
      ["Dharmadevi", 49],
      ["Khandbari", 87],
      ["Panchakhapan", 87],
      ["Sabhapokhari", 94],
    ],
  },
  {
    mapData: sunsari,
    name: "SUNSARI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SUNSARI",
    data: [
      ["Barahchhetra", 19],
      ["Barju", 82],
      ["Bhokraha Narshingh", 1],
      ["Dewanganj", 1],
      ["Dharan", 67],
      ["Duhabi", 96],
      ["Gadhi", 66],
      ["Harinagar", 4],
      ["Inaruwa", 36],
      ["Itahari", 47],
      ["Koshi", 48],
      ["Ramdhuni", 23],
    ],
  },
  {
    mapData: terhathum,
    name: "TERHATHUM",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "TERHATHUM",
    data: [
      ["Aathrai", 85],
      ["Chhathar", 77],
      ["Laligurans", 89],
      ["Myanglung", 11],
      ["Phedap", 32],
    ],
  },
  {
    mapData: koshi,
    name: "KOSHI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KOSHI",
    data: [
      { "hc-key": "BHOJPUR", value: 12, drilldown: "BHOJPUR" },
      { "hc-key": "DHANKUTA", value: 86, drilldown: "DHANKUTA" },
      { "hc-key": "MORANG", value: 63, drilldown: "MORANG" },
      { "hc-key": "SANKHUWASABHA", value: 29, drilldown: "SANKHUWASABHA" },
      { "hc-key": "SUNSARI", value: 28, drilldown: "SUNSARI" },
      { "hc-key": "TERHATHUM", value: 97, drilldown: "TERHATHUM" },
    ],
  },
  {
    mapData: arghakhanchi,
    name: "ARGHAKHANCHI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "ARGHAKHANCHI",
    data: [
      ["Bhumekasthan", 97],
      ["Chhatradev", 62],
      ["Malarani", 67],
      ["Panini", 100],
      ["Sandhikharka", 79],
      ["Sitganga", 2],
    ],
  },
  {
    mapData: gulmi,
    name: "GULMI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "GULMI",
    data: [
      ["Chandrakot", 76],
      ["Chatrakot", 52],
      ["Dhurkot", 9],
      ["Gulmidarbar", 91],
      ["Isma", 2],
      ["Malika", 64],
      ["Musikot", 14],
      ["Resunga", 42],
      ["Ruru", 51],
    ],
  },
  {
    mapData: kapilbastu,
    name: "KAPILBASTU",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KAPILBASTU",
    data: [
      ["Banganga", 84],
      ["Bijayanagar", 39],
      ["Buddhabhumi", 18],
      ["Kapilbastu", 52],
      ["Krishnanagar", 100],
      ["Maharajgunj", 41],
      ["Mayadevi", 67],
      ["Shivaraj", 15],
      ["Suddhodhan", 29],
      ["Yashodhara", 78],
    ],
  },
  {
    mapData: nawalparasi_east,
    name: "NAWALPARASI EAST",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "NAWALPARASI EAST",
    data: [
      ["Baudikali", 83],
      ["Binayee Tribeni", 88],
      ["Devchuli", 63],
      ["Gaidakot", 46],
      ["Hupsekot", 81],
      ["Kawasoti", 42],
      ["Madhyabindu", 6],
    ],
  },
  {
    mapData: nawalparasi_west,
    name: "NAWALPARASI WEST",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "NAWALPARASI WEST",
    data: [
      ["Bardaghat", 81],
      ["Palhi Nandan", 39],
      ["Pratappur", 85],
      ["Ramgram", 51],
      ["Sarawal", 95],
      ["Sunwal", 58],
      ["Susta", 79],
    ],
  },
  {
    mapData: palpa,
    name: "PALPA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "PALPA",
    data: [
      ["Bagnaskali", 60],
      ["Mathagadhi", 9],
      ["Purbakhola", 51],
      ["Rainadevi Chhahara", 42],
      ["Rambha", 5],
      ["Rampur", 78],
      ["Ribdikot", 79],
      ["Tansen", 72],
      ["Tinau", 38],
    ],
  },
  {
    mapData: rupandehi,
    name: "RUPANDEHI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RUPANDEHI",
    data: [
      ["Butwal", 12],
      ["Devdaha", 80],
      ["Gaidahawa", 88],
      ["Kanchan", 82],
      ["Kotahimai", 41],
      ["Lumbini Sanskritik", 29],
      ["Mayadevi", 87],
      ["Omsatiya", 28],
      ["Rohini", 37],
      ["Sainamaina", 77],
      ["Sammarimai", 32],
      ["Siddharthanagar", 53],
      ["Siyari", 13],
      ["Sudhdhodhan", 56],
      ["Tillotama", 44],
    ],
  },
  {
    mapData: lumbini,
    name: "LUMBINI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "LUMBINI",
    data: [
      { "hc-key": "ARGHAKHANCHI", value: 85, drilldown: "ARGHAKHANCHI" },
      { "hc-key": "GULMI", value: 15, drilldown: "GULMI" },
      { "hc-key": "KAPILBASTU", value: 98, drilldown: "KAPILBASTU" },
      {
        "hc-key": "NAWALPARASI EAST",
        value: 49,
        drilldown: "NAWALPARASI EAST",
      },
      {
        "hc-key": "NAWALPARASI WEST",
        value: 54,
        drilldown: "NAWALPARASI WEST",
      },
      { "hc-key": "PALPA", value: 39, drilldown: "PALPA" },
      { "hc-key": "RUPANDEHI", value: 46, drilldown: "RUPANDEHI" },
    ],
  },
  {
    mapData: baitadi,
    name: "BAITADI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BAITADI",
    data: [
      ["Dasharathchanda", 38],
      ["Dogadakedar", 29],
      ["Melauli", 70],
      ["Patan", 78],
      ["Purchaudi", 45],
      ["Shivanath", 48],
      ["Sigas", 42],
      ["Surnaya", 76],
    ],
  },
  {
    mapData: dadeldhura,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "DADELDHURA",
    id: "DADELDHURA",
    data: [
      ["Ajaymeru", 12],
      ["Alital", 56],
      ["Amargadhi", 75],
      ["Bhageshwar", 7],
      ["Ganayapdhura", 61],
      ["Nawadurga", 3],
      ["Parashuram", 64],
    ],
  },
  {
    mapData: darchula,
    name: "DARCHULA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DARCHULA",
    data: [
      ["Mahakali", 66],
      ["Malikaarjun", 77],
      ["Naugad", 79],
      ["Shailyashikhar", 97],
    ],
  },
  {
    mapData: kanchanpur,
    name: "KANCHANPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KANCHANPUR",
    data: [
      ["Bedkot", 59],
      ["Belauri", 90],
      ["Beldandi", 59],
      ["Bhimdatta", 40],
      ["Krishnapur", 88],
      ["Laljhadi", 43],
      ["Mahakali", 9],
      ["Punarbas", 27],
      ["Shuklaphanta National Park", 16],
    ],
  },
  {
    mapData: mahakali,
    name: "MAHAKALI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "MAHAKALI",
    data: [
      { "hc-key": "BAITADI", value: 98, drilldown: "BAITADI" },
      { "hc-key": "DADELDHURA", value: 82, drilldown: "DADELDHURA" },
      { "hc-key": "DARCHULA", value: 8, drilldown: "DARCHULA" },
      { "hc-key": "KANCHANPUR", value: 66, drilldown: "KANCHANPUR" },
    ],
  },
  {
    mapData: ilam,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "ILAM",
    id: "ILAM",
    data: [
      ["Chulachuli", 25],
      ["Deumai", 3],
      ["Fakphokthum", 2],
      ["Illam", 24],
      ["Mai", 18],
      ["Maijogmai", 97],
      ["Mangsebung", 29],
      ["Rong", 96],
      ["Sandakpur", 55],
      ["Suryodaya", 4],
    ],
  },
  {
    mapData: jhapa,
    name: "JHAPA",
    id: "JHAPA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    data: [
      ["Arjundhara", 47],
      ["Barhadashi", 80],
      ["Bhadrapur", 11],
      ["Birtamod", 86],
      ["Buddhashanti", 71],
      ["Damak", 88],
      ["Gauradhaha", 28],
      ["Gauriganj", 68],
      ["Haldibari", 80],
      ["Jhapa", 95],
      ["Kachankawal", 91],
      ["Kamal", 16],
      ["Kankai", 45],
      ["Mechinagar", 9],
      ["Shivasataxi", 81],
    ],
  },
  {
    mapData: panchthar,
    name: "PANCHTHAR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "PANCHTHAR",
    data: [
      ["Falelung", 36],
      ["Falgunanda", 37],
      ["Hilihang", 94],
      ["Kummayak", 10],
      ["Miklajung", 68],
      ["Phidim", 85],
      ["Tumbewa", 4],
      ["Yangwarak", 21],
    ],
  },
  {
    mapData: taplejung,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "TAPLEJUNG",
    id: "TAPLEJUNG",
    data: [
      ["Aathrai Tribeni", 70],
      ["Pathivara Yangwarak", 56],
      ["Phaktanglung", 53],
      ["Phungling", 52],
      ["Sidingba", 56],
      ["Sirijangha", 15],
    ],
  },
  {
    mapData: mechi,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "MECHI",
    id: "MECHI",
    data: [
      { "hc-key": "ILAM", value: 77, drilldown: "ILAM" },
      { "hc-key": "JHAPA", value: 98, drilldown: "JHAPA" },
      { "hc-key": "PANCHTHAR", value: 39, drilldown: "PANCHTHAR" },
      { "hc-key": "TAPLEJUNG", value: 28, drilldown: "TAPLEJUNG" },
    ],
  },
  {
    mapData: bara,
    name: "BARA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "BARA",
    data: [
      ["Adarshkotwal", 49],
      ["Baragadhi", 61],
      ["Bishrampur", 51],
      ["Devtal", 92],
      ["Jitpur Simara", 79],
      ["Kalaiya", 1],
      ["Karaiyamai", 56],
      ["Kolhabi", 33],
      ["Mahagadhimai", 96],
      ["Nijgadh", 2],
      ["Pacharauta", 81],
      ["Parwanipur", 78],
      ["Pheta", 53],
      ["Prasauni", 54],
      ["Simraungadh", 11],
      ["Suwarna", 65],
    ],
  },
  {
    mapData: chitawan,
    name: "CHITAWAN",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "CHITAWAN",
    data: [
      ["Bharatpur", 9],
      ["Ichchhyakamana", 75],
      ["Kalika", 80],
      ["Khairahani", 51],
      ["Madi", 20],
      ["Rapti", 50],
      ["Ratnanagar", 8],
    ],
  },
  {
    mapData: makawanpur,
    name: "MAKAWANPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "MAKAWANPUR",
    data: [
      ["Bakaiya", 27],
      ["Bhimphedi", 64],
      ["Hetauda", 88],
      ["Indrasarowar", 91],
      ["Kailash", 73],
      ["Makawanpurgadhi", 90],
      ["Manahari", 15],
      ["Parsa Wildlife Reserve", 83],
      ["Raksirang", 16],
      ["Thaha", 48],
    ],
  },
  {
    mapData: parsa,
    name: "PARSA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "PARSA",
    data: [
      ["Bahudaramai", 20],
      ["Bindabasini", 96],
      ["Birgunj", 28],
      ["Chhipaharmai", 75],
      ["Chitwan National Park", 60],
      ["Dhobini", 88],
      ["Jagarnathpur", 24],
      ["Jirabhawani", 12],
      ["Kalikamai", 38],
      ["Pakahamainpur", 92],
      ["Parsagadhi", 87],
      ["Paterwasugauli", 32],
      ["Pokhariya", 94],
      ["Sakhuwa Prasauni", 16],
      ["Thori", 79],
    ],
  },
  {
    mapData: rautahat,
    name: "RAUTAHAT",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RAUTAHAT",
    data: [
      ["Baudhimai", 51],
      ["Brindaban", 98],
      ["Chandrapur", 55],
      ["Dewahhi Gonahi", 97],
      ["Durga Bhagwati", 1],
      ["Gadhimai", 92],
      ["Garuda", 27],
      ["Gaur", 37],
      ["Gujara", 100],
      ["Ishanath", 69],
      ["Katahariya", 34],
      ["Madhav Narayan", 28],
      ["Maulapur", 53],
      ["Paroha", 74],
      ["Phatuwa Bijayapur", 90],
      ["Rajdevi", 55],
      ["Rajpur", 5],
      ["Yemunamai", 7],
    ],
  },
  {
    mapData: tanahu,
    name: "TANAHU",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "TANAHU",
    data: [["Devghat", 6]],
  },
  {
    mapData: narayani,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "NARAYANI",
    id: "NARAYANI",
    data: [
      { "hc-key": "BARA", value: 58, drilldown: "BARA" },
      { "hc-key": "CHITAWAN", value: 20, drilldown: "CHITAWAN" },
      { "hc-key": "MAKAWANPUR", value: 79, drilldown: "MAKAWANPUR" },
      { "hc-key": "PARSA", value: 59, drilldown: "PARSA" },
      { "hc-key": "RAUTAHAT", value: 96, drilldown: "RAUTAHAT" },
      { "hc-key": "TANAHU", value: 23, drilldown: "TANAHU" },
    ],
  },
  {
    mapData: dang,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "DANG",
    id: "DANG",
    data: [
      ["Babai", 40],
      ["Banglachuli", 99],
      ["Dangisharan", 9],
      ["Gadhawa", 21],
      ["Ghorahi", 8],
      ["Lamahi", 90],
      ["Rajpur", 64],
      ["Rapti", 35],
      ["Shantinagar", 58],
      ["Tulsipur", 8],
    ],
  },
  {
    mapData: jajarkot,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "JAJARKOT",
    id: "JAJARKOT",
    data: [["Nalagad", 39]],
  },
  {
    mapData: pyuthan,
    name: "PYUTHAN",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "PYUTHAN",
    data: [
      ["Ayirabati", 71],
      ["Jhimruk", 29],
      ["Mandavi", 1],
      ["Naubahini", 22],
      ["Pyuthan", 45],
      ["Sarumarani", 97],
      ["Sworgadwary", 49],
    ],
  },
  {
    mapData: rolpa,
    name: "ROLPA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "ROLPA",
    data: [
      ["Gangadev", 92],
      ["Madi", 6],
      ["Rolpa", 65],
      ["Runtigadi", 15],
      ["Sunilsmriti", 23],
      ["Thawang", 27],
      ["Tribeni", 99],
    ],
  },
  {
    mapData: rukum_east,
    name: "RUKUM EAST",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RUKUM EAST",
    data: [
      ["Bhume", 4],
      ["Sisne", 29],
    ],
  },
  {
    mapData: rukum_west,
    name: "RUKUM WEST",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RUKUM WEST",
    data: [
      ["Banfikot", 51],
      ["Chaurjahari", 47],
      ["Musikot", 78],
      ["Tribeni", 5],
    ],
  },
  {
    mapData: salyan,
    name: "SALYAN",
    id: "SALYAN",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    data: [
      ["Bagchaur", 52],
      ["Bangad Kupinde", 84],
      ["Chhatreshwori", 7],
      ["Darma", 24],
      ["Kalimati", 55],
      ["Kapurkot", 21],
      ["Kumakha", 98],
      ["Sharada", 89],
      ["Tribeni", 58],
    ],
  },
  {
    mapData: rapti,
    name: "RAPTI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "RAPTI",
    data: [
      { "hc-key": "DANG", value: 43, drilldown: "DANG" },
      { "hc-key": "JAJARKOT", value: 6, drilldown: "JAJARKOT" },
      { "hc-key": "PYUTHAN", value: 44, drilldown: "PYUTHAN" },
      { "hc-key": "ROLPA", value: 55, drilldown: "ROLPA" },
      { "hc-key": "RUKUM EAST", value: 66, drilldown: "RUKUM EAST" },
      { "hc-key": "RUKUM WEST", value: 42, drilldown: "RUKUM WEST" },
      { "hc-key": "SALYAN", value: 26, drilldown: "SALYAN" },
    ],
  },
  {
    mapData: khotang,
    name: "KHOTANG",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KHOTANG",
    data: [
      ["Ainselukhark", 81],
      ["Diktel Rupakot Majhuwagadhi", 12],
      ["Diprung Chuichumma", 40],
      ["Halesi Tuwachung", 16],
      ["Khotehang", 74],
      ["Rawa Besi", 6],
      ["Sakela", 91],
    ],
  },
  {
    mapData: okhaldhunga,
    name: "OKHALDHUNGA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "OKHALDHUNGA",
    data: [
      ["Chisankhugadhi", 97],
      ["Khijidemba", 42],
      ["Manebhanjyang", 83],
      ["Molung", 76],
      ["Siddhicharan", 65],
    ],
  },
  {
    mapData: saptari,
    name: "SAPTARI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SAPTARI",
    data: [
      ["Agnisair Krishna Savaran", 62],
      ["Balan Bihul", 31],
      ["Bishnupur", 84],
      ["Bode Barsain", 36],
      ["Chhinnamasta", 58],
      ["Dakneshwori", 37],
      ["Hanumannagar Kankalini", 20],
      ["Kanchanrup", 11],
      ["Khadak", 4],
      ["Mahadeva", 95],
      ["Rajbiraj", 41],
      ["Rajgadh", 62],
      ["Rupani", 88],
      ["Saptakoshi", 50],
      ["Shambhunath", 31],
      ["Surunga", 86],
      ["Tilathi Koiladi", 55],
      ["Tirahut", 83],
    ],
  },
  {
    mapData: siraha,
    name: "SIRAHA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SIRAHA",
    data: [
      ["Arnama", 21],
      ["Aurahi", 98],
      ["Bariyarpatti", 18],
      ["Bhagawanpur", 55],
      ["Bishnupur", 86],
      ["Dhangadhimai", 58],
      ["Golbazar", 89],
      ["Kalyanpur", 0],
      ["Karjanha", 42],
      ["Lahan", 92],
      ["Laxmipur Patari", 95],
      ["Mirchaiya", 57],
      ["Naraha", 82],
      ["Nawarajpur", 61],
      ["Sakhuwanankarkatti", 40],
      ["Siraha", 65],
      ["Sukhipur", 1],
    ],
  },
  {
    mapData: solukhumbu,
    name: "SOLUKHUMBU",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SOLUKHUMBU",
    data: [
      ["Khumbupasanglahmu", 30],
      ["Solududhakunda", 60],
      ["Thulung Dudhkoshi", 62],
    ],
  },
  {
    mapData: udayapur,
    name: "UDAYAPUR",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "UDAYAPUR",
    data: [
      ["Belaka", 65],
      ["Chaudandigadhi", 2],
      ["Katari", 30],
      ["Rautamai", 3],
      ["Sunkoshi", 2],
      ["Triyuga", 81],
      ["Udayapurgadhi", 41],
    ],
  },
  {
    mapData: sagarmatha,
    name: "SAGARMATHA",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SAGARMATHA",
    data: [
      { "hc-key": "KHOTANG", value: 76, drilldown: "KHOTANG" },
      { "hc-key": "OKHALDHUNGA", value: 88, drilldown: "OKHALDHUNGA" },
      { "hc-key": "SAPTARI", value: 41, drilldown: "SAPTARI" },
      { "hc-key": "SIRAHA", value: 81, drilldown: "SIRAHA" },
      { "hc-key": "SOLUKHUMBU", value: 56, drilldown: "SOLUKHUMBU" },
      { "hc-key": "UDAYAPUR", value: 55, drilldown: "UDAYAPUR" },
    ],
  },
  {
    mapData: achham,
    name: "ACHHAM",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "ACHHAM",
    data: [
      ["Bannigadhi Jayagadh", 61],
      ["Dhakari", 2],
      ["Mangalsen", 31],
      ["Mellekh", 78],
      ["Panchadewal Binayak", 5],
      ["Ramaroshan", 94],
      ["Sanphebagar", 57],
      ["Turmakhad", 3],
    ],
  },
  {
    mapData: baitadi,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "BAITADI",
    id: "BAITADI",
    data: [["Sigas", 43]],
  },
  {
    mapData: bajhang,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "BAJHANG",
    id: "BAJHANG",
    data: [
      ["Bungal", 47],
      ["Jaya Prithivi", 91],
      ["Masta", 71],
      ["Thalara", 77],
    ],
  },
  {
    mapData: bajura,
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    name: "BAJURA",
    id: "BAJURA",
    data: [
      ["Badimalika", 70],
      ["Budhiganga", 42],
    ],
  },
  {
    mapData: doti,
    name: "DOTI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "DOTI",
    data: [
      ["Adharsha", 97],
      ["Badikedar", 64],
      ["Bogtan Fudisal", 72],
      ["Dipayal Silgadi", 28],
      ["Jorayal", 24],
      ["K I Singh", 56],
      ["Purbichauki", 41],
      ["Sayal", 68],
      ["Shikhar", 11],
    ],
  },
  {
    mapData: kailali,
    name: "KAILALI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "KAILALI",
    data: [
      ["Bardagoriya", 51],
      ["Bhajani", 76],
      ["Chure", 78],
      ["Dhangadhi", 62],
      ["Gauriganga", 91],
      ["Ghodaghodi", 21],
      ["Godawari", 40],
      ["Janaki", 67],
      ["Joshipur", 87],
      ["Kailari", 33],
      ["Lamkichuha", 41],
      ["Mohanyal", 21],
      ["Tikapur", 71],
    ],
  },
  {
    mapData: seti,
    name: "SETI",
    dataLabels: {
      enabled: true,
      color: "#FFFFFF",
      format: "{point.hc-key}: {point.value}",
    },
    id: "SETI",
    data: [
      { "hc-key": "ACHHAM", value: 66, drilldown: "ACHHAM" },
      { "hc-key": "BAITADI", value: 47, drilldown: "BAITADI" },
      { "hc-key": "BAJHANG", value: 75, drilldown: "BAJHANG" },
      { "hc-key": "BAJURA", value: 78, drilldown: "BAJURA" },
      { "hc-key": "DOTI", value: 96, drilldown: "DOTI" },
      { "hc-key": "KAILALI", value: 97, drilldown: "KAILALI" },
    ],
  },
];
export { drilldownSeries };
