// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Mode$ReactHooksTemplate = require("./mode.bs.js");

function id(t) {
  return t[/* id */0];
}

function uid(t) {
  return t[/* uid */1];
}

function name(t) {
  return t[/* name */2];
}

function modes(t) {
  return t[/* modes */3];
}

function surveyName(t) {
  return t[/* surveyName */4];
}

function decode(json) {
  return /* record */[
          /* id */Json_decode.field("id", Json_decode.$$int, json),
          /* uid */Json_decode.field("uid", Json_decode.string, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* modes */Json_decode.field("campaign_modes", (function (param) {
                  return Json_decode.list(Mode$ReactHooksTemplate.decoder, param);
                }), json),
          /* surveyName */Json_decode.field("survey", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json)
        ];
}

exports.id = id;
exports.uid = uid;
exports.name = name;
exports.modes = modes;
exports.surveyName = surveyName;
exports.decode = decode;
/* No side effect */
