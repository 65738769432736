// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function ShortTextEditor(Props) {
  Props.shortText;
  Props.onShortTextChange;
  return React.createElement("div", undefined);
}

var make = ShortTextEditor;

exports.make = make;
/* react Not a pure module */
