// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");

function EmailInput(Props) {
  var email = Props.email;
  var label = Props.label;
  var handleChange = Props.handleChange;
  var errorCSS = Props.errorCSS;
  var errorText = Props.errorText;
  return React.createElement(React.Fragment, undefined, React.createElement("label", {
                  className: "block mb-10-px"
                }, React.createElement("p", {
                      className: "mb-10-px font-semibold"
                    }, label), React.createElement("input", {
                      className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px px-20-px py-10-px",
                      type: "email",
                      value: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, email),
                      onChange: handleChange
                    })), React.createElement("div", {
                  className: errorCSS
                }, errorText));
}

var make = EmailInput;

exports.make = make;
/* react Not a pure module */
