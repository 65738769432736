// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var MsisdnSelected$ReactHooksTemplate = require("./msisdnSelected.bs.js");

function setSelected(selected, t) {
  return /* record */[
          /* length */t[/* length */0],
          /* selected */selected
        ];
}

function resetSelected(t) {
  return /* record */[
          /* length */t[/* length */0],
          /* selected */undefined
        ];
}

function getLength(t) {
  return t[/* length */0];
}

function fromJson(json) {
  return /* record */[
          /* length */Json_decode.field("length", Json_decode.$$int, json),
          /* selected */Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("selected", (function (param) {
                                return Json_decode.optional(MsisdnSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        ];
}

function toString(t) {
  var match = t[/* selected */1];
  if (match !== undefined) {
    return MsisdnSelected$ReactHooksTemplate.toString(Caml_option.valFromOption(match));
  } else {
    return "";
  }
}

function isSelected(t) {
  return Belt_Option.isSome(t[/* selected */1]);
}

function encodeParams(t) {
  return Pervasives.$at(/* :: */[
              /* tuple */[
                "length",
                t[/* length */0]
              ],
              /* [] */0
            ], Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t[/* selected */1], MsisdnSelected$ReactHooksTemplate.encode));
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "category_type",
                "msisdn"
              ],
              /* :: */[
                /* tuple */[
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                /* [] */0
              ]
            ]);
}

exports.getLength = getLength;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.isSelected = isSelected;
exports.toString = toString;
exports.encode = encode;
exports.fromJson = fromJson;
/* Utils-ReactHooksTemplate Not a pure module */
