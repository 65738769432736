// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");

function emptyState(param) {
  return /* record */[
          /* header : [] */0,
          /* contents : [] */0
        ];
}

function noMessage(param) {
  return "";
}

function decodeDownloadReport(json) {
  return Json_decode.field("file_name", Json_decode.string, json);
}

function decodeReportDetails(header, json) {
  return List.map((function (name) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field(name, Json_decode.string, param);
                            }), json);
              }), header);
}

function decode(json) {
  var header = Json_decode.field("header", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }), json);
  return /* record */[
          /* header */header,
          /* contents */Json_decode.field("report", (function (param) {
                  return Json_decode.list((function (param) {
                                return decodeReportDetails(header, param);
                              }), param);
                }), json)
        ];
}

var Report = {
  emptyState: emptyState,
  noMessage: noMessage,
  decodeDownloadReport: decodeDownloadReport,
  decodeReportDetails: decodeReportDetails,
  decode: decode
};

var emptyReports = /* record */[
  /* header : [] */0,
  /* contents : [] */0
];

var initialState = /* record */[
  /* loading */true,
  /* reports */emptyReports,
  /* toggleFilter */false
];

function reducer(state, param) {
  if (typeof param === "number") {
    if (param !== 0) {
      return /* record */[
              /* loading */state[/* loading */0],
              /* reports */state[/* reports */1],
              /* toggleFilter */!state[/* toggleFilter */2]
            ];
    } else {
      return /* record */[
              /* loading */true,
              /* reports */state[/* reports */1],
              /* toggleFilter */state[/* toggleFilter */2]
            ];
    }
  } else {
    return /* record */[
            /* loading */false,
            /* reports */param[0],
            /* toggleFilter */state[/* toggleFilter */2]
          ];
  }
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = /* record */[
  /* total */"1",
  /* perPage */"1"
];

function CampaignReport(Props) {
  var search = Props.search;
  var campaignId = Props.campaignId;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return emptyPageInfo;
        }));
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var queryParams = Querystringify.parse(search);
  var match$2 = Js_dict.get(queryParams, "page");
  var currentPage;
  if (match$2 !== undefined) {
    var x = match$2;
    if (x === "") {
      currentPage = 1;
    } else {
      var page = Belt_Int.fromString(x);
      currentPage = page !== undefined ? page : 1;
    }
  } else {
    currentPage = 1;
  }
  var url = "/api/v1/general_report?campaign_id=" + (String(campaignId) + "&page=") + String(currentPage);
  var handlePageChange = function (pageNumber) {
    var pageNo = String(pageNumber);
    return ReasonReactRouter.push("/campaign/" + (String(campaignId) + ("/campaign_report?page=" + (String(pageNo) + ""))));
  };
  var fetchReport = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then((function (param) {
                  var headers = param[0];
                  var total = totalRecord(Caml_option.null_to_opt(headers.get("Total")));
                  var perPage$1 = perPage(Caml_option.null_to_opt(headers.get("Per-Page")));
                  var report = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("report", decode, json));
                        }));
                  return Promise.resolve(/* tuple */[
                              report,
                              /* tuple */[
                                total,
                                perPage$1
                              ]
                            ]);
                }));
  };
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* FetchReportsInProgress */0);
    fetchReport(/* () */0).then((function (param) {
            var pageInfo = param[1];
            var perPage = pageInfo[1];
            var total = pageInfo[0];
            Curry._1(setPageInfo, (function (param) {
                    return /* record */[
                            /* total */total,
                            /* perPage */perPage
                          ];
                  }));
            param[0].then((function (report) {
                    return Promise.resolve(Curry._1(dispatch, /* FetchReportsCompleted */[report]));
                  }));
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  };
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* FetchReportsInProgress */0);
          fetchReport(/* () */0).then((function (param) {
                  var pageInfo = param[1];
                  var perPage = pageInfo[1];
                  var total = pageInfo[0];
                  Curry._1(setPageInfo, (function (param) {
                          return /* record */[
                                  /* total */total,
                                  /* perPage */perPage
                                ];
                        }));
                  param[0].then((function (report) {
                          return Promise.resolve(Curry._1(dispatch, /* FetchReportsCompleted */[report]));
                        }));
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), /* array */[currentPage]);
  var match$3 = state[/* toggleFilter */2];
  var match$4 = state[/* loading */0];
  return React.createElement(React.Fragment, undefined, match$3 ? React.createElement(Filters$ReactHooksTemplate.make, {
                    onClose: toggleFilter,
                    onApply: onApplyFilter,
                    showDateFilter: false,
                    showFilterPills: false
                  }) : React.createElement(React.Fragment, undefined), match$4 ? React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading..."
                      })) : React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center p-20-px"
                      }, React.createElement("div", {
                            className: "flex"
                          }, React.createElement("h2", {
                                className: "text-xl font-semibold pt-6"
                              }, "Campaign Report"), React.createElement(Pagination$ReactHooksTemplate.make, {
                                total: totalPages(Caml_format.caml_int_of_string(pageInfo[/* total */0]), Caml_format.caml_int_of_string(pageInfo[/* perPage */1])),
                                currentPage: currentPage,
                                handlePageChange: handlePageChange
                              })), React.createElement("div", {
                            className: "flex gap-4"
                          }, React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n                shadow-inner flex rounded py-2 px-3 text-xs",
                                id: "filters",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleFilter */1);
                                  })
                              }, React.createElement("img", {
                                    className: "h-14-px mr-2-px",
                                    src: Image$ReactHooksTemplate.filterButton
                                  }), "Filters"), React.createElement("button", {
                                className: "bg-pigmentblue border border-gray-400\n                  flex rounded py-2 px-3 text-xs text-white",
                                onClick: (function ($$event) {
                                    $$event.preventDefault();
                                    var download_url = "/api/v1/download_report?campaign_id=" + campaignId;
                                    Http$ReactHooksTemplate.fetchGetJSONDecode(download_url, decodeDownloadReport).then((function (file_name) {
                                            return Promise.resolve((console.log(file_name), ReasonReactRouter.push("/campaigns/report_downloads/" + campaignId)));
                                          }));
                                    return /* () */0;
                                  })
                              }, React.createElement("img", {
                                    className: "h-14-px mr-1 filter-white",
                                    src: Image$ReactHooksTemplate.download
                                  }), "Download"))), React.createElement("div", {
                        className: "flex px-20-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, $$Array.of_list(List.mapi((function (i, header) {
                                              return React.createElement("th", {
                                                          key: String(i),
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "flex"
                                                            }, $$String.capitalize_ascii(header), React.createElement("img", {
                                                                  className: "ml-4-px",
                                                                  src: Image$ReactHooksTemplate.heading
                                                                })));
                                            }), state[/* reports */1][/* header */0])))), React.createElement("tbody", undefined, $$Array.of_list(List.mapi((function (i, x) {
                                          return React.createElement("tr", {
                                                      key: String(i),
                                                      className: "border-b border-bottom-gray-600"
                                                    }, $$Array.of_list(List.mapi((function (k, step) {
                                                                if (step !== undefined) {
                                                                  return React.createElement("td", {
                                                                              key: String(k),
                                                                              className: "px-4 py-2"
                                                                            }, step);
                                                                } else {
                                                                  return React.createElement("td", {
                                                                              key: String(k),
                                                                              className: "px-4 py-2"
                                                                            }, "");
                                                                }
                                                              }), x)));
                                        }), state[/* reports */1][/* contents */1])))))));
}

var noMessage$1 = "";

var make = CampaignReport;

exports.Report = Report;
exports.emptyReports = emptyReports;
exports.initialState = initialState;
exports.reducer = reducer;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.noMessage = noMessage$1;
exports.make = make;
/* react Not a pure module */
