// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var User$ReactHooksTemplate = require("./user.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var ListType$ReactHooksTemplate = require("./listType.bs.js");
var DataField$ReactHooksTemplate = require("./dataField.bs.js");
var SelectBox$ReactHooksTemplate = require("../common/SelectBox.bs.js");
var TextInput$ReactHooksTemplate = require("../common/TextInput.bs.js");
var EmailInput$ReactHooksTemplate = require("../common/EmailInput.bs.js");
var ToggleInput$ReactHooksTemplate = require("../common/ToggleInput.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var DataRestriction$ReactHooksTemplate = require("./dataRestriction.bs.js");
var NewPasswordInput$ReactHooksTemplate = require("../common/NewPasswordInput.bs.js");

function create($staropt$star, role) {
  var enabled = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */[
          /* role */role,
          /* enabled */enabled
        ];
}

function role(t) {
  return t[/* role */0];
}

function roleId(t) {
  return Role$ReactHooksTemplate.id(t[/* role */0]);
}

function enabled(t) {
  return t[/* enabled */1];
}

function toggleStatus(t) {
  return /* record */[
          /* role */t[/* role */0],
          /* enabled */!t[/* enabled */1]
        ];
}

var RoleStatus = {
  create: create,
  role: role,
  roleId: roleId,
  enabled: enabled,
  toggleStatus: toggleStatus
};

function create$1($staropt$star, name, $staropt$star$1, $staropt$star$2, $staropt$star$3, operator, value) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var dataField = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var _destroy = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var showOptions = $staropt$star$3 !== undefined ? $staropt$star$3 : false;
  return /* record */[
          /* id */id,
          /* name */name,
          /* dataField */dataField,
          /* operator */operator,
          /* value */value,
          /* _destroy */_destroy,
          /* showOptions */showOptions
        ];
}

var empty = /* record */[
  /* id */undefined,
  /* name */"Select Field",
  /* dataField */undefined,
  /* operator */"in",
  /* value : [] */0,
  /* _destroy */false,
  /* showOptions */false
];

function id(t) {
  return t[/* id */0];
}

function value(t) {
  return t[/* value */4];
}

function name(t) {
  return t[/* name */1];
}

function dataField(t) {
  return t[/* dataField */2];
}

function operator(t) {
  return t[/* operator */3];
}

function _destroy(t) {
  return t[/* _destroy */5];
}

function showOptions(t) {
  return t[/* showOptions */6];
}

var DataRestrictionCondition = {
  create: create$1,
  empty: empty,
  id: id,
  value: value,
  name: name,
  dataField: dataField,
  operator: operator,
  _destroy: _destroy,
  showOptions: showOptions
};

function reduerTest(f, t) {
  return f + t | 0;
}

function dispatchTest(g) {
  return (function (param) {
      return g + param | 0;
    });
}

function reducer(state, action) {
  if (state) {
    var formView = state[0];
    if (typeof action === "number") {
      if (formView.tag) {
        var userForm = formView[0];
        return /* Available */[/* EditUser */Block.__(1, [/* record */[
                      /* id */userForm[/* id */0],
                      /* email */userForm[/* email */1],
                      /* emailAvailable */userForm[/* emailAvailable */2],
                      /* firstName */userForm[/* firstName */3],
                      /* lastName */userForm[/* lastName */4],
                      /* username */userForm[/* username */5],
                      /* usernameAvailable */userForm[/* usernameAvailable */6],
                      /* activeStatus */!userForm[/* activeStatus */7],
                      /* roleStatuses */userForm[/* roleStatuses */8],
                      /* dataRestrictionConditions */userForm[/* dataRestrictionConditions */9],
                      /* dataFields */userForm[/* dataFields */10]
                    ]])];
      } else {
        var userForm$1 = formView[0];
        return /* Available */[/* NewUser */Block.__(0, [
                    /* record */[
                      /* id */userForm$1[/* id */0],
                      /* email */userForm$1[/* email */1],
                      /* emailAvailable */userForm$1[/* emailAvailable */2],
                      /* firstName */userForm$1[/* firstName */3],
                      /* lastName */userForm$1[/* lastName */4],
                      /* username */userForm$1[/* username */5],
                      /* usernameAvailable */userForm$1[/* usernameAvailable */6],
                      /* activeStatus */!userForm$1[/* activeStatus */7],
                      /* roleStatuses */userForm$1[/* roleStatuses */8],
                      /* dataRestrictionConditions */userForm$1[/* dataRestrictionConditions */9],
                      /* dataFields */userForm$1[/* dataFields */10]
                    ],
                    formView[1]
                  ])];
      }
    } else {
      switch (action.tag | 0) {
        case /* ShowAddNewUserForm */0 :
            return /* Available */[action[0]];
        case /* ChangeEmail */1 :
            var email = action[0];
            if (formView.tag) {
              var userForm$2 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$2[/* id */0],
                            /* email */email,
                            /* emailAvailable */userForm$2[/* emailAvailable */2],
                            /* firstName */userForm$2[/* firstName */3],
                            /* lastName */userForm$2[/* lastName */4],
                            /* username */userForm$2[/* username */5],
                            /* usernameAvailable */userForm$2[/* usernameAvailable */6],
                            /* activeStatus */userForm$2[/* activeStatus */7],
                            /* roleStatuses */userForm$2[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$2[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$2[/* dataFields */10]
                          ]])];
            } else {
              var userForm$3 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$3[/* id */0],
                            /* email */email,
                            /* emailAvailable */userForm$3[/* emailAvailable */2],
                            /* firstName */userForm$3[/* firstName */3],
                            /* lastName */userForm$3[/* lastName */4],
                            /* username */userForm$3[/* username */5],
                            /* usernameAvailable */userForm$3[/* usernameAvailable */6],
                            /* activeStatus */userForm$3[/* activeStatus */7],
                            /* roleStatuses */userForm$3[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$3[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$3[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* ChangeEmailAvailability */2 :
            var emailAvailable = action[0];
            if (formView.tag) {
              var userForm$4 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$4[/* id */0],
                            /* email */userForm$4[/* email */1],
                            /* emailAvailable */emailAvailable,
                            /* firstName */userForm$4[/* firstName */3],
                            /* lastName */userForm$4[/* lastName */4],
                            /* username */userForm$4[/* username */5],
                            /* usernameAvailable */userForm$4[/* usernameAvailable */6],
                            /* activeStatus */userForm$4[/* activeStatus */7],
                            /* roleStatuses */userForm$4[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$4[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$4[/* dataFields */10]
                          ]])];
            } else {
              var userForm$5 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$5[/* id */0],
                            /* email */userForm$5[/* email */1],
                            /* emailAvailable */emailAvailable,
                            /* firstName */userForm$5[/* firstName */3],
                            /* lastName */userForm$5[/* lastName */4],
                            /* username */userForm$5[/* username */5],
                            /* usernameAvailable */userForm$5[/* usernameAvailable */6],
                            /* activeStatus */userForm$5[/* activeStatus */7],
                            /* roleStatuses */userForm$5[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$5[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$5[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* ChangeFirstName */3 :
            var firstName = action[0];
            if (formView.tag) {
              var userForm$6 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$6[/* id */0],
                            /* email */userForm$6[/* email */1],
                            /* emailAvailable */userForm$6[/* emailAvailable */2],
                            /* firstName */firstName,
                            /* lastName */userForm$6[/* lastName */4],
                            /* username */userForm$6[/* username */5],
                            /* usernameAvailable */userForm$6[/* usernameAvailable */6],
                            /* activeStatus */userForm$6[/* activeStatus */7],
                            /* roleStatuses */userForm$6[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$6[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$6[/* dataFields */10]
                          ]])];
            } else {
              var userForm$7 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$7[/* id */0],
                            /* email */userForm$7[/* email */1],
                            /* emailAvailable */userForm$7[/* emailAvailable */2],
                            /* firstName */firstName,
                            /* lastName */userForm$7[/* lastName */4],
                            /* username */userForm$7[/* username */5],
                            /* usernameAvailable */userForm$7[/* usernameAvailable */6],
                            /* activeStatus */userForm$7[/* activeStatus */7],
                            /* roleStatuses */userForm$7[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$7[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$7[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* ChangeLastName */4 :
            var lastName = action[0];
            if (formView.tag) {
              var userForm$8 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$8[/* id */0],
                            /* email */userForm$8[/* email */1],
                            /* emailAvailable */userForm$8[/* emailAvailable */2],
                            /* firstName */userForm$8[/* firstName */3],
                            /* lastName */lastName,
                            /* username */userForm$8[/* username */5],
                            /* usernameAvailable */userForm$8[/* usernameAvailable */6],
                            /* activeStatus */userForm$8[/* activeStatus */7],
                            /* roleStatuses */userForm$8[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$8[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$8[/* dataFields */10]
                          ]])];
            } else {
              var userForm$9 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$9[/* id */0],
                            /* email */userForm$9[/* email */1],
                            /* emailAvailable */userForm$9[/* emailAvailable */2],
                            /* firstName */userForm$9[/* firstName */3],
                            /* lastName */lastName,
                            /* username */userForm$9[/* username */5],
                            /* usernameAvailable */userForm$9[/* usernameAvailable */6],
                            /* activeStatus */userForm$9[/* activeStatus */7],
                            /* roleStatuses */userForm$9[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$9[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$9[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* ChangeUserName */5 :
            var username = action[0];
            if (formView.tag) {
              var userForm$10 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$10[/* id */0],
                            /* email */userForm$10[/* email */1],
                            /* emailAvailable */userForm$10[/* emailAvailable */2],
                            /* firstName */userForm$10[/* firstName */3],
                            /* lastName */userForm$10[/* lastName */4],
                            /* username */username,
                            /* usernameAvailable */userForm$10[/* usernameAvailable */6],
                            /* activeStatus */userForm$10[/* activeStatus */7],
                            /* roleStatuses */userForm$10[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$10[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$10[/* dataFields */10]
                          ]])];
            } else {
              var userForm$11 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$11[/* id */0],
                            /* email */userForm$11[/* email */1],
                            /* emailAvailable */userForm$11[/* emailAvailable */2],
                            /* firstName */userForm$11[/* firstName */3],
                            /* lastName */userForm$11[/* lastName */4],
                            /* username */username,
                            /* usernameAvailable */userForm$11[/* usernameAvailable */6],
                            /* activeStatus */userForm$11[/* activeStatus */7],
                            /* roleStatuses */userForm$11[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$11[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$11[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* ChangeUserNameAvailability */6 :
            var usernameAvailable = action[0];
            if (formView.tag) {
              var userForm$12 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$12[/* id */0],
                            /* email */userForm$12[/* email */1],
                            /* emailAvailable */userForm$12[/* emailAvailable */2],
                            /* firstName */userForm$12[/* firstName */3],
                            /* lastName */userForm$12[/* lastName */4],
                            /* username */userForm$12[/* username */5],
                            /* usernameAvailable */usernameAvailable,
                            /* activeStatus */userForm$12[/* activeStatus */7],
                            /* roleStatuses */userForm$12[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$12[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$12[/* dataFields */10]
                          ]])];
            } else {
              var userForm$13 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$13[/* id */0],
                            /* email */userForm$13[/* email */1],
                            /* emailAvailable */userForm$13[/* emailAvailable */2],
                            /* firstName */userForm$13[/* firstName */3],
                            /* lastName */userForm$13[/* lastName */4],
                            /* username */userForm$13[/* username */5],
                            /* usernameAvailable */usernameAvailable,
                            /* activeStatus */userForm$13[/* activeStatus */7],
                            /* roleStatuses */userForm$13[/* roleStatuses */8],
                            /* dataRestrictionConditions */userForm$13[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$13[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* ChangeNewPassword */7 :
            if (formView.tag) {
              return /* Available */[/* EditUser */Block.__(1, [formView[0]])];
            } else {
              return /* Available */[/* NewUser */Block.__(0, [
                          formView[0],
                          /* record */[
                            /* newPassword */action[0],
                            /* newPasswordConfirmation */formView[1][/* newPasswordConfirmation */1]
                          ]
                        ])];
            }
        case /* ChangeNewPasswordConfirmation */8 :
            if (formView.tag) {
              return /* Available */[/* EditUser */Block.__(1, [formView[0]])];
            } else {
              return /* Available */[/* NewUser */Block.__(0, [
                          formView[0],
                          /* record */[
                            /* newPassword */formView[1][/* newPassword */0],
                            /* newPasswordConfirmation */action[0]
                          ]
                        ])];
            }
        case /* ToggleRoleStatus */9 :
            var roleStatus = action[0];
            if (formView.tag) {
              var userForm$14 = formView[0];
              var roleStatuses = List.map((function (x) {
                      var match = Caml_obj.caml_equal(x, roleStatus);
                      if (match) {
                        return toggleStatus(x);
                      } else {
                        return x;
                      }
                    }), userForm$14[/* roleStatuses */8]);
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$14[/* id */0],
                            /* email */userForm$14[/* email */1],
                            /* emailAvailable */userForm$14[/* emailAvailable */2],
                            /* firstName */userForm$14[/* firstName */3],
                            /* lastName */userForm$14[/* lastName */4],
                            /* username */userForm$14[/* username */5],
                            /* usernameAvailable */userForm$14[/* usernameAvailable */6],
                            /* activeStatus */userForm$14[/* activeStatus */7],
                            /* roleStatuses */roleStatuses,
                            /* dataRestrictionConditions */userForm$14[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$14[/* dataFields */10]
                          ]])];
            } else {
              var userForm$15 = formView[0];
              var roleStatuses$1 = List.map((function (x) {
                      var match = Caml_obj.caml_equal(x, roleStatus);
                      if (match) {
                        return toggleStatus(x);
                      } else {
                        return x;
                      }
                    }), userForm$15[/* roleStatuses */8]);
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$15[/* id */0],
                            /* email */userForm$15[/* email */1],
                            /* emailAvailable */userForm$15[/* emailAvailable */2],
                            /* firstName */userForm$15[/* firstName */3],
                            /* lastName */userForm$15[/* lastName */4],
                            /* username */userForm$15[/* username */5],
                            /* usernameAvailable */userForm$15[/* usernameAvailable */6],
                            /* activeStatus */userForm$15[/* activeStatus */7],
                            /* roleStatuses */roleStatuses$1,
                            /* dataRestrictionConditions */userForm$15[/* dataRestrictionConditions */9],
                            /* dataFields */userForm$15[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* AddDataRestrictionCondition */10 :
            var condition = action[0];
            if (formView.tag) {
              var userForm$16 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$16[/* id */0],
                            /* email */userForm$16[/* email */1],
                            /* emailAvailable */userForm$16[/* emailAvailable */2],
                            /* firstName */userForm$16[/* firstName */3],
                            /* lastName */userForm$16[/* lastName */4],
                            /* username */userForm$16[/* username */5],
                            /* usernameAvailable */userForm$16[/* usernameAvailable */6],
                            /* activeStatus */userForm$16[/* activeStatus */7],
                            /* roleStatuses */userForm$16[/* roleStatuses */8],
                            /* dataRestrictionConditions */List.append(userForm$16[/* dataRestrictionConditions */9], /* :: */[
                                  condition,
                                  /* [] */0
                                ]),
                            /* dataFields */userForm$16[/* dataFields */10]
                          ]])];
            } else {
              var userForm$17 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$17[/* id */0],
                            /* email */userForm$17[/* email */1],
                            /* emailAvailable */userForm$17[/* emailAvailable */2],
                            /* firstName */userForm$17[/* firstName */3],
                            /* lastName */userForm$17[/* lastName */4],
                            /* username */userForm$17[/* username */5],
                            /* usernameAvailable */userForm$17[/* usernameAvailable */6],
                            /* activeStatus */userForm$17[/* activeStatus */7],
                            /* roleStatuses */userForm$17[/* roleStatuses */8],
                            /* dataRestrictionConditions */List.append(userForm$17[/* dataRestrictionConditions */9], /* :: */[
                                  condition,
                                  /* [] */0
                                ]),
                            /* dataFields */userForm$17[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        case /* UpdateDataRestrictionConditions */11 :
            var dataRestrictionConditions = action[0];
            if (formView.tag) {
              var userForm$18 = formView[0];
              return /* Available */[/* EditUser */Block.__(1, [/* record */[
                            /* id */userForm$18[/* id */0],
                            /* email */userForm$18[/* email */1],
                            /* emailAvailable */userForm$18[/* emailAvailable */2],
                            /* firstName */userForm$18[/* firstName */3],
                            /* lastName */userForm$18[/* lastName */4],
                            /* username */userForm$18[/* username */5],
                            /* usernameAvailable */userForm$18[/* usernameAvailable */6],
                            /* activeStatus */userForm$18[/* activeStatus */7],
                            /* roleStatuses */userForm$18[/* roleStatuses */8],
                            /* dataRestrictionConditions */dataRestrictionConditions,
                            /* dataFields */userForm$18[/* dataFields */10]
                          ]])];
            } else {
              var userForm$19 = formView[0];
              return /* Available */[/* NewUser */Block.__(0, [
                          /* record */[
                            /* id */userForm$19[/* id */0],
                            /* email */userForm$19[/* email */1],
                            /* emailAvailable */userForm$19[/* emailAvailable */2],
                            /* firstName */userForm$19[/* firstName */3],
                            /* lastName */userForm$19[/* lastName */4],
                            /* username */userForm$19[/* username */5],
                            /* usernameAvailable */userForm$19[/* usernameAvailable */6],
                            /* activeStatus */userForm$19[/* activeStatus */7],
                            /* roleStatuses */userForm$19[/* roleStatuses */8],
                            /* dataRestrictionConditions */dataRestrictionConditions,
                            /* dataFields */userForm$19[/* dataFields */10]
                          ],
                          formView[1]
                        ])];
            }
        
      }
    }
  } else if (typeof action === "number" || action.tag) {
    return state;
  } else {
    return /* Available */[action[0]];
  }
}

function isPasswordFilled(x) {
  return !Curry._1(Domain$ReactHooksTemplate.$$String.Password.isEmpty, x);
}

function isPasswordValid(x) {
  var match = isPasswordFilled(x);
  if (match) {
    return Utils$ReactHooksTemplate.checkMinLength8(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isAlphanumericPassword(x) {
  var match = isPasswordFilled(x);
  if (match) {
    return Utils$ReactHooksTemplate.checkAlphanumeric(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isUserNameInPassword(email, x) {
  var emailInPwd = Utils$ReactHooksTemplate.checkUsernameExistInPassword(Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, email), Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  var match = isPasswordFilled(x);
  if (match) {
    return emailInPwd;
  } else {
    return false;
  }
}

function areIndpendentFieldsValid(formView) {
  if (formView.tag) {
    return true;
  } else {
    var passwordConfirmation = formView[1];
    if (isPasswordValid(passwordConfirmation[/* newPassword */0])) {
      return isPasswordValid(passwordConfirmation[/* newPasswordConfirmation */1]);
    } else {
      return false;
    }
  }
}

function arePasswordsFilled(x, y) {
  if (isPasswordFilled(x)) {
    return isPasswordFilled(y);
  } else {
    return false;
  }
}

function arePasswordsExactMatch(x, y) {
  var match = arePasswordsFilled(x, y);
  if (match) {
    return Caml_obj.caml_equal(x, y);
  } else {
    return true;
  }
}

function areDepedentFieldsValid(formView) {
  if (formView.tag) {
    return true;
  } else {
    var passwordConfirmation = formView[1];
    return arePasswordsExactMatch(passwordConfirmation[/* newPassword */0], passwordConfirmation[/* newPasswordConfirmation */1]);
  }
}

function hasEmail(form) {
  if (Caml_obj.caml_notequal(form[/* email */1], Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0))) {
    return form[/* emailAvailable */2];
  } else {
    return false;
  }
}

function hasFirstName(form) {
  return Caml_obj.caml_notequal(form[/* firstName */3], Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0));
}

function hasLastName(form) {
  return Caml_obj.caml_notequal(form[/* lastName */4], Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0));
}

function hasNewPassword(form) {
  return Caml_obj.caml_notequal(form[/* newPassword */0], Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0));
}

function hasNewPasswordConfirmation(form) {
  return Caml_obj.caml_notequal(form[/* newPasswordConfirmation */1], Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0));
}

function hasSomeRoleEnabled(form) {
  return Belt_List.some(form[/* roleStatuses */8], enabled);
}

function hasMandatoryParams(formView) {
  if (formView.tag) {
    var userForm = formView[0];
    if (hasEmail(userForm) && hasFirstName(userForm) && hasLastName(userForm)) {
      return hasSomeRoleEnabled(userForm);
    } else {
      return false;
    }
  } else {
    var passwordConfirmation = formView[1];
    var userForm$1 = formView[0];
    if (hasEmail(userForm$1) && hasFirstName(userForm$1) && hasLastName(userForm$1) && hasNewPassword(passwordConfirmation) && hasNewPasswordConfirmation(passwordConfirmation)) {
      return hasSomeRoleEnabled(userForm$1);
    } else {
      return false;
    }
  }
}

function isFormValid(formView) {
  if (hasMandatoryParams(formView) && areIndpendentFieldsValid(formView)) {
    return areDepedentFieldsValid(formView);
  } else {
    return false;
  }
}

var emptyUser_001 = /* email */Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, /* () */0);

var emptyUser_003 = /* firstName */Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0);

var emptyUser_004 = /* lastName */Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0);

var emptyUser_005 = /* username */Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0);

var emptyUser = /* record */[
  /* id */undefined,
  emptyUser_001,
  /* emailAvailable */true,
  emptyUser_003,
  emptyUser_004,
  emptyUser_005,
  /* usernameAvailable */true,
  /* activeStatus */false,
  /* roleStatuses : [] */0,
  /* dataRestrictionConditions : [] */0,
  /* dataFields : [] */0
];

var emptyPasswordConfirmation_000 = /* newPassword */Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0);

var emptyPasswordConfirmation_001 = /* newPasswordConfirmation */Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, /* () */0);

var emptyPasswordConfirmation = /* record */[
  emptyPasswordConfirmation_000,
  emptyPasswordConfirmation_001
];

function fetchRoles(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/roles", (function (param) {
                return Json_decode.field("roles", (function (param) {
                              return Json_decode.list(Role$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function fetchDataFields(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/data_fields", (function (param) {
                return Json_decode.field("data_fields", (function (param) {
                              return Json_decode.list(DataField$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function buildUserDict(formView) {
  var params = { };
  var userForm = formView[0];
  params["email"] = Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, userForm[/* email */1]);
  params["username"] = Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm[/* username */5]);
  if (!formView.tag) {
    var passwordConfirmation = formView[1];
    params["password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation[/* newPassword */0]);
    params["password_confirmation"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation[/* newPasswordConfirmation */1]);
  }
  params["first_name"] = Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm[/* firstName */3]);
  params["last_name"] = Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm[/* lastName */4]);
  params["active"] = userForm[/* activeStatus */7];
  params["role_ids"] = $$Array.of_list(List.map((function (prim) {
              return prim;
            }), List.map(roleId, List.filter(enabled)(userForm[/* roleStatuses */8]))));
  var dataRestrictionAttributes = Belt_List.map(Belt_List.keep(userForm[/* dataRestrictionConditions */9], (function (x) {
              var match = x[/* dataField */2];
              var match$1 = x[/* value */4];
              if (match !== undefined && match$1) {
                switch (match$1[0]) {
                  case "" :
                  case "Select Option" :
                      return false;
                  default:
                    return true;
                }
              } else {
                return false;
              }
            })), (function (x) {
          var attribute = { };
          var match = x[/* id */0];
          if (match !== undefined) {
            attribute["id"] = match;
            attribute["_destroy"] = x[/* _destroy */5];
          }
          var dataField = x[/* dataField */2];
          if (dataField !== undefined) {
            attribute["data_field_id"] = DataField$ReactHooksTemplate.id(Caml_option.valFromOption(dataField));
            attribute["operator"] = x[/* operator */3];
            attribute["value"] = Json_encode.list((function (y) {
                    return y;
                  }), x[/* value */4]);
          }
          return attribute;
        }));
  params["data_restrictions_attributes"] = $$Array.of_list(Belt_List.map(dataRestrictionAttributes, (function (prim) {
              return prim;
            })));
  var user = { };
  user["user"] = params;
  return user;
}

function createUser(formView) {
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users", buildUserDict(formView));
}

function updateUser(formView) {
  var id = formView[0][/* id */0];
  return Http$ReactHooksTemplate.fetchPutJSON("/api/v1/users/" + (String(id) + ""), buildUserDict(formView));
}

function checkEmailAPI(email, userId) {
  var api = "/users/check_email?[user][email]=" + Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, email);
  if (userId !== undefined) {
    return api + ("&[user][id]=" + String(userId));
  } else {
    return api;
  }
}

function checkUsernameAPI(username, userId) {
  var api = "/users/check_username?[user][username]=" + Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, username);
  if (userId !== undefined) {
    return api + ("&[user][id]=" + String(userId));
  } else {
    return api;
  }
}

function checkEmail(email, userId) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode(checkEmailAPI(email, userId), (function (param) {
                return Json_decode.field("available", Json_decode.bool, param);
              }));
}

function checkUsername(username, userId) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode(checkUsernameAPI(username, userId), (function (param) {
                return Json_decode.field("available", Json_decode.bool, param);
              }));
}

function editForm(formView) {
  if (formView.tag) {
    return true;
  } else {
    return false;
  }
}

function UserForm(Props) {
  var user = Props.user;
  var initialFormView;
  if (user !== undefined) {
    var user$1 = user;
    var match = User$ReactHooksTemplate.username(user$1);
    initialFormView = /* EditUser */Block.__(1, [/* record */[
          /* id */User$ReactHooksTemplate.id(user$1),
          /* email */User$ReactHooksTemplate.email(user$1),
          /* emailAvailable */true,
          /* firstName */User$ReactHooksTemplate.firstName(user$1),
          /* lastName */User$ReactHooksTemplate.lastName(user$1),
          /* username */match !== undefined ? Caml_option.valFromOption(match) : Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0),
          /* usernameAvailable */true,
          /* activeStatus */User$ReactHooksTemplate.active(user$1),
          /* roleStatuses */List.map((function (x) {
                  return create(true, x);
                }), User$ReactHooksTemplate.roles(user$1)),
          /* dataRestrictionConditions */Belt_List.map(User$ReactHooksTemplate.dataRestrictions(user$1), (function (x) {
                  return create$1(Caml_option.some(DataRestriction$ReactHooksTemplate.id(x)), DataField$ReactHooksTemplate.name(DataRestriction$ReactHooksTemplate.dataField(x)), Caml_option.some(Caml_option.some(DataRestriction$ReactHooksTemplate.dataField(x))), false, false, DataRestriction$ReactHooksTemplate.operator(x), DataRestriction$ReactHooksTemplate.value(x));
                })),
          /* dataFields : [] */0
        ]]);
  } else {
    initialFormView = /* NewUser */Block.__(0, [
        emptyUser,
        emptyPasswordConfirmation
      ]);
  }
  var match$1 = React.useReducer(reducer, /* FetchRolesInProgress */0);
  var dispatch = match$1[1];
  var state = match$1[0];
  var match$2 = React.useState((function () {
          return /* [] */0;
        }));
  var setDataFields = match$2[1];
  var dataFields = match$2[0];
  React.useEffect((function () {
          fetchRoles(/* () */0).then((function (roles) {
                  var tmp;
                  if (initialFormView.tag) {
                    var userForm = initialFormView[0];
                    tmp = /* EditUser */Block.__(1, [/* record */[
                          /* id */userForm[/* id */0],
                          /* email */userForm[/* email */1],
                          /* emailAvailable */userForm[/* emailAvailable */2],
                          /* firstName */userForm[/* firstName */3],
                          /* lastName */userForm[/* lastName */4],
                          /* username */userForm[/* username */5],
                          /* usernameAvailable */userForm[/* usernameAvailable */6],
                          /* activeStatus */userForm[/* activeStatus */7],
                          /* roleStatuses */Belt_List.map(roles, (function (role) {
                                  var enabled = Belt_List.length(Belt_List.keep(userForm[/* roleStatuses */8], (function (x) {
                                              return Role$ReactHooksTemplate.id(x[/* role */0]) === Role$ReactHooksTemplate.id(role);
                                            }))) > 0;
                                  return create(enabled, role);
                                })),
                          /* dataRestrictionConditions */userForm[/* dataRestrictionConditions */9],
                          /* dataFields */userForm[/* dataFields */10]
                        ]]);
                  } else {
                    var userForm$1 = initialFormView[0];
                    var partial_arg = false;
                    tmp = /* NewUser */Block.__(0, [
                        /* record */[
                          /* id */userForm$1[/* id */0],
                          /* email */userForm$1[/* email */1],
                          /* emailAvailable */userForm$1[/* emailAvailable */2],
                          /* firstName */userForm$1[/* firstName */3],
                          /* lastName */userForm$1[/* lastName */4],
                          /* username */userForm$1[/* username */5],
                          /* usernameAvailable */userForm$1[/* usernameAvailable */6],
                          /* activeStatus */userForm$1[/* activeStatus */7],
                          /* roleStatuses */Belt_List.map(roles, (function (param) {
                                  return create(partial_arg, param);
                                })),
                          /* dataRestrictionConditions */userForm$1[/* dataRestrictionConditions */9],
                          /* dataFields */userForm$1[/* dataFields */10]
                        ],
                        initialFormView[1]
                      ]);
                  }
                  return Promise.resolve(Curry._1(dispatch, /* ShowAddNewUserForm */Block.__(0, [tmp])));
                }));
          return ;
        }), ([]));
  React.useEffect((function () {
          fetchDataFields(/* () */0).then((function (dataFields) {
                  return Promise.resolve(Curry._1(setDataFields, (function (param) {
                                    return dataFields;
                                  })));
                }));
          return ;
        }), ([]));
  var handleFirstNameInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                  return Curry._1(dispatch, /* ChangeFirstName */Block.__(3, [Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, x)]));
                }), $$event);
  };
  var handleLastNameInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                  return Curry._1(dispatch, /* ChangeLastName */Block.__(4, [Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, x)]));
                }), $$event);
  };
  var handleNewPasswordInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(dispatch, /* ChangeNewPassword */Block.__(7, [Curry._1(Domain$ReactHooksTemplate.$$String.Password.create, x)]));
                }), $$event);
  };
  var handleNewPasswordConfirmationInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(dispatch, /* ChangeNewPasswordConfirmation */Block.__(8, [Curry._1(Domain$ReactHooksTemplate.$$String.Password.create, x)]));
                }), $$event);
  };
  var addRestrictionCondition = function ($$event) {
    $$event.preventDefault();
    return Curry._1(dispatch, /* AddDataRestrictionCondition */Block.__(10, [empty]));
  };
  var validCSS = "hidden";
  var errorCSS = "text-red-600 font-medium";
  var passwordMinLengthCSS = function (x) {
    var match = isPasswordValid(x);
    if (match) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var passwordAlphanumericCSS = function (x) {
    var match = isAlphanumericPassword(x);
    if (match) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var emailInPasswordCss = function (email, x) {
    var match = isUserNameInPassword(email, x);
    if (match) {
      return errorCSS;
    } else {
      return validCSS;
    }
  };
  var passwordMatchCSS = function (x, y) {
    var match = arePasswordsExactMatch(x, y);
    if (match) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var emailAvailableCSS = function (form) {
    if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, form[/* email */1])) {
      return validCSS;
    } else {
      var match = form[/* emailAvailable */2];
      if (match) {
        return validCSS;
      } else {
        return errorCSS;
      }
    }
  };
  if (state) {
    var formView = state[0];
    var userForm = formView[0];
    var tmp;
    if (formView.tag) {
      tmp = React.createElement(React.Fragment, undefined);
    } else {
      var passwordConfirmation = formView[1];
      tmp = React.createElement("div", {
            className: "flex items-top mb-30-px"
          }, React.createElement("div", {
                className: "w-1/2"
              }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                    password: passwordConfirmation[/* newPassword */0],
                    label: "Password",
                    handleChange: handleNewPasswordInput
                  }), React.createElement("div", {
                    className: passwordMinLengthCSS(passwordConfirmation[/* newPassword */0]) + " mb-10-px"
                  }, "Minimum length should be 8."), React.createElement("div", {
                    className: passwordAlphanumericCSS(passwordConfirmation[/* newPassword */0]) + " mb-10-px"
                  }, "Password should contain atleast one lowercase, one uppercase, one number and one special character"), React.createElement("div", {
                    className: emailInPasswordCss(userForm[/* email */1], passwordConfirmation[/* newPassword */0]) + " mb-10-px"
                  }, "Password Contains Email."), React.createElement("div", {
                    className: passwordMatchCSS(passwordConfirmation[/* newPassword */0], passwordConfirmation[/* newPasswordConfirmation */1])
                  }, "Passwords do not match.")), React.createElement("div", {
                className: "w-1/2"
              }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                    password: passwordConfirmation[/* newPasswordConfirmation */1],
                    label: "Confirm Password",
                    handleChange: handleNewPasswordConfirmationInput
                  }), React.createElement("div", {
                    className: passwordMinLengthCSS(passwordConfirmation[/* newPasswordConfirmation */1]) + " mb-10-px"
                  }, "Minimum length should be 8."), React.createElement("div", {
                    className: passwordMatchCSS(passwordConfirmation[/* newPassword */0], passwordConfirmation[/* newPasswordConfirmation */1])
                  }, "Passwords do not match."), React.createElement("div", {
                    className: passwordAlphanumericCSS(passwordConfirmation[/* newPasswordConfirmation */1]) + " mb-10-px"
                  }, "Password should contain atleast one lowercase, one uppercase, one number and one special character"), React.createElement("div", {
                    className: emailInPasswordCss(userForm[/* email */1], passwordConfirmation[/* newPasswordConfirmation */1]) + " mb-10-px"
                  }, "Password Contains Email.")));
    }
    var match$3 = editForm(formView);
    return React.createElement("div", {
                className: "p-30-px m-30-px w-2/3 bg-white"
              }, React.createElement("h1", {
                    className: "text-32-px mb-30-px"
                  }, "Add New User"), React.createElement("form", {
                    onSubmit: (function (param) {
                        var formView$1 = formView;
                        var $$event = param;
                        $$event.preventDefault();
                        var match = editForm(formView$1);
                        (
                              match ? updateUser(formView$1) : Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users", buildUserDict(formView$1))
                            ).then((function (response) {
                                  return Promise.resolve((console.log(response), /* () */0));
                                })).then((function (param) {
                                return Promise.resolve(ReasonReactRouter.push("/users/all"));
                              }));
                        return /* () */0;
                      })
                  }, React.createElement("div", {
                        className: "flex mb-30-px"
                      }, React.createElement("div", {
                            className: "w-1/2"
                          }, React.createElement(EmailInput$ReactHooksTemplate.make, {
                                email: userForm[/* email */1],
                                label: "Email",
                                handleChange: (function (param) {
                                    var form = userForm;
                                    var $$event = param;
                                    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                                                  var email = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, x);
                                                  checkEmail(email, form[/* id */0]).then((function (available) {
                                                          return Promise.resolve(Curry._1(dispatch, /* ChangeEmailAvailability */Block.__(2, [available])));
                                                        }));
                                                  return Curry._1(dispatch, /* ChangeEmail */Block.__(1, [email]));
                                                }), $$event);
                                  }),
                                errorCSS: emailAvailableCSS(userForm),
                                errorText: "Email is already taken"
                              })), React.createElement("div", {
                            className: "w-1/2"
                          }, React.createElement(TextInput$ReactHooksTemplate.make, {
                                label: "Username",
                                value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm[/* username */5]),
                                handleChange: (function (param) {
                                    var form = userForm;
                                    var $$event = param;
                                    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                                                  var username = Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, x);
                                                  checkUsername(username, form[/* id */0]).then((function (available) {
                                                          return Promise.resolve(Curry._1(dispatch, /* ChangeUserNameAvailability */Block.__(6, [available])));
                                                        }));
                                                  return Curry._1(dispatch, /* ChangeUserName */Block.__(5, [username]));
                                                }), $$event);
                                  }),
                                errorText: "Username is already taken",
                                error: !(Curry._1(Domain$ReactHooksTemplate.$$String.Name.isEmpty, userForm[/* username */5]) || userForm[/* usernameAvailable */6])
                              }))), React.createElement("div", {
                        className: "flex mb-30-px items-center"
                      }, React.createElement("div", {
                            className: "w-1/2"
                          }, React.createElement(TextInput$ReactHooksTemplate.make, {
                                label: "First Name",
                                value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm[/* firstName */3]),
                                handleChange: handleFirstNameInput
                              })), React.createElement("div", {
                            className: "w-1/2"
                          }, React.createElement(TextInput$ReactHooksTemplate.make, {
                                label: "Last Name",
                                value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm[/* lastName */4]),
                                handleChange: handleLastNameInput
                              }))), tmp, React.createElement("div", {
                        className: "flex mb-30-px items-top"
                      }, React.createElement("div", {
                            className: "flex mb-10-px w-1/2"
                          }, React.createElement("p", {
                                className: "font-semibold mr-20-px"
                              }, "Roles: "), React.createElement("div", {
                                className: "block"
                              }, $$Array.of_list(List.map((function (x) {
                                          return React.createElement("div", {
                                                      key: String(Role$ReactHooksTemplate.id(x[/* role */0])),
                                                      className: "mb-10-px"
                                                    }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                          checked: x[/* enabled */1],
                                                          label: Role$ReactHooksTemplate.name(x[/* role */0]),
                                                          handleChange: (function (param) {
                                                              return Curry._1(dispatch, /* ToggleRoleStatus */Block.__(9, [x]));
                                                            })
                                                        }));
                                        }), userForm[/* roleStatuses */8])))), React.createElement("div", {
                            className: "flex items-center w-1/2"
                          }, React.createElement("p", {
                                className: "font-semibold mr-15-px"
                              }, "Active: "), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                                checked: userForm[/* activeStatus */7],
                                handleChange: (function (_evt) {
                                    return Curry._1(dispatch, /* ToggleActiveStatus */0);
                                  })
                              }))), React.createElement("div", {
                        className: "flex mb-30-px items-top"
                      }, React.createElement("div", {
                            className: "w-full"
                          }, React.createElement("p", {
                                className: "font-semibold mb-5"
                              }, "Data Restrictions: "), React.createElement("div", {
                                className: ""
                              }, $$Array.of_list(Belt_List.mapWithIndex(userForm[/* dataRestrictionConditions */9], (function (index, x) {
                                          var match = x[/* _destroy */5];
                                          if (match) {
                                            return React.createElement(React.Fragment, undefined);
                                          } else {
                                            var match$1 = x[/* dataField */2];
                                            var partial_arg = userForm[/* dataRestrictionConditions */9];
                                            var match$2 = x[/* dataField */2];
                                            var tmp;
                                            if (match$2 !== undefined) {
                                              var dataField = Caml_option.valFromOption(match$2);
                                              tmp = React.createElement("option", {
                                                    key: DataField$ReactHooksTemplate.name(dataField),
                                                    value: String(DataField$ReactHooksTemplate.id(dataField))
                                                  }, DataField$ReactHooksTemplate.name(dataField));
                                            } else {
                                              tmp = React.createElement("option", {
                                                    key: "0",
                                                    value: "0"
                                                  }, "Select Field");
                                            }
                                            var match$3 = x[/* dataField */2];
                                            var options;
                                            if (match$3 !== undefined) {
                                              var match$4 = DataField$ReactHooksTemplate.dataType(Caml_option.valFromOption(match$3));
                                              options = match$4.tag ? /* [] */0 : ListType$ReactHooksTemplate.options(match$4[0]);
                                            } else {
                                              options = /* [] */0;
                                            }
                                            var partial_arg$1 = userForm[/* dataRestrictionConditions */9];
                                            var partial_arg$2 = userForm[/* dataRestrictionConditions */9];
                                            return React.createElement("div", {
                                                        key: String(index),
                                                        className: "flex items-center justify-between h-5 mb-5"
                                                      }, React.createElement("div", {
                                                            className: "w-2/6"
                                                          }, React.createElement("select", {
                                                                className: "border pl-1",
                                                                placeholder: "Select field",
                                                                value: match$1 !== undefined ? String(DataField$ReactHooksTemplate.id(Caml_option.valFromOption(match$1))) : "Select field",
                                                                onChange: (function (param) {
                                                                    var xs = partial_arg;
                                                                    var index$1 = index;
                                                                    var $$event = param;
                                                                    var dataFieldId = $$event.target.value;
                                                                    var dataField = List.filter((function (y) {
                                                                              return String(DataField$ReactHooksTemplate.id(y)) === dataFieldId;
                                                                            }))(dataFields);
                                                                    var conditions = Belt_List.mapWithIndex(xs, (function (i, x) {
                                                                            if (index$1 === i && dataField) {
                                                                              var head = dataField[0];
                                                                              return /* record */[
                                                                                      /* id */x[/* id */0],
                                                                                      /* name */DataField$ReactHooksTemplate.name(head),
                                                                                      /* dataField */Caml_option.some(head),
                                                                                      /* operator */x[/* operator */3],
                                                                                      /* value : [] */0,
                                                                                      /* _destroy */x[/* _destroy */5],
                                                                                      /* showOptions */x[/* showOptions */6]
                                                                                    ];
                                                                            } else {
                                                                              return x;
                                                                            }
                                                                          }));
                                                                    return Curry._1(dispatch, /* UpdateDataRestrictionConditions */Block.__(11, [conditions]));
                                                                  })
                                                              }, tmp, $$Array.of_list(Belt_List.map(Belt_List.keep(Belt_List.keep(dataFields, (function (x) {
                                                                                  var match = DataField$ReactHooksTemplate.dataType(x);
                                                                                  if (match.tag) {
                                                                                    return false;
                                                                                  } else {
                                                                                    return true;
                                                                                  }
                                                                                })), (function (x) {
                                                                              var addedDataFields = Belt_List.keepMap(Belt_List.map(userForm[/* dataRestrictionConditions */9], (function (x) {
                                                                                          return x[/* dataField */2];
                                                                                        })), (function (x) {
                                                                                      return x;
                                                                                    }));
                                                                              return !List.exists((function (y) {
                                                                                            return Caml_obj.caml_equal(x, y);
                                                                                          }), addedDataFields);
                                                                            })), (function (x) {
                                                                          return React.createElement("option", {
                                                                                      key: DataField$ReactHooksTemplate.name(x),
                                                                                      value: String(DataField$ReactHooksTemplate.id(x))
                                                                                    }, DataField$ReactHooksTemplate.name(x));
                                                                        }))))), React.createElement(SelectBox$ReactHooksTemplate.make, {
                                                            options: options,
                                                            placeholder: "Select Options",
                                                            handleChange: (function (param) {
                                                                var xs = partial_arg$1;
                                                                var index$1 = index;
                                                                var selectedOptions = param;
                                                                var conditions = Belt_List.mapWithIndex(xs, (function (i, x) {
                                                                        var match = index$1 === i;
                                                                        if (match) {
                                                                          return /* record */[
                                                                                  /* id */x[/* id */0],
                                                                                  /* name */x[/* name */1],
                                                                                  /* dataField */x[/* dataField */2],
                                                                                  /* operator */x[/* operator */3],
                                                                                  /* value */selectedOptions,
                                                                                  /* _destroy */x[/* _destroy */5],
                                                                                  /* showOptions */x[/* showOptions */6]
                                                                                ];
                                                                        } else {
                                                                          return x;
                                                                        }
                                                                      }));
                                                                return Curry._1(dispatch, /* UpdateDataRestrictionConditions */Block.__(11, [conditions]));
                                                              }),
                                                            selectedOptions: x[/* value */4],
                                                            className: "w-3/6"
                                                          }), React.createElement("div", {
                                                            className: "w-1/6"
                                                          }, React.createElement("a", {
                                                                className: "px-2 text-blue-800 text-xs\n                             font-extrabold cursor-pointer underline",
                                                                onClick: (function (param) {
                                                                    var xs = partial_arg$2;
                                                                    var index$1 = index;
                                                                    var $$event = param;
                                                                    $$event.preventDefault();
                                                                    var conditionToRemove = Belt_List.get(xs, index$1);
                                                                    var conditions;
                                                                    if (conditionToRemove !== undefined) {
                                                                      var match = conditionToRemove[/* id */0];
                                                                      conditions = match !== undefined ? Belt_List.mapWithIndex(xs, (function (i, x) {
                                                                                var match = i === index$1;
                                                                                if (match) {
                                                                                  return /* record */[
                                                                                          /* id */x[/* id */0],
                                                                                          /* name */x[/* name */1],
                                                                                          /* dataField */x[/* dataField */2],
                                                                                          /* operator */x[/* operator */3],
                                                                                          /* value */x[/* value */4],
                                                                                          /* _destroy */true,
                                                                                          /* showOptions */x[/* showOptions */6]
                                                                                        ];
                                                                                } else {
                                                                                  return x;
                                                                                }
                                                                              })) : Belt_List.keepWithIndex(xs, (function (param, i) {
                                                                                return i !== index$1;
                                                                              }));
                                                                    } else {
                                                                      conditions = xs;
                                                                    }
                                                                    return Curry._1(dispatch, /* UpdateDataRestrictionConditions */Block.__(11, [conditions]));
                                                                  })
                                                              }, "Remove")));
                                          }
                                        }))), React.createElement("a", {
                                    className: "text-blue-800 text-xs font-extrabold cursor-pointer\n                underline",
                                    onClick: addRestrictionCondition
                                  }, "+Add new condition")))), React.createElement("div", undefined, React.createElement("input", {
                            className: "bg-teal-500 hover:bg-teal-700 py-6-px px-20-px font-normal text-white font-16-px leading-snug",
                            disabled: !isFormValid(formView),
                            type: "submit",
                            value: match$3 ? "Update" : "Create"
                          }), React.createElement("a", {
                            className: "text-white bg-red-600 hover:bg-red-800 ml-4 py-6-px px-20-px font-16-px",
                            href: "/users/all"
                          }, "Cancel"))));
  } else {
    return React.createElement("p", undefined, "Form is loading...");
  }
}

var make = UserForm;

exports.RoleStatus = RoleStatus;
exports.DataRestrictionCondition = DataRestrictionCondition;
exports.reduerTest = reduerTest;
exports.dispatchTest = dispatchTest;
exports.reducer = reducer;
exports.isPasswordFilled = isPasswordFilled;
exports.isPasswordValid = isPasswordValid;
exports.isAlphanumericPassword = isAlphanumericPassword;
exports.isUserNameInPassword = isUserNameInPassword;
exports.areIndpendentFieldsValid = areIndpendentFieldsValid;
exports.arePasswordsFilled = arePasswordsFilled;
exports.arePasswordsExactMatch = arePasswordsExactMatch;
exports.areDepedentFieldsValid = areDepedentFieldsValid;
exports.hasEmail = hasEmail;
exports.hasFirstName = hasFirstName;
exports.hasLastName = hasLastName;
exports.hasNewPassword = hasNewPassword;
exports.hasNewPasswordConfirmation = hasNewPasswordConfirmation;
exports.hasSomeRoleEnabled = hasSomeRoleEnabled;
exports.hasMandatoryParams = hasMandatoryParams;
exports.isFormValid = isFormValid;
exports.emptyUser = emptyUser;
exports.emptyPasswordConfirmation = emptyPasswordConfirmation;
exports.fetchRoles = fetchRoles;
exports.fetchDataFields = fetchDataFields;
exports.buildUserDict = buildUserDict;
exports.createUser = createUser;
exports.updateUser = updateUser;
exports.checkEmailAPI = checkEmailAPI;
exports.checkUsernameAPI = checkUsernameAPI;
exports.checkEmail = checkEmail;
exports.checkUsername = checkUsername;
exports.editForm = editForm;
exports.make = make;
/* emptyUser Not a pure module */
