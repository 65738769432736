import React, { Component } from "react";
import proj4 from "./proj4.js";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import drilldown from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
import HighchartsHeatMap from "highcharts/modules/heatmap";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

drilldown(Highcharts);
dataModule(Highcharts);
HighchartsHeatMap(Highcharts);

function getPointCategoryName(point, dimension) {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
}

class HeatmapBinding extends Component {
  render() {
    return React.createElement(
      "div",
      {
        className: "h-full w-full p-20-px",
      },
      React.createElement(HighchartsReact, {
        highcharts: Highcharts,
        options: {
          title: {
            text: "",
          },
          chart: {
            type: "heatmap",
            height: this.props.mapHeight,
          },
          exporting: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          legend: {
            layout: "vertical",
            align: "left",
            verticalAlign: "middle",
          },
          xAxis: {
            categories: this.props.categories,
          },
          yAxis: {
            categories: this.props.categories,
            title: null,
            reversed: true,
          },
          colorAxis: {
            min: 0,
            minColor: "#FFFFFF",
            maxColor: "#39d353",
          },
          tooltip: {
            formatter: function () {
              return (
                getPointCategoryName(this.point, "x") +
                " x " +
                getPointCategoryName(this.point, "y") +
                "<br><b>Correlation Coefficient</b>:" +
                this.point.value
              );
            },
          },
          series: [
            {
              name: "Correlation Matrix",
              borderWidth: 1,
              data: this.props.data,
              dataLabels: {
                enabled: true,
                color: "#000000",
              },
            },
          ],
        },
      })
    );
  }
}

export default HeatmapBinding;
export { HeatmapBinding };
