// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TicketComment$ReactHooksTemplate = require("./ticketComment.bs.js");

function TicketCommentView(Props) {
  var activity = Props.activity;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between text-darkcharcoal"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, "Comment Added"), React.createElement("div", {
                      className: "text-xs font-normal"
                    }, TicketComment$ReactHooksTemplate.user_email(activity))), React.createElement("div", {
                  className: "mt-2 text-xs font-normal"
                }, TicketComment$ReactHooksTemplate.comment(activity)));
}

var make = TicketCommentView;

exports.make = make;
/* react Not a pure module */
