// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");

function make(text) {
  return text;
}

function toString(t) {
  return Belt_Option.getWithDefault(t, "");
}

function decode(json) {
  return Json_decode.field("text", (function (param) {
                return Json_decode.optional(Json_decode.string, param);
              }), json);
}

function encode(t) {
  return Json_encode.object_(Utils$ReactHooksTemplate.encodeOptionalParamToList("text", t, (function (prim) {
                    return prim;
                  })));
}

exports.make = make;
exports.toString = toString;
exports.decode = decode;
exports.encode = encode;
/* Utils-ReactHooksTemplate Not a pure module */
