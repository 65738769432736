// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function MandatoryMessage(Props) {
  var match = Props.language;
  var language = match !== undefined ? match : SurveyLanguage$ReactHooksTemplate.$$default;
  return React.createElement("p", {
              className: " w-full pt-6 small:pt-3 text-lg  opacity-50"
            }, "*", React.createElement("span", {
                  className: "text-xs"
                }, SurveyLanguage$ReactHooksTemplate.message(language, "mandatory")));
}

var make = MandatoryMessage;

exports.make = make;
/* react Not a pure module */
