// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");

function DropDownEditor(Props) {
  var dropDown = Props.dropDown;
  var onDropDownChange = Props.onDropDownChange;
  return React.createElement("div", undefined, React.createElement("button", {
                  className: "bg-ghostwhite border border-gray-400\n          flex rounded p-2 text-sm mt-0",
                  onClick: (function (param) {
                      return Curry._1(onDropDownChange, DropDown$ReactHooksTemplate.setChoice(DropDown$ReactHooksTemplate.makeChoice("", false, undefined), dropDown));
                    })
                }, React.createElement("div", {
                      className: "flex items-center justify-between gap-1 text-darkcharcoal"
                    }, React.createElement("img", {
                          src: Image$ReactHooksTemplate.plusCircle
                        }), React.createElement("p", undefined, "Add choice"))), React.createElement("div", {
                  className: "mb-7 mt-4"
                }, $$Array.of_list(List.mapi((function (i, choice) {
                            return React.createElement("div", {
                                        key: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice)),
                                        className: "mb-4"
                                      }, React.createElement("div", {
                                            className: "flex justify-between mb-2"
                                          }, React.createElement("div", {
                                                className: "text-darkcharcoal text-sm mb-2 font-medium"
                                              }, "Choice " + String(i + 1 | 0)), React.createElement("img", {
                                                className: "h-12-px",
                                                src: Image$ReactHooksTemplate.trash,
                                                onClick: (function (param) {
                                                    return Curry._1(onDropDownChange, DropDown$ReactHooksTemplate.removeChoice(choice, DropDown$ReactHooksTemplate.choices(dropDown), dropDown));
                                                  })
                                              })), React.createElement("input", {
                                            className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                                            placeholder: "Enter Choice " + String(i + 1 | 0),
                                            type: "text",
                                            value: DropDown$ReactHooksTemplate.getChoiceText(choice),
                                            onChange: (function ($$event) {
                                                var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                return Curry._1(onDropDownChange, DropDown$ReactHooksTemplate.setChoice(DropDown$ReactHooksTemplate.setChoiceText(value, choice), dropDown));
                                              })
                                          }), React.createElement("div", {
                                            className: "text-darkcharcoal text-sm font-light mt-1"
                                          }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                checked: DropDown$ReactHooksTemplate.getAllowNonSpecifiedText(choice),
                                                label: "Enable non specified text",
                                                handleChange: (function (param) {
                                                    return Curry._1(onDropDownChange, DropDown$ReactHooksTemplate.setChoice(DropDown$ReactHooksTemplate.toggleAllowNonSpecifiedText(choice), dropDown));
                                                  }),
                                                checkboxClassName: "custom_checkbox"
                                              })));
                          }), DropDown$ReactHooksTemplate.choices(dropDown)))));
}

var make = DropDownEditor;

exports.make = make;
/* react Not a pure module */
