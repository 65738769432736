// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Vendor$ReactHooksTemplate = require("../common/vendor.bs.js");

Vendor$ReactHooksTemplate.make(undefined);

function decodeLogo(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("url", Json_decode.string, param);
              }), json);
}

var emptyState = /* record */[/* vendor */Vendor$ReactHooksTemplate.make(undefined)];

function SurveyPageNotFound(Props) {
  var match = React.useState((function () {
          return emptyState;
        }));
  var setState = match[1];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/clients/logo").then((function (json) {
                  return Promise.resolve(Curry._1(setState, (function (param) {
                                    return /* record */[/* vendor */Vendor$ReactHooksTemplate.make(decodeLogo(json))];
                                  })));
                }));
          return ;
        }), ([]));
  var match$1 = Vendor$ReactHooksTemplate.getLogo(match[0][/* vendor */0]);
  return React.createElement("div", {
              className: "flex flex-col items-center h-screen justify-between"
            }, React.createElement("div", undefined), React.createElement("div", {
                  className: "flex flex-col items-center"
                }, match$1 !== undefined ? React.createElement("img", {
                        className: "w-64",
                        src: match$1
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "text-center"
                    }, "You have either already completed the survey or your session has expired!")), React.createElement("div", undefined));
}

var make = SurveyPageNotFound;

exports.decodeLogo = decodeLogo;
exports.emptyState = emptyState;
exports.make = make;
/*  Not a pure module */
