// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function makeOptionItems(options, selectedOptions) {
  return List.map((function (x) {
                return /* record */[
                        /* name */x,
                        /* selected */List.exists((function (y) {
                                return y === x;
                              }), selectedOptions)
                      ];
              }), options);
}

function SelectBox(Props) {
  var options = Props.options;
  var placeholder = Props.placeholder;
  var handleChange = Props.handleChange;
  var match = Props.selectedOptions;
  var selectedOptions = match !== undefined ? match : /* [] */0;
  var match$1 = Props.className;
  var className = match$1 !== undefined ? match$1 : "";
  var optionItems = makeOptionItems(options, selectedOptions);
  var match$2 = React.useState((function () {
          return /* record */[/* open_ */false];
        }));
  var setState = match$2[1];
  var domElement = React.useRef(null);
  var handleOutsideClick = function ($$event) {
    var targetElement = $$event.target;
    var selectBoxElement = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    var match = !selectBoxElement.contains(targetElement);
    if (match) {
      return Curry._1(setState, (function (param) {
                    return /* record */[/* open_ */false];
                  }));
    } else {
      return /* () */0;
    }
  };
  React.useEffect((function () {
          document.addEventListener("mousedown", handleOutsideClick);
          return (function (param) {
                    document.removeEventListener("mousedown", handleOutsideClick);
                    return /* () */0;
                  });
        }), ([]));
  var handleClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    return Curry._1(setState, (function (state) {
                  return /* record */[/* open_ */!state[/* open_ */0]];
                }));
  };
  var removeOption = function (optionName) {
    return Belt_List.keep(selectedOptions, (function (x) {
                  return x !== optionName;
                }));
  };
  var addOption = function (optionName) {
    return Belt_List.concat(/* :: */[
                optionName,
                /* [] */0
              ], selectedOptions);
  };
  var match$3 = match$2[0][/* open_ */0];
  var listBoxCSS = match$3 ? "block" : "hidden";
  var tmp;
  var exit = 0;
  if (placeholder !== undefined) {
    if (selectedOptions) {
      exit = 1;
    } else {
      tmp = placeholder;
    }
  } else if (selectedOptions) {
    exit = 1;
  } else {
    tmp = "";
  }
  if (exit === 1) {
    tmp = List.fold_left((function (x, y) {
            return x + ("," + y);
          }), selectedOptions[0], selectedOptions[1]);
  }
  return React.createElement("div", {
              key: "key",
              ref: domElement,
              className: className
            }, React.createElement("div", {
                  className: "w-full"
                }, React.createElement("p", {
                      className: "border truncate px-2",
                      onClick: handleClick
                    }, tmp)), React.createElement("div", {
                  className: "flex flex-col border my-2 z-50 bg-white " + listBoxCSS,
                  style: {
                    height: "20%",
                    overflow: "scroll",
                    position: "absolute"
                  }
                }, $$Array.of_list(Belt_List.mapWithIndex(optionItems, (function (i, x) {
                            var match = x[/* selected */1];
                            return React.createElement("button", {
                                        key: String(i),
                                        className: match ? "bg-blue-200 px-5" : "hover:bg-gray-300 px-5",
                                        value: x[/* name */0],
                                        onClick: (function ($$event) {
                                            $$event.preventDefault();
                                            var optionItem = x;
                                            var match = optionItem[/* selected */1];
                                            return Curry._1(handleChange, (
                                                          match ? removeOption : addOption
                                                        )(optionItem[/* name */0]));
                                          })
                                      }, x[/* name */0]);
                          })))));
}

var make = SelectBox;

exports.makeOptionItems = makeOptionItems;
exports.make = make;
/* react Not a pure module */
