// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");

function empty(param) {
  return /* record */[
          /* subject */"",
          /* body */""
        ];
}

function setSubject(x, t) {
  return /* record */[
          /* subject */x,
          /* body */t[/* body */1]
        ];
}

function setBody(x, t) {
  return /* record */[
          /* subject */t[/* subject */0],
          /* body */x
        ];
}

function validSubject(x) {
  return x !== "";
}

function validBody(x) {
  return x !== "";
}

function toForm(t) {
  var match = t[/* subject */0] !== "";
  var match$1 = t[/* body */1] !== "";
  return /* record */[
          /* subject */match ? /* Valid */Block.__(0, [t[/* subject */0]]) : /* Invalid */Block.__(1, [
                t[/* subject */0],
                /* :: */[
                  "can't be empty",
                  /* [] */0
                ]
              ]),
          /* body */match$1 ? /* Valid */Block.__(0, [t[/* body */1]]) : /* Invalid */Block.__(1, [
                t[/* body */1],
                /* :: */[
                  "can't be empty",
                  /* [] */0
                ]
              ])
        ];
}

function fromForm(form) {
  return /* record */[
          /* subject */FormField$ReactHooksTemplate.getInputValue(form[/* subject */0]),
          /* body */FormField$ReactHooksTemplate.getInputValue(form[/* body */1])
        ];
}

function encoder(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "subject",
                t[/* subject */0]
              ],
              /* :: */[
                /* tuple */[
                  "body",
                  t[/* body */1]
                ],
                /* [] */0
              ]
            ]);
}

function decoder(json) {
  return /* record */[
          /* subject */Json_decode.field("subject", Json_decode.string, json),
          /* body */Json_decode.field("body", Json_decode.string, json)
        ];
}

var modeType = "Email";

exports.modeType = modeType;
exports.empty = empty;
exports.setSubject = setSubject;
exports.setBody = setBody;
exports.validSubject = validSubject;
exports.validBody = validBody;
exports.toForm = toForm;
exports.fromForm = fromForm;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */
