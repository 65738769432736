// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var MomentRe = require("bs-moment/src/MomentRe.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("./common/http.bs.js");
var Image$ReactHooksTemplate = require("./common/image.bs.js");
var Utils$ReactHooksTemplate = require("./common/utils.bs.js");
var Filters$ReactHooksTemplate = require("./dashboard/filters/filters.bs.js");
var Pagination$ReactHooksTemplate = require("./common/Pagination.bs.js");

var categoryList = /* :: */[
  "Agent Related::Lack Agent Etiquette",
  /* :: */[
    "Agent Related::Need To Provide Proper Information",
    /* :: */[
      "Agent Related::Should Speak Politely",
      /* :: */[
        "B1w Issue",
        /* :: */[
          "Balance Deduction",
          /* :: */[
            "Better Offers And Services",
            /* :: */[
              "Cgt Issue",
              /* :: */[
                "Comment Namilekaharu",
                /* :: */[
                  "Contact Center Related",
                  /* :: */[
                    "Contact Center Related::Direct Call Transfer To Operator",
                    /* :: */[
                      "Contact Center Related::Multilingual",
                      /* :: */[
                        "Contact Center Related::Quick Response",
                        /* :: */[
                          "Contact Center Related::Quick Solution",
                          /* :: */[
                            "Dissatisfied Customer",
                            /* :: */[
                              "Expand Network",
                              /* :: */[
                                "Free Offers And Services",
                                /* :: */[
                                  "Implement 4g Services",
                                  /* :: */[
                                    "Improve Network",
                                    /* :: */[
                                      "More Offers And Services",
                                      /* :: */[
                                        "N/A",
                                        /* :: */[
                                          "Ncell Center Related::Dissatisfied Customer",
                                          /* :: */[
                                            "Ncell Center Related::More Centers",
                                            /* :: */[
                                              "No Match Found",
                                              /* :: */[
                                                "Others",
                                                /* :: */[
                                                  "Others::Auto Activation Of Services",
                                                  /* :: */[
                                                    "Others::Drc Issue",
                                                    /* :: */[
                                                      "Others::Service Issue",
                                                      /* :: */[
                                                        "Others::Service Issues",
                                                        /* :: */[
                                                          "Others::Service Queries",
                                                          /* :: */[
                                                            "Others::Sim Recycle Issue",
                                                            /* :: */[
                                                              "Product Enhancement Suggestions",
                                                              /* :: */[
                                                                "Product Enhancement Suggestions::Data Transfer Service",
                                                                /* :: */[
                                                                  "Product Enhancement Suggestions::Instant Data Pack End Notification",
                                                                  /* :: */[
                                                                    "Product Enhancement Suggestions::More Offers For Postpaid",
                                                                    /* :: */[
                                                                      "Product Enhancement Suggestions::Postpaid To Prepaid Conversion",
                                                                      /* :: */[
                                                                        "Product Enhancement Suggestions::Provide Bonus",
                                                                        /* :: */[
                                                                          "Product Enhancement Suggestions::Revise Data Pack",
                                                                          /* :: */[
                                                                            "Product Enhancement Suggestions::Revise Loan Service",
                                                                            /* :: */[
                                                                              "Product Enhancement Suggestions::Revise Voice Pack",
                                                                              /* :: */[
                                                                                "Product Enhancement Suggestions::Service Deactivation Process",
                                                                                /* :: */[
                                                                                  "Reduce Tariff",
                                                                                  /* :: */[
                                                                                    "Retail Channel Related::Photo Copy Issue",
                                                                                    /* :: */[
                                                                                      "Retail Channel Related::Quick Sim Activation",
                                                                                      /* :: */[
                                                                                        "Satisfied Customer",
                                                                                        /* :: */[
                                                                                          "Tsc Issue",
                                                                                          /* :: */[
                                                                                            "Unlimited Offers And Services",
                                                                                            /* [] */0
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

function id(t) {
  return t[/* id */0];
}

function msisdn(t) {
  return t[/* msisdn */1];
}

function lob(t) {
  return t[/* lob */2];
}

function shopName(t) {
  return t[/* shopName */3];
}

function status(t) {
  return t[/* status */4];
}

function longTextResponse(t) {
  return t[/* longTextResponse */6];
}

function sentiment(t) {
  return t[/* sentiment */7];
}

function triggerDate(t) {
  return t[/* triggerDate */5];
}

function categories(t) {
  return t[/* categories */8];
}

function edited(t) {
  return t[/* edited */9];
}

function lastEditedAt(t) {
  return t[/* lastEditedAt */10];
}

function lastEditedBy(t) {
  return t[/* lastEditedBy */11];
}

function setCategories(categories, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* msisdn */t[/* msisdn */1],
          /* lob */t[/* lob */2],
          /* shopName */t[/* shopName */3],
          /* status */t[/* status */4],
          /* triggerDate */t[/* triggerDate */5],
          /* longTextResponse */t[/* longTextResponse */6],
          /* sentiment */t[/* sentiment */7],
          /* categories */categories,
          /* edited */t[/* edited */9],
          /* lastEditedAt */t[/* lastEditedAt */10],
          /* lastEditedBy */t[/* lastEditedBy */11]
        ];
}

function setEdited(edited, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* msisdn */t[/* msisdn */1],
          /* lob */t[/* lob */2],
          /* shopName */t[/* shopName */3],
          /* status */t[/* status */4],
          /* triggerDate */t[/* triggerDate */5],
          /* longTextResponse */t[/* longTextResponse */6],
          /* sentiment */t[/* sentiment */7],
          /* categories */t[/* categories */8],
          /* edited */edited,
          /* lastEditedAt */t[/* lastEditedAt */10],
          /* lastEditedBy */t[/* lastEditedBy */11]
        ];
}

function setSentiment(sentiment, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* msisdn */t[/* msisdn */1],
          /* lob */t[/* lob */2],
          /* shopName */t[/* shopName */3],
          /* status */t[/* status */4],
          /* triggerDate */t[/* triggerDate */5],
          /* longTextResponse */t[/* longTextResponse */6],
          /* sentiment */sentiment,
          /* categories */t[/* categories */8],
          /* edited */t[/* edited */9],
          /* lastEditedAt */t[/* lastEditedAt */10],
          /* lastEditedBy */t[/* lastEditedBy */11]
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("response_id", Json_decode.$$int, json),
          /* msisdn */Json_decode.optional((function (param) {
                  return Json_decode.field("msisdn", Json_decode.string, param);
                }), json),
          /* lob */Json_decode.optional((function (param) {
                  return Json_decode.field("lob", Json_decode.string, param);
                }), json),
          /* shopName */Json_decode.optional((function (param) {
                  return Json_decode.field("shop_name", Json_decode.string, param);
                }), json),
          /* status */Json_decode.field("status", Json_decode.string, json),
          /* triggerDate */(function (eta) {
                return MomentRe.moment(undefined, eta);
              })(Json_decode.field("trigger_date", Json_decode.string, json)),
          /* longTextResponse */Json_decode.field("long_text_response", Json_decode.string, json),
          /* sentiment */Json_decode.field("sentiment", Json_decode.string, json),
          /* categories */Json_decode.field("categories", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          /* edited */Json_decode.field("edited", Json_decode.bool, json),
          /* lastEditedAt */Belt_Option.map(Json_decode.field("last_edited_info", (function (param) {
                      return Json_decode.optional((function (json) {
                                    return Json_decode.field("updated_at", Json_decode.string, json);
                                  }), param);
                    }), json), (function (eta) {
                  return MomentRe.moment(undefined, eta);
                })),
          /* lastEditedBy */Json_decode.field("last_edited_info", (function (param) {
                  return Json_decode.optional((function (json) {
                                return Json_decode.field("editor", (function (json) {
                                              return Json_decode.field("email", Json_decode.string, json);
                                            }), json);
                              }), param);
                }), json)
        ];
}

var CategoryReport = {
  id: id,
  msisdn: msisdn,
  lob: lob,
  shopName: shopName,
  status: status,
  longTextResponse: longTextResponse,
  sentiment: sentiment,
  triggerDate: triggerDate,
  categories: categories,
  edited: edited,
  lastEditedAt: lastEditedAt,
  lastEditedBy: lastEditedBy,
  setCategories: setCategories,
  setEdited: setEdited,
  setSentiment: setSentiment,
  fromJson: fromJson
};

var initialState = /* record */[
  /* loading */true,
  /* filter */false,
  /* editCategory */undefined,
  /* categoryReport : [] */0,
  /* total */1,
  /* perPage */1
];

function CategoryEdit(Props) {
  var params = Props.params;
  var match = React.useState((function () {
          return initialState;
        }));
  var setState = match[1];
  var state = match[0];
  var queryParams = Querystringify.parse(params);
  var match$1 = Js_dict.get(queryParams, "page");
  var currentPage;
  if (match$1 !== undefined) {
    var x = match$1;
    if (x === "") {
      currentPage = 1;
    } else {
      var page = Belt_Int.fromString(x);
      currentPage = page !== undefined ? page : 1;
    }
  } else {
    currentPage = 1;
  }
  var match$2 = Js_dict.get(queryParams, "sortedCol");
  var sortedColumn;
  if (match$2 !== undefined) {
    var x$1 = match$2;
    sortedColumn = x$1 === "" ? undefined : x$1;
  } else {
    sortedColumn = undefined;
  }
  var match$3 = Js_dict.get(queryParams, "sortOrder");
  var sortingOrder;
  if (match$3 !== undefined) {
    var x$2 = match$3;
    sortingOrder = x$2 === "" ? undefined : x$2;
  } else {
    sortingOrder = undefined;
  }
  var initialSortingInfo = /* record */[
    /* sortedColumn */sortedColumn,
    /* sortingOrder */sortingOrder
  ];
  var match$4 = React.useState((function () {
          return initialSortingInfo;
        }));
  var setSortingInfo = match$4[1];
  var sortingInfo = match$4[0];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setOpenSortModal = match$5[1];
  var fetchCategoryReport = function (pageNo) {
    var match = sortingInfo[/* sortedColumn */0];
    var match$1 = sortingInfo[/* sortingOrder */1];
    var url = match !== undefined ? (
        match$1 !== undefined ? "/api/v1/reports/text_category_report?page=" + (String(pageNo) + ("&sort_column=" + (match + ("&sort_order=" + match$1)))) : "/api/v1/reports/text_category_report?page=" + String(pageNo)
      ) : "/api/v1/reports/text_category_report?page=" + String(pageNo);
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then((function (param) {
                  var headers = param[0];
                  var total = Caml_format.caml_int_of_string(Belt_Option.getWithDefault(Caml_option.null_to_opt(headers.get("Total")), "1"));
                  var perPage = Caml_format.caml_int_of_string(Belt_Option.getWithDefault(Caml_option.null_to_opt(headers.get("Per-Page")), "20"));
                  var report = param[1].then((function (json) {
                          return Promise.resolve(Json_decode.field("report", (function (param) {
                                            return Json_decode.list(fromJson, param);
                                          }), json));
                        }));
                  return Promise.resolve(/* tuple */[
                              report,
                              /* tuple */[
                                total,
                                perPage
                              ]
                            ]);
                }));
  };
  var applySort = function (param) {
    var sortedCol = sortingInfo[/* sortedColumn */0];
    var sortOrder = sortingInfo[/* sortingOrder */1];
    ReasonReactRouter.push("/category_edit?page=1&sortedCol=" + (String(sortedCol) + ("&sortOrder=" + (String(sortOrder) + ""))));
    Curry._1(setState, (function (state) {
            return /* record */[
                    /* loading */true,
                    /* filter */false,
                    /* editCategory */state[/* editCategory */2],
                    /* categoryReport */state[/* categoryReport */3],
                    /* total */state[/* total */4],
                    /* perPage */state[/* perPage */5]
                  ];
          }));
    fetchCategoryReport(1).then((function (param) {
            var match = param[1];
            var perPage = match[1];
            var total = match[0];
            return Promise.resolve(param[0].then((function (categoryReport) {
                              return Promise.resolve(Curry._1(setState, (function (state) {
                                                return /* record */[
                                                        /* loading */false,
                                                        /* filter */state[/* filter */1],
                                                        /* editCategory */state[/* editCategory */2],
                                                        /* categoryReport */categoryReport,
                                                        /* total */total,
                                                        /* perPage */perPage
                                                      ];
                                              })));
                            })));
          }));
    return /* () */0;
  };
  var handlePageChange = function (pageNumber) {
    return ReasonReactRouter.push("/category_edit?page=" + String(pageNumber));
  };
  React.useEffect((function () {
          fetchCategoryReport(currentPage).then((function (param) {
                  var match = param[1];
                  var perPage = match[1];
                  var total = match[0];
                  return Promise.resolve(param[0].then((function (categoryReport) {
                                    return Promise.resolve(Curry._1(setState, (function (state) {
                                                      return /* record */[
                                                              /* loading */false,
                                                              /* filter */state[/* filter */1],
                                                              /* editCategory */state[/* editCategory */2],
                                                              /* categoryReport */categoryReport,
                                                              /* total */total,
                                                              /* perPage */perPage
                                                            ];
                                                    })));
                                  })));
                }));
          return ;
        }), /* array */[currentPage]);
  var onApplyFilter = function (param) {
    var match = currentPage > 1;
    if (match) {
      return ReasonReactRouter.push("/category_edit");
    } else {
      Curry._1(setState, (function (state) {
              return /* record */[
                      /* loading */true,
                      /* filter */false,
                      /* editCategory */state[/* editCategory */2],
                      /* categoryReport */state[/* categoryReport */3],
                      /* total */state[/* total */4],
                      /* perPage */state[/* perPage */5]
                    ];
            }));
      fetchCategoryReport(1).then((function (param) {
              var match = param[1];
              var perPage = match[1];
              var total = match[0];
              return Promise.resolve(param[0].then((function (categoryReport) {
                                return Promise.resolve(Curry._1(setState, (function (state) {
                                                  return /* record */[
                                                          /* loading */false,
                                                          /* filter */state[/* filter */1],
                                                          /* editCategory */state[/* editCategory */2],
                                                          /* categoryReport */categoryReport,
                                                          /* total */total,
                                                          /* perPage */perPage
                                                        ];
                                                })));
                              })));
            }));
      return /* () */0;
    }
  };
  var match$6 = state[/* loading */0];
  if (match$6) {
    return React.createElement("p", undefined, "Loading...");
  } else {
    var match$7 = state[/* filter */1];
    var tmp;
    if (match$5[0]) {
      var match$8 = Belt_Option.getWithDefault(sortingInfo[/* sortedColumn */0], "") !== "trigger_date" || Belt_Option.getWithDefault(sortingInfo[/* sortingOrder */1], "") === "desc";
      var match$9 = Belt_Option.getWithDefault(sortingInfo[/* sortedColumn */0], "") === "trigger_date" && Belt_Option.getWithDefault(sortingInfo[/* sortingOrder */1], "") === "asc";
      tmp = React.createElement("div", {
            className: "flex border border-gray-400 rounded-lg shadow-md flex-col justify-start\n                          items-start gap-2 p-4 z-10 absolute bg-white"
          }, React.createElement("div", {
                className: "text-sm font-light text-aurometalsaurus"
              }, "Sort"), React.createElement("button", {
                className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                  match$8 ? "bg-lavenderweb border border-lavenderweb" : ""
                ),
                onClick: (function (param) {
                    return Curry._1(setSortingInfo, (function (param) {
                                  return /* record */[
                                          /* sortedColumn */"trigger_date",
                                          /* sortingOrder */"desc"
                                        ];
                                }));
                  })
              }, "Sort by latest first"), React.createElement("button", {
                className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                  match$9 ? "bg-lavenderweb border border-lavenderweb" : ""
                ),
                onClick: (function (param) {
                    return Curry._1(setSortingInfo, (function (param) {
                                  return /* record */[
                                          /* sortedColumn */"trigger_date",
                                          /* sortingOrder */"asc"
                                        ];
                                }));
                  })
              }, "Sort by oldest first"), React.createElement("hr", {
                className: "border border-gray-200 w-full"
              }), React.createElement("div", {
                className: "flex justify-between w-full font-semibold text-sm gap-2"
              }, React.createElement("button", {
                    className: "py-2 w-1/2 border border-gray-300 text-aurometalsaurus rounded",
                    onClick: (function (param) {
                        return Curry._1(setOpenSortModal, (function (param) {
                                      return false;
                                    }));
                      })
                  }, "Cancel"), React.createElement("button", {
                    className: "py-2 w-1/2 bg-pigmentblue text-white rounded",
                    onClick: (function (param) {
                        applySort(/* () */0);
                        return Curry._1(setOpenSortModal, (function (param) {
                                      return false;
                                    }));
                      })
                  }, "Apply")));
    } else {
      tmp = React.createElement(React.Fragment, undefined);
    }
    return React.createElement(React.Fragment, undefined, match$7 ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return Curry._1(setState, (function (state) {
                                        return /* record */[
                                                /* loading */state[/* loading */0],
                                                /* filter */false,
                                                /* editCategory */state[/* editCategory */2],
                                                /* categoryReport */state[/* categoryReport */3],
                                                /* total */state[/* total */4],
                                                /* perPage */state[/* perPage */5]
                                              ];
                                      }));
                        }),
                      onApply: onApplyFilter,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Category Report"), React.createElement("button", {
                            className: "bg-ghostwhite border border-gray-400\n              shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "filters",
                            onClick: (function (param) {
                                return Curry._1(setState, (function (state) {
                                              return /* record */[
                                                      /* loading */state[/* loading */0],
                                                      /* filter */true,
                                                      /* editCategory */state[/* editCategory */2],
                                                      /* categoryReport */state[/* categoryReport */3],
                                                      /* total */state[/* total */4],
                                                      /* perPage */state[/* perPage */5]
                                                    ];
                                            }));
                              })
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.filterButton
                              }), "Filters")), React.createElement("div", {
                        className: "flex items-center px-30-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex cursor-pointer",
                                            onClick: (function (param) {
                                                return Curry._1(setOpenSortModal, (function (param) {
                                                              return true;
                                                            }));
                                              })
                                          }, "Trigger Date", React.createElement("img", {
                                                className: "ml-4-px sort-img",
                                                src: Image$ReactHooksTemplate.heading
                                              })), tmp), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "MSISDN", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "LOB", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "SHOP NAME", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "OPEN COMMENT", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "LAST EDITED AT", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "LAST EDITED BY", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "SENTIMENT", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "CATEGORIES", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          })))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                          var match = x[/* msisdn */1];
                                          var match$1 = x[/* lob */2];
                                          var match$2 = x[/* shopName */3];
                                          var match$3 = state[/* editCategory */2];
                                          var tmp;
                                          if (match$3 !== undefined) {
                                            var match$4 = match$3[/* id */0] === x[/* id */0];
                                            if (match$4) {
                                              var match$5 = x[/* sentiment */7] === "-";
                                              tmp = React.createElement("select", {
                                                    className: "border\n                                      border-gray-400 shade w-full",
                                                    placeholder: "Select Sentiment",
                                                    value: match$5 ? "Select Sentiment" : x[/* sentiment */7],
                                                    onChange: (function (param) {
                                                        var categoryReport = x;
                                                        var $$event = param;
                                                        var sentiment = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                        var newCategoryReport = setSentiment(sentiment, categoryReport);
                                                        var newCategoryReportList = List.map((function (x) {
                                                                var match = x[/* id */0] === categoryReport[/* id */0];
                                                                if (match) {
                                                                  return newCategoryReport;
                                                                } else {
                                                                  return x;
                                                                }
                                                              }), state[/* categoryReport */3]);
                                                        return Curry._1(setState, (function (state) {
                                                                      return /* record */[
                                                                              /* loading */state[/* loading */0],
                                                                              /* filter */state[/* filter */1],
                                                                              /* editCategory */state[/* editCategory */2],
                                                                              /* categoryReport */newCategoryReportList,
                                                                              /* total */state[/* total */4],
                                                                              /* perPage */state[/* perPage */5]
                                                                            ];
                                                                    }));
                                                      })
                                                  }, React.createElement("option", {
                                                        key: "0",
                                                        disabled: true,
                                                        value: "Select Sentiment"
                                                      }, "Select Sentiment"), React.createElement("option", {
                                                        key: "1",
                                                        selected: "positive" === x[/* sentiment */7],
                                                        value: "positive"
                                                      }, "Positive"), React.createElement("option", {
                                                        key: "2",
                                                        selected: "Negative" === x[/* sentiment */7],
                                                        value: "negative"
                                                      }, "Negative"), React.createElement("option", {
                                                        key: "3",
                                                        selected: "neutral" === x[/* sentiment */7],
                                                        value: "neutral"
                                                      }, "Neutral"), React.createElement("option", {
                                                        key: "4",
                                                        selected: "na" === x[/* sentiment */7],
                                                        value: "na"
                                                      }, "NA"));
                                            } else {
                                              tmp = React.createElement("p", undefined, x[/* sentiment */7]);
                                            }
                                          } else {
                                            tmp = React.createElement("p", {
                                                  className: "capitalize"
                                                }, x[/* sentiment */7]);
                                          }
                                          var match$6 = state[/* editCategory */2];
                                          var tmp$1;
                                          if (match$6 !== undefined) {
                                            var match$7 = match$6[/* id */0] === x[/* id */0];
                                            tmp$1 = match$7 ? React.createElement("div", {
                                                    className: "flex flex-col"
                                                  }, React.createElement("button", {
                                                        className: "bg-teal-500 hover:bg-teal-700\n                                    py-6-px px-20-px font-normal text-white\n                                    font-16-px leading-snug mb-10-px",
                                                        style: {
                                                          outline: "None"
                                                        },
                                                        onClick: (function (param) {
                                                            var categoryReport = x;
                                                            var params = { };
                                                            params["id"] = String(categoryReport[/* id */0]);
                                                            if (categoryReport[/* sentiment */7] !== "-") {
                                                              params["sentiment"] = categoryReport[/* sentiment */7];
                                                            }
                                                            params["categories"] = Json_encode.list((function (prim) {
                                                                    return prim;
                                                                  }), List.filter((function (x) {
                                                                          return x !== "-";
                                                                        }))(categoryReport[/* categories */8]));
                                                            Http$ReactHooksTemplate.fetchPostJSON("/api/v1/long_text_responses/update_categories", params).then((function (param) {
                                                                    var newCategoryReport = setEdited(true, categoryReport);
                                                                    var newCategoryReportList = List.map((function (x) {
                                                                            var match = x[/* id */0] === categoryReport[/* id */0];
                                                                            if (match) {
                                                                              return newCategoryReport;
                                                                            } else {
                                                                              return x;
                                                                            }
                                                                          }), state[/* categoryReport */3]);
                                                                    return Promise.resolve(Curry._1(setState, (function (state) {
                                                                                      return /* record */[
                                                                                              /* loading */state[/* loading */0],
                                                                                              /* filter */state[/* filter */1],
                                                                                              /* editCategory */undefined,
                                                                                              /* categoryReport */newCategoryReportList,
                                                                                              /* total */state[/* total */4],
                                                                                              /* perPage */state[/* perPage */5]
                                                                                            ];
                                                                                    })));
                                                                  }));
                                                            return /* () */0;
                                                          })
                                                      }, "Update"), React.createElement("button", {
                                                        className: "hover:text-gray-500 py-6-px\n                                  px-10-px border border-gray-400\n                                  hover:border-gray-300 mr-20-px",
                                                        style: {
                                                          outline: "None",
                                                          outlineStyle: "unset"
                                                        },
                                                        onClick: (function (param) {
                                                            return Curry._1(setState, (function (state) {
                                                                          return /* record */[
                                                                                  /* loading */state[/* loading */0],
                                                                                  /* filter */state[/* filter */1],
                                                                                  /* editCategory */undefined,
                                                                                  /* categoryReport */state[/* categoryReport */3],
                                                                                  /* total */state[/* total */4],
                                                                                  /* perPage */state[/* perPage */5]
                                                                                ];
                                                                        }));
                                                          })
                                                      }, "cancel")) : React.createElement("div", {
                                                    className: "flex items-center"
                                                  }, React.createElement("button", {
                                                        className: "hover:text-teal-500 py-6-px\n                                  px-10-px border border-gray-400\n                                  hover:border-teal-300 mr-20-px",
                                                        style: {
                                                          outline: "None",
                                                          outlineStyle: "unset"
                                                        },
                                                        onClick: (function (param) {
                                                            return Curry._1(setState, (function (state) {
                                                                          return /* record */[
                                                                                  /* loading */state[/* loading */0],
                                                                                  /* filter */state[/* filter */1],
                                                                                  /* editCategory */x,
                                                                                  /* categoryReport */state[/* categoryReport */3],
                                                                                  /* total */state[/* total */4],
                                                                                  /* perPage */state[/* perPage */5]
                                                                                ];
                                                                        }));
                                                          })
                                                      }, "Edit"));
                                          } else {
                                            tmp$1 = React.createElement("div", {
                                                  className: "flex items-center"
                                                }, React.createElement("button", {
                                                      className: "hover:text-teal-500 py-6-px px-10-px\n                              border border-gray-400 hover:border-teal-300\n                              mr-20-px",
                                                      style: {
                                                        outline: "None",
                                                        outlineStyle: "unset"
                                                      },
                                                      onClick: (function (param) {
                                                          return Curry._1(setState, (function (state) {
                                                                        return /* record */[
                                                                                /* loading */state[/* loading */0],
                                                                                /* filter */state[/* filter */1],
                                                                                /* editCategory */x,
                                                                                /* categoryReport */state[/* categoryReport */3],
                                                                                /* total */state[/* total */4],
                                                                                /* perPage */state[/* perPage */5]
                                                                              ];
                                                                      }));
                                                        })
                                                    }, "Edit"));
                                          }
                                          return React.createElement("tr", {
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, x[/* triggerDate */5].format("YYYY/MM/DD HH:MM:SS")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match !== undefined ? match : "-"), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match$1 !== undefined ? match$1 : "-"), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, match$2 !== undefined ? match$2 : "-"), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, x[/* longTextResponse */6]), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.mapWithDefault(x[/* lastEditedAt */10], "-", (function (y) {
                                                                return y.format("YYYY/MM/DD HH:MM:SS");
                                                              }))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.getWithDefault(x[/* lastEditedBy */11], "-")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "w-full"
                                                            }, tmp)), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, $$Array.of_list(Belt_List.mapWithIndex(x[/* categories */8], (function (idx, y) {
                                                                    var match = state[/* editCategory */2];
                                                                    var tmp;
                                                                    if (match !== undefined) {
                                                                      var match$1 = match[/* id */0] === x[/* id */0];
                                                                      if (match$1) {
                                                                        var match$2 = y === "-";
                                                                        tmp = React.createElement("select", {
                                                                              className: "border\n                                      border-gray-400\n                                      shade w-5/6",
                                                                              placeholder: "Select Category",
                                                                              value: match$2 ? "Select Category" : y,
                                                                              onChange: (function (param) {
                                                                                  var categoryReport = x;
                                                                                  var categoryId = idx;
                                                                                  var $$event = param;
                                                                                  var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                                                  var newCategories = Belt_List.mapWithIndex(categoryReport[/* categories */8], (function (idx, x) {
                                                                                          var match = idx === categoryId;
                                                                                          if (match) {
                                                                                            return value;
                                                                                          } else {
                                                                                            return x;
                                                                                          }
                                                                                        }));
                                                                                  var newCategoryReport = setCategories(newCategories, categoryReport);
                                                                                  var newCategoryReportList = List.map((function (x) {
                                                                                          var match = x[/* id */0] === categoryReport[/* id */0];
                                                                                          if (match) {
                                                                                            return newCategoryReport;
                                                                                          } else {
                                                                                            return x;
                                                                                          }
                                                                                        }), state[/* categoryReport */3]);
                                                                                  return Curry._1(setState, (function (state) {
                                                                                                return /* record */[
                                                                                                        /* loading */state[/* loading */0],
                                                                                                        /* filter */state[/* filter */1],
                                                                                                        /* editCategory */state[/* editCategory */2],
                                                                                                        /* categoryReport */newCategoryReportList,
                                                                                                        /* total */state[/* total */4],
                                                                                                        /* perPage */state[/* perPage */5]
                                                                                                      ];
                                                                                              }));
                                                                                })
                                                                            }, React.createElement("option", {
                                                                                  key: "0",
                                                                                  disabled: true,
                                                                                  value: "Select Category"
                                                                                }, "Select Category"), $$Array.of_list(Belt_List.mapWithIndex(categoryList, (function (id, category) {
                                                                                        return React.createElement("option", {
                                                                                                    key: String(id + 1 | 0),
                                                                                                    selected: category === y,
                                                                                                    value: category
                                                                                                  }, category);
                                                                                      }))));
                                                                      } else {
                                                                        tmp = React.createElement("p", {
                                                                              className: "w-5/6"
                                                                            }, y);
                                                                      }
                                                                    } else {
                                                                      tmp = React.createElement("p", undefined, y);
                                                                    }
                                                                    return React.createElement("div", {
                                                                                className: "flex mb-10-px items-center w-full"
                                                                              }, React.createElement("p", {
                                                                                    className: "font-medium"
                                                                                  }, String(idx + 1 | 0) + " : "), tmp);
                                                                  })))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "flex w-full justify-center"
                                                            }, tmp$1)));
                                        }), state[/* categoryReport */3])))))), React.createElement("div", {
                    className: "flex justify-end"
                  }, React.createElement(Pagination$ReactHooksTemplate.make, {
                        total: Caml_int32.div(state[/* total */4] - 1 | 0, state[/* perPage */5]) + 1 | 0,
                        currentPage: currentPage,
                        handlePageChange: handlePageChange
                      })));
  }
}

var make = CategoryEdit;

exports.categoryList = categoryList;
exports.CategoryReport = CategoryReport;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */
