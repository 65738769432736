// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var RatingSmileySet$ReactHooksTemplate = require("./ratingSmileySet.bs.js");

function SmileyRatingEditor(Props) {
  var smileyRating = Props.smileyRating;
  var onSmileyRatingChange = Props.onSmileyRatingChange;
  React.useEffect((function () {
          var match = SmileyRating$ReactHooksTemplate.ratingSmileySet(smileyRating);
          if (match === undefined) {
            Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/rating_smiley_sets/default", (function (json) {
                      return Json_decode.field("rating_smiley_set", RatingSmileySet$ReactHooksTemplate.fromJson, json);
                    })).then((function (ratingSmileySet) {
                    return Promise.resolve(Curry._1(onSmileyRatingChange, SmileyRating$ReactHooksTemplate.setRatingSmileySet(smileyRating, ratingSmileySet)));
                  }));
          }
          return ;
        }), /* array */[smileyRating]);
  return React.createElement("div", undefined);
}

var make = SmileyRatingEditor;

exports.make = make;
/* react Not a pure module */
