// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");

function comparatorToString(param) {
  switch (param) {
    case /* Empty */0 :
        return "None";
    case /* EqualsTo */1 :
        return "EqualsTo";
    case /* LessThanEqualTo */2 :
        return "LessThanEqualTo";
    case /* GreaterThanEqualTo */3 :
        return "GreaterThanEqualTo";
    
  }
}

function comparatorToDisplay(param) {
  switch (param) {
    case /* Empty */0 :
        return "";
    case /* EqualsTo */1 :
        return "Equals To";
    case /* LessThanEqualTo */2 :
        return "Less than equal to";
    case /* GreaterThanEqualTo */3 :
        return "Greater than equal to";
    
  }
}

function comparatorTypeFromId(param) {
  if (param > 2 || param < 0) {
    return /* Empty */0;
  } else {
    return param + 1 | 0;
  }
}

function id(t) {
  return t[/* id */0];
}

function question(t) {
  return t[/* question */1];
}

function formulae(t) {
  return t[/* formulae */2];
}

function jumpTo(t) {
  return t[/* jumpTo */3];
}

function defaultJumpTo(t) {
  return t[/* defaultJumpTo */4];
}

function setQuestion(question, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* question */question,
          /* formulae */t[/* formulae */2],
          /* jumpTo */t[/* jumpTo */3],
          /* defaultJumpTo */t[/* defaultJumpTo */4]
        ];
}

function setFormulae(formulae, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* question */t[/* question */1],
          /* formulae */formulae,
          /* jumpTo */t[/* jumpTo */3],
          /* defaultJumpTo */t[/* defaultJumpTo */4]
        ];
}

function setJumpTo(jumpTo, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* question */t[/* question */1],
          /* formulae */t[/* formulae */2],
          /* jumpTo */jumpTo,
          /* defaultJumpTo */t[/* defaultJumpTo */4]
        ];
}

function makeFormula(comparator, compareValue) {
  return /* record */[
          /* id */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* comparator */comparator,
          /* compareValue */compareValue
        ];
}

function makeComparator(comparatorType) {
  return /* record */[
          /* id */comparatorType - 1 | 0,
          /* comparatorType */comparatorType,
          /* displayName */comparatorToDisplay(comparatorType)
        ];
}

function getFormulaId(formula) {
  return formula[/* id */0];
}

function getComparatorFromFormula(formula) {
  return formula[/* comparator */1];
}

function getCompareValueFromFormula(formula) {
  return formula[/* compareValue */2];
}

function setComparatorToFormula(comparator, formula) {
  return /* record */[
          /* id */formula[/* id */0],
          /* comparator */comparator,
          /* compareValue */formula[/* compareValue */2]
        ];
}

function setCompareValueToFormula(compareValue, formula) {
  return /* record */[
          /* id */formula[/* id */0],
          /* comparator */formula[/* comparator */1],
          /* compareValue */compareValue
        ];
}

function setDefaultJumpTo(defaultJumpTo, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* question */t[/* question */1],
          /* formulae */t[/* formulae */2],
          /* jumpTo */t[/* jumpTo */3],
          /* defaultJumpTo */defaultJumpTo
        ];
}

function getComparatorId(comparator) {
  return comparator[/* id */0];
}

function getComparatorDisplayName(comparator) {
  return comparator[/* displayName */2];
}

function makeGroup(param) {
  return /* [] */0;
}

function addToGroup(t, group) {
  return /* :: */[
          t,
          group
        ];
}

function deleteFromGroup(t, group) {
  return Belt_List.keep(group, (function (x) {
                return Caml_obj.caml_notequal(x[/* id */0], t[/* id */0]);
              }));
}

function replaceInGroup(t, group) {
  return Belt_List.map(group, (function (x) {
                var match = Caml_obj.caml_equal(x[/* id */0], t[/* id */0]);
                if (match) {
                  return t;
                } else {
                  return x;
                }
              }));
}

function make(question, formulae, jumpTo, defaultJumpTo) {
  return /* record */[
          /* id */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* question */question,
          /* formulae */formulae,
          /* jumpTo */jumpTo,
          /* defaultJumpTo */defaultJumpTo
        ];
}

function emptyComparator(param) {
  return makeComparator(/* Empty */0);
}

function empty(param) {
  return make(undefined, /* :: */[
              makeFormula(makeComparator(/* Empty */0), ""),
              /* [] */0
            ], undefined, undefined);
}

function comparatorForQuestions(t) {
  var match = t[/* question */1];
  if (match !== undefined) {
    var match$1 = Question$ReactHooksTemplate.questionType(match);
    switch (match$1.tag | 0) {
      case /* OpinionScale */0 :
      case /* Nps */4 :
          break;
      case /* MultipleChoice */5 :
          return /* :: */[
                  makeComparator(/* EqualsTo */1),
                  /* [] */0
                ];
      default:
        return /* [] */0;
    }
    return /* :: */[
            makeComparator(/* EqualsTo */1),
            /* :: */[
              makeComparator(/* LessThanEqualTo */2),
              /* :: */[
                makeComparator(/* GreaterThanEqualTo */3),
                /* [] */0
              ]
            ]
          ];
  } else {
    return /* [] */0;
  }
}

function equate(question, formula) {
  var y = formula[/* compareValue */2];
  if (question !== undefined) {
    var match = Question$ReactHooksTemplate.questionType(question);
    var match$1 = formula[/* comparator */1][/* comparatorType */1];
    switch (match.tag | 0) {
      case /* OpinionScale */0 :
          var opinionScale = match[0];
          switch (match$1) {
            case /* Empty */0 :
                return false;
            case /* EqualsTo */1 :
                return OpinionScale$ReactHooksTemplate.$eq(opinionScale, Caml_format.caml_int_of_string(y));
            case /* LessThanEqualTo */2 :
                return OpinionScale$ReactHooksTemplate.$less$eq(opinionScale, Caml_format.caml_int_of_string(y));
            case /* GreaterThanEqualTo */3 :
                return OpinionScale$ReactHooksTemplate.$great$eq(opinionScale, Caml_format.caml_int_of_string(y));
            
          }
      case /* Nps */4 :
          var scale = match[0];
          switch (match$1) {
            case /* Empty */0 :
                return false;
            case /* EqualsTo */1 :
                return Nps$ReactHooksTemplate.$eq(scale, Caml_format.caml_int_of_string(y));
            case /* LessThanEqualTo */2 :
                return Nps$ReactHooksTemplate.$less$eq(scale, Caml_format.caml_int_of_string(y));
            case /* GreaterThanEqualTo */3 :
                return Nps$ReactHooksTemplate.$great$eq(scale, Caml_format.caml_int_of_string(y));
            
          }
      case /* MultipleChoice */5 :
          if (match$1 !== 1) {
            return false;
          } else {
            return MultipleChoice$ReactHooksTemplate.$eq(match[0], y);
          }
      default:
        return false;
    }
  } else {
    return false;
  }
}

function iterFormulae(t) {
  var _acc = true;
  var _xs = t[/* formulae */2];
  while(true) {
    var xs = _xs;
    var acc = _acc;
    if (xs) {
      if (acc) {
        _xs = xs[1];
        _acc = equate(t[/* question */1], xs[0]);
        continue ;
      } else {
        return false;
      }
    } else {
      return acc;
    }
  };
}

function compute(t) {
  var match = iterFormulae(t);
  if (match) {
    return t[/* jumpTo */3];
  } else {
    return t[/* defaultJumpTo */4];
  }
}

function hasLogicalJump(question, t) {
  var match = t[/* question */1];
  if (match !== undefined) {
    var match$1 = Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(question), Question$ReactHooksTemplate.uuid(match));
    if (match$1) {
      return t;
    } else {
      return ;
    }
  }
  
}

function findQuestionId(question) {
  if (question !== undefined) {
    return RandomId$ReactHooksTemplate.toJson(Question$ReactHooksTemplate.uuid(question));
  } else {
    return RandomId$ReactHooksTemplate.toJson(RandomId$ReactHooksTemplate.generate(/* () */0));
  }
}

function encodeFormulae(xs) {
  var comparator = xs[/* comparator */1];
  var value = xs[/* compareValue */2];
  return /* :: */[
          /* tuple */[
            "comparator",
            Json_encode.object_(/* :: */[
                  /* tuple */[
                    "id",
                    String(comparator[/* id */0])
                  ],
                  /* :: */[
                    /* tuple */[
                      "key",
                      comparatorToString(comparator[/* comparatorType */1])
                    ],
                    /* :: */[
                      /* tuple */[
                        "display",
                        comparator[/* displayName */2]
                      ],
                      /* [] */0
                    ]
                  ]
                ])
          ],
          /* :: */[
            /* tuple */[
              "value",
              value
            ],
            /* [] */0
          ]
        ];
}

function encode(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "uid",
                RandomId$ReactHooksTemplate.toJson(t[/* id */0])
              ],
              /* :: */[
                /* tuple */[
                  "question_uid",
                  findQuestionId(t[/* question */1])
                ],
                /* :: */[
                  /* tuple */[
                    "formulae",
                    Json_encode.list(Json_encode.object_, List.map(encodeFormulae, t[/* formulae */2]))
                  ],
                  /* :: */[
                    /* tuple */[
                      "jump_to",
                      findQuestionId(t[/* jumpTo */3])
                    ],
                    /* :: */[
                      /* tuple */[
                        "default_jump_to",
                        findQuestionId(t[/* defaultJumpTo */4])
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function encodeGroup(group) {
  return Json_encode.list(encode, group);
}

function getQuestionFromList(questionList, id) {
  return Belt_List.head(Belt_List.keep(questionList, (function (question) {
                    return Caml_obj.caml_equal(id, RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question)));
                  })));
}

function decodeComparator(json) {
  var id = Caml_format.caml_int_of_string(Json_decode.field("id", Json_decode.string, json));
  var comparatorType = comparatorTypeFromId(id);
  return /* record */[
          /* id */id,
          /* comparatorType */comparatorType,
          /* displayName */comparatorToDisplay(comparatorType)
        ];
}

function decodeFormulae(json) {
  return /* record */[
          /* id */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* comparator */Json_decode.field("comparator", decodeComparator, json),
          /* compareValue */Json_decode.field("value", Json_decode.string, json)
        ];
}

function fromJson(questionList, json) {
  return Json_decode.field("survey", (function (param) {
                return Json_decode.withDefault(/* [] */0, (function (param) {
                              return Json_decode.field("logic_jump", (function (param) {
                                            return Json_decode.list((function (param) {
                                                          var questionList$1 = questionList;
                                                          var json = param;
                                                          return /* record */[
                                                                  /* id */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
                                                                  /* question */getQuestionFromList(questionList$1, Json_decode.field("question_uid", Json_decode.string, json)),
                                                                  /* formulae */Json_decode.field("formulae", (function (param) {
                                                                          return Json_decode.list(decodeFormulae, param);
                                                                        }), json),
                                                                  /* jumpTo */getQuestionFromList(questionList$1, Json_decode.field("jump_to", Json_decode.string, json)),
                                                                  /* defaultJumpTo */getQuestionFromList(questionList$1, Json_decode.field("default_jump_to", Json_decode.string, json))
                                                                ];
                                                        }), param);
                                          }), param);
                            }), param);
              }), json);
}

exports.makeGroup = makeGroup;
exports.addToGroup = addToGroup;
exports.deleteFromGroup = deleteFromGroup;
exports.replaceInGroup = replaceInGroup;
exports.compute = compute;
exports.hasLogicalJump = hasLogicalJump;
exports.empty = empty;
exports.id = id;
exports.question = question;
exports.jumpTo = jumpTo;
exports.defaultJumpTo = defaultJumpTo;
exports.setQuestion = setQuestion;
exports.setJumpTo = setJumpTo;
exports.setDefaultJumpTo = setDefaultJumpTo;
exports.encodeGroup = encodeGroup;
exports.encode = encode;
exports.make = make;
exports.setFormulae = setFormulae;
exports.formulae = formulae;
exports.comparatorToDisplay = comparatorToDisplay;
exports.comparatorForQuestions = comparatorForQuestions;
exports.getComparatorDisplayName = getComparatorDisplayName;
exports.makeFormula = makeFormula;
exports.emptyComparator = emptyComparator;
exports.getFormulaId = getFormulaId;
exports.getComparatorFromFormula = getComparatorFromFormula;
exports.getCompareValueFromFormula = getCompareValueFromFormula;
exports.getComparatorId = getComparatorId;
exports.comparatorTypeFromId = comparatorTypeFromId;
exports.makeComparator = makeComparator;
exports.setComparatorToFormula = setComparatorToFormula;
exports.setCompareValueToFormula = setCompareValueToFormula;
exports.fromJson = fromJson;
/* Nps-ReactHooksTemplate Not a pure module */
