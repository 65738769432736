// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Highcharts$ReactHooksTemplate = require("../../common/Highcharts.bs.js");

function RadialBarChartComponent(Props) {
  Props.chartId;
  Props.chartAttribute;
  Props.enlarged;
  var chartHeight = Props.chartHeight;
  return React.createElement("div", {
              className: chartHeight
            }, React.createElement(Highcharts$ReactHooksTemplate.HighchartsGaugeBinding.make, { }));
}

var make = RadialBarChartComponent;

exports.make = make;
/* react Not a pure module */
