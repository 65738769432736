// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function StarRatingEditor(Props) {
  Props.starRating;
  Props.onStarRatingChange;
  return React.createElement("div", undefined);
}

var make = StarRatingEditor;

exports.make = make;
/* react Not a pure module */
