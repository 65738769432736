// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function name(t) {
  return t[/* name */0];
}

function font(t) {
  return t[/* font */2];
}

function questionColor(t) {
  return t[/* questionColor */3];
}

function answerColor(t) {
  return t[/* answerColor */4];
}

function backButtonColor(t) {
  return t[/* backButtonColor */5];
}

function nextButtonColor(t) {
  return t[/* nextButtonColor */6];
}

function backgroundColor(t) {
  return t[/* backgroundColor */7];
}

function logoAlignment(t) {
  return t[/* logoAlignment */8];
}

function empty(param) {
  return /* record */[
          /* name */"",
          /* default */false,
          /* font */"",
          /* questionColor */"",
          /* answerColor */"",
          /* backButtonColor */"",
          /* nextButtonColor */"",
          /* backgroundColor */"",
          /* logoAlignment */undefined
        ];
}

function decode(json) {
  return /* record */[
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* default */Json_decode.field("default", Json_decode.bool, json),
          /* font */Json_decode.field("font", Json_decode.string, json),
          /* questionColor */Json_decode.field("colors", (function (param) {
                  return Json_decode.field("question", Json_decode.string, param);
                }), json),
          /* answerColor */Json_decode.field("colors", (function (param) {
                  return Json_decode.field("answer", Json_decode.string, param);
                }), json),
          /* backButtonColor */Json_decode.field("colors", (function (param) {
                  return Json_decode.field("backButton", Json_decode.string, param);
                }), json),
          /* nextButtonColor */Json_decode.field("colors", (function (param) {
                  return Json_decode.field("nextButton", Json_decode.string, param);
                }), json),
          /* backgroundColor */Json_decode.field("colors", (function (param) {
                  return Json_decode.field("background", Json_decode.string, param);
                }), json),
          /* logoAlignment */Json_decode.optional((function (param) {
                  return Json_decode.field("logo_properties", (function (param) {
                                return Json_decode.field("alignment", Json_decode.string, param);
                              }), param);
                }), json)
        ];
}

exports.empty = empty;
exports.name = name;
exports.font = font;
exports.questionColor = questionColor;
exports.answerColor = answerColor;
exports.backButtonColor = backButtonColor;
exports.nextButtonColor = nextButtonColor;
exports.backgroundColor = backgroundColor;
exports.logoAlignment = logoAlignment;
exports.decode = decode;
/* No side effect */
