// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function compose(f, g, x) {
  return Curry._1(f, Curry._1(g, x));
}

function formTargetValue($$event) {
  return $$event.target.value;
}

function handleInput(predicate, sendAction, $$event) {
  var x = $$event.target.value;
  if (Curry._1(predicate, x)) {
    return Curry._1(sendAction, x);
  } else {
    return 0;
  }
}

var __x = document.querySelector("meta[name='csrf-token']");

var __x$1 = Belt_Option.flatMap((__x == null) ? undefined : Caml_option.some(__x), (function (param) {
        return Caml_option.nullable_to_opt(param.getAttribute("content"));
      }));

var csrfToken = Belt_Option.getWithDefault(__x$1, "");

function encodeOptionalParamToList(text, param, encoder) {
  if (param !== undefined) {
    return /* :: */[
            /* tuple */[
              text,
              Curry._1(encoder, Caml_option.valFromOption(param))
            ],
            /* [] */0
          ];
  } else {
    return /* [] */0;
  }
}

function encodeOptionalMomentDateParamToList(text, param) {
  if (param !== undefined) {
    return /* :: */[
            /* tuple */[
              text,
              Caml_option.valFromOption(param).format("YYYY-MM-DD HH:mm:SS")
            ],
            /* [] */0
          ];
  } else {
    return /* [] */0;
  }
}

function humanize(text) {
  return $$String.capitalize(text.replace("_", " "));
}

function checkMaxLength(n, x) {
  return x.length <= n;
}

function checkMaxLength255(x) {
  return x.length <= 255;
}

function checkMaxLength36(x) {
  return x.length <= 36;
}

function checkMaxLength24(x) {
  return x.length <= 24;
}

function checkMinLength(n, x) {
  return x.length >= n;
}

function checkMinLength8(x) {
  return x.length >= 8;
}

function checkAlphanumeric(x) {
  var matched = x.match((/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,70}$/));
  return (
          matched === null ? undefined : Caml_option.some(matched)
        ) !== undefined;
}

function checkUsernameExistInPassword(uname, x) {
  var matched = x.includes(uname);
  if (matched) {
    return true;
  } else {
    return false;
  }
}

var timeout = 500;

exports.compose = compose;
exports.formTargetValue = formTargetValue;
exports.handleInput = handleInput;
exports.csrfToken = csrfToken;
exports.encodeOptionalParamToList = encodeOptionalParamToList;
exports.encodeOptionalMomentDateParamToList = encodeOptionalMomentDateParamToList;
exports.humanize = humanize;
exports.checkMaxLength = checkMaxLength;
exports.checkMaxLength255 = checkMaxLength255;
exports.checkMaxLength36 = checkMaxLength36;
exports.checkMaxLength24 = checkMaxLength24;
exports.checkMinLength = checkMinLength;
exports.checkMinLength8 = checkMinLength8;
exports.checkAlphanumeric = checkAlphanumeric;
exports.checkUsernameExistInPassword = checkUsernameExistInPassword;
exports.timeout = timeout;
/* __x Not a pure module */
