// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function make(text, publicKey) {
  return {
          text: text,
          publicKey: publicKey
        };
}

var EncryptionOptions = {
  make: make
};

exports.EncryptionOptions = EncryptionOptions;
/* No side effect */
