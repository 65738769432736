// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Moment = require("moment");
var MomentRe = require("bs-moment/src/MomentRe.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactDates = require("react-dates");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var DateFilter$ReactHooksTemplate = require("./dateFilter.bs.js");
var DateSelected$ReactHooksTemplate = require("./dateSelected.bs.js");
var ReactDatePicker$ReactHooksTemplate = require("../../common/ReactDatePicker.bs.js");

function DateFilterForm(Props) {
  var filter = Props.filter;
  var onChange = Props.onChange;
  var displayFilterPills = Props.displayFilterPills;
  var disableDatePicker = Props.disableDatePicker;
  var match = React.useState((function () {
          var match = DateFilter$ReactHooksTemplate.selected(filter);
          var match$1 = DateFilter$ReactHooksTemplate.selected(filter);
          return /* record */[
                  /* startDate */match !== undefined ? DateSelected$ReactHooksTemplate.from(match) : undefined,
                  /* endDate */match$1 !== undefined ? DateSelected$ReactHooksTemplate.to_(match$1) : undefined,
                  /* focusedInput */null,
                  /* focusedDate */disableDatePicker ? "One Month" : "Custom Date"
                ];
        }));
  var setState = match[1];
  var state = match[0];
  var handleOffSetFilter = function (duration) {
    var startDate = Caml_option.some(MomentRe.Moment.subtract(duration, Moment()));
    var endDate = Caml_option.some(Moment());
    Curry._1(setState, (function (state) {
            return /* record */[
                    /* startDate */startDate,
                    /* endDate */endDate,
                    /* focusedInput */state[/* focusedInput */2],
                    /* focusedDate */state[/* focusedDate */3]
                  ];
          }));
    return Curry._1(onChange, /* Date */Block.__(1, [DateFilter$ReactHooksTemplate.setSelected(DateSelected$ReactHooksTemplate.make(startDate, endDate), filter)]));
  };
  var handleYesterdayFilter = function (param) {
    var startDate = Caml_option.some(MomentRe.Moment.subtract(Moment.duration(1, "days"), MomentRe.Moment.startOf(/* day */4994652, Moment())));
    var endDate = Caml_option.some(MomentRe.Moment.subtract(Moment.duration(1, "days"), MomentRe.Moment.subtract(Moment.duration(400, "milliseconds"), MomentRe.Moment.endOf(/* day */4994652, Moment()))));
    Curry._1(setState, (function (state) {
            return /* record */[
                    /* startDate */startDate,
                    /* endDate */endDate,
                    /* focusedInput */state[/* focusedInput */2],
                    /* focusedDate */state[/* focusedDate */3]
                  ];
          }));
    return Curry._1(onChange, /* Date */Block.__(1, [DateFilter$ReactHooksTemplate.setSelected(DateSelected$ReactHooksTemplate.make(startDate, endDate), filter)]));
  };
  var onDatesChange = function (dateRangeTuple) {
    var rangeTuple = ReactDatePicker$ReactHooksTemplate.DateRangeTuple.getDates(dateRangeTuple);
    var selectedStartDate = Caml_option.some(ReactDatePicker$ReactHooksTemplate.DateRangeTuple.getStartDate(rangeTuple));
    var selectedEndDate = Caml_option.some(ReactDatePicker$ReactHooksTemplate.DateRangeTuple.getEndDate(rangeTuple));
    Curry._1(setState, (function (state) {
            return /* record */[
                    /* startDate */selectedStartDate,
                    /* endDate */selectedEndDate,
                    /* focusedInput */state[/* focusedInput */2],
                    /* focusedDate */state[/* focusedDate */3]
                  ];
          }));
    return Curry._1(onChange, /* Date */Block.__(1, [DateFilter$ReactHooksTemplate.setSelected(DateSelected$ReactHooksTemplate.make(selectedStartDate, selectedEndDate), filter)]));
  };
  var checkForOutsideRange = function (date) {
    return false;
  };
  var match$1 = displayFilterPills === true;
  return React.createElement("div", {
              className: displayFilterPills ? "text-darkcharcoal p-5 border-b border-gray-400" : ""
            }, match$1 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "mb-3"
                      }, "Transaction Date"), React.createElement("div", {
                        className: "flex gap-2"
                      }, React.createElement("div", {
                            className: "dropdown mr-20-px"
                          }, React.createElement("div", {
                                className: "flex items-center text-sm font-normal w-40 justify-between predefined_dates rounded"
                              }, React.createElement("p", {
                                    className: "mr-5-px"
                                  }, state[/* focusedDate */3]), React.createElement("img", {
                                    className: "h-7-px filter-gray-400",
                                    src: Image$ReactHooksTemplate.chevronDown
                                  })), React.createElement("ul", {
                                className: "dropdown-list shadow text-gray-800 text-sm"
                              }, React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleOffSetFilter(Moment.duration(1, "days"));
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"One Day"
                                                                ];
                                                        }));
                                          })
                                      }, "One Day")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleYesterdayFilter(/* () */0);
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"Yesterday"
                                                                ];
                                                        }));
                                          })
                                      }, "Yesterday")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleOffSetFilter(Moment.duration(1, "weeks"));
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"One Week"
                                                                ];
                                                        }));
                                          })
                                      }, "One Week")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleOffSetFilter(Moment.duration(1, "months"));
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"One Month"
                                                                ];
                                                        }));
                                          })
                                      }, "One Month")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleOffSetFilter(Moment.duration(3, "months"));
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"3 Months"
                                                                ];
                                                        }));
                                          })
                                      }, "3 Months")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleOffSetFilter(Moment.duration(6, "months"));
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"6 Months"
                                                                ];
                                                        }));
                                          })
                                      }, "6 Months")), React.createElement("li", {
                                    className: "hover:bg-gray-300"
                                  }, React.createElement("div", {
                                        className: "block p-10-px hover:bg-palatinateblue hover:text-white rounded",
                                        onClick: (function (param) {
                                            handleOffSetFilter(Moment.duration(1, "years"));
                                            return Curry._1(setState, (function (state) {
                                                          return /* record */[
                                                                  /* startDate */state[/* startDate */0],
                                                                  /* endDate */state[/* endDate */1],
                                                                  /* focusedInput */state[/* focusedInput */2],
                                                                  /* focusedDate */"One Year"
                                                                ];
                                                        }));
                                          })
                                      }, "One Year")))), React.createElement("div", {
                            className: "flex text-xs"
                          }, React.createElement("button", {
                                className: "border border-gray-300 p-2 hover:bg-palatinateblue hover:text-white rounded mr-1 hidden",
                                id: "d-minus-one",
                                onClick: (function (param) {
                                    handleYesterdayFilter(/* () */0);
                                    return Curry._1(setState, (function (state) {
                                                  return /* record */[
                                                          /* startDate */state[/* startDate */0],
                                                          /* endDate */state[/* endDate */1],
                                                          /* focusedInput */state[/* focusedInput */2],
                                                          /* focusedDate */"Yesterday"
                                                        ];
                                                }));
                                  })
                              }, "D-1")), React.createElement("div", {
                            className: "flex"
                          }, React.createElement(ReactDates.DateRangePicker, {
                                startDate: state[/* startDate */0],
                                startDateId: "your_unique_start_date_id",
                                endDate: state[/* endDate */1],
                                endDateId: "your_unique_end_date_id",
                                disabled: disableDatePicker,
                                onDatesChange: onDatesChange,
                                focusedInput: state[/* focusedInput */2],
                                onFocusChange: (function (focusedInput) {
                                    return Curry._1(setState, (function (state) {
                                                  return /* record */[
                                                          /* startDate */state[/* startDate */0],
                                                          /* endDate */state[/* endDate */1],
                                                          /* focusedInput */focusedInput,
                                                          /* focusedDate */"Custom Date"
                                                        ];
                                                }));
                                  }),
                                displayFormat: "DD/MM/YYYY",
                                openDirection: "down",
                                hideKeyboardShortcutsPanel: true,
                                enableOutsideDays: true,
                                isOutsideRange: checkForOutsideRange,
                                small: true,
                                orientation: "horizontal",
                                numberOfMonths: 1,
                                readOnly: true,
                                minimumNights: 0
                              })))) : React.createElement("div", {
                    className: "flex"
                  }, React.createElement(ReactDates.DateRangePicker, {
                        startDate: state[/* startDate */0],
                        startDateId: "your_unique_start_date_id",
                        endDate: state[/* endDate */1],
                        endDateId: "your_unique_end_date_id",
                        disabled: disableDatePicker,
                        onDatesChange: onDatesChange,
                        focusedInput: state[/* focusedInput */2],
                        onFocusChange: (function (focusedInput) {
                            return Curry._1(setState, (function (state) {
                                          return /* record */[
                                                  /* startDate */state[/* startDate */0],
                                                  /* endDate */state[/* endDate */1],
                                                  /* focusedInput */focusedInput,
                                                  /* focusedDate */"Custom Date"
                                                ];
                                        }));
                          }),
                        displayFormat: "DD/MM/YYYY",
                        openDirection: "down",
                        hideKeyboardShortcutsPanel: true,
                        enableOutsideDays: true,
                        isOutsideRange: checkForOutsideRange,
                        small: true,
                        orientation: "horizontal",
                        numberOfMonths: 1,
                        readOnly: true,
                        minimumNights: 0
                      })));
}

var make = DateFilterForm;

exports.make = make;
/* react Not a pure module */
