// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decodeLabel(json) {
  return /* record */[
          /* rating */Json_decode.field("id", Json_decode.$$int, json),
          /* label */Json_decode.field("label", Json_decode.string, json)
        ];
}

function decode(json) {
  return /* record */[
          /* text */Json_decode.field("text", Json_decode.string, json),
          /* description */Json_decode.field("description", Json_decode.string, json),
          /* ratingLabels */Json_decode.field("options", (function (param) {
                  return Json_decode.list(decodeLabel, param);
                }), json)
        ];
}

function empty(param) {
  return /* record */[
          /* id */undefined,
          /* uuid */RandomId$ReactHooksTemplate.generate(/* () */0),
          /* text */"",
          /* ratingLabels : :: */[
            /* record */[
              /* rating */1,
              /* label */"Poor"
            ],
            /* :: */[
              /* record */[
                /* rating */2,
                /* label */"Fair"
              ],
              /* :: */[
                /* record */[
                  /* rating */3,
                  /* label */"Good"
                ],
                /* :: */[
                  /* record */[
                    /* rating */4,
                    /* label */"Very Good"
                  ],
                  /* :: */[
                    /* record */[
                      /* rating */5,
                      /* label */"Excellent"
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]
          ],
          /* description */"",
          /* translations : [] */0,
          /* negativeToPositive */true,
          /* answer */undefined
        ];
}

function answered(t) {
  return t[/* answer */7] !== undefined;
}

function id(t) {
  return t[/* id */0];
}

function uuid(t) {
  return t[/* uuid */1];
}

function text(t) {
  return t[/* text */2];
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */5]);
  if (translation !== undefined) {
    return translation[1][/* text */0];
  } else {
    return t[/* text */2];
  }
}

function description(t) {
  return t[/* description */4];
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */5]);
  if (translation !== undefined) {
    return translation[1][/* description */1];
  } else {
    return t[/* description */4];
  }
}

function negativeToPositive(t) {
  return t[/* negativeToPositive */6];
}

function toggleNegativeToPositive(t) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* ratingLabels */t[/* ratingLabels */3],
          /* description */t[/* description */4],
          /* translations */t[/* translations */5],
          /* negativeToPositive */!t[/* negativeToPositive */6],
          /* answer */t[/* answer */7]
        ];
}

function answerTypeToUnicode(param) {
  switch (param) {
    case /* One */0 :
        return "😧";
    case /* Two */1 :
        return "🙁";
    case /* Three */2 :
        return "😐";
    case /* Four */3 :
        return "🙂";
    case /* Five */4 :
        return "😃";
    case /* NotSelected */5 :
        return "";
    
  }
}

function answerTypeToRating(param) {
  switch (param) {
    case /* One */0 :
        return 1;
    case /* Two */1 :
        return 2;
    case /* Three */2 :
        return 3;
    case /* Four */3 :
        return 4;
    case /* Five */4 :
        return 5;
    case /* NotSelected */5 :
        return -1;
    
  }
}

function answerTypeCollection(t) {
  var match = t[/* negativeToPositive */6];
  if (match) {
    return Belt_List.reverse(/* :: */[
                /* Five */4,
                /* :: */[
                  /* Four */3,
                  /* :: */[
                    /* Three */2,
                    /* :: */[
                      /* Two */1,
                      /* :: */[
                        /* One */0,
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]);
  } else {
    return /* :: */[
            /* Five */4,
            /* :: */[
              /* Four */3,
              /* :: */[
                /* Three */2,
                /* :: */[
                  /* Two */1,
                  /* :: */[
                    /* One */0,
                    /* [] */0
                  ]
                ]
              ]
            ]
          ];
  }
}

function answerTypeToString(t, answerType, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t[/* translations */5]);
  var rating = answerTypeToRating(answerType);
  var ratingLabels = translation !== undefined ? translation[1][/* ratingLabels */2] : t[/* ratingLabels */3];
  if (rating !== -1) {
    return List.find((function (x) {
                    return x[/* rating */0] === rating;
                  }), ratingLabels)[/* label */1];
  } else {
    return "";
  }
}

function ratingToAnswerType(param) {
  var switcher = param - 1 | 0;
  if (switcher > 4 || switcher < 0) {
    return /* NotSelected */5;
  } else {
    return switcher;
  }
}

function answerToString(t) {
  var match = t[/* answer */7];
  if (match !== undefined) {
    return answerTypeToString(t, match, "");
  } else {
    return answerTypeToString(t, /* NotSelected */5, "");
  }
}

function setText(t, text) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */text,
          /* ratingLabels */t[/* ratingLabels */3],
          /* description */t[/* description */4],
          /* translations */t[/* translations */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* answer */t[/* answer */7]
        ];
}

function setDescription(t, description) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* ratingLabels */t[/* ratingLabels */3],
          /* description */description,
          /* translations */t[/* translations */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* answer */t[/* answer */7]
        ];
}

function setAnswer(t, answer) {
  return /* record */[
          /* id */t[/* id */0],
          /* uuid */t[/* uuid */1],
          /* text */t[/* text */2],
          /* ratingLabels */t[/* ratingLabels */3],
          /* description */t[/* description */4],
          /* translations */t[/* translations */5],
          /* negativeToPositive */t[/* negativeToPositive */6],
          /* answer */answer
        ];
}

function getAnswer(t) {
  return t[/* answer */7];
}

function encodeAnswerTypeCollection(ratingLabel) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                ratingLabel[/* rating */0]
              ],
              /* :: */[
                /* tuple */[
                  "label",
                  ratingLabel[/* label */1]
                ],
                /* [] */0
              ]
            ]);
}

function toJson($staropt$star, t) {
  var withAnswer = $staropt$star !== undefined ? $staropt$star : false;
  var encodeArray_000 = /* tuple */[
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t[/* id */0])
  ];
  var encodeArray_001 = /* :: */[
    /* tuple */[
      "text",
      t[/* text */2]
    ],
    /* :: */[
      /* tuple */[
        "description",
        t[/* description */4]
      ],
      /* :: */[
        /* tuple */[
          "negative_to_positive",
          t[/* negativeToPositive */6]
        ],
        /* :: */[
          /* tuple */[
            "options",
            Json_encode.list(encodeAnswerTypeCollection, t[/* ratingLabels */3])
          ],
          /* [] */0
        ]
      ]
    ]
  ];
  var encodeArray = /* :: */[
    encodeArray_000,
    encodeArray_001
  ];
  return Json_encode.object_(withAnswer ? /* :: */[
                /* tuple */[
                  "answer",
                  answerTypeToRating(Belt_Option.getWithDefault(t[/* answer */7], /* NotSelected */5))
                ],
                encodeArray
              ] : encodeArray);
}

function decodeTranslation(json) {
  return /* tuple */[
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function decodeLabel$1(json) {
  return /* record */[
          /* rating */Json_decode.field("id", Json_decode.$$int, json),
          /* label */Json_decode.field("label", Json_decode.string, json)
        ];
}

function fromJson(json) {
  return /* record */[
          /* id */Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          /* uuid */Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          /* text */Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          /* ratingLabels */Json_decode.field("category", (function (param) {
                  return Json_decode.field("options", (function (param) {
                                return Json_decode.list(decodeLabel$1, param);
                              }), param);
                }), json),
          /* description */Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          /* translations */Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          /* negativeToPositive */Json_decode.field("category", (function (param) {
                  return Json_decode.field("negative_to_positive", Json_decode.bool, param);
                }), json),
          /* answer */undefined
        ];
}

var categoryType = "Csat";

exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.negativeToPositive = negativeToPositive;
exports.toggleNegativeToPositive = toggleNegativeToPositive;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.ratingToAnswerType = ratingToAnswerType;
exports.answerTypeToUnicode = answerTypeToUnicode;
exports.answerTypeCollection = answerTypeCollection;
exports.answerTypeToString = answerTypeToString;
exports.setText = setText;
exports.setDescription = setDescription;
exports.categoryType = categoryType;
exports.empty = empty;
exports.id = id;
exports.uuid = uuid;
exports.toJson = toJson;
exports.setAnswer = setAnswer;
exports.getAnswer = getAnswer;
exports.answered = answered;
exports.fromJson = fromJson;
exports.answerToString = answerToString;
/* RandomId-ReactHooksTemplate Not a pure module */
