// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");

function reportId(t) {
  return t[/* reportId */0];
}

function name(t) {
  return t[/* name */1];
}

function path(t) {
  return t[/* path */2];
}

function decode(json) {
  return /* record */[
          /* reportId */Json_decode.field("id", Json_decode.$$int, json),
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* path */Json_decode.field("path", Json_decode.string, json)
        ];
}

var Report = {
  reportId: reportId,
  name: name,
  path: path,
  decode: decode
};

function ReportList(Props) {
  var id = Props.id;
  Props.search;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setReports = match[1];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/campaign_reports?campaign_id=" + (String(id) + ""), (function (param) {
                    return Json_decode.field("reports", (function (param) {
                                  return Json_decode.list(decode, param);
                                }), param);
                  })).then((function (reports) {
                  return Promise.resolve((console.log(reports), Curry._1(setReports, (function (param) {
                                      return reports;
                                    }))));
                }));
          return ;
        }), ([]));
  var handleOnClick = function ($$event, url) {
    $$event.preventDefault();
    return ReasonReactRouter.push(url);
  };
  var match$2 = Curry._1(match$1[0], /* ShowReport */19);
  return React.createElement(React.Fragment, undefined, match$2 ? React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center p-20-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Reports"), React.createElement("a", {
                            className: "bg-ghostwhite border border-gray-400\n                shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "add-report",
                            href: ""
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.plusCircle
                              }), "New Report")), React.createElement("div", {
                        className: "flex px-20-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Id", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Name", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (report) {
                                          var reportPath = report[/* path */2];
                                          var url = "" + (String(reportPath) + "");
                                          return React.createElement("tr", {
                                                      key: id,
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("a", {
                                                              key: id,
                                                              onClick: (function ($$event) {
                                                                  return handleOnClick($$event, url);
                                                                })
                                                            }, String(report[/* reportId */0]))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("a", {
                                                              key: id,
                                                              onClick: (function ($$event) {
                                                                  return handleOnClick($$event, url);
                                                                })
                                                            }, report[/* name */1])));
                                        }), match[0])))))) : React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        img: Image$ReactHooksTemplate.unAuthorized,
                        text: "401: Unauthorized!!",
                        loading: false
                      })));
}

var emptyReports = /* [] */0;

var make = ReportList;

exports.emptyReports = emptyReports;
exports.Report = Report;
exports.make = make;
/* react Not a pure module */
