// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Mode$ReactHooksTemplate = require("./mode.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var SmsMode$ReactHooksTemplate = require("./SmsMode.bs.js");
var Campaign$ReactHooksTemplate = require("./campaign.bs.js");
var EmailMode$ReactHooksTemplate = require("./EmailMode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");
var AppLinkMode$ReactHooksTemplate = require("./AppLinkMode.bs.js");
var SmsLinkMode$ReactHooksTemplate = require("./SmsLinkMode.bs.js");
var SmsSenderId$ReactHooksTemplate = require("./smsSenderId.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var WebsiteLinkMode$ReactHooksTemplate = require("./WebsiteLinkMode.bs.js");
var TextAreaInputNew$ReactHooksTemplate = require("../common/TextAreaInputNew.bs.js");
var WhatsappLinkMode$ReactHooksTemplate = require("./WhatsappLinkMode.bs.js");

function id(t) {
  return t[/* id */0];
}

var Survey = {
  id: id
};

function decodeStatus(json) {
  return Json_decode.field("status", Json_decode.string, json).toLowerCase();
}

function decodeNameErrors(json) {
  return Json_decode.field("errors", (function (param) {
                return Json_decode.field("name", (function (param) {
                              return Json_decode.list(Json_decode.string, param);
                            }), param);
              }), json);
}

function decode(json) {
  var match = decodeStatus(json);
  switch (match) {
    case "failed" :
        return /* Failure */[decodeNameErrors(json)];
    case "success" :
        return /* Success */0;
    default:
      return /* Failure */[/* [] */0];
  }
}

var CampaignApiResponse = {
  decodeStatus: decodeStatus,
  decodeNameErrors: decodeNameErrors,
  decode: decode
};

var availableModes_000 = /* record */[
  /* id */undefined,
  /* mode : Email */Block.__(0, [EmailMode$ReactHooksTemplate.empty(/* () */0)])
];

var availableModes_001 = /* :: */[
  /* record */[
    /* id */undefined,
    /* mode : Sms */Block.__(1, [SmsMode$ReactHooksTemplate.empty(/* () */0)])
  ],
  /* :: */[
    /* record */[
      /* id */undefined,
      /* mode : SmsLink */Block.__(2, [SmsLinkMode$ReactHooksTemplate.empty(/* () */0)])
    ],
    /* :: */[
      /* record */[
        /* id */undefined,
        /* mode : WhatsappLink */Block.__(3, [WhatsappLinkMode$ReactHooksTemplate.empty(/* () */0)])
      ],
      /* :: */[
        /* record */[
          /* id */undefined,
          /* mode : AppLink */Block.__(4, [AppLinkMode$ReactHooksTemplate.empty(/* () */0)])
        ],
        /* :: */[
          /* record */[
            /* id */undefined,
            /* mode : WebsiteLink */Block.__(5, [WebsiteLinkMode$ReactHooksTemplate.empty(/* () */0)])
          ],
          /* :: */[
            /* record */[
              /* id */undefined,
              /* mode : AnonymousLink */0
            ],
            /* :: */[
              /* record */[
                /* id */undefined,
                /* mode : Telephonic */1
              ],
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var availableModes = /* :: */[
  availableModes_000,
  availableModes_001
];

var emptyForm_000 = /* name : Pristine */Block.__(2, [Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, /* () */0)]);

var emptyForm_005 = /* survey : Pristine */Block.__(2, [undefined]);

var emptyForm = /* record */[
  emptyForm_000,
  /* surveys : [] */0,
  /* smsSenderIds : [] */0,
  /* availableModes */availableModes,
  /* showAddMode */false,
  emptyForm_005,
  /* modes : [] */0
];

function generateForm(campaign) {
  return /* record */[
          /* name : Valid */Block.__(0, [Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Campaign$ReactHooksTemplate.name(campaign))]),
          /* surveys : [] */0,
          /* smsSenderIds : [] */0,
          /* availableModes */availableModes,
          /* showAddMode */false,
          /* survey : Pristine */Block.__(2, [undefined]),
          /* modes */Belt_List.map(Campaign$ReactHooksTemplate.modes(campaign), (function (x) {
                  return /* Valid */Block.__(0, [Mode$ReactHooksTemplate.toForm(x)]);
                }))
        ];
}

function reducer(state, action) {
  if (state.tag) {
    var form = state[0];
    if (typeof action === "number") {
      return /* Available */Block.__(1, [/* record */[
                  /* name */form[/* name */0],
                  /* surveys */form[/* surveys */1],
                  /* smsSenderIds */form[/* smsSenderIds */2],
                  /* availableModes */form[/* availableModes */3],
                  /* showAddMode */!form[/* showAddMode */4],
                  /* survey */form[/* survey */5],
                  /* modes */form[/* modes */6]
                ]]);
    } else {
      switch (action.tag | 0) {
        case /* FetchSurveyCompleted */0 :
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */action[0],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */form[/* modes */6]
                      ]]);
        case /* ChangeNameInput */1 :
            return /* Available */Block.__(1, [/* record */[
                        /* name */action[0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */form[/* modes */6]
                      ]]);
        case /* EmailModeChanged */2 :
            var emailMode = action[0];
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                var form = FormField$ReactHooksTemplate.getInputValue(x);
                                var match = form[/* formMode */1];
                                if (typeof match === "number" || match.tag) {
                                  return x;
                                } else {
                                  return /* Valid */Block.__(0, [/* record */[
                                              /* id */form[/* id */0],
                                              /* formMode : Email */Block.__(0, [emailMode])
                                            ]]);
                                }
                              }))
                      ]]);
        case /* SmsLinkModeChanged */3 :
            var smsLinkMode = action[0];
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                var form = FormField$ReactHooksTemplate.getInputValue(x);
                                var match = form[/* formMode */1];
                                if (typeof match === "number" || match.tag !== /* SmsLink */2) {
                                  return x;
                                } else {
                                  return /* Valid */Block.__(0, [/* record */[
                                              /* id */form[/* id */0],
                                              /* formMode : SmsLink */Block.__(2, [smsLinkMode])
                                            ]]);
                                }
                              }))
                      ]]);
        case /* WhatsappLinkModeChanged */4 :
            var whatsappLinkMode = action[0];
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                var form = FormField$ReactHooksTemplate.getInputValue(x);
                                var match = form[/* formMode */1];
                                if (typeof match === "number" || match.tag !== /* WhatsappLink */3) {
                                  return x;
                                } else {
                                  return /* Valid */Block.__(0, [/* record */[
                                              /* id */form[/* id */0],
                                              /* formMode : WhatsappLink */Block.__(3, [whatsappLinkMode])
                                            ]]);
                                }
                              }))
                      ]]);
        case /* AppLinkModeChanged */5 :
            var appLinkMode = action[0];
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                var form = FormField$ReactHooksTemplate.getInputValue(x);
                                var match = form[/* formMode */1];
                                if (typeof match === "number" || match.tag !== /* AppLink */4) {
                                  return x;
                                } else {
                                  return /* Valid */Block.__(0, [/* record */[
                                              /* id */form[/* id */0],
                                              /* formMode : AppLink */Block.__(4, [appLinkMode])
                                            ]]);
                                }
                              }))
                      ]]);
        case /* WebsiteLinkModeChanged */6 :
            var websiteLinkMode = action[0];
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                var form = FormField$ReactHooksTemplate.getInputValue(x);
                                var match = form[/* formMode */1];
                                if (typeof match === "number" || match.tag !== /* WebsiteLink */5) {
                                  return x;
                                } else {
                                  return /* Valid */Block.__(0, [/* record */[
                                              /* id */form[/* id */0],
                                              /* formMode : WebsiteLink */Block.__(5, [websiteLinkMode])
                                            ]]);
                                }
                              }))
                      ]]);
        case /* SmsModeChanged */7 :
            var smsMode = action[0];
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                var form = FormField$ReactHooksTemplate.getInputValue(x);
                                var match = form[/* formMode */1];
                                if (typeof match === "number" || match.tag !== /* Sms */1) {
                                  return x;
                                } else {
                                  return /* Valid */Block.__(0, [/* record */[
                                              /* id */form[/* id */0],
                                              /* formMode : Sms */Block.__(1, [smsMode])
                                            ]]);
                                }
                              }))
                      ]]);
        case /* AnonymousLinkModeChanged */8 :
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */Belt_List.map(form[/* modes */6], (function (x) {
                                FormField$ReactHooksTemplate.getInputValue(x);
                                return x;
                              }))
                      ]]);
        case /* FailedApiResponse */9 :
            return /* Available */Block.__(1, [/* record */[
                        /* name : Invalid */Block.__(1, [
                            FormField$ReactHooksTemplate.getInputValue(form[/* name */0]),
                            action[0]
                          ]),
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */form[/* modes */6]
                      ]]);
        case /* UpdateSurvey */10 :
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey : Valid */Block.__(0, [action[0]]),
                        /* modes */form[/* modes */6]
                      ]]);
        case /* AddMode */11 :
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */false,
                        /* survey */form[/* survey */5],
                        /* modes : :: */[
                          action[0],
                          form[/* modes */6]
                        ]
                      ]]);
        case /* RemoveMode */12 :
            var mode = action[0];
            var modes = Belt_List.keep(form[/* modes */6], (function (x) {
                    return Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x))) !== Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(mode));
                  }));
            return /* Available */Block.__(1, [/* record */[
                        /* name */form[/* name */0],
                        /* surveys */form[/* surveys */1],
                        /* smsSenderIds */form[/* smsSenderIds */2],
                        /* availableModes */form[/* availableModes */3],
                        /* showAddMode */form[/* showAddMode */4],
                        /* survey */form[/* survey */5],
                        /* modes */modes
                      ]]);
        
      }
    }
  } else {
    var form$1 = state[0];
    if (typeof action === "number" || action.tag) {
      return /* FetchSurveysInProgress */Block.__(0, [form$1]);
    } else {
      var surveys = action[0];
      var survey = Belt_List.head(surveys);
      return /* Available */Block.__(1, [/* record */[
                  /* name */form$1[/* name */0],
                  /* surveys */surveys,
                  /* smsSenderIds */form$1[/* smsSenderIds */2],
                  /* availableModes */form$1[/* availableModes */3],
                  /* showAddMode */form$1[/* showAddMode */4],
                  /* survey : Valid */Block.__(0, [survey]),
                  /* modes */form$1[/* modes */6]
                ]]);
    }
  }
}

function validationErrors(field) {
  return React.createElement("div", {
              className: "my-2"
            }, $$Array.of_list(Belt_List.mapWithIndex(FormField$ReactHooksTemplate.getValidationError(field), (function (key, error) {
                        return React.createElement("p", {
                                    key: String(key),
                                    className: "text-red-500 text-xs float-right"
                                  }, error);
                      }))));
}

function isValid(field) {
  switch (field.tag | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function isValidMode(form) {
  var mode = Belt_List.head(form[/* modes */6]);
  if (mode !== undefined) {
    switch (mode.tag | 0) {
      case /* Valid */0 :
          return true;
      case /* Invalid */1 :
      case /* Pristine */2 :
          return false;
      
    }
  } else {
    return false;
  }
}

function isValidSurvey(survey) {
  switch (survey.tag | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function isValidForm(form) {
  if (isValid(form[/* name */0]) && isValidSurvey(form[/* survey */5])) {
    return isValidMode(form);
  } else {
    return false;
  }
}

function CampaignForm(Props) {
  var toggleCampaign = Props.toggleCampaign;
  var toggleModal = Props.toggleModal;
  var selectedCampaign = Props.selectedCampaign;
  var match = React.useReducer(reducer, selectedCampaign !== undefined ? /* FetchSurveysInProgress */Block.__(0, [generateForm(Caml_option.valFromOption(selectedCampaign))]) : /* FetchSurveysInProgress */Block.__(0, [emptyForm]));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setSenderIds = match$1[1];
  var senderIds = match$1[0];
  var match$2 = React.useState((function () {
          return /* GeneralSettings */0;
        }));
  var setSelected = match$2[1];
  var selected = match$2[0];
  var match$3 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var fetchSurveys = function (param) {
    var surveyDecoder = function (json) {
      return /* record */[
              /* id */Json_decode.field("id", Json_decode.$$int, json),
              /* name */Json_decode.field("name", Json_decode.string, json)
            ];
    };
    return fetch("/api/v1/all_surveys").then((function (json) {
                    return json.json();
                  })).then((function (json) {
                  return Promise.resolve(Curry._1(dispatch, /* FetchSurveyCompleted */Block.__(0, [Json_decode.field("surveys", (function (param) {
                                            return Json_decode.list(surveyDecoder, param);
                                          }), json)])));
                }));
  };
  var fetchSenderIds = function (param) {
    return fetch("/api/v1/sms_sender_ids").then((function (json) {
                    return json.json();
                  })).then((function (json) {
                  return Promise.resolve(Curry._1(setSenderIds, (function (param) {
                                    return Json_decode.field("sms_sender_ids", (function (param) {
                                                  return Json_decode.list(SmsSenderId$ReactHooksTemplate.decode, param);
                                                }), json);
                                  })));
                }));
  };
  React.useEffect((function () {
          fetchSurveys(/* () */0).then((function (param) {
                  return fetchSenderIds(/* () */0).then((function (prim) {
                                return Promise.resolve(prim);
                              }));
                }));
          return ;
        }), ([]));
  var handleNameInput = function ($$event) {
    var name = Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    var match = Curry._1(Domain$ReactHooksTemplate.$$String.Name.isEmpty, name);
    if (match) {
      return Curry._1(dispatch, /* ChangeNameInput */Block.__(1, [/* Invalid */Block.__(1, [
                        name,
                        /* :: */[
                          "can't be empty",
                          /* [] */0
                        ]
                      ])]));
    } else {
      return Curry._1(dispatch, /* ChangeNameInput */Block.__(1, [/* Valid */Block.__(0, [name])]));
    }
  };
  var handleSmsLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, /* SmsLinkModeChanged */Block.__(3, [SmsLinkMode$ReactHooksTemplate.toForm(SmsLinkMode$ReactHooksTemplate.setMessage(value))]));
  };
  var handleWhatsappLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, /* WhatsappLinkModeChanged */Block.__(4, [WhatsappLinkMode$ReactHooksTemplate.toForm(WhatsappLinkMode$ReactHooksTemplate.setMessage(value))]));
  };
  var handleAppLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, /* AppLinkModeChanged */Block.__(5, [AppLinkMode$ReactHooksTemplate.toForm(AppLinkMode$ReactHooksTemplate.setMessage(value))]));
  };
  var handleWebsiteLinkMessageChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(dispatch, /* WebsiteLinkModeChanged */Block.__(6, [WebsiteLinkMode$ReactHooksTemplate.toForm(WebsiteLinkMode$ReactHooksTemplate.setMessage(value))]));
  };
  var handleChangeModal = function (form, selectedCampaign$1) {
    if (selectedCampaign$1 !== undefined) {
      return (function (param) {
          var form$1 = form;
          var survey = param;
          var selectedCampaign$2 = Belt_Option.getExn(selectedCampaign);
          var modes = Belt_List.map(Belt_List.map(form$1[/* modes */6], FormField$ReactHooksTemplate.getInputValue), Mode$ReactHooksTemplate.fromForm);
          var buildTargetDict = function (form) {
            var params = { };
            params["campaign"] = Json_encode.object_(/* :: */[
                  /* tuple */[
                    "name",
                    Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, FormField$ReactHooksTemplate.getInputValue(form[/* name */0]))
                  ],
                  /* :: */[
                    /* tuple */[
                      "survey_id",
                      survey[/* id */0]
                    ],
                    /* :: */[
                      /* tuple */[
                        "campaign_modes_attributes",
                        Json_encode.list(Mode$ReactHooksTemplate.encoder, modes)
                      ],
                      /* [] */0
                    ]
                  ]
                ]);
            return params;
          };
          var campaignId = Campaign$ReactHooksTemplate.id(selectedCampaign$2);
          Http$ReactHooksTemplate.fetchPutJSON("/api/v1/campaigns/" + (String(campaignId) + ""), buildTargetDict(form$1)).then((function (response) {
                  Curry._1(toggleCampaign, "save");
                  return Promise.resolve(response);
                }));
          return /* () */0;
        });
    } else {
      return (function (param) {
          var form$1 = form;
          var survey = param;
          var modes = Belt_List.map(Belt_List.map(form$1[/* modes */6], FormField$ReactHooksTemplate.getInputValue), Mode$ReactHooksTemplate.fromForm);
          var campaignEncoder = Json_encode.object_(/* :: */[
                /* tuple */[
                  "name",
                  Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, FormField$ReactHooksTemplate.getInputValue(form$1[/* name */0]))
                ],
                /* :: */[
                  /* tuple */[
                    "survey_id",
                    survey[/* id */0]
                  ],
                  /* :: */[
                    /* tuple */[
                      "campaign_modes_attributes",
                      Json_encode.list(Mode$ReactHooksTemplate.encoder, modes)
                    ],
                    /* [] */0
                  ]
                ]
              ]);
          Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/campaigns", Json_encode.object_(/* :: */[
                      /* tuple */[
                        "campaign",
                        campaignEncoder
                      ],
                      /* [] */0
                    ])).then((function (response) {
                  Curry._1(toggleCampaign, "save");
                  return Promise.resolve(response);
                }));
          return /* () */0;
        });
    }
  };
  var findChecked = function (form, modeString) {
    return Belt_List.getBy(form[/* modes */6], (function (x) {
                  return modeString === Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x)));
                })) !== undefined;
  };
  var modeView = function (form, mode) {
    var selected = Belt_List.getBy(form[/* modes */6], (function (x) {
            return Mode$ReactHooksTemplate.modeType(mode) === Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x)));
          }));
    if (selected !== undefined) {
      var mode$1 = FormField$ReactHooksTemplate.getInputValue(selected);
      var match = mode$1[/* formMode */1];
      if (typeof match === "number") {
        return React.createElement(React.Fragment, undefined);
      } else {
        switch (match.tag | 0) {
          case /* Email */0 :
              var emailMode = match[0];
              var partial_arg = EmailMode$ReactHooksTemplate.fromForm(emailMode);
              var partial_arg$1 = EmailMode$ReactHooksTemplate.fromForm(emailMode);
              return React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                        }, React.createElement("div", {
                              className: "mb-8"
                            }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                  label: "Subject",
                                  value: FormField$ReactHooksTemplate.getInputValue(emailMode[/* subject */0]),
                                  handleChange: (function (param) {
                                      var emailMode = partial_arg;
                                      var $$event = param;
                                      var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                      return Curry._1(dispatch, /* EmailModeChanged */Block.__(2, [EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.setSubject(value, emailMode))]));
                                    }),
                                  maxLength: 100
                                }), validationErrors(emailMode[/* subject */0])), React.createElement("div", undefined, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                                  label: "Body",
                                  value: FormField$ReactHooksTemplate.getInputValue(emailMode[/* body */1]),
                                  handleChange: (function (param) {
                                      var emailMode = partial_arg$1;
                                      var $$event = param;
                                      var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                      return Curry._1(dispatch, /* EmailModeChanged */Block.__(2, [EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.setBody(value, emailMode))]));
                                    })
                                }), validationErrors(emailMode[/* body */1])));
          case /* Sms */1 :
              var smsMode = match[0];
              return React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8\n                         flex justify-between items-center"
                        }, React.createElement("div", {
                              className: "text-sm font-medium mb-2"
                            }, "Sender ID"), React.createElement("select", {
                              className: "h-10 w-3/5 block border px-3 py-2 border-gray-400\n                           w-52 bg-white rounded capitalize text-sm",
                              placeholder: "Select Sender",
                              value: String(SmsMode$ReactHooksTemplate.senderId(SmsMode$ReactHooksTemplate.fromForm(smsMode))),
                              onChange: (function (param) {
                                  var $$event = param;
                                  var value = Caml_format.caml_int_of_string(Utils$ReactHooksTemplate.formTargetValue($$event));
                                  return Curry._1(dispatch, /* SmsModeChanged */Block.__(7, [SmsMode$ReactHooksTemplate.toForm(SmsMode$ReactHooksTemplate.setSenderId(value))]));
                                })
                            }, React.createElement("option", {
                                  key: "0",
                                  value: "0"
                                }, "Select SenderId"), $$Array.of_list(Belt_List.mapWithIndex(senderIds, (function (idx, x) {
                                        return React.createElement("option", {
                                                    key: String(idx),
                                                    selected: x[/* id */0] === SmsMode$ReactHooksTemplate.senderId(SmsMode$ReactHooksTemplate.fromForm(smsMode)),
                                                    value: String(x[/* id */0])
                                                  }, x[/* senderId */1]);
                                      })))));
          case /* SmsLink */2 :
              var smsLinkMode = match[0];
              return React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                        }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                              label: "Message",
                              value: FormField$ReactHooksTemplate.getInputValue(smsLinkMode[/* message */0]),
                              handleChange: handleSmsLinkMessageChange
                            }), validationErrors(smsLinkMode[/* message */0]));
          case /* WhatsappLink */3 :
              var whatsappLinkMode = match[0];
              return React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                        }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                              label: "Message",
                              value: FormField$ReactHooksTemplate.getInputValue(whatsappLinkMode[/* message */0]),
                              handleChange: handleWhatsappLinkMessageChange
                            }), validationErrors(whatsappLinkMode[/* message */0]));
          case /* AppLink */4 :
              var appLinkMode = match[0];
              return React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                        }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                              label: "Message",
                              value: FormField$ReactHooksTemplate.getInputValue(appLinkMode[/* message */0]),
                              handleChange: handleAppLinkMessageChange
                            }), validationErrors(appLinkMode[/* message */0]));
          case /* WebsiteLink */5 :
              var websiteLinkMode = match[0];
              return React.createElement("div", {
                          className: "p-6 bg-ghostwhite border border-gray-300 rounded-lg mb-8"
                        }, React.createElement(TextAreaInputNew$ReactHooksTemplate.make, {
                              label: "Message",
                              value: FormField$ReactHooksTemplate.getInputValue(websiteLinkMode[/* message */0]),
                              handleChange: handleWebsiteLinkMessageChange
                            }), validationErrors(websiteLinkMode[/* message */0]));
          
        }
      }
    } else {
      return React.createElement(React.Fragment, undefined);
    }
  };
  if (state.tag) {
    var form = state[0];
    var match$4 = form[/* survey */5];
    var selectedSurvey;
    switch (match$4.tag | 0) {
      case /* Valid */0 :
          selectedSurvey = match$4[0];
          break;
      case /* Invalid */1 :
      case /* Pristine */2 :
          selectedSurvey = undefined;
          break;
      
    }
    var selectedSurvey$1 = Belt_Option.getExn(selectedSurvey);
    var match$5 = Curry._1(match$3[0], /* CreateCampaign */15);
    var tmp;
    if (match$5) {
      var match$6 = selected === /* GeneralSettings */0;
      var match$7 = selected === /* CampaignModes */1;
      var tmp$1;
      if (selected) {
        var tmp$2;
        if (selectedCampaign !== undefined) {
          var selectedModes = Campaign$ReactHooksTemplate.modes(Caml_option.valFromOption(selectedCampaign));
          tmp$2 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: "flex flex-col overflow-y-scroll max-h-400-px"
                  }, $$Array.of_list(Belt_List.map(selectedModes, (function (mode) {
                              return React.createElement("div", undefined, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                              checked: true,
                                              label: Mode$ReactHooksTemplate.modeType(mode),
                                              handleChange: (function (param) {
                                                  return /* () */0;
                                                }),
                                              className: "mb-8",
                                              checkboxClassName: "blue_checkbox",
                                              key: Mode$ReactHooksTemplate.modeType(mode)
                                            }), modeView(form, mode));
                            })))));
        } else {
          tmp$2 = React.createElement("div", undefined, React.createElement("div", {
                    className: "flex flex-col overflow-y-scroll max-h-400-px"
                  }, $$Array.of_list(Belt_List.map(form[/* availableModes */3], (function (x) {
                              var partial_arg = Mode$ReactHooksTemplate.modeType(x);
                              return React.createElement("div", undefined, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                              checked: findChecked(form, Mode$ReactHooksTemplate.modeType(x)),
                                              label: Mode$ReactHooksTemplate.modeType(x),
                                              handleChange: (function (param) {
                                                  var form$1 = form;
                                                  var modeString = partial_arg;
                                                  var $$event = param;
                                                  var selected = Belt_List.getBy(form$1[/* modes */6], (function (x) {
                                                          return modeString === Mode$ReactHooksTemplate.modeType(Mode$ReactHooksTemplate.fromForm(FormField$ReactHooksTemplate.getInputValue(x)));
                                                        }));
                                                  if (selected !== undefined) {
                                                    var mode = FormField$ReactHooksTemplate.getInputValue(selected);
                                                    return Curry._1(dispatch, /* RemoveMode */Block.__(12, [mode]));
                                                  } else {
                                                    var value = modeString;
                                                    var mode$1;
                                                    switch (value) {
                                                      case "AnonymousLink" :
                                                          mode$1 = /* Valid */Block.__(0, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : AnonymousLink */0
                                                              ]]);
                                                          break;
                                                      case "AppLink" :
                                                          mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : AppLink */Block.__(4, [AppLinkMode$ReactHooksTemplate.toForm(AppLinkMode$ReactHooksTemplate.empty(/* () */0))])
                                                              ]]);
                                                          break;
                                                      case "Email" :
                                                          mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : Email */Block.__(0, [EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.empty(/* () */0))])
                                                              ]]);
                                                          break;
                                                      case "Sms" :
                                                          mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : Sms */Block.__(1, [SmsMode$ReactHooksTemplate.toForm(SmsMode$ReactHooksTemplate.empty(/* () */0))])
                                                              ]]);
                                                          break;
                                                      case "SmsLink" :
                                                          mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : SmsLink */Block.__(2, [SmsLinkMode$ReactHooksTemplate.toForm(SmsLinkMode$ReactHooksTemplate.empty(/* () */0))])
                                                              ]]);
                                                          break;
                                                      case "Telephonic" :
                                                          mode$1 = /* Valid */Block.__(0, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : Telephonic */1
                                                              ]]);
                                                          break;
                                                      case "WebsiteLink" :
                                                          mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : WebsiteLink */Block.__(5, [WebsiteLinkMode$ReactHooksTemplate.toForm(WebsiteLinkMode$ReactHooksTemplate.empty(/* () */0))])
                                                              ]]);
                                                          break;
                                                      case "WhatsappLink" :
                                                          mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                                /* id */undefined,
                                                                /* formMode : WhatsappLink */Block.__(3, [WhatsappLinkMode$ReactHooksTemplate.toForm(WhatsappLinkMode$ReactHooksTemplate.empty(/* () */0))])
                                                              ]]);
                                                          break;
                                                      default:
                                                        mode$1 = /* Pristine */Block.__(2, [/* record */[
                                                              /* id */undefined,
                                                              /* formMode : Email */Block.__(0, [EmailMode$ReactHooksTemplate.toForm(EmailMode$ReactHooksTemplate.empty(/* () */0))])
                                                            ]]);
                                                    }
                                                    return Curry._1(dispatch, /* AddMode */Block.__(11, [mode$1]));
                                                  }
                                                }),
                                              className: "mb-8",
                                              checkboxClassName: "blue_checkbox",
                                              key: Mode$ReactHooksTemplate.modeType(x)
                                            }), modeView(form, x));
                            })))));
        }
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, tmp$2));
      } else {
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "mb-6"
                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                      label: "Campaign Name",
                      value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, FormField$ReactHooksTemplate.getInputValue(form[/* name */0])),
                      handleChange: handleNameInput,
                      maxLength: 100
                    }), validationErrors(form[/* name */0])), React.createElement("div", {
                  className: "mb-6"
                }, React.createElement("div", {
                      className: "flex justify-between items-center"
                    }, React.createElement("div", {
                          className: "text-sm font-medium"
                        }, "Select Survey"), selectedCampaign !== undefined ? React.createElement("input", {
                            className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                            disabled: true,
                            type: "text",
                            value: Campaign$ReactHooksTemplate.surveyName(Caml_option.valFromOption(selectedCampaign))
                          }) : React.createElement("select", {
                            className: "h-10 w-3/5 block border px-3 py-2 border-gray-400\n                                w-52 bg-white rounded capitalize text-sm",
                            value: String(selectedSurvey$1[/* id */0]),
                            onChange: (function (param) {
                                var form$1 = form;
                                var $$event = param;
                                var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                var selectedSurvey = List.find((function (x) {
                                        return String(x[/* id */0]) === value;
                                      }), form$1[/* surveys */1]);
                                return Curry._1(dispatch, /* UpdateSurvey */Block.__(10, [selectedSurvey]));
                              })
                          }, $$Array.of_list(Belt_List.mapWithIndex(form[/* surveys */1], (function (idx, x) {
                                      return React.createElement("option", {
                                                  key: String(idx),
                                                  value: String(x[/* id */0])
                                                }, x[/* name */1]);
                                    })))))));
      }
      tmp = React.createElement("div", {
            className: "h-432-px"
          }, React.createElement("div", {
                className: "p-8 text-lg font-bold flex justify-between"
              }, React.createElement("div", undefined, selectedCampaign !== undefined ? React.createElement("div", undefined, "Edit Campaign") : React.createElement("div", undefined, "Create New Campaign")), React.createElement("img", {
                    className: "h-14-px float-right",
                    src: Image$ReactHooksTemplate.closeIcon,
                    onClick: toggleModal
                  })), React.createElement("form", {
                className: "h-432-px",
                onSubmit: (function (param) {
                    var form$1 = form;
                    var $$event = param;
                    $$event.preventDefault();
                    return Belt_Option.mapWithDefault(FormField$ReactHooksTemplate.getInputValue(form$1[/* survey */5]), /* () */0, handleChangeModal(form$1, selectedCampaign));
                  })
              }, React.createElement("div", {
                    className: "flex h-432-px z-20 relative"
                  }, React.createElement("div", {
                        className: "w-3/10 flex flex-col border-r border-gray-300\n                                text-darkcharcoal text-base"
                      }, React.createElement("div", {
                            className: "px-6 py-5 " + (
                              match$6 ? "bg-lavenderweb" : ""
                            ),
                            onClick: (function (param) {
                                return Curry._1(setSelected, (function (param) {
                                              return /* GeneralSettings */0;
                                            }));
                              })
                          }, "General Settings"), React.createElement("div", {
                            className: "px-6 py-5 " + (
                              match$7 ? "bg-lavenderweb" : ""
                            ),
                            onClick: (function (param) {
                                return Curry._1(setSelected, (function (param) {
                                              return /* CampaignModes */1;
                                            }));
                              })
                          }, "Campaign Modes")), React.createElement("div", {
                        className: "w-7/10 flex flex-col p-8"
                      }, tmp$1)), React.createElement("div", {
                    className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n                absolute bottom-0 text-base w-full z-30 relative"
                  }, React.createElement("button", {
                        className: "bg-ghostwhite border border-gray-400`\n                  flex rounded py-3 px-6 text-xs",
                        id: "close_modal",
                        onClick: toggleModal
                      }, "Discard"), React.createElement("input", {
                        className: "bg-palatinateblue border border-gray-400\n                  flex rounded py-3 px-6 text-xs text-white",
                        disabled: !isValidForm(form),
                        type: "submit",
                        value: "Save"
                      }))));
    } else {
      tmp = React.createElement("div", {
            className: "h-screen w-full animated fadeIn"
          }, React.createElement(Loading$ReactHooksTemplate.make, {
                img: Image$ReactHooksTemplate.unAuthorized,
                text: "401: Unauthorized!!",
                loading: false
              }));
    }
    return React.createElement("div", undefined, tmp);
  } else {
    return React.createElement("p", undefined, "Loading...");
  }
}

var make = CampaignForm;

exports.Survey = Survey;
exports.CampaignApiResponse = CampaignApiResponse;
exports.availableModes = availableModes;
exports.emptyForm = emptyForm;
exports.generateForm = generateForm;
exports.reducer = reducer;
exports.validationErrors = validationErrors;
exports.isValid = isValid;
exports.isValidMode = isValidMode;
exports.isValidSurvey = isValidSurvey;
exports.isValidForm = isValidForm;
exports.make = make;
/* availableModes Not a pure module */
