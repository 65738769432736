// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var PermissionTree$ReactHooksTemplate = require("./settings/permissionTree.bs.js");

function initValue_000(param) {
  return false;
}

var initValue_001 = PermissionTree$ReactHooksTemplate.emptyPermission(/* () */0);

var initValue = /* tuple */[
  initValue_000,
  initValue_001
];

var authContext = React.createContext(initValue);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = authContext.Provider;

exports.initValue = initValue;
exports.authContext = authContext;
exports.makeProps = makeProps;
exports.make = make;
/* initValue Not a pure module */
